import React, {
  FunctionComponent,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Flex, Text } from 'theme-ui';
import { observer } from 'mobx-react-lite';
import { Tabs, Tab } from '@blueprintjs/core';
import { useMediaQuery } from 'react-responsive';

import styled from '../../theme/styled';

import ActivitiesTab from './ActivitiesTab';
import PeopleTab from './PeopleTab';
import GroupsTab from './GroupsTab';
import VenuesTab from './VenuesTab';
import FilterBar from '../Layout/FilterBar';

import { filterStore } from '../../stores/FilterStore';
import { SORT_TYPES, encodeSchedule } from '../../helpers/search';
import SearchField from './SearchField';

const Body = styled(Flex)`
  z-index: 0;
  min-height: 100vh;
`;

const Title = styled(Text)`
  padding: 4px 16px;
`;

const availableTabs = ['activities', 'people', 'groups', 'venues', 'classes'];

type Props = {};

const ExploreBody: FunctionComponent<Props> = observer(() => {
  const {
    selectedSports,
    sortTypes,
    skillLevelRange,
    scheduleList,
    isShowFullActivities,
    locations,
    setExploreQuery,
    customFilters,
  } = filterStore;

  const [selectedTab, setSelectedTab]: [
    string,
    Dispatch<SetStateAction<string>>
  ] = useState('activities');

  const param: { tabId?: string } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (param.tabId && availableTabs.includes(param.tabId)) {
      setSelectedTab(param.tabId);
    }
  }, [param]);

  useEffect(() => {
    setExploreQuery();
  }, [setExploreQuery]);

  const selectedType = sortTypes[selectedTab];

  const query: {
    sports: string[];
    sort: string;
    locations: { latitude: number; longitude: number; radius: number }[];
    minSkillRating: number;
    maxSkillRating: number;
    schedule: number;
    showFullGames: boolean;
  } = {
    sports: selectedSports.map((sport) => sport.toUpperCase()),
    sort: SORT_TYPES[selectedType],
    locations: locations.map(({ latitude, longitude, radius }) => ({
      latitude: Number(latitude),
      longitude: Number(longitude),
      radius: Number(radius),
    })),
    minSkillRating: skillLevelRange[0],
    maxSkillRating: skillLevelRange[1],
    schedule: encodeSchedule(scheduleList),
    showFullGames: isShowFullActivities,
  };

  const isShowSearch = useMediaQuery({ query: '(min-width: 768px)' });

  return (
    <Body
      as="main"
      sx={{
        flexDirection: 'column',
        maxWidth: '580px',
      }}
    >
      {isShowSearch && <SearchField />}
      <Flex
        sx={{
          flexDirection: 'column',
          px: [0, '24px'],
          flex: 1,
        }}
      >
        <Tabs
          id="explore-tabs"
          className="modify-tabs"
          renderActiveTabPanelOnly
          onChange={(id: string) => {
            setSelectedTab(id);
            history.push(`/explore/${id}`);
          }}
          selectedTabId={selectedTab}
        >
          <Tab
            id="activities"
            title={<Title>Activities</Title>}
            panel={<ActivitiesTab query={customFilters || query} />}
          />
          <Tab
            id="people"
            title={<Title>People</Title>}
            panel={<PeopleTab query={query} />}
          />
          <Tab
            id="groups"
            title={<Title>Groups</Title>}
            panel={<GroupsTab query={query} />}
          />
          <Tab
            id="venues"
            title={<Title>Venues</Title>}
            panel={<VenuesTab query={query} />}
          />
        </Tabs>
        <FilterBar selectedTab={selectedTab} />
      </Flex>
    </Body>
  );
});

export default ExploreBody;
