import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from 'rebass';

import ActivityCard from '../../UI/Card/ActivityCard';
import EmptyList from '../../UI/EmptyList';

import { useStores } from '../../../hooks/useStore';
import useWindowInfinityScroll from '../../../hooks/useWindowInfinityScroll';
import { ActivityLoading } from '../../UI/ContentLoading';

const UpcomingActivitiesTab: React.FC = observer(() => {
  const { scheduleStore } = useStores();

  const { getUpcomingActivities, activities, loading, hasMore } = scheduleStore;

  useWindowInfinityScroll(getUpcomingActivities);

  return (
    <Box>
      {!!activities.length &&
        activities.map(activity => (
          <Box px="3" py="2" key={activity.id}>
            <ActivityCard activity={activity} />
          </Box>
        ))}
      {!activities.length && !loading && <EmptyList text="activities" />}
      {hasMore && loading && (
        <>
          <ActivityLoading />
          <ActivityLoading />
        </>
      )}
    </Box>
  );
});

export default UpcomingActivitiesTab;
