import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Flex } from 'rebass';
import LocalStorageHelper from '../../helpers/localStorage';
import FireBaseService from '../../services/firebaseService';
import { Button } from '../Primitives';
import { Input } from '../UI/Input';
import Link from '../UI/Link';
import Text from '../UI/Text';
import FacebookLogin from './components/FacebookLogin';
import AuthLayout from './components/Layout';
import { SignUpBtn } from './styled';

type FormData = {
  email: string;
  password: string;
};

const SignInPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const parsed = qs.parse(location.search);
  const re = parsed.re;

  const [serverErr, setServerErr] = useState({
    message: '',
    code: '',
  });
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, errors } = useForm<FormData>();

  useEffect(() => {
    const account = LocalStorageHelper.get('currentUser');
    if (account && account.session) {
      history.push('/home');
    }
  }, [history]);

  const onSubmit = handleSubmit(async ({ email, password }) => {
    setServerErr({
      message: '',
      code: '',
    });
    setLoading(true);
    try {
      await FireBaseService.signIn(email, password, (status?: string) => {
        if (status === 'INCOMPLETE') {
          return history.push('/complete-profile');
        }
        if (typeof re === 'string') {
          window.location.href = re;
          return;
        }
        window.location.pathname = '/home';
      });
    } catch (err) {
      setServerErr(err);
    } finally {
      setLoading(false);
    }
  });

  const isPremium = re?.includes('premium');

  return (
    <AuthLayout isPremium={isPremium} formName="Login">
      <>
        <Flex as="form" flexDirection="column" width="100%" onSubmit={onSubmit}>
          <Box mb="2">
            <Text variant="lb.md" mb="2">
              Email
            </Text>
            <Input
              disabled={loading}
              name="email"
              type="email"
              placeholder="Enter your email"
              ref={register({ required: true })}
              isError={!!errors.email}
            />
          </Box>
          {errors.email && <Text color="danger">Email is required</Text>}
          <Box mb="2">
            <Text variant="lb.md" mb="2">
              Password
            </Text>
            <Input
              disabled={loading}
              name="password"
              type="password"
              placeholder="Enter your password"
              ref={register({ required: true })}
              isError={!!errors.password}
            />
          </Box>
          {errors.password && <Text color="danger">Password is required</Text>}
          {serverErr.code === 'auth/user-not-found' ||
          serverErr.code === 'auth/wrong-password' ||
          serverErr.code === 'auth/invalid-email' ? (
            <Text color="danger" mt="2">
              You have entered an invalid email or password. If you signed up
              using Facebook, please try logging in using Facebook. For further
              assistance, please contact us at{' '}
              <a
                href="mailto:support@rovo.co?Subject=Login Issues"
                target="_blank"
                rel="noopener noreferrer"
              >
                support@rovo.co
              </a>
            </Text>
          ) : (
            !!serverErr.message && (
              <Text color="danger" mt="2">
                {serverErr.message}
              </Text>
            )
          )}
          <Button loading={loading} type="submit" my="3" size="md">
            <Text variant="lb.md" color="white">
              Log in
            </Text>
          </Button>
          <Flex justifyContent="center" mb="4" mt="1">
            <Link to="/reset-password">
              <Text variant="lb.md" color="#0ACCCC">
                Forgot Password?
              </Text>
            </Link>
          </Flex>
          <Flex alignItems="center" mb="3">
            <Box height="1px" bg="#D1DCE8" flex="1" />
            <Text variant="lb.md" mx="2">
              Or continue with
            </Text>
            <Box height="1px" bg="#D1DCE8" flex="1" />
          </Flex>
          <Flex>
            <SignUpBtn type="button" flex="1" mr="2">
              <Link
                to={typeof re === 'string' ? `/sign-up?re=${re}` : '/sign-up'}
              >
                <Text variant="lb.md" color="#0ac2cc">
                  Sign up
                </Text>
              </Link>
            </SignUpBtn>
            <FacebookLogin isAuth isNoText />
          </Flex>
        </Flex>
      </>
    </AuthLayout>
  );
};

export default SignInPage;
