import React, { useEffect } from 'react';
import { Flex, Link } from 'theme-ui';
import { UnAuthHeaderOnlyLogo } from '../components/Layout/UnAuth/Header';
import Footer from '../components/Layout/UnAuth/Footer';
import { Text } from '../components/Primitives';
import { trackPageView } from '../services/amplitudeService';

const DownloadApp = () => {
  useEffect(() => {
    trackPageView('download-app');
  }, []);

  return (
    <Flex
      sx={{
        minHeight: '100vh',
        flexDirection: 'column',
      }}
    >
      <UnAuthHeaderOnlyLogo />
      <Flex
        sx={{
          justifyContent: 'center',
          flex: 1,
          alignItems: 'center',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Text
            variant="lb.lg"
            mb="2"
            sx={{
              textAlign: 'center',
            }}
          >
            This feature is currently only available on the Rovo App.
          </Text>

          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://page.rovo.co/download-app"
            sx={{
              width: '100%',
              bg: 'primary',
              color: 'white',
              textAlign: 'center',
              py: 3,
              fontSize: '16px',
              fontWeight: 500,
              borderRadius: '8px',
              ':hover': {
                color: 'white',
                textDecoration: 'none',
              },
            }}
          >
            Get the Rovo app here
          </Link>
        </Flex>
      </Flex>
      <Footer />
    </Flex>
  );
};

export default DownloadApp;
