import React, { useEffect } from 'react';

import Partner from '../components/Venue/Partner';
import { trackPageView } from '../services/amplitudeService';

const PartnerPage: React.FC<{}> = () => {
  useEffect(() => {
    trackPageView('partner');
  }, []);

  return <Partner />;
};

export default PartnerPage;
