import React, { useState } from 'react';
import { PrimaryDialog } from '../UI/Dialog';
import { observer } from 'mobx-react-lite';
import { IActivity } from '../../interface/Activity';
import { Box } from 'rebass';
import { Tabs, Tab } from '@blueprintjs/core';
import SearchPlayers from './SearchPlayers';
import { Text } from 'rebass';
import SearchGroups from './SearchGroups';
import DiscoverPlayer from './DiscoverPlayers';
import { useStores } from '../../hooks/useStore';
interface Props {
  isOpen: boolean;
  onClose: () => void;
  activity: IActivity;
}

const InvitePlayers = observer(({ isOpen, onClose, activity }: Props) => {
  const { inviteStore } = useStores();
  const {
    invitedPlayers,
    invitePlayersToGame,
    inviteGroupsToGame,
    inviteSinglePlayerToGame,
  } = inviteStore;

  const [selectedTab, setSelectedTab] = useState('search');

  const handleInvite = () => {
    if (selectedTab === 'search') {
      return invitePlayersToGame(activity.id);
    }
    return inviteGroupsToGame(activity.id);
  };

  return (
    <PrimaryDialog
      title="Invite Players"
      isOpen={isOpen}
      onClose={onClose}
      actionBtn={selectedTab !== 'discover' ? 'Invite' : ''}
      onClickActionBtn={handleInvite}
    >
      <Box bg="white">
        <Tabs
          className="modify-tabs no-margin"
          id="invite-tabs"
          renderActiveTabPanelOnly
          selectedTabId={selectedTab}
          onChange={(id: string) => setSelectedTab(id)}
        >
          <Tab
            id="search"
            panel={
              <SearchPlayers
                activity={activity}
                invitedPlayers={invitedPlayers}
              />
            }
            title={
              <Text px="3" py="2" fontWeight="bold">
                Search
              </Text>
            }
          />
          <Tab
            id="discover"
            panel={
              <DiscoverPlayer
                activity={activity}
                invitedPlayers={invitedPlayers}
                inviteSinglePlayerToGame={inviteSinglePlayerToGame}
              />
            }
            title={
              <Text px="3" py="2" fontWeight="bold">
                Discover
              </Text>
            }
          />
          <Tab
            id="groups"
            panel={<SearchGroups activity={activity} />}
            title={
              <Text px="3" py="2" fontWeight="bold">
                Groups
              </Text>
            }
          />
        </Tabs>
      </Box>
    </PrimaryDialog>
  );
});

export default InvitePlayers;
