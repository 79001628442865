import httpService from '../services/httpService';
import get from 'lodash/get';
import { IPeople, IAccount, ISportProfile } from '../interface/People';

export const getSelfTimeline = async (lastActivityId: string | null) => {
  const data: any = {
    isAggregated: true,
    limit: 20,
  };
  if (lastActivityId) {
    data['id_lt'] = lastActivityId;
  }
  try {
    const response = await httpService.post(`me/timeline`, data);
    const timeline = get(response, 'data', {
      activities: [],
      lastActivityId: '',
    });
    return timeline;
  } catch (err) {
    if (err.response.status === 404) {
      return { activities: [], lastActivityId: null };
    }
    throw err;
  }
};

export const getTimeline = async (
  profileId: number | string,
  lastActivityId: string | null
) => {
  const data: any = {
    isAggregated: true,
    limit: 20,
  };
  if (lastActivityId) {
    data['id_lt'] = lastActivityId;
  }
  try {
    const response = await httpService.post(
      `profiles/${profileId}/timeline`,
      data
    );
    const timeline = get(response, 'data', {
      activities: [],
      lastActivityId: '',
    });
    return timeline;
  } catch (err) {
    if (err.response.status === 404) {
      return { activities: [], lastActivityId: null };
    }
    throw err;
  }
};

export const getFeed = async (lastActivityId: string | null) => {
  const data: any = {
    limit: 20,
  };
  if (lastActivityId) {
    data['id_lt'] = lastActivityId;
  }
  try {
    const response = await httpService.post(`me/feed`, data);
    const timeline = response?.data || { activities: [], lastActivityId: '' };
    return timeline;
  } catch (err) {
    throw err;
  }
};

export const getSelfPlays = async (params?: { limit: number }) => {
  try {
    const data = await httpService.get(`me/plays`, {
      params,
    });
    const plays = get(data, 'data.plays', []);
    return plays;
  } catch (err) {
    throw err;
  }
};

export const getProfilePlays = async (
  name: string,
  params?: { limit?: number; offset?: number; past?: boolean }
) => {
  try {
    const data = await httpService.get(`profiles/${name}/plays`, {
      params,
    });
    const plays = get(data, 'data.plays', []);
    return plays;
  } catch (err) {
    throw err;
  }
};

export const getSelfProfile = async () => {
  try {
    const data = await httpService.get(`me/profile`);
    const profile: IPeople = get(data.data, 'profile');
    return profile;
  } catch (err) {
    throw err;
  }
};

export const getCurrentAccount = async () => {
  try {
    const data = await httpService.get(`me`);
    const profile: IAccount = get(data.data, 'account');
    return profile;
  } catch (err) {
    throw err;
  }
};

export const getProfile = async (username: string = '') => {
  try {
    const data = await httpService.get(`profiles/${username}`);
    const profile: IPeople = get(data.data, 'profile');
    return profile;
  } catch (err) {
    if (err.response.status > 400) {
      window.location.pathname = '/not-found';
    }
    throw err;
  }
};

export const getFollowers = async (
  profileId: number,
  params?: { limit?: number; offset?: number }
) => {
  try {
    const data = await httpService.get(`profiles/${profileId}/followers`, {
      params,
    });
    const followers = get(data.data, 'followers');
    return followers;
  } catch (err) {
    throw err;
  }
};

export const getFollowings = async (
  profileId: number,
  params?: { limit?: number; offset?: number }
) => {
  try {
    const data = await httpService.get(`profiles/${profileId}/following`, {
      params,
    });
    const followings = get(data.data, 'followings');
    return followings;
  } catch (err) {
    throw err;
  }
};

export const getScores = async (
  profileId: number,
  params?: { limit?: number; offset?: number }
) => {
  try {
    const data = await httpService.get(
      `profiles/${profileId}/plays/matches/history`,
      {
        params,
      }
    );
    const plays = get(data.data, 'plays');
    return plays;
  } catch (err) {
    throw err;
  }
};

export const updateProfile = async (data: {
  username?: string;
  firstName?: string;
  lastName?: string;
  isPrivate?: boolean;
  gender?: string;
  defaultCurrencyId?: string;
}) => {
  try {
    const response = await httpService.put(`me/profile`, data);
    const profile: IPeople = get(response.data, 'profile');
    return profile;
  } catch (err) {
    throw err;
  }
};

export const updateAccount = async (data: { emailVerified?: boolean, freshchatId?: string | null }) => {
  try {
    const response = await httpService.patch(`me`, data);
    return response.data?.account;
  } catch (err) {
    throw err;
  }
};

export const uploadUserProfilePicture = async (image: File) => {
  const formData = new window.FormData();
  formData.append('file', image);
  try {
    const response = await httpService.post('me/profile/picture', formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    const profile = get(response, 'data.profile');
    return profile;
  } catch (err) {
    throw err;
  }
};

export const uploadImage = async (image: File) => {
  const formData = new window.FormData();
  formData.append('file', image);
  try {
    const response = await httpService.post('me/posts/media', formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    const media = response?.data?.media;
    return media;
  } catch (err) {
    throw err;
  }
};

export const createPost = async (caption: string, media: any[]) => {
  try {
    const data = {
      type: 'DEFAULT',
      caption,
      media,
    };
    const response = await httpService.post('me/posts', data);
    return response?.data?.post;
  } catch (error) {
    throw error;
  }
};

export const updateSportProfileSchedule = async (
  schedule: number,
  sport: string,
  applyGlobal?: boolean
) => {
  try {
    const response = await httpService.patch(
      `me/profile/sportProfiles/${sport}?syncSchedule=${applyGlobal}`,
      { schedule }
    );
    const profile: IPeople = get(response.data, 'profile');
    return profile;
  } catch (err) {
    throw err;
  }
};

export const getFacebookFriends = async (token: string) => {
  try {
    const response = await httpService.post('/me/profile/friends', {
      type: 'FACEBOOK',
      token,
    });
    const friends = get(response.data, 'friends');
    return friends;
  } catch (err) {
    throw err;
  }
};

export const updateFollow = async (profileId: number, isFollow: boolean) => {
  const url = `profiles/${profileId}/follow`;
  try {
    if (isFollow) {
      return await httpService.delete(`profiles/${profileId}/follow`);
    }
    return await httpService.post(url, {
      isFavorite: true,
      requestFollow: true,
    });
  } catch (err) {
    throw err;
  }
};

export const approveFollower = async (profileId: number) => {
  try {
    await httpService.post(`/me/followers/${profileId}`);
  } catch (error) {
    throw error;
  }
};

export const removeFollower = async (profileId: number) => {
  try {
    await httpService.delete(`/me/followers/${profileId}`);
  } catch (error) {
    throw error;
  }
};

export const addSports = async (
  location: any,
  assessments: { sport: string; level: string }[]
) => {
  try {
    const response = await httpService.post('/me/profile/sportProfiles', {
      location,
      assessments,
    });
    const profile = get(response, 'data.profile');
    return profile;
  } catch (error) {
    throw error;
  }
};

export const removeSport = async (sportProfileId: string) => {
  try {
    const response = await httpService.delete(
      `/me/profile/sportProfiles/${sportProfileId}`
    );
    const profile = get(response, 'data.profile');
    return profile;
  } catch (error) {
    throw error;
  }
};

export const updateSportProfile = async (
  sport: string,
  data: ISportProfile
) => {
  try {
    const response = await httpService.patch(
      `me/profile/sportProfiles/${sport}`,
      data
    );
    const profile = get(response, 'data.profile');
    return profile;
  } catch (err) {
    throw err;
  }
};

export const updateActivityLike = async (
  activityId: string,
  isLiked: boolean
) => {
  try {
    const url = `activities/${activityId}/likes`;
    if (isLiked) {
      const response = await httpService.post(url);
      return response;
    } else {
      const response = await httpService.delete(url);
      return response;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchActivityLike = async (
  activityId: string,
  params?: { offset: number; limit: number }
) => {
  try {
    const response = await httpService.get(`activities/${activityId}/likes`, {
      params,
    });
    const peopleList = response?.data?.likes || [];
    return peopleList;
  } catch (error) {
    throw error;
  }
};

export const getComments = async (
  type: string,
  id: string,
  params?: { before?: number; limit?: number }
) => {
  try {
    let uri = '';
    switch (type) {
      case 'ACTIVITY':
        uri = '/activities';
        break;
      case 'PLAY':
        uri = '/plays';
        break;
      case 'CLASS':
        uri = '/classSessions';
        break;
    }
    const response = await httpService.get(`${uri}/${id}/comments`, { params });
    return response?.data?.comments;
  } catch (error) {
    if (error?.response?.status === 404) return [];
    throw error;
  }
};

export const getProfileCompletion = async () => {
  try {
    const response = await httpService.get('me/profile/checklist');
    return response?.data?.checklist;
  } catch (error) {
    throw error;
  }
};

export const getFacilityBookingsHistory = async ({
  upcoming = false,
  offset = 0,
  limit = 20,
}: {
  upcoming: boolean;
  offset: number;
  limit: number;
}) => {
  try {
    const response = await httpService.get('partners/facilityBookings/me', {
      params: { upcoming, offset, limit },
    });
    return response?.data?.data;
  } catch (error) {
    if (error?.response?.statusCode === 404) return [];
    throw error;
  }
};

export async function getSelfHomeData() {
  try {
    const response = await httpService.get('me/home');
    return response.data?.sections;
  } catch (error) {
    throw error;
  }
}

export async function getSelfInvoices() {
  try {
    const response = await httpService.get('me/unpaidInvoices');
    return response.data?.data;
  } catch (error) {
    if (error?.response?.status === 404) return [];
    throw error;
  }
}

export async function payInvoice(
  invoiceId: string,
  paymentMethodId?: string,
  token?: string
) {
  try {
    const response = await httpService.post(
      `me/unpaidInvoices/${invoiceId}/pay`,
      token ? { token } : { paymentMethodId }
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
}

export async function checkInvoiceStatus(paymentId: string) {
  try {
    await httpService.get(`me/invoicePayments/${paymentId}`);
  } catch (error) {
    throw error;
  }
}

export async function generateCustomFirebaseToken(email: string) {
  try {
    const response = await httpService.get('auth/firebase/token/generate', {
      params: { email },
    });
    return response.data?.token;
  } catch (error) {
    throw error;
  }
}
