import httpService from '../services/httpService';

export const searchLocations = async (query: string) => {
  try {
    let response = await httpService.get('locations', {
      params: {
        query,
        offset: 0,
        limit: 10,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getLocationDetail = async (placeId: string) => {
  try {
    const response = await httpService.get(`locations/${placeId}`);
    return response?.data?.location;
  } catch (error) {
    throw error;
  }
};
