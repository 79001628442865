import { Box, Card } from 'rebass';
import PerfectScrollBar from 'react-perfect-scrollbar';
import styled from '../../../theme/styled';

export const CardWrapper = styled(Card)`
  :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const UserWrapper = styled(PerfectScrollBar)`
  display: flex;
  overflow: auto;
  padding-bottom: 16px;
  height: inherit;
  > div:not(:last-child) {
    min-width: 32px;
    margin-right: 16px;
  }
`;

export const Tag = styled(Box)`
  display: inline-block;
  :not(:last-child) {
    margin-right: 8px;
  }
  padding: 4px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
`;
