import React, { useEffect } from 'react';
import { Box } from 'theme-ui';

import FollowList from '../Explore/FollowList';
import Navigation from '../Layout/Navigation';
import VenueBody from './components/VenueBody';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../hooks/useStore';
import { useParams } from 'react-router-dom';
import VenuePageLoading from './components/VenuePageLoading';

const VenuePage: React.FC = observer(() => {
  const { venueStore } = useStores();

  const { getSelectedVenue, selectedVenue, partnerProfile } = venueStore;

  const param: { venueUri?: string } = useParams();

  useEffect(() => {
    if (param?.venueUri && param.venueUri !== selectedVenue?.pageInfo?.uri) {
      getSelectedVenue(param.venueUri);
    }
  }, [getSelectedVenue, selectedVenue, param]);

  return (
    <Box
      variant="layout.main"
      sx={{
        gap: '0px',
      }}
    >
      <Box variant="layout.leftSide">
        <Navigation hideOnMobile />
      </Box>
      {!!selectedVenue ? (
        <VenueBody venue={selectedVenue} partnerProfile={partnerProfile} />
      ) : (
        <VenuePageLoading />
      )}
      <Box variant="layout.rightSide">
        <FollowList />
      </Box>
    </Box>
  );
});

export default VenuePage;
