import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import { useParams, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { Box } from 'theme-ui';

import Navigation from '../Layout/Navigation';
import Header from '../Layout/Header';
import ShareDialog from '../Layout/ShareDialog';

import { userStore } from '../../stores/UserStore';
import UpcomingActivities from './UpcomingActivities';
import Followers from './Followers';
import Following from './Following';
import Plays from './Plays';
import ProfileCompletion from './ProfileCompletion';

import { MobileSettingMenu } from './Profile.styled';
import useWindowInfinityScroll from '../../hooks/useWindowInfinityScroll';
import config from '../../config';
import ProfileBody from './ProfileBody';

const listView = [
  'profile',
  'follower',
  'following',
  'play',
  'profile-completion',
];

const ProfilePage: FunctionComponent<{}> = observer(() => {
  const {
    account,
    profile,
    timeline,
    selfTimeline,
    timelineLoading,
    selfTimelineLoading,
    upcomingActivities,
    loading,
    getUpcomingActivities,
    getSelfTimeline,
    getProfile,
    getTimeline,
    isSelf,
  } = userStore;

  const me = account.profile;

  const [isShowSetting, setSettingMenu] = useState(false);
  const [isShowShareDialog, setShowShareDialog] = useState(false);

  const param: { username?: string } = useParams();

  const location = useLocation();
  const parsed = qs.parse(location.search);
  const current = parsed.current;

  const getUserData = useCallback(async () => {
    getProfile(param.username);
  }, [getProfile, param]);

  const getSelfData = useCallback(() => {
    getUpcomingActivities();
  }, [getUpcomingActivities]);

  useEffect(() => {
    if (param.username && param.username !== profile.username) {
      getUserData();
    }
    if (account.profile.id > -1) {
      getSelfData();
    }
  }, [getUserData, getSelfData, param, profile.username, account]);

  const userProfile = isSelf ? me : profile;

  const userTimeline = isSelf ? selfTimeline : timeline;

  const userTimelineLoading = isSelf ? selfTimelineLoading : timelineLoading;

  const getCurrentTimeline = useCallback(
    (query?: any, isReset?: boolean) => {
      if (isSelf) {
        return getSelfTimeline(isReset);
      }
      getTimeline(profile.id, isReset);
    },
    [getTimeline, getSelfTimeline, profile, isSelf]
  );

  useWindowInfinityScroll(getCurrentTimeline);

  const displayName = userProfile.username;

  const shouldDisplayMainBody =
    !current || (typeof current === 'string' && !listView.includes(current));

  return (
    <Box
      variant="layout.main"
      sx={{
        gap: '0px',
      }}
    >
      <Header
        name={`@${displayName}`}
        secondaryIcon="hamburger"
        onClickIcon={() => setSettingMenu(true)}
      />
      {isShowSetting && (
        <MobileSettingMenu
          openShare={setShowShareDialog}
          setMoreMenuStatus={setSettingMenu}
        />
      )}
      <Box variant="layout.leftSide">
        <Navigation selected={isSelf ? 'profile' : ''} />
      </Box>
      {current === 'play' && (
        <Plays
          profileId={userProfile.id}
          name={userProfile.firstName}
          username={param.username || userProfile.username}
          isSelf={isSelf}
        />
      )}
      {current === 'follower' && (
        <Followers
          profileId={userProfile.id}
          isSelf={isSelf}
          name={userProfile.username}
        />
      )}
      {current === 'following' && (
        <Following
          profileId={userProfile.id}
          isSelf={isSelf}
          username={userProfile.username}
        />
      )}
      {current === 'profile-completion' && <ProfileCompletion profile={me} />}
      {shouldDisplayMainBody && (
        <ProfileBody
          profile={userProfile}
          timeline={userTimeline}
          timelineLoading={userTimelineLoading}
          isSelf={isSelf}
          loading={loading}
        />
      )}
      <Box variant="layout.rightSide">
        <UpcomingActivities
          username={displayName}
          upcomingActivities={upcomingActivities}
          loading={loading}
        />
      </Box>
      <ShareDialog
        isOpen={isShowShareDialog}
        onClose={() => setShowShareDialog(false)}
        url={`${config.URL}/@${userProfile.username}`}
      />
    </Box>
  );
});

export default ProfilePage;
