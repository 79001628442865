import { decorate, observable, action, runInAction } from 'mobx';
import { getFollowings } from '../api/ProfileAPI';
import { SEARCH_LIMIT } from '../constant';
import { IFollowing } from '../interface/People';

class FollowingStore {
  followings: IFollowing[] = [];

  loading: boolean = false;

  hasMore: boolean = true;

  params: {
    offset: number;
    limit: number;
  } = { offset: 0, limit: SEARCH_LIMIT };

  getFollowings = async (profileId: number, isReset?: boolean) => {
    if (profileId === -1) return;
    if (this.loading) return;
    if (!this.hasMore && !isReset) return;
    if (isReset) {
      this.followings = []
      this.params.offset = 0;
    }
    this.loading = true;
    let followings: IFollowing[] = [];
    try {
      followings = await getFollowings(profileId, this.params);
    } catch { }
    const dataLength = followings.length;
    runInAction(() => {
      if (dataLength < SEARCH_LIMIT) this.hasMore = false;
      if (!isReset) {
        this.followings = [...this.followings, ...followings];
      } else {
        this.followings = followings;
      }
      this.loading = false;
      this.params.offset =
        dataLength === SEARCH_LIMIT
          ? this.params.offset + dataLength
          : this.params.offset;
    });
  };
}

decorate(FollowingStore, {
  followings: observable,
  loading: observable,
  hasMore: observable,
  getFollowings: action,
});

export const followingStore = new FollowingStore();
