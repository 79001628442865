import React, { FunctionComponent, useState } from 'react';
import { Box, Flex } from 'rebass';
import { Spinner } from '@blueprintjs/core';
import { toJS } from 'mobx';
import Text from '../../UI/Text';
import { Icon } from '../../Primitives';
import { IconWithText } from '../../Primitives/Icon';

import { getRating } from '../../../helpers/user';
import { ISportProfile } from '../../../interface/People';

import {
  Wrapper,
  SportCard,
  WrapperImg,
  CoverRight,
  AddSportCard,
  AddSportIcon,
  RankedLevel,
  BadgeCount,
  Badge,
  BadgeWrapper,
  AddSportDialog,
  SportDetailDialog,
} from './SportProfile.styled';
import AddSport from './AddSport';
import SportDetail from './SportDetail';
import { Button } from 'rebass';

const SportProfile: FunctionComponent<{
  sportProfiles: ISportProfile[];
  isSelf: boolean;
  loading: boolean;
}> = ({ sportProfiles, isSelf, loading }) => {
  const [isOpenAddSportDialog, setAddSportDialogStatus] = useState(false);
  const [isShowSportDetail, setSportDetailDialog] = useState(false);

  const [selectedSport, setSelectedSport] = useState<ISportProfile | null>(
    null
  );

  const onClickSportCard = (sportProfile: ISportProfile) => {
    setSelectedSport(toJS(sportProfile));
    setSportDetailDialog(true);
  };

  const selectedSports = sportProfiles.map((sport) => sport.sport);

  return (
    <Flex mb="4" px="3" bg="white">
      <Wrapper>
        {isSelf && (
          <AddSportCard
            minWidth="180px"
            py="4"
            mr="2"
            color="white"
            justifyContent="center"
          >
            <Flex
              alignItems="center"
              onClick={() => setAddSportDialogStatus(true)}
            >
              <AddSportIcon>
                <Icon icon="add" fill="white" />
              </AddSportIcon>
              <Text ml="2">Add a sport</Text>
            </Flex>
            <Box
              variant="line"
              my="3"
              sx={{
                backgroundColor: 'rgba(255,255,255, 0.3)',
              }}
            />
            <Flex
              alignItems="center"
              onClick={() => setAddSportDialogStatus(true)}
            >
              <AddSportIcon>
                <Icon icon="arrange" fill="white" />
              </AddSportIcon>
              <Text ml="2">Arrange</Text>
            </Flex>
          </AddSportCard>
        )}
        {!!sportProfiles.length &&
          !loading &&
          sportProfiles.map((sportProfile) => {
            const {
              sport,
              gamesPlayed,
              level,
              achievements,
              rating,
              assessmentPending,
              sportInfo: { uri, name },
            } = sportProfile;
            const totalBadge = achievements.reduce(
              (accumulator, achievement) =>
                achievement.count
                  ? (accumulator += achievement.count)
                  : accumulator,
              0
            );
            const badges = achievements.slice().sort((a, b) => {
              if (a.count && b.count) return a.count - b.count;
              return 0;
            });
            return (
              <SportCard
                color="white"
                key={sport}
                minWidth="250px"
                src={`https://s3-ap-southeast-1.amazonaws.com/prod.rovo.co/assets/sports/sport-${uri}-background.jpg`}
                onClick={() => onClickSportCard(sportProfile)}
              >
                <Text fontWeight="bold" mb="2">
                  {name.toUpperCase()}
                </Text>
                <Text mb="3">{level}</Text>
                <Flex>
                  <Flex flexDirection="column" alignItems="center" mr="2">
                    <RankedLevel color="white" fontWeight="bold">
                      {getRating({ assessmentPending, rating })}
                    </RankedLevel>
                    <Text fontSize={['12px', '14px']}>Level</Text>
                  </Flex>
                  <Flex flexDirection="column" alignItems="center" mr="2">
                    <IconWithText
                      icon="activity-cover"
                      size="badge"
                      text={gamesPlayed}
                    />
                    <Text fontSize={['12px', '14px']}>Games</Text>
                  </Flex>
                  <BadgeCount flexDirection="column" alignItems="center">
                    {totalBadge ? (
                      <IconWithText
                        icon="badge-cover"
                        size="badge"
                        text={totalBadge}
                      />
                    ) : (
                      <Icon icon="no-badge" size="badge" />
                    )}
                    <Text>Badges</Text>
                  </BadgeCount>
                  <Flex>
                    {!!totalBadge && (
                      <BadgeWrapper>
                        {badges
                          .splice(0, 2)
                          .map(({ count, icon, id }, index) =>
                            count ? (
                              <Badge
                                key={id}
                                src={icon}
                                left={index}
                                size="40px"
                              />
                            ) : null
                          )}
                      </BadgeWrapper>
                    )}
                  </Flex>
                </Flex>
                <WrapperImg>
                  <CoverRight
                    height="150px"
                    width="auto"
                    src={`https://s3-ap-southeast-1.amazonaws.com/prod.rovo.co/assets/sports/sport-${uri}-background.jpg`}
                  />
                </WrapperImg>
              </SportCard>
            );
          })}
        {loading && <Spinner intent="primary" />}
        {!loading && !sportProfiles?.length && (
          <Flex
            onClick={() => setAddSportDialogStatus(true)}
            alignItems="center"
            p="3"
          >
            <Text variant="body">You don't have any sport profile</Text>
            <Button
              height="50px"
              variant="transparent"
              ml="2"
              fontWeight="bold"
            >
              Create One
            </Button>
          </Flex>
        )}
      </Wrapper>
      {isOpenAddSportDialog && (
        <AddSportDialog
          isOpen={isOpenAddSportDialog}
          onClose={() => setAddSportDialogStatus(false)}
        >
          <AddSport
            selectedSports={selectedSports}
            close={() => setAddSportDialogStatus(false)}
          />
        </AddSportDialog>
      )}
      {isShowSportDetail && (
        <SportDetailDialog
          isOpen={isShowSportDetail}
          onClose={() => setSportDetailDialog(false)}
        >
          <SportDetail
            isSelf={isSelf}
            selectedSport={selectedSport}
            onClose={() => setSportDetailDialog(false)}
            setSelectedSport={setSelectedSport}
          />
        </SportDetailDialog>
      )}
    </Flex>
  );
};

export default SportProfile;
