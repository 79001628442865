import React, { useEffect } from 'react';

import { ActivityDetailPage } from '../components/ActivityDetail';
import { trackPageView } from '../services/amplitudeService';

const Activities: React.FC = () => {
  useEffect(() => {
    trackPageView('Activities');
  }, []);

  return <ActivityDetailPage />;
};

export default Activities;
