import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { Spinner } from '@blueprintjs/core';
import { Box } from 'theme-ui';

import { PageBody } from '../../components/Layout/Layout.styled';
import FollowList from '../../components/Explore/FollowList';
import Navigation from '../../components/Layout/Navigation';
import { useStores } from '../../hooks/useStore';
import { trackPageView } from '../../services/amplitudeService';

import GroupBody from './components/GroupBody';
import { Text } from '../../components/Primitives';

const VenuePage: React.FC = observer(() => {
  const { groupDetailStore } = useStores();

  const { group, getGroupDetail, loading } = groupDetailStore;

  const param: { groupUri?: string } = useParams();

  useEffect(() => {
    if (param?.groupUri && param.groupUri !== group?.groupProfile.uri) {
      getGroupDetail(param.groupUri);
    }
  }, [getGroupDetail, group, param]);

  useEffect(() => {
    trackPageView('Group');
  }, []);

  return (
    <Box
      variant="layout.main"
      sx={{
        gap: '0px',
      }}
    >
      <Box variant="layout.leftSide">
        <Navigation hideOnMobile />
      </Box>
      <PageBody>
        {!!group && !loading && <GroupBody group={group} />}
        {loading && <Spinner />}
        {!group && !loading && (
          <Text>Seem like there's no group like this.</Text>
        )}
      </PageBody>
      <Box variant="layout.rightSide">
        <FollowList />
      </Box>
    </Box>
  );
});

export default VenuePage;
