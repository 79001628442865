import { useCallback, useEffect } from 'react';
import throttle from 'lodash/throttle';

export default function useWindowInfinityScroll(
  fetchData: (query?: any, isReset?: boolean) => void,
  query?: any
) {
  const getData = useCallback(
    (isReset?: boolean) => {
      fetchData(query, isReset);
    },
    [fetchData, query]
  );

  const handleScroll = useCallback(
    throttle(() => {
      if (
        window.innerHeight + document.documentElement.scrollTop <
        document.documentElement.offsetHeight - 300
      ) {
        return;
      }
      getData();
    }, 500),
    [getData]
  );

  useEffect(() => {
    getData(true);
  }, [getData]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
}

export function useBodyInfinityScroll(
  ref: any,
  fetchData: (query?: any, isReset?: boolean) => void,
  query?: any
) {
  const getData = useCallback(
    (isReset?: boolean) => {
      fetchData(query, isReset);
    },
    [fetchData, query]
  );

  const handleScroll = useCallback(
    throttle(() => {
      if (
        ref?.current &&
        ref.current?.scrollTop + ref.current?.clientHeight <
          ref.current?.scrollHeight - 200
      ) {
        return;
      }
      getData();
    }, 500),
    [getData]
  );

  useEffect(() => {
    getData(true);
  }, [getData]);

  useEffect(() => {
    const body = ref?.current;
    if (body) {
      body.addEventListener('scroll', handleScroll);
      return () => {
        body.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll, ref]);
}
