import React from 'react';
import { Flex } from 'rebass';

import styled from '../../../theme/styled';

import Text from '../../UI/Text';

import ScoreCard from './ScoreCard';
import PostCard from './PostCard';
import HostCard from './HostCard';
import AwardCard from './AwardCard';
import CardContainer from './CardContainer';
import IActivityTimeline from '../../../interface/ActivityTimeline';
import { useHistory } from 'react-router-dom';

const Interactive = styled(Flex)`
  border-top: ${(p) => p.theme.border.box};
`;

interface Props {
  item: IActivityTimeline;
  isSelf: boolean;
  onClick?: () => void;
  isShowCommentInbox?: boolean;
}

const InteractiveCard: React.FC<Props> = ({
  item,
  isSelf,
  onClick,
  isShowCommentInbox,
}) => {
  const { object, title } = item;

  const renderCard = (item: any) => {
    const { verb } = item;
    switch (verb) {
      case 'post':
        return <PostCard key={item.id} item={item} />;
      case 'play':
        return <ScoreCard key={item.id} item={item} />;
      case 'host':
      case 'join':
        return <HostCard key={item.id} item={item} />;
      case 'award':
        return <AwardCard key={item.id} item={item} />;
      default:
        return null;
    }
  };

  const history = useHistory();

  const getLink = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    switch (item.verb) {
      case 'like':
        if (item.actor.objectType === 'profile') {
          return history.push(
            `/@${item.actor.rawObject.username}/activities/${item.object.id}`
          );
        }
        return null;
      case 'comment':
        if (item.object.objectType === 'play') {
          return history.push(
            `/plays/${item.object.id}?current=activity-discussions`
          );
        }
        if (
          item.object.objectType === 'activity' &&
          item.actor.objectType === 'profile'
        ) {
          return history.push(
            `/@${item.actor.rawObject.username}/activities/${item.object.id}`
          );
        }
        return null;

      default:
        break;
    }
  };

  return (
    <Interactive flexDirection="column" bg="white">
      <Flex
        sx={{
          a: {
            color: 'primaryText',
          },
        }}
        px="3"
        py="2"
        onClick={getLink}
      >
        <Text color="primaryText" dangerouslySetInnerHTML={{ __html: title }} />
      </Flex>
      <CardContainer
        item={object.rawObject}
        isSelf={isSelf}
        isShowCommentInbox={isShowCommentInbox}
      >
        {renderCard(object.rawObject)}
      </CardContainer>
    </Interactive>
  );
};

export default InteractiveCard;
