import React from 'react';
import { Box } from 'theme-ui';

import Navigation from '../Layout/Navigation';
import FollowList from './FollowList';
import SearchBody from './SearchBody';

const SearchPage: React.FC = () => {
  return (
    <Box variant="layout.main">
      <Box variant="layout.leftSide">
        <Navigation selected="explore" />
      </Box>
      <SearchBody />
      <Box variant="layout.rightSide">
        <FollowList />
      </Box>
    </Box>
  );
};

export default SearchPage;
