import React from 'react';
import { Flex } from 'rebass';

import Text from '../../../UI/Text';
import RadioSelect from '../../../UI/RadioSelect';
import { CurrentSport } from './AddSport';

type Props = {
  currentSports: CurrentSport[];
  setCurrentSports: Function;
};

const levelOptions = [
  { text: 'BEGINNER', value: 'BEGINNER' },
  { text: 'INTERMEDIATE', value: 'INTERMEDIATE' },
  { text: 'ADVANCED', value: 'ADVANCED' },
];

const SelectSportLevel: React.FC<Props> = ({
  currentSports,
  setCurrentSports,
}) => {
  const setLevel = (value: string, index: number) => {
    const updateSports = [...currentSports];
    updateSports[index].level = value;
    setCurrentSports(updateSports);
  };

  return (
    <Flex flexDirection="column" mb="2">
      {currentSports.map(({ sport, level, name }, index) => (
        <Flex flexDirection="column" key={sport} mb="3">
          <Text mb="2" fontWeight="bold" color="primaryText">
            {name.toUpperCase()}
          </Text>
          <RadioSelect
            value={level}
            setValue={(value: string) => setLevel(value, index)}
            options={levelOptions}
          />
        </Flex>
      ))}
      <Text textAlign="center" color="primaryText">
        You'll be able to do a self skill assessment for the popular sports to
        better gauge your level later on.
      </Text>
    </Flex>
  );
};

export default SelectSportLevel;
