import React, { useMemo, useState } from 'react';
import Carousel from '@brainhubeu/react-carousel';
import { Box, Text, Image, Flex } from 'rebass';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Icon } from '../../Primitives';
import Avatar from '../../UI/Avatar';

import { useStores } from '../../../hooks/useStore';
import useWindowInfinityScroll from '../../../hooks/useWindowInfinityScroll';

import { getLastActive } from '../../../helpers/datetime';

import { IActivityNotification } from '../../../interface/Notification';

import {
  LoadingItem,
  EmptyNotificationScreen,
  WhatsNew,
  RequestItem,
} from './NotificationItems';

import { PageBody, ColumnItem, PageHeading } from '../Notification.styled';
import JoinDialog from '../../UI/Dialog/JoinDialog';

const icons: {
  [index: string]: 'calendar' | 'user' | 'favorite' | 'edit' | 'chat' | 'star';
} = {
  join: 'calendar',
  invite: 'user',
  like: 'favorite',
  post: 'edit',
  comment: 'chat',
  notify: 'chat',
  award: 'star',
};

const NotificationBody: React.FC = observer(() => {
  const history = useHistory();
  const { notificationStore } = useStores();
  const {
    getNotifications,
    activities,
    loading,
    lastActivityId,
    banners,
    groups,
    followRequests,
    markReadNotifications,
  } = notificationStore;

  const [isOpenDialog, setOpenDialog] = useState(false);

  // Memo value to prevent call api to many time
  const query = useMemo(() => ({ limit: 20, markSeen: true }), []);
  useWindowInfinityScroll(getNotifications, query);

  const onClickNotification = (activity: IActivityNotification) => {
    switch (activity.verb) {
      case 'join':
        return history.push(`/plays/${activity.object.id}`);
      case 'invite':
        return history.push(
          `/groups/${activity.target.rawObject.groupProfile?.uri}`
        );
      case 'award':
      case 'post':
        if (activity.actor.objectType === 'partner') {
          return history.push(
            `/${activity.actor.rawObject.pageInfo.uri}/activities/${activity.id}`
          );
        }
        if (activity.actor.objectType === 'profile') {
          return history.push(
            `/@${activity.actor.rawObject.username}/activities/${activity.id}`
          );
        }
        return null;
      case 'like':
        if (activity.actor.objectType === 'profile') {
          return history.push(
            `/@${activity.actor.rawObject.username}/activities/${activity.object.id}`
          );
        }
        return null;
      case 'comment':
        if (activity.object.objectType === 'play') {
          return history.push(
            `/plays/${activity.object.id}?current=activity-discussions`
          );
        }
        if (
          activity.object.objectType === 'activity' &&
          activity.actor.objectType === 'profile'
        ) {
          return history.push(
            `/@${activity.actor.rawObject.username}/activities/${activity.object.id}`
          );
        }
        return null;
      case 'follow':
        return history.push(
          `/${
            activity.actor.objectType === 'profile'
              ? activity.actor.rawObject.username
              : 'not-found'
          }`
        );
      default:
        return setOpenDialog(true);
    }
  };

  return (
    <PageBody>
      <PageHeading
        display="flex"
        height="56px"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        px={['3', '24px']}
      >
        <Icon
          icon="chevron-left"
          size="lg"
          fill="primaryText"
          onClick={() => history.goBack()}
        />
        <Text variant="headline.small">Notification</Text>
        <Icon
          icon="settings"
          size="md"
          onClick={() => history.push('settings/notifications')}
        />
      </PageHeading>
      <Box variant="line" />
      <Box>
        <Carousel infinite dots autoPlay={3000} animationSpeed={1000}>
          {banners
            // TODO: Remove this when premium is ready
            .filter((b) => !b.link?.includes('premium'))
            .map((b) => {
              return (
                <a
                  key={b.id}
                  href={b.link}
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  <Image
                    sx={{
                      cursor: 'pointer',
                    }}
                    src={b.image}
                  />
                </a>
              );
            })}
        </Carousel>
      </Box>

      <WhatsNew />
      {groups.length > 0 && (
        <RequestItem
          numOfUnRead={groups.length}
          title="Group join requests"
          icon="people"
          path="/notifications/groups"
        />
      )}
      {followRequests.length > 0 && (
        <RequestItem
          numOfUnRead={followRequests.length}
          title="Follow requests"
          icon="person-add"
          path="/notifications/follows"
        />
      )}

      <Box>
        {!!activities.length &&
          activities.map((activity) => (
            <ColumnItem
              p="3"
              width="100%"
              key={activity.id}
              bg={!activity.isRead ? 'dark7' : 'white'}
              onClick={() => {
                if (!activity.isRead) {
                  markReadNotifications([activity.streamId]);
                }
                onClickNotification(activity);
              }}
            >
              <Box minWidth="48px">
                <Avatar imageLink={activity.actor.image.url} size="48px" />
              </Box>
              <Box ml="3">
                <Text
                  mb="1"
                  variant="body"
                  dangerouslySetInnerHTML={{ __html: activity.title }}
                  sx={{
                    '> a': {
                      color: 'primaryText',
                    },
                  }}
                />
                <Flex>
                  {!!icons[activity.verb] && (
                    <Icon icon={icons[activity.verb]} size="sm" />
                  )}
                  <Text ml="1" variant="caption">
                    {getLastActive(activity.time)}
                  </Text>
                </Flex>
              </Box>
            </ColumnItem>
          ))}
        {!activities.length && !loading && (
          <EmptyNotificationScreen description="You have no notifications yet. You’ll be able to find them here when you host or join activities or chat with people." />
        )}
        {lastActivityId !== undefined && loading && <LoadingItem />}
        {isOpenDialog && (
          <JoinDialog
            isOpen={isOpenDialog}
            onClose={() => setOpenDialog(false)}
            title="This feature is under development on web, but is available on the Rovo app."
            getAppDescription=""
          />
        )}
      </Box>
    </PageBody>
  );
});

export default NotificationBody;
