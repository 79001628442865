import React from 'react';
import NotificationStore from './stores/NotificationStore';
import ScheduleStore from './stores/ScheduleStore';
import ScoreStore from './stores/ScoreStore';
import FacilityBookingStore from './stores/FacilityBookingStore';
import VenueStore from './stores/VenueStore';
import GroupDetailStore from './stores/GroupDetailStore';
import PostDetailStore from './stores/PostDetailStore';
import InviteStore from './stores/InviteStore';

export const storesContext = React.createContext({
  notificationStore: new NotificationStore(),
  scheduleStore: new ScheduleStore(),
  scoreStore: new ScoreStore(),
  facilityBookingStore: new FacilityBookingStore(),
  venueStore: new VenueStore(),
  groupDetailStore: new GroupDetailStore(),
  postDetailStore: new PostDetailStore(),
  inviteStore: new InviteStore(),
});
