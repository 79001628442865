import React, {
  FunctionComponent,
  SyntheticEvent,
  useState,
  useEffect,
} from 'react';
import { observer } from 'mobx-react-lite';
import { Flex, Button } from 'rebass';
import { Switch } from '@blueprintjs/core';

import Text from '../../UI/Text';

import { FilterDialog } from './FilterBar.styled';

import { filterStore } from '../../../stores/FilterStore';
import FilterLocation from './FilterLocation';
import FilterSchedule from './FilterSchedule';
import FilterLevel from './FilterLevel';
import { DISPLAY_FILTER_OPTION } from '../../../helpers/search';
import { userStore } from '../../../stores/UserStore';

type Props = {
  isOpen: boolean;
  selectedTab: string;
  onClose: (event?: SyntheticEvent<HTMLElement, Event> | undefined) => void;
};

type Location = {
  latitude: number;
  longitude: number;
  placeId: string;
  radius: number;
  name: string;
};

const AdvanceOptions: FunctionComponent<Props> = observer(
  ({ isOpen, selectedTab, onClose }) => {
    const {
      locations,
      skillLevelRange,
      scheduleList,
      isShowFullActivities,
      setLocations,
      setSkillLevel,
      setScheduleList,
      setShowFullActivities,
    } = filterStore;

    const { account } = userStore;

    const [currentLocations, setCurrentLocation] = useState<Location[]>(
      locations
    );
    const [isShowFull, setToggleShowFull] = useState(isShowFullActivities);
    const [levelRange, setLevelRange] = useState<[number, number]>(
      skillLevelRange
    );
    const [schedule, setSchedule] = useState(scheduleList);

    const [isAddingLocation, setAddLocation] = useState(false);

    useEffect(() => {
      if (account?.profile?.filterLocations?.length) {
        const mapperFilterLocations: Location[] = account.profile.filterLocations.map(
          (location) => {
            return {
              latitude: Number(location.latitude),
              longitude: Number(location.longitude),
              placeId: location.placeId,
              radius: 20,
              name: location?.locality || location?.name || location?.city,
            };
          }
        );
        setLocations(mapperFilterLocations);
        setCurrentLocation(mapperFilterLocations);
      }
    }, [account, setLocations]);

    const applyFilter = () => {
      setLocations(currentLocations);
      setSkillLevel(levelRange);
      setShowFullActivities(isShowFull);
      setScheduleList(schedule);
      onClose();
    };

    const availableFilter = DISPLAY_FILTER_OPTION[selectedTab];

    return (
      <FilterDialog
        isOpen={isOpen}
        onClose={() => {
          setAddLocation(false);
          onClose();
        }}
      >
        <Flex
          fontSize="3"
          mb="3"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text color="primaryText">Filter {selectedTab}</Text>
          <Button variant="noPadding" color="primary" onClick={applyFilter}>
            Done
          </Button>
        </Flex>
        {availableFilter.includes('location') && (
          <FilterLocation
            isAddingLocation={isAddingLocation}
            currentLocations={currentLocations}
            setCurrentLocation={setCurrentLocation}
            setAddLocation={setAddLocation}
          />
        )}
        {availableFilter.includes('level') && !isAddingLocation && (
          <FilterLevel levelRange={levelRange} setLevelRange={setLevelRange} />
        )}
        {availableFilter.includes('gender') && !isAddingLocation && (
          <Flex mt="3">
            <Text color="primaryText" mr="2">
              Show players of your gender only
            </Text>
            <Switch
              large
              checked={isShowFull}
              onChange={() => setToggleShowFull((s) => !s)}
            />
          </Flex>
        )}
        {availableFilter.includes('schedule') && !isAddingLocation && (
          <>
            <Text
              uppercase
              color="primaryText"
              fontWeight="bold"
              fontSize="2"
              mb="3"
            >
              DAYS
            </Text>
            <FilterSchedule schedule={schedule} setSchedule={setSchedule} />
          </>
        )}
        {availableFilter.includes('showFull') && !isAddingLocation && (
          <Flex mt="3">
            <Text color="primaryText" mr="2">
              Show activities which are full
            </Text>
            <Switch
              large
              checked={isShowFull}
              onChange={() => setToggleShowFull((s) => !s)}
            />
          </Flex>
        )}
      </FilterDialog>
    );
  }
);

export default AdvanceOptions;
