import React, { useState } from 'react';
import { Spinner } from '@blueprintjs/core';
import { Button, Image, Box, Flex } from 'rebass';
import { observer } from 'mobx-react-lite';

import Avatar from '../UI/Avatar';
import Text from '../UI/Text';
import { Icon } from '../Primitives';
import { InputFile } from '../UI/Input';

import CreatePostDialog from './CreatePostDialog';

import { IPeople } from '../../interface/People';

import { userStore } from '../../stores/UserStore';

import styled from '../../theme/styled';

export const RemoveImgIcon = styled(Icon)`
  position: absolute;
  top: 0px;
  right: 0px;
`;

const Wrapper = styled(Flex)`
  border-top: ${p => p.theme.border.box};
  border-bottom: ${p => p.theme.border.box};
`;

interface Props {
  profile: IPeople;
}

const AddFeedBox: React.FC<Props> = observer(({ profile }) => {
  const { name, picture, pulseLevel } = profile;
  const { createPost } = userStore;

  const [isOpenCreate, setIsOpenCreate] = useState(false);

  const [images, setImages] = useState([]);
  const [caption, setCaption] = useState('');
  const [loading, setLoading] = useState(false);

  const onFileSelected = (e: any) => {
    e.preventDefault();
    let files = e.target.files;
    if (!files.length) {
      return;
    }
    const fileArr: any = Array.from(files).map((file: any) => file);
    const mergedFiles: any = [...images, ...fileArr];
    setImages(mergedFiles);
  };

  const onRemoveImage = (name: string) => {
    const updatedImages = images.filter((i: File) => i.name !== name);
    setImages(updatedImages);
  };

  const onCreatePost = async () => {
    setLoading(true);
    await createPost(caption, images);
    setLoading(false);
    setImages([]);
    setCaption('');
  };

  return (
    <>
      <Wrapper p="3" flexDirection="column" mb="3" bg="white">
        <Flex onClick={() => setIsOpenCreate(true)} alignItems="center">
          <Avatar pulseLevel={pulseLevel} imageLink={picture} size="52px" />
          {caption ? (
            <Text color="primaryText" ml="3">
              {caption}
            </Text>
          ) : (
            <Box ml="3">
              <Text color="primaryText">What's going on {name} ?</Text>
              <Text fontSize="12px">
                Share a photo, post with your followers
              </Text>
            </Box>
          )}
        </Flex>
        <Flex
          as="label"
          htmlFor="postFeed"
          justifyContent="center"
          alignItems="center"
          sx={{
            cursor: 'pointer',
          }}
        >
          <Icon icon="camera" stroke="primary" />
          <Text fontWeight="bold" color="primary" ml="2">
            Add Photo
          </Text>
          <InputFile
            type="file"
            id="postFeed"
            name="avatar"
            accept="image/*"
            onChange={onFileSelected}
            multiple
          />
        </Flex>
        <Flex flexWrap="wrap">
          {images.map((i: File, index) => {
            const url = window.URL.createObjectURL(i);
            return (
              <Flex
                key={index}
                justifyContent="center"
                alignItems="center"
                p="2"
                width="120px"
                height="120px"
                variant="card"
                mr="2"
                mb="2"
                sx={{
                  position: 'relative',
                }}
              >
                <Image src={url} maxWidth="100px" maxHeight="100%" />
                <RemoveImgIcon
                  icon="close"
                  stroke="black"
                  size="sm"
                  onClick={() => onRemoveImage(i.name)}
                />
              </Flex>
            );
          })}
        </Flex>
        {isOpenCreate && (
          <CreatePostDialog
            isOpen={isOpenCreate}
            onClose={() => setIsOpenCreate(false)}
            profile={profile}
            images={images}
            onFileSelected={onFileSelected}
            onRemoveImage={onRemoveImage}
            onCreatePost={onCreatePost}
            caption={caption}
            setCaption={setCaption}
          />
        )}
        {!!images.length && <Button onClick={onCreatePost}>Post</Button>}
      </Wrapper>
      {loading && (
        <Flex
          variant="card"
          width="100%"
          alignItems="center"
          justifyContent="center"
          mb="3"
        >
          <Spinner intent="primary" size={24} />
        </Flex>
      )}
    </>
  );
});

export default AddFeedBox;
