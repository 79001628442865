import React, { useState, useEffect } from 'react';
import { Flex, Button, Box } from 'rebass';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react-lite';
import { Spinner } from '@blueprintjs/core';
import { useForm } from 'react-hook-form';

import Text from '../../UI/Text';
import Avatar from '../../UI/Avatar';
import { Icon } from '../../Primitives';
import { StaticMap } from '../../UI/GoogleMap';
import { Input, Label, InputFile, CurrencyInput } from '../../UI/Input';
import RadioSelect from '../../UI/RadioSelect';

import LocationInput from '../../Layout/FilterBar/LocationInput';
import { HeaderMobile } from '../../Layout/Header';

import { userStore } from '../../../stores/UserStore';

import { IUserLocation } from '../../../interface/People';

const options = [
  {
    value: 'male',
    text: 'Male',
  },
  {
    value: 'female',
    text: 'Female',
  },
];

type FormData = {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
};

const Profile: React.FC = observer(() => {
  const {
    account,
    updateProfile,
    loading,
    uploadImageProfile,
    sendEmailVerify,
  } = userStore;
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const { register, handleSubmit, errors } = useForm<FormData>();

  const {
    picture,
    firstName,
    lastName,
    username,
    currency,
    location,
  } = account.profile;

  const [gender, setGender] = useState('male');
  const [selectedCurrency, setCurrency] = useState(currency);
  const [imagePreviewUrl, setImagePreviewUrl] = useState<any>('');
  const [image, setImage] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(location);

  useEffect(() => {
    setCurrentLocation(location);
  }, [location]);

  const onChangeLocation = (newLocation: IUserLocation) => {
    setCurrentLocation(newLocation);
  };

  const onFileSelected = (e: any) => {
    e.preventDefault();

    let reader = new window.FileReader();
    let file = e.target.files[0];
    if (!file) {
      return;
    }
    reader.onloadend = () => {
      setImage(file);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onSubmit = handleSubmit(
    async ({ firstName, lastName, username, email }) => {
      let data: {
        firstName: string;
        lastName: string;
        gender: string;
        defaultCurrencyId?: string;
        username: string;
        email: string;
        location: IUserLocation;
      } = {
        firstName,
        lastName,
        gender,
        username,
        email,
        location: currentLocation,
      };
      if (selectedCurrency?.id !== currency?.id) {
        data.defaultCurrencyId = selectedCurrency?.id;
      }

      await updateProfile(data);
      if (image) await uploadImageProfile(image);
    }
  );

  return (
    <Flex flexDirection="column">
      <HeaderMobile text="Profile" hasBackBtn={isMobile} backLink="/settings">
        <Button ml="auto" variant="noPadding" onClick={onSubmit}>
          DONE
        </Button>
      </HeaderMobile>
      {loading ? (
        <Flex mt="4" justifyContent="center">
          <Spinner intent="primary" />
        </Flex>
      ) : (
        <Flex as="form" flexDirection="column" onSubmit={onSubmit}>
          <Text textTransform="uppercase" fontWeight="bold" p="3">
            Public Info
          </Text>
          <Flex flexDirection="column" alignItems="center">
            <Label>
              <Avatar size="100px" imageLink={imagePreviewUrl || picture} />
              <Text color="primary" textAlign="center">
                Change avatar
              </Text>
              <InputFile
                type="file"
                id="avatar"
                name="avatar"
                accept="image/*"
                onChange={onFileSelected}
              />
            </Label>
          </Flex>
          <Flex flexDirection="column" p="3">
            <Label>
              <Text mb="1">First Name</Text>
              <Input
                name="firstName"
                defaultValue={firstName}
                ref={register({ required: true })}
                isError={!!errors.firstName}
              />
              {errors.firstName && (
                <Text color="danger">First name is required</Text>
              )}
            </Label>
            <Label>
              <Text mb="1">Last Name</Text>
              <Input
                name="lastName"
                defaultValue={lastName}
                ref={register({ required: true })}
                isError={!!errors.lastName}
              />
              {errors.firstName && (
                <Text color="danger">First name is required</Text>
              )}
            </Label>
            <Label>
              <Text mb="1">Profile Link</Text>
              <Input
                name="username"
                defaultValue={username}
                ref={register({ required: true })}
              />
            </Label>
            <Box py="2">
              <RadioSelect
                value={gender}
                setValue={setGender}
                options={options}
              />
            </Box>
            <Label>
              <Text mb="1">
                Your city or district:{' '}
                {currentLocation?.locality ||
                  currentLocation?.city ||
                  currentLocation?.name}
              </Text>
              <Box mb="2">
                <LocationInput
                  onClickItem={onChangeLocation}
                  isOverlay={true}
                />
              </Box>
              <StaticMap
                latitude={Number(currentLocation?.latitude)}
                longitude={Number(currentLocation?.longitude)}
              />
            </Label>
            <Text textTransform="uppercase" fontWeight="bold" py={[2, 3]}>
              Private Info
            </Text>
            <Label>
              <Text mb="1">Email</Text>
              <Input
                name="email"
                defaultValue={account.email || ''}
                disabled={!account.firebaseId}
                type="email"
                ref={register({ required: true })}
              />
              {account.firebaseId ? (
                account.emailVerified ? (
                  <Flex
                    alignItems="center"
                    fontWeight="bold"
                    fontSize="14px"
                    mt="1"
                  >
                    <Icon icon="ok" stroke="primary" size="sm" />
                    Email verified
                  </Flex>
                ) : (
                  <Text>
                    Email not verified.{' '}
                    <Button
                      variant="flat.primary"
                      type="button"
                      onClick={sendEmailVerify}
                    >
                      Send email verify
                    </Button>
                  </Text>
                )
              ) : (
                <Flex>
                  <Text
                    alignItems="center"
                    fontWeight="bold"
                    justifyContent="space-between"
                    mt="1"
                  >
                    Please set a password before change your email address.
                  </Text>
                  <Button variant="flat.primary">Set password</Button>
                </Flex>
              )}
            </Label>
            <Label>
              <Text mb="1">Currency</Text>
              {!currency ? (
                <CurrencyInput defaultValue={currency} setValue={setCurrency} />
              ) : (
                <Input readOnly value={`${currency.code} (${currency.name})`} />
              )}
            </Label>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
});

export default Profile;
