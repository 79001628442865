import React from 'react';
import PhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/style.css';
import { observer } from 'mobx-react-lite';
import { userStore } from '../../stores/UserStore';

type Props = {
  phone: string;
  disabled?: boolean;
  setPhone: (phone: string) => void;
};

const PrimitivePhoneInput = observer(({ phone, setPhone, disabled }: Props) => {
  const { account } = userStore;

  return (
    <PhoneInput
      value={phone}
      onChange={(phone: string) => setPhone(phone)}
      enableSearch
      disabled={disabled}
      countryCodeEditable={false}
      country={account?.profile?.country?.toLowerCase() || 'sg'}
      inputClass="rovo-phone-input"
      buttonClass="rovo-phone-input-button"
    />
  );
});

export default PrimitivePhoneInput;
