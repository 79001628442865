import React from 'react';
import { Box } from 'theme-ui';
import { useHistory } from 'react-router-dom';

import Navigation from '../../components/Layout/Navigation';
import FollowList from '../../components/Explore/FollowList';
import Header from '../../components/Layout/Header';
import HomeBody from './components/HomeBody';

const HomePage: React.FC = () => {
  const history = useHistory();

  return (
    <Box variant="layout.main">
      <Header
        name="Home"
        onClickIcon={() => history.push('/search', { isFromExplore: true })}
      />
      <Box variant="layout.leftSide">
        <Navigation selected="home" />
      </Box>
      <HomeBody />
      <Box variant="layout.rightSide">
        <FollowList />
      </Box>
    </Box>
  );
};

export default HomePage;
