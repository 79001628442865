import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import Profile from './profile';
import Partner from './partner';
import partnerStore from '../stores/partnerStore';

const MixProfile = observer(() => {
  const { getPartnerProfileByUri, partnerProfile, state } = partnerStore;

  const param: { username?: string } = useParams();

  useEffect(() => {
    if (param?.username && partnerProfile?.pageInfo.uri !== param.username) {
      getPartnerProfileByUri(param.username);
    }
  }, [getPartnerProfileByUri, param, partnerProfile]);

  if (state === 'NOT_FOUND') return <Profile />;

  return <Partner />;
});

export default MixProfile;
