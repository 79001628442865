import React, { FunctionComponent } from 'react';
import { Card, Image, Box } from 'rebass';
import qs from 'query-string';

import styled from '../../theme/styled';

import Icon, { HexagonBadge } from '../Primitives/Icon';
import { Spinner } from '@blueprintjs/core';
import { PULSE_LEVELS } from '../../constant';
import GradientCircularProgress from './GradientCircularProgress';

import 'react-circular-progressbar/dist/styles.css';
import { PulseIconNames } from '../Primitives/Icon/iconTypes';

const Wrapper = styled(Box)`
  position: relative;
`;

const Badge = styled(HexagonBadge)`
  position: absolute;
  bottom: 0px;
  right: -8px;
  font-size: 12px;
`;

const StyledImage = styled(Image)`
  border-color: ${(p) => p.border};
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
`;

const IconLevel = styled(Box)`
  position: absolute;
  width: 28px;
  height: 28px;
  bottom: 0px;
  right: 4px;
`;

type Props = {
  badgeText?: string | number;
  size?: string;
  badgeSize?: string;
  imageLink?: string;
  variant?: string;
  noBorder?: boolean;
  pulseLevel?: string;
  onClick?: Function;
  className?: string;
};

const displayLink = (imageLink: string) => {
  if (imageLink && imageLink.includes('https://platform-lookaside.fbsbx.com')) {
    const parsed = qs.parse(imageLink);
    const id =
      parsed['https://platform-lookaside.fbsbx.com/platform/profilepic/?asid'];
    return `https://graph.facebook.com/v3.2/${id}/picture?type=large`;
  } else return imageLink;
};

const Avatar: FunctionComponent<Props> = ({
  badgeText,
  size = '32px',
  imageLink,
  variant,
  pulseLevel,
  onClick,
  className,
  badgeSize,
}) => {
  return (
    <Wrapper width={size} height={size} className={className}>
      {!!imageLink ? (
        <StyledImage
          src={displayLink(imageLink)}
          alt="user-avatar"
          variant={onClick ? 'clickableAvatar' : 'avatar'}
          minWidth={size}
          minHeight={size}
          borderColor={pulseLevel ? PULSE_LEVELS[pulseLevel][1] : ''}
          style={{}}
          onClick={onClick}
        />
      ) : (
        <Card variant={variant || 'avatar'} minWidth={size} minHeight={size} />
      )}
      {!!badgeText && <Badge size={badgeSize || '20px'} text={badgeText} />}
    </Wrapper>
  );
};

export const AvatarWithProgress: FunctionComponent<{
  imageLink: string;
  pulseLevel: PulseIconNames;
  size?: string;
  value: number;
  onClick?: Function;
  loading?: boolean;
}> = ({ imageLink, pulseLevel, size, value, onClick, loading }) => {
  const [startColor, endColor] = PULSE_LEVELS[pulseLevel];

  return (
    <GradientCircularProgress
      value={value}
      strokeWidth={5}
      startColor={startColor}
      endColor={endColor}
      gradientId="gradient-profile-circle-picture"
    >
      {!loading ? (
        <>
          <Image
            src={displayLink(imageLink)}
            alt="user-avatar"
            variant={onClick ? 'clickableAvatar' : 'avatar'}
            width={size}
            minHeight={size}
            style={{
              textIndent: '100%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
            onClick={onClick}
          />
          <IconLevel>
            <Icon icon={pulseLevel} size="xl" />
          </IconLevel>
        </>
      ) : (
        <Spinner intent="primary" />
      )}
    </GradientCircularProgress>
  );
};

export default Avatar;
