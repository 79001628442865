import React from 'react';
import { Flex, Box } from 'theme-ui';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../hooks/useStore';
import { Checkbox, Text, Button } from '../../Primitives';
import { fromServer } from '../../../helpers/datetime';

type Props = {
  setStep: (step: number) => void;
  timezone: string;
};

const SelectSlots = observer(({ setStep, timezone }: Props) => {
  const { facilityBookingStore } = useStores();
  const {
    reschedulableBookings,
    selectedLineItems,
    toggleSelectedLineItem,
  } = facilityBookingStore;

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        flex: 1,
        mx: [0, '-16px'],
      }}
    >
      {reschedulableBookings.map((booking, index) => {
        const date = fromServer(
          booking.facilityBooking?.timeRange[0],
          timezone,
          'EEE d, MMM y'
        );
        const prevDate =
          index > 0
            ? fromServer(
                reschedulableBookings[index - 1].facilityBooking?.timeRange[0],
                timezone,
                'EEE d, MMM y'
              )
            : null;
        const start = fromServer(
          booking.facilityBooking?.timeRange[0],
          timezone,
          'h:mm a'
        );
        const end = fromServer(
          booking.facilityBooking?.timeRange[1],
          timezone,
          'h:mm a'
        );
        return (
          <React.Fragment key={booking.id}>
            {date !== prevDate && (
              <>
                <Text variant="lb.md" px="3" mt="3" textTransform="uppercase">
                  {date}
                </Text>
                <Box variant="line" mt="2" />
              </>
            )}
            <Flex
              sx={{
                justifyContent: 'space-between',
                p: 3,
                bg: 'white',
              }}
            >
              <Box>
                <Text variant="pg.md">
                  {booking.facilityBooking.facility.name}
                </Text>
                <Text variant="pg.md">
                  {start} - {end}
                </Text>
                <Text variant="lb.md">
                  {booking.price.price === 0
                    ? 'Free'
                    : `${booking.price.price}  ${booking.price.currency}`}
                </Text>
              </Box>
              <Checkbox.Circle
                sx={{
                  minWidth: '24px',
                  maxWidth: '24px',
                }}
                readOnly
                checked={selectedLineItems.some(
                  (item) => item.id === booking.id
                )}
                onClick={() => toggleSelectedLineItem(booking)}
              />
            </Flex>
            <Box variant="line" />
          </React.Fragment>
        );
      })}
      {!!selectedLineItems.length && (
        <Flex
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            px: 3,
            py: 2,
            bg: 'primary',
            mt: 'auto',
            position: 'sticky',
            bottom: '0px',
          }}
        >
          <Text variant="lb.md" color="white">
            {selectedLineItems.length > 1
              ? `${selectedLineItems.length} slots selected`
              : '1 slot selected'}
          </Text>
          <Button onClick={() => setStep(2)}>
            <Text variant="lb.md">Next</Text>
          </Button>
        </Flex>
      )}
    </Flex>
  );
});

export default SelectSlots;
