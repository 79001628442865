import styled from '../../theme/styled';
import { Flex } from 'rebass';

export const FeatureCard = styled(Flex)`
  padding-top: 32px;
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
