import React, { useState, useEffect } from 'react';
import { Box, Flex, Spinner } from 'theme-ui';

import { Text } from '../Primitives';
import styled from '../../theme/styled';
import Footer from '../Layout/Footer';
import { IPeople } from '../../interface/People';

import { getSuggestFollows } from '../../api/UtilsAPI';
import LocalStorageHelper from '../../helpers/localStorage';
import { PeopleCard } from '../Cards';

const Wrapper = styled(Flex)`
  display: none;
  @media (min-width: 1024px) {
    display: flex;
    flex-grow: 1;
    height: 100vh;
    position: sticky;
    top: 0;
    width: 100%;
    padding-left: 16px;
  }
`;

const FollowList: React.FC = () => {
  const [peopleToFollow, setPeopleToFollow] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getFollows() {
      try {
        setLoading(true);
        const people = await getSuggestFollows();
        setPeopleToFollow(people || []);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    }
    const account = LocalStorageHelper.get('currentUser');
    if (account?.session) {
      getFollows();
    }
  }, []);

  return (
    <Wrapper pt="24px">
      <Flex
        sx={{
          width: '100%',
          flexDirection: 'column',
        }}
      >
        {!loading && !!peopleToFollow.length && (
          <>
            <Text variant="lb.lg" mb="3">
              People to follow
            </Text>
            <Box p="0" mb="3">
              {loading ? (
                <Box p="3">
                  <Spinner color="primary" size="16px" />
                </Box>
              ) : (
                peopleToFollow.map((people: IPeople) => (
                  <Box
                    sx={{
                      ':not(:last-child)': {
                        mb: 3,
                      },
                    }}
                    key={people.id}
                  >
                    <PeopleCard person={people} />
                  </Box>
                ))
              )}
            </Box>
          </>
        )}
        <Footer />
      </Flex>
    </Wrapper>
  );
};

export default FollowList;
