import React, { useMemo } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { secondaryText, primaryText, danger } from '../../theme/colors';
import { Text, Box, Flex, Label } from 'theme-ui';
import { Icon } from '../Primitives';
import cogoToast from 'cogo-toast';
import { checkOrderTransactionStatus } from '../../api/VenueAPI';

const CreditCard = React.forwardRef(
  (
    props: {
      onMakePayment: (
        token: string
      ) => Promise<void | {
        clientSecret: string;
        orderId: string;
        id: string;
      } | null>;
      onSuccess: Function;
      onFailed: Function;
      hasLabel?: boolean;
      skipCheckOrderStatus?: boolean;
    },
    ref: any
  ) => {
    const stripe = useStripe();
    const elements = useElements();

    const {
      onMakePayment,
      onFailed,
      onSuccess,
      hasLabel,
      skipCheckOrderStatus,
    } = props;

    const options = useMemo(
      () => ({
        style: {
          base: {
            fontSize: '16px',
            fontWeight: '500',
            color: primaryText,
            fontFamily: 'Roboto, sans-serif',
            '::placeholder': {
              color: secondaryText,
            },
          },
          invalid: {
            color: danger,
          },
        },
      }),
      []
    );

    const handleSubmit = async (event: React.FormEvent) => {
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }
      const card = elements.getElement(CardNumberElement);
      if (card) {
        const { token, error } = await stripe.createToken(card);
        if (token?.id) {
          const data = await onMakePayment(token.id);
          if (data) return check3DSecure(data);
        } else {
          cogoToast.error(error?.message);
          onFailed();
        }
      }
    };

    async function check3DSecure({
      clientSecret,
      orderId,
      id,
    }: {
      clientSecret: string;
      orderId: string;
      id: string;
    }) {
      if (clientSecret && stripe) {
        const { error } = await stripe.confirmCardPayment(clientSecret);
        if (error) {
          return onFailed(error.message);
        }
        if (!skipCheckOrderStatus) {
          await checkOrderTransactionStatus(orderId, id);
        }
        return onSuccess(id);
      }
    }

    return (
      <form onSubmit={handleSubmit} ref={ref}>
        <Box
          sx={{
            flex: '1',
          }}
        >
          {hasLabel && <Label mb="2">Card number</Label>}
          <Box
            sx={{
              px: 3,
              py: '12px',
            }}
            variant="cardBox"
            mb="2"
          >
            <CardNumberElement options={options} />
          </Box>
        </Box>
        <Flex mx={-1}>
          <Box
            mx={1}
            sx={{
              flex: '1',
            }}
          >
            {hasLabel && <Label mb="2">Expiry</Label>}
            <Box
              variant="cardBox"
              sx={{
                px: 3,
                py: '12px',
              }}
            >
              <CardExpiryElement options={options} />
            </Box>
          </Box>
          <Box
            mx={1}
            sx={{
              flex: '1',
            }}
          >
            {hasLabel && <Label mb="2">CVC</Label>}
            <Box
              variant="cardBox"
              sx={{
                px: 3,
                py: '12px',
              }}
            >
              <CardCvcElement options={options} />
            </Box>
          </Box>
        </Flex>
        <Flex
          sx={{
            alignItems: 'center',
          }}
          mt="2"
        >
          <Icon icon="shield" size="lg" />
          <Text variant="body" ml="2">
            100% Secure AES-256 encrypted payment
          </Text>
        </Flex>
      </form>
    );
  }
);

export default CreditCard;
