import React from 'react';
import { Box, Flex } from 'rebass';
import { Spinner } from '@blueprintjs/core';
import { DateTime } from 'luxon';

import { ChatInput } from '../../UI/Input';
import Text from '../../UI/Text';
import Avatar from '../../UI/Avatar';

import { IMessage } from '../../../interface/Inbox';

import { fromServer } from '../../../helpers/datetime';

import MessageBubbleCard from './MessageBubbleCard';

import { ChatBodyWrapper } from '../GroupInboxDetail.styled';

interface Props {
  messages: IMessage[];
  profileId: number;
  loading: boolean;
  getMore: () => void;
  onSendTextMessage: (text: string) => void;
  onSendImageMessage: (image: File) => void;
  updateGameInvite: (
    messageId: number,
    playId: string,
    status: 'DECLINED' | 'ACCEPTED'
  ) => void;
}

class ChatBody extends React.Component<Props> {
  state: { message: string } = { message: '' };

  body: HTMLElement | null = null;

  isUpdatedScroll = false;

  componentDidUpdate() {
    if (this.body) {
      if (
        this.body.scrollTop !== this.body.scrollHeight &&
        !this.isUpdatedScroll
      ) {
        this.body.scrollTop = this.body.scrollHeight;
        this.isUpdatedScroll = true;
      }
    }
  }

  onSetContainer = (ref: HTMLElement) => {
    this.body = ref;
  };

  onLoadMore = () => {
    const { getMore } = this.props;
    getMore();
  };

  onChangeMessage = (message: string) => {
    this.setState({ message });
  };

  sendTextMessage = async (text: string) => {
    const { onSendTextMessage } = this.props;
    await onSendTextMessage(text);
    if (this.body && this.body.scrollTop !== this.body.scrollHeight) {
      this.body.scrollTop = this.body.scrollHeight;
    }
  };

  sendImageMessage = async (image: File) => {
    const { onSendImageMessage } = this.props;
    await onSendImageMessage(image);
    if (this.body && this.body.scrollTop !== this.body.scrollHeight) {
      this.body.scrollTop = this.body.scrollHeight;
    }
  };

  renderMessages = () => {
    const { messages, profileId, updateGameInvite } = this.props;
    return messages.map((m, index) => {
      const { updatedAt, senderId, sender, type } = m;
      const prevUpdatedAt = messages[index - 1]?.updatedAt;
      const prevSenderId = messages[index - 1]?.senderId;
      const showShowAvatar =
        prevSenderId !== senderId && senderId !== profileId;

      if (type === 'SYSTEM')
        return (
          <React.Fragment key={m.id}>
            {!DateTime.fromISO(updatedAt)
              .setZone('local')
              .hasSame(
                DateTime.fromISO(prevUpdatedAt).setZone('local'),
                'day'
              ) && (
              <Text
                alignSelf="center"
                textTransform="uppercase"
                fontWeight="bold"
                mt="3"
                mb="2"
              >
                {fromServer(updatedAt, '', 'EEE, MMM dd')}
              </Text>
            )}
            <Text alignSelf="center" my="1">
              {m.content.text}
            </Text>
          </React.Fragment>
        );

      const { picture, pulseLevel } = sender;
      return (
        <React.Fragment key={m.id}>
          {!DateTime.fromISO(updatedAt)
            .setZone('local')
            .hasSame(
              DateTime.fromISO(prevUpdatedAt).setZone('local'),
              'day'
            ) && (
            <Text
              alignSelf="center"
              textTransform="uppercase"
              fontWeight="bold"
              mt="3"
              mb="2"
            >
              {fromServer(updatedAt, '', 'EEE, MMM dd')}
            </Text>
          )}
          <Flex width="100%" alignItems="center" flex="none" mt="2">
            <Avatar
              imageLink={showShowAvatar ? picture : ''}
              pulseLevel={pulseLevel}
            />
            <MessageBubbleCard
              message={m}
              isSelf={m.senderId === profileId}
              profileId={profileId}
              updateGameInvite={updateGameInvite}
            />
          </Flex>
        </React.Fragment>
      );
    });
  };

  render() {
    const { loading } = this.props;
    const { message } = this.state;
    return (
      <>
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          height="calc(100vh - 106px)"
        >
          <Box alignSelf="center" mt="3">
            {loading && <Spinner size={24} intent="primary" />}
          </Box>
          <ChatBodyWrapper
            containerRef={this.onSetContainer}
            onScrollY={() => {
              if (this.body?.scrollTop && this.body.scrollTop < 100) {
                this.body.scrollTop = 110;
                this.onLoadMore();
              }
            }}
          >
            {this.renderMessages()}
          </ChatBodyWrapper>
        </Flex>
        <Box p="2" mt="auto" bg="white">
          <ChatInput
            value={message}
            onChange={this.onChangeMessage}
            hasLikeEmoji
            onEnter={this.sendTextMessage}
            onSelectImage={this.sendImageMessage}
          />
        </Box>
      </>
    );
  }
}

export default ChatBody;
