import React from 'react';
import { Box, Flex } from 'rebass';
import { useHistory } from 'react-router-dom';

import Text from '../../UI/Text';

import { IGroupProfile } from '../../../interface/Group';

interface Props {
  groupProfile: IGroupProfile;
  groupId: number;
}

const GroupInboxCard: React.FC<Props> = ({ groupProfile, groupId }) => {
  const history = useHistory();

  return (
    <Box p="2" minWidth={100 / 3 + '%'} maxWidth={100 / 3 + '%'}>
      <Flex
        key={groupId}
        variant="card"
        p="0"
        flexDirection="column"
        sx={{
          cursor: 'pointer',
        }}
        onClick={() => history.push(`/inbox/g/${groupId}`)}
      >
        <Box
          sx={{
            backgroundImage: `url(${groupProfile?.picture})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            position: 'relative',
            borderTopLeftRadius: '6px',
            borderTopRightRadius: '6px',
          }}
          height="150px"
          width="100%"
        ></Box>
        <Box variant="line" />
        <Text
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            borderBottomLeftRadius: '6px',
            borderBottomRightRadius: '6px',
          }}
          color="primaryText"
          bg="white"
          p="2"
          fontWeight="bold"
          fontSize="14px"
        >
          {groupProfile.name}
        </Text>
      </Flex>
    </Box>
  );
};

export default GroupInboxCard;
