import React from 'react';
import { Flex } from 'rebass';

import Text from '../UI/Text';
import Link from '../UI/Link';
import { Icon } from '../Primitives';

import { MENU_SETTINGS } from '../../constant/settings';
import { HeaderMobile } from '../Layout/Header';
import styled from '../../theme/styled';
import { observer } from 'mobx-react-lite';
import { userStore } from '../../stores/UserStore';

const Wrapper = styled.div`
  border-right: ${(p) => p.theme.border.box};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    display: flex;
    height: 100vh;
    overflow: auto;
    position: sticky;
    top: 0;
  }
`;

const SettingTitle = styled(Text)`
  border-bottom: ${(p) => p.theme.border.box};
`;

const SettingItem = styled(Flex)`
  border-bottom: ${(p) => p.theme.border.box};
  cursor: pointer;
  :hover {
    background-color: ${(p) => p.theme.colors.primaryHover};
  }
`;

const Logout = styled(Text)`
  border-bottom: ${(p) => p.theme.border.box};
  border-top: ${(p) => p.theme.border.box};
  :hover {
    background-color: ${(p) => p.theme.colors.dangerHover};
  }
  cursor: pointer;
`;

type SettingProps = {
  text: string;
  child: {
    link: string;
    isExtend?: boolean;
    text: string;
  }[];
};

const SettingsMenu: React.FC = observer(() => {
  const { onSignOut, account } = userStore;

  const handleSignOut = async () => {
    await onSignOut();
  };

  return (
    <Wrapper>
      <HeaderMobile
        text="Settings"
        hasBackBtn
        backLink={
          account?.profile?.username
            ? `/${account.profile.username}`
            : '/explore'
        }
      />
      {MENU_SETTINGS.map((setting: SettingProps) => (
        <Flex flexDirection="column" key={setting.text}>
          <SettingTitle textTransform="uppercase" fontWeight="bold" p="3">
            {setting.text}
          </SettingTitle>
          {setting.child.map((c) => (
            <Link to={c.link} key={c.text} isExtend={c.isExtend}>
              <SettingItem
                px="3"
                py={[2, 2]}
                alignItems="center"
                justifyContent="space-between"
              >
                <Text color="primaryText">{c.text}</Text>
                {!c.isExtend && (
                  <Icon icon="chevron-right" size="lg" fill="primaryText" />
                )}
              </SettingItem>
            </Link>
          ))}
        </Flex>
      ))}
      <Logout my="3" px="3" py={[2, 2]} color="danger" onClick={handleSignOut}>
        Log out
      </Logout>
    </Wrapper>
  );
});

export default SettingsMenu;
