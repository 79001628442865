import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Box, Flex } from 'theme-ui';

import { Icon, Text, Button } from '../Primitives';

import DummyImg from '../../assets/dummy-img.png';

import { IGroup } from '../../interface/Group';

import { userStore } from '../../stores/UserStore';
import { groupStore } from '../../stores/GroupStore';

type Props = {
  group: IGroup;
};

const GroupCard: FunctionComponent<Props> = observer(({ group }) => {
  const { joinGroup } = groupStore;
  const { account } = userStore;
  const history = useHistory();

  const {
    members,
    groupProfile: { sport, picture, name, privacy, uri, location },
  } = group;

  const isPublic = privacy === 'PUBLIC';
  const self = members.find((m) => m.profileId === account.profileId);
  const isRequested = self?.status === 'PENDING' ? true : false;
  const isApproved = self?.status === 'ACTIVE';
  return (
    <Box variant="card" p="0" onClick={() => history.push(`/groups/${uri}`)}>
      <Flex
        sx={{
          backgroundImage: `url(${picture || DummyImg})`,
          width: '100%',
          height: '152px',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: '50%',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        }}
      >
        <Text
          variant="lb.sm"
          color="white"
          sx={{
            textShadow: 'below.tiny',
            alignSelf: ' flex-end',
            p: 2,
          }}
        >
          {sport}
        </Text>
      </Flex>
      <Box p="3">
        <Text truncate variant="lb.md" mb="1">
          {name}
        </Text>
        <Flex
          sx={{
            alignItems: 'center',
          }}
        >
          {!isPublic ? (
            <>
              <Icon icon="lock-alt" />
              <Text ml="1">Private group</Text>
            </>
          ) : (
            <Text>
              {members.length > 1 ? `${members.length} players` : '1 player'}
            </Text>
          )}
        </Flex>
        <Text ml="2">{location?.name}</Text>
      </Box>
      <Box px="3" pb="3">
        <Button
          sx={{
            width: '100%',
          }}
          variant="outline"
          disabled={isApproved || isRequested}
          onClick={(e) => {
            e.stopPropagation();
            joinGroup(group.id);
          }}
        >
          {isRequested ? 'Requested' : isApproved ? 'Joined' : 'Join'}
        </Button>
      </Box>
    </Box>
  );
});

export default GroupCard;
