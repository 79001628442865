import React, { FunctionComponent } from 'react';
import { Box } from 'theme-ui';

import Navigation from '../Layout/Navigation';
import FollowList from '../Explore/FollowList';
import FeedBody from './FeedBody';
import Header from '../Layout/Header';

type Props = {};

const FeedPage: FunctionComponent<Props> = () => (
  <Box variant="layout.main">
    <Header name="Feed" />
    <Box variant="layout.leftSide">
      <Navigation selected="feed" />
    </Box>
    <FeedBody />
    <Box variant="layout.rightSide">
      <FollowList />
    </Box>
  </Box>
);

export default FeedPage;
