import { activityStore } from './ActivityStore';
import { groupStore } from './GroupStore';
import { ISession } from './../interface/Home';
import { observable, action, runInAction } from 'mobx';
import { getSelfHomeData } from '../api/ProfileAPI';
class HomeStore {
  @observable
  sessions: ISession[] = [];

  @observable
  state: 'INITIAL' | 'LOADING' | 'DONE' | 'ERROR' = 'INITIAL';

  @action
  getHomeData = async () => {
    this.state = 'LOADING';
    try {
      const sessions: ISession[] = await getSelfHomeData();
      runInAction(() => {
        this.sessions = sessions;
        sessions.forEach((session) => {
          switch (session.type) {
            case 'GROUP':
              return groupStore.setGroups(session.items);
            case 'PLAY':
              if (session.title === 'Activities nearby') {
                return activityStore.setActivities(session.items);
              } else {
                return activityStore.setParties(session.items);
              }
            default:
              break;
          }
        });
        this.state = 'DONE';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'ERROR';
      });
    }
  };
}

export const homeStore = new HomeStore();
