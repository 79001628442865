import styled from '../../theme/styled';
import { keyframes } from '@emotion/core';
import { Text } from 'theme-ui';

const head = keyframes`
    0% {opacity:1;}
    33% { opacity:0;}
    33% { opacity:1; content: "Welcome to your sports & fitness community."}
    66% { opacity:0;}
    66% { opacity:1; content: "Whatever your sport, do more of it with Rovo."}
    100% { opacity:0;}
    100% {opacity:1; content: 'Meet & play with likeminded sports people like you'}
`;

export const TitleText = styled(Text)`
  ::before {
    content: 'Meet & play with likeminded sports people like you';
    animation-name: ${head};
    animation-duration: 18s;
    animation-iteration-count: infinite;
  }
`;
