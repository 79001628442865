import React, { FunctionComponent } from 'react';
import { Flex, Image } from 'rebass';

import EmptyIcon from '../../assets/empty-slot-icon.png';

import Text from '../UI/Text';

type Props = {
  text: string;
};

const EmptyList: FunctionComponent<Props> = ({ text }) => {
  return (
    <Flex flexDirection="column" alignItems="center" mt="5">
      <Image maxWidth="124px" alt="empty-icon" src={EmptyIcon} />
      <Text
        mt="3"
        color="secondaryText">{`Seems like there are no ${text} at the moment.`}</Text>
    </Flex>
  );
};

export default EmptyList;
