import React, { FunctionComponent, Dispatch, SetStateAction } from 'react';
import { Flex, Button } from 'rebass';
import { useMediaQuery } from 'react-responsive';

import Text from '../../UI/Text';
import { Icon } from '../../Primitives';

import { Menu, Item } from './SearchField.styled';

import { useLockBodyScroll } from '../../../hooks/useLockBodyScroll';

const RecentSearchBox: FunctionComponent<{
  setMenuStatus: Dispatch<SetStateAction<boolean>>;
  recentSearchs: string[];
  clearRecentSearchs: Function;
  removeRecentSearch: Function;
  handleSearch: Function;
}> = ({
  recentSearchs,
  clearRecentSearchs,
  removeRecentSearch,
  handleSearch,
}) => {
  const isLockScroll = useMediaQuery({ query: '(min-width: 768px)' });

  useLockBodyScroll(isLockScroll);

  return (
    <Menu>
      <Item width="100%">
        {recentSearchs.length ? (
          <>
            <Text textTransform="uppercase" color="primaryText" fontSize="16px">
              Recent searchs
            </Text>
            <Button
              p="1"
              variant="transparent"
              fontWeight="bold"
              onClick={clearRecentSearchs}
            >
              Clear
            </Button>
          </>
        ) : (
          <Text color="primaryText" fontSize="16px">
            No recent search
          </Text>
        )}
      </Item>
      {!!recentSearchs.length &&
        recentSearchs.map(text => (
          <Item
            key={text}
            onClick={() => {
              handleSearch(text);
            }}
          >
            <Flex alignItems="center">
              <Icon icon="search" size="lg" />
              <Text ml="2">{text}</Text>
            </Flex>
            <Icon
              icon="close"
              onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
                event.stopPropagation();
                removeRecentSearch(text);
              }}
            />
          </Item>
        ))}
    </Menu>
  );
};

export default RecentSearchBox;
