import httpService from '../services/httpService';
import get from 'lodash/get';

export const getSports = async () => {
  try {
    const data = await httpService.get('sports');
    const sports = get(data, 'data.data', []);
    return sports;
  } catch (err) {
    throw err;
  }
};

export const getSportAssessment = async (sportId: string) => {
  try {
    const response = await httpService.get(`sports/${sportId}/assessment`);
    const assessment = get(response, 'data', []);
    return assessment;
  } catch (err) {
    throw err;
  }
};

export const getSuggestFollows = async () => {
  const params = {
    offset: 0,
    limit: 3,
    includeTeamSports: true,
  };
  try {
    const res = await httpService.get('me/suggestions/profiles', {
      params,
    });
    return res.data.profiles;
  } catch (err) {
    throw err;
  }
};

export const getCurrencies = async () => {
  try {
    const response = await httpService.get('currencies');
    return response.data.data;
  } catch (err) {
    throw err;
  }
};

export const getBanners = async () => {
  try {
    const response = await httpService.get('banners');
    return response?.data?.banners;
  } catch (error) {
    throw error;
  }
};

export const getSubscriptions = async () => {
  try {
    const response = await httpService.get('subscriptions');
    return response.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getPaymentMethodsOfCurrency = async (currency: string) => {
  try {
    const response = await httpService.get(`orders/paymentMethods/${currency}`);
    return response.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getPremiumCatalog = async () => {
  try {
    const response = await httpService.get('orders/subscriptions/catalog');
    return response.data?.data;
  } catch (error) {
    throw error;
  }
};

export const dryrunPremiumOrder = async (premiumRequest: {
  lineItems: {
    productType: string;
    productId: string;
  }[];
  customerInfo?: {
    firstName?: string;
    lastName?: string;
    email?: string;
  };
  currency: string;
}) => {
  try {
    const response = await httpService.post('orders/dryrun', {
      ...premiumRequest,
      paymentInfo: {
        paymentMethod: 'creditCard',
      },
    });
    return response.data?.data;
  } catch (error) {
    throw error;
  }
};

export async function purchasePremium(
  token: string,
  customerInfo: {
    firstName?: string;
    lastName?: string;
    email: string;
    mobile?: string;
  },
  productId: string,
  productType: string,
  promoCode: string,
  currency: string
) {
  try {
    const response = await httpService.post('orders', {
      paymentInfo: {
        paymentMethod: 'creditCard',
        creditCardTokenInfo: {
          token,
        },
      },
      customerInfo,
      lineItems: [
        {
          productType,
          productId,
        },
      ],
      promoCode,
      currency,
    });
    return response.data?.data;
  } catch (error) {
    throw error;
  }
}

export const cancelSubscription = async (
  subscriptionId: string,
  reason: string
) => {
  try {
    const response = await httpService.post(
      `subscriptions/${subscriptionId}/cancel`,
      { reason }
    );
    return response.data?.ok;
  } catch (error) {
    throw error;
  }
};

export const uncanceledSubscription = async (subscriptionId: string) => {
  try {
    const response = await httpService.post(
      `subscriptions/${subscriptionId}/uncancel`
    );
    return response.data?.ok;
  } catch (error) {
    throw error;
  }
};
