import React from 'react';
import { Flex, Box } from 'theme-ui';
import { observer } from 'mobx-react-lite';
import cogoToast from 'cogo-toast';
import { DateTime } from 'luxon';

import { useStores } from '../../../hooks/useStore';
import { Text, Button } from '../../Primitives';
import { ISelectedSlot } from '../RescheduleBooking';
import { toServer } from '../../../helpers/datetime';

type Props = {
  onClose: () => void;
  timezone: string;
  selectedSlots: ISelectedSlot[];
};

const ReviewSlots = observer(({ onClose, timezone, selectedSlots }: Props) => {
  const { facilityBookingStore } = useStores();
  const { rescheduleFacilityBookings, state } = facilityBookingStore;

  const handleReschedule = async () => {
    const toFacilityBookings = selectedSlots.map(slot => ({
      facilityId: slot.facilityId,
      startTime: toServer(
        `${slot.date} ${slot.start}`,
        'yyyy-MM-dd h:mm',
        timezone
      ),
      endTime: toServer(
        `${slot.date} ${slot.end}`,
        'yyyy-MM-dd h:mm',
        timezone
      ),
    }));
    try {
      await rescheduleFacilityBookings(toFacilityBookings);
      onClose();
      cogoToast.success('Reschedule slots successfully');
    } catch (error) {
      const message =
        error.response.data.msg ||
        'Reschedule failed. Please contact your venue.';
      cogoToast.error(message);
    }
  };

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        flex: 1,
        mx: [0, '-16px'],
      }}
    >
      {selectedSlots.map((slot, index) => {
        const { start, end, date, name, price } = slot;
        const prevDate = index > 0 ? selectedSlots[index - 1].date : null;
        const displayDate = DateTime.fromISO(slot.date)
          .toFormat('EEE dd, MMM y')
          .toUpperCase();
        return (
          <React.Fragment key={slot.facilityId + slot.start + slot.end}>
            {date !== prevDate && (
              <>
                <Text variant="lb.md" px="3" mt="3" textTransform="uppercase">
                  {displayDate}
                </Text>
                <Box variant="line" mt="2" />
              </>
            )}
            <Flex
              sx={{
                justifyContent: 'space-between',
                p: 3,
                bg: 'white',
              }}
            >
              <Box>
                <Text variant="pg.md">{name}</Text>
                <Text variant="pg.md">
                  {start} - {end}
                </Text>
                <Text variant="lb.md">
                  {price?.price === 0 && 'Free'}
                  {!!price &&
                    price.price > 0 &&
                    `${price.price} ${price.currency}`}
                </Text>
              </Box>
            </Flex>
            <Box variant="line" />
          </React.Fragment>
        );
      })}
      <Flex
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          p: 3,
          bg: 'primary',
          mt: 'auto',
          position: 'sticky',
          bottom: '0px',
        }}
      >
        <Button
          loading={state === 'loading'}
          disabled={state === 'loading'}
          variant="transparent"
          onClick={handleReschedule}
        >
          <Text variant="lb.md">Confirm</Text>
        </Button>
      </Flex>
    </Flex>
  );
});

export default ReviewSlots;
