import React, { useEffect } from 'react';
import { Button, Flex } from 'rebass';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react-lite';

import Text from '../../UI/Text';
import { Icon } from '../../Primitives';

import { settingStore, INotification } from '../../../stores/SettingStore';

import { HeaderMobile } from '../../Layout/Header';

import styled from '../../../theme/styled';

const Title = styled(Text)`
  border-bottom: ${p => p.theme.border.box};
  background-color: ${p => p.theme.colors.gray};
`;

const ChildItem = styled(Flex)`
  border-bottom: ${p => p.theme.border.box};
`;

const NotificationItem: React.FC<{
  item: INotification;
  updateNotificationPreferences: Function;
}> = ({ item, updateNotificationPreferences }) => {
  const { title, preferences } = item;

  const onToggle = (pIndex: number, oIndex: number, isEnabled: boolean) => {
    const cloneItem = { ...item };
    cloneItem.preferences[pIndex].options[oIndex].value = isEnabled
      ? 'off'
      : 'on';
    updateNotificationPreferences(cloneItem);
  };

  return (
    <Flex flexDirection="column">
      <Title
        textTransform="uppercase"
        fontWeight="bold"
        fontSize={['12px', '14px']}
        p={[2, 3]}
      >
        {title}
      </Title>
      {preferences.map((c, pIndex) => (
        <ChildItem key={c.id} flexDirection="column" p={[2, 3]}>
          <Text color="primaryText" fontWeight="bold">
            {c.title}
          </Text>
          <Text color="primaryText" mb="2">
            {c.subtitle}
          </Text>
          <Flex alignItem="center">
            {c.options.map((option, oIndex) => {
              const isEnabled = option.value === 'on';
              return (
                <Button
                  key={option.id}
                  onClick={() => onToggle(pIndex, oIndex, isEnabled)}
                  variant={isEnabled ? 'primary' : 'outline'}
                  mr="2"
                  width="90px"
                  height="40px"
                >
                  <Flex alignItems="center" justifyContent="center">
                    {isEnabled && <Icon icon="ok" stroke="white" size="sm" />}
                    <Text ml={isEnabled ? '2' : '0'}>{option.name}</Text>
                  </Flex>
                </Button>
              );
            })}
          </Flex>
        </ChildItem>
      ))}
    </Flex>
  );
};

const Notifications: React.FC = observer(() => {
  const {
    notificationPreferences,
    getNotificationPreferences,
    updateNotificationPreferences,
  } = settingStore;
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  useEffect(() => {
    getNotificationPreferences();
  }, [getNotificationPreferences]);

  return (
    <Flex flexDirection="column">
      <HeaderMobile
        text="Notifications prefercences"
        hasBackBtn={isMobile}
        backLink="/settings"
      />
      {notificationPreferences.map((item: INotification) => (
        <NotificationItem
          key={item.id}
          item={item}
          updateNotificationPreferences={updateNotificationPreferences}
        />
      ))}
    </Flex>
  );
});

export default Notifications;
