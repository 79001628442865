import React, { useState, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Box, Flex } from 'theme-ui';

import { Text, Button } from '../../Primitives';

import { ReactComponent as LogoFullTextColor } from '../../../assets/logo-full-text-color.svg';

import { useOnClickOutside } from '../../../hooks/useOuterClick';

import ConfigLink, { ExtendLink } from '../../UI/Link';
import { Icon } from '../../Primitives';

import {
  Header,
  HeaderItem,
  MobileMenu,
  MobileHeaderItem,
  FooterMobileItem,
} from './styled';
import { useHistory } from 'react-router-dom';

const UnAuthHeader = ({ isWhiteHeader }: { isWhiteHeader?: boolean }) => {
  const [isExpand, setExpand] = useState(false);

  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });

  const menuRef = useRef<HTMLDivElement>(null);

  const history = useHistory();

  useOnClickOutside(menuRef, () => setExpand(false));

  return (
    <Header
      px={['3', '4']}
      sx={{
        height: '60px',
        borderBottom: isWhiteHeader
          ? '1px solid #D1DCE8'
          : '1px solid transparent',
        backgroundColor: isWhiteHeader ? 'white' : 'transparent',
        zIndex: 2,
      }}
    >
      <ConfigLink to="/">
        <LogoFullTextColor height="32px" />
      </ConfigLink>

      {isDesktop ? (
        <>
          <ConfigLink to="/explore">
            <HeaderItem variant="lb.md">Explore</HeaderItem>
          </ConfigLink>
          <HeaderItem variant="lb.md">
            <ConfigLink to="https://business.rovo.co/" isExtend>
              Business
            </ConfigLink>
          </HeaderItem>
          <ConfigLink to="/features">
            <HeaderItem variant="lb.md">Features</HeaderItem>
          </ConfigLink>
          <Box mx="auto" />
          <Flex
            sx={{
              width: [1, 'initial'],
              justifyContent: ['flex-end', 'flex-start'],
            }}
            mt={[2, 0]}
          >
            <ConfigLink to="/sign-up">
              <Button size="md">
                <Text variant="lb.md" color="white">
                  Sign up
                </Text>
              </Button>
            </ConfigLink>
            <ConfigLink to="/sign-in">
              <Button variant="outline" size="md" ml="3">
                <Flex>
                  <Icon icon="user" size="md" />
                  <Text ml="2" variant="lb.md" color="#0AC2CC">
                    Login
                  </Text>
                </Flex>
              </Button>
            </ConfigLink>
          </Flex>
        </>
      ) : (
        <Box
          ref={menuRef}
          sx={{
            cursor: 'pointer',
            display: 'flex',
          }}
          ml="auto"
        >
          <Icon
            onClick={() => setExpand((s) => !s)}
            icon={isExpand ? 'close' : 'hamburger'}
            fill="primaryText"
          />
          {isExpand && (
            <MobileMenu px="3">
              <Flex
                sx={{
                  justifyContent: 'space-between',
                  width: '100%',
                }}
                py="3"
              >
                <ConfigLink to="/">
                  <LogoFullTextColor height="32px" />
                </ConfigLink>
                <Icon
                  onClick={() => setExpand((s) => !s)}
                  icon={isExpand ? 'close' : 'hamburger'}
                  stroke="#222F3C"
                />
              </Flex>
              <ConfigLink to="/explore">
                <MobileHeaderItem variant="hd.md">Explore</MobileHeaderItem>
              </ConfigLink>
              <ConfigLink to="https://partners.rovo.co" isExtend>
                <MobileHeaderItem variant="hd.md">Business</MobileHeaderItem>
              </ConfigLink>
              <ConfigLink to="/features">
                <MobileHeaderItem variant="hd.md">Features</MobileHeaderItem>
              </ConfigLink>
              <Button
                sx={{
                  width: '100%',
                }}
                mb="24px"
                size="lg"
              >
                <ConfigLink to="/sign-up">
                  <Text variant="lb.md" color="white">
                    Sign Up
                  </Text>
                </ConfigLink>
              </Button>
              <Button
                variant="outline"
                sx={{
                  width: '100%',
                }}
                onClick={() => history.push('/sign-in')}
                size="lg"
              >
                <Flex
                  sx={{
                    justifyContent: 'center',
                  }}
                >
                  <Icon icon="user" size="md" />
                  <Text variant="lb.md" ml="2">
                    Login
                  </Text>
                </Flex>
              </Button>
              <Flex
                sx={{
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                }}
                mt="auto"
              >
                <ExtendLink to="https://page.rovo.co/about">
                  <FooterMobileItem variant="pg.xs">About</FooterMobileItem>
                </ExtendLink>
                <ExtendLink to="https://www.notion.so/rovo/Rovo-Careers-208a2e996fa642269e3be77ec6d39aa4">
                  <FooterMobileItem variant="pg.xs">Careers</FooterMobileItem>
                </ExtendLink>
                <ExtendLink to="https://page.rovo.co/about">
                  <FooterMobileItem variant="pg.xs">Press</FooterMobileItem>
                </ExtendLink>
                <ExtendLink to="https://blog.rovo.co">
                  <FooterMobileItem variant="pg.xs">Blog</FooterMobileItem>
                </ExtendLink>
                {/* <ExtendLink to="mailto:friends@rovo.co">
                  <FooterMobileItem variant="pg.xs">Contact</FooterMobileItem>
                </ExtendLink>
                <ExtendLink to="https://support.rovo.co">
                  <FooterMobileItem variant="pg.xs">Support</FooterMobileItem>
                </ExtendLink> */}
                <ExtendLink to="https://support.rovo.co">
                  <FooterMobileItem variant="pg.xs">Privacy</FooterMobileItem>
                </ExtendLink>
                <ExtendLink to="https://support.rovo.co">
                  <FooterMobileItem variant="pg.xs">
                    Terms & Conditions
                  </FooterMobileItem>
                </ExtendLink>
                <ExtendLink to="https://support.rovo.co">
                  <FooterMobileItem variant="pg.xs">
                    Community Guidelines
                  </FooterMobileItem>
                </ExtendLink>
              </Flex>
            </MobileMenu>
          )}
        </Box>
      )}
    </Header>
  );
};

export const UnAuthHeaderOnlyLogo = () => {
  return (
    <Header
      px={['3', '4']}
      sx={{
        height: '60px',
        bg: 'white',
        boxShadow: 'stroke.bottom',
        zIndex: 2,
      }}
    >
      <ConfigLink to="/">
        <LogoFullTextColor height="32px" />
      </ConfigLink>
    </Header>
  );
};

export default UnAuthHeader;
