import httpService from '../services/httpService';

export const getGroupByUri = async (groupUri: string) => {
  try {
    const response = await httpService.get(`groups/${groupUri}`);
    return response?.data?.group;
  } catch (error) {
    throw error;
  }
};

export const joinGroup = async (groupId: number) => {
  try {
    const response = await httpService.post(`groups/${groupId}/join`);
    return response?.data?.group;
  } catch (error) {
    throw error;
  }
};

export const leaveGroup = async (groupId: number) => {
  try {
    const response = await httpService.post(`groups/${groupId}/leave`);
    return response?.data?.group;
  } catch (error) {
    throw error;
  }
};
