import { PulseIconNames } from './../components/Primitives/Icon/iconTypes';
export type ISportProfile = {
  access: string;
  sport: string;
  gamesPlayed: number;
  status: string;
  level: string;
  rating: number;
  id: string;
  bio: string | null;
  assessmentPending: boolean;
  schedule: number;
  achievements: {
    achievementTypeId: string;
    count: number | null;
    desc: string;
    icon: string;
    id: string;
    learnMore: string;
    name: string;
    playFeedbackIds: string[];
  }[];
  assessment: {
    id: string;
    name: string;
    value: number;
  }[];
  sportInfo: {
    uri: string;
    id: string;
    name: string;
    images: {
      badge: string;
      banner: string;
      icon: string;
      iconGradient: string;
    };
    hasAssessment: boolean;
    assessmentEnabled: boolean;
  };
  preferences: any;
  sportPreferences: {
    id: string;
    attributes: {
      id: string;
      value: number;
      name: string;
    }[];
    title: string;
  }[];
};

export interface IPeople {
  id: number;
  city: string;
  firstName: string;
  lastName: string;
  createdAt: string;
  status: string;
  currency?: {
    code: string;
    id: string;
    name: string;
  };
  defaultCurrencyId?: string;
  sportProfiles: ISportProfile[];
  sportProfile?: ISportProfile;
  subscriptions?: string[];
  followSelfStatus?: string;
  followStatus?: string;
  picture: string;
  pictureLarge: string;
  followers: number;
  following: number;
  isPrivate: boolean;
  name: string;
  progress: number;
  pulseLevel: PulseIconNames;
  pulsePoints: number;
  username: string;
  email: string;
  gender?: 'male' | 'female';
  location: IUserLocation;
  filterLocations: IUserLocation[];
  country?: string;
}

export interface IFollower {
  approved: boolean;
  createdAt: string;
  followedId: string;
  followedType: string;
  follower: IPeople;
}

export interface ILiker {
  id: string;
  targetType: string;
  targetId: string;
  likerId: number;
  liker: IPeople;
}

export interface IFollowing {
  approved: boolean;
  createdAt: string;
  followedId: string;
  followedType: string;
  following: IPeople;
}

export interface IAccount {
  email?: string;
  emailVerified?: boolean;
  facebook?: {
    id: string;
    email: string;
    first_name: string;
    picture: {
      data: {
        url: string;
      };
    };
  };
  facebookId?: string;
  firebaseId?: string;
  freshchatId: string | null;
  id: number;
  phone?: string | number;
  phoneVerified?: boolean;
  profile: IPeople;
  profileId: number;
  referralLink: string;
  status: string;
  registeredAt: string;
}

export interface IUserLocation {
  id: string;
  name: string;
  latitude: string;
  longitude: string;
  city: string;
  placeId: string;
  venueDetail: Object | null;
  locality: null | string;
}

export interface IInvitePlayer {
  playId: string;
  profileId: number;
  status: string;
  role: string;
  reserved: boolean;
  inviterId: number;
  profile: IPeople;
}

export const defaultPeople: IPeople = {
  id: -1,
  city: '',
  firstName: '',
  lastName: '',
  createdAt: '',
  sportProfiles: [],
  picture: '',
  pictureLarge: '',
  followers: 0,
  following: 0,
  name: '',
  isPrivate: false,
  progress: 0,
  pulseLevel: 'SPARK',
  status: '',
  pulsePoints: 0,
  username: '',
  email: '',
  location: {
    id: '',
    name: '',
    latitude: '',
    longitude: '',
    city: '',
    placeId: '',
    venueDetail: null,
    locality: null,
  },
  filterLocations: [],
};
