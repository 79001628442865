import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { Box } from 'rebass';
import Geocode from 'react-geocode';

import Text from './Text';

import config from '../../config';

import styled from '../../theme/styled';

const Marker = styled(Box)`
  position: absolute;
  border-radius: 50% 50% 50% 0;
  border: 4px solid ${p => p.theme.colors.primary};
  width: 20px;
  height: 20px;
  transform: rotate(-45deg);
  ::after {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    margin-left: -5px;
    margin-top: -5px;
    background-color: ${p => p.theme.colors.primary};
  }
`;

const GoogleMap = () => {
  const [pinAddress, setPinAddress] = useState('');
  const [location, setLocation] = useState({ lat: 10.793742, lng: 106.723992 });

  const onClickMap = ({ lat, lng }: { lat: number; lng: number }) => {
    Geocode.fromLatLng(
      lat.toString(),
      lng.toString(),
      config.GOOGLE_MAP_API_KEY
    ).then(
      (response: {
        results: {
          formatted_address: string;
        }[];
      }) => {
        const address = response.results[0].formatted_address;
        if (address) {
          setLocation({ lat, lng });
          setPinAddress(address);
        }
      },
      (error: Error) => {
        console.error(error);
      }
    );
  };

  return (
    <>
      <Text>{pinAddress}</Text>
      <Box height="200px" width="100%">
        <GoogleMapReact
          bootstrapURLKeys={{ key: config.GOOGLE_MAP_API_KEY }}
          defaultCenter={{
            lat: 10.793742,
            lng: 106.723992,
          }}
          defaultZoom={15}
          zoom={15}
          onClick={({ lat, lng }) => onClickMap({ lat, lng })}
        >
          <Marker lat={location.lat} lng={location.lng} text="My Marker" />
        </GoogleMapReact>
      </Box>
    </>
  );
};

interface StaticMapProps {
  zoom?: number;
  size?: string;
  latitude: number | string;
  longitude: number | string;
}

export const StaticMap: React.FC<StaticMapProps> = ({
  latitude,
  longitude,
  zoom = 13,
  size = '640x200',
}) => {
  const latLongStr = `${latitude},${longitude}`;

  return (
    <a
      target="_blank"
      href={`https://www.google.com/maps/search/?api=1&query=${latLongStr}`}
      rel="noopener noreferrer"
    >
      <img
        alt="map"
        width="100%"
        src={`https://maps.googleapis.com/maps/api/staticmap?center=${latLongStr}&zoom=${zoom}&size=${size}&maptype=roadmap
&markers=icon:https://rovo.co/assets/locationpin_green.png%7C${latLongStr}&key=${config.GOOGLE_MAP_API_KEY}`}
      />
    </a>
  );
};

export default GoogleMap;
