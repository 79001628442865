import React, { useEffect, useMemo } from 'react';
import { Box } from 'theme-ui';

import { PageBody } from '../Layout/Layout.styled';
import FollowList from '../Explore/FollowList';
import Navigation from '../Layout/Navigation';
import PostDetailBody from './components/PostDetailBody';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../hooks/useStore';
import { useParams } from 'react-router-dom';
import { Instagram } from 'react-content-loader';

const PostDetailPage: React.FC = observer(() => {
  const { postDetailStore } = useStores();

  const { getPost, post, loadingData } = postDetailStore;

  const params: { activityId?: string } = useParams();

  const activityId = useMemo(() => params?.activityId, [params]);

  useEffect(() => {
    if (activityId) {
      getPost(activityId);
    }
  }, [activityId, getPost]);

  return (
    <Box
      variant="layout.main"
      sx={{
        gap: '0px',
      }}
    >
      <Box variant="layout.leftSide">
        <Navigation hideOnMobile />
      </Box>
      <PageBody>
        {!!post && <PostDetailBody item={post} />}
        {loadingData && <Instagram />}
      </PageBody>
      <Box variant="layout.rightSide">
        <FollowList />
      </Box>
    </Box>
  );
});

export default PostDetailPage;
