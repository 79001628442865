const cyan = [
  '#CEF9F9',
  '#A6EEF1',
  '#7FE5EA',
  '#5ADAE1',
  '#3BCFD6',
  '#0AC2CC', // Active Cyan
  '#1BA4AB',
  '#12848A',
  '#0B6268',
  '#043F43',
];

const green = [
  '#EDFBCC',
  '#DEF7A4',
  '#CFF27D',
  '#BFEB58',
  '#AEE234',
  '#9DD911', // Global Green
  '#83B70A',
  '#699405',
  '#4D6E01',
  '#324700',
];

const grayScale = [
  '#FFFFFF', // Community White
  '#FAFAFA',
  '#F5F8FA',
  '#E9EFF5', // Sportman Gray
  '#D1DCE8',
  '#C3D1DE',
  '#99ACBF',
  '#697B8C',
  '#3E4C59',
  '#222F3C', // Business Black
  '#000000',
];

const red = [
  '#FECFD6',
  '#FEAAB5',
  '#FD8696',
  '#FD6579',
  '#FD455C',
  '#FE223C', // Passionate Red
  '#CB243A',
  '#9C1F30',
  '#701925',
  '#451118',
];

const yellow = [
  '#FFF6D1',
  '#FFEEAF',
  '#FEE58D',
  '#FEDC6F',
  '#FED353',
  '#FDC83D', // Team Yellow
  '#CCA334',
  '#9D7E2B',
  '#715C20',
  '#463915',
];

const orange = [
  '#FFE9CD',
  '#FFD7A7',
  '#FFC582',
  '#FEB35E',
  '#FAA13C',
  '#FD9426', // Innovation Orange
  '#CF7612',
  '#A65D0B',
  '#794405',
  '#4D2B02',
];

const blue = [
  '#D6E5FF',
  '#B6D0FF',
  '#97BBFF',
  '#7AA7FC',
  '#5D93F9',
  '#4280F4', // Preserving Blue
  '#3369CD',
  '#2551A4',
  '#193B79',
  '#0F254D',
];

const purple = [
  '#E2DAF7',
  '#CABDEF',
  '#B3A1E7',
  '#9D85DE',
  '#876BD4',
  '#7151C9', // Playful Purple
  '#5C40A8',
  '#483187',
  '#342264',
  '#201540',
];

const magenta = [
  '#FCD1E9',
  '#F9AED7',
  '#F58CC5',
  '#EF6BB3',
  '#E84CA1',
  '#E02D8F', // Impact Magenta
  '#BD2176',
  '#97175E',
  '#710F45',
  '#49082B',
];

// Define Colors
export const black = grayScale[10];
export const white = grayScale[0];

// Brand Colors

export const activeCyan = cyan[5];
export const globalGreen = green[5];
export const communityWhite = grayScale[0];
export const sportmanGray = grayScale[3];
export const businessBlack = grayScale[9];
export const passionateRed = red[5];
export const teamYellow = yellow[5];
export const innovativeOrange = orange[5];
export const preservingBlue = blue[5];
export const playfulPurple = purple[5];
export const impactMagenta = magenta[5];

export const primary = activeCyan;
export const primaryHover = cyan[0];
export const primaryGreen = globalGreen;
export const primaryGreenHover = green[0];

export const primaryText = businessBlack;
export const primaryTextHover = grayScale[2];
export const secondaryText = grayScale[7];

export const border = grayScale[5];
export const divider = grayScale[4];
export const background = grayScale[1];
export const danger = passionateRed;
export const dangerHover = red[0];
export const warning = teamYellow;
export const warningHover = yellow[0];
export const success = preservingBlue;
export const successHover = blue[0];

export const disabledButton = grayScale[2];

// Solid colors
export const primaryDisabled = cyan[1];
export const primaryFocused = cyan[6];
export const primaryPressed = cyan[7];

export const primaryGreenDisabled = green[1];
export const primaryGreenFocused = green[6];
export const primaryGreenPressed = green[7];

// Neutral colors
export const dark1 = '#212F3D';
export const dark2 = '#454851';
export const dark3 = '#667190';
export const dark4 = '#ADBBC4';
export const dark5 = '#D4DADE';
export const dark6 = '#E9EEF1';
export const dark7 = '#FAFAFA';
export const dark8 = '#F8FCFF';

// Gradient
export const goldGradient =
  'linear-gradient(137.15deg, #ECB326 14.91%, #FFD759 51.48%, #FBC13B 87.3%)';
export const doomGradient = 'linear-gradient(180deg, #8D2A84 0%, #EF6E33 100%)';
export const flareGradient =
  'linear-gradient(180deg, #F3A130 0%, #EE2E22 100%)';
export const grayGradient =
  'linear-gradient(139.29deg, #F7F7F7 6.62%, #DDDDDD 89.33%)';
export const violetGradient =
  'linear-gradient(133.85deg, #E9638E 6.84%, #7284F2 100%);';
export const quepalGradient =
  'linear-gradient(320.13deg, #0A9C8D 0%, #43EA82 105.06%)';
export const energyGreenGradient =
  'linear-gradient(301.67deg, #509F00 -1.19%, #E2FF7A 102.38%)';
export const skyGradient =
  'linear-gradient(132.77deg, #4ECBEF 15.54%, #1488BD 95.67%)';
export const sinCityRedGradient =
  'linear-gradient(132.86deg, #EA263F 2.97%, #972924 104.14%)';
export const sweetCarrotGradient =
  'linear-gradient(123.98deg, #FA501A 0%, #FF0D5B 104.03%)';
export const proGradient =
  'radial-gradient(81.82% 81.82% at 69.2% 18.18%, #1C436A 0%, #071829 100%)';
export const silverLightGradient =
  'linear-gradient(99.21deg, #F4F4F4 0.8%, #FFFFFF 56.91%)';

const facebookPrimary = '#3b5998';

// Background
const searchInputBg = '#F7F7F7';

const colors: { [index: string]: string } = {
  primary,
  primaryDisabled,
  primaryFocused,
  primaryHover,
  primaryPressed,
  primaryGreen,
  primaryGreenDisabled,
  primaryGreenFocused,
  primaryGreenHover,
  primaryGreenPressed,
  primaryText,
  primaryTextHover,
  secondaryText,
  dark1,
  dark2,
  dark3,
  dark4,
  dark5,
  dark6,
  dark7,
  dark8,
  white,
  black,
  background,
  border,
  divider,
  borderColor: border,
  searchInputBg,
  cardBg: white,
  gray: dark7,
  coral: innovativeOrange,
  danger,
  dangerHover,
  success,
  facebook: facebookPrimary,

  activeCyan,
  globalGreen,
  communityWhite,
  sportmanGray,
  businessBlack,
  passionateRed,
  teamYellow,
  innovativeOrange,
  preservingBlue,
  playfulPurple,
  impactMagenta,
};

export default colors;
