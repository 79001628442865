import React from 'react';
import { Box, Flex } from 'rebass';
import get from 'lodash/get';

import Text from '../../UI/Text';
import Avatar from '../../UI/Avatar';
import { Icon } from '../../Primitives';

import { IActivity } from '../../../interface/Activity';
import { IPeople } from '../../../interface/People';
import { IVenue } from '../../../interface/Venue';

import DummyImg from '../../../assets/dummy-img.png';

import { fromServer } from '../../../helpers/datetime';

import styled from '../../../theme/styled';
import { IGroup } from '../../../interface/Group';

const GroupImage = styled(Box)`
  background: url(${p => p.picture || DummyImg}) no-repeat center center;
  min-width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
`;

const Item = styled(Flex)`
  :hover {
    background-color: ${p => p.theme.colors.primaryHover};
  }
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
`;
export const ActivityBlock: React.FC<{
  activity: IActivity;
  onClick: Function;
}> = ({ activity, onClick }) => {
  const { sport, name, start, end, locations, timezone } = activity;

  const displayStart = fromServer(start, timezone, 'cccc MMM dd');
  const timeStart = fromServer(start, timezone, 'h:mm a');
  const timeEnd = fromServer(end, timezone, 'h:mm a');
  const activityLocation = locations && locations.length && locations[0].name;

  return (
    <Flex
      flexDirection="column"
      color="primaryText"
      onClick={onClick}
      sx={{
        cursor: 'pointer',
      }}
    >
      <Text fontWeight="bold" color="secondaryText">
        {sport}
      </Text>
      <Box mb="1">
        <Text as="span">{displayStart}, &nbsp;</Text>
        <Text as="span">
          {timeStart} - {timeEnd}
        </Text>
      </Box>
      <Text mb="1">{activityLocation}</Text>
      <Text fontWeight="bold">{name}</Text>
    </Flex>
  );
};

export const PeopleBlock: React.FC<{ person: IPeople; onClick: Function }> = ({
  person,
  onClick,
}) => {
  const {
    firstName,
    lastName,
    picture,
    sportProfiles,
    pulseLevel,
    location,
  } = person;

  const name = get(location, 'name', '');

  const displaySports = () => {
    const sports = sportProfiles.map(({ sport }) => sport);
    if (sports.length > 3) {
      const [first, second, ...others] = sports;
      return `${first}, ${second} and ${others.length} more`;
    }
    return sports.join(', ');
  };

  return (
    <Item
      alignItems="center"
      mb="2"
      onClick={onClick}
      sx={{
        cursor: 'pointer',
      }}
    >
      <Avatar size="60px" imageLink={picture} pulseLevel={pulseLevel} />
      <Box ml="4" flex="1">
        <Text fontWeight="bold">{displaySports()}</Text>
        <Text
          color="primaryText"
          fontWeight="bold"
          fontSize="2"
        >{`${firstName} ${lastName}`}</Text>
        <Text color="primaryText">{name}</Text>
      </Box>
    </Item>
  );
};

export const GroupBlock: React.FC<{ group: IGroup; onClick: Function }> = ({
  group,
  onClick,
}) => {
  const {
    members,
    groupProfile: { sport, picture, name, description, privacy },
  } = group;

  const isPublic = privacy === 'PUBLIC';
  return (
    <Flex
      alignItems="center"
      onClick={onClick}
      sx={{
        cursor: 'pointer',
      }}
    >
      <GroupImage picture={picture} width="80px" height="80px" />
      <Box p="3">
        <Flex alignItems="center" px="3" pt="3">
          <Icon icon={isPublic ? 'people' : 'lock-alt'} size="sm" />
          {isPublic && <Text ml="1">{members.length}</Text>}
          {!!sport && <Box variant="dot" />}
          <Text fontWeight="bold">{sport}</Text>
        </Flex>
        <Box color="primaryText" px="3" pb="3">
          <Text fontSize="3" fontWeight="bold">
            {name}
          </Text>
          <Text>{description}</Text>
        </Box>
      </Box>
    </Flex>
  );
};

export const VenueBlock: React.FC<{ venue: IVenue; onClick: Function }> = ({
  venue,
  onClick,
}) => {
  const {
    venueName,
    media,
    location: { address },
  } = venue;
  const firstImage = media.length && media[0].url;
  return (
    <Flex
      alignItems="center"
      onClick={onClick}
      sx={{
        cursor: 'pointer',
      }}
    >
      <GroupImage picture={firstImage} width="80px" height="80px" />
      <Box p="3">
        <Text color="primaryText" fontWeight="bold" fontSize="18px" mb="2">
          {venueName}
        </Text>
        <Flex>
          <Icon icon="location" size="sm" />
          <Text color="primaryText" ml="1">
            {address}
          </Text>
        </Flex>
      </Box>
    </Flex>
  );
};
