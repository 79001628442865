import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Flex } from 'rebass';
import { useHistory } from 'react-router-dom';

import Avatar from '../UI/Avatar';
import Text from '../UI/Text';

import { groupInboxStore } from '../../stores/GroupInboxStore';
import { userStore } from '../../stores/UserStore';

import { Body } from './GroupInboxDetail.styled';

import { HeaderMobile } from '../Layout/Header';

import ChatBody from './components/ChatBody';

interface Props {
  groupId: number;
}

const GroupInboxDetail: React.FC<Props> = observer(({ groupId }) => {
  const {
    getMessages,
    getGroup,
    group,
    messages,
    loading,
    sendTextMessage,
    sendImageMessage,
    updateGameInvite,
  } = groupInboxStore;
  const {
    account: { profileId },
  } = userStore;

  useEffect(() => {
    if (!groupId) return;
    getGroup(groupId);
    getMessages(groupId, true);
  }, [getMessages, getGroup, groupId]);

  const onSendTextMessage = async (text: string) => {
    await sendTextMessage(groupId, text);
  };

  const onSendImageMessage = async (image: File) => {
    await sendImageMessage(groupId, image);
  };

  const pair =
    group?.type === 'PAIR'
      ? group?.members.find(m => m.profile.id !== profileId)
      : group?.members[0];

  const history = useHistory();

  return (
    <Body flexDirection="column" height="100vh">
      <HeaderMobile hasBackBtn>
        {group?.type === 'PAIR' ? (
          <Flex
            alignItems="center"
            onClick={() => history.push(`/${pair?.profile.username}`)}
            sx={{
              cursor: 'pointer',
            }}
          >
            <Avatar imageLink={pair?.profile?.picture} />
            <Text ml="2" fontSize="18px" color="primaryText">
              {pair?.profile?.name}
            </Text>
          </Flex>
        ) : (
          <Text fontSize="18px" color="primaryText">
            {group?.groupProfile?.name}
          </Text>
        )}
      </HeaderMobile>
      <ChatBody
        messages={messages.slice().reverse()}
        profileId={profileId}
        loading={loading}
        getMore={() => getMessages(groupId)}
        onSendTextMessage={onSendTextMessage}
        onSendImageMessage={onSendImageMessage}
        updateGameInvite={updateGameInvite}
      />
    </Body>
  );
});

export default GroupInboxDetail;
