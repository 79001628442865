import React from 'react';
import { Image, ImageProps, Box, Flex, BoxProps } from 'theme-ui';
import qs from 'query-string';

import { PulseIconNames } from './Icon/iconTypes';
import Icon from './Icon';
import Text from './Text';

type AvatarSizes = 'sm' | 'md' | 'lg' | 'xl';

enum Sizes {
  sm = '24px',
  md = '40px',
  lg = '60px',
  xl = '88px',
}

enum Borders {
  sm = '2px solid',
  md = '2px solid',
  lg = '4px solid',
  xl = '4px solid',
}

enum BadgeTextVariants {
  sm = 'lb.xxs',
  md = 'lb.xs',
  lg = 'lb.sm',
  xl = 'lb.md',
}

enum IconSizes {
  sm = 'sm',
  md = 'lg',
  lg = 'xl',
  xl = 'badge',
}

enum IconPosition {
  sm = '-8px',
  md = '-12px',
  lg = '-16px',
  xl = '-20px',
}

enum BorderColors {
  SPARK = 'primaryGreen',
  FLICKER = 'primaryGreen',
  EMBER = 'teamYellow',
  FLAME = 'teamYellow',
  WILDFIRE = 'innovativeOrange',
  BLAZE = 'innovativeOrange',
  FIRESTORM = 'passionateRed',
  INFERNO = 'passionateRed',
}

interface Props extends ImageProps {
  pulseLevel?: PulseIconNames;
  badgeText?: string;
  size: AvatarSizes;
}

const displayLink = (src?: string) => {
  if (src && src.includes('https://platform-lookaside.fbsbx.com')) {
    const parsed = qs.parse(src);
    const id =
      parsed['https://platform-lookaside.fbsbx.com/platform/profilepic/?asid'];
    return `https://graph.facebook.com/v3.2/${id}/picture?type=large`;
  } else return src;
};

const PrimitiveAvatar = React.forwardRef<HTMLImageElement, Props>(
  ({ pulseLevel, size = 'md', badgeText, ...props }, ref) => {
    return (
      <Box
        sx={{
          position: 'relative',
          maxHeight: Sizes[size],
          maxWidth: Sizes[size],
          minWidth: Sizes[size],
          minHeight: Sizes[size],
          cursor: props.onClick ? 'pointer' : 'initial',
        }}
      >
        <Image
          {...props}
          src={displayLink(props.src)}
          ref={ref}
          sx={{
            ...props.sx,
            height: Sizes[size],
            width: Sizes[size],
            border: pulseLevel ? Borders[size] : 'none',
            borderColor: pulseLevel ? BorderColors[pulseLevel] : 'transparent',
            background: 'transparent',
            borderRadius: '9999px',
          }}
        />
        {!!badgeText && (
          <Flex
            sx={{
              position: 'absolute',
              bottom: '0px',
              right: IconPosition[size],
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Icon icon="polygon-green" size={IconSizes[size]} />
            <Text
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
              variant={BadgeTextVariants[size]}
            >
              {badgeText}
            </Text>
          </Flex>
        )}
      </Box>
    );
  }
);

interface EmptyAvatarProps extends BoxProps {
  size: AvatarSizes;
  hasAddIcon?: boolean;
}

export const EmptyAvatar = React.forwardRef<HTMLImageElement, EmptyAvatarProps>(
  ({ size, hasAddIcon, ...props }, ref) => {
    return (
      <Flex
        {...props}
        sx={{
          ...props.sx,
          maxHeight: Sizes[size],
          maxWidth: Sizes[size],
          minWidth: Sizes[size],
          minHeight: Sizes[size],
          border: '1px dashed',
          borderColor: 'border',
          borderRadius: '9999px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        ref={ref}
      >
        {hasAddIcon && <Icon icon="add" fill="secondaryText" />}
      </Flex>
    );
  }
);

export default PrimitiveAvatar;
