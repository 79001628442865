import React, { FunctionComponent } from 'react';
import { Flex, Button } from 'rebass';

import Text from '../../UI/Text';
import { Icon } from '../../Primitives';

import { DAYS_OF_WEEK } from '../../../constant';
import { ScheduleSlot } from '../../../helpers/search';

import styled from '../../../theme/styled';

const SButton = styled(Button)`
  :not(:last-child) {
    margin-right: 8px;
  }
`;

type Props = {
  schedule: number[];
  setSchedule: Function;
};

const FilterSchedule: FunctionComponent<Props> = ({
  schedule,
  setSchedule,
}) => {
  const toggleSchedule = (index: number) => {
    if (schedule.includes(index)) {
      setSchedule(schedule.filter(s => s !== index));
    } else {
      schedule.push(index);
      setSchedule([...schedule]);
    }
  };

  return (
    <Flex flexDirection="column">
      {DAYS_OF_WEEK.map(day => (
        <Flex alignItems="center" key={day}>
          <Text fontWeight="bold" color="primaryText" width={0.1} mr="3">
            {day}
          </Text>
          <Flex alignItems="center" mb="2">
            <SButton
              onClick={() => {
                toggleSchedule(ScheduleSlot[`${day}_MORNING`]);
              }}
              display="flex"
              fontSize="12px"
              variant={
                schedule.includes(ScheduleSlot[`${day}_MORNING`])
                  ? 'primary'
                  : 'disabled'
              }
            >
              <Icon icon="sunset" size="sm" stroke="white" />
              <Text ml="2px">Morning</Text>
            </SButton>
            <SButton
              onClick={() => {
                toggleSchedule(ScheduleSlot[`${day}_AFTERNOON`]);
              }}
              display="flex"
              fontSize="12px"
              variant={
                schedule.includes(ScheduleSlot[`${day}_AFTERNOON`])
                  ? 'primary'
                  : 'disabled'
              }
            >
              <Icon icon="sun" size="sm" stroke="white" />
              <Text ml="2px">Afternoon</Text>
            </SButton>
            <SButton
              onClick={() => {
                toggleSchedule(ScheduleSlot[`${day}_EVENING`]);
              }}
              display="flex"
              fontSize="12px"
              variant={
                schedule.includes(ScheduleSlot[`${day}_EVENING`])
                  ? 'primary'
                  : 'disabled'
              }
            >
              <Icon icon="moon" size="sm" stroke="white" />
              <Text ml="2px">Evening</Text>
            </SButton>
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};

export default FilterSchedule;
