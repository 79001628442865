import React, { useState } from 'react';
import { Button, Card, Flex, Image } from 'rebass';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react-lite';
import { Spinner } from '@blueprintjs/core';
import { useHistory } from 'react-router-dom';

import Text from '../../UI/Text';

import { HeaderMobile } from '../../Layout/Header';
import ShareDialog from '../../Layout/ShareDialog';

import InviteImg from '../../../assets/invite-friend.png';
import { userStore } from '../../../stores/UserStore';
import NavigatorShare from '../../../helpers/socialShare';

const InviteFriends: React.FC = observer(() => {
  const { account, loading } = userStore;
  const [isOpen, setShareDialogStatus] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 1024 });

  const history = useHistory();

  const handleShare = () => {
    NavigatorShare(
      () => setShareDialogStatus(true),
      { url: account.referralLink },
      () => setShareDialogStatus(false)
    );
  };

  return (
    <Flex flexDirection="column">
      <HeaderMobile
        text="Invite your friends"
        hasBackBtn={isMobile}
        backLink="/settings"
      />
      <Flex flexDirection="column" alignItems="center" px="3" py="4">
        <Image src={InviteImg} width="200px" mb="3" />
        <Text color="primaryText" fontSize="20px" my="4" textAlign="center">
          You're likely to play more when your friends are on Rovo
        </Text>
        <Card color="primaryText" minWidth="250px">
          {!loading ? (
            <>
              <Text mb="1" fontWeight="bold">
                Use your referral link
              </Text>
              <Text mb="3">{account.referralLink}</Text>
              <Button width="100%" onClick={handleShare}>
                Copy link & share
              </Button>
            </>
          ) : (
            <Spinner intent="primary" />
          )}
        </Card>
        <Button
          my="3"
          variant="noPadding"
          fontWeight="bold"
          color="primaryText"
          onClick={() => history.push(`/${account.profile.username}`)}
        >
          Skip invite
        </Button>
      </Flex>
      {isOpen && (
        <ShareDialog
          isOpen={isOpen}
          onClose={() => setShareDialogStatus(false)}
          url={account.referralLink}
          text="Share your referral link"
        />
      )}
    </Flex>
  );
});

export default InviteFriends;
