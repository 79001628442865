import React from 'react';
import { Box, Flex } from 'rebass';
import css from '@emotion/css';

import styled from '../../theme/styled';

const Radio = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;

const Label = styled(Box)`
  cursor: pointer;
  padding: 8px 16px;
  flex: 1;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  ${p =>
    p.defaultChecked
      ? css`
          background-color: ${p.theme.colors.primary};
          color: ${p.theme.colors.white};
          border-radius: 4px;
        `
      : ''}
  transition: 0.3s;
`;

const Wrapper = styled(Flex)`
  border: ${p => (p.noBorder ? 'none' : p.theme.border.box)};
  background-color: ${p => p.noBorder && `rgba(0, 0, 0, 0.1)`};
  border-radius: 4px;
  width: 100%;
`;

const RadioSelect: React.FC<{
  value?: string;
  setValue: Function;
  options: { text: string; value: string }[];
  noBorder?: boolean;
}> = ({ value, setValue, options, noBorder }) => {
  return (
    <Wrapper noBorder={noBorder}>
      {options.map(option => (
        <Label
          as="label"
          minWidth={`${100 / options.length}%`}
          key={option.value}
          defaultChecked={value === option.value}
          onClick={() => setValue(option.value)}
        >
          {option.text}
          <Radio type="radio" name={option.value} value={option.value} />
        </Label>
      ))}
    </Wrapper>
  );
};

export default RadioSelect;
