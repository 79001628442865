import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

export interface ProtectedRouteProps extends RouteProps {
  isAuthenticated: boolean;
  isCompleted: boolean;
  redirectPath: string;
  authenticationPath: string;
}

export interface NeedCompleteProfileRouteProps extends RouteProps {
  isCompleted: boolean;
  redirectPath: string;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
  const {
    isAuthenticated,
    isCompleted,
    redirectPath,
    authenticationPath,
  } = props;
  const pathname = isAuthenticated
    ? isCompleted
      ? ''
      : redirectPath
    : authenticationPath;

  if (pathname) {
    const renderComponent = () => <Redirect to={pathname} />;
    return <Route {...props} component={renderComponent} render={undefined} />;
  } else {
    return <Route {...props} />;
  }
};

export const NeedCompleteProfileRoute: React.FC<NeedCompleteProfileRouteProps> = (
  props
) => {
  const { redirectPath, isCompleted } = props;
  const pathname = isCompleted ? '' : redirectPath;

  if (pathname) {
    const renderComponent = () => <Redirect to={{ pathname }} />;
    return <Route {...props} component={renderComponent} render={undefined} />;
  } else {
    return <Route {...props} />;
  }
};
