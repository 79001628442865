import React, { useState } from 'react';
import { Flex } from 'rebass';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Spinner } from '@blueprintjs/core';

import Text from '../../../UI/Text';
import Link from '../../../UI/Link';
import { SearchInput } from '../../../UI/Input';
import { ISport } from '../../../../interface/Utils';
import { CurrentSport } from './AddSport';

import SportImage from './SportImage';

const SelectSport: React.FC<{
  sports: ISport[];
  setCurrentSports: Function;
  setStep: Function;
  loading?: boolean;
  currentSports: CurrentSport[];
}> = ({ sports, setCurrentSports, setStep, loading, currentSports }) => {
  const [searchString, setSearchString] = useState('');

  const popularSports = sports.filter(
    (sport: { popular: boolean; name: string }) =>
      sport.popular &&
      sport.name.toLowerCase().includes(searchString.toLowerCase())
  );

  const otherSports = sports.filter(
    (sport: { popular: boolean; name: string }) =>
      !sport.popular &&
      sport.name.toLowerCase().includes(searchString.toLowerCase())
  );

  return (
    <>
      <Flex mb="2">
        <SearchInput
          placeholder="Search for a sport"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearchString(e.target.value)
          }
        />
      </Flex>

      {loading ? (
        <Spinner intent="primary" />
      ) : (
        <PerfectScrollbar>
          <Text
            textTransform="uppercase"
            fontWeight="bold"
            my="2"
            color="primaryText"
          >
            Popular sports
          </Text>
          <Flex flexWrap="wrap">
            {popularSports.map(({ id, uri, name }, index) => (
              <SportImage
                key={id}
                index={index}
                id={id}
                uri={uri}
                name={name}
                currentSports={currentSports}
                setCurrentSports={setCurrentSports}
              />
            ))}
          </Flex>
          <Text
            textTransform="uppercase"
            fontWeight="bold"
            my="2"
            color="primaryText"
          >
            Other sports
          </Text>
          <Flex flexWrap="wrap">
            {otherSports.map(({ uri, id, name }, index) => (
              <SportImage
                key={id}
                index={index}
                id={id}
                uri={uri}
                name={name}
                currentSports={currentSports}
                setCurrentSports={setCurrentSports}
              />
            ))}
          </Flex>
          <Flex flexDirection="column" alignItems="center">
            <Text mb="2" color="primaryText">
              Can't find your sport?
            </Text>
            <Link to="https://api.rovo.co/v1.9.0/sports/suggest" isExtend>
              <Text color="primary">Suggest a sport</Text>
            </Link>
          </Flex>
        </PerfectScrollbar>
      )}
    </>
  );
};

export default SelectSport;
