import React, { useState, useEffect } from 'react';
import { Box } from 'theme-ui';
import { useParams } from 'react-router-dom';

import Navigation from '../Layout/Navigation';
import FollowList from '../Explore/FollowList';

import ScheduleBody from './components/ScheduleBody';
import HistoryBody from './components/HistoryBody';

const availableScheduleTab = ['activities', 'facility-bookings', 'scores'];
const availableHistoryTab = ['activities', 'facility-bookings'];

const SchedulePage: React.FC = () => {
  const [tabId, setTabId] = useState(availableScheduleTab[0]);
  const [historyTabId, setHistoryTabId] = useState(availableHistoryTab[0]);
  const param: { tabId?: string; historyTabId?: string } = useParams();

  useEffect(() => {
    if (param?.tabId && availableScheduleTab.includes(param.tabId)) {
      setTabId(param.tabId);
    } else if (!param?.historyTabId) {
      setTabId('activities');
    }
    if (
      param?.historyTabId &&
      availableHistoryTab.includes(param.historyTabId)
    ) {
      setHistoryTabId(param.historyTabId);
    } else {
      setHistoryTabId('activities');
    }
  }, [param]);

  return (
    <Box
      variant="layout.main"
      sx={{
        gap: '0px',
      }}
    >
      <Box variant="layout.leftSide">
        <Navigation selected="schedule" hideOnMobile />
      </Box>
      {param?.historyTabId ? (
        <HistoryBody tabId={historyTabId} setTabId={setHistoryTabId} />
      ) : (
        <ScheduleBody tabId={tabId} setTabId={setTabId} />
      )}
      <Box variant="layout.rightSide">
        <FollowList />
      </Box>
    </Box>
  );
};

export default SchedulePage;
