import React from 'react';
import { Box } from 'rebass';
import { Tabs, Tab } from '@blueprintjs/core';
import { useHistory } from 'react-router-dom';

import Text from '../../UI/Text';
import PastActivitiesTab from './PastTab';
import UpcomingActivitiesTab from './UpcomingTab';

import { Body, BackBtn, Title, ExtendHeader } from '../Profile.styled';
import ScoreTab from './ScoreTab';

const Plays: React.FC<{
  profileId: number;
  name: string;
  username: string;
  isSelf: boolean;
}> = ({ profileId, name, username, isSelf }) => {
  const history = useHistory();

  const pastParams = {
    username,
    params: { past: true },
  };

  return (
    <Body flexDirection="column">
      <ExtendHeader px="3" py={[2, 3]} alignItems="center" bg="white">
        <BackBtn
          icon="chevron-left"
          fill="primaryText"
          onClick={() => history.push(`/${username}`)}
        />
        <Text ml="3" fontSize="18px" color="primaryText">
          {isSelf ? 'Your activities' : `${name}'s public activities`}
        </Text>
      </ExtendHeader>
      <Box variant="line" />
      <Tabs className="modify-tabs" renderActiveTabPanelOnly>
        {isSelf && (
          <Tab
            id="profile-activities-upcoming"
            title={<Title>Upcoming</Title>}
            panel={<UpcomingActivitiesTab />}
          />
        )}
        <Tab
          id="profile-activities-past"
          title={<Title>Past</Title>}
          panel={<PastActivitiesTab params={pastParams} />}
        />
        <Tab
          id="profile-score"
          title={<Title>Score</Title>}
          panel={<ScoreTab profileId={profileId} />}
        />
      </Tabs>
    </Body>
  );
};

export default Plays;
