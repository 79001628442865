import React from 'react';
import { Box } from 'rebass';
import { Flex } from 'rebass';
import UnAuthHeader from '../../components/Layout/UnAuth/Header';
import { MinimalFooter } from '../../components/Layout/UnAuth/Footer';

import { FeatureCard } from './Features.styed';
import { Image } from 'rebass';
import { PUBLIC_URL } from '../../constant';
import { Text } from 'rebass';

const Features: React.FC = () => {
  return (
    <Box width="100%">
      <UnAuthHeader isWhiteHeader />
      <Flex
        justifyContent="center"
        mt="40px"
        mb="72px"
        flexWrap="wrap"
        px={['3', '0']}
      >
        <Flex
          width={[1, 0.5]}
          pr={['0', 2]}
          maxWidth="560px"
          flexDirection="column"
        >
          <FeatureCard bg="#09B3B3" mb="3">
            <Text color="white" px="72px" variant="lb.md" mb="3">
              Find people near you
            </Text>
            <Text color="white" px="72px" variant="hd.lg" mb="3">
              Find people at your skill level to do sports with.
            </Text>
            <Text color="white" px="72px" variant="pg.md" mb="4">
              Looking for someone to play tennis or basketball with? Rovo helps
              you find players by skill level so you’ll always have a better
              game with them.
            </Text>
            <Image
              width="290px"
              mb="-4px"
              mx="auto"
              mt="3"
              src={`${PUBLIC_URL}/images/features/1.png`}
            />
          </FeatureCard>
          <FeatureCard bg="#9DD911" mb="3">
            <Text color="white" px="72px" variant="lb.md" mb="3">
              Score tracking
            </Text>
            <Text color="white" px="72px" variant="hd.lg" mb="3">
              It’s more fun with a little friendly competition.
            </Text>
            <Text color="white" px="72px" variant="pg.md" mb="4">
              Rovo comes with the ability to track scores and stats for your
              sessions, which is great if you want a little friendly competition
              in your games to push everyone further.
            </Text>
            <Image
              width="290px"
              mb="-4px"
              mt="3"
              src={`${PUBLIC_URL}/images/features/3.png`}
            />
          </FeatureCard>
          <FeatureCard bg="#7151C9" mb="3">
            <Text color="white" px="72px" variant="lb.md" mb="3">
              Badges & Feedback
            </Text>
            <Text color="white" px="72px" variant="hd.lg" mb="3">
              Had a good session? Exchange badges and feedback.
            </Text>
            <Text color="white" px="72px" variant="pg.md" mb="4">
              Exchange badges to inspire each other to be more active. Badges
              are also great for other people to see what kind of sports player
              are you.
            </Text>
            <Image
              mb="-4px"
              mt="3"
              src={`${PUBLIC_URL}/images/features/5.png`}
            />
          </FeatureCard>
        </Flex>
        <Flex
          width={[1, 0.5]}
          pl={['0', 2]}
          maxWidth="560px"
          flexDirection="column"
        >
          <FeatureCard bg="#4280F4" mb="3">
            <Text color="white" px="72px" variant="lb.md" mb="3">
              Create
            </Text>
            <Text color="white" px="72px" variant="hd.lg" mb="3">
              Groups to help you organise your sessions easily.
            </Text>
            <Text color="white" px="72px" variant="pg.md" mb="4">
              Rovo groups allow you to easily take RSVPs for sessions with your
              friends. You can also join some public groups around your
              neighborhood to find activities around you.
            </Text>
            <Image
              width="290px"
              mb="-4px"
              mt="3"
              src={`${PUBLIC_URL}/images/features/2.png`}
            />
          </FeatureCard>
          <FeatureCard bg="#F58F1B" mb="3">
            <Text color="white" px="72px" variant="lb.md" mb="3">
              Organise & join activities
            </Text>
            <Text color="white" px="72px" variant="hd.lg" mb="3">
              Stay active with weekly activities you can join.
            </Text>
            <Text color="white" px="72px" variant="pg.md" mb="4">
              Find activities that are happening around you every week. You can
              also easily create an activity and invite people to your activity.
            </Text>
            <Image
              width="290px"
              mb="-4px"
              mt="3"
              src={`${PUBLIC_URL}/images/features/4.png`}
            />
          </FeatureCard>
          <FeatureCard bg="#FFC043">
            <Text color="white" px="72px" variant="lb.md" mb="3">
              Sports Feed
            </Text>
            <Text color="white" px="72px" variant="hd.lg" mb="3">
              Stay updated with your friends and people you follow.
            </Text>
            <Text color="white" px="72px" variant="pg.md" mb="4">
              Discover what your friends are playing this weekend and join them.
              You can also follow sports people in Rovo that inspires you to get
              stronger and more active.
            </Text>
            <Image
              width="241px"
              mb="-4px"
              mt="3"
              src={`${PUBLIC_URL}/images/features/6.png`}
            />
          </FeatureCard>
        </Flex>
      </Flex>
      <Flex alignItems="center" maxWidth="600px" mx="auto" mb="4" px={[3, 0]}>
        <Box
          sx={{
            border: '1px solid #D1DCE8',
            flex: '1 1 auto',
          }}
        />
        <Text variant="lb.md" color="#697B8C" mx="3">
          We’re featured on
        </Text>
        <Box
          sx={{
            border: '1px solid #D1DCE8',
            height: '1px',
            flex: '1 1 auto',
          }}
        />
      </Flex>
      <Flex
        justifyContent="space-between"
        mx="auto"
        flexWrap="wrap"
        maxWidth="600px"
        mb="5"
      >
        <Box width={['100%', 'initial']} textAlign="center">
          <Image src="/images/tech-in-asia.png" />
        </Box>
        <Box width={['100%', 'initial']} textAlign="center">
          <Image src="/images/vulcan-post.png" />
        </Box>
        <Box width={['100%', 'initial']} textAlign="center">
          <Image src="/images/jump-start.png" />
        </Box>
      </Flex>
      <Text variant="hd.lg" textAlign="center" mb="2">
        Get the app
      </Text>
      <Text variant="pg.md" textAlign="center" mb="3">
        100% free. Easy to use. 1,000+ ratings.
      </Text>
      <Flex mb="5" justifyContent="center">
        <Box mr="3">
          <a
            rel="noopener noreferrer"
            href="https://apps.apple.com/us/app/rovo-find-sports-buddies/id1044009295?mt=8"
          >
            <Image
              minHeight="56px"
              alt="Download on the App Store"
              src="/images/apple-store-badge.png"
            />
          </a>
        </Box>
        <Box>
          <a
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=com.rovo.rovo&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          >
            <Image
              minHeight="56px"
              alt="Get it on Google Play"
              src="/images/google-play-badge.png"
            />
          </a>
        </Box>
      </Flex>
      <MinimalFooter />
    </Box>
  );
};

export default Features;
