import React, { useEffect } from 'react';

import VenuePage from '../components/Venue';
import { trackPageView } from '../services/amplitudeService';

const Schedule: React.FC<{}> = () => {
  useEffect(() => {
    trackPageView('venue');
  }, []);

  return <VenuePage />;
};

export default Schedule;
