import React, { useEffect } from 'react';

import ProfilePage from '../components/Profile';
import { trackPageView } from '../services/amplitudeService';
import { observer } from 'mobx-react-lite';
import partnerStore from '../stores/partnerStore';

const Profile = observer(() => {
  const { resetState } = partnerStore;
  useEffect(() => {
    trackPageView('UserProfile');
    return function cleanup() {
      resetState();
    };
  }, [resetState]);

  return <ProfilePage />;
});

export default Profile;
