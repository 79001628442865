import { Dialog } from '@blueprintjs/core';
import { Box, Button, Flex, Image, Text } from 'rebass';
import styled from '../../theme/styled';
import { Icon } from '../Primitives';
import { Input } from '../UI/Input';

export const FacilityBookingDialog = styled(Dialog)`
  @media (min-width: 768px) {
    width: 100%;
    padding-bottom: 0px;
  }
  @media (min-width: 860px) {
    width: 860px;
  }
  padding: 0px;
  width: 100%;
  margin: 0px;
  background-color: ${(p) => p.theme.colors.dark7};
`;

export const StepIndicator = styled(Box)`
  width: 25px;
  height: 5px;
  border-radius: 1px;
`;

export const Slot = styled(Flex)`
  align-items: center;
  justify-content: center;
  min-width: 120px;
  min-height: 48px;
  border: ${(p) => p.theme.border.box};
  box-shadow: 0px 1.5px 1.5px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const TimeSlot = styled(Flex)`
  min-height: 48px;
  :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const FacilityName = styled(Text)`
  min-width: 120px;
  max-width: 120px;
  text-align: center;
`;

export const DateSelect = styled(Flex)`
  height: 50px;
  min-width: 50px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  :not(:last-child) {
    margin-right: 8px;
  }
  padding: 4px;
  cursor: pointer;
`;

export const DateSelectWrapper = styled(Flex)`
  overflow: auto;
`;

export const CalendarPicker = styled(Flex)`
  height: 50px;
  min-width: 50px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: ${(p) => p.theme.border.box};
`;

export const TableContainer = styled(Flex)`
  display: flex;
  overflow-y: hidden;
  margin-bottom: 16px;
  marginleft: '16px';
  marginright: '16px';
`;

export const LeftStickyColumn = styled(Flex)`
  flex-direction: column;
  overflow: hidden;
  min-width: 40px;
  margin-right: 8px;
`;

export const SlotContainer = styled(Flex)`
  flex-direction: column;
  overflow: hidden;
  overflow-x: hidden;
  flex: 1 1 auto;
`;

export const TableHeader = styled(Flex)`
  overflow: hidden;
  padding: 8px 0px;
`;

export const TableHeaderWrapper = styled(Box)`
  padding-left: 48px;
  marginleft: '16px';
  marginright: '16px';
`;

export const TableBody = styled(Flex)`
  overflow: auto;
`;

export const InputCoupon = styled(Input)`
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  height: 40px;
  text-transform: uppercase;
`;

export const ApplyCouponButton = styled(Button)`
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  min-width: 104px;
`;

export const PaymentMethodCard = styled(Box)`
  border-radius: 4px;
  padding: 16px;
  border-width: 2px;
  :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const StickyBottomBar = styled(Box)`
  position: sticky;
  bottom: 0px;
  padding: 16px;
  background-color: white;
  border-top: ${(p) => p.theme.border.box};
  background-color: ${(p) => p.theme.colors.white};
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr repeat(3, auto) 1fr;
    justify-items: center;
  }
`;

export const ConfirmBtn = styled(Button)`
  width: 100%;
  height: 48px;
  font-size: 16px;
  font-weight: bold;
  @media (min-width: 768px) {
    grid-column-start: 2;
    width: 240px;
  }
`;

export const BackBtn = styled(Button)`
  @media (min-width: 768px) {
    display: block;
  }
  display: none;
  margin-right: auto;
  border: ${(p) => p.theme.border.box};
`;

export const Header = styled(Flex)`
  position: relative;
`;

export const PartnerLogo = styled(Image)`
  position: absolute;
  left: 16px;
`;

export const CloseButton = styled(Icon)`
  position: absolute;
  right: 16px;
`;
