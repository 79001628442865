import React, { useState } from 'react';
import { PrimaryDialog } from '../../UI/Dialog';
import { Text, Button } from '../../Primitives';
import { Textarea, Box, Flex, Select } from 'theme-ui';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../hooks/useStore';
import { centsToDecimal } from '../../../helpers/currency';
import cogoToast from 'cogo-toast';

import { ReactComponent as CancelSuccess } from '../../../assets/illustrator/cancel-success.svg';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  currency: string;
};

const CancellationBookingDialog = observer(
  ({ isOpen, onClose, currency }: Props) => {
    const { facilityBookingStore } = useStores();
    const {
      refundPolicies,
      cancelFacilityBooking,
      state,
      order,
    } = facilityBookingStore;

    const [comment, setComment] = useState('');
    const [reason, setReason] = useState('Bad weather');

    const handleCancel = async () => {
      try {
        await cancelFacilityBooking(reason, comment);
        if (!order?.refundableAmount) {
          onClose();
        }
        cogoToast.success('Cancel transaction successfully.');
      } catch (error) {
        const errMsg =
          error.response?.data?.msg ||
          error.response?.data?.message ||
          'Cancel booking failed. Please contact the venue for more information.';
        cogoToast.error(errMsg);
      }
    };

    return (
      <PrimaryDialog isOpen={isOpen} onClose={onClose} title="Cancel this slot">
        {order && order.status === 'canceled' && order.refundableAmount ? (
          <Flex p="3" sx={{ flexDirection: 'column', alignItems: 'center' }}>
            <CancelSuccess />
            <Text my="3" variant="pg.md">
              Hooray, you're eligible for refund!
            </Text>
            <Text variant="pg.md">
              Based on the refund policy, we were eligible for{' '}
              {centsToDecimal(order.refundedAmount, order.currency)}{' '}
              {order.currency} refund.
            </Text>
          </Flex>
        ) : (
          <Flex
            p="3"
            sx={{
              height: '100%',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            <Text variant="lb.md" mb="3">
              Reason for cancellation:
            </Text>
            <Select
              mb="3"
              value={reason}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                setReason(e.target.value)
              }
            >
              <option>Bad weather</option>
              <option>The time slot is not convenient</option>
              <option>The location is not convenient</option>
              <option>The facility is not convenient</option>
              <option>I found a better option to book</option>
              <option>Other reason</option>
            </Select>
            {reason === 'Other reason' && (
              <Textarea
                mb="3"
                rows={3}
                sx={{
                  color: '#222F3C',
                }}
                value={comment}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setComment(e.target.value)
                }
                placeholder="Share more about your reason"
              />
            )}
            <Text variant="lb.md">Refund Policy</Text>
            <Box
              as="ul"
              sx={{
                paddingInlineStart: '16px',
                mb: '3',
              }}
            >
              {refundPolicies?.length &&
                refundPolicies.map((p, index) => (
                  <li key={p.hoursBeforeServiceTime + index}>
                    <Text variant="pg.md">
                      If the user cancel within{' '}
                      <b>{p.hoursBeforeServiceTime} hours</b> in prior to the
                      booking, the refund is{' '}
                      <b>
                        {100 - p.feePercentage}%{' '}
                        {!!p.feeCents
                          ? `and ${centsToDecimal(
                              p.feeCents,
                              currency
                            )} ${currency}`
                          : ''}{' '}
                      </b>
                      of the payment.
                    </Text>
                  </li>
                ))}
            </Box>

            <Button
              sx={{
                mt: 'auto',
              }}
              variant="outline.danger"
              onClick={handleCancel}
              loading={state === 'loading'}
              disabled={state === 'loading'}
            >
              Confirm the cancellation
            </Button>
          </Flex>
        )}
      </PrimaryDialog>
    );
  }
);

export default CancellationBookingDialog;
