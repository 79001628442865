import React, { useState, useEffect, useMemo } from 'react';
import { Flex, Spinner } from 'theme-ui';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { observer } from 'mobx-react-lite';

import { Text } from '../Primitives';

import { userStore } from '../../stores/UserStore';

const EmailVerify = observer(() => {
  const [serverErr, setServerErr] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSend, setIsSend] = useState(false);

  const location = useLocation();
  const parsed = qs.parse(location.search);

  const code = useMemo(() => parsed.oobCode, [parsed]);

  useEffect(() => {
    async function makeVerify(code: string | string[]) {
      if (typeof code !== 'string') {
        setIsSubmitting(false);
        return setServerErr(
          'There might an issues with the verify link. Please contact us via support@rovo.co'
        );
      }
      try {
        await userStore.updateEmailVerified(code);
        setIsSubmitting(false);
      } catch (err) {
        setIsSubmitting(false);
        setServerErr(err.message);
      }
    }
    if (code && !isSubmitting && !isSend) {
      setIsSubmitting(true);
      setIsSend(true);
      makeVerify(code);
    }
  }, [code, isSend, isSubmitting]);

  return (
    <Flex>
      {isSubmitting ? (
        <Spinner />
      ) : (
        <>
          {serverErr ? (
            <Text variant="lb.lg" color="danger">
              {serverErr}
            </Text>
          ) : (
            <Text variant="lb.lg">
              <span role="img" aria-label="medal">
                🏅🏅
              </span>
              Yay. Your email has been verified. Time to #PlayMore!
            </Text>
          )}
        </>
      )}
    </Flex>
  );
});

export default EmailVerify;
