import { FocusStyleManager } from '@blueprintjs/core';
import 'mobx-react-lite/batchingForReactDom';
import '@brainhubeu/react-carousel/lib/style.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ThemeProvider } from 'emotion-theming';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import AccountVerify from './components/Authentication/AccountVerify';
import CompleteProfile from './components/Authentication/CompleteProfile';
import ResetPassword from './components/Authentication/ResetPassword';
import SignInPage from './components/Authentication/SignIn';
import SignUpPage from './components/Authentication/SignUp';
import PostDetail from './components/PostDetail';
import {
  NeedCompleteProfileRoute,
  ProtectedRoute,
} from './components/UI/ProtectedRoute';
import config from './config';
import LocalStorageHelper from './helpers/localStorage';
import { useFreshchat } from './hooks/useFreshchat';
import ActivityDetail from './pages/activities';
import DownloadApp from './pages/download-app';
import Error from './pages/error';
import Explore from './pages/explore';
import Features from './pages/Features';
import Feed from './pages/feed';
import Group from './pages/Group';
import Home from './pages/Home';
import Inbox from './pages/inbox';
import LandingPage from './pages/LandingPage';
import MixProfile from './pages/mix-profile';
import Notifications from './pages/notification';
import { Premium, PremiumPurchase, PremiumSection } from './pages/Premium';
import Schedule from './pages/schedule';
import Search from './pages/search';
import Settings from './pages/settings';
import ServerIssue from './pages/severIssue';
import Venue from './pages/venue';
import './static/styles.scss';
import { userStore } from './stores/UserStore';
import theme from './theme';

FocusStyleManager.onlyShowFocusOnTabs();

const stripePromise = loadStripe(config.STRIPE_PUBLIC_KEY);

const App: React.FC = observer(() => {
  const { account, getSelfProfile, isPremium } = userStore;

  const storedAccount = LocalStorageHelper.get('currentUser');

  useEffect(() => {
    const currentUser = LocalStorageHelper.get('currentUser');
    if (currentUser && currentUser.session) {
      getSelfProfile();
    }
  }, [getSelfProfile]);

  const isAuthenticated =
    account.id !== -1 ||
    (!!storedAccount && !!storedAccount.account && !!storedAccount.session);

  const isCompletedProfile =
    storedAccount?.account?.profile?.status === 'ACTIVE' &&
    !!storedAccount?.account?.profile?.location?.id;

  useFreshchat();

  return (
    <Router>
      <Elements stripe={stripePromise}>
        <ThemeProvider theme={theme}>
          <Switch>
            <Route exact path="/">
              {isAuthenticated ? <Redirect to="home" /> : <LandingPage />}
            </Route>

            <Route exact path="/features" component={Features} />
            <Redirect exact from="/explore" to="/explore/activities" />
            <Redirect from="/paymentMethods" to="/premium/billing" />
            <NeedCompleteProfileRoute
              redirectPath="/complete-profile"
              isCompleted={isAuthenticated ? isCompletedProfile : true}
              path="/home"
              component={Home}
            />
            <NeedCompleteProfileRoute
              redirectPath="/complete-profile"
              isCompleted={isAuthenticated ? isCompletedProfile : true}
              path="/explore/:tabId"
              component={Explore}
            />
            <NeedCompleteProfileRoute
              redirectPath="/complete-profile"
              isCompleted={isAuthenticated ? isCompletedProfile : true}
              path="/plays/:activityName"
              component={ActivityDetail}
            />
            <NeedCompleteProfileRoute
              redirectPath="/complete-profile"
              isCompleted={isAuthenticated ? isCompletedProfile : true}
              path="/games/:activityName"
              component={ActivityDetail}
            />
            <NeedCompleteProfileRoute
              redirectPath="/complete-profile"
              isCompleted={isAuthenticated ? isCompletedProfile : true}
              path="/search"
              component={Search}
            />
            <Route path="/venues/:venueUri" component={Venue} />
            <NeedCompleteProfileRoute
              redirectPath="/complete-profile"
              isCompleted={isAuthenticated ? isCompletedProfile : true}
              path="/groups/:groupUri"
              component={Group}
            />
            <NeedCompleteProfileRoute
              exact
              path="/premium"
              redirectPath="/premium/explore"
              isCompleted={!isPremium}
              component={Premium}
            />
            <ProtectedRoute
              exact
              path="/premium/purchase"
              isAuthenticated={isAuthenticated}
              isCompleted={isCompletedProfile}
              redirectPath="/complete-profile"
              authenticationPath={`/sign-in?re=${config.URL}/premium/purchase`}
              component={PremiumPurchase}
            />
            <ProtectedRoute
              exact
              path="/premium/:section"
              isAuthenticated={isAuthenticated}
              isCompleted={isCompletedProfile}
              redirectPath="/complete-profile"
              authenticationPath={`/sign-in?re=${config.URL}/premium/purchase`}
              component={PremiumSection}
            />
            <ProtectedRoute
              exact
              path="/feed"
              isAuthenticated={isAuthenticated}
              isCompleted={isCompletedProfile}
              redirectPath="/complete-profile"
              authenticationPath="/sign-in"
              component={Feed}
            />
            <ProtectedRoute
              exact
              path="/inbox"
              isAuthenticated={isAuthenticated}
              isCompleted={isCompletedProfile}
              redirectPath="/complete-profile"
              authenticationPath="/sign-in"
              component={Inbox}
            />
            <ProtectedRoute
              exact
              path="/inbox/:tabId"
              isAuthenticated={isAuthenticated}
              isCompleted={isCompletedProfile}
              redirectPath="/complete-profile"
              authenticationPath="/sign-in"
              component={Inbox}
            />
            <ProtectedRoute
              exact
              path="/inbox/g/:groupId"
              isAuthenticated={isAuthenticated}
              isCompleted={isCompletedProfile}
              redirectPath="/complete-profile"
              authenticationPath="/sign-in"
              component={Inbox}
            />
            <ProtectedRoute
              exact
              path="/schedule"
              isAuthenticated={isAuthenticated}
              isCompleted={isCompletedProfile}
              redirectPath="/complete-profile"
              authenticationPath="/sign-in"
              component={Schedule}
            />
            <ProtectedRoute
              exact
              path="/schedule/:tabId"
              isAuthenticated={isAuthenticated}
              isCompleted={isCompletedProfile}
              redirectPath="/complete-profile"
              authenticationPath="/sign-in"
              component={Schedule}
            />
            <ProtectedRoute
              exact
              path="/schedule/history/:historyTabId"
              isAuthenticated={isAuthenticated}
              isCompleted={isCompletedProfile}
              redirectPath="/complete-profile"
              authenticationPath="/sign-in"
              component={Schedule}
            />
            <ProtectedRoute
              exact
              path="/notifications"
              isAuthenticated={isAuthenticated}
              isCompleted={isCompletedProfile}
              redirectPath="/complete-profile"
              authenticationPath="/sign-in"
              component={Notifications}
            />
            <ProtectedRoute
              path="/notifications/:path"
              isAuthenticated={isAuthenticated}
              isCompleted={isCompletedProfile}
              redirectPath="/complete-profile"
              authenticationPath="/sign-in"
              component={Notifications}
            />
            <ProtectedRoute
              exact
              path="/settings"
              isAuthenticated={isAuthenticated}
              isCompleted={isCompletedProfile}
              redirectPath="/complete-profile"
              authenticationPath="/sign-in"
              component={Settings}
            />
            <ProtectedRoute
              path="/settings/:path"
              isAuthenticated={isAuthenticated}
              isCompleted={isCompletedProfile}
              redirectPath="/complete-profile"
              authenticationPath="/sign-in"
              component={Settings}
            />
            <Route path="/sign-in" component={SignInPage} />
            <Route exact path="/sign-up" component={SignUpPage} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route exact path="/account/verify" component={AccountVerify} />
            <Route path="/complete-profile" component={CompleteProfile} />
            <Route exact path="/not-found" component={Error} />
            <Route exact path="/download-app" component={DownloadApp} />
            <Route exact path="/server-issue" component={ServerIssue} />
            <Route exact path="/:username" component={MixProfile} />
            <NeedCompleteProfileRoute
              exact
              redirectPath="/complete-profile"
              isCompleted={isAuthenticated ? isCompletedProfile : true}
              path="/:username/activities/:activityId"
              component={PostDetail}
            />
            <Redirect from="/plans/:id" to="/download-app" />
            <Redirect
              from="/:username/transactions"
              to={{
                pathname: '/schedule/facility-bookings',
                search: window.location.search || '',
              }}
            />
            <Redirect to="/not-found" />
          </Switch>
        </ThemeProvider>
      </Elements>
    </Router>
  );
});

export default App;
