import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import LocalStorageHelper from './helpers/localStorage';

export default function SentryInit() {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: 'https://deb2639a1d08430a87c84bb36cd07f58@sentry.io/3774892',
      environment: process.env.REACT_APP_STAGE,
      normalizeDepth: 10,
      maxBreadcrumbs: 50,
      integrations: [
        new Sentry.Integrations.GlobalHandlers({
          onerror: true,
          onunhandledrejection: false,
        }),
        new Integrations.ExtraErrorData({
          depth: 10,
        }),
      ],
      ignoreErrors: [
        /Network request failed/gm,
        /Failed to fetch/gm,
        /NetworkError/gm,
        /A network error/gm,
        /Network Error/gm,
      ],
    });
    const currentUser = LocalStorageHelper.get('currentUser');
    const id = currentUser?.account?.profileId;
    const email = currentUser?.account?.email;

    Sentry.configureScope(scope => {
      if (id) {
        scope.setUser({ id, email });
      } else {
        scope.setUser(null);
      }
    });
  }
}
