import httpService from './httpService';
import get from 'lodash/get';
import * as Sentry from '@sentry/browser';

import LocalStorageHelper from '../helpers/localStorage';
import { userStore } from '../stores/UserStore';
import { setUserAccount } from './amplitudeService';
import cogoToast from 'cogo-toast';
import firebaseService from './firebaseService';

const AuthService = {
  loginWithToken: async (
    token: string,
    provider: string,
    callback?: Function
  ) => {
    try {
      const response = await httpService.post('auth/login', {
        token,
        provider,
      });
      httpService.defaults.headers.common['Authorization'] =
        response.data.session;
      const { setAccount } = userStore;
      setAccount(response.data.account);
      setUserAccount(response.data.account);
      LocalStorageHelper.set('currentUser', response.data);
      const id = response?.data?.account?.profileId;
      const email = response?.data?.account?.email;

      Sentry.configureScope((scope) => {
        if (id) {
          scope.setUser({ id, email });
        } else {
          scope.setUser(null);
        }
      });

      callback && callback(response?.data?.account?.profile?.status);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  loginWithFacebook: async (fbData: { accessToken: string }) => {
    const check = await AuthService.checkProvider(
      fbData.accessToken,
      'FACEBOOK'
    );
    if (check.isError) {
      cogoToast.error('Login with facebook failed');
    }
    return AuthService.loginWithToken(fbData.accessToken, 'FACEBOOK');
  },
  verifyLogin: async (uid: string, id: string, method: string) => {
    try {
      await httpService.post('auth/verify', { uid, id, method });
      await firebaseService.authChanged();
    } catch (error) {
      throw error;
    }
  },
  checkProvider: async (
    id: string | number,
    provider: 'FACEBOOK' | 'FIREBASE'
  ) => {
    try {
      const response = await httpService.post(`/auth/check/${id}`, {
        provider,
      });
      const providers = get(response, 'data.providers');
      return providers;
    } catch (err) {
      throw err;
    }
  },
  connectProvider: async (
    id: string | number,
    provider: 'FACEBOOK' | 'FIREBASE'
  ) => {
    try {
      const response = await httpService.post('/auth/providers/connect', {
        id,
        provider,
      });
      const account = get(response, 'data.account');
      return account;
    } catch (err) {
      throw err;
    }
  },
  disconnectProvider: async (provider: 'FACEBOOK' | 'FIREBASE') => {
    try {
      const response = await httpService.post('/auth/providers/disconnect', {
        provider,
      });
      const account = get(response, 'data.account');
      return account;
    } catch (err) {
      throw err.response.data;
    }
  },
  signOut: async () => {
    LocalStorageHelper.remove('currentUser');
    LocalStorageHelper.remove('facebookAccessToken');
    Sentry.configureScope((scope) => {
      scope.setUser(null);
    });
    await firebaseService.signOut();
  },
};

export default AuthService;
