import React, { useState, useEffect } from 'react';
import { Box, Flex } from 'rebass';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';

import Text from '../UI/Text';
import { Icon } from '../Primitives';

import { IPeople } from '../../interface/People';

import { getProfileCompletion } from '../../api/ProfileAPI';

import ProfileProgress from './components/ProfileProgress';
import { Body, BackBtn, TickIconCover } from './Profile.styled';

interface IChecklist {
  id: string;
  title: string;
  subtitle: string | null;
  completed: boolean;
  link: string | null;
}

const ProfileCompletion: React.FC<{
  profile: IPeople;
}> = observer(({ profile }) => {
  const [checklist, setChecklist] = useState<IChecklist[]>([]);

  const { progress, username } = profile;

  const history = useHistory();

  useEffect(() => {
    const getChecklist = async () => {
      const checklist: IChecklist[] = await getProfileCompletion();
      setChecklist(checklist);
    };
    getChecklist();
  }, []);

  return (
    <Body py={[2, 3]} flexDirection="column">
      <Flex px="3" pb={[2, 3]} alignItems="center" bg="white">
        <BackBtn
          icon="chevron-left"
          fill="primaryText"
          onClick={() => history.push(`/${username}`)}
        />
        <Text ml="3" fontSize="18px" color="primaryText">
          Profile completion
        </Text>
      </Flex>
      <Flex flexDirection="column" px={[2, 3]}>
        <ProfileProgress progress={progress} />
        <Text color="primaryText" my="3">
          Complete your profile so that you're more likely to me invited to
          games
        </Text>
        {checklist.map((item) => (
          <Flex alignItems="center" fontWeight="bold" mb="3" key={item.id}>
            <TickIconCover
              completed={item.completed}
              width={[32, 24]}
              height={[32, 24]}
            >
              <Icon icon="ok" stroke="white" size="md" />
            </TickIconCover>
            <Box flex="1">
              <Text color="primaryText">{item.title}</Text>
              {!!item.subtitle && <Text>{item.subtitle}</Text>}
            </Box>
          </Flex>
        ))}
      </Flex>
    </Body>
  );
});

export default ProfileCompletion;
