import React from 'react';
import { Dialog } from '@blueprintjs/core';
import { Button, Flex } from 'rebass';

import styled from '../../../theme/styled';

import { Icon } from '../../Primitives';
import Text from '../Text';

const Wrapper = styled(Dialog)`
  margin: 0px;
  padding: 0px;
  height: 100vh;
  width: 100vw;
  background-color: #fafafa;
  @media (min-width: 768px) {
    width: 500px;
    height: 100%;
  }
`;

const Header = styled(Flex)`
  align-items: center;
  @media (min-width: 768px) {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  position: sticky;
  top: 0;
  z-index: 1;
`;

const BackIcon = styled(Icon)`
  position: absolute;
  left: 16px;
  @media (min-width: 768px) {
    display: none;
  }
  cursor: pointer;
  :hover {
    background-color: ${(p) => p.theme.colors.primaryHover};
  }
  border-radius: 100%;
`;

const CloseIcon = styled(Icon)`
  position: absolute;
  right: 16px;
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
  cursor: pointer;
  :hover {
    background-color: ${(p) => p.theme.colors.primaryHover};
  }
  border-radius: 100%;
`;

interface Props {
  isOpen: boolean;
  onClose: any;
  title?: string | React.FC;
  align?: string;
  className?: string;
  actionBtn?: string;
  onClickActionBtn?: Function;
}

const PrimaryDialog: React.FC<Props> = ({
  children,
  isOpen,
  onClose,
  title,
  align = 'center',
  className,
  actionBtn,
  onClickActionBtn,
}) => {
  const handleOnClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClose();
  };

  return (
    <Wrapper isOpen={isOpen} onClose={onClose} className={className}>
      <Header p="3" color="primaryText" bg="white">
        <BackIcon
          icon="chevron-left"
          fill="primaryText"
          size="lg"
          onClick={handleOnClose}
        />
        <Text textAlign={align} px="24px" width="100%" fontSize={[16, 18]}>
          {title}
        </Text>
        {!!actionBtn && (
          <Button variant="noPadding" ml="auto" onClick={onClickActionBtn}>
            {actionBtn}
          </Button>
        )}
        {!actionBtn && (
          <CloseIcon
            fill="primaryText"
            icon="close"
            size="md"
            onClick={handleOnClose}
          />
        )}
      </Header>
      {children}
    </Wrapper>
  );
};

export default PrimaryDialog;
