import React from 'react';
import { Box, Flex, Spinner, Image } from 'theme-ui';
import { observer } from 'mobx-react-lite';
import { DateTime } from 'luxon';

import { Avatar, Text, Icon, Button } from '../../components/Primitives';
import { premiumStore } from '../../stores/PremiumStore';
import { userStore } from '../../stores/UserStore';
import PaymentErrorBox from './components/PaymentErrorBox';

import { benefits } from './PremiumBenefits';

const PremiumExplore = observer(() => {
  const {
    premiumSubscription,
    isCancelledSubscription,
    hasInvoice,
    state,
    resubscription,
    IsAvailableToResubscribe,
  } = premiumStore;
  const { account } = userStore;

  return (
    <Box>
      {state === 'LOADING' && (
        <Flex
          sx={{
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spinner />
        </Flex>
      )}
      {state !== 'LOADING' && (
        <Box
          sx={{
            maxWidth: '1160px',
            mx: [3, 'auto'],
            py: 3,
          }}
        >
          {hasInvoice && <PaymentErrorBox />}
          <Flex
            sx={{
              flexDirection: 'column',
              alignItems: 'center',
              px: 3,
            }}
          >
            <Flex
              sx={{
                alignItems: 'center',
                mb: 2,
              }}
            >
              <Avatar src={account.profile.picture} size="lg" />
              <Text variant="lb.lg" mx="2">
                {account.profile.name}
              </Text>
              <Icon icon="premium" size="lg" />
            </Flex>
            {isCancelledSubscription &&
            premiumSubscription &&
            premiumSubscription.status !== 'CANCELLED' ? (
              <Flex
                px="3"
                sx={{ flexDirection: 'column', alignItems: 'center' }}
              >
                <Text mb="2">
                  Your subscription for Rovo Premium is cancelled
                </Text>
                <Text mb="3">
                  You can still access to all the advanced features until{' '}
                  {DateTime.fromISO(
                    premiumSubscription.chargedThroughDate
                  ).toLocaleString(DateTime.DATE_FULL)}
                </Text>
                {IsAvailableToResubscribe && (
                  <Button
                    fullWidth
                    size="md"
                    mb="3"
                    onClick={resubscription}
                    loading={state === 'SUBMITTING'}
                  >
                    Resubscribe
                  </Button>
                )}
              </Flex>
            ) : (
              <>
                <Text mb="2">You are now a Premium user.</Text>
                <Text mb="4">
                  Enjoy all the advanced features that Premium is bringing to
                  you
                </Text>
              </>
            )}
            <Box>
              {benefits.map((benefit) => {
                return (
                  <Flex
                    key={benefit.title.en}
                    sx={{
                      ':not(:last-child)': {
                        mb: 3,
                      },
                    }}
                  >
                    <Image
                      sx={{
                        maxWidth: '20px',
                        maxHeight: '20px',
                      }}
                      src={benefit.icon}
                    />
                    <Box>
                      <Text variant="lb.md" mx="2">
                        {benefit.title.en}
                      </Text>
                      <Text variant="pg.sm" color="secondaryText" mx="2">
                        {benefit.subtitle.en}
                      </Text>
                    </Box>
                  </Flex>
                );
              })}
            </Box>
          </Flex>
        </Box>
      )}
    </Box>
  );
});

export default PremiumExplore;
