import cogoToast from 'cogo-toast';
import { DateTime } from 'luxon';
import { observable, action, runInAction, computed, when } from 'mobx';

import { userStore } from './UserStore';

import {
  getPremiumCatalog,
  dryrunPremiumOrder,
  purchasePremium,
  getSubscriptions,
  getPaymentMethodsOfCurrency,
  cancelSubscription,
  uncanceledSubscription,
} from './../api/UtilsAPI';
import {
  IPremium,
  ISubscription,
  IPaymentMethod,
  IInvoice,
} from './../interface/Utils';
import { getSelfInvoices } from '../api/ProfileAPI';
import { trialDurations } from '../pages/Premium/PremiumBenefits';

class PremiumStore {
  constructor() {
    when(
      () => userStore.account.id > -1,
      () => {
        this.getPremium();
        this.getInvoices();
        this.getSubscriptions();
      }
    );
  }

  @observable
  premium: IPremium | null = null;

  @observable
  subscriptions: ISubscription[] = [];

  @observable
  paymentMethods: IPaymentMethod[] = [];

  @observable
  invoices: IInvoice[] = [];

  @observable
  hasInvoice: boolean = false;

  @observable
  currentPaymentId: string = '';

  @observable
  isShowToast: boolean = false;

  @observable
  state:
    | 'INITIAL'
    | 'LOADING'
    | 'DONE'
    | 'SUBMITTING'
    | 'PURCHASED'
    | 'PURCHASE_FAILED'
    | 'ERROR' = 'INITIAL';

  @action
  getPremium = async () => {
    this.state = 'LOADING';
    try {
      const premium = await getPremiumCatalog();
      runInAction(() => {
        this.premium = premium;
        this.state = 'DONE';
      });
    } catch (error) {
      runInAction(() => {
        if (window.location.pathname === '/premium') {
          cogoToast.error(
            error.data?.message ||
              error.data?.msg ||
              'There something wrong. please contact with us via support@rovo.co'
          );
        }
        this.state = 'ERROR';
      });
    }
  };

  @action
  getInvoices = async () => {
    this.state = 'LOADING';
    try {
      const invoices = await getSelfInvoices();
      runInAction(() => {
        this.hasInvoice = invoices.length ? true : false;
        this.invoices = invoices;
        this.state = 'DONE';
      });
    } catch (error) {
      cogoToast.error(
        error.data?.message ||
          error.data?.msg ||
          'There something wrong. please contact with us via support@rovo.co'
      );
      this.state = 'ERROR';
    }
  };

  @action
  getSubscriptions = async () => {
    this.state = 'LOADING';
    try {
      const subscriptions: ISubscription[] = await getSubscriptions();
      runInAction(() => {
        this.state = 'DONE';
        this.subscriptions = subscriptions;
      });
    } catch (error) {
      this.state = 'ERROR';
    }
  };

  @action
  getPaymentMethodsOfCurrency = async (currency: string) => {
    this.state = 'LOADING';
    try {
      const paymentMethods = await getPaymentMethodsOfCurrency(currency);
      runInAction(() => {
        this.paymentMethods = paymentMethods;
        this.state = 'DONE';
      });
    } catch (error) {
      this.state = 'ERROR';
    }
  };

  @action
  dryrunPurchasePremium = async (premiumRequest: {
    lineItems: {
      productType: string;
      productId: string;
    }[];
    customerInfo?: {
      firstName?: string;
      lastName?: string;
      email?: string;
    };
    currency: string;
  }) => {
    try {
      const response = await dryrunPremiumOrder(premiumRequest);
      return response;
    } catch (error) {
      throw error;
    }
  };

  @action
  changeState = (
    state:
      | 'INITIAL'
      | 'LOADING'
      | 'DONE'
      | 'SUBMITTING'
      | 'PURCHASED'
      | 'PURCHASE_FAILED'
      | 'ERROR'
  ) => {
    this.state = state;
  };

  @action
  purchasePremium = async (
    token: string,
    customerInfo: {
      firstName?: string;
      lastName?: string;
      email: string;
      mobile?: string;
    },
    productId: string,
    promoCode: string,
    currency: string
  ) => {
    try {
      this.state = 'SUBMITTING';
      await purchasePremium(
        token,
        customerInfo,
        productId,
        this.product?.type || 'BASE',
        promoCode,
        currency
      );
      await this.getSubscriptions();
      await this.getInvoices();
      runInAction(() => {
        this.state = 'PURCHASED';
        userStore.account.profile.subscriptions?.push('premium-pack');
      });
    } catch (error) {
      this.state = 'DONE';
      cogoToast.error(
        error.data?.message || error.data?.msg || 'Purchase premium failed.'
      );
    }
  };

  @action
  cancelSubscription = async (reason: string) => {
    if (!this.premiumSubscription) return;
    this.state = 'SUBMITTING';
    try {
      await cancelSubscription(this.premiumSubscription.id, reason);
      await this.getSubscriptions();
      runInAction(() => {
        this.state = 'DONE';
        if (this.premiumSubscription?.phaseType === 'TRIAL') {
          userStore.account.profile.subscriptions = [];
        }
      });
    } catch (error) {
      this.state = 'ERROR';
      cogoToast.error('Cancel Premium failed');
    }
  };

  @action
  resubscription = async () => {
    if (!this.premiumSubscription) return;
    this.state = 'LOADING';
    try {
      await uncanceledSubscription(this.premiumSubscription.id);
      runInAction(() => {
        if (this.premiumSubscription) {
          this.premiumSubscription.cancelledAt = null;
        }
        this.state = 'DONE';
      });
    } catch (error) {
      this.state = 'ERROR';
      cogoToast.error('Resubscription failed');
    }
  };

  @action
  setIsToast = (value: boolean) => {
    this.isShowToast = value;
  };

  @computed get product() {
    if (!this.premium) return null;
    return (
      this.premium.products.find(
        (product) => product.name === 'premium-pack'
      ) || null
    );
  }

  @computed get plans() {
    if (!this.product) return [];
    return this.product.plans;
  }

  @computed get premiumSubscription() {
    return this.subscriptions.find(
      (subscription) =>
        subscription.productName === 'premium-pack' &&
        subscription.status === 'ACTIVE'
    );
  }

  @computed get isCancelledSubscription() {
    if (!this.premiumSubscription) return false;
    return !!this.premiumSubscription?.cancelledAt;
  }

  @computed get IsAvailableToResubscribe() {
    if (!this.premiumSubscription) return false;
    if (!this.premiumSubscription.cancelledAt) return false;
    return (
      DateTime.fromISO(this.premiumSubscription.cancelledAt) > DateTime.local()
    );
  }

  @computed get renewedDate() {
    if (!this.premiumSubscription) return '';
    const date = DateTime.fromISO(this.premiumSubscription.chargedThroughDate);

    const selectedPlan = trialDurations.find(
      (duration) => duration.planName === this.premiumSubscription?.planName
    );

    if (!selectedPlan) return date.toLocaleString(DateTime.DATE_FULL);

    return this.premiumSubscription.phaseType === 'TRIAL'
      ? date
          .plus({
            [selectedPlan.unit.toLowerCase()]: selectedPlan.number,
          })
          .toLocaleString(DateTime.DATE_FULL)
      : date.toLocaleString(DateTime.DATE_FULL);
  }
}

export const premiumStore = new PremiumStore();
