import React, { useEffect } from 'react';
import { Box } from 'theme-ui';

import FollowList from '../Explore/FollowList';
import Navigation from '../Layout/Navigation';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import VenuePageLoading from './components/VenuePageLoading';
import PartnerBody from './components/PartnerBody';
import partnerStore from '../../stores/partnerStore';

const PartnerPage: React.FC = observer(() => {
  const {
    getPartnerProfileByUri,
    partnerProfile,
    venuesByPartner,
    state,
  } = partnerStore;

  const param: { partnerUri?: string } = useParams();

  useEffect(() => {
    if (
      param?.partnerUri &&
      partnerProfile?.pageInfo.uri !== param.partnerUri
    ) {
      getPartnerProfileByUri(param.partnerUri);
    }
  }, [getPartnerProfileByUri, param, partnerProfile]);

  return (
    <Box
      variant="layout.main"
      sx={{
        gap: '0px',
      }}
    >
      <Box variant="layout.leftSide">
        <Navigation hideOnMobile />
      </Box>
      {!!partnerProfile && state === 'DONE' ? (
        <PartnerBody
          partnerProfile={partnerProfile}
          venuesByPartner={venuesByPartner}
        />
      ) : (
        <VenuePageLoading />
      )}
      <Box variant="layout.rightSide">
        <FollowList />
      </Box>
    </Box>
  );
});

export default PartnerPage;
