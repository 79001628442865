import cogoToast from 'cogo-toast';
import {
  IPartnerProfile,
  IFacilityBookingSchedule,
  ICustomer,
} from './../interface/Venue';
import {
  getVenueByUri,
  getVenueById,
  getPartnerProfile,
  getPageReviews,
  createPageReview,
  getBookingSchedule,
  getCustomerForCurrentProfileByPartnerId,
} from './../api/VenueAPI';
import { observable, action, runInAction, computed } from 'mobx';
import { getVenues } from '../api/SearchAPI';
import { IVenue } from '../interface/Venue';
import { SEARCH_LIMIT } from '../constant';
import { DateTime } from 'luxon';

class VenueStore {
  @observable
  venues: IVenue[] = [];

  @observable
  selectedVenue: IVenue | undefined;

  @observable
  loading: boolean = false;

  @observable
  hasMore: boolean = true;

  query: {
    offset: number;
  } = { offset: 0 };

  @observable
  partnerProfile: IPartnerProfile | undefined;

  @observable
  currentCustomerData: ICustomer | undefined;

  @observable
  venueLoading: boolean = false;

  @action
  getVenues = async (query?: Object, isReset?: boolean) => {
    if (this.loading) return;
    if (!this.hasMore && !isReset) return;
    if (isReset) this.query.offset = 0;
    this.loading = true;
    let venues: IVenue[] = [];
    try {
      venues = await getVenues({
        ...this.query,
        ...query,
      });
    } catch {}
    const dataLength = venues.length;
    runInAction(() => {
      if (dataLength < SEARCH_LIMIT) this.hasMore = false;
      if (!isReset) {
        this.venues = [...this.venues, ...venues];
      } else {
        this.venues = venues;
      }
      this.loading = false;
      this.query.offset =
        dataLength === SEARCH_LIMIT
          ? this.query.offset + dataLength
          : this.query.offset;
    });
  };

  @action
  getPartnerProfile = async (partnerId: string) => {
    try {
      const partnerProfile = await getPartnerProfile(partnerId);
      runInAction(() => {
        this.partnerProfile = partnerProfile;
      });
    } catch (error) {}
  };

  @action
  getVenueByUri = async (venueUri: string, isId?: boolean) => {
    if (this.venueLoading) return;
    this.venueLoading = true;
    try {
      const venue: IVenue = isId
        ? await getVenueById(venueUri)
        : await getVenueByUri(venueUri);
      const partnerProfile = await getPartnerProfile(venue?.partnerId);
      runInAction(() => {
        this.selectedVenue = venue;
        this.partnerProfile = partnerProfile;
      });
    } catch (error) {
      throw error;
    } finally {
      this.venueLoading = false;
    }
  };

  @action
  getSelectedVenue = (venueUri: string, isId?: boolean) => {
    const selectedVenue = this.venues.find(
      (venue) => venue.pageInfo.uri === venueUri
    );
    if (selectedVenue) {
      this.selectedVenue = selectedVenue;
      if (this.partnerProfile?.id !== this.selectedVenue.partnerId) {
        this.getPartnerProfile(this.selectedVenue.partnerId);
      }
    } else {
      this.getVenueByUri(venueUri, isId);
    }
  };

  @observable
  reviews: Object[] = [];

  reviewsParams: { offset: number; limit: number } = {
    offset: 0,
    limit: SEARCH_LIMIT,
  };

  @action
  getPageReviews = async (pageId: string) => {
    try {
      const reviews = await getPageReviews(pageId, this.reviewsParams);
      runInAction(() => {
        this.reviews = reviews;
      });
    } catch (error) {
      throw error;
    }
  };

  @action
  createReview = async (data: {
    pageId: string;
    text: string;
    rating: number;
    reviewerName: string;
    email: string;
    rovoProfileId: number;
  }) => {
    try {
      const review = await createPageReview(data);
      runInAction(() => {
        this.reviews = [review, ...this.reviews];
      });
    } catch (error) {
      cogoToast.error(error?.response?.msg, {
        position: 'top-center',
      });
    }
  };

  @observable
  facilitySchedule: IFacilityBookingSchedule[] = [];

  @observable
  facilityGroup: IFacilityBookingSchedule | undefined;

  @observable
  facilityScheduleLoading: boolean = false;

  @action
  getBookingSchedule = async (date: DateTime) => {
    if (!this.selectedVenue || this.facilityScheduleLoading) return;
    this.facilityScheduleLoading = true;
    try {
      const facilityRequests = this.selectedVenue.facilityGroups.map((group) =>
        getBookingSchedule(group.id, date.toISODate())
      );
      const facilitySchedule = await Promise.all(facilityRequests);
      runInAction(() => {
        this.facilitySchedule = facilitySchedule;
        this.facilityGroup = facilitySchedule.length
          ? facilitySchedule[0]
          : undefined;
      });
    } catch (error) {
    } finally {
      this.facilityScheduleLoading = false;
    }
  };

  @computed
  get facilityGroupsOptions() {
    if (this.selectedVenue)
      return this.selectedVenue.facilityGroups.map((group) => ({
        value: group.id,
        label: group.name,
      }));
    return [];
  }

  @action
  changeFacilityGroup = (facilityGroupId: string) => {
    this.facilityGroup = this.facilitySchedule.find(
      (g) => g.id === facilityGroupId
    );
  };

  @action
  getCustomerForCurrentProfileByPartnerId = async () => {
    if (
      this.partnerProfile?.partnerId &&
      this.currentCustomerData?.partnerId !== this.partnerProfile?.partnerId
    ) {
      try {
        const customer = await getCustomerForCurrentProfileByPartnerId(
          this.partnerProfile.partnerId
        );
        runInAction(() => {
          this.currentCustomerData = customer;
        });
      } catch (error) {}
    }
  };
}

export default VenueStore;
