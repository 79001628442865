import { IGroupInbox } from '../interface/Inbox';
import httpService from '../services/httpService';

export const getGroups = async (params?: {
  limit?: number;
  offset?: number;
  query?: string;
}) => {
  try {
    const response = await httpService.get('me/groups', {
      params: {
        type: 'SOCIAL',
        limit: 10,
        ...params,
      },
    });
    const groups = response?.data?.groups;
    return groups;
  } catch (err) {
    if (err?.response?.status === 404) return [];
    throw err;
  }
};

export const createGroups = async (
  profileIds: number[],
  type: 'PAIR' | 'SOCIAL'
): Promise<IGroupInbox> => {
  try {
    const res = await httpService.post('me/groups', { profileIds, type });
    return res.data?.group;
  } catch (error) {
    throw error;
  }
};

export const getInboxOfPeopleAndActivities = async (params?: {
  before?: string;
  limit?: number;
}) => {
  try {
    const response = await httpService.get('me/inbox/peopleAndActivities', {
      params,
    });
    return response?.data?.data;
  } catch (error) {
    if (error?.response?.statusCOde === 404) return [];
    throw error;
  }
};

export const getInboxPairGroups = async (params?: {
  offset?: number;
  limit?: number;
  query?: string;
}) => {
  try {
    const response = await httpService.get('me/groups', {
      params: {
        ...params,
        order: 'unread',
        type: 'PAIR',
      },
    });
    return response?.data?.groups;
  } catch (error) {
    if (error?.response?.status === 404) return [];
    throw error;
  }
};

export const getInboxActivities = async (params?: {
  before?: string;
  limit?: number;
}) => {
  try {
    const response = await httpService.get('me/inbox/activities', {
      params,
    });
    return response?.data?.data;
  } catch (error) {
    if (error?.response?.statusCOde === 404) return [];
    throw error;
  }
};

export const getGroupMessages = async (
  groupId: number,
  params: { before?: number | null; limit?: number }
) => {
  try {
    const response = await httpService.get(`me/groups/${groupId}/messages`, {
      params,
    });
    return response?.data?.messages;
  } catch (error) {
    if (error?.response?.status === 404) return [];
  }
};

export const getGroupById = async (groupId: number) => {
  try {
    const response = await httpService.get(`groups/${groupId}`);
    return response?.data?.group;
  } catch (error) {
    throw error;
  }
};

export const sendTextMessage = async (groupId: number, text: string) => {
  try {
    const response = await httpService.post(`me/groups/${groupId}/messages`, {
      text,
    });
    return response?.data?.message;
  } catch (error) {
    throw error;
  }
};

export const sendImageMessage = async (groupId: number, image: File) => {
  const formData = new window.FormData();
  formData.append('file', image);
  try {
    const response = await httpService.post(
      `me/groups/${groupId}/messages`,
      formData,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    const media = response?.data?.message;
    return media;
  } catch (err) {
    throw err;
  }
};

export const markAsReadMessage = async (groupId: number, position: number) => {
  try {
    await httpService.post(`me/groups/${groupId}/messages/${position}/read`);
  } catch (error) {
    throw error;
  }
};

export const sendTextComment = async (
  parentType: 'ACTIVITY' | 'PLAY' | 'CLASS',
  parentId: string,
  text: string
) => {
  try {
    let parentUri = '';
    switch (parentType) {
      case 'ACTIVITY':
        parentUri = 'activities';
        break;
      case 'PLAY':
        parentUri = 'plays';
        break;
      case 'CLASS':
        parentUri = 'classSessions';
        break;
      default:
        break;
    }
    const response = await httpService.post(
      `${parentUri}/${parentId}/comments`,
      { text }
    );
    return response?.data?.comment;
  } catch (error) {
    throw error;
  }
};

export const sendImageComment = async (
  parentType: 'ACTIVITY' | 'PLAY' | 'CLASS',
  parentId: string,
  image: File
) => {
  const formData = new window.FormData();
  formData.append('file', image);
  let parentUri = '';
  switch (parentType) {
    case 'ACTIVITY':
      parentUri = 'activities';
      break;
    case 'PLAY':
      parentUri = 'plays';
      break;
    case 'CLASS':
      parentUri = 'classSessions';
      break;
    default:
      break;
  }
  try {
    const response = await httpService.post(
      `${parentUri}/${parentId}/comments`,
      formData,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    return response?.data?.comment;
  } catch (err) {
    throw err;
  }
};

export const sendPollComment = async (
  parentType: string,
  parentId: string,
  poll: any
) => {
  try {
    let parentUri = '';
    switch (parentType) {
      case 'ACTIVITY':
        parentUri = 'activities';
        break;
      case 'PLAY':
        parentUri = 'plays';
        break;
      case 'CLASS':
        parentUri = 'classSessions';
        break;
      default:
        break;
    }
    const response = await httpService.post(
      `${parentUri}/${parentId}/comments`,
      { poll }
    );
    return response?.data?.comment;
  } catch (error) {
    throw error;
  }
};

export const getComments = async (
  type: string,
  id: string,
  params?: { before?: number | null; limit?: number }
) => {
  try {
    let uri = '';
    switch (type) {
      case 'ACTIVITY':
        uri = '/activities';
        break;
      case 'PLAY':
        uri = '/plays';
        break;
      case 'CLASS':
        uri = '/classSessions';
        break;
    }
    const response = await httpService.get(`${uri}/${id}/comments`, { params });
    if (type === 'PLAY') return response?.data;
    return response?.data?.comments;
  } catch (error) {
    if (error?.response?.status === 404) return [];
    throw error;
  }
};

export const markAsReadDiscussion = async (
  playId: string,
  position: number
) => {
  try {
    await httpService.post(`plays/${playId}/comments/${position}/read`);
  } catch (error) {
    throw error;
  }
};

export const getPoll = async (pollId: string) => {
  try {
    const response = await httpService.get(`polls/${pollId}`);
    return response?.data?.poll;
  } catch (error) {
    throw error;
  }
};

export const updatePoll = async (poll: any) => {
  try {
    const response = await httpService.put(`polls/${poll?.id}`, { poll });
    return response?.data?.poll;
  } catch (error) {
    throw error;
  }
};

export const deletePoll = async (pollId: string) => {
  try {
    const response = await httpService.delete(`polls/${pollId}`);
    return response?.data?.poll;
  } catch (error) {
    throw error;
  }
};

export const votePollOption = async (pollId: string, optionId: string) => {
  try {
    const response = await httpService.post(
      `polls/${pollId}/options/${optionId}/vote`
    );
    return response?.data?.poll;
  } catch (error) {
    if (error?.response?.statusCode === 404) return null;
    throw error;
  }
};
export const unVotePollOption = async (pollId: string, optionId: string) => {
  try {
    const response = await httpService.post(
      `polls/${pollId}/options/${optionId}/unvote`
    );
    return response?.data?.poll;
  } catch (error) {
    if (error?.response?.statusCode === 404) return null;
    throw error;
  }
};
