import React, { FunctionComponent } from 'react';
import { Flex } from 'rebass';

import { IActivityData } from '../../../interface/ActivityTimeline';

import ActivityData from '../../UI/Card/ActivityData';

const HostCard: FunctionComponent<{
  item: IActivityData;
}> = ({ item }) => {
  const {
    object: { rawObject },
  } = item;

  if (!rawObject) return null;

  return (
    <Flex flexDirection="column" p="3">
      <ActivityData activity={rawObject} />
    </Flex>
  );
};

export default HostCard;
