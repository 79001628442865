import React from 'react';
import { Box, Grid, Flex, Image, Spinner } from 'theme-ui';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

import { ReactComponent as RovoPremiumText } from '../../assets/illustrator/rovo-premium.svg';
import { ReactComponent as Premium } from '../../assets/icons/stylized/rovo-premium.svg';

import { Text } from '../../components/Primitives';
import { UnAuthHeaderOnlyLogo } from '../../components/Layout/UnAuth/Header';

import SuccessPurchasePremiumDialog from './components/SuccessPurchasePremiumDialog';
import PremiumDetails from './components/PremiumDetails';

import { premiumStore } from '../../stores/PremiumStore';
import { userStore } from '../../stores/UserStore';

import { benefits } from './PremiumBenefits';

const PurchasePremium = observer(() => {
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
  const { account } = userStore;

  const { state, product } = premiumStore;

  const isPremium = account.profile.subscriptions?.includes('premium-pack');

  return (
    <Box>
      <UnAuthHeaderOnlyLogo />
      {(state === 'PURCHASED' || isPremium) && <SuccessPurchasePremiumDialog />}
      {(state === 'LOADING' || state === 'SUBMITTING') && !isPremium && (
        <Flex
          sx={{
            justifyContent: 'center',
            p: 3,
          }}
        >
          <Spinner />
        </Flex>
      )}
      {state === 'DONE' && !isPremium && (
        <Grid
          gap="24px"
          columns={['1fr', '4fr 1fr 5fr']}
          sx={{
            maxWidth: '1160px',
            mx: [3, 3, 3, 'auto'],
          }}
        >
          <Box
            sx={{
              borderRight: ['none', '1px solid'],
              borderColor: ['transparent', 'sportmanGray'],
            }}
          >
            <Flex
              sx={{
                alignItems: 'center',
                mb: '4',
                mt: '24px',
                justifyContent: ['center', 'flex-start'],
              }}
            >
              <RovoPremiumText
                width={isDesktop ? '280px' : '160px'}
                height={isDesktop ? '56px' : '32px'}
              />
              <Flex ml="2">
                <Premium
                  width={isDesktop ? '35px' : '16px'}
                  height={isDesktop ? '35px' : '16px'}
                />
              </Flex>
            </Flex>
            <Flex
              sx={{
                alignItems: 'center',
                maxWidth: '320px',
                mb: 4,
                mx: ['auto', 0],
              }}
            >
              <Box>
                <Image src="/images/premium-1.png" />
              </Box>
              <Box>
                <Image src="/images/premium-2.png" />
              </Box>
              <Box>
                <Image src="/images/premium-3.png" />
              </Box>
              <Box>
                <Image src="/images/premium-4.png" />
              </Box>
              <Box>
                <Image src="/images/premium-5.png" />
              </Box>
            </Flex>
            {benefits.map((benefit) => (
              <Flex
                key={benefit.icon}
                sx={{
                  alignItems: 'center',
                  mb: 3,
                }}
              >
                <Image src={benefit.icon} width="28px" height="28px" />
                <Text variant="pg.lg" ml="2">
                  {benefit.title.en}
                </Text>
              </Flex>
            ))}
          </Box>
          <Box
            sx={{
              display: ['none', 'block'],
            }}
          />
          {!!product && !!product.plans.length && (
            <PremiumDetails currency={account.profile.currency?.code} />
          )}
        </Grid>
      )}
    </Box>
  );
});

export default PurchasePremium;
