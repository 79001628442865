import React from 'react';
import ContentLoader from 'react-content-loader';

const VenuePageLoading = () => (
  <ContentLoader
    height={600}
    width={600}
    speed={2}
    backgroundColor="#E9EEF1"
    foregroundColor="#fafafa"
  >
    <rect x="116" y="84" rx="4" ry="4" width="117" height="6" />
    <rect x="116" y="31" rx="3" ry="3" width="350" height="6" />
    <rect x="116" y="46" rx="3" ry="3" width="380" height="6" />
    <rect x="116" y="65" rx="3" ry="3" width="167" height="6" />
    <circle cx="62" cy="58" r="40" />
    <rect x="27" y="132" rx="16" ry="16" width="552" height="37" />
    <rect x="28" y="190" rx="4" ry="4" width="95" height="150" />
    <rect x="149" y="190" rx="4" ry="4" width="227" height="151" />
    <rect x="401" y="190" rx="4" ry="4" width="174" height="151" />
  </ContentLoader>
);

export default VenuePageLoading;
