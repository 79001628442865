import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Textarea, Flex, Select } from 'theme-ui';
import { useHistory } from 'react-router-dom';

import { PrimaryDialog } from '../../../components/UI/Dialog';
import { Text, Button } from '../../../components/Primitives';

import { premiumStore } from '../../../stores/PremiumStore';

import { ReactComponent as CancelPremium } from '../../../assets/illustrator/cancel-subscription.svg';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const CancelPremiumDialog = observer(({ isOpen, onClose }: Props) => {
  const history = useHistory();

  const {
    cancelSubscription,
    isCancelledSubscription,
    state,
    premiumSubscription,
    renewedDate,
  } = premiumStore;

  const [comment, setComment] = useState('');
  const [reason, setReason] = useState('Bad weather');

  const handleCancel = async () => {
    await cancelSubscription(reason);
  };

  return (
    <PrimaryDialog
      isOpen={isOpen}
      onClose={onClose}
      title="Cancel Subscription"
    >
      {isCancelledSubscription && premiumSubscription ? (
        <Flex p="3" sx={{ flexDirection: 'column', alignItems: 'center' }}>
          <Text mb="2">Your subscription for Rovo Premium is cancelled</Text>
          {premiumSubscription.phaseType !== 'TRIAL' && (
            <Text mb="24px">
              You can still access to all the advanced features until{' '}
              {renewedDate}
            </Text>
          )}
          <Button size="md" fullWidth onClick={() => history.push('/home')}>
            Ok
          </Button>
        </Flex>
      ) : (
        <Flex
          p="3"
          sx={{
            height: '100%',
            flexDirection: 'column',

            textAlign: 'center',
            flex: 1,
          }}
        >
          <Flex
            sx={{
              flexDirection: 'column',
              textAlign: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <CancelPremium />
            <Text
              mb="4"
              mt="3"
              sx={{
                maxWidth: '300px',
              }}
            >
              Sad to see you go... Could you share with us why you are
              cancelling the subscription? We could do better to serve you in
              the future.
            </Text>
          </Flex>

          <Select
            mb="3"
            sx={{
              width: '100%',
            }}
            value={reason}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setReason(e.target.value)
            }
          >
            <option>The price of the pack is not reasonable</option>
            <option>I don't use Rovo frequently</option>
            <option>Other reasons & recommendations</option>
          </Select>
          {reason === 'Other reasons & recommendations' && (
            <Textarea
              mb="3"
              rows={3}
              value={comment}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setComment(e.target.value)
              }
              placeholder="Other reasons or recommendation..."
            />
          )}

          <Button
            sx={{
              mt: 'auto',
            }}
            fullWidth
            size="md"
            mb="3"
            onClick={() => history.push('/home')}
            disabled={state === 'SUBMITTING'}
          >
            I change my mind
          </Button>

          <Button
            fullWidth
            size="md"
            variant="outline.gray"
            onClick={handleCancel}
            disabled={state === 'SUBMITTING'}
            loading={state === 'SUBMITTING'}
          >
            Finish cancelling subscription
          </Button>
        </Flex>
      )}
    </PrimaryDialog>
  );
});

export default CancelPremiumDialog;
