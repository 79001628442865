import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { IPartnerProfile } from '../../interface/Venue';
import BookingHeader from './components/BookingHeader';
import { FacilityBookingDialog } from './FacilityBooking.styled';
import ConfirmBooking from './Steps/ConfirmBooking';
import MakeBooking from './Steps/MakeBooking';
import MakePayment from './Steps/MakePayment';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  partnerProfile: IPartnerProfile;
}

export interface ISelectedSlot {
  facilityId: string;
  start: string;
  end: string;
  date: string;
  name: string;
  price?: { price: number; currency: string };
}

const FacilityBooking: React.FC<Props> = ({
  isOpen,
  onClose,
  partnerProfile,
}) => {
  const [step, setStep] = useLocalStorage(
    `${partnerProfile.id}_booking_step`,
    1
  );
  const [date, setDate] = useState(DateTime.local());
  const [orderId, setOrderId] = useState('');
  const [readableId, setReadableId] = useState('');
  const [processingStatus, setProcessingStatus] = useState('');

  const [selectedSlots, setSelectedSlots] = useLocalStorage(
    `${partnerProfile.id}_booking_slots`,
    []
  );

  const onClickSlot = (slot: ISelectedSlot, isSelected: boolean) => {
    let updateSelectedSlots = [...selectedSlots];
    if (isSelected) {
      updateSelectedSlots = updateSelectedSlots.filter(
        (s) =>
          !(
            s.start === slot.start &&
            s.facilityId === slot.facilityId &&
            s.date === slot.date
          )
      );
    } else {
      updateSelectedSlots.push(slot);
    }
    setSelectedSlots(updateSelectedSlots);
  };

  const onPreClose = () => {
    window.localStorage.removeItem(`${partnerProfile.id}_booking_step`);
    window.localStorage.removeItem(`${partnerProfile.id}_booking_slots`);
    onClose();
  };

  return (
    <FacilityBookingDialog
      isOpen={isOpen}
      onClose={onPreClose}
      canEscapeKeyClose={step !== 2}
    >
      <BookingHeader
        step={step}
        processingStatus={processingStatus}
        onClose={onPreClose}
        logo={partnerProfile.logo}
      />
      {step === 1 && (
        <MakeBooking
          setStep={setStep}
          onClickSlot={onClickSlot}
          selectedSlots={selectedSlots}
          setSelectedSlots={setSelectedSlots}
          date={date}
          setDate={setDate}
        />
      )}
      {step === 2 && (
        <MakePayment
          selectedSlots={selectedSlots}
          setStep={setStep}
          setOrderId={setOrderId}
          setReadableId={setReadableId}
          partnerProfile={partnerProfile}
          onClose={onPreClose}
        />
      )}
      {step === 3 && orderId && (
        <ConfirmBooking
          processingStatus={processingStatus}
          setProcessingStatus={setProcessingStatus}
          readableId={readableId}
          orderId={orderId}
          onClose={onPreClose}
        />
      )}
    </FacilityBookingDialog>
  );
};

export default FacilityBooking;
