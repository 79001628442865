import React, { useRef } from 'react';
import { Box, Flex } from 'rebass';
import { Spinner } from '@blueprintjs/core';

import { SearchInvite } from './ActivityDetail.styled';
import { Text } from 'rebass';
import EmptyList from '../UI/EmptyList';
import { Icon } from '../Primitives';
import { observer } from 'mobx-react-lite';
import { IActivity } from '../../interface/Activity';
import { useBodyInfinityScroll } from '../../hooks/useWindowInfinityScroll';
import debounce from 'lodash/debounce';

import { inboxStore } from '../../stores/InboxStore';
import { useStores } from '../../hooks/useStore';

interface Props {
  activity: IActivity;
}

const SearchGroups: React.FC<Props> = observer(({ activity }) => {
  const { inviteStore } = useStores();
  const { selectedGroups, toggleSelectGroup } = inviteStore;
  const { getGroups, loadingGroups, groups, hasMoreGroup } = inboxStore;

  const searchGroup = debounce(value => {
    getGroups(value, true);
  }, 50);

  const body = useRef<HTMLDivElement>();

  useBodyInfinityScroll(body, getGroups);

  return (
    <Flex flexDirection="column" justifyContent="space-between">
      <Flex flexWrap="wrap" px="3">
        {selectedGroups.map(g => (
          <Flex
            key={g.id}
            sx={{
              borderRadius: '20px',
              bg: 'border',
              p: 1,
              m: 1,
            }}
            alignItems="center"
          >
            <Text mr="1" fontSize="12px" color="primaryText">
              {g.groupProfile.name}
            </Text>
            <Flex
              p="1"
              bg="secondaryText"
              sx={{
                borderRadius: '20px',
              }}
            >
              <Icon
                icon="close"
                size="sm"
                stroke="white"
                onClick={() => toggleSelectGroup(g, true)}
              />
            </Flex>
          </Flex>
        ))}
        <Flex alignItems="center" width="100%" mb="2">
          <Text color="primaryText" fontWeight="bold" mr="1">
            Invite:
          </Text>
          <SearchInvite
            placeholder="Search players"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              searchGroup(e.target.value)
            }
          />
        </Flex>
      </Flex>
      <Flex
        flexDirection="column"
        overflow="auto"
        id="body-scrolling"
        height="calc(100vh - 200px)"
        ref={body}
      >
        {!!groups.length &&
          groups.map(g => {
            const isSelected = selectedGroups.some(gr => gr.id === g.id);
            const locationName =
              g.groupProfile.location?.name ||
              g.groupProfile.location?.locality ||
              g.groupProfile.location?.city;
            return (
              <Flex
                key={g.id}
                sx={{
                  borderBottom: '1px solid #d4dade',
                  ':hover': {
                    bg: 'primaryHover',
                  },
                  cursor: 'pointer',
                }}
                p="2"
                alignItems="center"
                onClick={() => toggleSelectGroup(g, isSelected)}
              >
                <Box
                  sx={{
                    backgroundImage: `url(${g.groupProfile?.picture})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    position: 'relative',
                    borderRadius: '4px',
                  }}
                  height="56px"
                  width="56px"
                ></Box>
                <Box ml="2">
                  <Text
                    sx={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                    color="primaryText"
                    fontWeight="bold"
                  >
                    {g.groupProfile.name}
                  </Text>
                  <Text
                    sx={{
                      textTransform: 'capitalize',
                    }}
                    color="primaryText"
                  >
                    {g.groupProfile.sport?.toLowerCase()}, {locationName}
                  </Text>
                </Box>
                <Box minWidth="24px" ml="auto">
                  {isSelected && <Icon icon="ok" stroke="primary" size="lg" />}
                </Box>
              </Flex>
            );
          })}
        {!groups?.length && !loadingGroups && <EmptyList text="group" />}
        {hasMoreGroup && loadingGroups && (
          <Spinner intent="primary" size={24} />
        )}
      </Flex>
    </Flex>
  );
});

export default SearchGroups;
