import PerfectScrollBar from 'react-perfect-scrollbar';
import styled from '../../theme/styled';
import { Flex } from 'theme-ui';

export const AvatarWrapper = styled(PerfectScrollBar)`
  display: flex;
  overflow: auto;
  padding-bottom: 16px;
  height: inherit;
  > div:not(:last-child) {
    margin-right: 16px;
  }
`;

export const TagWrapper = styled(Flex)`
  > div {
    :not(:last-child) {
      margin-right: 4px;
    }
  }
`;
