import React, { useState, useEffect } from 'react';
import { Image, Flex, Box } from 'rebass';
import { useForm } from 'react-hook-form';

import Text from '../UI/Text';
import { Input, Label } from '../UI/Input';

import { Button } from '../Primitives';
import AuthLayout from './components/Layout';

import { ImagePlaceholder } from './styled';
import { observer } from 'mobx-react-lite';
import { userStore } from '../../stores/UserStore';
import { IUserLocation } from '../../interface/People';
import LocationInput from '../Layout/FilterBar/LocationInput';
import { Icon } from '../Primitives';

type FormData = {
  firstName: string;
  lastName: string;
  avatar: string;
  locationName: string;
};

const LoginPage: React.FC = observer(() => {
  const { updateProfile, uploadImageProfile, account } = userStore;
  const { profile } = account;

  const [serverMessage, setServerMessage] = useState({
    message: '',
    code: '',
    isError: false,
  });

  const [loading, setLoading] = useState(false);

  const [image, setImage] = useState<File | null>(null);

  const [currentLocation, setCurrentLocation] = useState<IUserLocation | null>(
    null
  );

  const { register, handleSubmit, errors, setValue } = useForm<FormData>();

  const onChangeLocation = (newLocation: IUserLocation) => {
    setCurrentLocation(newLocation);
    setValue(
      'locationName',
      newLocation.locality || newLocation.name || newLocation.city,
      true
    );
  };

  const [imagePreviewUrl, setImagePreviewUrl] = useState('');

  useEffect(() => {
    if (profile?.picture) {
      setValue('avatar', profile.picture, true);
      setImagePreviewUrl(profile.picture);
    }
    if (profile.location?.id) {
      setValue(
        'locationName',
        profile.location.name ||
          profile.location.locality ||
          profile.location.city,
        true
      );
      setCurrentLocation(profile.location);
    }
  }, [profile, setValue]);

  const onSubmit = handleSubmit(async ({ firstName, lastName }) => {
    setLoading(true);
    try {
      if (image instanceof File) {
        await uploadImageProfile(image);
      }
      if (currentLocation) {
        await updateProfile({ firstName, lastName, location: currentLocation });
        window.location.href = '/home';
        setLoading(false);
      }
    } catch (err) {
      setServerMessage({ ...err, isError: true });
      setLoading(false);
    }
  });

  const onFileSelected = (e: any) => {
    e.preventDefault();

    let reader = new window.FileReader();
    let file = e.target.files[0];
    if (!file) {
      return;
    }
    reader.onloadend = () => {
      setImage(file);
      if (typeof reader.result === 'string') {
        setImagePreviewUrl(reader.result);
        setValue('avatar', reader.result, true);
      }
    };
    reader.readAsDataURL(file);
  };

  return (
    <AuthLayout formName="Complete your profile">
      <Flex as="form" flexDirection="column" onSubmit={onSubmit}>
        <Text variant="lb.lg" textAlign="center" mb="3">
          Almost there! Just a few more things for Rovo players to get to know
          you better.
        </Text>
        <Box>
          <Input
            style={{ display: 'none' }}
            name="locationName"
            type="text"
            ref={register({ required: true })}
            isError={!!errors.locationName}
          />
          <Text variant="lb.md">Your city or district:</Text>
          {(currentLocation?.name ||
            currentLocation?.city ||
            currentLocation?.locality) && (
            <Flex alignItems="center" justifyContent="space-between" my="2">
              <Text variant="lb.md">
                {currentLocation?.name ||
                  currentLocation?.city ||
                  currentLocation?.locality}
              </Text>
              <Icon
                icon="close"
                onClick={() => {
                  setCurrentLocation(null);
                }}
                size="md"
                fill="primaryText"
              />
            </Flex>
          )}
          <Box mb="2">
            {!(
              currentLocation?.name ||
              currentLocation?.city ||
              currentLocation?.locality
            ) && <LocationInput onClickItem={onChangeLocation} isOverlay />}
            {errors.locationName && (
              <Text color="danger">Location is required</Text>
            )}
          </Box>
        </Box>
        <Text variant="lb.md">Add your best photo of yourself:</Text>
        <Label my="3">
          <ImagePlaceholder
            mx="auto"
            variant="emptyAvatar"
            width="120px"
            height="120px"
            sx={{
              borderColor: !!errors.avatar ? 'red' : 'initial',
              borderStyle: imagePreviewUrl ? 'none' : 'dash',
            }}
          >
            {imagePreviewUrl ? (
              <Image
                width="100%"
                height="100%"
                variant="avatar"
                src={imagePreviewUrl}
                alt="imagePreview"
                style={{
                  objectFit: imagePreviewUrl ? 'cover' : 'none',
                }}
              />
            ) : (
              <Text fontSize="50px" fontWeight="100">
                +
              </Text>
            )}
          </ImagePlaceholder>

          <input
            style={{ display: 'none' }}
            type="file"
            name="image"
            accept="image/x-png,image/gif,image/jpeg"
            onChange={onFileSelected}
          />
          <input
            style={{ display: 'none' }}
            type="text"
            name="avatar"
            accept="image/x-png,image/gif,image/jpeg"
            ref={register({ required: true })}
          />
        </Label>
        <Box mb="2">
          <Text variant="lb.md"> First name:</Text>
          <Input
            disabled={loading}
            name="firstName"
            type="text"
            defaultValue={profile.firstName}
            ref={register({ required: true })}
            isError={!!errors.firstName}
          />
        </Box>
        {errors.firstName && <Text color="danger">First name is required</Text>}
        <Box>
          <Text variant="lb.md">Last name:</Text>
          <Input
            disabled={loading}
            name="lastName"
            type="text"
            defaultValue={profile.lastName}
            ref={register({ required: true })}
            isError={!!errors.lastName}
          />
        </Box>
        {errors.lastName && <Text color="danger">Last name is required</Text>}
        {!!serverMessage.message && (
          <Text color={serverMessage.isError ? 'danger' : 'primary'}>
            {serverMessage.message}
          </Text>
        )}

        <Button loading={loading} type="submit" my="3" size="lg">
          <Text variant="lb.md" color="white">
            Complete profile
          </Text>
        </Button>
      </Flex>
    </AuthLayout>
  );
});

export default LoginPage;
