import React from 'react';
import { Box } from 'rebass';

import Text from '../../UI/Text';

import styled from '../../../theme/styled';

const BaseProgressLine = styled(Box)`
  background-color: ${p => p.theme.colors.border};
  border-radius: 8px;
`;

const FillProgressLine = styled(Box)`
  height: 6px;
  border-radius: 8px;
  background: linear-gradient(359.22deg, #b7ce62 0%, #06bebd 100%);
`;

const ProfileProgress: React.FC<{ progress: number }> = ({ progress }) => {
  return (
    <>
      <Text color="primaryText" mb="1">
        {progress}% profile completion
      </Text>
      <BaseProgressLine>
        <FillProgressLine width={`${progress}%`} />
      </BaseProgressLine>
    </>
  );
};

export default ProfileProgress;
