import React from 'react';
import { Dialog } from '@blueprintjs/core';
import { Flex } from 'rebass';

import styled from '../../../theme/styled';

import Text from '../Text';

const Wrapper = styled(Dialog)`
  margin: 0px;
  padding: 0px;
  height: initial;
  width: initial;
`;

interface Props {
  children: React.ReactChild | string | null;
  isOpen: boolean;
  onClose: () => void;
  className?: string;
}

const PrimaryDialog: React.FC<Props> = ({
  children,
  isOpen,
  onClose,
  className,
}) => {
  return (
    <Wrapper isOpen={isOpen} onClose={onClose} className={className}>
      <Flex flexDirection="column" color="primaryText" p="3">
        {children}
        <Text
          mt="3"
          onClick={onClose}
          fontWeight="bold"
          alignSelf="flex-end"
          sx={{
            cursor: 'pointer',
          }}
        >
          OK
        </Text>
      </Flex>
    </Wrapper>
  );
};

export default PrimaryDialog;
