import React from 'react';
import { Flex } from 'theme-ui';
import { useHistory } from 'react-router-dom';

import { Button } from '../Primitives';

const UnAuthNav: React.FC = () => {
  const history = useHistory();
  return (
    <Flex
      sx={{
        flexDirection: 'column',
      }}
    >
      <Button size="md" mx="3" mb="3" onClick={() => history.push('/sign-up')}>
        Sign Up
      </Button>
      <Button
        variant="outline"
        size="md"
        mx="3"
        mb="3"
        onClick={() => history.push('/sign-in')}
      >
        Sign In
      </Button>
    </Flex>
  );
};

export default UnAuthNav;
