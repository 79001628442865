import React from 'react';
import { Box } from 'theme-ui';

import Navigation from '../Layout/Navigation';
import SettingsMenu from './SettingsMenu';
import SettingsBody from './SettingsBody';
import { useMediaQuery } from 'react-responsive';

const ExplorePage: React.FC = () => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  return (
    <Box
      variant="layout.setting"
      sx={{
        gap: '0px',
      }}
    >
      <Box variant="layout.leftSide">
        <Navigation hideOnMobile />
      </Box>
      {isDesktop && <SettingsMenu />}
      <SettingsBody />
    </Box>
  );
};

export default ExplorePage;
