import { Spinner } from '@blueprintjs/core';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import { Flex } from 'theme-ui';
import { useStores } from '../../../hooks/useStore';
import { Button, Text } from '../../Primitives';
import { ISelectedSlot } from '../FacilityBooking';
import {
  ConfirmBtn,
  FacilityName,
  LeftStickyColumn,
  Slot,
  SlotContainer,
  StickyBottomBar,
  TableBody,
  TableContainer,
  TableHeader,
  TableHeaderWrapper,
  TimeSlot,
} from '../FacilityBooking.styled';
import FilterBar from '../FilterBar';

interface Props {
  setStep: Function;
  onClickSlot: (slot: ISelectedSlot, isSelected: boolean) => void;
  selectedSlots: ISelectedSlot[];
  setSelectedSlots: Function;
  date: DateTime;
  setDate: (date: DateTime) => void;
  isReschedule?: boolean;
  numOfReschedule?: number;
}

const MakeBooking: React.FC<Props> = observer(
  ({
    setStep,
    selectedSlots,
    onClickSlot,
    setSelectedSlots,
    date,
    setDate,
    isReschedule,
    numOfReschedule,
  }) => {
    const { venueStore } = useStores();
    const {
      facilityGroupsOptions,
      facilityGroup,
      facilityScheduleLoading,
      getBookingSchedule,
      changeFacilityGroup,
      selectedVenue,
    } = venueStore;

    useEffect(() => {
      getBookingSchedule(date);
    }, [date, getBookingSchedule]);

    const onChangeFacilityGroup = ({
      value,
      label,
    }: {
      value: string;
      label: string;
    }) => {
      changeFacilityGroup(value);
      setSelectedSlots([]);
    };

    const bodyRef = useRef<HTMLDivElement>();
    const headerRef = useRef<HTMLDivElement>();
    const firstRowRef = useRef<HTMLDivElement>();

    const onBodyScroll = () => {
      if (
        !firstRowRef ||
        !bodyRef ||
        !headerRef ||
        !firstRowRef.current ||
        !bodyRef.current ||
        !headerRef.current
      )
        return;
      if (firstRowRef?.current?.scrollTop !== bodyRef?.current?.scrollTop) {
        firstRowRef.current.scrollTop = bodyRef.current.scrollTop;
      }
      if (headerRef?.current?.scrollLeft !== bodyRef?.current?.scrollLeft) {
        headerRef.current.scrollLeft = bodyRef.current.scrollLeft;
      }
    };

    return (
      <Flex
        sx={{
          height: '100%',
          flexDirection: 'column',
        }}
      >
        {!!facilityGroup && (
          <FilterBar
            options={facilityGroupsOptions}
            facilityGroup={{
              value: facilityGroup.id,
              label: facilityGroup.name,
            }}
            handleChange={onChangeFacilityGroup}
            setDate={setDate}
            date={date}
          />
        )}
        <TableHeaderWrapper>
          <TableHeader ref={headerRef}>
            {facilityGroup?.facilities?.length &&
              facilityGroup?.facilities.map((facility) => {
                return (
                  <FacilityName
                    key={facility.facilityId}
                    variant="body"
                    fontWeight="bold"
                    mx="1"
                  >
                    {facilityGroup.publicFacilityNames
                      ? facility.name
                      : facilityGroup.name}
                  </FacilityName>
                );
              })}
          </TableHeader>
        </TableHeaderWrapper>
        {facilityGroup &&
        facilityGroup.facilities[0].slots &&
        !facilityScheduleLoading ? (
          <TableContainer>
            <LeftStickyColumn ref={firstRowRef}>
              {facilityGroup?.facilities[0].slots.map((slot) => (
                <TimeSlot
                  key={slot.start}
                  flexDirection="column"
                  alignItems="flex-end"
                >
                  <Text variant="lb.xs">
                    {DateTime.fromFormat(slot.start, 'hh:mm').toFormat('h:mm')}
                  </Text>
                  <Text variant="lb.sm" color="secondaryText">
                    {DateTime.fromFormat(slot.start, 'hh:mm').toFormat('a')}
                  </Text>
                </TimeSlot>
              ))}
            </LeftStickyColumn>
            <SlotContainer>
              <TableBody ref={bodyRef} onScroll={onBodyScroll}>
                {facilityGroup?.facilities.map((facility) => {
                  return (
                    <Flex
                      key={facility.facilityId}
                      sx={{
                        flexDirection: 'column',
                        minWidth: '120px',
                      }}
                      mx="1"
                    >
                      {facility.slots.map((slot) => {
                        const isSelected = selectedSlots.some(
                          (s) =>
                            s.start === slot.start &&
                            s.facilityId === facility.facilityId &&
                            s.date === slot.date
                        );

                        if (
                          slot.status === 'CLOSED' ||
                          DateTime.local().toUTC().toISO() >
                            DateTime.fromFormat(
                              `${slot.date} ${slot.end}`,
                              'yyyy-MM-dd h:mm',
                              { zone: selectedVenue?.timezone }
                            )
                              .toUTC()
                              .toISO()
                        )
                          return (
                            <Slot
                              key={slot.start + slot.end + slot.date}
                              bg="dark5"
                              sx={{
                                cursor: 'not-allowed',
                              }}
                            >
                              <Text variant="lb.md">CLOSED</Text>
                            </Slot>
                          );
                        if (slot.booking)
                          return (
                            <Slot
                              key={slot.start + slot.end + slot.date}
                              bg="dark5"
                              sx={{
                                cursor: 'not-allowed',
                              }}
                            >
                              <Text variant="lb.md">Unavailable</Text>
                            </Slot>
                          );
                        return (
                          <Slot
                            key={slot.start + slot.end + slot.date}
                            sx={{
                              cursor: 'pointer',
                              bg: isSelected ? 'primary' : 'dark7',
                            }}
                            onClick={() =>
                              onClickSlot(
                                {
                                  facilityId: facility.facilityId,
                                  start: slot.start,
                                  end: slot.end,
                                  date: slot.date,
                                  name: facility.name,
                                  price: slot.price,
                                },
                                isSelected
                              )
                            }
                          >
                            <Text
                              variant="lb.md"
                              color={isSelected ? 'white' : 'primaryText'}
                            >
                              {slot.price?.price === 0
                                ? 'Free'
                                : `${slot.price?.price} ${slot.price?.currency}`}
                            </Text>
                          </Slot>
                        );
                      })}
                    </Flex>
                  );
                })}
              </TableBody>
            </SlotContainer>
          </TableContainer>
        ) : (
          <Flex
            sx={{
              width: '100%',
              justifyContent: 'center',
            }}
            p="3"
          >
            <Spinner intent="primary" />
          </Flex>
        )}
        {!!selectedSlots.length && !isReschedule && (
          <StickyBottomBar>
            <Button
              sx={{
                mr: 'auto',
              }}
              variant="outline.normal"
              size="md"
              color="primaryText"
              onClick={() => setSelectedSlots([])}
            >
              Clear
            </Button>
            <ConfirmBtn onClick={() => setStep(2)}>
              Book{' '}
              {selectedSlots.length > 1
                ? `${selectedSlots.length} slots`
                : '1 slot'}
            </ConfirmBtn>
          </StickyBottomBar>
        )}
        {isReschedule && numOfReschedule && (
          <Flex
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
              px: 3,
              py: 2,
              bg:
                selectedSlots.length === numOfReschedule ? 'primary' : 'white',
              mt: 'auto',
              mx: ['0', '-16px'],
              position: 'sticky',
              bottom: '0px',
            }}
          >
            <Text variant="lb.md" color="white">
              {selectedSlots.length}/
              {numOfReschedule > 1 ? `${numOfReschedule} slots` : '1 slot'}{' '}
              selected
            </Text>
            {selectedSlots.length === numOfReschedule && (
              <Button onClick={() => setStep(3)}>
                <Text variant="lb.md">Next</Text>
              </Button>
            )}
          </Flex>
        )}
      </Flex>
    );
  }
);

export default MakeBooking;
