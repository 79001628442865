import io from 'socket.io-client';
import { reaction, observable, action } from 'mobx';

import LocalStorageHelper from '../helpers/localStorage';
import config from '../config';
import { activityDetailStore } from '../stores/ActivityDetailStore';
import { userStore } from '../stores/UserStore';

import { IActivity } from '../interface/Activity';
import { IComment } from '../interface/Inbox';

class WebsocketService {
  constructor() {
    const currentUser = LocalStorageHelper.get('currentUser');
    if (currentUser?.session) {
      this.session = currentUser.session;
    }
  }

  @observable
  isConnected: boolean = false;

  @observable
  socket: SocketIOClient.Socket | null = null;

  @observable
  session: string = '';

  onProfileChange = reaction(
    () => this.session,
    (session) => {
      if (session) {
        this.connect();
      } else {
        this.disconnect();
      }
    }
  );

  @action
  connect = () => {
    if (this.socket || this.isConnected) return;
    this.socket = io(config.SOCKET_HOST, {
      path: '/v1.9.0/socket.io/',
      query: { session: this.session },
    });

    this.socket.on('connect', () => {
      this.isConnected = true;
    });

    this.socket.on(
      'new comment',
      (data: { play: IActivity; comment: IComment }) => {
        if (userStore.account.profileId !== data.comment.senderId) {
          activityDetailStore.updateComments(data.play, data.comment);
        }
      }
    );

    this.socket.on('disconnect', () => {
      this.isConnected = false;
      this.connect();
    });
  };

  @action
  disconnect = () => {
    if (!this.socket) return;
    this.socket.on('disconnect', () => {
      this.isConnected = false;
      this.socket?.close();
    });
  };
}

const websocketService = new WebsocketService();

export default websocketService;
