import { Flex } from 'rebass';

import styled from '../../theme/styled';
import { Text } from 'rebass';

export const PageWrapper = styled(Flex)`
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const PageBody = styled(Flex)`
  z-index: 0;
  flex-direction: column;
  width: 100%;
  @media (min-width: 768px) {
    width: 600px;
  }
  .bp3-tab-list {
    position: sticky;
    top: 56px;
    background-color: ${(p) => p.theme.colors.white};
    z-index: 1;
    border-bottom: ${(p) => p.theme.border.box};
  }
`;

export const PageHeading = styled(Flex)`
  position: sticky;
  top: 0;
  height: 56px;
  border-bottom: ${(p) => p.theme.border.box};
  background-color: ${(p) => p.theme.colors.white};
  z-index: 1;
`;

export const TabTitle = styled(Text)`
  padding: 4px 16px;
  font-weight: bold;
`;
