import React from 'react';
import { Box, Flex } from 'rebass';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';

import Avatar from '../../UI/Avatar';
import Text from '../../UI/Text';

import { IGroupInbox } from '../../../interface/Inbox';
import { fromServer, isToday } from '../../../helpers/datetime';

import { userStore } from '../../../stores/UserStore';

import styled from '../../../theme/styled';

const Wrapper = styled(Flex)`
  :hover {
    transform: scale(1.005);
  }
  padding: 8px 4px;
  border-radius: 4px;
  border-bottom: ${p => p.theme.border.box};
  cursor: pointer;
`;

const Unread = styled(Text)`
  border-radius: 20px;
  position: absolute;
  padding: 4px 8px;
  font-size: 10px;
  font-weight: bold;
  right: 0px;
`;

const PeopleInbox: React.FC<{ groupInbox: IGroupInbox }> = observer(
  ({ groupInbox }) => {
    const {
      account: { profileId },
    } = userStore;
    const { members, lastMessage, id } = groupInbox;
    const selected =
      members.find(m => m.profile.id !== profileId) || members[0];
    const self = members.find(m => m.profile.id === profileId) || members[1];
    const person = selected.profile;
    const { picture, pulseLevel } = person;
    const numberOfUnread =
      lastMessage !== null ? lastMessage.position - Number(self?.lastRead) : 0;
    const history = useHistory();

    return (
      <Wrapper
        alignItems="center"
        mb="2"
        onClick={() => history.push(`/inbox/g/${id}`)}
      >
        <Box minWidth="40px">
          <Avatar pulseLevel={pulseLevel} imageLink={picture} size="40px" />
        </Box>
        <Box flex="1 1 auto" ml="3" color="primaryText">
          <Text fontWeight="bold" mb="1">
            {selected?.profile.name}
          </Text>
          <Text>
            {lastMessage?.content?.text || 'Say hi or invite to game'}
          </Text>
        </Box>
        {lastMessage !== null && (
          <Box alignSelf="flex-start" sx={{ position: 'relative' }}>
            <Text fontSize="12px">
              {fromServer(
                lastMessage.updatedAt,
                '',
                isToday(lastMessage.updatedAt) ? 'H:mm a' : 'EEE, MMM dd'
              )}
            </Text>
            {!!numberOfUnread && (
              <Unread bg="danger" color="white">
                {numberOfUnread}
              </Unread>
            )}
          </Box>
        )}
      </Wrapper>
    );
  }
);

export default PeopleInbox;
