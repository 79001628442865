import { divider } from './colors';
// Brand colors
import { elevation } from './shadows';
import text from './text';
import buttons from './buttons';
import forms from './forms';
import extendColors, { primaryGreen, primaryHover } from './colors';

export const brand1 = '#06BEBD';

// Secondary colors
const gray1 = '#212F3D';
// const gray2 = '#454851';
const gray3 = '#667190';
const gray3Hover = 'rgba(102,113,144, 0.1)';
const gray4 = '#ADBBC4';
const gray5 = '#D4DADE';
const gray6 = '#E9EEF1';

// Gray scale colors
export const gray = '#FAFAFA';
const white = '#FFFFFF';
// const black = '#000000';

// Additional colors
export const greenGradient = ['#DFEC92', '#B7CE63'];
export const yellowGradient = ['#FAD961', '#FAB450'];
export const coralGradient = ['#FCC79E', '#FA8161'];
export const redGradient = ['#9A6CB0', '#EE4054'];
export const purple = '#737BC4';
export const red = '#EE4054';
export const redHover = 'rgba(238,64,84, 0.1)';
export const yellow = '#FFC452';
export const green = '#4CC065';
export const coral = '#EE9872';
export const facebookPrimary = '#3b5998';

// Background

const theme = {
  colors: {
    ...extendColors,
  },
  border: {
    searchInput: `1px solid ${gray5}`,
    box: `1px solid ${gray6}`,
  },
  fontSizes: [8, 10, 12, 14, 16, 18, 24, 36, 48],
  shadows: elevation,
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 500,
  },
  variants: {
    card: {
      p: 3,
      bg: 'white',
      borderRadius: '8px',
      boxShadow: 'below.low',
    },
    avatar: {
      border: '2px solid transparent',
      borderRadius: '50%',
    },
    clickableAvatar: {
      border: '2px solid transparent',
      borderRadius: '50%',
      cursor: 'pointer',
    },
    dot: {
      width: '5px',
      height: '5px',
      backgroundColor: gray4,
      margin: '0px 4px',
      borderRadius: '5px',
    },
    emptyAvatar: {
      border: `2px dashed ${gray5}`,
      borderRadius: '50%',
    },
    line: {
      height: '0px',
      borderBottom: `1px solid ${gray6}`,
      width: '100%',
      vertical: {
        height: '100%',
        width: '1px',
        borderBottom: `1px solid ${gray6}`,
      },
    },
    shadowCard: {
      boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
      borderRadius: '12px',
    },
    badge: {
      backgroundColor: gray1,
      borderRadius: '24px',
      padding: '0px 8px',
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'bold',
    },
    number: {
      danger: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '9999px',
        backgroundColor: 'danger',
        fontSize: '2',
        color: 'white',
        fontWeight: 'bold',
        padding: '1',
        height: '20px',
      },
    },
    activityTag: {
      gameOn: {
        border: `1.5px solid`,
        borderColor: 'primary',
        color: 'primary',
        borderRadius: '4px',
        p: 1,
      },
      ranked: {
        border: `1.5px solid`,
        borderColor: 'playfulPurple',
        color: 'playfulPurple',
        borderRadius: '4px',
        p: 1,
      },
      host: {
        border: `1.5px solid`,
        borderColor: 'globalGreen',
        color: 'globalGreen',
        borderRadius: '4px',
        p: 1,
      },
    },
    paymentTag: {
      paid: {
        border: `1px solid ${primaryGreen}`,
        color: primaryGreen,
        borderRadius: '2px',
        fontSize: '2',
        py: '2px',
        px: '2',
        textAlign: 'center',
      },
      canceled: {
        border: `1px solid ${coral}`,
        color: coral,
        borderRadius: '2px',
        fontSize: '2',
        py: '2px',
        px: '2',
        textAlign: 'center',
      },
      unpaid: {
        border: `1px solid ${red}`,
        color: red,
        borderRadius: '2px',
        fontSize: '2',
        py: '2px',
        px: '2',
        textAlign: 'center',
      },
    },
    backgroundGradient: {
      background: `linear-gradient(344.64deg, ${primaryGreen} 0%, ${brand1} 98.22%)`,
    },

    // new variant
    cardBox: {
      border: '1px solid',
      borderColor: 'border',
      borderRadius: '4px',
      px: 3,
      py: '24px',
    },
    tags: {
      primary: {
        border: '1px solid',
        borderColor: 'primary',
        color: 'primary',
        borderRadius: '24px',
        p: 1,
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: '500',
      },
    },
  },
  text,
  buttons: {
    primary: {
      cursor: 'pointer',
      borderRadius: '6px',
      '&:active': {
        background:
          'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #06BEBD',
      },
      '&:disabled': {
        opacity: '0.5',
      },
    },
    transparent: {
      border: 'none',
      color: brand1,
      backgroundColor: 'transparent',
      '&:hover': {
        background: primaryHover,
      },
      cursor: 'pointer',
    },
    noPadding: {
      border: 'none',
      color: brand1,
      backgroundColor: 'transparent',
      cursor: 'pointer',
      padding: 0,
    },
    outline: {
      border: `1px solid ${brand1}`,
      color: brand1,
      backgroundColor: 'transparent',
      cursor: 'pointer',
      '&:hover': {
        background: primaryHover,
      },
      danger: {
        color: red,
        '&:hover': {
          background: redHover,
        },
        border: `1px solid ${red}`,
        backgroundColor: 'transparent',
        cursor: 'pointer',
      },
      dismiss: {
        color: gray3,
        '&:hover': {
          background: gray3Hover,
        },
        border: `1px solid ${gray3}`,
        backgroundColor: 'transparent',
        cursor: 'pointer',
      },
      normal: {
        color: white,
        '&:hover': {
          background: 'primary',
        },
        border: `1px solid ${white}`,
        backgroundColor: 'transparent',
        cursor: 'pointer',
      },
    },
    disabled: {
      backgroundColor: gray4,
      cursor: 'not-allowed',
    },
    ...buttons,
  },
  forms,
  layout: {
    leftSide: {
      borderRight: ['none', `1px solid ${divider}`],
    },
    rightSide: {
      borderLeft: ['none', `1px solid ${divider}`],
    },
    main: {
      display: ['flex', 'grid'],
      flexDirection: ['column', 'initial'],
      gap: '0px',
      maxWidth: '1160px',
      mx: 'auto',
      gridTemplateColumns: ['1fr', '2fr  5fr', '2fr 5fr 3fr'],
    },
    setting: {
      display: ['flex', 'grid'],
      flexDirection: ['column', 'initial'],
      gap: '24px',
      maxWidth: '1160px',
      mx: 'auto',
      gridTemplateColumns: ['1fr', '2fr  5fr', '2fr 3fr 5fr'],
    },
  },
  images: {
    scale: {
      objectFit: 'contain',
    },
  },
  breakpoints: ['768px', '1024px', '1280px'],
};

export default theme;

export type Theme = typeof theme;
