import { userStore } from './UserStore';
import { updateFollow, fetchActivityLike } from './../api/ProfileAPI';
import { decorate, observable, action, runInAction } from 'mobx';
import { ILiker } from '../interface/People';
import { SEARCH_LIMIT } from '../constant';

class LikersStore {
  likers: ILiker[] = [];

  loading: boolean = false;

  hasMore: boolean = true;

  params: {
    offset: number;
    limit: number;
  } = { offset: 0, limit: SEARCH_LIMIT };

  getActivityLikes = async (activityId: string, isReset?: boolean) => {
    if (this.loading) return;
    if (!this.hasMore && !isReset) return;
    if (isReset) {
      this.likers = [];
      this.params.offset = 0;
    }
    this.loading = true;
    let likers: ILiker[] = [];
    try {
      likers = await fetchActivityLike(activityId, this.params);
    } catch { }
    const dataLength = likers.length;
    runInAction(() => {
      if (dataLength < SEARCH_LIMIT) this.hasMore = false;
      if (!isReset) {
        this.likers = [...this.likers, ...likers];
      } else {
        this.likers = likers;
      }
      this.loading = false;
      this.params.offset =
        dataLength === SEARCH_LIMIT
          ? this.params.offset + dataLength
          : this.params.offset;
    });
  };

  updateFollow = async (liker: ILiker) => {
    const { liker: { followStatus, id, isPrivate } } = liker;
    const isFollow = followStatus !== 'NOT_FOLLOWING';
    const selectedFollowerIndex = this.likers.findIndex(f => f.liker.id === id)
    const following = userStore.account.profile.following
    runInAction(() => {
      this.likers[selectedFollowerIndex].liker.followStatus = !isFollow ? isPrivate ? 'REQUESTED' : 'FOLLOWING' : 'NOT_FOLLOWING'
      userStore.account.profile.following = isPrivate ? following : !isFollow ? following + 1 : following - 1;
    })
    try {
      await updateFollow(id, isFollow);
    } catch (error) {
      runInAction(() => {
        this.likers[selectedFollowerIndex].liker.followStatus = followStatus
        userStore.account.profile.following = following
      })
    }
  }
}

decorate(LikersStore, {
  likers: observable,
  loading: observable,
  hasMore: observable,
  getActivityLikes: action,
  updateFollow: action,
});

export const likersStore = new LikersStore();
