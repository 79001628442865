import React, { useEffect, useMemo } from 'react';
import { Box, Flex, Spinner } from 'theme-ui';
import { useParams, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import ActivityDetail from './ActivityDetail';
import Navigation from '../Layout/Navigation';
import FollowList from '../Explore/FollowList';

import { activityDetailStore } from '../../stores/ActivityDetailStore';

import { Body } from './ActivityDetail.styled';

const ActivityDetailPage = observer(() => {
  const {
    activity,
    getActivityDetail,
    getComments,
    loadingDetail,
  } = activityDetailStore;

  const param: { activityName?: string } = useParams();
  const history = useHistory();

  const activityName = useMemo(() => param?.activityName, [param]);

  useEffect(() => {
    const getData = async () => {
      if (activityName) {
        getActivityDetail(activityName);
        getComments(activityName, true);
      }
    };
    getData();
  }, [activityName, getActivityDetail, getComments]);

  return (
    <Box
      variant="layout.main"
      sx={{
        gap: '0px',
      }}
    >
      <Box variant="layout.leftSide">
        <Navigation hideOnMobile />
      </Box>
      <Body>
        {activity && !loadingDetail && (
          <ActivityDetail
            activity={activity}
            onClose={() => history.goBack()}
          />
        )}

        {!activity && (
          <Flex
            sx={{
              width: '100%',
              height: '100vh',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '24px',
              color: 'primaryText',
            }}
          >
            {loadingDetail && <Spinner />}
            {!loadingDetail && 'No activity found'}
          </Flex>
        )}
      </Body>
      <Box variant="layout.rightSide">
        <FollowList />
      </Box>
    </Box>
  );
});

export default ActivityDetailPage;
