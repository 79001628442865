import httpService from '../services/httpService';

export const getAttendances = async (request?: { sessionIds: string }) => {
  try {
    const data = await httpService.post(
      'partners/classes/sessions/attendances/list',
      request
    );
    const attendances = data?.data?.data || [];
    return attendances;
  } catch (err) {
    throw err;
  }
};

export const getActivityData = async (name: string) => {
  try {
    const response = await httpService.get(`plays/${name}`);
    return response?.data?.play;
  } catch (error) {
    throw error;
  }
};

export const updateProfilePlayStatus = async (
  gameId: string,
  status: 'DECLINED' | 'ACCEPTED'
) => {
  try {
    const response = await httpService.put(`me/plays/${gameId}`, { status });
    return response?.data?.play;
  } catch (error) {
    throw error;
  }
};

export const getPostData = async (activityId: string) => {
  try {
    const response = await httpService.get(`activities/${activityId}`);
    return response?.data?.activity;
  } catch (error) {
    throw error;
  }
};

export const invitePlayersToGame = async (
  gameId: string,
  profileIds: number[]
) => {
  try {
    const response = await httpService.post(`plays/${gameId}/invite`, {
      profileIds,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const inviteGroupsToGame = async (
  gameId: string,
  groupIds: number[]
) => {
  try {
    const data = groupIds.map(g => ({ groupId: g }));
    const response = await httpService.post(
      `plays/${gameId}/groups/invite`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const searchPlayers = async (params: {
  query?: string;
  offset: number;
  limit: number;
  includeContacts?: boolean;
  playId: string;
  sport: string[];
}) => {
  try {
    const response = await httpService.get('players/search', {
      params: {
        ...params,
        isFavourite: !params.query ? true : false,
      },
    });
    return response?.data?.profiles;
  } catch (error) {
    if (error?.response?.status === 404) return [];
    throw error;
  }
};

export const getInvitedPlayers = async (
  params: {
    offset: number;
    limit: number;
  },
  playId: string
) => {
  try {
    const response = await httpService.get(`plays/${playId}/players/invited`, {
      params,
    });
    return response?.data?.players;
  } catch (error) {
    if (error?.response?.status === 404) return [];

    throw error;
  }
};

interface Invite {
  type: 'APP' | 'GROUP' | 'PLAY';
  objectId: string;
  channel: 'SMS' | 'EMAIL';
  channelId: string;
  message: string;
  name: string;
}

export const reserveSpots = async (
  playId: string,
  profileIds: number[],
  invites?: Invite[]
) => {
  try {
    const data = {
      invites: invites || [],
      profileIds,
      enableSMS: true,
    };
    await httpService.post(`plays/${playId}/reserve`, data);
  } catch (error) {
    throw error;
  }
};
