import React, { FunctionComponent } from 'react';
import { Spinner } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { IGroup } from '../../interface/Group';
import { groupStore } from '../../stores/GroupStore';

import useWindowInfinityScroll from '../../hooks/useWindowInfinityScroll';
import EmptyList from '../UI/EmptyList';

import { GroupCard } from '../Cards';
import { Box } from 'rebass';

type Props = {
  query: Object;
};

const GroupsTab: FunctionComponent<Props> = observer(({ query }) => {
  const { groups, getGroups, hasMore, loading } = groupStore;

  useWindowInfinityScroll(getGroups, query);

  return (
    <Box pb={['108px', 5]}>
      {groups.map((group: IGroup) => (
        <Box mb="3">
          <GroupCard key={group.id} group={group} />
        </Box>
      ))}
      {!groups.length && !loading && <EmptyList text="groups" />}

      {hasMore && loading && <Spinner size={24} intent="primary" />}
    </Box>
  );
});

export default GroupsTab;
