export const PULSE_LEVELS: { [index: string]: [number, number] } = {
  SPARK: [0, 79],
  FLICKER: [80, 249],
  EMBER: [250, 1199],
  FLAME: [1200, 2499],
  WILDFIRE: [2500, 7999],
  BLAZE: [8000, 19999],
  FIRESTORM: [20000, 49999],
  INFERNO: [50000, 50000],
};

export const getPulseLevel = (pulseLevel: string, pulsePoints: number) => {
  const [min, max] = PULSE_LEVELS[pulseLevel];
  return Math.floor(((pulsePoints - min) / (max - min)) * 100);
};
