import React, { useState, useEffect } from 'react';
import { Text, Box, Flex } from 'rebass';
import { DateTime } from 'luxon';

import { Icon } from '../../Primitives';
import { IOpeningHours } from '../../../interface/Venue';
import { OPEN_HOURS_PARSE } from '../../../constant';

interface Props {
  openingHours: IOpeningHours;
  timezone: string | null;
}

const OpenTime: React.FC<Props> = ({ openingHours, timezone }) => {
  const [isShow, setIsShow] = useState(false);
  const [isOpenNow, setIsOpenNow] = useState(false);

  const currentDayOfWeek = DateTime.local()
    .toFormat('ccc')
    .slice(0, 2)
    .toUpperCase();

  useEffect(() => {
    const validate = () => {
      const isOpenNow = Object.entries(openingHours).some(([key, value]) => {
        if (key === currentDayOfWeek && value.length > 0) {
          const [start, end] = value[0].split('-');
          return (
            DateTime.fromFormat(start, 'h:mm').setZone(timezone || '') <=
              DateTime.local().setZone(timezone || '') &&
            DateTime.fromFormat(end, 'h:mm').setZone(timezone || '') >=
              DateTime.local().setZone(timezone || '')
          );
        }
        return false;
      });
      setIsOpenNow(isOpenNow);
    };
    validate();
  }, [openingHours, timezone, currentDayOfWeek]);

  return (
    <Box px="3" py="2">
      <Flex
        sx={{
          cursor: 'pointer',
        }}
        onClick={() => setIsShow(s => !s)}
      >
        <Icon icon="time" size="md" />
        <Text variant="body" ml="2">
          {isOpenNow ? 'Open now' : 'Open time'}
        </Text>
        <Icon icon={isShow ? 'chevron-up' : 'chevron-down'} size="md" />
      </Flex>
      {isShow ? (
        <Box mt="2">
          {Object.entries(openingHours).map(([key, value]) =>
            OPEN_HOURS_PARSE[key] ? (
              <Flex
                justifyContent="space-between"
                py="1"
                px="3"
                key={key}
                sx={{
                  bg: currentDayOfWeek === key ? 'primaryHover' : 'initial',
                  fontWeight: currentDayOfWeek === key ? 'bold' : 'initial',
                }}
              >
                <Text variant="body">{OPEN_HOURS_PARSE[key]}</Text>
                <Text variant="body">{value.length ? value[0] : 'Closed'}</Text>
              </Flex>
            ) : null
          )}
        </Box>
      ) : null}
    </Box>
  );
};

export default OpenTime;
