export const ScheduleSlot: { [index: string]: number } = {
  MON_MORNING: 0,
  MON_AFTERNOON: 1,
  MON_EVENING: 2,

  TUE_MORNING: 3,
  TUE_AFTERNOON: 4,
  TUE_EVENING: 5,

  WED_MORNING: 6,
  WED_AFTERNOON: 7,
  WED_EVENING: 8,

  THU_MORNING: 9,
  THU_AFTERNOON: 10,
  THU_EVENING: 11,

  FRI_MORNING: 12,
  FRI_AFTERNOON: 13,
  FRI_EVENING: 14,

  SAT_MORNING: 15,
  SAT_AFTERNOON: 16,
  SAT_EVENING: 17,

  SUN_MORNING: 18,
  SUN_AFTERNOON: 19,
  SUN_EVENING: 20,
};

export const SORT_TYPES: { [index: string]: string } = {
  time: 'TIME',
  distance: 'NEAREST',
  'recently added': 'NEWEST',
  recommended: 'RECOMMENDED',
  'most active': 'MOST_ACTIVE',
  'latest activity': 'LATEST_ACTIVITY',
  'a to z': 'A_TO_Z',
  'highest rating': 'HIGHEST_RATING',
};

export const SORT_OPTIONS: { [index: string]: string[] } = {
  activities: ['time', 'distance', 'recently added'],
  people: ['recommended', 'distance', 'recently added', 'most active'],
  groups: ['latest activity', 'recently added', 'a to z', 'distance'],
  venues: ['distance', 'highest rating', 'a to z'],
  classes: ['distance', 'recently added'],
};

export const DISPLAY_FILTER_OPTION: { [index: string]: string[] } = {
  activities: ['location', 'level', 'schedule', 'showFull'],
  people: ['location', 'level', 'schedule', 'gender'],
  groups: ['location'],
  venues: ['location'],
  classes: ['location'],
};

export function encodeSchedule(slots: number[]): number {
  let schedule = 0;
  slots.forEach(slot => {
    schedule |= 1 << slot;
  });
  return schedule;
}

export function decodeSchedule(schedule: number): number[] {
  return [...Array(21).keys()].filter(index => {
    return ((schedule || 0) & (1 << index)) !== 0;
  });
}
