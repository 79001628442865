import React, { useEffect } from 'react';

import InboxPage from '../components/Inbox';
import { trackPageView } from '../services/amplitudeService';

const Inbox: React.FC = () => {
  useEffect(() => {
    trackPageView('Inbox');
  }, []);

  return <InboxPage />;
};

export default Inbox;
