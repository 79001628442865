import React from 'react';
import { Dialog } from '@blueprintjs/core';
import { Box, Flex } from 'rebass';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
} from 'react-share';

import Text from '../UI/Text';

const ShareDialog: React.FC<{
  isOpen: boolean;
  onClose: any;
  url: string;
  text?: string;
}> = ({ isOpen, onClose, text, url = 'https://inrovo.co/minhphan' }) => {
  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <Box p="3">
        <Text color="primaryText" fontSize="16px" mb="3">
          {text || 'Share your profile'}
        </Text>
        <Flex justifyContent="space-between">
          <FacebookShareButton onShareWindowClose={onClose} url={url}>
            <FacebookIcon />
          </FacebookShareButton>
          <TwitterShareButton onShareWindowClose={onClose} url={url}>
            <TwitterIcon />
          </TwitterShareButton>
          <WhatsappShareButton onShareWindowClose={onClose} url={url}>
            <WhatsappIcon />
          </WhatsappShareButton>
          <EmailShareButton url={url}>
            <EmailIcon />
          </EmailShareButton>
        </Flex>
      </Box>
    </Dialog>
  );
};

export default ShareDialog;
