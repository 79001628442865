import { Box, Card, Flex, Image } from 'rebass';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Dialog } from '@blueprintjs/core';

import styled from '../../../theme/styled';

export const Wrapper = styled(PerfectScrollbar)`
  display: flex;
  padding-bottom: 16px;
`;

export const SportCard = styled(Card)`
  position: relative;
  margin-right: 8px;
  background: linear-gradient(148.08deg, #685aa0 0%, #03bebd 100%);
  border-bottom-left-radius: 32px;
  border-top-right-radius: 16px;
`;

export const WrapperImg = styled(Box)`
  position: absolute;
  overflow: hidden;
  align-self: flex-end;
  width: 250px;
  top: 0;
  left: 0;
`;

export const CoverRight = styled(Box)`
  mix-blend-mode: overlay;
  display: block;
  width: 100%;
  background-image: url(${p => p.src});
  background-size: cover;
  clip-path: polygon(75% 100%, 100% 100%, 100% 0%, 50% 0%);
`;

export const AddSportCard = styled(Card)`
  background: linear-gradient(135.58deg, #685aa0 0%, #03bebd 100%);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 16px;
`;

export const AddSportIcon = styled(Flex)`
  border-radius: 50%;
  width: 48px;
  height: 48px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.1);
  justify-content: center;
  align-items: center;
`;

export const RankedLevel = styled(Flex)`
  border-radius: 100%;
  border: 1px solid ${p => p.theme.colors.white};
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
`;

export const BadgeCount = styled(Flex)`
  z-index: 3;
`;

export const Badge = styled(Image)`
  position: absolute;
  left: ${p => p.left * 24}px;
  z-index: ${p => 2 - p.left};
`;

export const BadgeWrapper = styled(Flex)`
  position: relative;
  width: 40px;
  left: -16px;
`;

export const AddSportDialog = styled(Dialog)`
  margin: 0px;
  height: 100vh;
  @media (min-width: 768px) {
    margin: 30px 0;
    height: calc(100vh - 60px);
  }
  background-color: ${p => p.theme.colors.white};
`;

export const SportDetailGraph = styled(Box)`
  background: linear-gradient(120.45deg, #685aa0 0%, #03bebd 100%);
`;

export const SportDetailDialog = styled(Dialog)`
  padding: 0px;
  width: 100%;
  margin: 0px;
  @media (min-width: 768px) {
    margin: 30px 0;
    width: 500px;
  }
`;

export const Achievements = styled(PerfectScrollbar)`
  display: flex;
  align-items: flex-end;
`;

export const Achievement = styled(Flex)`
  position: relative;
`;
export const BadgeHeight = styled(Box)`
  width: 50px;
  margin-top: -16px;
  background: linear-gradient(
    0deg,
    rgba(183, 206, 99, 0.0001) 0%,
    rgba(6, 190, 189, 0.5) 98.07%
  );
`;

export const NumForEachBadge = styled(Box)`
  position: absolute;
  font-size: 12px;
  border-radius: 4px;
  right: 0px;
  z-index: 2;
  padding: 0px 8px;
`;
