import React from 'react';
import { useHistory } from 'react-router-dom';

import { Flex } from 'theme-ui';
import { Icon, Text } from '../Primitives';
import { IconNames } from '../Primitives/Icon/iconTypes';

import styled from '../../theme/styled';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../hooks/useStore';

const NotificationIcon = styled(Flex)`
  position: relative;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 4px;
  :hover {
    background-color: ${(p) => p.theme.colors.primaryHover};
    border-radius: 99px;
  }
`;

const Notification = styled(Flex)`
  border-radius: 99px;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
`;

const BackBtn = styled(Icon)`
  cursor: pointer;
  :hover {
    background-color: ${(p) => p.theme.colors.primaryHover};
  }
  border-radius: 100%;
  margin-right: 8px;
`;

const HeaderWrapper = styled(Flex)`
  border-bottom: ${(p) => p.theme.border.box};
  position: sticky;
  top: 0;
  z-index: 2;
`;

const Header: React.FC<{
  name: string;
  onClickIcon?: Function;
  secondaryIcon?: IconNames;
  noSecondaryIcon?: boolean;
}> = observer(
  ({ name, secondaryIcon, noSecondaryIcon, onClickIcon = () => {} }) => {
    const history = useHistory();

    const { notificationStore, scheduleStore } = useStores();
    const { totalNotification } = notificationStore;
    const { totalActivities } = scheduleStore;

    return (
      <Flex
        as="header"
        sx={{
          justifyContent: 'space-between',
          display: ['flex', 'none'],
        }}
        p="3"
      >
        <NotificationIcon>
          <Icon
            icon="calendar-event"
            size="md"
            onClick={() => history.push('/schedule')}
          />
          {totalActivities > 0 && (
            <Notification color="white" bg="primary" variant="lb.xxs">
              {totalActivities}
            </Notification>
          )}
        </NotificationIcon>
        {!!name && (
          <Text variant="pg.lg" ml="24px">
            {name}
          </Text>
        )}
        <Flex
          sx={{
            alignItems: 'center',
          }}
        >
          {!noSecondaryIcon && (
            <Icon
              icon={secondaryIcon || 'search'}
              size="md"
              onClick={() => onClickIcon()}
            />
          )}
          <NotificationIcon ml="2">
            <Icon
              icon="bell"
              onClick={() => history.push('/notifications')}
              size="md"
            />
            {totalNotification > 0 && (
              <Notification color="white" bg="danger" variant="lb.xxs">
                {totalNotification}
              </Notification>
            )}
          </NotificationIcon>
        </Flex>
      </Flex>
    );
  }
);

export const HeaderMobile: React.FC<{
  text?: string;
  hasBackBtn?: boolean;
  backLink?: string;
  children?: React.ReactChild;
}> = ({ text, hasBackBtn, backLink, children }) => {
  const history = useHistory();

  return (
    <HeaderWrapper
      sx={{
        alignItems: 'center',
        minHeight: '50px',
      }}
      px="3"
      py="2"
      bg="white"
    >
      <Flex
        sx={{
          alignItems: 'center',
        }}
      >
        {hasBackBtn && (
          <BackBtn
            icon="chevron-left"
            fill="primaryText"
            onClick={() =>
              backLink ? history.push(backLink) : history.goBack()
            }
          />
        )}
        <Text variant="pg.lg">{text}</Text>
      </Flex>
      {children}
    </HeaderWrapper>
  );
};

export default Header;
