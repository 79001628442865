import React from 'react';
import { Box, Flex } from 'rebass';
import { useHistory } from 'react-router-dom';

import Text from '../../UI/Text';
import { Icon } from '../../Primitives';

import { IActivity } from '../../../interface/Activity';
import { fromServer, isToday } from '../../../helpers/datetime';

import styled from '../../../theme/styled';
import { HTMLText } from '../../Primitives/Text';

const ActivityAvatar = styled(Flex)`
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 40px;
  border-radius: 40px;
`;

const Wrapper = styled(Flex)`
  :hover {
    transform: scale(1.005);
  }
  padding: 8px 4px;
  border-radius: 4px;
  border-bottom: ${(p) => p.theme.border.box};
  cursor: pointer;
`;

const Unread = styled(Text)`
  border-radius: 20px;
  position: absolute;
  padding: 4px 8px;
  font-size: 10px;
  font-weight: bold;
  right: 0px;
`;

const ActivityInbox: React.FC<{ activity: IActivity; profileId: number }> = ({
  activity,
  profileId,
}) => {
  const { name, sport, start, lastComment, id, players } = activity;

  const {
    content: { text },
    position,
  } = lastComment;

  const history = useHistory();

  const self = players.find((p) => p.profileId === profileId) || players[0];

  const numberOfUnread =
    lastComment !== null ? position - Number(self?.readPosition) : 0;

  return (
    <Wrapper
      alignItems="center"
      mb="2"
      onClick={() => history.push(`/plays/${id}?current=activity-discussions`)}
    >
      <ActivityAvatar variant="backgroundGradient">
        <Icon icon="calendar" size="md" stroke="white" />
      </ActivityAvatar>
      <Box flex="1 1 auto" ml="3" color="primaryText">
        <Text fontWeight="bold" mb="1">
          {name}
        </Text>
        <Flex alignItems="center" mb="1" color="secondaryText">
          <Text fontSize="12px" fontWeight="bold">
            {sport}
          </Text>
          <Box variant="dot" />
          <Text>{fromServer(start, '', 'EEE dd MMM')}</Text>
        </Flex>
        <Text>
          <HTMLText text={text} />
        </Text>
      </Box>
      {lastComment !== null && (
        <Box alignSelf="flex-start" sx={{ position: 'relative' }}>
          <Text fontSize="12px">
            {fromServer(
              lastComment.updatedAt,
              '',
              isToday(lastComment.updatedAt) ? 'H:mm a' : 'EEE, MMM dd'
            )}
          </Text>
          {numberOfUnread > 0 && (
            <Unread bg="danger" color="white">
              {numberOfUnread}
            </Unread>
          )}
        </Box>
      )}
    </Wrapper>
  );
};

export default ActivityInbox;
