import { decorate, observable, action, runInAction } from 'mobx';
import { getNotificationPreferences, updateNotificationPreferences } from '../api/SettingsAPI';

export interface INotification {
  id: string,
  preferences: {
    id: string,
    title: string,
    subtitle: string,
    options: {
      allowedValues: {
        id: string,
        name: 'On' | 'Off',
      }[],
      id: string
      name: string
      value: 'on' | 'off'
    }[]
  }[],
  title: string
}

class SettingStore {
  notificationPreferences: INotification[] = [];

  loading: boolean = false;

  getNotificationPreferences = async () => {
    this.loading = true;
    try {
      const notificationPreferences = await getNotificationPreferences();
      runInAction(() => {
        this.notificationPreferences = [...notificationPreferences];
      })
    } catch { }
    this.loading = false;
  }

  updateNotificationPreferences = async (selectedItem: INotification) => {
    const selectedIndex = this.notificationPreferences.findIndex(item => item.id === selectedItem.id)
    if (selectedIndex > -1) this.notificationPreferences[selectedIndex] = selectedItem;
    this.loading = true;
    try {
      await updateNotificationPreferences(this.notificationPreferences);
    } catch { }
    this.loading = false;
  }
}

decorate(SettingStore, {
  notificationPreferences: observable,
  loading: observable,
  getNotificationPreferences: action,
  updateNotificationPreferences: action,
});

export const settingStore = new SettingStore();
