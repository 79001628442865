import React from 'react';
import { Box, Label } from 'theme-ui';

import { Button, Text, PhoneInput } from '../../Primitives';

type Props = {
  phone: string;
  setPhone: (phone: string) => void;
  handleSendPhone: () => void;
  isSubmitting: boolean;
};

const PhoneVerify = ({
  handleSendPhone,
  isSubmitting,
  phone,
  setPhone,
}: Props) => {
  return (
    <Box>
      <Text variant="pg.lg">Verify phone number</Text>
      <Text variant="pg.sm" mb="3" color="secondaryText">
        Verify your phone number before your first booking
      </Text>
      <Label mb="2">Phone</Label>
      <Box mb="3">
        <PhoneInput phone={phone} setPhone={setPhone} disabled={isSubmitting} />
      </Box>
      <Button
        onClick={() => handleSendPhone()}
        size="lg"
        disabled={isSubmitting || phone.length < 8}
        loading={isSubmitting}
        sx={{
          width: '100%',
        }}
      >
        Next
      </Button>
    </Box>
  );
};

export default PhoneVerify;
