import React, { FunctionComponent, useState, useRef, useEffect } from 'react';
import { Flex, Image } from 'theme-ui';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import Link from '../UI/Link';

import LogoFullTextColor from '../../assets/logo-full-text-color.svg';

import {
  Wrapper,
  Logo,
  NavigationItem,
  NavigationWrapper,
  ContainerWrapper,
  SettingMenuWrapper,
} from './Navigation.styled';

import { userStore } from '../../stores/UserStore';
import { useOnClickOutside } from '../../hooks/useOuterClick';
import { MORE_MENU } from '../../constant/settings';
import NavigatorShare from '../../helpers/socialShare';
import LocalStorageHelper from '../../helpers/localStorage';

import ShareDialog from './ShareDialog';
import UnAuthNav from './UnAuthNav';
import config from '../../config';
import { IconNames } from '../Primitives/Icon/iconTypes';
import { Icon, Text } from '../Primitives';
import useHover from '../../hooks/useHover';
import { premiumStore } from '../../stores/PremiumStore';
import PaymentErrorBox from '../../pages/Premium/components/PaymentErrorBox';
import { useStores } from '../../hooks/useStore';

type Props = {
  selected?: string;
  hideOnMobile?: boolean;
};

type ItemProps = {
  name: string;
  selected?: string;
  imageLink?: string;
  username?: string;
  activeIcon: IconNames;
  icon: IconNames;
};

const Item: React.FC<ItemProps> = ({
  name,
  selected,
  username,
  imageLink,
  activeIcon,
  icon,
  children,
}) => {
  const [hoverRef, isHovered] = useHover();

  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });

  const isActive = selected === name;
  return (
    <Link to={username ? `/${username}` : `/${name}`}>
      <NavigationItem
        ref={hoverRef}
        alignItems="center"
        justifyContent="space-between"
        px={['24px', 0]}
        pl={['24px', 2]}
        isActive={isActive}
      >
        <Flex
          sx={{
            flexDirection: ['column', 'row'],
            alignItems: 'center',
          }}
        >
          {imageLink ? (
            <Image
              src={imageLink}
              variant="avatar"
              sx={{
                width: '24px',
                height: ' 24px',
                border: '2px solid',
                borderColor: isActive || isHovered ? 'primary' : 'transparent',
                borderRadius: '24px',
              }}
            />
          ) : (
            <Icon icon={isActive || isHovered ? activeIcon : icon} size="lg" />
          )}
          {isDesktop && (
            <Text
              variant="lb.lg"
              ml={[0, 3]}
              color={isActive || isHovered ? 'primary' : 'primaryText'}
              textTransform="capitalize"
            >
              {name}
            </Text>
          )}
        </Flex>
        {children}
      </NavigationItem>
    </Link>
  );
};

const SettingItem: React.FC<{
  icon: IconNames;
  text: string;
  link: string;
  isExtend?: boolean;
  onClick: Function;
}> = ({ icon, text, link, isExtend, onClick }) => {
  return (
    <Link to={link} isExtend={isExtend}>
      <NavigationItem alignItems="center" px="2" onClick={onClick}>
        <Icon icon={icon} size="lg" />
        <Text variant="lb.md" ml="3">
          {text}
        </Text>
      </NavigationItem>
    </Link>
  );
};

export const MenuSetting: React.FC<{
  setMoreMenuStatus: Function;
  className?: string;
  openShare: Function;
}> = observer(({ setMoreMenuStatus, className, openShare }) => {
  const {
    account: { profile },
  } = userStore;
  const { username } = profile;

  const menu = useRef();

  useOnClickOutside(menu, () => setMoreMenuStatus(false));

  const handleShare = () => {
    NavigatorShare(
      () => {
        setMoreMenuStatus(false);
        openShare(true);
      },
      { url: `${config.URL}/${username}` },
      () => openShare(false)
    );
  };

  return (
    <SettingMenuWrapper
      className={className}
      ref={menu}
      variant="shadowCard"
      bg="white"
      width="250px"
      py="2"
    >
      <NavigationItem
        sx={{
          ':hover': {
            color: 'primary',
          },
        }}
        alignItems="center"
        px="2"
        onClick={handleShare}
      >
        <Icon icon="share-android" size="lg" />
        <Text variant="lb.md" ml="3">
          Share Profile
        </Text>
      </NavigationItem>
      {MORE_MENU.map((item) => (
        <SettingItem
          key={item.text}
          {...item}
          onClick={() => setMoreMenuStatus(false)}
        />
      ))}
    </SettingMenuWrapper>
  );
});

const Navigation: FunctionComponent<Props> = observer(
  ({ selected, hideOnMobile }) => {
    const history = useHistory();
    const [isOpenMore, setMoreMenuStatus] = useState(false);
    const [isOpenShare, setShareDialogStatus] = useState(false);

    const { notificationStore, scheduleStore } = useStores();
    const { totalNotification } = notificationStore;
    const { totalActivities } = scheduleStore;

    const {
      account: { profile },
    } = userStore;
    const { invoices, isShowToast, setIsToast } = premiumStore;
    const { picture, username } = profile;

    const currentUser = LocalStorageHelper.get('currentUser');

    const isShowOthers = useMediaQuery({ query: '(min-width: 768px)' });

    useEffect(() => {
      if (invoices.length && !isShowToast) {
        cogoToast.error(<PaymentErrorBox />, {
          position: 'top-right',
          onClick: () => history.push('/premium/billing'),
          hideAfter: 10,
          renderIcon: () => null,
        });
        setIsToast(true);
      }
    }, [invoices, history, isShowToast, setIsToast]);

    if (hideOnMobile && !isShowOthers) return null;

    return (
      <Wrapper>
        <ContainerWrapper flexDirection="column">
          <Logo
            alt="rovo-text-logo"
            src={LogoFullTextColor}
            width="104px"
            height="25px"
            my="24px"
            onClick={() => history.push('/')}
          />
          {currentUser && currentUser.session ? (
            <NavigationWrapper
              sx={{
                bg: ['white', 'inherit'],
                boxShadow: ['above.low', 'none'],
              }}
            >
              <Item
                name="home"
                activeIcon="home-alt-colored"
                icon="home-alt"
                selected={selected}
              />
              <Item
                name="feed"
                activeIcon="feed-colored"
                icon="feed"
                selected={selected}
              />
              <Item
                name="inbox"
                activeIcon="chat-colored"
                icon="chat"
                selected={selected}
              />
              <Item
                name="profile"
                selected={selected}
                username={username}
                imageLink={picture}
                icon="people"
                activeIcon="people"
              />
              {isShowOthers && (
                <>
                  <Item
                    name="schedule"
                    activeIcon="calender-colored"
                    icon="calendar"
                    selected={selected}
                  >
                    {!!totalActivities && (
                      <Flex
                        sx={{
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: 'white',
                          bg: 'primary',
                          fontWeight: 500,
                          fontSize: '12px',
                          borderRadius: ' 16px',
                          width: '16px',
                          height: '16px',
                          mr: '3',
                        }}
                      >
                        {totalActivities}
                      </Flex>
                    )}
                  </Item>
                  <Item
                    name="notifications"
                    activeIcon="bell-colored"
                    icon="bell"
                    selected={selected}
                  >
                    {!!totalNotification && (
                      <Flex
                        sx={{
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: 'white',
                          bg: 'danger',
                          fontWeight: 500,
                          fontSize: '12px',
                          borderRadius: ' 16px',
                          width: '16px',
                          height: '16px',
                          mr: '3',
                        }}
                      >
                        {totalNotification}
                      </Flex>
                    )}
                  </Item>
                  <Item
                    name="premium"
                    activeIcon="premium"
                    icon="premium"
                    selected={selected}
                  />
                  <NavigationItem
                    pl="2"
                    alignItems="center"
                    onClick={() => setMoreMenuStatus(true)}
                  >
                    <Icon icon="hamburger" size="lg" />
                    <Text variant="lb.lg" ml="3">
                      More
                    </Text>
                  </NavigationItem>
                  {isOpenMore && (
                    <MenuSetting
                      openShare={setShareDialogStatus}
                      setMoreMenuStatus={setMoreMenuStatus}
                    />
                  )}
                </>
              )}
            </NavigationWrapper>
          ) : (
            <UnAuthNav />
          )}
          <ShareDialog
            isOpen={isOpenShare}
            onClose={() => setShareDialogStatus(false)}
            url={`${config.URL}/${username}`}
          />
        </ContainerWrapper>
      </Wrapper>
    );
  }
);

export default Navigation;
