import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Flex } from 'rebass';
import styled from '../../../../theme/styled';

export const SportDetailGraph = styled(Box)`
  background: linear-gradient(120.45deg, #685aa0 0%, #03bebd 100%);
`;

export const Achievements = styled(PerfectScrollbar)`
  display: flex;
  align-items: flex-end;
`;

export const Achievement = styled(Flex)`
  position: relative;
`;
export const BadgeHeight = styled(Box)`
  width: 50px;
  margin-top: -16px;
  background: linear-gradient(
    0deg,
    rgba(183, 206, 99, 0.0001) 0%,
    rgba(6, 190, 189, 0.5) 98.07%
  );
`;

export const NumForEachBadge = styled(Box)`
  position: absolute;
  font-size: 12px;
  border-radius: 4px;
  right: 0px;
  z-index: 2;
  padding: 0px 8px;
`;
