import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Box, Flex, Image } from 'theme-ui';
import { Text } from '../../Primitives';

import Signature from '../../../assets/signature.png';

import { ExtendLink } from '../../UI/Link';

import { FooterItem } from './styled';

const Footer: React.FC = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
  const isShowSignatureLine = useMediaQuery({ query: '(min-width: 1100px)' });

  return (
    <Flex
      px={[3, '24px']}
      sx={{
        borderTop: '1px solid #D1DCE8',
        flexWrap: 'wrap',
        minHeight: '56px',
        alignItems: 'center',
      }}
    >
      {isDesktop && (
        <>
          <ExtendLink to="https://www.notion.so/rovo/About-Rovo-2347b729578840b4a2c6aaed26f60bf4">
            <FooterItem variant="pg.sm">About</FooterItem>
          </ExtendLink>
          <ExtendLink to="https://www.notion.so/rovo/Rovo-Careers-208a2e996fa642269e3be77ec6d39aa4">
            <FooterItem variant="pg.sm">Careers</FooterItem>
          </ExtendLink>
          <ExtendLink to="https://www.notion.so/rovo/Press-c9b856150da74a41ad292637d0f0fbb9">
            <FooterItem variant="pg.sm">Press</FooterItem>
          </ExtendLink>
          <ExtendLink to="https://blog.rovo.co">
            <FooterItem variant="pg.sm">Blog</FooterItem>
          </ExtendLink>
          {/* <ExtendLink to="mailto:friends@rovo.co">
            <FooterItem variant="pg.sm">Contact</FooterItem>
          </ExtendLink>
          <ExtendLink to="https://support.rovo.co">
            <FooterItem variant="pg.sm">Support</FooterItem>
          </ExtendLink> */}
          <ExtendLink to="https://page.rovo.co/p/privacy">
            <FooterItem variant="pg.sm">Privacy</FooterItem>
          </ExtendLink>
          <ExtendLink to="https://page.rovo.co/p/terms">
            <FooterItem variant="pg.sm">Terms & Conditions</FooterItem>
          </ExtendLink>
          <ExtendLink to="https://page.rovo.co/p/community-guidelines">
            <FooterItem variant="pg.sm">Community Guidelines</FooterItem>
          </ExtendLink>
        </>
      )}
      <Text
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
        variant="pg.sm"
      >
        © {new Date().getFullYear()} Rovo Pte. Ltd.
      </Text>
      <Box mx="auto" />
      {isShowSignatureLine && <Image src={Signature} />}
    </Flex>
  );
};

export const MinimalFooter = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
  const isShowSignatureLine = useMediaQuery({ query: '(min-width: 1100px)' });

  return (
    <Flex
      px={[3, '24px']}
      sx={{
        borderTop: '1px solid #D1DCE8',
        flexWrap: 'wrap',
        minHeight: '56px',
        alignItems: 'center',
        width: '100%',
      }}
    >
      {isDesktop && (
        <>
          <ExtendLink to="https://page.rovo.co/p/privacy">
            <FooterItem variant="pg.sm">Privacy</FooterItem>
          </ExtendLink>
          <ExtendLink to="https://page.rovo.co/p/terms">
            <FooterItem variant="pg.sm">Terms & Conditions</FooterItem>
          </ExtendLink>
        </>
      )}
      <Text variant="pg.sm">© {new Date().getFullYear()} Rovo Pte. Ltd.</Text>
      <Box mx="auto" />
      {isShowSignatureLine && <Image src={Signature} />}
    </Flex>
  );
};

export default Footer;
