import React, { useState, useEffect } from 'react';
import { Text, Button } from '../../Primitives';
import { Box, Flex, Label, Spinner } from 'theme-ui';
import OtpInput from 'react-otp-input';

type Props = {
  handleSendCode: (code: string) => void;
  handleSendPhone: () => void;
  isSubmitting: boolean;
};

const OTPVerify = ({
  handleSendCode,
  isSubmitting,
  handleSendPhone,
}: Props) => {
  const [code, setCode] = useState('');
  const [tick, setTick] = useState(60);

  useEffect(() => {
    if (code.length === 6) {
      handleSendCode(code);
      setCode('');
    }
  }, [code, handleSendCode]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (tick > 0) {
        setTick(t => t - 1);
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [tick]);

  const handleResend = async () => {
    await handleSendPhone();
    setTick(60);
  };

  return (
    <Box>
      <Flex
        mb="3"
        sx={{
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Label
          mb="3"
          sx={{
            display: 'block',
            textAlign: 'center',
          }}
        >
          Verification code
        </Label>
        {isSubmitting ? (
          <Spinner />
        ) : (
          <OtpInput
            inputStyle={{
              width: '40px',
              height: '40px',
              border: '1px solid #C3D1DE',
              borderRadius: '4px',
              fontSize: '16px',
              fontWeight: 'medium',
              color: '#222F3C',
            }}
            separator={
              <Text variant="lb.md" mx="1">
                -
              </Text>
            }
            numInputs={6}
            value={code}
            shouldAutoFocus
            onChange={(otp: string) => setCode(otp)}
          />
        )}
        <Flex
          mt="2"
          sx={{
            alignItems: 'center',
          }}
        >
          <Text variant="pg.sm" mr="2" color="secondaryText">
            Didn't receive the code?
          </Text>
          <Button
            disabled={tick !== 0}
            variant="noPadding"
            onClick={() => handleResend()}
          >
            Resend
          </Button>
          <Text ml="2" variant="lb.sm">
            {tick} s
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default OTPVerify;
