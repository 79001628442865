import React, { useState, useEffect } from 'react';
import { Button, Box, Flex } from 'rebass';
import styled from '../../../../theme/styled';
import { observer } from 'mobx-react-lite';
import { userStore } from '../../../../stores/UserStore';

import { Icon } from '../../../Primitives';
import Text from '../../../UI/Text';
import RadioSelect from '../../../UI/RadioSelect';
import { PUBLIC_URL } from '../../../../constant';

import { getRating } from '../../../../helpers/user';

import SkillChart from './SkillChart';

const options = [
  {
    text: 'BEGINNER',
    value: 'beginner',
  },
  {
    text: 'ADVANCED',
    value: 'advanced',
  },
  {
    text: 'INTERMEDIATE',
    value: 'intermediate',
  },
];

const RankedLevel = styled(Flex)`
  border-radius: 100%;
  border: 1px solid ${(p) => p.theme.colors.white};
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: white;
  font-size: 20px;
  @media (min-width: 768px) {
    width: 56px;
    height: 56px;
    font-size: 24px;
  }
`;

const UnRankedBg = styled(Flex)`
  background: url(${`${PUBLIC_URL}/images/unranked.png`}) no-repeat center;
  width: 100%;
  justify-content: center;
`;

interface RankedProps {
  assessmentPending: boolean;
  rating: number;
  isSelf: boolean;
  assessment: {
    id: string;
    name: string;
    value: number;
  }[];
  sport: string;
  name: string;
  setIsSelfAssessment: Function;
  hasAssessment: boolean;
  assessmentEnabled: boolean;
}

export const Ranked: React.FC<RankedProps> = observer(
  ({
    assessmentPending,
    rating,
    assessment,
    isSelf,
    sport,
    setIsSelfAssessment,
    hasAssessment,
    assessmentEnabled,
  }) => {
    const { account } = userStore;
    const me = account.profile.sportProfiles;
    const selectedSport = me.find((s) => s.sport === sport);

    const [selfSkillData, setSelfSkillData] = useState<number[]>([]);
    const [isShowSelfSkill, toggleSelfSkill] = useState(false);

    useEffect(() => {
      const selfAssessment = selectedSport?.assessment;
      if (!selfAssessment) return;
      const data: number[] = [];
      selfAssessment.forEach((element) => {
        if (element.id === 'base') return;
        data.push(element.value);
      });
      setSelfSkillData(data);
    }, [selectedSport]);

    return (
      <Flex flexDirection="column" color="white">
        <Flex justifyContent="space-between" alignItems="center">
          <Flex mb="2" alignItems="center" color="white">
            <RankedLevel width="70px" height="70px" mr="2">
              {getRating({ assessmentPending, rating })}
            </RankedLevel>
            <Flex flexDirection="column">
              <Text color="white">SKILL LEVEL</Text>
              <Text fontWeight="300" fontSize="16px">
                Advanced
              </Text>
            </Flex>
          </Flex>
          {isSelf && hasAssessment && assessmentEnabled && (
            <Flex
              alignItems="center"
              sx={{ cursor: 'pointer' }}
              onClick={() => setIsSelfAssessment(true)}
            >
              <Icon icon="refresh" stroke="white" />
              <Text ml="2">Retake</Text>
            </Flex>
          )}
        </Flex>
        <Box mb="48px">
          <SkillChart
            assessment={assessment}
            selfSkillData={selfSkillData}
            isShowSelfSkill={isShowSelfSkill}
          />
        </Box>
        {!assessmentPending && !isSelf && (
          <Box textAlign="center">
            <Button
              variant="outline.normal"
              onClick={() => toggleSelfSkill((s) => !s)}
            >
              {isShowSelfSkill ? 'Turn off compare' : 'See how you match up'}
            </Button>
          </Box>
        )}
      </Flex>
    );
  }
);

interface UnRankedProps {
  isSelf: boolean;
  hasAssessment: boolean;
  assessmentEnabled: boolean;
}

export const UnRanked: React.FC<UnRankedProps> = ({
  isSelf,
  hasAssessment,
  assessmentEnabled,
}) => {
  const [skillLevel, setSkillLevel] = useState('advanced');

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      color="white"
    >
      {isSelf && (
        <>
          <Text fontWeight="bold" mb="3">
            SKILL LEVEL
          </Text>
          <RadioSelect
            value={skillLevel}
            options={options}
            setValue={setSkillLevel}
            noBorder
          />
        </>
      )}
      <UnRankedBg height={isSelf ? '300px' : '130px'} alignItems="center">
        <Flex
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <RankedLevel>U</RankedLevel>
          <Text mt="2">Unrated</Text>
          <Text textTransform="capitalize">{skillLevel}</Text>
        </Flex>
      </UnRankedBg>
      {isSelf && hasAssessment && assessmentEnabled && (
        <Box textAlign="center">
          <Text as="div" color="white" mb="3">
            Find out what your strengths in are
          </Text>
          <Button variant="outline.normal">Take the self assessment</Button>
        </Box>
      )}
    </Flex>
  );
};
