const localStorage = window.localStorage;

type KEY =
  | 'currentUser'
  | 'facebookAccessToken'
  | 'remoteConfig'
  | 'isBooking'

const LocalStorageHelper = {
  set: (key: KEY, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
  },

  get: (key: KEY) => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : '';
  },

  remove: (key: KEY) => {
    localStorage.removeItem(key);
  },
};

export default LocalStorageHelper;
