import React from 'react';
import { Flex, Box } from 'theme-ui';
import { ReactComponent as Premium } from '../../../assets/icons/stylized/rovo-premium.svg';

import { Text } from '../../Primitives';
import { MinimalFooter } from '../../Layout/UnAuth/Footer';
import { UnAuthHeaderOnlyLogo } from '../../Layout/UnAuth/Header';

const AuthLayout: React.FC<{
  formName: string;
  isPremium?: boolean;
}> = ({ formName, children, isPremium }) => {
  return (
    <>
      <Flex
        sx={{
          minHeight: '100vh',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <UnAuthHeaderOnlyLogo />
        <Box
          sx={{
            flex: 1,
            maxWidth: '392px',
            width: '100%',
          }}
        >
          <Box
            variant="card"
            sx={{
              my: 4,
              flex: 1,
            }}
          >
            <Flex
              sx={{
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
              }}
            >
              {isPremium && (
                <>
                  <Flex
                    sx={{
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <Box>
                      <Text variant="hd.lg">Get 2 weeks</Text>
                      <Text variant="hd.lg">Premium Trial</Text>
                    </Box>
                    <Premium
                      style={{
                        boxShadow: '0px 16px 48px #FED657',
                        borderRadius: 9999,
                        width: '64px',
                        height: '64px',
                      }}
                    />
                  </Flex>
                  <Box variant="line" my="24px" />
                </>
              )}
              <Text
                sx={{
                  alignSelf: 'flex-start',
                }}
                mb="24px"
                variant="hd.md"
              >
                {formName}
              </Text>
              {children}
            </Flex>
          </Box>
        </Box>
        <MinimalFooter />
      </Flex>
    </>
  );
};

export default AuthLayout;
