import React, { useState, useEffect } from 'react';
import { Box, Flex } from 'theme-ui';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import cogoToast from 'cogo-toast';

import { Input } from '../UI/Input';

import FireBaseService from '../../services/firebaseService';
import { Button, Text } from '../Primitives';

type FormData = {
  password: string;
  confirmPassword: string;
};

const ChangePassword: React.FC = () => {
  const history = useHistory();

  const [serverErr, setServerErr] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const { register, handleSubmit, errors, watch } = useForm<FormData>();

  const location = useLocation();
  const parsed = qs.parse(location.search);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        history.push('/sign-in');
      }, 3000);
    }
    return clearTimeout;
  }, [isSuccess, history]);

  const onSubmit = handleSubmit(async ({ password }) => {
    setLoading(true);
    const code = parsed.oobCode;
    if (typeof code !== 'string') {
      setLoading(false);
      return cogoToast.error('Error parse reset key. Please generate new one.');
    }
    try {
      await FireBaseService.changePassword(code, password, () => {
        setIsSuccess(true);
      });
    } catch (err) {
      setServerErr(err.message);
      setLoading(false);
    }
    setLoading(false);
  });

  return isSuccess ? (
    <Flex>
      <Text variant="hd.lg">
        Change password successfully. We will redirect you to login page now.
      </Text>
    </Flex>
  ) : (
    <>
      <Flex
        as="form"
        sx={{
          flexDirection: 'column',
          width: '100%',
        }}
        onSubmit={onSubmit}
      >
        <Box mb="2">
          <Text variant="lb.md" mb="2">
            Password
          </Text>
          <Input
            disabled={loading}
            name="password"
            type="password"
            placeholder="Enter your password"
            ref={register({ required: true, minLength: 6 })}
            isError={!!errors.password}
          />
        </Box>
        {errors.password && errors.password.type === 'required' && (
          <Text color="danger">Password is required</Text>
        )}
        {errors.password && errors.password.type === 'minLength' && (
          <Text color="danger">Password have to be more than 6 letters</Text>
        )}
        <Box mb="2">
          <Text variant="lb.md" mb="2">
            Confirm Password
          </Text>
          <Input
            disabled={loading}
            name="confirmPassword"
            type="password"
            placeholder="Confirm your password"
            ref={register({
              validate: (value) => value === watch('password'),
            })}
            isError={!!errors.confirmPassword}
          />
        </Box>

        {errors.confirmPassword && (
          <Text color="danger">Confirm password did not match</Text>
        )}
        {!!serverErr && <Text color="danger">{serverErr}</Text>}
        <Button
          loading={loading}
          type="submit"
          sx={{
            color: 'white',
          }}
          mt="3"
          mb="4"
          size="lg"
        >
          Change password
        </Button>
      </Flex>
    </>
  );
};

export default ChangePassword;
