import cogoToast from 'cogo-toast';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Flex, Link } from 'theme-ui';
import config from '../../config';
import { IActivity } from '../../interface/Activity';
import { activityStore } from '../../stores/ActivityStore';
import { userStore } from '../../stores/UserStore';
import { Button } from '../Primitives';
import JoinDialog from '../UI/Dialog/JoinDialog';
import LeaveDialog from '../UI/Dialog/LeaveDialog';
import ActivityData from './ActivityData';

type Props = {
  activity: IActivity;
};

const ActivityCard: React.FC<Props> = observer(({ activity }) => {
  const {
    account: { profileId, profile },
  } = userStore;
  const { updateActivity } = activityStore;
  const history = useHistory();

  const { players, maxPlayer, id, accepted, sport, uris } = activity;

  const [isOpenJoin, setOpenJoin] = useState(false);
  const [isOpenLeave, setOpenLeave] = useState(false);

  const isHost =
    players.find((p) => p.role === 'ORGANISER')?.profileId === profileId;

  const isJoined = !!activity.accepted.find((p) => p === profileId);

  const isWorkoutParty = useMemo(() => sport === 'WORKOUT_PARTY', [sport]);

  const uri = useMemo(() => (uris?.length ? uris[0].longUri : '' || id), [
    uris,
    id,
  ]);

  const handleJoin = async () => {
    if (isJoined) return setOpenLeave(true);
    if (isWorkoutParty) return history.push(`/plays/${id}`);
    const status = isJoined ? 'DECLINED' : 'ACCEPTED';
    try {
      await updateActivity(id, status);
      setOpenJoin(true);
    } catch (error) {
      cogoToast.warn(
        error?.response?.data?.errorMessage ||
          'Something wrong, Please try again or download the app'
      );
    }
  };

  const handleLeave = async () => {
    try {
      await updateActivity(id, 'DECLINED');
      setOpenLeave(false);
    } catch (error) {
      cogoToast.warn(
        error?.response?.data?.errorMessage ||
          'Something wrong, Please try again or download the app'
      );
    }
  };

  return (
    <Flex
      sx={{
        height: '100%',
        flexDirection: 'column',
      }}
      variant="card"
    >
      <ActivityData activity={activity} />
      {isWorkoutParty ? (
        <Link
          sx={{
            textDecoration: 'none',
            border: '1px solid #C3D1DE',
            borderRadius: '4px',
            height: '36px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'primary',
            fontWeight: 500,
          }}
          href={`${config.WP_URL}/parties/${uri}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          View on WorkoutParty!
        </Link>
      ) : (
        !isHost && (
          <>
            <Box mt="3" />
            <Button
              mt="auto"
              variant={isJoined ? 'flat.primary' : 'outline'}
              sx={{
                width: '100%',
              }}
              onClick={handleJoin}
            >
              {isJoined && 'Joined'}
              {!isJoined && accepted.length < maxPlayer && 'Join'}
              {!isJoined && accepted.length >= maxPlayer && 'Join waitlist'}
            </Button>
          </>
        )
      )}
      {isOpenJoin && (
        <JoinDialog
          isOpen={isOpenJoin}
          onClose={() => {
            setOpenJoin(false);
          }}
          title={`Awesome ${profile.firstName}! You've joined ${activity.name}.`}
          description={`Our hosts go out of their way to arrange games, so joining a game is a commitment that you will turn up for the game on time.`}
        />
      )}
      {isOpenLeave && (
        <LeaveDialog
          isOpen={isOpenLeave}
          onClose={() => setOpenLeave(false)}
          onLeave={handleLeave}
          activityId={id}
        />
      )}
    </Flex>
  );
});

export default ActivityCard;
