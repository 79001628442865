import React, { useState, useEffect, useRef } from 'react';
import { Box, Flex } from 'rebass';
import { Spinner } from '@blueprintjs/core';

import { ChatInput } from '../UI/Input';
// import { Icon } from '../Primitives';
// import Text from '../UI/Text';
import { PrimaryDialog } from '../UI/Dialog';

import { IComment } from '../../interface/Inbox';
import { IActivity } from '../../interface/Activity';

import { ChatBodyWrapper } from './ActivityDetail.styled';

import { SystemComment, TextComment, PollComment } from './Comments';
import { PollDialog } from './PollDialog';
import { Text, Icon } from '../Primitives';

interface Props {
  comments: IComment[];
  loading: boolean;
  activity: IActivity;
  lastMessageWithPoll: IComment | undefined;
  getMore: () => void;
  onSendTextComment: (text: string) => void;
  onSendImageComment: (image: File) => void;
  isLockDiscussion: boolean;
}

const ChatBody: React.FC<Props> = ({
  comments,
  loading,
  activity,
  lastMessageWithPoll,
  getMore,
  onSendImageComment,
  onSendTextComment,
  isLockDiscussion,
}) => {
  const [comment, setComment] = useState('');
  const [isWorkingWithPoll, setIsWorkingWithPoll] = useState(false);

  const body = useRef<HTMLDivElement>();

  const scrollToBottom = async () => {
    let objDiv = await document.getElementById('body-scrolling');
    if (objDiv && objDiv.scrollTop !== objDiv.scrollHeight) {
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  };

  const onScrollToTop = () => {
    if (body && body.current && body.current.scrollTop < 120) {
      getMore();
    }
  };

  const sendTextMessage = async (text: string) => {
    await onSendTextComment(text);
    scrollToBottom();
  };

  const sendImageMessage = async (image: File) => {
    await onSendImageComment(image);
    scrollToBottom();
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  const renderMessages = () => {
    return comments.map((m) => {
      const { type } = m;
      switch (type) {
        case 'SYSTEM':
          return <SystemComment key={m.id} m={m} />;
        case 'PHOTO':
        case 'TEXT':
          return <TextComment key={m.id} m={m} />;
        case 'POLL':
          return (
            <PollComment
              key={m.id}
              m={m}
              isDisplayPoll={m.id === lastMessageWithPoll?.id}
            />
          );
        default:
          return null;
      }
    });
  };

  return (
    <>
      {isLockDiscussion ? (
        <Flex
          sx={{
            height: 'calc(100vh - 150px)',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Flex
            sx={{
              width: '80px',
              height: '80px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '80px',
              bg: 'sportmanGray',
            }}
            mb="2"
          >
            <Icon icon="lock-alt" size="xl" />
          </Flex>
          <Text color="secondaryText">
            The discussion section is only visible to participants.
            <br />
            Please pay to join in WorkoutParty! to view the discussion.
          </Text>
        </Flex>
      ) : (
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          height="calc(100vh - 150px)"
        >
          {/* {!lastMessageWithPoll && (
          <Button
            m="2"
            variant="outline.normal"
            onClick={() => setIsWorkingWithPoll(true)}
          >
            <Flex alignItems="center" justifyContent="center">
              <Icon icon="poll" stroke="primary" size="sm" />
              <Text ml="1">Create vote for time & location</Text>
            </Flex>
          </Button>
        )} */}
          <Box width="100%" height="0px" />
          {loading && (
            <Box alignSelf="center" mt="3">
              <Spinner size={24} intent="primary" />
            </Box>
          )}
          <ChatBodyWrapper
            id="body-scrolling"
            ref={body}
            onScroll={onScrollToTop}
          >
            {renderMessages()}
          </ChatBodyWrapper>
        </Flex>
      )}
      {!isLockDiscussion && (
        <Box p="2" mt="auto">
          <ChatInput
            value={comment}
            onChange={(value: string) => setComment(value)}
            hasLikeEmoji
            onEnter={sendTextMessage}
            onSelectImage={sendImageMessage}
          />
        </Box>
      )}
      {isWorkingWithPoll && (
        <PrimaryDialog
          isOpen={isWorkingWithPoll}
          onClose={() => setIsWorkingWithPoll(false)}
          title="Create Poll"
        >
          <PollDialog
            activity={activity}
            lastMessageWithPoll={lastMessageWithPoll}
          />
        </PrimaryDialog>
      )}
    </>
  );
};

export default ChatBody;
