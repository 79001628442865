import httpService from '../services/httpService';

export const getActivityNotifications = async ({
  idLt = null,
  limit = 20,
  markSeen = false,
  withAppNotifications = true,
}: {
  idLt?: string | null;
  limit?: number;
  markSeen?: boolean;
  withAppNotifications?: boolean;
}) => {
  const data = {
    idLt,
    limit,
    markSeen,
    withAppNotifications,
  };
  try {
    const response = await httpService.post('me/notifications', data);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const markAsReadActivityNotifications = async (
  activityIds: string[]
) => {
  try {
    await httpService.post('me/notifications/markRead', {
      markRead: activityIds,
    });
  } catch (error) {
    throw error;
  }
};

export const getGroupJoinRequests = async ({
  limit = 20,
  offset = 0,
}: {
  limit?: number;
  offset?: number;
}) => {
  try {
    const response = await httpService.get('me/groups/requests', {
      params: { limit, offset },
    });
    return response?.data?.groups;
  } catch (error) {
    if (error?.response?.statusCode === 404) return [];
    throw error;
  }
};

export const approveGroupJoinRequest = async (
  groupId: number,
  memberId: string
) => {
  try {
    await httpService.post(`groups/${groupId}/members/${memberId}/approve`);
  } catch (error) {
    throw error;
  }
};

export const deleteGroupJoinRequest = async (
  groupId: number,
  memberId: string
) => {
  try {
    await httpService.delete(`groups/${groupId}/members/${memberId}`);
  } catch (error) {
    throw error;
  }
};

export const getFollowRequests = async ({
  limit = 20,
  offset = 0,
}: {
  limit?: number;
  offset?: number;
}) => {
  try {
    const response = await httpService.get('me/followers/requests', {
      params: { limit, offset },
    });
    return response?.data?.requests;
  } catch (error) {
    if (error?.response?.statusCode === 404) return [];
    throw error;
  }
};
