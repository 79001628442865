import React from 'react';
import {
  Header,
  StepIndicator,
  PartnerLogo,
  CloseButton,
} from '../FacilityBooking.styled';
import { Flex, Text } from 'rebass';

interface Props {
  step: number;
  logo?: string;
  processingStatus: string;
  onClose: Function;
}

const BookingHeader: React.FC<Props> = ({
  step,
  logo,
  processingStatus,
  onClose,
}) => {
  return (
    <Header
      px={[2, 0]}
      py={[2, 0]}
      alignItems="center"
      justifyContent="center"
      height="64px"
    >
      <PartnerLogo height="48px" src={logo || ''} />
      <Flex flexDirection="column" alignItems="center">
        <Text variant="body" mb="1">
          {step === 1 && 'Make a booking'}
          {step === 2 && 'Make payment & confirm booking'}
          {step === 3 &&
            `Booking ${
              processingStatus === 'COMMITTED' ? 'confirmed' : 'pending'
            }`}
        </Text>
        <Flex alignItems="center">
          <StepIndicator bg={step === 1 ? 'primary' : 'dark6'} />
          <StepIndicator mx="1" bg={step === 2 ? 'primary' : 'dark6'} />
          <StepIndicator bg={step === 3 ? 'primary' : 'dark6'} />
        </Flex>
      </Flex>
      <CloseButton
        icon="close"
        fill="primaryText"
        size="md"
        onClick={() => onClose()}
      />
    </Header>
  );
};

export default BookingHeader;
