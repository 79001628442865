import React, { useEffect, useState, useRef } from 'react';
import { Box, Flex, Spinner } from 'theme-ui';
import { observer } from 'mobx-react-lite';
import cogoToast from 'cogo-toast';
import { useHistory } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';

import { Text, Icon, Button } from '../../../components/Primitives';
import { CreditCard } from '../../../components/Payment';

import { premiumStore } from '../../../stores/PremiumStore';
import { checkInvoiceStatus, payInvoice } from '../../../api/ProfileAPI';
import { IInvoiceOrder } from '../../../interface/Utils';

import PaymentErrorBox from './PaymentErrorBox';

type Props = {
  setCancel: (value: boolean) => void;
};

const BillingPremium = observer(({ setCancel }: Props) => {
  const history = useHistory();
  const stripe = useStripe();

  const {
    getPaymentMethodsOfCurrency,
    premiumSubscription,
    paymentMethods,
    invoices,
    state,
    hasInvoice,
    currentPaymentId,
    resubscription,
    isCancelledSubscription,
    IsAvailableToResubscribe,
    renewedDate,
    getSubscriptions,
  } = premiumStore;

  const [selectedMethod, setMethod] = useState('');
  const [isAddNew, setIsAddNew] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const ref = useRef<HTMLFormElement>();

  useEffect(() => {
    if (!premiumSubscription) {
      getSubscriptions();
    }
  }, [premiumSubscription, getSubscriptions]);

  useEffect(() => {
    if (premiumSubscription?.currency) {
      getPaymentMethodsOfCurrency(premiumSubscription.currency);
    }
  }, [premiumSubscription, getPaymentMethodsOfCurrency]);

  useEffect(() => {
    if (currentPaymentId) {
      checkInvoiceStatus(currentPaymentId);
    }
  }, [currentPaymentId]);

  const onSuccessPayment = async (paymentId?: string) => {
    if (paymentId) {
      await checkInvoiceStatus(paymentId);
    }
    setIsSubmitting(false);
    history.push('/premium/purchase');
  };

  const onFailedPayment = (message?: string) => {
    setIsSubmitting(false);
    cogoToast.error(message || 'Purchase subscription failed');
  };

  const onMakePayment = async (token?: string) => {
    setIsSubmitting(true);
    const invoiceId = invoices.length ? invoices[0].invoiceId : '';
    if (invoiceId) {
      try {
        const response: IInvoiceOrder = await payInvoice(
          invoiceId,
          selectedMethod,
          token
        );
        if (response) {
          const pendingOrderTransaction = response.transactions.find(
            (o) => o.status !== 'SUCCESS'
          );
          const properties = pendingOrderTransaction
            ? pendingOrderTransaction.properties
            : [];
          const clientSecret = properties.length
            ? properties.find((p) => p.key === 'client_secret')?.value
            : '';
          if (clientSecret && pendingOrderTransaction) {
            return {
              clientSecret,
              orderId: '',
              id: response.paymentId,
            };
          }
          return onFailedPayment('Purchase subscription failed');
        }
      } catch (error) {
        return onFailedPayment('Purchase subscription failed');
      }
    }
  };

  const onCreatePayment = async () => {
    if (ref && ref.current) {
      return ref.current?.dispatchEvent(new Event('submit'));
    }
    const response = await onMakePayment();
    if (response) {
      if (response?.clientSecret && stripe) {
        const { error } = await stripe.confirmCardPayment(
          response.clientSecret
        );
        if (error) {
          return onFailedPayment(error.message);
        }
        return onSuccessPayment(response?.id);
      }
    }
  };

  function renderPaymentMethods(onlyDefault?: boolean) {
    if (!paymentMethods?.length) return null;
    return paymentMethods.map((paymentMethod) => {
      if (onlyDefault && !paymentMethod.isDefault) return null;

      const ccLast4 = paymentMethod.pluginInfo.properties.find(
        (p) => p.key === 'ccLast4'
      )?.value;
      const ccType = paymentMethod.pluginInfo.properties.find(
        (p) => p.key === 'ccType'
      )?.value;
      const ccExpirationYear = paymentMethod.pluginInfo.properties.find(
        (p) => p.key === 'ccExpirationYear'
      )?.value;
      const ccExpirationMonth = paymentMethod.pluginInfo.properties.find(
        (p) => p.key === 'ccExpirationMonth'
      )?.value;

      const isSelected = selectedMethod === paymentMethod.paymentMethodId;
      if (ccLast4 && ccType && ccExpirationMonth && ccExpirationYear) {
        return (
          <Flex
            sx={{
              alignItems: 'center',
              width: '100%',
              border: '1px solid',
              borderColor: isSelected ? 'primary' : 'transparent',
              borderRadius: '4px',
              px: '3',
              cursor: 'pointer',
              ':hover': {
                borderColor: onlyDefault ? 'transparent' : 'primary',
              },
            }}
            key={paymentMethod.paymentMethodId}
            onClick={() => {
              if (onlyDefault) return;
              setMethod(paymentMethod.paymentMethodId);
            }}
          >
            <Icon icon="visa" size="badge" />
            <Text variant="lb.md" mx="3">
              **** {ccLast4}
            </Text>
            <Text>
              Exp: {ccExpirationMonth} / {ccExpirationYear}
            </Text>
            <Flex ml="auto">
              <Icon icon="chevron-right" size="lg" fill="primaryText" />
            </Flex>
          </Flex>
        );
      } else {
        return null;
      }
    });
  }

  const enableSubmit = (!selectedMethod && isAddNew) || selectedMethod;

  return state === 'LOADING' || isSubmitting ? (
    <Flex
      sx={{
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Spinner />
    </Flex>
  ) : (
    <Flex
      sx={{
        alignItems: 'center',
        textAlign: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: '480px',
        mx: 'auto',
        mt: [3, '120px'],
        p: 4,
      }}
    >
      {hasInvoice && <PaymentErrorBox />}
      {!!premiumSubscription &&
        (isCancelledSubscription ? (
          <Text mb="3">
            Your subscription will be cancelled on {renewedDate}
          </Text>
        ) : (
          <Text mb="3">Your subscription will be renewed on {renewedDate}</Text>
        ))}
      {hasInvoice && (
        <Box
          mt="24px"
          sx={{
            width: '100%',
          }}
        >
          {hasInvoice && (
            <Text
              sx={{
                textAlign: 'left',
              }}
            >
              Choose a payment method:
            </Text>
          )}
          {renderPaymentMethods(!hasInvoice)}
          <Box variant="line" mb="3" />
          {hasInvoice && (
            <Flex
              sx={{
                alignItems: 'center',
                width: '100%',
                borderRadius: '4px',
                px: '3',
                cursor: 'pointer',
                ':hover': {
                  borderColor: 'primary',
                },
                mb: '3',
              }}
              onClick={() => {
                if (!isAddNew) {
                  setMethod('');
                }
                setIsAddNew((s) => !s);
              }}
            >
              <Text>New credit card</Text>
              <Flex ml="auto">
                <Icon
                  icon={isAddNew ? 'chevron-down' : 'chevron-right'}
                  size="lg"
                  fill="primaryText"
                />
              </Flex>
            </Flex>
          )}
          {isAddNew && (
            <CreditCard
              ref={ref}
              onMakePayment={onMakePayment}
              onSuccess={onSuccessPayment}
              onFailed={onFailedPayment}
              skipCheckOrderStatus
              hasLabel
            />
          )}
        </Box>
      )}
      {hasInvoice && (
        <Button
          fullWidth
          size="md"
          onClick={onCreatePayment}
          disabled={!enableSubmit}
          mb="3"
        >
          Make payment
        </Button>
      )}
      {!isCancelledSubscription && (
        <Button
          variant="outline.gray"
          fullWidth
          size="md"
          onClick={() => {
            setCancel(true);
          }}
          loading={state === 'SUBMITTING'}
        >
          Cancel Subscription
        </Button>
      )}
      {IsAvailableToResubscribe && (
        <Button
          fullWidth
          size="md"
          onClick={resubscription}
          loading={state === 'SUBMITTING'}
        >
          Resubscribe
        </Button>
      )}
    </Flex>
  );
});

export default BillingPremium;
