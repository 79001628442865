import React, { useEffect } from 'react';

import SettingsPage from '../components/Settings';
import { trackPageView } from '../services/amplitudeService';

const Settings: React.FC = () => {
  useEffect(() => {
    trackPageView('Settings');
  });

  return <SettingsPage />;
};

export default Settings;
