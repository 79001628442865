import React, { useMemo } from 'react';
import { Box, Flex, Image } from 'theme-ui';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';

import { Text, Icon, Avatar, EmptyAvatar } from '../Primitives';

import { IActivity } from '../../interface/Activity';

import { fromServer } from '../../helpers/datetime';
import { centsToDecimal } from '../../helpers/currency';
import { getRating } from '../../helpers/user';

import { userStore } from '../../stores/UserStore';

import { AvatarWrapper, TagWrapper } from './styled';

type Props = {
  activity: IActivity;
  hideTag?: boolean;
  isWhiteText?: boolean;
};

const MIN = 1;
const MAX = 7;

const ActivityData: React.FC<Props> = observer(
  ({ activity, hideTag, isWhiteText }) => {
    const {
      account: { profileId },
    } = userStore;

    const {
      sportInfo,
      name,
      accepted,
      discoverable,
      players,
      minPlayer,
      maxPlayer,
      minRating,
      maxRating,
      start,
      end,
      isRanked,
      timezone,
      recurring,
      priceCents,
      pricePoints,
      locations,
      courtBooked,
      id,
      onAt,
      purpose,
      sport,
    } = activity;

    const isWorkoutParty = useMemo(() => sport === 'WORKOUT_PARTY', [sport]);

    const displayStart = fromServer(start, timezone, 'cccc MMM dd');
    const timeStart = fromServer(start, timezone, 'h:mm a');
    const timeEnd = fromServer(end, timezone, 'h:mm a');
    const activityLocation = locations[0];

    const rule = recurring ? recurring.rrule.split(':')[1].split(';') : null;

    const freq = rule ? rule[0].split('=')[1] : null;

    const renderEmptyAvatar = () => {
      const notAttendedPlayers = [];
      const totalNotAttend = maxPlayer - accepted.length;
      for (let i = 0; i < totalNotAttend; i += 1) {
        notAttendedPlayers.push(
          <EmptyAvatar key={`${id}-${i}-empty-player`} size="md" />
        );
      }
      return notAttendedPlayers;
    };

    const isRestrict = minRating !== MIN || maxRating !== MAX;

    const isHost =
      players.find((p) => p.role === 'ORGANISER')?.profileId === profileId;

    const isGameIsOn = !!onAt;

    const history = useHistory();

    const textColor = isWhiteText ? 'white' : 'primaryText';

    return (
      <Box onClick={() => history.push(`/plays/${id}`)}>
        <Flex
          mb="2"
          sx={{
            alignItems: 'center',
          }}
        >
          <Text
            variant="lb.sm"
            color={isWhiteText ? 'white' : 'secondaryText'}
            textTransform="capitalize"
          >
            {`${sportInfo.name} ${purpose}`.toLowerCase()}
          </Text>
          <Image
            ml="2"
            width="16px"
            src={`https://s3-ap-southeast-1.amazonaws.com/prod.rovo.co/assets/sports/sport-${sportInfo.uri}-icon.png`}
          />
          <Box
            ml="auto"
            sx={{
              fontWeight: 'medium',
              border: '1px solid',
              borderColor: 'primary',
              px: 2,
              py: 1,
              borderRadius: '24px',
            }}
          >
              <Text variant="lb.sm" color="primary">
                {isRestrict && minRating !== null && maxRating !== null
                  ? `Lv ${
                      minRating === maxRating
                        ? minRating
                        : `${minRating}-${maxRating}`
                    }`
                  : 'All level'}
              </Text>
          </Box>
        </Flex>
        <Text
          variant="lb.lg"
          color={textColor}
          css={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {isRanked && 'Ranked game: '}
          {name}
        </Text>
        {discoverable && (
          <Text mb="2" variant="pg.md" color={textColor}>
            Public Activity
          </Text>
        )}
        <AvatarWrapper>
          {players.map(
            ({ profile: { id, picture, pulseLevel, sportProfile } }) => {
              const rating = getRating(sportProfile);
              if (!accepted.includes(id)) return null;
              return (
                <Avatar
                  key={id}
                  size="md"
                  src={picture}
                  badgeText={rating}
                  pulseLevel={pulseLevel}
                />
              );
            }
          )}
          {renderEmptyAvatar()}
        </AvatarWrapper>
        <Text variant="pg.md" color={textColor} mb="1">
          {`${accepted.length}/${maxPlayer} Going - Min ${minPlayer} to start`}
        </Text>
        {!!pricePoints && pricePoints.length > 1 ? (
          <Flex
            mb="2"
            sx={{
              alignItems: 'center',
            }}
          >
            <Text variant="lb.md" color={textColor}>
              {centsToDecimal(
                pricePoints[0].priceCents.price,
                pricePoints[0].priceCents.currency
              )}{' '}
              {pricePoints[0].priceCents.currency}
              {' - '}
              {centsToDecimal(
                pricePoints[pricePoints.length - 1].priceCents.price,
                pricePoints[pricePoints.length - 1].priceCents.currency
              )}{' '}
              {pricePoints[0].priceCents.currency}
              &nbsp; / person
            </Text>
          </Flex>
        ) : (
          !!priceCents && (
            <Flex
              mb="2"
              sx={{
                alignItems: 'center',
              }}
            >
              <Text variant="lb.md" color={textColor}>
                {centsToDecimal(priceCents.price, priceCents.currency)}{' '}
                {priceCents.currency} / person
              </Text>
            </Flex>
          )
        )}
        {!hideTag && (
          <TagWrapper>
            {isHost && <Box variant="activityTag.host">Host</Box>}
            {isGameIsOn && <Box variant="activityTag.gameOn">Game is on!</Box>}
            {isRanked && <Box variant="activityTag.ranked">Ranked game</Box>}
          </TagWrapper>
        )}
        <Box variant="line" my="3" bg={isWhiteText ? 'white' : 'divider'} />
        <Flex
          sx={{
            alignItems: 'center',
          }}
        >
          <Icon icon="time" size="md" />
          <Box ml="2">
            <Text as="span" variant="pg.md" color={textColor}>
              {displayStart}, &nbsp;
            </Text>
            <Text as="span" variant="pg.md" color={textColor}>
              {timeStart} - {timeEnd}
            </Text>
          </Box>
        </Flex>
        <Text
          ml="28px"
          mb="1"
          variant="lb.md"
          color={isWhiteText ? 'white' : 'secondaryText'}
        >
          {!!freq ? `Repeat ${freq.toLowerCase()}` : 'One time activity'}
        </Text>
        <Flex
          sx={{
            alignItems: 'center',
          }}
        >
          <Icon
            icon="location"
            stroke={isWhiteText ? 'white' : 'secondaryText'}
            size="md"
          />
          {courtBooked ? (
            <>
              <Text ml="2" variant="pg.md" color={textColor}>
                Court Booked
              </Text>
              <Box variant="dot" />
              <Text variant="pg.md" color={textColor}>
                {isWorkoutParty
                  ? 'Online'
                  : activityLocation?.venueDetail?.venueName ||
                    activityLocation.name}
              </Text>
            </>
          ) : (
            <Text variant="pg.md" color={textColor} ml="2">
              {isWorkoutParty
                ? 'Online'
                : activityLocation?.venueDetail?.venueName ||
                  activityLocation.name}
            </Text>
          )}
        </Flex>
      </Box>
    );
  }
);

export default ActivityData;
