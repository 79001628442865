import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Flex, Spinner } from 'theme-ui';

import { ActivityCard } from '../Cards';
import { IActivity } from '../../interface/Activity';

import { activityStore } from '../../stores/ActivityStore';

import useWindowInfinityScroll from '../../hooks/useWindowInfinityScroll';
import EmptyList from '../UI/EmptyList';

type Props = {
  query: Object;
};

const ActivitiesTab: FunctionComponent<Props> = observer(({ query }) => {
  const { getActivities, activities, hasMore, loading } = activityStore;

  useWindowInfinityScroll(getActivities, query);

  return (
    <Box
      pb={['108px', 5]}
      sx={{
        flex: 1,
      }}
    >
      {!!activities.length &&
        activities.map((activity: IActivity) => (
          <Box mb="3" key={activity.id}>
            <ActivityCard key={activity.id} activity={activity} />
          </Box>
        ))}
      {!activities.length && !loading && <EmptyList text="activities" />}
      {hasMore && loading && (
        <Flex
          sx={{
            justifyContent: 'center',
          }}
        >
          <Spinner size={32} />
        </Flex>
      )}
    </Box>
  );
});

export default ActivitiesTab;
