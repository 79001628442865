import React from 'react';
import { Flex } from 'rebass';
import { observer } from 'mobx-react-lite';
import { Spinner } from '@blueprintjs/core';
import debounce from 'lodash/debounce';

import { inboxStore } from '../../../stores/InboxStore';

import { SearchInput } from '../../UI/Input';

import EmptyList from '../../UI/EmptyList';
import useWindowInfinityScroll from '../../../hooks/useWindowInfinityScroll';
import GroupInboxCard from '../components/GroupInboxCard';

const PeopleInboxTab: React.FC = observer(() => {
  const { getGroups, loadingGroups, groups, hasMoreGroup } = inboxStore;

  const searchGroup = debounce(value => {
    getGroups(value, true);
  }, 50);

  useWindowInfinityScroll(getGroups);

  return (
    <Flex flexDirection="column">
      <SearchInput
        placeholder="Search groups you're in"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          searchGroup(e.target.value)
        }
      />
      <Flex flexWrap="wrap" mx={-2}>
        {!!groups.length &&
          groups.map(g => (
            <GroupInboxCard
              key={g.id}
              groupId={g.id}
              groupProfile={g.groupProfile}
            />
          ))}
      </Flex>
      {!groups?.length && !loadingGroups && <EmptyList text="group" />}
      {hasMoreGroup && loadingGroups && <Spinner intent="primary" size={24} />}
    </Flex>
  );
});

export default PeopleInboxTab;
