import React, { useState, useEffect } from 'react';
import { Flex, Box } from 'rebass';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';

import Text from '../UI/Text';
import Link from '../UI/Link';
import { Input } from '../UI/Input';

import FireBaseService from '../../services/firebaseService';
import FacebookLogin from './components/FacebookLogin';
import { Button } from '../Primitives';
import AuthLayout from './components/Layout';
import LocalStorageHelper from '../../helpers/localStorage';
import { SignUpBtn } from './styled';

type FormData = {
  email: string;
  password: string;
  confirmPassword: string;
};

const SignUpPage: React.FC = () => {
  const history = useHistory();

  const [serverErr, setServerErr] = useState('');
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, errors, watch } = useForm<FormData>();

  const location = useLocation();
  const parsed = qs.parse(location.search);
  const re = parsed?.re;

  useEffect(() => {
    const account = LocalStorageHelper.get('currentUser');
    if (account && account.session) {
      history.push('/explore');
    }
  }, [history]);

  const onSubmit = handleSubmit(async ({ email, password }) => {
    setLoading(true);
    try {
      await FireBaseService.signUp(email, password, () => {
        if (typeof re === 'string') {
          window.location.href = re;
        } else {
          history.push('/complete-profile');
        }
      });
    } catch (err) {
      setServerErr(err.message);
    }
    setLoading(false);
  });

  return (
    <AuthLayout formName="Sign up for Rovo">
      <>
        <Flex as="form" flexDirection="column" width="100%" onSubmit={onSubmit}>
          <Box mb="2">
            <Text variant="lb.md" mb="2">
              Email
            </Text>
            <Input
              disabled={loading}
              name="email"
              type="email"
              placeholder="Enter your email"
              ref={register({ required: true })}
              isError={!!errors.email}
            />
          </Box>
          {errors.email && <Text color="danger">Email is required</Text>}
          <Box mb="2">
            <Text variant="lb.md" mb="2">
              Password
            </Text>
            <Input
              disabled={loading}
              name="password"
              type="password"
              placeholder="Enter your password"
              ref={register({ required: true, minLength: 6 })}
              isError={!!errors.password}
            />
          </Box>
          {errors.password && errors.password.type === 'required' && (
            <Text color="danger">Password is required</Text>
          )}
          {errors.password && errors.password.type === 'minLength' && (
            <Text color="danger">Password have to be more than 6 letters</Text>
          )}
          <Box mb="2">
            <Text variant="lb.md" mb="2">
              Confirm Password
            </Text>
            <Input
              disabled={loading}
              name="confirmPassword"
              type="password"
              placeholder="Confirm your password"
              ref={register({ validate: value => value === watch('password') })}
              isError={!!errors.confirmPassword}
            />
          </Box>

          {errors.confirmPassword && (
            <Text color="danger">Confirm password did not match</Text>
          )}
          {!!serverErr && <Text color="danger">{serverErr}</Text>}
          <Button loading={loading} type="submit" mt="3" mb="4" size="lg">
            <Text variant="lb.md" color="white">
              Create Rovo Account
            </Text>
          </Button>
          <Flex alignItems="center" mb="3">
            <Box height="1px" bg="#D1DCE8" flex="1" />
            <Text variant="lb.md" mx="2">
              Or continue with
            </Text>
            <Box height="1px" bg="#D1DCE8" flex="1" />
          </Flex>
          <Flex>
            <SignUpBtn flex="1" mr="2">
              <Link to="/sign-in">
                <Text variant="lb.md" color="#0ac2cc">
                  Log in
                </Text>
              </Link>
            </SignUpBtn>
            <FacebookLogin isAuth isNoText />
          </Flex>
        </Flex>
      </>
    </AuthLayout>
  );
};

export default SignUpPage;
