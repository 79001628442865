import React, { FunctionComponent } from 'react';
import { Flex, Image } from 'rebass';

import Text from '../../UI/Text';

import IActivityTimeline from '../../../interface/ActivityTimeline';

import styled from '../../../theme/styled';

const Title = styled(Text)`
  span {
    font-size: ${p => (p.notOnly ? '14px' : '18px')};
  }
`;

const PostCard: FunctionComponent<{
  item: IActivityTimeline;
}> = ({ item }) => {
  const {
    object: { displayName, attachments },
  } = item;

  return (
    <Flex flexDirection="column">
      {!!displayName && (
        <Title
          px={[2, 3]}
          py="2"
          color="primaryText"
          notOnly={attachments && attachments.length}
          dangerouslySetInnerHTML={{ __html: displayName }}
        />
      )}
      {!!attachments && !!attachments.length && (
        <Image src={attachments[0]?.url} width="100%" />
      )}
    </Flex>
  );
};

export default PostCard;
