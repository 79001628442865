import styled from '../../../theme/styled';
import { Flex, Text } from 'theme-ui';

export const Header = styled(Flex)`
  flex-wrap: wrap;
  position: sticky;
  top: 0px;
  width: 100%;
  align-items: center;
`;

export const HeaderItem = styled(Text)`
  padding: 8px 0px;
  margin-left: 32px;
  text-shadow: 0px 0px 60px rgba(0, 0, 0, 0.5);
  :hover {
    text-decoration: underline;
  }
`;

export const MobileHeaderItem = styled(Text)`
  text-align: center;
  margin-bottom: 24px;
  text-shadow: 0px 0px 60px rgba(0, 0, 0, 0.5);
  :hover {
    text-decoration: underline;
  }
`;

export const FooterItem = styled(Text)`
  margin-right: 24px;
  padding: 8px 0px;
  flex: 1 1 auto;
  text-shadow: 0px 0px 60px rgba(0, 0, 0, 0.5);
  :hover {
    text-decoration: underline;
  }
`;

export const FooterMobileItem = styled(Text)`
  padding: 8px;
  flex: 1 1 auto;
  text-shadow: 0px 0px 60px rgba(0, 0, 0, 0.5);
  :hover {
    text-decoration: underline;
  }
`;

export const MobileMenu = styled(Flex)`
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0px;
  height: calc(100vh);
  left: 0px;
  width: 100%;
  background-color: white;
  padding-bottom: 32px;
`;
