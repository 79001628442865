import React, { FunctionComponent, useState, useRef, useEffect } from 'react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { observer } from 'mobx-react-lite';
import { useHistory, useLocation } from 'react-router-dom';

import { SearchInput } from '../../UI/Input';
import { Icon } from '../../Primitives';

import { searchStore } from '../../../stores/SearchStore';
import { useOnClickOutside } from '../../../hooks/useOuterClick';
import { useLocalStorage } from '../../../hooks/useLocalStorage';

import SearchSuggest from './SearchSuggest';
import RecentSearchBox from './RecentSearchBox';

import { SearchWrapper, InputWrapper, BackBtn } from './SearchField.styled';

const SearchField: FunctionComponent<{
  defaultSearchString?: string;
  hasBackBtn?: boolean;
}> = observer(({ defaultSearchString, hasBackBtn }) => {
  const { suggest, searchAll, loading } = searchStore;

  const [isShowMenu, setMenuStatus] = useState(false);
  const [searchString, setSearchString] = useState('');

  const [recentSearchs, setRecentSearchs] = useLocalStorage(
    'RECENT_SEARCHS',
    []
  );

  const history = useHistory();
  const location = useLocation();
  const searchBox = useRef();
  const searchInput = useRef(null);

  useEffect(() => {
    setSearchString(defaultSearchString || '');
    if (defaultSearchString) {
      searchAll({
        q: defaultSearchString,
      });
    }
  }, [defaultSearchString, searchAll]);

  useEffect(() => {
    const { state } = location;
    const isFromExplore = get(state, 'isFromExplore', false);
    setMenuStatus(isFromExplore);
  }, [location]);

  useOnClickOutside(searchBox, () => {
    setMenuStatus(false);
  });

  const getSuggestData = debounce(async (value) => {
    setSearchString(value);
    searchAll({ q: value }, true);
  }, 50);

  const addRecentSearch = (text: string) => {
    if (!recentSearchs.includes(text)) {
      const addedRecentSearchs = [text, ...recentSearchs];
      setRecentSearchs(addedRecentSearchs);
    }
  };

  const removeRecentSearch = (text: string) => {
    const removedRecentSearchs = recentSearchs.filter(
      (t: string) => t !== text
    );
    setRecentSearchs(removedRecentSearchs);
  };

  const clearRecentSearchs = () => {
    setRecentSearchs([]);
  };

  const handleSearch = (text: string) => {
    history.push(`/search?q=${text}`);
    addRecentSearch(text);
    setMenuStatus(false);
  };

  return (
    <SearchWrapper>
      {hasBackBtn && (
        <BackBtn variant="transparent" mr="2" p="0" width="40px" height="40px">
          <Icon icon="arrow-left" size="lg" onClick={() => history.goBack()} />
        </BackBtn>
      )}
      <InputWrapper ref={searchBox}>
        <SearchInput
          ref={searchInput}
          type="text"
          id="explore-search-input"
          autoComplete="off"
          value={searchString}
          placeholder="Search"
          onFocus={() => {
            setMenuStatus(true);
            setSearchString('');
          }}
          onChange={(e) => getSuggestData(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && searchString) {
              handleSearch(searchString);
            }
          }}
        />
        {isShowMenu ? (
          !!searchString ? (
            <SearchSuggest
              suggest={suggest}
              loading={loading}
              setMenuStatus={setMenuStatus}
              searchString={searchString}
              addRecentSearch={addRecentSearch}
              handleSearch={handleSearch}
            />
          ) : (
            <RecentSearchBox
              setMenuStatus={setMenuStatus}
              recentSearchs={recentSearchs}
              removeRecentSearch={removeRecentSearch}
              clearRecentSearchs={clearRecentSearchs}
              handleSearch={handleSearch}
            />
          )
        ) : null}
      </InputWrapper>
    </SearchWrapper>
  );
});

export default SearchField;
