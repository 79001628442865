import React, { useMemo, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Flex, Text, Button } from 'rebass';
import qs from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';

import EmptyList from '../../UI/EmptyList';

import { useStores } from '../../../hooks/useStore';
import useWindowInfinityScroll from '../../../hooks/useWindowInfinityScroll';
import { fromServer, isSameDate } from '../../../helpers/datetime';
import styled from '../../../theme/styled';
import { FacilityBookingLoading } from '../../UI/ContentLoading';
import { PrimaryDialog } from '../../UI/Dialog';
import TransactionDetail from './TransactionDetail';
import { getOrderByTransactionId } from '../../../api/OrderAPI';
import { IOrderTransaction } from '../../../interface/Order';

const Time = styled(Text)`
  border-top: ${(p) => p.theme.border.box};
  border-bottom: ${(p) => p.theme.border.box};
  text-transform: uppercase;
  padding: 8px 16px;
  :not(:first-of-type) {
    margin-top: 16px;
  }
`;

const BreakLine = styled(Box)`
  width: 100%;
  height: 0px;
  border-bottom: ${(p) => p.theme.border.box};
`;

const Detail = styled(Flex)`
  border-top: ${(p) => p.theme.border.box};
  border-bottom: ${(p) => p.theme.border.box};
  padding: 16px;
`;

interface Props {
  upcoming?: boolean;
}

const FacilityBookingsTab: React.FC<Props> = observer(({ upcoming }) => {
  const location = useLocation();
  const history = useHistory();

  const params = useMemo(() => qs.parse(location.search), [location]);

  const [selectedOrderId, setSelectedOrderId] = useState('');
  const { facilityBookingStore } = useStores();

  const { getOrderHistory, orders, loading, hasMore } = facilityBookingStore;

  const query = useMemo(() => ({ upcoming }), [upcoming]);

  useEffect(() => {
    const getTransactionData = async (transactionId: string) => {
      const transactions: IOrderTransaction[] = await getOrderByTransactionId(
        transactionId
      );
      if (transactions?.length) {
        setSelectedOrderId(transactions[0].orderId);
      }
    };

    if (params.bid && typeof params.bid === 'string') {
      getTransactionData(params.bid);
    } else {
      setSelectedOrderId('');
    }
  }, [params]);

  useWindowInfinityScroll(getOrderHistory, query);

  return (
    <Flex flexDirection="column">
      {!!orders.length &&
        orders.map((order, index) => {
          const isSamePrev =
            index > 0
              ? isSameDate(
                  order.timeRange[0],
                  orders[index - 1]?.timeRange[0]
                ) && order.venueId === orders[index - 1].venueId
              : false;

          const isSameNext =
            index < orders.length - 1
              ? isSameDate(
                  order.timeRange[0],
                  orders[index + 1]?.timeRange[0]
                ) && order.venueId === orders[index + 1].venueId
              : false;

          const timezone = order.facility.facilityGroup?.venue?.timezone || '';

          const date = fromServer(order.timeRange[0], timezone, 'EEE d, MMM y');
          const start = fromServer(order.timeRange[0], timezone, 'h:mm a');
          const end = fromServer(order.timeRange[1], timezone, 'h:mm a');

          if (!isSamePrev) {
            return (
              <React.Fragment key={order.id + start + end + date}>
                <Time bg="white" variant="label">
                  {date}
                </Time>
                <Text
                  bg="white"
                  px="3"
                  py="2"
                  variant="body.large"
                  fontWeight="bold"
                >
                  {order.facility?.facilityGroup?.venue?.venueName}
                </Text>
                <Box bg="white" px="3" pb="2">
                  <Text variant="body" mb="1">
                    {order.facility.name}
                  </Text>
                  <Text variant="body" mb="1">
                    {start} - {end}
                  </Text>
                  <Flex>
                    <Text variant="caption" mr="1">
                      Transaction ID:
                    </Text>
                    <Button
                      variant="noPadding"
                      fontWeight="bold"
                      onClick={() =>
                        setSelectedOrderId(order?.orderLineItem?.orderId)
                      }
                    >
                      {order.orderLineItem?.order?.readableId?.readableId}
                    </Button>
                  </Flex>
                </Box>
                {!isSameNext && <BreakLine />}
              </React.Fragment>
            );
          } else
            return (
              <React.Fragment key={order.id + start + end + date}>
                <Box bg="white" px="3" pb="2">
                  <BreakLine mb="2" />
                  <Text variant="body" mb="1">
                    {order.facility.name}
                  </Text>
                  <Text variant="body" mb="1">
                    {start} - {end}
                  </Text>
                  <Flex>
                    <Text variant="caption" mr="1">
                      Transaction ID:
                    </Text>
                    <Button
                      variant="noPadding"
                      fontWeight="bold"
                      onClick={() =>
                        setSelectedOrderId(order?.orderLineItem?.orderId)
                      }
                    >
                      {order.orderLineItem?.order?.readableId?.readableId}
                    </Button>
                  </Flex>
                </Box>
                {!isSameNext && <BreakLine />}
              </React.Fragment>
            );
        })}
      {!orders.length && !loading && <EmptyList text="orders" />}
      {hasMore && loading && (
        <>
          <FacilityBookingLoading />
          <FacilityBookingLoading />
        </>
      )}
      {!!selectedOrderId && (
        <PrimaryDialog
          title="Transaction Details"
          isOpen={!!selectedOrderId}
          onClose={() => {
            history.replace(history.location.pathname);
          }}
        >
          <Detail
            sx={{
              height: '100%',
              flexDirection: 'column',
              bg: '#fafafa',
              flex: 1,
            }}
          >
            <TransactionDetail orderId={selectedOrderId} isShowAction />
          </Detail>
        </PrimaryDialog>
      )}
    </Flex>
  );
});

export default FacilityBookingsTab;
