import styled from '../../theme/styled';
import { Button, Box, Card } from 'rebass';

export const ImagePlaceholder = styled(Card)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TermLink = styled(Box)`
  text-align: center;
  margin-bottom: 16px;
  padding: 0px 16px;
  a {
    color: ${p => p.theme.colors.primary};
    :hover {
      text-decoration: underline;
    }
    display: inline;
  }
`;

export const SignUpBtn = styled(Button)`
  border: 1px solid #C3D1DE;
  background: #ffffff;
  border-radius: 4px;
  height: 48px;
`;
