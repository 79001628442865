import React from 'react';
import { Flex, Text } from 'rebass';

import Switch from '../../../UI/Switch';

interface Props {
  sportPreference: {
    id: string;
    attributes: {
      id: string;
      value: number;
      name: string;
    }[];
    title: string;
  };
  isSelf: boolean;
  onEditPreferences: (
    preferenceId: string,
    attributeId: string,
    value: 0 | 1
  ) => void;
}

const SportPreference: React.FC<Props> = ({
  sportPreference,
  isSelf,
  onEditPreferences,
}) => {
  const { title, attributes } = sportPreference;
  const isAll = attributes.every(attribute => attribute.value === 0);

  return (
    <Flex
      p="3"
      mb="3"
      sx={{
        borderTop: '1px solid',
        borderBottom: '1px solid',
        borderColor: 'border',
      }}
      color="primaryText"
      flexDirection="column"
    >
      <Text fontSize="18px" fontWeight="300" mb="2">
        {title}
      </Text>
      {isSelf ? (
        attributes.slice().map(({ id, value, name }) => (
          <Flex
            key={id}
            alignItems="center"
            justifyContent="space-between"
            sx={{
              '&:not(:last-child)': {
                marginBottom: '2',
              },
            }}
          >
            <Text>{name}</Text>
            <Switch
              checked={!!value}
              onClick={() => {
                onEditPreferences(sportPreference.id, id, value === 1 ? 0 : 1);
              }}
            />
          </Flex>
        ))
      ) : (
        <Text>
          {isAll
            ? 'All'
            : attributes
                .filter(({ value }) => value > 0)
                .map(({ name }) => name)
                .join(', ')}
        </Text>
      )}
    </Flex>
  );
};

export default SportPreference;
