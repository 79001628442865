import { IOrderLineItem } from './../interface/Order';
import { observable, action, runInAction, computed } from 'mobx';
import { SEARCH_LIMIT } from '../constant';
import { getFacilityBookingsHistory } from '../api/ProfileAPI';
import {
  IFacilityBookingOrder,
  ICancelationPolicy,
  IOrder,
} from '../interface/Order';
import cogoToast from 'cogo-toast';
import {
  getOrderById,
  getCancelationPolicy,
  cancelFacilityBooking,
  rescheduleFacilityBooking,
} from '../api/OrderAPI';

class FacilityBookingStore {
  @observable
  orders: IFacilityBookingOrder[] = [];

  @observable
  loading: boolean = false;

  @observable
  hasMore: boolean = true;

  params: { offset: number; limit: number } = {
    offset: 0,
    limit: SEARCH_LIMIT,
  };

  @action
  getOrderHistory = async (
    { upcoming }: { upcoming: boolean },
    isReset?: boolean
  ) => {
    if (this.loading) return;
    if (!this.hasMore && !isReset) return;
    if (isReset) {
      this.params.offset = 0;
      this.orders = [];
    }
    this.loading = true;
    const mergedParams = {
      ...this.params,
      upcoming,
    };
    try {
      const orders: IFacilityBookingOrder[] = await getFacilityBookingsHistory(
        mergedParams
      );
      const dataLength = orders.length;
      runInAction(() => {
        if (dataLength < SEARCH_LIMIT) this.hasMore = false;
        this.orders = [...this.orders, ...orders];
        this.params.offset =
          dataLength === SEARCH_LIMIT
            ? this.params.offset + dataLength
            : this.params.offset;
      });
    } catch {
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @observable
  order: IOrder | null = null;

  @observable
  policy: ICancelationPolicy[] = [];

  @observable
  state: 'initial' | 'done' | 'error' | 'loading' = 'initial';

  @observable
  selectedLineItems: IOrderLineItem[] = [];

  @action
  getOrderDetail = async (orderId: string) => {
    this.state = 'loading';
    this.selectedLineItems = [];
    try {
      const order = await getOrderById(orderId);
      runInAction(() => {
        this.state = 'done';
        this.order = order;
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'error';
        cogoToast.error(
          error.response?.data?.message || 'Something wrong, please try again'
        );
      });
    }
  };

  @action
  getPolicy = async (orderId: string) => {
    this.state = 'loading';
    try {
      const policy = await getCancelationPolicy(orderId);
      runInAction(() => {
        this.state = 'done';
        this.policy = policy;
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'error';
        cogoToast.error(
          error.response?.data?.message || 'Something wrong, please try again'
        );
      });
    }
  };

  @action
  cancelFacilityBooking = async (reason?: string, comment?: string) => {
    if (!this.order) return;
    this.state = 'loading';
    try {
      const order = await cancelFacilityBooking(
        this.cancelableBookingIds,
        this.order.id,
        {
          comment,
          reason,
        }
      );
      runInAction(() => {
        this.order = order;
        this.state = 'done';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'error';
      });
      throw error;
    }
  };

  @action
  rescheduleFacilityBookings = async (
    toFacilityBookings: {
      facilityId: string;
      startTime: string;
      endTime: string;
    }[]
  ) => {
    if (!this.order) return;
    this.state = 'loading';
    const fromFacilityBookings = this.selectedLineItems.map(item => ({
      id: item.facilityBooking.id,
    }));
    try {
      const order = await rescheduleFacilityBooking(
        this.order.id,
        fromFacilityBookings,
        toFacilityBookings
      );
      await this.getPolicy(this.order.id);
      runInAction(() => {
        this.order = order;
        this.state = 'done';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'error';
      });
      throw error;
    }
  };

  @action
  toggleSelectedLineItem = (item: IOrderLineItem) => {
    if (this.selectedLineItems.some(b => b.id === item.id)) {
      this.selectedLineItems = this.selectedLineItems.filter(
        b => b.id !== item.id
      );
    } else {
      this.selectedLineItems.push(item);
    }
  };

  @computed get cancelableBookingIds() {
    return this.policy
      .filter(p => p.cancelableByCustomer)
      .map(p => p.lineItem.facilityBooking.id);
  }

  @computed get reschedulableBookings() {
    return this.policy
      .filter(p => p.reschedulableByCustomer)
      .map(p => p.lineItem);
  }

  @computed get minHoursCancel() {
    if (!this.cancelableBookingIds.length) {
      const cancelationPolicy = this.policy.find(p => !p.cancelableByCustomer);
      return (
        cancelationPolicy?.cancelationPolicy?.minHoursBeforeServiceTime || 0
      );
    }
    return 0;
  }

  @computed get customerCancelationAfterReschedule() {
    return this.policy.some(
      p => p.reschedulePolicy?.customerCancelationAfterReschedule
    );
  }

  @computed get rescheduledByCustomer() {
    return this.policy.some(p => p.lineItem.meta?.rescheduledByCustomer);
  }

  @computed get totalPrice() {
    let total = 0;
    this.selectedLineItems.forEach(item => {
      total = total + item.price.price;
    });
    return total;
  }

  @computed get refundPolicies() {
    return this.policy.find(p => p.cancelationPolicy?.refundPolicies)
      ?.cancelationPolicy?.refundPolicies;
  }
}

export default FacilityBookingStore;
