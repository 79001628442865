export default function NavigatorShare(fallBack: Function, data: { title?: string, text?: string, url: string }, onSuccess: Function, onError: Function = () => { }) {
  let shareObj: any;
  shareObj = window.navigator;
  if (shareObj && shareObj.share) {
    shareObj
      .share(data)
      .then(() => onSuccess())
      .catch((error: Error) => onError());
  } else {
    fallBack();
  }
};