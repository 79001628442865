import { decorate, observable, action, runInAction } from 'mobx';
import { getPeople } from '../api/SearchAPI';
import { IPeople } from '../interface/People';
import { SEARCH_LIMIT } from '../constant';

class PeopleStore {
  people: IPeople[] = [];

  loading: boolean = false;

  hasMore: boolean = true;

  query: {
    offset: number;
  } = { offset: 0 };

  getPeople = async (query?: Object, isReset?: boolean) => {
    if (this.loading) return;
    if (!this.hasMore && !isReset) return;
    if (isReset) this.query.offset = 0;
    this.loading = true;
    let people: IPeople[] = [];
    try {
      people = await getPeople({
        ...this.query,
        ...query,
      });
    } catch {}
    const dataLength = people.length;
    runInAction(() => {
      if (dataLength !== SEARCH_LIMIT) this.hasMore = false;
      if (!isReset) {
        this.people = [...this.people, ...people];
      } else {
        this.people = people;
      }
      this.loading = false;
      this.query.offset =
        dataLength === SEARCH_LIMIT
          ? this.query.offset + dataLength
          : this.query.offset;
    });
  };
}

decorate(PeopleStore, {
  people: observable,
  loading: observable,
  hasMore: observable,
  getPeople: action,
});

export const peopleStore = new PeopleStore();
