import React, { FunctionComponent } from 'react';
import { Spinner } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';
import { Box } from 'theme-ui';

import { IVenue } from '../../interface/Venue';

import useWindowInfinityScroll from '../../hooks/useWindowInfinityScroll';
import EmptyList from '../UI/EmptyList';

import { VenueCard } from '../Cards';
import { useStores } from '../../hooks/useStore';

type Props = {
  query: Object;
};

const VenuesTab: FunctionComponent<Props> = observer(({ query }) => {
  const { venueStore } = useStores();
  const { venues, getVenues, hasMore, loading } = venueStore;

  useWindowInfinityScroll(getVenues, query);

  return (
    <Box pb={['108px', 5]}>
      {venues.map((venue: IVenue) => (
        <Box mb="3">
          <VenueCard key={venue.id} venue={venue} />
        </Box>
      ))}
      {!venues.length && !loading && <EmptyList text="venues" />}
      {hasMore && loading && <Spinner size={24} intent="primary" />}
    </Box>
  );
});

export default VenuesTab;
