import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';
import { Spinner } from '@blueprintjs/core';
import { Box, Card } from 'rebass';

import EmptyList from '../../UI/EmptyList';

import useWindowInfinityScroll from '../../../hooks/useWindowInfinityScroll';
import ScoreBody from './ScoreBody';
import { useStores } from '../../../hooks/useStore';

const ScoreTab: FunctionComponent<{ profileId: number }> = observer(
  ({ profileId }) => {
    const { scoreStore } = useStores();
    const { getScores, scores, hasMore, loading } = scoreStore;

    useWindowInfinityScroll(getScores, profileId);

    return (
      <Box>
        {!!scores.length &&
          scores.map(score =>
            score.matches.map(match => (
              <Card my="2" mx="3" key={match.id}>
                <ScoreBody match={match} start={score.start} end={score.end} />
              </Card>
            ))
          )}
        {!scores.length && !loading && <EmptyList text="scores" />}
        {hasMore && loading && <Spinner size={24} intent="primary" />}
      </Box>
    );
  }
);

export default ScoreTab;
