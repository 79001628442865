import React, { FunctionComponent } from 'react';

import UserInformation from './components/UserInformation';
import SportProfile from './components/SportProfile';
import Timeline from '../Timeline';
import { ProfileLoading } from '../UI/ContentLoading';

import { IPeople } from '../../interface/People';
import IActivityTimeline from '../../interface/ActivityTimeline';

import { Flex } from 'theme-ui';

const ProfileBody: FunctionComponent<{
  profile: IPeople;
  timeline: { activities: IActivityTimeline[]; lastActivityId: string | null };
  isSelf: boolean;
  loading: boolean;
  timelineLoading: boolean;
}> = ({ profile, timeline, isSelf, loading, timelineLoading }) => {
  const { sportProfiles } = profile;

  return (
    <Flex
      as="main"
      sx={{
        flexDirection: 'column',
      }}
    >
      {loading ? (
        <ProfileLoading />
      ) : (
        <>
          <UserInformation
            profile={profile}
            isSelf={isSelf}
            loading={loading}
          />
          <SportProfile
            sportProfiles={sportProfiles.filter((s) => s.status === 'ACTIVE')}
            isSelf={isSelf}
            loading={loading}
          />
          <Timeline
            profile={profile}
            timeline={timeline}
            timelineLoading={timelineLoading}
            isSelf={isSelf}
          />
        </>
      )}
    </Flex>
  );
};

export default ProfileBody;
