import React from 'react';
import { Box, Flex, Image, Text } from 'rebass';
import { useHistory } from 'react-router-dom';

import { NotificationLoading } from '../../UI/ContentLoading';
import { Icon } from '../../Primitives';
import { IconNames } from '../../Primitives/Icon/iconTypes';

import EmptyNotification from '../../../assets/empty-notification.png';
import { WHATS_NEW_URL } from '../../../constant';

import { ColumnItem } from '../Notification.styled';

interface EmptyNotificationProps {
  title?: string;
  description?: string;
}

export const EmptyNotificationScreen: React.FC<EmptyNotificationProps> = ({
  title,
  description,
}) => {
  return (
    <Flex justifyContent="center">
      <Flex
        alignItems="center"
        mt="4"
        flexDirection="column"
        px="2"
        maxWidth="360px"
      >
        <Image src={EmptyNotification} />
        <Text variant="headline" my="2">
          {title || "Let's make some noise!"}
        </Text>
        <Text variant="body" color="secondaryText" textAlign="center">
          {description}
        </Text>
      </Flex>
    </Flex>
  );
};

export const LoadingItem = () => {
  return (
    <>
      <ColumnItem p="3">
        <NotificationLoading />
      </ColumnItem>
      <ColumnItem p="3">
        <NotificationLoading />
      </ColumnItem>
    </>
  );
};

export const WhatsNew: React.FC = () => {
  return (
    <ColumnItem
      alignItems="center"
      p="3"
      as="a"
      href={WHATS_NEW_URL}
      target="_blank"
      noreferrer
      noopener
      sx={{
        ':hover': {
          textDecoration: 'none',
        },
      }}
    >
      <Icon icon="news" size="lg" />
      <Text variant="body" fontWeight="bold" ml="1">
        What's new on Rovo app?
      </Text>
      <Flex ml="auto" alignItems="center">
        <Icon icon="chevron-right" size="lg" fill="primaryText" />
      </Flex>
    </ColumnItem>
  );
};

interface RequestItemProps {
  numOfUnRead?: number;
  title: string;
  icon: IconNames;
  path: string;
}

export const RequestItem: React.FC<RequestItemProps> = ({
  numOfUnRead = 0,
  title,
  icon,
  path,
}) => {
  const history = useHistory();
  return (
    <ColumnItem alignItems="center" p="3" onClick={() => history.push(path)}>
      <Icon icon={icon} size="lg" />
      <Text variant="body" fontWeight="bold" ml="1">
        {title}
      </Text>
      <Flex ml="auto" alignItems="center">
        {numOfUnRead > 0 && <Box variant="number.danger">{numOfUnRead}</Box>}
        <Icon icon="chevron-right" size="lg" fill="primaryText" />
      </Flex>
    </ColumnItem>
  );
};
