import React from 'react';
import { Flex } from 'theme-ui';

import { Icon, Text } from '../Primitives';

type Props = {
  overallStars: number;
  reviewsNumber: number;
  isShowTotal?: boolean;
  invert?: boolean;
};

const ReviewStar: React.FC<Props> = ({
  reviewsNumber,
  overallStars,
  isShowTotal,
  invert,
}) => {
  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i += 1) {
      if (i <= overallStars)
        stars.push(<Icon key={i} icon="star-full-colored" size="sm" />);
      else if (i - 0.5 <= overallStars)
        stars.push(<Icon key={i} icon="star-half-colored" size="sm" />);
      else stars.push(<Icon key={i} icon="star-empty-colored" size="sm" />);
    }
    return stars;
  };
  return reviewsNumber > 0 ? (
    <Flex
      sx={{
        alignItems: 'center',
      }}
    >
      {isShowTotal && (
        <Text variant="lb.md" color={invert ? 'white' : 'primaryText'} mr="1">
          {overallStars}
        </Text>
      )}
      {renderStars()}
      <Text ml="2" color={invert ? 'white' : 'primaryText'}>
        {reviewsNumber} reviews
      </Text>
    </Flex>
  ) : (
    <Text color={invert ? 'white' : 'primaryText'}>No reviews</Text>
  );
};

export default ReviewStar;
