import React, { ReactChild } from 'react';

import { Link } from 'react-router-dom';
import styled from '../../theme/styled';

const SLink = styled(Link)`
  :hover {
    text-decoration: none;
  }
`;

const ALink = styled.a`
  color: inherit;
  :hover {
    text-decoration: none;
  }
`;

type Props = {
  to: string;
  isExtend?: boolean;
  text?: string;
  children?: ReactChild;
  target?: string;
};

export const ExtendLink: React.FC<Props> = ({ to, text, target, children }) => (
  <ALink href={to} target={target || '_blank'} rel="noopener noreferrer">
    {text || children}
  </ALink>
);

const NormalLink: React.FC<{
  to: string;
  children?: ReactChild;
}> = ({ to, children }) => <SLink to={to}>{children}</SLink>;

const ConfigLink: React.FC<Props> = ({
  to,
  text,
  isExtend,
  target,
  children,
}) => {
  if (isExtend)
    return (
      <ExtendLink to={to} text={text} target={target} children={children} />
    );
  return <NormalLink to={to}>{children}</NormalLink>;
};

export default ConfigLink;
