import React, { useState, useEffect } from 'react';
import { Radar } from 'react-chartjs-2';

interface Props {
  assessment: {
    id: string;
    name: string;
    value: number;
  }[];
  selfSkillData: number[];
  isShowSelfSkill: boolean;
}

const SkillChart: React.FC<Props> = ({
  assessment,
  selfSkillData,
  isShowSelfSkill,
}) => {
  const [labels, setLabels] = useState<string[]>([]);
  const [values, setValues] = useState<number[]>([]);
  const [cover, setCover] = useState<number[]>([]);

  useEffect(() => {
    const values: number[] = [];
    const labels: string[] = [];
    const cover: number[] = [];
    if (assessment && assessment?.length) {
      assessment.forEach(element => {
        if (element.id === 'base') return;
        values.push(element.value);
        labels.push(element.name.toUpperCase());
      });
      setLabels(labels);
      setValues(values);
      cover.length = values.length;
      cover.fill(6);
      setCover(cover);
    }
  }, [assessment]);

  return (
    <Radar
      data={{
        labels: labels || [],
        datasets: [
          {
            label: 'Assessment',
            backgroundColor: 'rgba(6,190,189,0.3)',
            borderColor: 'transparent',
            pointBackgroundColor: '#fff',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: '#fff',
            data: values,
          },
          {
            label: 'cover',
            backgroundColor: 'rgba(52,70,106,0.5)',
            borderColor: 'transparent',
            pointBackgroundColor: 'transparent',
            pointBorderColor: 'transparent',
            pointHoverBackgroundColor: 'transparent',
            pointHoverBorderColor: 'transparent',
            data: cover,
          },
          {
            label: 'self',
            backgroundColor: 'transparent',
            borderColor: isShowSelfSkill ? 'rgba(6,190,189,1)' : 'transparent',
            pointBackgroundColor: isShowSelfSkill ? '#fff' : 'transparent',
            pointBorderColor: isShowSelfSkill ? '#fff' : 'transparent',
            pointHoverBackgroundColor: isShowSelfSkill ? '#fff' : 'transparent',
            pointHoverBorderColor: isShowSelfSkill ? '#fff' : 'transparent',
            data: selfSkillData,
          },
        ],
      }}
      options={{
        animation: {
          duration: 0,
        },
        legend: {
          display: false,
        },
        scale: {
          gridLines: {
            color: 'rgba(255,255,255,0.2)',
          },
          ticks: {
            display: false,
            suggestedMin: 1,
            suggestedMax: 6,
            beginAtZero: true,
            stepSize: 2,
          },
          tooltips: {
            enabled: false,
          },
          title: {
            display: false,
          },
          pointLabels: {
            fontColor: '#fff',
            fontSize: 12,
            fontStyle: 'bold',
          },
        },
      }}
    />
  );
};

export default SkillChart;
