import { observable, action, runInAction, computed, when } from 'mobx';
import { IActivity } from '../interface/Activity';
import { SEARCH_LIMIT } from '../constant';
import { getSelfPlays } from '../api/ProfileAPI';
import { userStore } from './UserStore';

class ScheduleStore {
  constructor() {
    when(
      () => userStore.account.id > -1,
      () => {
        this.getUpcomingActivities(this.params, true);
      }
    );
  }

  @observable
  activities: IActivity[] = [];

  @observable
  loading: boolean = false;

  @observable
  hasMore: boolean = false;

  params: { offset: number; limit: number } = {
    offset: 0,
    limit: SEARCH_LIMIT,
  };

  @action
  getUpcomingActivities = async (params: Object, isReset?: boolean) => {
    if (this.loading) return;
    if (!this.hasMore && !isReset) return;
    if (isReset) this.params.offset = 0;
    this.loading = true;
    let activities: IActivity[] = [];
    const mergedParams = {
      ...this.params,
      ...params,
    };
    try {
      activities = await getSelfPlays(mergedParams);
    } catch {}
    const dataLength = activities.length;
    runInAction(() => {
      if (dataLength < SEARCH_LIMIT) this.hasMore = false;
      if (!isReset) {
        this.activities = [...this.activities, ...activities];
      } else {
        this.activities = activities;
      }
      this.loading = false;
      this.params.offset =
        dataLength === SEARCH_LIMIT
          ? this.params.offset + dataLength
          : this.params.offset;
    });
  };

  @computed
  get totalActivities() {
    return this.activities.length;
  }
}

export default ScheduleStore;
