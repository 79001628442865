const newBrand = {
  dp: {
    xs: {
      fontWeight: 'medium',
      fontSize: '36px',
      lineHeight: '44px',
    },
    sm: {
      fontWeight: 'medium',
      fontSize: '44px',
      lineHeight: '52px',
    },
    md: {
      fontWeight: 'medium',
      fontSize: '52px',
      lineHeight: '64px',
    },
    lg: {
      fontWeight: 'medium',
      fontSize: '96px',
      lineHeight: '112px',
    },
  },
  hd: {
    xs: {
      fontWeight: 'medium',
      fontSize: '20px',
      lineHeight: '28px',
    },
    sm: {
      fontWeight: 'medium',
      fontSize: '24px',
      lineHeight: '32px',
    },
    md: {
      fontWeight: 'medium',
      fontSize: '28px',
      lineHeight: '36px',
    },
    lg: {
      fontWeight: 'medium',
      fontSize: '32px',
      lineHeight: '40px',
    },
    xl: {
      fontWeight: 'medium',
      fontSize: '36px',
      lineHeight: '44px',
    },
    xxl: {
      fontWeight: 'medium',
      fontSize: '40px',
      lineHeight: '52px',
    },
  },
  pg: {
    xxs: {
      fontWeight: 'normal',
      fontSize: '10px',
      lineHeight: '14px',
    },
    xs: {
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '20px',
    },
    sm: {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
    },
    md: {
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
    },
    lg: {
      fontWeight: 'normal',
      fontSize: '18px',
      lineHeight: '28px',
    },
    xl: {
      fontWeight: 'normal',
      fontSize: '24px',
      lineHeight: '36px',
    },
  },
  lb: {
    xxs: {
      fontWeight: 'medium',
      fontSize: '10px',
      lineHeight: '14px',
    },
    xs: {
      fontWeight: 'medium',
      fontSize: '12px',
      lineHeight: '16px',
    },
    sm: {
      fontWeight: 'medium',
      fontSize: '14px',
      lineHeight: '16px',
    },
    md: {
      fontWeight: 'medium',
      fontSize: '16px',
      lineHeight: '20px',
    },
    lg: {
      fontWeight: 'medium',
      fontSize: '18px',
      lineHeight: '24px',
    },
  },
};

export default {
  body: {
    color: 'primaryText',
    fontSize: '3',
    large: {
      color: 'primaryText',
      fontSize: '4',
    },
  },
  label: {
    color: 'primaryText',
    fontSize: '2',
    textTransform: 'uppercase',
    bold: {
      color: 'primaryText',
      fontSize: '2',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
  },
  caption: {
    small: {
      color: 'primaryText',
      fontSize: '1',
    },
    large: {
      color: 'primaryText',
      fontSize: '2',
    },
  },
  tiny: {
    fontSize: '0',
    color: 'primaryText',
  },
  headline: {
    fontSize: '6',
    color: 'primaryText',
    small: {
      fontSize: '5',
      color: 'primaryText',
    },
    large: {
      fontSize: '7',
      color: 'primaryText',
    },
    xLarge: {
      fontSize: '8',
      color: 'primaryText',
    },
  },
  ...newBrand,
};
