import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Box, Flex } from 'rebass';
import { observer } from 'mobx-react-lite';
import { Tabs, Tab, Spinner } from '@blueprintjs/core';

import Text from '../../UI/Text';

import FilterSchedule from '../../Layout/FilterBar/FilterSchedule';
import { HeaderMobile } from '../../Layout/Header';
import { encodeSchedule, decodeSchedule } from '../../../helpers/search';

import { userStore } from '../../../stores/UserStore';

import styled from '../../../theme/styled';
import { Button } from 'rebass';

const Title = styled.div`
  padding: 4px 16px;
  font-weight: bold;
`;

const SportAvailability: React.FC<{
  schedule: number;
  sport: string;
  name: string;
  updateSportProfileSchedule: Function;
}> = ({ schedule, sport, name, updateSportProfileSchedule }) => {
  const parsedSchedule = decodeSchedule(schedule);
  const [currentSchedule, setSchedule] = useState(parsedSchedule);

  useEffect(() => {
    setSchedule(decodeSchedule(schedule));
  }, [schedule]);

  const handleScheduleChange = (schedule: number[]) => {
    setSchedule(schedule);
    updateSportProfileSchedule(encodeSchedule(schedule), sport);
  };

  const handleApplyAll = () => {
    updateSportProfileSchedule(encodeSchedule(parsedSchedule), sport, true);
  };

  return (
    <Box px={[0, 3]}>
      <Text color="primaryText" mb="3">
        Tell us your <strong>{name} weekly availability</strong> and only
        receive invites during these timings
      </Text>
      {parsedSchedule.length ? (
        <Text mb="3">Available on {parsedSchedule.length} slot(s) </Text>
      ) : (
        <Text mb="3">No slot available</Text>
      )}
      <FilterSchedule
        schedule={currentSchedule}
        setSchedule={handleScheduleChange}
      />
      <Flex flexDirection="column" alignItems="center" mt="2">
        <Text color="primaryText" mb="2">
          Apply same availability for other sports?
        </Text>
        <Button
          variant="noPadding"
          fontWeight="bold"
          color="primary"
          onClick={() => handleApplyAll()}
        >
          Apply availability
        </Button>
      </Flex>
    </Box>
  );
};

const Availability: React.FC = observer(() => {
  const {
    account: { profile },
    loading,
    updateSportProfileSchedule,
  } = userStore;
  const { sportProfiles } = profile;

  const isMobile = useMediaQuery({ maxWidth: 1024 });

  return (
    <Flex flexDirection="column">
      <HeaderMobile
        text="Your weekly availability"
        hasBackBtn={isMobile}
        backLink="/settings"
      />
      <Box py="3">
        {!!sportProfiles.length && !loading && (
          <Tabs id="sport-availability" className="modify-tabs">
            {sportProfiles.map(sport => (
              <Tab
                key={sport.sport}
                id={sport.sport}
                title={<Title>{sport.sport}</Title>}
                panel={
                  <SportAvailability
                    schedule={sport.schedule}
                    sport={sport.sport}
                    name={sport.sportInfo.name}
                    updateSportProfileSchedule={updateSportProfileSchedule}
                  />
                }
              />
            ))}
          </Tabs>
        )}
        {loading && <Spinner intent="primary" />}
        {!sportProfiles?.length && !loading && (
          <Text variant="body.large" textAlign="center">
            You need to select as least one sport to see availability
          </Text>
        )}
      </Box>
    </Flex>
  );
});

export default Availability;
