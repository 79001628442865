import { Flex } from 'rebass';
import PerfectScrollBar from 'react-perfect-scrollbar';

import styled from '../../theme/styled';

export const Body = styled(Flex)`
  @media (min-width: 768px) {
    border-right: ${(p) => p.theme.border.box};
    width: 600px;
  }
`;

export const ChatBodyWrapper = styled(PerfectScrollBar)`
  display: flex;
  flex-direction: column;
  padding: 0px 8px;
  height: 100%;
`;
