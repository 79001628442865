import React from 'react';
import ReactFacebookLogin, {
  ReactFacebookLoginInfo,
} from 'react-facebook-login';
import AuthService from '../../../services/authService';

import LocalStorageHelper from '../../../helpers/localStorage';

import qs from 'query-string';

import config from '../../../config';

import styled from '../../../theme/styled';
import cogoToast from 'cogo-toast';

const Wrapper = styled.div`
  span {
    width: 100%;
    button.kep-login-facebook.metro {
      margin: 16px 0px;
      width: 100%;
      border-radius: 8px;
    }
  }
`;

const IconBtnWrapper = styled.div`
  width: 48px;
  height: 48px;
  button.kep-login-facebook.metro {
    padding: 0px;
    border: 0px;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    i {
      margin: 0px;
    }
  }
`;

interface Props {
  isAuth?: boolean;
  isNoText?: boolean;
}

const FacebookLogin: React.FC<Props> = ({ isAuth, isNoText }) => {
  const onLoginByFacebook = async (userInfo: ReactFacebookLoginInfo) => {
    LocalStorageHelper.set('facebookAccessToken', userInfo.accessToken);
    try {
      if (isAuth) {
        await AuthService.loginWithFacebook(userInfo);
      } else {
        await AuthService.connectProvider(userInfo.accessToken, 'FACEBOOK');
      }
    } catch (error) {
      const message =
        error?.response?.data?.errorMessage || 'Connect with facebook failed';
      return cogoToast.error(message);
    }
    if (window.location.search) {
      let q = qs.parse(window.location.search.substr(1));
      if (q.re && typeof q.re === 'string') {
        return window.location.replace(q.re);
      }
    }
    if (
      window.location.pathname === '/sign-in' ||
      window.location.pathname === '/sign-up'
    ) {
      return (window.location.pathname = '/explore');
    }
  };

  if (isNoText)
    return (
      <IconBtnWrapper>
        <ReactFacebookLogin
          typeButton="button"
          appId={config.FB.APP_ID}
          autoLoad={false}
          fields="name,email,picture"
          textButton={isNoText ? '' : 'Continue with Facebook'}
          icon="fa-facebook"
          callback={onLoginByFacebook}
        />
      </IconBtnWrapper>
    );

  return (
    <Wrapper>
      <ReactFacebookLogin
        appId={config.FB.APP_ID}
        autoLoad={false}
        fields="name,email,picture"
        textButton={isNoText ? '' : 'Continue with Facebook'}
        icon="fa-facebook"
        callback={onLoginByFacebook}
      />
    </Wrapper>
  );
};

export default FacebookLogin;
