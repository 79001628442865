import React, { RefObject } from 'react';
import { Text, TextProps } from 'theme-ui';

import Emojify from 'react-emojione';
import parse from 'html-react-parser';
import Linkify from 'linkifyjs/react';

export type TextVariant =
  | 'dp.xs'
  | 'dp.sm'
  | 'dp.md'
  | 'dp.lg'
  | 'hd.xs'
  | 'hd.sm'
  | 'hd.md'
  | 'hd.lg'
  | 'hd.xl'
  | 'hd.xxl'
  | 'pg.xxs'
  | 'pg.xs'
  | 'pg.sm'
  | 'pg.md'
  | 'pg.lg'
  | 'pg.xl'
  | 'lb.xxs'
  | 'lb.xs'
  | 'lb.sm' // font-size: 14px ; line-height: 16px
  | 'lb.md'
  | 'lb.lg'
  | 'lb.xl';

interface Props extends TextProps {
  variant?: TextVariant;
  textTransform?: 'initial' | 'capitalize' | 'uppercase' | 'lowercase';
  truncate?: boolean;
}

const PrimitiveText = React.forwardRef(
  (
    props: Props,
    ref?:
      | ((instance: HTMLDivElement | null) => void)
      | RefObject<HTMLDivElement>
      | null
      | undefined
  ) => {
    const { textTransform, truncate } = props;
    return (
      <Text
        {...props}
        variant={props.variant || 'pg.md'}
        ref={ref}
        sx={{
          textTransform,
          whiteSpace: truncate ? 'nowrap' : 'initial',
          overflow: truncate ? 'hidden' : 'initial',
          textOverflow: truncate ? 'ellipsis' : 'initial',
          ...props.sx,
        }}
      />
    );
  }
);

type HTMLProps = {
  text: string;
};

export const HTMLText = ({ text }: HTMLProps) => {
  return (
    <Emojify style={{ height: 16, width: 16 }}>
      <Linkify>{parse(text)}</Linkify>
    </Emojify>
  );
};

export default PrimitiveText;
