import get from 'lodash/get';
import config from '../../config';
declare global {
  interface Window {
    Razorpay: any;
  }
}

const razorPayOptions = {
  key: config.RAZORPAY_API_KEY,
  currency: 'INR',
  name: 'Rovo',
  description: 'Rovo Payment',
  image: `${config.API_HOST}/favicon.ico`,
  theme: {
    color: '#06bebd',
  },
};

export default function checkoutWithRazorpay(
  data: any,
  callback: Function,
  onDismiss = () => {}
) {
  const form = get(data, 'orderTransactions[0].formDescriptor', null);
  if (form) {
    const fields = get(form, 'formFields', {});
    const customer = get(data, 'customer', {});
    const { mobile = '', firstName = '', lastName = '', email = '' } = customer;
    const options = {
      ...razorPayOptions,
      ...fields,
      handler: callback,
      notes: {
        kb_account_id: fields['notes[kb_account_id]'],
        kb_payment_method_id: fields['notes[kb_payment_method_id]'],
        rovo_order_id: fields['notes[rovo_order_id]'],
        rovo_order_transaction_id: fields['notes[rovo_order_transaction_id]'],
      },
      prefill: {
        name: `${firstName} ${lastName}`,
        email,
        contact: mobile,
      },
      modal: {
        onDismiss,
      },
    };
    const rzp = new window.Razorpay(options);
    rzp.open();
  }
}
