import React, { useState, useEffect, useMemo } from 'react';
import { Box, Flex, Link } from 'theme-ui';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import { Icon, Text, Button, Avatar, EmptyAvatar } from '../Primitives';
import { StaticMap } from '../UI/GoogleMap';

import { PrimaryDialog } from '../UI/Dialog';

import { fromServer, isToday } from '../../helpers/datetime';
import { getRating } from '../../helpers/user';

import { userStore } from '../../stores/UserStore';

import { IActivity } from '../../interface/Activity';

import ProTips from './ProTips';
import DynamicPricing from './DynamicPricing';

import {
  TabWrapper,
  PeopleNeeded,
  Footer,
  AvatarWrapper,
} from './ActivityDetail.styled';
import { activityDetailStore } from '../../stores/ActivityDetailStore';
import LeaveDialog from '../UI/Dialog/LeaveDialog';
import JoinDialog from '../UI/Dialog/JoinDialog';
import ShareDialog from '../Layout/ShareDialog';
import config from '../../config';
import InviteDialog from './InviteDialog';
import InvitePlayers from './InvitePlayers';
import { useStores } from '../../hooks/useStore';
import ReservePlayers from './ReservePlayers';
import PayToJoinDialog from './PayToJoin';

interface Props {
  activity: IActivity;
}

const MIN = 1;
const MAX = 7;

const ActivityDetailTab: React.FC<Props> = observer(({ activity }) => {
  const {
    account: { profileId, profile },
  } = userStore;
  const { inviteStore } = useStores();
  const { getInvitedPlayers, invitedPlayers } = inviteStore;

  useEffect(() => {
    getInvitedPlayers(activity.id);
  }, [getInvitedPlayers, activity.id]);

  const { updateActivity } = activityDetailStore;

  const [isOpenProTips, setIsOpenProTips] = useState(false);
  const [isOpenJoin, setOpenJoin] = useState(false);
  const [isOpenLeave, setOpenLeave] = useState(false);
  const [isOpenShare, setOpenShare] = useState(false);
  const [isInvite, setIsInvite] = useState(false);
  const [isInvitePlayer, setInvitePlayer] = useState(false);
  const [isReservePlayer, setReservePlayer] = useState(false);
  const [isOpenPayToJoin, setIsPayToJoin] = useState(false);

  const {
    sportInfo,
    sport,
    name,
    discoverable,
    description,
    players,
    accepted,
    minPlayer,
    maxPlayer,
    minRating,
    maxRating,
    start,
    end,
    isRanked,
    courtBooked,
    timezone,
    recurring,
    priceCents,
    pricePoints,
    locations,
    waitlisted,
    id,
    allowInvite,
    uris,
  } = activity;

  const history = useHistory();

  const isWorkoutParty = useMemo(() => sport === 'WORKOUT_PARTY', [sport]);

  const uri = useMemo(() => (uris?.length ? uris[0].longUri : '' || id), [
    uris,
    id,
  ]);

  const displayStart = isToday(start, timezone)
    ? 'Today'
    : fromServer(start, timezone, 'cccc MMM dd');

  const timeStart = fromServer(start, timezone, 'h:mm a');
  const timeEnd = fromServer(end, timezone, 'h:mm a');
  const activityLocation = locations && locations.length ? locations[0] : null;

  const rule = recurring ? recurring.rrule.split(':')[1].split(';') : null;

  const freq = rule ? rule[0].split('=')[1] : null;

  const numberOfPlayNeeded = minPlayer - accepted.length;
  const isRestrict = minRating !== MIN || maxRating !== MAX;

  const renderEmptyAvatar = () => {
    const notAttendedPlayers = [];
    const totalNotAttend = maxPlayer - accepted.length;
    for (let i = 0; i < totalNotAttend; i += 1) {
      notAttendedPlayers.push(
        <Flex
          sx={{
            minWidth: '80px',
            justifyContent: 'center',
          }}
          key={`${id}-${i}-empty-player`}
        >
          <EmptyAvatar size="lg" />
        </Flex>
      );
    }
    return notAttendedPlayers;
  };
  const isJoined = !!activity.accepted.find((p) => p === profileId);
  const isWaitListed = !!activity.waitlisted.find((p) => p === profileId);
  const isHost =
    players.find((p) => p.role === 'ORGANISER')?.profileId === profileId;

  const isAllowToSeeInvite = invitedPlayers.some(
    (player) => player.profileId === profileId
  );

  const handleJoin = async () => {
    if (isJoined) return setOpenLeave(true);
    const status = isJoined ? 'DECLINED' : 'ACCEPTED';
    try {
      await updateActivity(id, status);
      setOpenJoin(true);
    } catch (error) {
      cogoToast.warn(
        error?.response?.data?.errorMessage ||
          'Something wrong, Please try again or download the app'
      );
    }
  };

  const handleLeave = async () => {
    try {
      await updateActivity(id, 'DECLINED');
      setOpenLeave(false);
    } catch (error) {
      cogoToast.warn(
        error?.response?.data?.errorMessage ||
          'Something wrong, Please try again or download the app'
      );
    }
  };

  return (
    <TabWrapper>
      {numberOfPlayNeeded > 0 && (
        <>
          <Flex
            sx={{
              alignItems: 'center',
            }}
            bg="white"
            p="3"
            onClick={() => setIsOpenProTips(true)}
          >
            <PeopleNeeded mr="3">
              <Icon icon="people" size="sm" />
            </PeopleNeeded>
            <Box>
              <Text variant="lb.md">
                {numberOfPlayNeeded} more people needed.
              </Text>
              <Text color="primary" variant="lb.md">
                5 pro tips to help you find people.
              </Text>
            </Box>
          </Flex>
          <Box variant="line" />
        </>
      )}
      <Flex
        sx={{
          flexDirection: 'column',
        }}
        p="3"
        bg="white"
      >
        <Flex
          sx={{
            alignItems: 'center',
          }}
          mb="3"
        >
          <Icon icon="activity" size="md" />
          <Box ml="2">
            <Text
              variant="lb.xs"
              color="secondaryText"
              textTransform="uppercase"
            >
              {sportInfo?.name}
            </Text>
            <Text variant="pg.lg">{name}</Text>
          </Box>
        </Flex>
        <Flex
          sx={{
            alignItems: 'center',
          }}
          mb="3"
        >
          <Icon icon="accessibility-human" size="md" />
          <Text ml="2">{discoverable ? 'Public game' : 'Private game'}</Text>
        </Flex>
        {isRestrict && (
          <Flex
            sx={{
              alignItems: 'center',
            }}
            mb="3"
          >
            <Icon icon="bookmark" size="md" />
            <Text ml="2">
              Restricted to players with level{' '}
              <Text as="span" variant="lb.md">
                {minRating} - {maxRating}
              </Text>
            </Text>
          </Flex>
        )}
        {isRanked && (
          <Flex
            sx={{
              alignItems: 'flex-start',
            }}
            mb="3"
          >
            <Icon icon="star" size="md" />
            <Box ml="2">
              <Text mb="1">Ranked game</Text>
              <Text variant="pg.xs" color="secondaryText">
                Match scores will be recorded & Pulse rank points will be
                awarded after the match
              </Text>
            </Box>
          </Flex>
        )}
        {courtBooked && (
          <Flex
            sx={{
              alignItems: 'center',
            }}
            mb="3"
          >
            <Icon icon="calendar-event" size="md" />
            <Text ml="2">Court booked</Text>
          </Flex>
        )}
        {!!priceCents && (
          <DynamicPricing
            priceCents={priceCents}
            pricePoints={pricePoints}
            numOfPlayer={accepted.length}
          />
        )}
        <Box variant="line" mb="3" />
        <Flex mb="3">
          <Icon icon="time" size="md" />
          <Box mb="2" ml="2" color="primaryText">
            <Text as="span">{displayStart}, &nbsp;</Text>
            <Text as="span" variant="lb.md">
              {timeStart} - {timeEnd}
            </Text>
            {!!freq ? (
              <Text color="secondaryText">Repeat {freq.toLowerCase()}</Text>
            ) : (
              <Text color="secondaryText">One time activity</Text>
            )}
          </Box>
        </Flex>
        {isWorkoutParty ? (
          <Flex mb="3">
            <Icon icon="location" size="md" />
            <Text color="primaryText" ml="2">
              Online
            </Text>
          </Flex>
        ) : (
          !!activityLocation && (
            <Flex mb="3">
              <Icon icon="location" size="md" />
              <Text color="primaryText" ml="2">
                {activityLocation.name}
              </Text>
            </Flex>
          )
        )}
        {activityLocation && !isWorkoutParty && (
          <StaticMap
            latitude={activityLocation.latitude}
            longitude={activityLocation.longitude}
          />
        )}
        {!!description && (
          <Flex my="3" id="activities-detail-description">
            <Box
              sx={{
                minWidth: '20px',
              }}
            >
              <Icon icon="link" size="md" />
            </Box>
            <Text
              sx={{
                whiteSpace: 'pre-line',
              }}
              color="primaryText"
              ml="2"
            >
              {description}
            </Text>
          </Flex>
        )}
        <Flex
          mb="2"
          sx={{
            flexDirection: 'column',
          }}
        >
          <Text as="span" variant="lb.md">
            {`Going ${accepted.length}/${maxPlayer}`}
          </Text>
          <Text as="span">Min {minPlayer} to start</Text>
        </Flex>
        <AvatarWrapper>
          <Flex mb="3">
            {players.map(
              ({
                role,
                profile: {
                  id,
                  picture,
                  pulseLevel,
                  sportProfile,
                  firstName,
                  username,
                  subscriptions,
                },
                reserved,
              }) => {
                const rating = getRating(sportProfile);
                if (!accepted.includes(id)) return null;
                return (
                  <Flex
                    sx={{
                      alignItems: 'center',
                      flexDirection: 'column',
                      mr: '3',
                      flex: 'none',
                    }}
                    key={id}
                  >
                    <Avatar
                      src={picture}
                      badgeText={rating}
                      pulseLevel={pulseLevel}
                      size="lg"
                      onClick={() => history.push(`/${username}`)}
                    />
                    {role === 'ORGANISER' ? (
                      <Flex
                        sx={{
                          alignItems: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <Flex
                          sx={{
                            alignItems: 'center',
                          }}
                        >
                          {firstName}{' '}
                          {!!subscriptions?.length && (
                            <Icon icon="premium" size="sm" />
                          )}
                        </Flex>
                        <Text>(Host)</Text>
                      </Flex>
                    ) : (
                      <Flex
                        sx={{
                          alignItems: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <Text>{firstName}</Text>
                        {reserved && <Text>(Reserved)</Text>}
                      </Flex>
                    )}
                  </Flex>
                );
              }
            )}
            {renderEmptyAvatar()}
          </Flex>
        </AvatarWrapper>
        <Box variant="line" mb="3" />
        <Flex
          sx={{
            flexDirection: 'column',
          }}
        >
          <Text variant="lb.md">Waitlist ({waitlisted.length})</Text>
          <Text variant="pg.sm" mb={players?.length ? '3' : '0'}>
            If someone who is already in leaves, then the next person in the
            waitlist joins automatically
          </Text>
          <AvatarWrapper>
            <Flex mb="3">
              {players.map(
                ({
                  role,
                  profile: {
                    id,
                    picture,
                    pulseLevel,
                    sportProfile,
                    firstName,
                    username,
                    subscriptions,
                  },
                }) => {
                  const rating = getRating(sportProfile);
                  if (!waitlisted.includes(id)) return null;
                  return (
                    <Flex
                      sx={{
                        alignItems: 'center',
                        minWidth: '80px',
                        flexDirection: 'column',
                        mr: '3',
                      }}
                      key={id}
                    >
                      <Avatar
                        src={picture}
                        badgeText={rating}
                        pulseLevel={pulseLevel}
                        size="lg"
                        onClick={() => history.push(`/${username}`)}
                      />
                      <Flex
                        sx={{
                          alignItems: 'center',
                        }}
                      >
                        <Text mr="1">{firstName}</Text>
                        {!!subscriptions?.length && (
                          <Icon icon="premium" size="sm" />
                        )}
                      </Flex>
                      )}
                    </Flex>
                  );
                }
              )}
            </Flex>
          </AvatarWrapper>
        </Flex>
        {(isAllowToSeeInvite || isHost) && invitedPlayers?.length > 0 && (
          <Flex
            sx={{
              flexDirection: 'column',
            }}
          >
            <Text variant="lb.md">
              Invited {isHost ? `(${invitedPlayers.length})` : ''}
            </Text>
            <Text mb={invitedPlayers?.length ? '3' : '0'}>
              Organiser can see all invites while others can only see theirs.
            </Text>
            <AvatarWrapper>
              <Flex mb="3">
                {invitedPlayers.map(
                  ({
                    role,
                    profile: {
                      id,
                      picture,
                      pulseLevel,
                      sportProfile,
                      firstName,
                      username,
                      subscriptions,
                    },
                  }) => {
                    const rating = getRating(sportProfile);
                    if (id !== profileId && !isHost) return null;
                    return (
                      <Flex
                        sx={{
                          alignItems: 'center',
                          minWidth: '80px',
                          flexDirection: 'column',
                          mr: '3',
                        }}
                        key={id}
                      >
                        <Avatar
                          src={picture}
                          badgeText={rating}
                          pulseLevel={pulseLevel}
                          size="lg"
                          onClick={() => history.push(`/${username}`)}
                        />
                        <Flex
                          sx={{
                            alignItems: 'center',
                          }}
                        >
                          <Text mr="1">{firstName}</Text>
                          {!!subscriptions?.length && (
                            <Icon icon="premium" size="sm" />
                          )}
                        </Flex>
                      </Flex>
                    );
                  }
                )}
              </Flex>
            </AvatarWrapper>
          </Flex>
        )}
      </Flex>
      {!isHost && isJoined && allowInvite && (
        <Flex
          my="3"
          sx={{
            cursor: 'pointer',
            borderTop: '1px solid #E9EEF1',
            borderBottom: '1px solid #E9EEF1',
            '&:hover': {
              backgroundColor: 'primaryHover',
            },
          }}
          bg="white"
          onClick={() => setIsInvite(true)}
        >
          <Text color="primary" variant="lb.md" p="3">
            Invite people
          </Text>
        </Flex>
      )}
      <Flex
        mb="3"
        sx={{
          cursor: 'pointer',
          borderTop: '1px solid #E9EEF1',
          borderBottom: '1px solid #E9EEF1',
          '&:hover': {
            backgroundColor: 'primaryHover',
          },
        }}
        bg="white"
        onClick={() => setOpenShare(true)}
      >
        <Text color="primary" variant="lb.md" p="3">
          Share this game
        </Text>
      </Flex>

      <Footer justifyContent="space-around" bg="white" mt="auto">
        {isWorkoutParty ? (
          <Link
            sx={{
              textDecoration: 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              fontWeight: 500,
              width: '100%',
              borderRadius: '0px',
              height: '64px',
              backgroundColor: 'primary',
              ':hover': {
                color: 'white',
              },
            }}
            href={`${config.WP_URL}/parties/${uri}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            View on WorkoutParty!
          </Link>
        ) : isHost ? (
          <Button
            sx={{
              width: '100%',
            }}
            variant="flat.primary"
            onClick={() => setIsInvite(true)}
          >
            Invite people
          </Button>
        ) : (
          <Button
            sx={{
              width: '100%',
              borderRadius: '0px',
              height: '64px',
            }}
            variant={isJoined ? 'flat.danger' : 'primary'}
            onClick={handleJoin}
          >
            <Text>
              {!isJoined && accepted.length < maxPlayer && 'Join'}
              {!isJoined && accepted.length >= maxPlayer && 'Join waitlist'}
              {isJoined && isWaitListed && 'Leave waitlist'}
              {isJoined && !isWaitListed && 'Leave'}
            </Text>
          </Button>
        )}
      </Footer>
      <PrimaryDialog
        isOpen={isOpenProTips}
        onClose={() => setIsOpenProTips(false)}
        title="Pro tips"
      >
        <ProTips />
      </PrimaryDialog>

      {isOpenJoin && (
        <JoinDialog
          isOpen={isOpenJoin}
          onClose={() => {
            setOpenJoin(false);
          }}
          title={`Awesome ${profile.firstName}! You've joined ${activity.name}.`}
          description={`Our hosts go out of their way to arrange games, so joining a game is a commitment that you will turn up for the game on time.`}
        />
      )}
      {isOpenLeave && (
        <LeaveDialog
          isOpen={isOpenLeave}
          onClose={() => setOpenLeave(false)}
          onLeave={handleLeave}
          activityId={id}
        />
      )}
      {isOpenShare && (
        <ShareDialog
          isOpen={isOpenShare}
          onClose={() => setOpenShare(false)}
          url={`${config.URL}/plays/${id}`}
          text="Share this game"
        />
      )}
      {isInvite && (
        <InviteDialog
          isOpen={isInvite}
          onClose={() => setIsInvite(false)}
          showInvite={() => {
            setIsInvite(false);
            setInvitePlayer(true);
          }}
          showReserve={() => {
            setIsInvite(false);
            setReservePlayer(true);
          }}
        />
      )}
      {isInvitePlayer && (
        <InvitePlayers
          activity={activity}
          isOpen={isInvitePlayer}
          onClose={() => setInvitePlayer(false)}
        />
      )}
      {isReservePlayer && (
        <ReservePlayers
          activity={activity}
          isOpen={isReservePlayer}
          onClose={() => setReservePlayer(false)}
        />
      )}
      {isOpenPayToJoin && priceCents && (
        <PayToJoinDialog
          priceCents={priceCents}
          isOpen={isOpenPayToJoin}
          onClose={() => setIsPayToJoin(false)}
          createdById={activity.createdById}
          activityId={activity.id}
        />
      )}
    </TabWrapper>
  );
});

export default ActivityDetailTab;
