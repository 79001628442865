import React, { useEffect } from 'react';
import { Button, Flex } from 'rebass';
import { observer } from 'mobx-react-lite';

import Text from '../../UI/Text';
import { AllTabInboxLoading } from '../../UI/ContentLoading';

import { inboxStore } from '../../../stores/InboxStore';
import { userStore } from '../../../stores/UserStore';

import GroupInboxCard from '../components/GroupInboxCard';
import PeopleAndActivities from '../components/PeopleAndActivities';

import { Groups } from '../Inbox.styled';
import useWindowInfinityScroll from '../../../hooks/useWindowInfinityScroll';
import { useHistory } from 'react-router-dom';

const AllTab = observer(() => {
  const {
    groups,
    loadingGroups,
    loadingPeople,
    loadingPeopleAndActivities,
    hasMorePeopleAndActivities,
    peopleAndActivities,
    getPeopleAndActivities,
    getGroups,
  } = inboxStore;

  const {
    account: { profileId },
  } = userStore;

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  useWindowInfinityScroll(getPeopleAndActivities);

  if (loadingGroups || loadingPeople) return <AllTabInboxLoading />;

  const history = useHistory();

  return (
    <Flex flexDirection="column">
      <Flex alignItems="center" justifyContent="space-between">
        <Text textTransform="uppercase" fontWeight="bold" color="primaryText">
          groups
        </Text>
        {!!groups?.length && (
          <Button
            variant="noPadding"
            fontWeight="bold"
            onClick={() => history.push('/inbox/groups')}
          >
            See all
          </Button>
        )}
      </Flex>
      <Groups>
        {!!groups.length ? (
          groups.map(g => (
            <GroupInboxCard
              key={g.id}
              groupId={g.id}
              groupProfile={g.groupProfile}
            />
          ))
        ) : (
          <Flex>
            <Text>You haven't join any group</Text>
          </Flex>
        )}
      </Groups>
      <Text
        textTransform="uppercase"
        fontWeight="bold"
        color="primaryText"
        mb="2"
      >
        People & Activities
      </Text>
      <PeopleAndActivities
        peopleAndActivities={peopleAndActivities}
        loading={loadingPeopleAndActivities}
        hasMore={hasMorePeopleAndActivities}
        profileId={profileId}
      />
    </Flex>
  );
});

export default AllTab;
