import React from 'react';
import { Box, Card, Flex, Text, Button } from 'rebass';
import { ISelectedSlot } from '../FacilityBooking';
import { DateTime } from 'luxon';
import { InputCoupon, ApplyCouponButton } from '../FacilityBooking.styled';
import { IOrderLineItem } from '../../../interface/Order';
import { centsToDecimal } from '../../../helpers/currency';
import { Icon } from '../../Primitives';
import { Spinner } from '@blueprintjs/core';

interface Props {
  venueName?: string;
  selectedFacilities: string[];
  setStep: Function;
  sortSlots: ISelectedSlot[];
  currency: string;
  fee: number;
  total: number;
  coupon: string;
  setCoupon: Function;
  setCouponDetail: Function;
  couponDetail: IOrderLineItem | undefined;
  applyCoupon: Function;
  isDryrun: boolean;
}

const PaymentSummary: React.FC<Props> = ({
  venueName,
  selectedFacilities,
  setStep,
  sortSlots,
  setCoupon,
  couponDetail,
  setCouponDetail,
  fee,
  total,
  coupon,
  currency,
  applyCoupon,
  isDryrun,
}) => {
  if (isDryrun)
    return (
      <Card>
        <Spinner intent="primary" size={20} />
      </Card>
    );
  return (
    <Card>
      <Flex alignItems="center" justifyContent="space-between" mb="3">
        <Text variant="body" fontWeight="bold">
          Booking at {venueName}
        </Text>
        <Button
          variant="noPadding"
          fontWeight="bold"
          onClick={() => setStep(1)}
        >
          Edit
        </Button>
      </Flex>
      {selectedFacilities.map(facilityName => {
        const filteredSlots = sortSlots.filter(
          slot => slot.name === facilityName
        );
        return (
          <Box mb="3" key={facilityName}>
            <Box
              width="100%"
              bg="dark6"
              sx={{
                borderRadius: '2px',
              }}
              color="primaryText"
              mb="2"
              p="1"
            >
              {facilityName}
            </Box>
            {filteredSlots.map((slot, index) => {
              const start = DateTime.fromFormat(slot.start, 'h:mm').toFormat(
                'h:mm a'
              );
              const end = DateTime.fromFormat(slot.end, 'h:mm').toFormat(
                'h:mm a'
              );

              const shouldDisplayNewDay =
                index === 0 ||
                (index > 0 && filteredSlots[index - 1].date !== slot.date);

              return (
                <React.Fragment key={index}>
                  {shouldDisplayNewDay && (
                    <Text variant="caption.large" fontWeight="bold" mb="2">
                      {DateTime.fromISO(slot.date)
                        .toFormat('EEE dd, MMM y')
                        .toUpperCase()}
                    </Text>
                  )}
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    mb="1"
                  >
                    <Text variant="body" my="1">
                      {start} - {end}
                    </Text>
                    <Text variant="body" my="1" fontWeight="bold">
                      {slot.price?.price === 0
                        ? 'Free'
                        : `${slot.price?.price} ${slot.price?.currency}`}
                    </Text>
                  </Flex>
                </React.Fragment>
              );
            })}
          </Box>
        );
      })}
      <Flex mt="3" alignItems="center">
        {couponDetail ? (
          <>
            <Icon
              icon="close"
              size="lg"
              onClick={() => {
                setCoupon('');
                setCouponDetail(undefined);
              }}
            />
            <Text variant="body" ml="3">
              Discount
            </Text>
            <Text ml="auto" variant="body">
              {centsToDecimal(
                couponDetail.priceCents.price,
                couponDetail.priceCents.currency
              )}{' '}
              {couponDetail.priceCents.currency}
            </Text>
          </>
        ) : (
          <>
            <InputCoupon
              placeholder="Enter Coupon"
              value={coupon}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCoupon(e.target.value)
              }
            />
            <ApplyCouponButton height="40px" onClick={applyCoupon}>
              Apply
            </ApplyCouponButton>
          </>
        )}
      </Flex>
      <Box variant="line" my="3" />
      {!!fee && (
        <>
          <Flex alignItems="center" justifyContent="space-between">
            <Text variant="body">Taxes & non-refundable processing fee</Text>
            <Text variant="body" fontWeight="bold">
              {fee} {currency}
            </Text>
          </Flex>
          <Box variant="line" my="3" />
        </>
      )}
      <Flex alignItems="center" justifyContent="space-between">
        <Text variant="body.large" fontWeight="bold">
          Total
        </Text>
        <Text variant="body.large" fontWeight="bold">
          {total === 0 ? 'Free' : `${total} ${currency}`}
        </Text>
      </Flex>
    </Card>
  );
};

export default PaymentSummary;
