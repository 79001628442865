import { decorate, observable, action, runInAction, computed } from 'mobx';
import {
  getSelfTimeline,
  getSelfPlays,
  getProfile,
  getTimeline,
  getFeed,
  getCurrentAccount,
  updateSportProfileSchedule,
  updateProfile,
  uploadUserProfilePicture,
  updateFollow,
  addSports,
  removeSport,
  updateSportProfile,
  updateActivityLike,
  createPost,
  uploadImage,
  updateAccount,
} from '../api/ProfileAPI';
import AuthService from '../services/authService';
import FirebaseService from '../services/firebaseService';

import {
  IPeople,
  defaultPeople,
  IAccount,
  ISportProfile,
  IUserLocation,
} from '../interface/People';
import IActivityTimeline from '../interface/ActivityTimeline';
import { IActivity } from '../interface/Activity';
import LocalStorageHelper from '../helpers/localStorage';
import cogoToast from 'cogo-toast';
import websocketService from '../services/websocketService';
import firebaseService from '../services/firebaseService';

const displayActivities = ['post', 'play', 'host', 'award'];

class UserStore {
  account: IAccount = {
    profile: defaultPeople,
    id: -1,
    freshchatId:  null,
    profileId: -1,
    referralLink: '',
    status: '',
    registeredAt: '',
  };

  profile: IPeople = defaultPeople;

  selfTimeline: {
    activities: IActivityTimeline[];
    lastActivityId: string | null;
  } = { activities: [], lastActivityId: '' };

  timeline: {
    activities: IActivityTimeline[];
    lastActivityId: string | null;
  } = { activities: [], lastActivityId: '' };

  feed: { activities: IActivityTimeline[]; lastActivityId: string | null } = {
    activities: [],
    lastActivityId: '',
  };

  upcomingActivities: IActivity[] = [];

  loading: boolean = false;

  timelineLoading: boolean = false;

  selfTimelineLoading: boolean = false;

  feedLoading: boolean = false;

  getSelfProfile = async () => {
    this.loading = true;
    const currentUser = LocalStorageHelper.get('currentUser');
    try {
      const account = await getCurrentAccount();
      runInAction(() => {
        this.setAccount(account);
      });
    } catch {
      if (currentUser.account.id > -1) this.account = currentUser.account;
    }
    runInAction(() => {
      this.loading = false;
    });
  };

  getSelfTimeline = async (isReset?: boolean) => {
    if (this.selfTimelineLoading || this.selfTimeline.lastActivityId === null)
      return;
    this.selfTimelineLoading = true;
    if (isReset) this.selfTimeline = { activities: [], lastActivityId: '' };
    try {
      const selfTimeline: {
        activities: IActivityTimeline[];
        lastActivityId: string | null;
      } = await getSelfTimeline(this.selfTimeline.lastActivityId);
      runInAction(() => {
        if (selfTimeline.lastActivityId !== this.selfTimeline.lastActivityId) {
          this.selfTimeline = {
            activities: [
              ...this.selfTimeline.activities,
              ...selfTimeline.activities,
            ],
            lastActivityId: selfTimeline.lastActivityId,
          };
        }
        this.selfTimelineLoading = false;
      });
      const displayTimeline = selfTimeline.activities.filter((t) =>
        displayActivities.includes(t.verb)
      );
      if (!displayTimeline.length) this.getSelfTimeline();
    } catch {}
    runInAction(() => {
      this.selfTimelineLoading = false;
    });
  };

  getTimeline = async (profileId: number, isReset?: boolean) => {
    if (
      profileId === -1 ||
      this.timelineLoading ||
      this.profile.isPrivate ||
      this.timeline.lastActivityId === null
    )
      return;
    if (isReset) this.timeline = { activities: [], lastActivityId: '' };
    this.timelineLoading = true;
    try {
      const timeline: {
        activities: IActivityTimeline[];
        lastActivityId: string | null;
      } = await getTimeline(profileId, this.timeline.lastActivityId);
      runInAction(() => {
        if (timeline.lastActivityId !== this.timeline.lastActivityId) {
          this.timeline = {
            activities: [...this.timeline.activities, ...timeline.activities],
            lastActivityId: timeline.lastActivityId,
          };
        }
        this.timelineLoading = false;
      });
      const displayTimeline = timeline.activities.filter((t) =>
        displayActivities.includes(t.verb)
      );
      if (!displayTimeline.length) this.getTimeline(profileId);
    } catch {}
    this.timelineLoading = false;
  };

  getFeed = async (isReset?: boolean) => {
    if (this.feedLoading || this.feed.lastActivityId === null) return;
    this.feedLoading = true;
    if (isReset) this.feed = { activities: [], lastActivityId: '' };
    try {
      const feed: {
        activities: IActivityTimeline[];
        lastActivityId: string | null;
      } = await getFeed(this.feed.lastActivityId);
      runInAction(() => {
        if (feed.lastActivityId !== this.feed.lastActivityId) {
          this.feed = {
            activities: [...this.feed.activities, ...feed.activities],
            lastActivityId: feed.lastActivityId,
          };
        }
        this.feedLoading = false;
      });
      const displayTimeline = feed.activities.filter((t) =>
        displayActivities.includes(t.verb)
      );
      if (!displayTimeline.length) this.getFeed();
    } catch {}
    runInAction(() => {
      this.feedLoading = false;
    });
  };

  getUpcomingActivities = async (params?: { limit: number }) => {
    try {
      const upcomingActivities = await getSelfPlays(params);
      runInAction(() => {
        this.upcomingActivities = upcomingActivities;
      });
    } catch {}
  };

  getProfile = async (username?: string) => {
    this.loading = true;
    let profile = defaultPeople;
    try {
      profile = await getProfile(username);
      runInAction(() => {
        this.profile = profile;
      });
    } catch (error) {}
    runInAction(() => {
      this.loading = false;
    });
  };

  updateSportProfileSchedule = async (
    schedule: number,
    sport: string,
    applyGlobal: boolean = false
  ) => {
    this.loading = true;
    try {
      const profile = await updateSportProfileSchedule(
        schedule,
        sport,
        applyGlobal
      );
      runInAction(() => {
        this.account.profile = profile;
      });
    } catch {}
    this.loading = false;
  };

  updateProfile = async (data: {
    username?: string;
    firstName?: string;
    lastName?: string;
    isPrivate?: boolean;
    gender?: string;
    defaultCurrencyId?: string;
    location?: IUserLocation;
  }) => {
    this.loading = true;
    try {
      const profile = await updateProfile(data);
      runInAction(() => {
        const currentUser = LocalStorageHelper.get('currentUser');
        LocalStorageHelper.set('currentUser', {
          ...currentUser,
          account: { ...currentUser.account, profile },
        });
        this.account.profile = profile;
      });
    } catch {}
    this.loading = false;
  };

  uploadImageProfile = async (image: any) => {
    this.loading = true;
    try {
      const profile = await uploadUserProfilePicture(image);
      runInAction(() => {
        this.setAccount({ ...this.account, profile });
      });
    } catch (error) {
    } finally {
      this.loading = false;
    }
  };

  updateFollow = async () => {
    const { id, followStatus, isPrivate } = this.profile;
    const isFollow = followStatus !== 'NOT_FOLLOWING';
    const followers = this.profile.followers;
    runInAction(() => {
      this.profile.followers = isPrivate
        ? this.profile.followers
        : !isFollow
        ? this.profile.followers + 1
        : this.profile.followers - 1;
      this.profile.followStatus = !isFollow
        ? isPrivate
          ? 'REQUESTED'
          : 'FOLLOWING'
        : 'NOT_FOLLOWING';
    });
    try {
      await updateFollow(id, isFollow);
    } catch (error) {
      runInAction(() => {
        this.profile.followers = followers;
        this.profile.followStatus = followStatus;
      });
    }
  };

  addSports = async (sports: { sport: string; level: string }[]) => {
    const {
      profile: { location },
    } = this.account;
    if (!location)
      return cogoToast.warn(
        "You didn't set location. Please go to settings and add location"
      );
    try {
      const profile = await addSports(location, sports);
      runInAction(() => {
        this.account.profile = profile;
      });
    } catch (error) {
    } finally {
    }
  };

  removeSport = async (sportProfileId: string) => {
    try {
      const profile = await removeSport(sportProfileId);
      runInAction(() => {
        this.account.profile = profile;
      });
    } catch (error) {
    } finally {
    }
  };

  updateSportProfile = async (data: ISportProfile) => {
    const { sport } = data;
    try {
      const profile = await updateSportProfile(sport, data);
      runInAction(() => {
        this.account.profile = profile;
      });
    } catch (error) {}
  };

  setAccount = (account: IAccount) => {
    const currentUser = LocalStorageHelper.get('currentUser');
    LocalStorageHelper.set('currentUser', { ...currentUser, account });
    this.account = account;
    this.profile = account.profile;
  };

  disconnectProvider = async (provider: 'FIREBASE' | 'FACEBOOK') => {
    this.loading = true;
    try {
      const account = await AuthService.disconnectProvider(provider);
      runInAction(() => {
        this.setAccount(account);
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  };

  connectProvider = async (id: string, provider: 'FIREBASE' | 'FACEBOOK') => {
    this.loading = true;
    try {
      const account = await AuthService.connectProvider(id, provider);
      runInAction(() => {
        this.setAccount(account);
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  };

  updateActivityLike = async (
    activityId: string,
    userLiked: boolean,
    likesCount: number
  ) => {
    try {
      await updateActivityLike(activityId, userLiked);
      runInAction(() => {
        const feedIndex = this.feed.activities.findIndex(
          (t) => t.id === activityId
        );
        const selfIndex = this.selfTimeline.activities.findIndex(
          (t) => t.id === activityId
        );
        const selectedIndex = this.timeline.activities.findIndex(
          (t) => t.id === activityId
        );
        if (feedIndex > -1) {
          this.feed.activities[feedIndex].custom.likesCount = userLiked
            ? likesCount + 1
            : likesCount - 1;
          this.feed.activities[feedIndex].custom.userLiked = userLiked;
        }
        if (selfIndex > -1) {
          this.selfTimeline.activities[selfIndex].custom.likesCount = userLiked
            ? likesCount + 1
            : likesCount - 1;
          this.selfTimeline.activities[selfIndex].custom.userLiked = userLiked;
        }
        if (selectedIndex > -1) {
          this.timeline.activities[selectedIndex].custom.likesCount = userLiked
            ? likesCount + 1
            : likesCount - 1;
          this.timeline.activities[selectedIndex].custom.userLiked = userLiked;
        }
      });
    } catch (error) {}
  };

  createPost = async (caption: string, images: File[]) => {
    const requestList: Promise<any>[] = [];
    images.forEach((element) => {
      requestList.push(uploadImage(element));
    });

    try {
      const media = await Promise.all(requestList);
      await createPost(caption, media);
      setTimeout(() => {
        this.getFeed(true);
      }, 2000);
    } catch (error) {
      cogoToast.error(error?.response?.data?.message || 'Create post failed');
    }
  };

  onSignOut = async () => {
    await AuthService.signOut();
    this.account = {
      profile: defaultPeople,
      id: -1,
      freshchatId: null,
      profileId: -1,
      referralLink: '',
      status: '',
      registeredAt: '',
    };
    websocketService.disconnect();
  };

  sendEmailVerify = async () => {
    if (!this.account?.email) return;
    try {
      await FirebaseService.sendEmailVerify(this.account.email);
      cogoToast.success(
        'Email sent. Please check you inbox and follow instruction.'
      );
    } catch (error) {
      cogoToast.error(error.message);
    }
  };

  updateAccount = async (data: {emailVerified?: boolean, freshchatId?: string | null}) => {
    try {
      const account = await updateAccount(data)
      this.setAccount(account)
    } catch (error) {
      throw error
    }
  }

  updateEmailVerified = async (code: string) => {
    try {
      await firebaseService.verifyEmail(code);
      this.updateAccount({emailVerified: true})
    } catch (error) {
      throw error;
    }
  };

  @computed get isSelf() {
    return (
      this.account?.profile?.id === this.profile.id && this.profile.id !== -1
    );
  }

  @computed get isCompletedProfile() {
    return (
      this.account.profile.status !== 'INCOMPLETE' &&
      !!this.account.profile.location.id
    );
  }

  @computed get isPremium() {
    return this.account.profile.subscriptions?.includes('premium-pack');
  }
}

decorate(UserStore, {
  account: observable,
  profile: observable,
  timeline: observable,
  feed: observable,
  selfTimeline: observable,
  upcomingActivities: observable,
  loading: observable,
  timelineLoading: observable,
  selfTimelineLoading: observable,
  feedLoading: observable,
  getProfile: action,
  getTimeline: action,
  getSelfProfile: action,
  getSelfTimeline: action,
  getFeed: action,
  getUpcomingActivities: action,
  updateSportProfileSchedule: action,
  setAccount: action,
  disconnectProvider: action,
  onSignOut: action,
  createPost: action,
});

export const userStore = new UserStore();
