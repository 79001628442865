import DateFnsUtils from '@date-io/luxon';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Box, Flex, Text } from 'rebass';
import { isSameDate, isToday } from '../../helpers/datetime';
import { black, dark3, primary, primaryGreen, white } from '../../theme/colors';
import { Icon } from '../Primitives';
import {
  CalendarPicker,
  DateSelect,
  DateSelectWrapper,
} from './FacilityBooking.styled';

interface Props {
  options: { value: string; label: string }[];
  facilityGroup: { value: string; label: string };
  handleChange: (value: { value: string; label: string }) => void;
  setDate: Function;
  date: DateTime;
}

export const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: primary,
      light: white,
      dark: black,
    },
    secondary: {
      main: primaryGreen,
    },
  },
});

const FilterBar: React.FC<Props> = ({
  options,
  facilityGroup,
  handleChange,
  setDate,
  date,
}) => {
  const [dateRange, setDateRange] = useState<DateTime[]>([]);
  const [isOpenPicker, setOpenPicker] = useState(false);

  const renderWeekDay = (date: DateTime) => {
    const dateRange = [];
    for (let index = 0; index < 7; index++) {
      dateRange.push(date.plus({ day: index }));
    }
    return dateRange;
  };

  useEffect(() => {
    const newDateRange = renderWeekDay(date);
    if (!dateRange.length || date > dateRange[6] || date < dateRange[0]) {
      setDateRange(newDateRange);
    }
  }, [date, dateRange]);

  const onChangeCalendar = (date: DateTime) => {
    setDate(date);
  };

  return (
    <Flex
      py="3"
      px={[2, 0]}
      alignITem="center"
      flexWrap={['wrap', 'nowrap']}
      bg="dark7"
      sx={{
        position: 'sticky',
        top: '0px',
        marginLeft: '16px',
        marginRight: '16px',
      }}
    >
      <Box width={['100%', '300px']} mb={['2', '0']}>
        <Select
          styles={{
            control: (styles) => ({
              ...styles,
              minHeight: '50px',
              minWidth: '200px',
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isSelected ? primary : 'transparent',
              zIndex: 1,
              color: state.isSelected ? 'white' : dark3,
              ':hover': {
                backgroundColor: primary,
                color: 'white',
              },
            }),
          }}
          options={options}
          value={facilityGroup}
          onChange={(value: any) => handleChange(value)}
        />
      </Box>
      <Flex width={['100%', 'initial']} ml={['0', '3']}>
        <DateSelectWrapper>
          {dateRange.map((d) => (
            <DateSelect
              key={d.toISODate()}
              bg={
                isSameDate(d.toISODate(), date.toISODate())
                  ? 'primary'
                  : 'transparent'
              }
              sx={{
                ':hover': {
                  border:
                    isToday(d.toISODate()) &&
                    isSameDate(d.toISODate(), date.toISODate())
                      ? 'none'
                      : 'box',
                },
              }}
              onClick={() => setDate(d)}
            >
              <Text
                variant="caption.large"
                fontWeight="bold"
                color={
                  isSameDate(d.toISODate(), date.toISODate())
                    ? 'white'
                    : 'secondaryText'
                }
              >
                {isToday(d.toISODate())
                  ? 'TODAY'
                  : d.toFormat('EEE').toUpperCase()}
              </Text>
              <Text
                fontWeight="bold"
                variant="body"
                color={
                  isSameDate(d.toISODate(), date.toISODate())
                    ? 'white'
                    : 'primaryText'
                }
              >
                {d.toFormat('dd')}
              </Text>
            </DateSelect>
          ))}
        </DateSelectWrapper>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Box
            sx={{
              visibility: 'hidden',
              width: '0px',
            }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <DatePicker
                label="Basic example"
                value={date}
                onChange={(date: any) => onChangeCalendar(date)}
                animateYearScrolling
                open={isOpenPicker}
                onClose={() => setOpenPicker(false)}
                minDate={DateTime.local()}
              />
            </ThemeProvider>
          </Box>
          <CalendarPicker ml="2">
            <Icon
              icon="calendar"
              size="lg"
              onClick={() => setOpenPicker(true)}
            />
          </CalendarPicker>
        </MuiPickersUtilsProvider>
      </Flex>
    </Flex>
  );
};

export default FilterBar;
