import React from 'react';
import { Box, Button, Flex, Text } from 'rebass';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Icon } from '../../Primitives';

import {
  PageBody,
  PageHeading,
  GroupRequestItem,
} from '../Notification.styled';
import { useStores } from '../../../hooks/useStore';
import Avatar from '../../UI/Avatar';
import { GroupJoinRequestLoading } from '../../UI/ContentLoading';
import { EmptyNotificationScreen } from './NotificationItems';
import useWindowInfinityScroll from '../../../hooks/useWindowInfinityScroll';

const FollowBody: React.FC = observer(() => {
  const history = useHistory();
  const { notificationStore } = useStores();
  const {
    followRequests,
    followLoading,
    hasMoreFollowRequests,
    getFollowRequests,
    approveFollow,
    deleteFollow,
    actionLoading,
  } = notificationStore;

  useWindowInfinityScroll(getFollowRequests);

  return (
    <PageBody>
      <PageHeading
        display="flex"
        height="56px"
        alignItems="center"
        width="100%"
        px={['3', '24px']}
      >
        <Icon
          icon="chevron-left"
          size="lg"
          fill="primaryText"
          onClick={() => history.goBack()}
        />
        <Text ml="2" variant="headline.small">
          Follow requests
        </Text>
      </PageHeading>
      <Box>
        {!!followRequests.length &&
          followRequests.map((f) => (
            <GroupRequestItem>
              <Avatar
                imageLink={f.follower?.picture}
                size="50px"
                pulseLevel={f.follower?.pulseLevel}
                onClick={() => history.push(`/${f.follower?.firstName}`)}
              />
              <Text ml="2" variant="body.large" fontWeight="bold">
                {f.follower?.name ||
                  `${f.follower?.firstName} ${f.follower?.lastName}`}
              </Text>
              <Flex ml="auto">
                <Button
                  disabled={actionLoading}
                  onClick={() => approveFollow(f.follower.id)}
                  variant="outline"
                  fontWeight="bold"
                  mr="2"
                >
                  Approve
                </Button>
                <Button
                  disabled={actionLoading}
                  onClick={() => deleteFollow(f.follower.id)}
                  variant="outline.normal"
                  fontWeight="bold"
                >
                  Delete
                </Button>
              </Flex>
            </GroupRequestItem>
          ))}
        {!followRequests.length && !followLoading && (
          <EmptyNotificationScreen />
        )}

        {hasMoreFollowRequests && followLoading && (
          <>
            <GroupJoinRequestLoading />
            <GroupJoinRequestLoading />
            <GroupJoinRequestLoading />
          </>
        )}
      </Box>
    </PageBody>
  );
});

export default FollowBody;
