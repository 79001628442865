import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Tabs, Tab } from '@blueprintjs/core';

import AllTab from './Tab/AllTab';
import PeopleInboxTab from './Tab/PeopleInboxTab';
import GroupInboxTab from './Tab/GroupInboxTab';
import ActivitiesInboxTab from './Tab/ActivitiesInboxTab';

import { Body, Title } from './Inbox.styled';
import { useParams, useHistory } from 'react-router-dom';

const AVAILABLE_TAB_IDS = ['all', 'people', 'groups', 'activities'];

const InboxBody: React.FC = observer(() => {
  const [selectedTab, setSelectedTab] = useState<string>('all');

  const param: { tabId?: string } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (param?.tabId && AVAILABLE_TAB_IDS.includes(param.tabId)) {
      setSelectedTab(param.tabId);
    }
  }, [param]);

  return (
    <Body as="main" flexDirection="column">
      <Tabs
        id="inbox-tabs"
        className="modify-tabs"
        onChange={(id: string) => {
          setSelectedTab(id);
          history.push(`/inbox/${id}`);
        }}
        selectedTabId={selectedTab}
        renderActiveTabPanelOnly
      >
        <Tab id="all" title={<Title>All</Title>} panel={<AllTab />} />
        <Tab
          id="people"
          title={<Title>People</Title>}
          panel={<PeopleInboxTab />}
        />
        <Tab
          id="groups"
          title={<Title>Groups</Title>}
          panel={<GroupInboxTab />}
        />
        <Tab
          id="activities"
          title={<Title>Activities</Title>}
          panel={<ActivitiesInboxTab />}
        />
      </Tabs>
    </Body>
  );
});

export default InboxBody;
