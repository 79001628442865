import React from 'react';
import { observer } from 'mobx-react-lite';

import { activityDetailStore } from '../../stores/ActivityDetailStore';

import Discussion from './Discussion';
import { IActivity } from '../../interface/Activity';

import { TabWrapper } from './ActivityDetail.styled';
import { userStore } from '../../stores/UserStore';

interface Props {
  activity: IActivity;
  isPayToJoinHost: boolean;
}

const ActivityDetailDiscussion: React.FC<Props> = observer(
  ({ activity, isPayToJoinHost }) => {
    const activityId = activity.id;
    const {
      comments,
      lastMessageWithPoll,
      loading,
      sendTextComment,
      sendImageComment,
      getComments,
    } = activityDetailStore;

    const {
      account: { profileId },
    } = userStore;

    const isJoined = activity.accepted.includes(profileId);

    const onSendTextMessage = async (text: string) => {
      await sendTextComment(activityId, text);
    };

    const onSendImageMessage = async (image: File) => {
      await sendImageComment(activityId, image);
    };

    return (
      <TabWrapper>
        <Discussion
          comments={comments.slice().reverse()}
          loading={loading}
          getMore={() => getComments(activityId)}
          lastMessageWithPoll={lastMessageWithPoll}
          onSendTextComment={onSendTextMessage}
          onSendImageComment={onSendImageMessage}
          activity={activity}
          isLockDiscussion={isPayToJoinHost && !isJoined}
        />
      </TabWrapper>
    );
  }
);

export default ActivityDetailDiscussion;
