import React, { useState } from 'react';

import IActivityTimeline from '../../../interface/ActivityTimeline';

import { getLastActive } from '../../../helpers/datetime';

import ShareDialog from '../../Layout/ShareDialog';
import LikeDetailDialog from '../../Layout/LikeDetailDialog';

import { CardWrapper, CardComment, CardHeader } from './CardComponents';
import config from '../../../config';
import { observer } from 'mobx-react-lite';

const CardContainer: React.FC<{
  item: IActivityTimeline;
  isSelf: boolean;
  isDetail?: boolean;
  onClick?: () => void;
  isShowCommentInbox?: boolean;
  isHideInteractive?: boolean;
}> = observer(
  ({
    item,
    isShowCommentInbox,
    isHideInteractive,
    isSelf,
    isDetail,
    children,
    onClick = () => {},
  }) => {
    const [isOpenShare, setShareDialog] = useState(false);
    const [isOpenLikeDetail, setLikeDetailDialog] = useState(false);

    if (!item.verb) return null;

    const {
      actor: {
        image: { url },
      },
      title,
      custom: { likesCount, commentsCount, userLiked },
      time,
      id,
    } = item;

    const lastActive = getLastActive(time);

    const shareUrl = `${config.URL}/${
      item.actor.objectType === 'partner'
        ? item.actor.rawObject.pageInfo.uri
        : item.actor.rawObject.username
    }/activities/${id}`;

    const pulseLevel =
      item.actor.objectType === 'profile'
        ? item.actor.rawObject?.pulseLevel
        : '';

    return (
      <CardWrapper
        isShowCommentInbox={isShowCommentInbox}
        flexDirection="column"
      >
        <CardHeader
          picture={url}
          pulseLevel={pulseLevel}
          header={title}
          time={lastActive}
          onClick={onClick}
        />
        {children}
        <CardComment
          pulseLevel={pulseLevel}
          numOfLike={likesCount}
          numOfComment={commentsCount}
          userLiked={userLiked}
          id={id}
          isSelf={isSelf}
          isDetail={isDetail}
          onShare={() => setShareDialog(true)}
          onOpenLikeDetail={() => setLikeDetailDialog(true)}
          isShowCommentInbox={isShowCommentInbox}
          isHideInteractive={isHideInteractive}
          onClick={onClick}
        />
        {isOpenShare && (
          <ShareDialog
            isOpen={isOpenShare}
            onClose={() => setShareDialog(false)}
            url={shareUrl}
            text="Share this post"
          />
        )}
        {isOpenLikeDetail && (
          <LikeDetailDialog
            isOpen={isOpenLikeDetail}
            onClose={() => setLikeDetailDialog(false)}
            activityId={id}
          />
        )}
      </CardWrapper>
    );
  }
);

export default CardContainer;
