import React, { FunctionComponent } from 'react';
import { Box, Card, Flex } from 'rebass';

import Avatar from '../../UI/Avatar';
import Text from '../../UI/Text';

import { IPeople } from '../../../interface/People';
import styled from '../../../theme/styled';
import { Image } from 'rebass';
import { getRating } from '../../../helpers/user';

export const CardWrapper = styled(Card)`
  :not(:last-child) {
    margin-bottom: 16px;
  }
  cursor: pointer;
  :hover {
    border: 1px solid ${p => p.theme.colors.primary};
  }
`;

type Props = {
  person: IPeople;
  selectedSport?: string;
};

const PeopleData: FunctionComponent<Props> = ({ person, selectedSport }) => {
  const {
    firstName,
    lastName,
    picture,
    sportProfiles,
    pulseLevel,
    location,
  } = person;

  const locationName = location?.name || location?.locality || location?.city;

  const firstFiveSports = sportProfiles.slice(0, 5);

  const rating = getRating(firstFiveSports[0]);

  const isShowBadge = firstFiveSports.length < 2;

  return (
    <Flex alignItems="center" mb="2">
      <Avatar
        badgeText={isShowBadge ? rating : ''}
        badgeSize="32px"
        size="60px"
        imageLink={picture}
        pulseLevel={pulseLevel}
      />
      <Box ml="4" flex="1">
        <Text
          variant="body.large"
          fontWeight="bold"
          mb="1"
        >{`${firstName} ${lastName}`}</Text>
        <Flex mb="1">
          {firstFiveSports.map(sport => {
            if (selectedSport && selectedSport !== sport.sport) return null;
            return (
              <Box key={sport.id}>
                <Image
                  mr="1"
                  width="18px"
                  src={`https://s3-ap-southeast-1.amazonaws.com/prod.rovo.co/assets/sports/sport-${sport.sportInfo.uri}-icon.png`}
                />
              </Box>
            );
          })}
        </Flex>
        <Text color="primaryText">{locationName}</Text>
      </Box>
    </Flex>
  );
};

export default PeopleData;
