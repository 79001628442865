import { userStore } from './UserStore';
import { updateFollow, removeFollower, getFollowers } from './../api/ProfileAPI';
import { decorate, observable, action, runInAction } from 'mobx';
import { IFollower } from '../interface/People';
import { SEARCH_LIMIT } from '../constant';

class FollowerStore {
  followers: IFollower[] = [];

  loading: boolean = false;

  hasMore: boolean = true;

  params: {
    offset: number;
    limit: number;
  } = { offset: 0, limit: SEARCH_LIMIT };

  getFollowers = async (profileId: number, isReset?: boolean) => {
    if (profileId === -1) return;
    if (this.loading) return;
    if (!this.hasMore && !isReset) return;
    if (isReset) {
      this.followers = [];
      this.params.offset = 0;
    }
    this.loading = true;
    let followers: IFollower[] = [];
    try {
      followers = await getFollowers(profileId, this.params);
    } catch { }
    const dataLength = followers.length;
    runInAction(() => {
      if (dataLength < SEARCH_LIMIT) this.hasMore = false;
      if (!isReset) {
        this.followers = [...this.followers, ...followers];
      } else {
        this.followers = followers;
      }
      this.loading = false;
      this.params.offset =
        dataLength === SEARCH_LIMIT
          ? this.params.offset + dataLength
          : this.params.offset;
    });
  };

  updateFollow = async (follower: IFollower) => {
    const { follower: { followStatus, id, isPrivate } } = follower;
    const isFollow = followStatus !== 'NOT_FOLLOWING';
    const selectedFollowerIndex = this.followers.findIndex(f => f.follower.id === id)
    const following = userStore.account.profile.following
    runInAction(() => {
      this.followers[selectedFollowerIndex].follower.followStatus = !isFollow ? isPrivate ? 'REQUESTED' : 'FOLLOWING' : 'NOT_FOLLOWING'
      userStore.account.profile.following = isPrivate ? following : !isFollow ? following + 1 : following - 1;
    })
    try {
      await updateFollow(id, isFollow);
    } catch (error) {
      runInAction(() => {
        this.followers[selectedFollowerIndex].follower.followStatus = followStatus
        userStore.account.profile.following = following
      })
    }
  }

  removeFollower = async (profileId: number) => {
    try {
      await removeFollower(profileId)
      runInAction(() => {
        this.followers = [...this.followers.filter(f => f.follower.id !== profileId)]
      })
    } catch (error) {

    }
  }
}

decorate(FollowerStore, {
  followers: observable,
  loading: observable,
  hasMore: observable,
  getFollowers: action,
  updateFollow: action,
});

export const followerStore = new FollowerStore();
