import React, { FunctionComponent } from 'react';
import { Box, Flex } from 'theme-ui';

import { Text } from '../Primitives';
import { ExtendLink } from '../UI/Link';

const Footer: FunctionComponent<{}> = () => (
  <Flex
    as="footer"
    sx={{
      flexDirection: 'column',
    }}
  >
    <Flex
      sx={{
        flexWrap: 'wrap',
        '> div': {
          ':not(:last-child)': {
            mr: 3,
            mb: 2,
          },
        },
      }}
    >
      <Text variant="pg.xs" color="secondaryText">
        <ExtendLink to="https://business.rovo.co/" text="Business" />
      </Text>
      <Text variant="pg.xs" color="secondaryText">
        <ExtendLink to="https://page.rovo.co/features" text="Features" />
      </Text>
      <Text variant="pg.xs" color="secondaryText">
        <ExtendLink
          to="https://www.notion.so/rovo/About-Rovo-2347b729578840b4a2c6aaed26f60bf4"
          text="About"
        />
      </Text>
      <Text variant="pg.xs" color="secondaryText">
        <ExtendLink
          to="https://www.notion.so/rovo/Rovo-Careers-208a2e996fa642269e3be77ec6d39aa4"
          text="Careers"
        />
      </Text>
      <Text variant="pg.xs" color="secondaryText">
        <ExtendLink
          to="https://www.notion.so/rovo/Press-c9b856150da74a41ad292637d0f0fbb9"
          text="Press"
        />
      </Text>
      <Text variant="pg.xs" color="secondaryText">
        <ExtendLink to="https://blog.rovo.co" text="Blog" />
      </Text>
      <Text variant="pg.xs" color="secondaryText">
        <ExtendLink to="https://page.rovo.co/p/privacy" text="Privacy" />
      </Text>
      <Text variant="pg.xs" color="secondaryText">
        <ExtendLink
          to="https://page.rovo.co/p/terms"
          text="Terms &  Conditions"
        />
      </Text>
      <Text variant="pg.xs" color="secondaryText">
        <ExtendLink
          to="https://page.rovo.co/p/community-guidelines"
          text="Community Guidelines"
        />
      </Text>
    </Flex>
    <Box variant="line" mb="3" mt="2" />
    <Text variant="pg.xs">© {new Date().getFullYear()} Rovo Pte. Ltd. </Text>
  </Flex>
);

export default Footer;
