import { primary, primaryText, danger, white } from './colors';
const solid = {
  primary: {
    borderRadius: '4px',
    color: 'white',
    bg: 'primary',
  },
};

const outline = {
  color: 'primary',
  border: '1px solid #C3D1DE',
  borderRadius: '4px',
  bg: 'transparent',
  ':hover': {
    bg: 'primaryHover',
    borderColor: 'primary',
  },

  //
  primary: {
    borderRadius: '4px',
    color: 'primary',
    bg: 'transparent',
    border: `1px solid ${primary}`,
    ':hover': {
      bg: 'primaryHover',
    },
  },
  normal: {
    borderRadius: '4px',
    color: 'primaryText',
    bg: 'transparent',
    border: `1px solid ${primaryText}`,
    ':hover': {
      bg: 'primaryTextHover',
    },
  },
  white: {
    borderRadius: '4px',
    color: 'white',
    bg: 'transparent',
    border: `1px solid ${white}`,
  },
  gray: {
    border: `1px solid`,
    borderColor: 'border',
    borderRadius: '4px',
    color: 'border',
    bg: 'transparent',
  },
  danger: {
    borderRadius: '4px',
    color: 'danger',
    bg: 'transparent',
    border: `1px solid ${danger}`,
    ':hover': {
      bg: 'dangerHover',
    },
  },
};

const flat = {
  primary: {
    border: 'none',
    p: 2,
    m: 0,
    bg: 'transparent',
    color: 'primary',
    ':hover': {
      bg: 'primaryHover',
    },
  },
  danger: {
    border: 'none',
    p: 2,
    m: 0,
    bg: 'transparent',
    color: 'danger',
    ':hover': {
      bg: 'dangerHover',
    },
  },
};

const buttons = {
  solid,
  outline,
  flat,
};

export default buttons;
