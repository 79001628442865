import React, { FunctionComponent, SetStateAction, Dispatch } from 'react';
import { Flex } from 'rebass';

import Text from '../../UI/Text';

import { SRangeSlider, LevelText } from './FilterBar.styled';

type Props = {
  levelRange: [number, number];
  setLevelRange: Dispatch<SetStateAction<[number, number]>>;
};

const FilterLevel: FunctionComponent<Props> = ({
  levelRange,
  setLevelRange,
}) => {
  return (
    <Flex flexDirection="column" my="3">
      <Text color="primaryText" fontWeight="bold" fontSize="2" mb="3">
        ORGANISER SKILL LEVEL
      </Text>
      <SRangeSlider
        min={1}
        max={7}
        stepSize={0.1}
        labelStepSize={6}
        onChange={value => setLevelRange(value)}
        value={levelRange}
      />
      <Flex alignItems="center">
        <LevelText width={1.5 / 6} textAlign="center">
          Beginner
        </LevelText>
        <LevelText width={2 / 6} textAlign="center">
          Intermediate
        </LevelText>
        <LevelText width={2.5 / 6} textAlign="center">
          Advanced
        </LevelText>
      </Flex>
    </Flex>
  );
};

export default FilterLevel;
