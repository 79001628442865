import Carousel from '@brainhubeu/react-carousel';
import { observer } from 'mobx-react-lite';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Flex, Image, Text } from 'theme-ui';
import { IPartnerProfile, IVenue } from '../../../interface/Venue';
import { userStore } from '../../../stores/UserStore';
import { dark5 } from '../../../theme/colors';
import FacilityBooking from '../../FacilityBooking';
import { PageBody } from '../../Layout/Layout.styled';
import { Button, Icon } from '../../Primitives';
import Avatar from '../../UI/Avatar';
import { StaticMap } from '../../UI/GoogleMap';
import ReviewStar from '../../UI/ReviewStar';
import { InfoItem, Session } from '../Venue.styled';
import OpenTime from './OpenTime';

const VenueBody: React.FC<{
  venue: IVenue;
  partnerProfile?: IPartnerProfile;
}> = observer(({ venue, partnerProfile }) => {
  const { account } = userStore;

  const history = useHistory();

  const [isOpenBooking, setOpenBooking] = useState(false);

  const location = useLocation();
  const parsed = qs.parse(location.search);
  const booking = parsed?.booking;

  useEffect(() => {
    if (
      booking === 'true' &&
      // account?.profile?.status !== 'INCOMPLETE' &&
      partnerProfile
    ) {
      setOpenBooking(true);
    }
  }, [booking, account, partnerProfile]);

  const handleBooking = () => {
    window.history.pushState('', '', '?booking=true');
    setOpenBooking(true);
  };

  const { media } = venue;
  const followed = partnerProfile?.followStatus === 'FOLLOWING';

  const onlineBookingEnabled =
    venue.facilityGroups?.length &&
    venue.facilityGroups.some((f) => f?.onlineBookingEnabled);

  return (
    <PageBody>
      <Flex
        px="3"
        py="2"
        bg={partnerProfile?.brandColor || 'dark5'}
        sx={{
          height: '56px',
          zIndex: '1',
          alignItems: 'center',
          position: 'sticky',
          top: '0px',
        }}
      >
        <Icon
          icon="chevron-left"
          size="lg"
          stroke="white"
          onClick={() => {
            history.goBack();
          }}
        />
      </Flex>
      <Box mb="3">
        {media.length > 0 ? (
          <Carousel>
            {media.map((m) => (
              <Image
                sx={{
                  backgroundImage: `url(${m.url})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  height: '200px',
                  width: '100%',
                }}
                key={m.url}
                src={m.url}
              />
            ))}
          </Carousel>
        ) : (
          <>
            <Flex
              sx={{
                height: '200px',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              bg={partnerProfile?.brandColor || 'dark5'}
            >
              <Icon icon="camera" size="lg" stroke="white" />
              <Text color="white" variant="lb.lg" mb="1">
                No photo yet
              </Text>
              <Text color="white" variant="lb.md">
                Help others see what this place like
              </Text>
            </Flex>
          </>
        )}
        <Flex
          sx={{
            width: '100%',
            bg: partnerProfile?.brandColor || dark5,
            opacity: 0.9,
          }}
          p="3"
        >
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Flex
              sx={{
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Text variant="headline.small" color="white">
                  {venue.venueName}
                </Text>
                <ReviewStar
                  invert
                  isShowTotal
                  overallStars={venue.reviewSummary.overallStars}
                  reviewsNumber={venue.reviewSummary.reviewsNumber}
                />
              </Box>
              {!!partnerProfile && onlineBookingEnabled && (
                <Button
                  variant="outline.white"
                  ml="auto"
                  size="md"
                  onClick={handleBooking}
                >
                  Make Booking
                </Button>
              )}
            </Flex>
            {!!venue.tags?.length && (
              <Flex
                mt="2"
                sx={{
                  flexWrap: 'wrap',
                }}
              >
                {venue.tags.map((tag) => (
                  <Box
                    key={tag}
                    sx={{
                      borderRadius: '4px',
                      border: '1px solid white',
                      color: 'white',
                      p: 1,
                      mr: 1,
                      mb: 1,
                    }}
                  >
                    {tag}
                  </Box>
                ))}
              </Flex>
            )}
          </Box>
        </Flex>
      </Box>
      {!!partnerProfile && (
        <Session
          p="3"
          mb="3"
          sx={{
            borderTop: 'box',
          }}
        >
          <Text variant="body" mb="2">
            Owned by{' '}
            <Text
              sx={{
                cursor: 'pointer',
              }}
              onClick={() =>
                (window.location.href = `/${partnerProfile.pageInfo.uri}`)
              }
              as="span"
              color="primary"
              variant="lb.md"
            >
              {partnerProfile.brandName}
            </Text>
          </Text>
          <Flex
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Avatar
              imageLink={partnerProfile.logo}
              size="56px"
              variant="avatar"
            />
            <Box
              sx={{
                textAlign: 'center',
              }}
            >
              <Text variant="lb.lg">{partnerProfile.gamesCount}</Text>
              <Text variant="pg.md">Games</Text>
            </Box>
            <Box
              sx={{
                textAlign: 'center',
              }}
            >
              <Text variant="lb.lg">{partnerProfile.followersCount}</Text>
              <Text variant="pg.md">Followers</Text>
            </Box>
            <Button
              variant={followed ? 'outline.primary' : 'outline.normal'}
              size="md"
            >
              {followed ? 'Following' : 'Follow'}
            </Button>
          </Flex>
        </Session>
      )}
      <Session mb="3">
        <Flex
          sx={{
            width: '100%',
          }}
          p="3"
        >
          <Icon icon="location" size="md" />
          <Text variant="body">{venue.location.address}</Text>
        </Flex>
        <StaticMap
          latitude={venue.location?.latitude}
          longitude={venue.location?.longitude}
        />
        {!!venue.website && (
          <Flex px="3" py="2">
            <Icon icon="clip" size="md" />
            <InfoItem ml="2">
              <Text variant="body">{venue.website}</Text>
            </InfoItem>
          </Flex>
        )}
        <OpenTime openingHours={venue.openingHours} timezone={venue.timezone} />
        {!!venue.phoneNumber && (
          <Flex px="3" py="2">
            <Icon icon="support" size="md" />
            <InfoItem ml="2">
              <Text variant="body">{venue.phoneNumber}</Text>
            </InfoItem>
          </Flex>
        )}
      </Session>
      <Text px="3" mb="1" variant="lb.md">
        FACILITIES
      </Text>
      <Session mb="3">
        {venue.facilityGroups.map((facility) => (
          <Text variant="body" p="3" key={facility.id}>
            {facility.name}
          </Text>
        ))}
      </Session>
      {isOpenBooking && partnerProfile && onlineBookingEnabled && (
        <FacilityBooking
          isOpen={isOpenBooking}
          onClose={() => setOpenBooking(false)}
          partnerProfile={partnerProfile}
        />
      )}
    </PageBody>
  );
});

export default VenueBody;
