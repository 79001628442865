import React from 'react';
import { Flex } from 'rebass';

import Text from '../UI/Text';

import styled from '../../theme/styled';

const Number = styled(Flex)`
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  background: linear-gradient(344.64deg, #b7ce63 0%, #06bebd 98.22%);
  width: 24px;
  height: 24px;
  border-radius: 24px;
`;

const PRO_TIPS = [
  {
    value: 'Invite 5-10 players to your game',
  },
  {
    value: 'Add details to your game',
    description: 'Exact time and location',
  },
  {
    value: 'Share your game on Facebook, Whatsapp or Instagram',
  },
  {
    value: 'Add title ad description to your game',
  },
  {
    value: 'Broadcast your game',
  },
];

const ProTips: React.FC = () => {
  return (
    <Flex bg="white" flexDirection="column" color="primaryText" p="3">
      <Text mb="3">
        Here's a couple of pro tips to help you get players to join your games
      </Text>
      {PRO_TIPS.map((tip, index) => (
        <Flex mb="3">
          <Number>{index + 1}</Number>
          <Flex flexDirection="column" ml="3" justifyContent="center">
            <Text fontWeight="bold">{tip.value}</Text>
            {!!tip.description && (
              <Text fontSize="12px" color="secondaryText">
                {tip.description}
              </Text>
            )}
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};

export default ProTips;
