import { decorate, observable, action, runInAction, computed } from 'mobx';
import { searchAll } from '../api/SearchAPI';
import { IActivity } from '../interface/Activity';
import { IGroup } from '../interface/Group';
import { IPeople } from '../interface/People';
import { IVenue } from '../interface/Venue';

class SearchStore {
  suggest: {
    text: string;
    _id: string;
  }[] = [];

  activities: IActivity[] = [];

  groups: IGroup[] = [];

  venues: IVenue[] = [];

  people: IPeople[] = [];

  loading: boolean = false;

  searchAll = async (query?: Object, suggest?: boolean) => {
    this.loading = true;
    try {
      const response = await searchAll(query, suggest);
      let suggestText: {
        text: string;
        _id: string;
      }[] = [];
      if (suggest) {
        suggestText = response.suggestText;
      }
      const { bucketsData } = response;
      runInAction(() => {
        if (suggestText && suggestText.length) {
          this.suggest = suggestText;
        }
        if (bucketsData) {
          this.activities = bucketsData.activities;
          this.groups = bucketsData.groups;
          this.venues = bucketsData.venues;
          this.people = bucketsData.people;
        }
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });
      throw error;
    }
  };

  get totalDoc() {
    return (
      this.activities.length +
      this.people.length +
      this.groups.length +
      this.venues.length
    );
  }

  resetSearch = () => {
    this.activities = [];
    this.people = [];
    this.groups = [];
    this.venues = [];
  };
}

decorate(SearchStore, {
  suggest: observable,
  loading: observable,
  activities: observable,
  people: observable,
  venues: observable,
  groups: observable,
  searchAll: action,
  totalDoc: computed,
});

export const searchStore = new SearchStore();
