import { Flex, Button } from 'rebass';

import styled from '../../theme/styled';

export const FollowBtn = styled(Button)`
  border-color: ${p => p.followed && p.theme.colors.primaryText};
  color: ${p => p.followed && p.theme.colors.primaryText};
  :hover {
    background-color: ${p => p.theme.colors.primaryTextHover};
  }
`;

export const PeopleWrapper = styled(Flex)`
  cursor: pointer;
  :hover {
    background-color: ${p => p.theme.colors.primaryHover};
  }
`;

export const InviteFriend = styled(Flex)`
  cursor: pointer;
  :hover {
    background-color: ${p => p.theme.colors.primaryTextHover};
  }
`;
