import React, { FunctionComponent } from 'react';
import { Box, Card, Flex } from 'rebass';
import { Spinner } from '@blueprintjs/core';
import { useHistory } from 'react-router-dom';

import Text from '../UI/Text';
import Footer from '../Layout/Footer';

import styled from '../../theme/styled';
import { IActivity } from '../../interface/Activity';
import { fromServer } from '../../helpers/datetime';
import { Button } from 'rebass';

const Wrapper = styled(Flex)`
  display: none;
  @media (min-width: 1024px) {
    display: flex;
    flex-grow: 1;
    height: 100vh;
    position: sticky;
    top: 0;
    min-width: 312px;
  }
`;

const ItemWrapper = styled(Box)`
  :not(:last-child) {
    border-bottom: ${(p) => p.theme.border.box};
  }
  padding: 16px;
`;

type Props = {
  upcomingActivities: IActivity[];
  loading: boolean;
  username: string;
};

const UpcomingActivities: FunctionComponent<Props> = ({
  upcomingActivities,
  loading,
  username,
}) => {
  const history = useHistory();

  return (
    <Wrapper px="3" pt="24px">
      <Flex maxWidth="350px" flexDirection="column">
        {!loading && !!upcomingActivities.length && (
          <>
            <Text
              variant="uppercase"
              color="primaryText"
              fontWeight="bold"
              mb="3"
            >
              Upcoming Activities
            </Text>
            <Card variant="card" p="0" mb="3">
              {loading ? (
                <Box p="3">
                  <Spinner intent="primary" />
                </Box>
              ) : (
                <>
                  {upcomingActivities
                    .slice(0, 3)
                    .map(
                      (
                        { id, sport, name, start, end, timezone, locations },
                        index
                      ) => {
                        const displayStart = fromServer(
                          start,
                          timezone,
                          'cccc MMM dd'
                        );
                        const timeStart = fromServer(start, timezone, 'h:mm a');
                        const timeEnd = fromServer(end, timezone, 'h:mm a');
                        const activityLocation =
                          locations.length && locations[0].name;
                        return (
                          <ItemWrapper key={id}>
                            <Text fontWeight="bold">{sport}</Text>
                            <Text
                              color="primaryText"
                              fontSize="16px"
                              fontWeight="bold"
                            >
                              {name}
                            </Text>
                            <Box color="primaryText">
                              <Text as="span">{displayStart}, &nbsp;</Text>
                              <Text as="span">
                                {timeStart} - {timeEnd}
                              </Text>
                            </Box>

                            {!!activityLocation && (
                              <Text>{activityLocation}</Text>
                            )}
                          </ItemWrapper>
                        );
                      }
                    )}
                  <Button
                    variant="transparent"
                    width="100%"
                    textAlign="left"
                    fontWeight="bold"
                    onClick={() => history.push('/schedule/activities')}
                  >
                    Show more
                  </Button>
                </>
              )}
            </Card>
          </>
        )}
        <Footer />
      </Flex>
    </Wrapper>
  );
};

export default UpcomingActivities;
