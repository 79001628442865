import { css } from '@emotion/core';
import styled from '../../theme/styled';
import { Text } from 'rebass';

const StyledText = styled(Text)`
  text-transform: ${p => (p.variant === 'uppercase' ? 'uppercase' : 'initial')};
  text-transform: ${p => p.textTransform};
  ${p =>
    p.truncate &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

export default StyledText;
