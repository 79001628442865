import * as firebase from 'firebase/app';
import cogoToast from 'cogo-toast';
import '@firebase/auth';
import '@firebase/remote-config';

import AuthService from './authService';
import config from '../config';
import LocalStorageHelper from '../helpers/localStorage';
import { generateCustomFirebaseToken } from '../api/ProfileAPI';

firebase.initializeApp({
  apiKey: config.FIREBASE.API_KEY,
  authDomain: config.FIREBASE.AUTH_DOMAIN,
  databaseURL: config.FIREBASE.DATABASE_URL,
  storageBucket: config.FIREBASE.STORAGE_BUCKET,
  messagingSenderId: config.FIREBASE.MESSAGING_SENDER_ID,
  appId: config.FIREBASE.APP_ID,
  projectId: config.FIREBASE.PROJECT_ID,
});

type RemoteConfigKey = 'whitelisted_paid_hosts';

class FireBaseService {
  constructor() {
    this.setCurrentUser();

    this.remoteConfig.settings = {
      fetchTimeoutMillis: 3600000,
      minimumFetchIntervalMillis: 3600000,
    };
    this.remoteConfig
      .fetchAndActivate()
      .then(() => {
        const value = this.remoteConfig.getAll();
        LocalStorageHelper.set('remoteConfig', value);
        this.config = value;
      })
      .catch((err) => {});
  }

  remoteConfig = firebase.remoteConfig();

  config: { [key: string]: firebase.remoteConfig.Value } = {};

  currentUser: firebase.User | null = null;

  getRemoteConfigByKey = (key: RemoteConfigKey) => {
    const data = this.remoteConfig.getValue(key);
    return data.asString() ? JSON.parse(data.asString()) : null;
  };

  setCurrentUser = () => {
    return firebase.auth().onAuthStateChanged((user) => {
      this.currentUser = user ? user : null;
    });
  };

  authChanged = (callback?: Function) => {
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.currentUser = user;
        return user
          .getIdToken(true)
          .then(async (idToken: string) => {
            await AuthService.loginWithToken(idToken, 'FIREBASE', callback);
          })
          .catch((err) => {
            throw err;
          });
      } else {
        firebase.auth().signOut();
      }
    });
  };
  signIn = (email: string, password: string, callback?: Function) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        this.authChanged(callback);
        return { isError: false, message: 'You have successful login' };
      })
      .catch((error) => {
        throw error;
      });
  };
  signUp = (email: string, password: string, callback?: Function) => {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(() => {
        this.authChanged(callback);
        return { message: 'Your have successful create an account' };
      })
      .catch((error) => {
        throw error;
      });
  };
  phoneVerification = async (phoneNumber: string) => {
    const appVerifier = new firebase.auth.RecaptchaVerifier(
      'phone-verification-button',
      {
        size: 'invisible',
      }
    );

    if (this.currentUser?.phoneNumber) {
      try {
        return await AuthService.verifyLogin(
          this.currentUser.uid,
          this.currentUser.phoneNumber,
          'PHONE'
        );
      } catch (error) {
        return cogoToast.error(
          'Verify phone failed, please contact us via support@rovo.co for more information.'
        );
      }
    }

    if (this.currentUser && this.currentUser.linkWithPhoneNumber) {
      return this.currentUser
        .linkWithPhoneNumber(phoneNumber, appVerifier)
        .then((confirmationResult) => {
          return confirmationResult;
        })
        .catch(async (error) => {
          await appVerifier.clear();
          throw error;
        });
    }
    return firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        return confirmationResult;
      })
      .catch(async (error) => {
        await appVerifier.clear();
        throw error;
      });
  };
  sendEmailToResetPassword = (email: string) => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return {
          message:
            'Done! Check your Email for instructions to reset your password.',
        };
      })
      .catch((error) => {
        throw error;
      });
  };
  changePassword = async (
    code: string,
    newPassword: string,
    successCallback: Function
  ) => {
    return firebase
      .auth()
      .verifyPasswordResetCode(code)
      .then(() => {
        firebase
          .auth()
          .confirmPasswordReset(code, newPassword)
          .then(() => {
            successCallback();
            cogoToast.success('Password change successfully');
          })
          .catch((error) => {
            cogoToast.error(
              error?.message ||
                'Change password failed, Please generate new one.'
            );
          });
      })
      .catch((error) => {
        cogoToast.error(
          error?.message ||
            'Change password code is invalid or expired. Please generate new one.'
        );
      });
  };
  sendEmailVerify = async (email: string) => {
    try {
      const token = await generateCustomFirebaseToken(email);
      const authResult = await firebase.auth().signInWithCustomToken(token);
      authResult.user?.sendEmailVerification();
    } catch (error) {
      throw error;
    }
  };
  verifyEmail = async (code: string) => {
    try {
      await firebase.auth().applyActionCode(code);
    } catch (error) {
      throw error;
    }
  };
  signOut = async () => {
    await firebase
      .auth()
      .signOut()
      .then(() => {
        window.location.pathname = '/';
      });
  };
}

const firebaseService = new FireBaseService();

export default firebaseService;
