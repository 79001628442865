import React, { useState, useRef } from 'react';
import { Flex, Button } from 'rebass';
import { Icon } from '../../../components/Primitives';
import { useHistory } from 'react-router-dom';
import { IGroup } from '../../../interface/Group';
import { Text } from 'rebass';
import { Image } from 'rebass';
import { Box } from 'rebass';
import Avatar from '../../../components/UI/Avatar';
import { observer } from 'mobx-react-lite';
import { userStore } from '../../../stores/UserStore';
import ShareDialog from '../../../components/Layout/ShareDialog';
import config from '../../../config';
import { getLastActive } from '../../../helpers/datetime';
import { useStores } from '../../../hooks/useStore';
import { useOnClickOutside } from '../../../hooks/useOuterClick';
import { Card } from 'rebass';

interface Props {
  group: IGroup;
}

const GroupBody: React.FC<Props> = observer(({ group }) => {
  const { account } = userStore;
  const { groupDetailStore } = useStores();
  const { joinGroup, leaveGroup } = groupDetailStore;

  const history = useHistory();

  const {
    groupProfile: {
      name,
      picture,
      privacy,
      location,
      anyoneCanJoin,
      description,
    },
    members,
    profileIds,
    lastActivity,
  } = group;

  const isPrivate = privacy === 'PRIVATE';
  const isJoined = profileIds.includes(account.profileId);
  const self = members.find((m) => m.profileId === account.profileId);

  const isRequested = self?.status === 'PENDING' ? true : false;
  const isApproved = self?.status === 'ACTIVE';

  const [isShare, setIsShare] = useState(false);
  const [isShowSetting, setIsShowSetting] = useState(false);

  const moreSetting = useRef<HTMLDivElement>();

  useOnClickOutside(moreSetting, () => setIsShowSetting(false));

  return (
    <div>
      <Flex
        sx={{
          position: 'sticky',
          top: '0',
          height: '56px',
          'z-index': 1,
        }}
        px="3"
        py="2"
        alignItems="center"
        bg="white"
      >
        <Icon
          icon="chevron-left"
          size="lg"
          fill="primaryText"
          onClick={() => {
            history.goBack();
          }}
        />
        <Text variant="headline.small">{name}</Text>
        {isApproved && (
          <Box
            ml="auto"
            ref={moreSetting}
            sx={{
              position: 'relative',
            }}
          >
            <Icon
              icon="ellipsis-vertical"
              onClick={() => setIsShowSetting(true)}
            />
            {isShowSetting && (
              <Card
                sx={{
                  position: 'absolute',
                  right: '12px',
                }}
                p="0"
              >
                <Box
                  sx={{
                    ':hover': {
                      bg: 'primaryHover',
                    },
                    p: 3,
                    cursor: 'pointer',
                    color: 'primaryText',
                  }}
                  onClick={() => leaveGroup(group.id)}
                >
                  Leave
                </Box>
              </Card>
            )}
          </Box>
        )}
      </Flex>
      <Box
        sx={{
          backgroundImage: `url(${picture})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          position: 'relative',
        }}
        height="300px"
        width="100%"
      >
        {!!group.groupProfile?.sportInfo?.uri && (
          <Image
            sx={{
              position: 'absolute',
              bottom: '-40px',
              right: '24px',
              border: '10px solid white',
              borderRadius: '100px',
            }}
            bg="white"
            width="80px"
            src={`https://s3-ap-southeast-1.amazonaws.com/prod.rovo.co/assets/sports/sport-${group.groupProfile.sportInfo.uri}-icon.png`}
          />
        )}
      </Box>
      <Box p="3">
        <Text variant="body.large" fontWeight="bold" mb="1">
          {name}
        </Text>
        <Flex alignItems="center" mb="3">
          {isPrivate ? (
            <Text variant="body.large" color="secondaryText">
              Closed group
            </Text>
          ) : (
            <>
              <Icon icon="people" size="sm" />
              <Text variant="body.large" color="secondaryText">
                {group.members.length}
              </Text>
              <Box variant="dot" />
              <Text variant="body.large" color="secondaryText">
                Public group
              </Text>
            </>
          )}
        </Flex>
        <Box variant="line" />
        <Flex justifyContent="space-around" my="1">
          {!isJoined && (
            <Button
              variant="transparent"
              fontWeight="bold"
              fontSize="16px"
              disabled={isRequested}
              onClick={() => {
                joinGroup(group.id);
              }}
            >
              Join
            </Button>
          )}
          {/* {isJoined && (
            <Button variant="transparent" fontWeight="bold" fontSize="16px">
              Invite
            </Button>
          )} */}
          <Button
            variant="transparent"
            fontWeight="bold"
            fontSize="16px"
            onClick={() => setIsShare(true)}
          >
            Share
          </Button>
        </Flex>
        <Box variant="line" />
        <Box my="3">
          <Text variant="body.large" fontWeight="bold" mb="1">
            Group admins
          </Text>
          <Flex
            sx={{
              overflow: 'auto',
            }}
          >
            {members.map((member) => {
              if (member.role !== 'ADMIN') return null;
              return (
                <Box mr="2" minWidth="40px" key={member.id}>
                  <Avatar
                    onClick={() => history.push(`/${member.profile.username}`)}
                    size="40px"
                    imageLink={member.profile.picture}
                    pulseLevel={member.profile.pulseLevel}
                  />
                </Box>
              );
            })}
          </Flex>
        </Box>
        <Box variant="line" />
        <Box my="3">
          <Text variant="body.large" fontWeight="bold" mb="1">
            Group members
          </Text>
          {isPrivate ? (
            <Flex>
              <Text>Join the group to view group members</Text>
            </Flex>
          ) : (
            <Flex
              sx={{
                overflow: 'auto',
              }}
            >
              {members.map((member) => (
                <Box mr="2" minWidth="40px" key={member.id}>
                  {member.profile.picture ? (
                    <Avatar
                      onClick={() =>
                        history.push(`/${member.profile.username}`)
                      }
                      size="40px"
                      imageLink={member.profile.picture}
                      pulseLevel={member.profile.pulseLevel}
                    />
                  ) : (
                    <Box variant="emptyAvatar" />
                  )}
                </Box>
              ))}
            </Flex>
          )}
        </Box>
        {!!location && (
          <Box mt="3">
            <Box variant="line" mb="3" />
            <Text variant="body.large" fontWeight="bold" mb="1">
              Location
            </Text>
            <Text variant="body.large">
              {location.name || location.city || location.locale}
            </Text>
          </Box>
        )}
        {!!description && (
          <Box mt="3">
            <Box variant="line" mb="3" />
            <Text variant="body.large" fontWeight="bold" mb="1">
              Description
            </Text>
            <Text variant="body.large">{description}</Text>
          </Box>
        )}
        <Box mt="3">
          <Box variant="line" mb="3" />
          <Text variant="body.large" fontWeight="bold" mb="1">
            How to join
          </Text>
          <Text variant="body.large">
            {anyoneCanJoin
              ? 'Anyone can join.'
              : 'Joining the group requires admin approval.'}
          </Text>
        </Box>
        <Box mt="3">
          <Box variant="line" mb="3" />
          <Text variant="body.large">
            Last active {getLastActive(lastActivity)}
          </Text>
        </Box>
      </Box>
      {!isApproved && (
        <Box
          sx={{
            position: 'sticky',
            bottom: '0px',
          }}
        >
          <Button
            width="100%"
            sx={{
              borderRadius: '0px',
              fontWeight: 'bold',
            }}
            height="48px"
            disabled={isRequested}
            onClick={() => {
              joinGroup(group.id);
            }}
          >
            {isRequested ? 'Requested' : 'Join'}
          </Button>
        </Box>
      )}
      {isShare && (
        <ShareDialog
          isOpen={isShare}
          onClose={() => setIsShare(false)}
          url={`${config.URL}/groups/${group.groupProfile.uri}?pid=${account.profileId}`}
          text="Share this group"
        />
      )}
    </div>
  );
});

export default GroupBody;
