import { Flex } from 'rebass';
import PerfectScrollbar from 'react-perfect-scrollbar';

import styled from '../../theme/styled';

export const Body = styled(Flex)`
  @media (min-width: 768px) {
    padding: 16px;
    border-right: ${p => p.theme.border.box};
    width: 600px;
  }
`;

export const Title = styled.div`
  padding: 4px 16px;
  font-weight: bold;
`;

export const PageWrapper = styled(Flex)`
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const Groups = styled(PerfectScrollbar)`
  display: flex;
  margin-bottom: 16px;
  margin-top: 8px;
  margin: 8px -8px 16px -8px;
`;
