import httpService from '../services/httpService';
import get from 'lodash/get';

export const getNotificationPreferences = async () => {
  try {
    const response = await httpService.get('settings/notification');
    const settings = get(response, 'data.settings', []);
    return settings
  } catch (err) {
    throw err;
  }
};

export const updateNotificationPreferences = async (data: any) => {
  try {
    const response = await httpService.put('settings/notification', data);
    const settings = get(response, 'data.settings', []);
    return settings
  } catch (err) {
    throw err;
  }
};