import React, { useState } from 'react';
import { Box, Button, Flex } from 'rebass';
import { observer } from 'mobx-react-lite';

import Text from '../../UI/Text';
import { Textarea } from '../../UI/Input';
import Switch from '../../UI/Switch';
import { ConfirmDialog } from '../../UI/Dialog';

import { fromServer } from '../../../helpers/datetime';

import { IMessage } from '../../../interface/Inbox';

import styled from '../../../theme/styled';
import { Dialog } from '@blueprintjs/core';
import { Image } from 'rebass';
import { ActivityData } from '../../Cards';
import { HTMLText } from '../../Primitives/Text';

interface Props {
  isSelf: boolean;
  message: IMessage;
  profileId: number;
  updateGameInvite: (
    messageId: number,
    playId: string,
    status: 'DECLINED' | 'ACCEPTED'
  ) => void;
}

const BubbleCard = styled(Flex)`
  border-radius: 8px;
  border: ${(p) => !p.isSelf && p.theme.border.box};
  flex-direction: column;
`;

const BoxImage = styled(Box)`
  width: 100%;
  height: 200px;
  border-radius: 8px;
  background-image: url(${(p) => p.src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

const Time = styled(Text)`
  opacity: 0.4;
  font-size: 12px;
`;

const FullImageDialog = styled(Dialog)`
  padding: 16px;
  width: initial;
  max-width: 80%;
  max-height: 80%;
  margin: 0px;
`;

const MessageBubbleCard: React.FC<Props> = observer(
  ({ isSelf, message, profileId, updateGameInvite }) => {
    const [isOpenJoin, setIsOpenJoin] = useState(false);
    const [isOpenLeave, setIsOpenLeave] = useState(false);
    const [reason, setReason] = useState('');
    const [isInform, setIsInform] = useState(false);
    const [isShowFullImage, setIsShowFullImage] = useState(false);

    const { content, type, updatedAt, id } = message;

    const hasPlay =
      content.subType === 'PLAY_INVITE' || content.subType === 'PLAY_RESERVED';

    const host = content.play?.players?.find((p) => p.role === 'ORGANISER');

    const hostName = host?.profile?.name || '';

    const isLeave = content?.play?.declined.includes(profileId);

    const isJoin = content?.play?.accepted.includes(profileId);

    const onLeave = () => {
      const { playId } = content;
      if (playId) {
        updateGameInvite(id, playId, 'DECLINED');
        setIsOpenLeave(false);
      }
    };

    const onJoin = () => {
      const { playId } = content;
      if (playId) {
        updateGameInvite(id, playId, 'ACCEPTED');
        setIsOpenJoin(false);
      }
    };

    return (
      <BubbleCard
        isSelf={isSelf}
        maxWidth={['90%', '75%']}
        minWidth={type === 'PHOTO' && '300px'}
        color={isSelf ? 'white' : 'primaryText'}
        bg={isSelf ? 'primary' : 'white'}
        p="2"
        ml={isSelf ? 'auto' : '2'}
      >
        {type === 'TEXT' && (
          <Text
            sx={{
              whiteSpace: 'pre-wrap',
            }}
            mb={hasPlay ? '2' : '0'}
          >
            <HTMLText text={content.text} />
            <Time
              as="span"
              mt="1"
              ml="1"
              color={isSelf ? 'white' : 'primaryText'}
            >
              {fromServer(updatedAt, '', 'h:mm a')}
            </Time>
          </Text>
        )}
        {type === 'PHOTO' && (
          <>
            <BoxImage
              src={content?.url}
              sx={{
                cursor: 'pointer',
              }}
              onClick={() => setIsShowFullImage(true)}
            />
            <Time mt="1" alignSelf="flex-end" color="white">
              {fromServer(updatedAt, '', 'h:mm a')}
            </Time>
          </>
        )}
        {hasPlay && content.play && (
          <Box>
            <Box variant="line" bg="white" />
            <ActivityData
              activity={content.play}
              hideTag
              isWhiteText={isSelf}
            />
            {!isSelf && (
              <Flex justifyContent="space-around">
                <Button
                  p="2"
                  disabled={isLeave}
                  variant="noPadding"
                  color="danger"
                  width="calc(50% - 4px)"
                  mr="1"
                  fontWeight="bold"
                  onClick={() => {
                    setIsOpenLeave(true);
                  }}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'dangerHover',
                    },
                    backgroundColor: isLeave && 'dangerHover',
                  }}
                >
                  Leave
                </Button>
                <Button
                  p="2"
                  disabled={isJoin}
                  variant="noPadding"
                  ml="1"
                  width="calc(50% - 4px)"
                  fontWeight="bold"
                  onClick={() => {
                    setIsOpenJoin(true);
                  }}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'primaryHover',
                    },
                    backgroundColor: isJoin && 'primaryHover',
                  }}
                >
                  Join
                </Button>
              </Flex>
            )}
          </Box>
        )}
        {isOpenLeave && (
          <ConfirmDialog
            isOpen={isOpenLeave}
            onClose={() => setIsOpenLeave(false)}
            onConfirm={onLeave}
            title="Pulling out?"
            confirmText="Yes, leave game"
            buttonColors={['primaryText', 'danger']}
            description={`Leaving the game after committing is not very nice to ${hostName}. 😞 If you have to leave, do ensure you let players know why you're pulling out.`}
          >
            <Box>
              <Textarea
                onChange={(e: { target: { value: string } }) =>
                  setReason(e.target.value)
                }
                value={reason}
                placeholder="Share your reason for leaving in the game discussion"
              />
              <Flex alignItems="center">
                <Text fontSize="12px">
                  I've informed everyone on the reason for leaving the game
                </Text>
                <Flex ml="2" flex="1 1 auto" width="50px">
                  <Switch
                    checked={isInform}
                    onChange={() => setIsInform((s) => !s)}
                  />
                </Flex>
              </Flex>
            </Box>
          </ConfirmDialog>
        )}
        {isOpenJoin && (
          <ConfirmDialog
            isOpen={isOpenJoin}
            onClose={() => setIsOpenJoin(false)}
            onConfirm={onJoin}
            confirmText="Join game"
            title="Confirm join game?"
            description={`Our host(s) go out of their way to arrange games, so joining a game is a commitment to ${hostName} that you will turn up for the game on time.`}
            buttonColors={['primaryText', 'primary']}
          />
        )}
        {isShowFullImage && (
          <FullImageDialog
            isOpen={isShowFullImage}
            onClose={() => setIsShowFullImage(false)}
          >
            <Image src={content?.url} width="100vw" />
          </FullImageDialog>
        )}
      </BubbleCard>
    );
  }
);

export default MessageBubbleCard;
