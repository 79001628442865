import React, { useRef, useMemo, useState, useEffect } from 'react';
import { Flex } from 'rebass';
import { Spinner } from '@blueprintjs/core';

import EmptyList from '../UI/EmptyList';
import { observer } from 'mobx-react-lite';
import { IActivity } from '../../interface/Activity';
import { useBodyInfinityScroll } from '../../hooks/useWindowInfinityScroll';

import { peopleStore } from '../../stores/PeopleStore';
import { filterStore } from '../../stores/FilterStore';

import { IPeople, IInvitePlayer } from '../../interface/People';
import { encodeSchedule } from '../../helpers/search';
import AdvanceOptions from '../Layout/FilterBar/AdvanceOptions';
import { Button } from 'rebass';
import { DEFAULT_LOCATION } from '../../constant';
import { Text } from 'rebass';
import PeopleData from '../Explore/components/PeopleData';
import { Card } from 'rebass';

interface Props {
  activity: IActivity;
  invitedPlayers: IInvitePlayer[];
  inviteSinglePlayerToGame: (playId: string, profile: IPeople) => void;
}

const DiscoverPlayer: React.FC<Props> = observer(
  ({ activity, invitedPlayers, inviteSinglePlayerToGame }) => {
    const { getPeople, people, hasMore, loading } = peopleStore;
    const {
      skillLevelRange,
      scheduleList,
      isShowFullActivities,
      locations,
      setLocations,
    } = filterStore;

    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
      const locations = activity.locations?.length
        ? [
            {
              latitude: Number(activity.locations[0].latitude),
              longitude: Number(activity.locations[0].longitude),
              radius: 20,
              name: activity.locations[0].name,
              placeId: activity.locations[0].placeId,
            },
          ]
        : [DEFAULT_LOCATION];
      setLocations(locations);
    }, [activity, setLocations]);

    const query = useMemo(
      () => ({
        sports: [activity.sport],
        sort: 'RECOMMENDED',
        locations,
        minSkillRating: skillLevelRange[0],
        maxSkillRating: skillLevelRange[1],
        schedule: encodeSchedule(scheduleList),
        showFullGames: isShowFullActivities,
      }),
      [activity, isShowFullActivities, scheduleList, skillLevelRange, locations]
    );

    const body = useRef<HTMLDivElement>();

    useBodyInfinityScroll(body, getPeople, query);

    const locationName = activity.locations?.length
      ? activity.locations[0]?.venueDetail?.venueName ||
        activity.locations[0].name ||
        activity.locations[0].city
      : '';

    return (
      <Flex flexDirection="column" justifyContent="space-between">
        <Flex alignItems="center" justifyContent="space-between" px="3" mb="2">
          <Text variant="body">{locationName}</Text>
          <Button variant="outline.normal" onClick={() => setOpen(true)}>
            Filter
          </Button>
        </Flex>
        <Flex
          flexDirection="column"
          overflow="auto"
          id="body-scrolling"
          px="3"
          height="calc(100vh - 200px)"
          ref={body}
        >
          {people.map((person: IPeople) => {
            const isJoined = activity.accepted.includes(person.id);
            const isInvited = invitedPlayers.some(
              (play) => play.profileId === person.id
            );
            if (isJoined) return null;

            return (
              <Card key={person.id} mb="2" p="2">
                <PeopleData selectedSport={activity.sport} person={person} />
                <Button
                  width="100%"
                  variant="transparent"
                  onClick={() => inviteSinglePlayerToGame(activity.id, person)}
                >
                  {isInvited ? 'Invited' : 'Invite'}
                </Button>
              </Card>
            );
          })}
          {!people.length && !loading && <EmptyList text="people" />}
          {hasMore && loading && <Spinner size={24} intent="primary" />}
        </Flex>
        {isOpen && (
          <AdvanceOptions
            selectedTab="people"
            isOpen={isOpen}
            onClose={() => setOpen(false)}
          />
        )}
      </Flex>
    );
  }
);

export default DiscoverPlayer;
