import {
  greenGradient,
  yellowGradient,
  coralGradient,
  redGradient,
} from '../theme';

export const DEFAULT_LOCATION = {
  radius: 20,
  latitude: 1.352083,
  longitude: 103.819836,
  name: 'Singapore',
  placeId: '-9999',
};

export const DAYS_OF_WEEK: string[] = [
  'MON',
  'TUE',
  'WED',
  'THU',
  'FRI',
  'SAT',
  'SUN',
];

export const OPEN_HOURS_PARSE: { [index: string]: string } = {
  MO: 'Monday',
  TU: 'Tuesday',
  WE: 'Wednesday',
  TH: 'Thursday',
  FR: 'Friday',
  SA: 'Saturday',
  SU: 'Sunday',
  PH: 'Public Holidays',
};

export const PULSE_LEVELS: { [index: string]: string[] } = {
  SPARK: greenGradient,
  FLICKER: greenGradient,
  EMBER: yellowGradient,
  FLAME: yellowGradient,
  WILDFIRE: coralGradient,
  BLAZE: coralGradient,
  FIRESTORM: redGradient,
  INFERNO: redGradient,
};

export const PUBLIC_URL = process.env.PUBLIC_URL;

export const SEARCH_LIMIT = 20;

export const WHATS_NEW_URL =
  'https://www.notion.so/What-s-been-released-recently-339a36c229f64c9e86d7499848d1cc73';

type PaymentOptions =
  | 'cash'
  | 'bankTransfer'
  | 'cheque'
  | 'voucher'
  | 'pointOfSales'
  | 'creditCard'
  | 'clubCredits'
  | 'razorpay';

type IMethodData = {
  [data in PaymentOptions]: { name: string; description: string };
};

export const PAYMENT_METHODS: IMethodData = {
  cash: {
    name: 'Cash Payment',
    description:
      'Simply turn up at the Club to make payment and use the facility',
  },
  bankTransfer: {
    name: 'Bank Transfer',
    description: '',
  },
  cheque: {
    name: 'Cheque',
    description: '',
  },
  voucher: {
    name: 'Voucher',
    description: '',
  },
  pointOfSales: {
    name: 'Point of sales',
    description: '',
  },
  creditCard: {
    name: 'Online Payment',
    description: 'Using your debit/credit card information',
  },
  clubCredits: {
    name: 'Wallet balance',
    description: '',
  },
  razorpay: {
    name: 'Online Payment',
    description: 'Using your debit/credit card information',
  },
};
