import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from 'theme-ui';

import Navigation from '../Layout/Navigation';
import Header from '../Layout/Header';
import FollowList from '../Explore/FollowList';

import InboxBody from './InboxBody';
import GroupInboxDetail from './GroupInboxDetail';

type Props = {};

const ChatPage: React.FC<Props> = () => {
  const [groupId, setGroupId] = useState<string | undefined>();

  const params: { groupId?: string } = useParams();

  useEffect(() => {
    setGroupId(params.groupId);
  }, [params]);

  return (
    <Box
      variant="layout.main"
      sx={{
        gap: '0px',
      }}
    >
      {!groupId && <Header name="Inbox" noSecondaryIcon />}
      <Box variant="layout.leftSide">
        <Navigation selected="inbox" hideOnMobile={!!groupId} />
      </Box>
      {groupId ? <GroupInboxDetail groupId={Number(groupId)} /> : <InboxBody />}
      <Box variant="layout.rightSide">
        <FollowList />
      </Box>
    </Box>
  );
};

export default ChatPage;
