import { IconNames } from '../components/Primitives/Icon/iconTypes';

export const MORE_MENU: {
  icon: IconNames;
  text: string;
  link: string;
  isExtend?: boolean;
}[] = [
  {
    icon: 'edit',
    text: 'Edit Profile',
    link: '/settings/profile',
  },
  {
    icon: 'settings',
    text: 'Settings',
    link: '/settings',
  },
  {
    icon: 'timer',
    text: 'Availability',
    link: '/settings/availability',
  },
  {
    icon: 'pulse',
    text: 'Pulse',
    isExtend: true,
    link: 'https://page.rovo.co/pulse',
  },
  {
    icon: 'search',
    text: 'Find Friends',
    link: '/settings/find-friends',
  },
  {
    icon: 'person-add',
    text: 'Invite Friends',
    link: '/settings/invite-friends',
  },
  // {
  //   icon: 'star',
  //   text: 'Feedbacks',
  //   isExtend: true,
  //   link: 'https://page.rovo.co/p/feedback',
  // },
];

export const MENU_SETTINGS = [
  {
    text: 'Account',
    child: [
      {
        link: '/settings/profile',
        text: 'Profile',
      },
      {
        link: '/settings/social-accounts',
        text: 'Social Accounts',
      },
      {
        link: '/settings/find-friends',
        text: 'Find Friends',
      },
      {
        link: '/settings/invite-friends',
        text: 'Invite Friends',
      },
      {
        text: 'Availability',
        link: '/settings/availability',
      },
      {
        link: '/settings/notifications',
        text: 'Notifications',
      },
      {
        link: '/settings/privacy',
        text: 'Privacy',
      },
    ],
  },
  {
    text: 'App Settings',
    child: [
      {
        link: '/settings/language',
        text: 'Change Language',
      },
    ],
  },
  // {
  //   text: 'Support',
  //   child: [
  //     {
  //       link: 'https://page.rovo.co/p/feedback',
  //       isExtend: true,
  //       text: 'Feedback',
  //     },
  //     {
  //       link: 'mailto:support@rovo.co',
  //       isExtend: true,
  //       text: 'Contact Us',
  //     },
  //   ],
  // },
  {
    text: 'About',
    child: [
      {
        link: 'https://page.rovo.co/p/terms',
        isExtend: true,
        text: 'Terms & Conditions',
      },
      {
        link: 'https://rovo.co/p/privacy',
        isExtend: true,
        text: 'Privacy Policy',
      },
      {
        link: 'https://blog.rovo.co/',
        isExtend: true,
        text: 'Blog',
      },
    ],
  },
];
