import { observable, action, runInAction } from 'mobx';
import { getScores } from '../api/ProfileAPI';
import { SEARCH_LIMIT } from '../constant';
import { IHistory } from '../interface/Score';

class ScoreStore {
  @observable
  scores: IHistory[] = [];

  @observable
  loading: boolean = false;

  @observable
  hasMore: boolean = true;

  params: {
    offset: number;
    limit: number;
  } = { offset: 0, limit: SEARCH_LIMIT };

  @action
  getScores = async (profileId: number, isReset?: boolean) => {
    if (profileId === -1) return;
    if (this.loading) return;
    if (!this.hasMore && !isReset) return;
    if (isReset) this.params.offset = 0;
    this.loading = true;
    let scores: IHistory[] = [];
    try {
      scores = await getScores(profileId, this.params);
    } catch {}
    const dataLength = scores.length;
    runInAction(() => {
      if (dataLength < SEARCH_LIMIT) this.hasMore = false;
      if (!isReset) {
        this.scores = [...this.scores, ...scores];
      } else {
        this.scores = scores;
      }
      this.loading = false;
      this.params.offset =
        dataLength === SEARCH_LIMIT
          ? this.params.offset + dataLength
          : this.params.offset;
    });
  };
}

export default ScoreStore;
