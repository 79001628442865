import React from 'react';
import { Box } from 'theme-ui';
import { useHistory } from 'react-router-dom';

import Navigation from '../Layout/Navigation';
import ExploreBody from './ExploreBody';
import FollowList from './FollowList';
import Header from '../Layout/Header';

const ExplorePage: React.FC = () => {
  const history = useHistory();

  return (
    <Box variant="layout.main">
      <Header
        name="Explore"
        onClickIcon={() => history.push('/search', { isFromExplore: true })}
      />
      <Box variant="layout.leftSide">
        <Navigation selected="explore" />
      </Box>
      <ExploreBody />
      <Box variant="layout.rightSide">
        <FollowList />
      </Box>
    </Box>
  );
};

export default ExplorePage;
