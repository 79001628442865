import { observable, action, runInAction } from 'mobx';
import { IGroup } from '../interface/Group';
import config from '../config';
import { getGroupByUri, joinGroup, leaveGroup } from '../api/GroupAPI';

class GroupDetailStore {
  @observable
  group: IGroup | undefined;

  @observable
  loading: boolean = false;

  @observable
  state: 'JOINING_GROUP' | 'LEAVING_GROUP' | 'DONE' = 'DONE';

  @action
  getGroupDetail = async (groupUri: string) => {
    if (this.loading) return;
    this.loading = true;
    try {
      const group = await getGroupByUri(groupUri);
      runInAction(() => {
        this.group = group;
        this.loading = false;
      });
    } catch (error) {
      this.loading = false;
      if (error?.response?.status === 404)
        window.location.href = `${config.URL}/not-found`;
    }
  };

  @action
  joinGroup = async (groupId: number) => {
    try {
      this.state = 'JOINING_GROUP';
      const group = await joinGroup(groupId);
      runInAction(() => {
        this.group = group;
        this.state = 'DONE';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'DONE';
      });
    }
  };

  @action
  leaveGroup = async (groupId: number) => {
    try {
      this.state = 'LEAVING_GROUP';
      const group = await leaveGroup(groupId);
      runInAction(() => {
        this.group = group;
        this.state = 'DONE';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'DONE';
      });
    }
  };
}

export default GroupDetailStore;
