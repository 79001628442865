import React, { useState } from 'react';
import { Card, Image, Box, Flex } from 'rebass';

import Text from '../UI/Text';
import { Icon, Avatar } from '../Primitives';

import { IComment } from '../../interface/Inbox';

import {
  getLastActive,
  fromServer,
  isSameTimeZone,
} from '../../helpers/datetime';

import { CommentWrapper, FullImageDialog } from './ActivityDetail.styled';
import { HTMLText } from '../Primitives/Text';

export const SystemComment: React.FC<{ m: IComment }> = ({ m }) => {
  return (
    <CommentWrapper
      display="flex"
      ml="36px"
      color="secondaryText"
      py="2"
      my="2"
      justifyContent="space-between"
    >
      <Text
        sx={{
          whiteSpace: 'pre-wrap',
        }}
      >
        <HTMLText text={m.content.text} />
      </Text>
      <Text color="secondaryText" fontSize="12px">
        {getLastActive(m?.updatedAt)}
      </Text>
    </CommentWrapper>
  );
};

export const TextComment: React.FC<{ m: IComment }> = ({ m }) => {
  const [isShowFullImage, setIsShowFullImage] = useState(false);

  return (
    <React.Fragment>
      <Flex width="100%" alignItems="flex-start" mt="2">
        <Avatar
          src={m?.sender?.picture}
          size="sm"
          pulseLevel={m?.sender?.pulseLevel}
        />
        <CommentWrapper flex="1 1 auto" ml="2">
          <Flex mb="1" justifyContent="space-between">
            <Text fontWeight="bold">{m?.sender?.name}</Text>
            <Text color="secondaryText" fontSize="12px">
              {getLastActive(m?.updatedAt)}
            </Text>
          </Flex>
          {m?.type === 'PHOTO' ? (
            <Box width="200px">
              <Image
                width="200px"
                src={m.content?.url}
                sx={{
                  borderRadius: '8px',
                  cursor: 'pointer',
                }}
                onClick={() => setIsShowFullImage(true)}
              />
            </Box>
          ) : (
            <Text
              sx={{
                whiteSpace: 'pre-wrap',
              }}
              mb="2"
            >
              <HTMLText text={m.content.text} />
            </Text>
          )}
        </CommentWrapper>
      </Flex>
      {isShowFullImage && (
        <FullImageDialog
          isOpen={isShowFullImage}
          onClose={() => setIsShowFullImage(false)}
        >
          <Image src={m.content?.url} width="100vw" />
        </FullImageDialog>
      )}
    </React.Fragment>
  );
};

export const PollComment: React.FC<{
  m: IComment;
  isDisplayPoll?: boolean;
}> = ({ m, isDisplayPoll }) => {
  return (
    <Flex mt="2" flexDirection="column">
      <CommentWrapper flex="1 1 auto" ml="2">
        <Flex mb="1" justifyContent="space-between">
          <Text fontWeight="bold">{m?.sender?.name}</Text>
          <Text color="secondaryText" fontSize="12px">
            {getLastActive(m?.updatedAt)}
          </Text>
        </Flex>
        <Text
          sx={{
            whiteSpace: 'pre-wrap',
          }}
          mb="2"
        >
          <HTMLText text={m.content.text} />
        </Text>
        {m?.content?.poll && isDisplayPoll ? (
          <Card display="flex" flexDirection="column" alignItems="center">
            <Flex mb="3">
              <Icon icon="timer" size="sm" stroke="primary" />
              <Text>Vote time & location</Text>
            </Flex>
            {m.content.poll.options.map((option) => (
              <Flex
                bg="border"
                p="2"
                width="100%"
                flexDirection="column"
                sx={{
                  borderRadius: '8px;',
                  '&:not(:last-child)': {
                    marginBottom: '8px;',
                  },
                }}
              >
                <Text>
                  {fromServer(option.start, option.timezone, 'EEE MMM dd')}
                  <Text as="span" fontWeight="bold" textTransform="lowercase">
                    {', '}
                    {fromServer(option.start, option.timezone, 'h:mm a')} -{' '}
                    {fromServer(option.end, option.timezone, 'h:mm a')}
                  </Text>
                </Text>
                {!isSameTimeZone(option.timezone) && (
                  <Text>{`${option.timezone} time`}</Text>
                )}
                <Text>
                  {option.location.name ||
                    option.location.city ||
                    option.location.locality}
                </Text>
              </Flex>
            ))}
          </Card>
        ) : null}
      </CommentWrapper>
    </Flex>
  );
};
