import React, { useEffect } from 'react';

import NotificationsPage from '../components/Notification';
import { trackPageView } from '../services/amplitudeService';

const Notifications: React.FC = () => {
  useEffect(() => {
    trackPageView('Notifications');
  });

  return <NotificationsPage />;
};

export default Notifications;
