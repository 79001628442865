import React from 'react';
import { Flex, Box } from 'rebass';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';

import { Icon } from '../Primitives';
import Text from '../UI/Text';
import Avatar from '../UI/Avatar';

import { Spinner } from '@blueprintjs/core';
import { Body, BackBtn, PeopleWrapper, InviteFriend } from './Profile.styled';
import { IFollowing } from '../../interface/People';
import { followingStore } from '../../stores/FollowingStore';
import useWindowInfinityScroll from '../../hooks/useWindowInfinityScroll';
import EmptyList from '../UI/EmptyList';

const Following: React.FC<{
  profileId: number;
  isSelf: boolean;
  username: string;
}> = observer(({ profileId, isSelf, username }) => {
  const { followings, getFollowings, loading, hasMore } = followingStore;
  const history = useHistory();

  useWindowInfinityScroll(getFollowings, profileId);

  const toProfile = (username: string) => {
    history.push(`/${username}`);
  };

  return (
    <Body py={[2, 3]} flexDirection="column">
      <Flex px="3" pb={[2, 3]} alignItems="center" bg="white">
        <BackBtn
          icon="chevron-left"
          fill="primaryText"
          onClick={() => history.push(`/${username}`)}
        />
        <Text ml="3" fontSize="18px" color="primaryText">
          Following
        </Text>
      </Flex>
      <Box variant="line" />
      {isSelf && (
        <InviteFriend
          px="3"
          py="2"
          alignItems="center"
          justifyContent="space-between"
          onClick={() => history.push('/settings/find-friends')}
        >
          <Text color="primary" fontWeight="bold">
            Invite your friend
          </Text>
          <Icon icon="chevron-right" fill="primaryText" />
        </InviteFriend>
      )}
      <Box variant="line" />
      {!!followings.length &&
        followings.map((p: IFollowing) => {
          const { following } = p;
          const {
            id,
            firstName,
            lastName,
            picture,
            pulseLevel,
            username,
          } = following;
          if (id === profileId) return null;
          return (
            <PeopleWrapper
              p="3"
              alignItems="center"
              key={id}
              bg="white"
              onClick={() => toProfile(username)}
            >
              <Avatar imageLink={picture} pulseLevel={pulseLevel} />
              <Text ml="2" fontWeight="bold" color="primaryText">
                {firstName} {lastName}
              </Text>
            </PeopleWrapper>
          );
        })}
      {!followings.length && !loading && <EmptyList text="following" />}
      {hasMore && loading && <Spinner size={30} intent="primary" />}
    </Body>
  );
});

export default Following;
