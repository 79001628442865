import { Spinner } from '@blueprintjs/core';
import cogoToast from 'cogo-toast';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { Box, Card, Flex, Image, Text } from 'rebass';
import {
  checkOrderTransactionStatus,
  dryrunFacilityBooking,
  makeFacilityBooking,
} from '../../../api/VenueAPI';
import RazorpayLogo from '../../../assets/razorpay.png';
import { PAYMENT_METHODS } from '../../../constant';
import { centsToDecimal } from '../../../helpers/currency';
import LocalStorageHelper from '../../../helpers/localStorage';
import { useStores } from '../../../hooks/useStore';
import { IOrder, IOrderLineItem } from '../../../interface/Order';
import { IPartnerProfile } from '../../../interface/Venue';
import { trackEvent } from '../../../services/amplitudeService';
import { userStore } from '../../../stores/UserStore';
import PhoneVerification from '../../Authentication/PhoneVerificationDialog';
import { CreditCard } from '../../Payment';
import checkoutWithRazorpay from '../../Payment/Razorpay';
import { Input, Label, Textarea } from '../../UI/Input';
import PaymentCard from '../components/PaymentCard';
import PaymentSummary from '../components/PaymentSummary';
import { ISelectedSlot } from '../FacilityBooking';
import {
  BackBtn,
  ConfirmBtn,
  StickyBottomBar,
} from '../FacilityBooking.styled';
import { Grid } from 'theme-ui';

interface Props {
  setStep: Function;
  setOrderId: Function;
  setReadableId: Function;
  selectedSlots: ISelectedSlot[];
  partnerProfile: IPartnerProfile;
  onClose: () => void;
}

const MakePayment: React.FC<Props> = observer(
  ({
    setStep,
    selectedSlots,
    setOrderId,
    setReadableId,
    partnerProfile,
    onClose,
  }) => {
    const { venueStore } = useStores();
    const { account, updateProfile } = userStore;
    const {
      selectedVenue,
      currentCustomerData,
      getCustomerForCurrentProfileByPartnerId,
    } = venueStore;

    const [selectedFacilities, setSelectedFacilities] = useState<string[]>([]);
    const [sortSlots, setSortSlots] = useState<ISelectedSlot[]>([]);
    const [note, setNote] = useState('');
    const [email, setEmail] = useState(account.email || '');
    const [firstName, setFirstName] = useState(
      account?.profile?.firstName || ''
    );
    const [lastName, setLastName] = useState(account?.profile?.lastName || '');
    const [coupon, setCoupon] = useState('');
    const [couponDetail, setCouponDetail] = useState<IOrderLineItem>();
    const [paymentMethod, setPaymentMethod] = useState('');
    const [total, setTotal] = useState<number>(0);
    const [fee, setFee] = useState(0);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDryrun, setIsDryrun] = useState(false);
    const [isDryrunErr, setIsDryrunErr] = useState(false);
    const [isReqUserInfo, setIsReqUserInfo] = useState(false);

    const [isOpenPhoneVerification, setOpenPhoneVerification] = useState(false);

    const ref = useRef<HTMLFormElement>();

    useEffect(() => {
      if (!currentCustomerData) {
        getCustomerForCurrentProfileByPartnerId();
      }

      if (
        account.id !== -1 &&
        partnerProfile?.partner.customerContactRequirements.includes(
          'PHONE_VERIFIED'
        ) &&
        !account.phoneVerified
      ) {
        return setOpenPhoneVerification(true);
      }
    }, [
      currentCustomerData,
      getCustomerForCurrentProfileByPartnerId,
      partnerProfile,
      account,
    ]);

    useEffect(() => {
      let total = 0;
      selectedSlots.forEach((slot) => {
        if (slot.price?.price) {
          total = total + slot.price?.price;
        }
      });
      setTotal(0);
    }, [selectedSlots]);

    useEffect(() => {
      if (
        partnerProfile?.partner.paymentMethodsForCustomers?.length &&
        !paymentMethod
      ) {
        setPaymentMethod(partnerProfile.partner.paymentMethodsForCustomers[0]);
      }
    }, [paymentMethod, partnerProfile]);

    useEffect(() => {
      const sortedSelectedSlots = [...selectedSlots];
      const facilitiesName: string[] = [];
      selectedSlots.forEach((slot) => {
        if (!facilitiesName.includes(slot.name)) {
          facilitiesName.push(slot.name);
        }
      });
      sortedSelectedSlots.sort((a, b) => {
        const startA = DateTime.fromFormat(
          `${a.date} ${a.start}`,
          'yyyy-MM-dd h:mm'
        );
        const startB = DateTime.fromFormat(
          `${b.date} ${b.start}`,
          'yyyy-MM-dd h:mm'
        );
        return startA === startB ? 0 : startA > startB ? 1 : -1;
      });
      setSelectedFacilities(facilitiesName.sort());
      setSortSlots(sortedSelectedSlots);
    }, [selectedSlots]);

    const paymentMethods =
      partnerProfile?.partner.paymentMethodsForCustomers || [];

    const generateBookings = (selectedSlots: ISelectedSlot[]) => {
      return selectedSlots.map((slot) => {
        const startTime = DateTime.fromFormat(
          `${slot.date} ${slot.start}`,
          'yyyy-MM-dd h:mm',
          { zone: selectedVenue?.timezone }
        )
          .toUTC()
          .toISO();
        const endTime = DateTime.fromFormat(
          `${slot.date} ${slot.end}`,
          'yyyy-MM-dd h:mm',
          { zone: selectedVenue?.timezone }
        )
          .toUTC()
          .toISO();
        return {
          facilityId: slot.facilityId,
          startTime,
          endTime,
        };
      });
    };

    const dryrunBooking = async () => {
      const bookings = generateBookings(selectedSlots);

      const data = {
        customer: {
          id: currentCustomerData?.id,
          email: email,
          firstName: firstName || account.profile.firstName || '',
          lastName: lastName || account.profile.lastName || '',
        },
        bookings,
        paymentMethod,
        customerNote: note,
        couponCode: coupon,
        customerId: currentCustomerData?.id,
      };

      try {
        setIsDryrun(true);
        setIsDryrunErr(false);
        const response: IOrder = await dryrunFacilityBooking(
          partnerProfile?.partnerId,
          data
        );
        setTotal(centsToDecimal(response.totalAmount, response.currency));
        setFee(response.processingFee.amount);
        if (coupon) {
          const couponDetail = response.orderLineItems.find(
            (item) => item.targetType === 'coupon'
          );
          setCouponDetail(couponDetail);
        }
      } catch (error) {
        const message =
          error?.response?.data?.msg || error?.response?.data?.message;
        if (message) {
          cogoToast.error(message);
        }
        setCoupon('');
        setIsDryrunErr(true);
      } finally {
        setIsDryrun(false);
      }
    };

    const applyCoupon = () => {
      dryrunBooking();
    };

    useEffect(() => {
      if (paymentMethod) {
        dryrunBooking();
      }
      // eslint-disable-next-line
    }, [paymentMethod]);

    const onSuccessPayment = (data: any) => {
      trackEvent('processBookingFacilitySuccess', data);
      LocalStorageHelper.remove('isBooking');
      setStep(3);
    };

    const onFailedPayment = (message: string) => {
      cogoToast.error(message || 'Booking Fail');
      LocalStorageHelper.remove('isBooking');
      setIsSubmitting(false);
    };

    const checkStatus = async (orderId: string, orderTransactionId: string) => {
      let timeLeft = 3;

      const sleep = (ms: number) =>
        new Promise((resolve) => setTimeout(resolve, ms));

      const check = async (): Promise<unknown> => {
        try {
          const res: {
            processingStatus: string;
          } = await checkOrderTransactionStatus(orderId, orderTransactionId);
          if (res.processingStatus !== 'COMMITTED') {
            throw new Error(`Processing status is ${res.processingStatus}`);
          }
          return res;
        } catch (error) {
          if (timeLeft <= 1) throw error;
          timeLeft = timeLeft - 1;
          await sleep(2000);
          return check();
        }
      };

      return await check();
    };

    const makeBooking = async (token?: string) => {
      const bookings = generateBookings(selectedSlots);

      const data = {
        customer: {
          id: currentCustomerData?.id,
          email: email,
          firstName: firstName,
          lastName: lastName,
        },
        bookings,
        paymentMethod,
        customerNote: note,
        couponCode: coupon,
        customerId: currentCustomerData?.id,
      };

      const creditInfo = {
        creditToken: token || '',
      };

      trackEvent('processBookingFacility', data);

      try {
        const response: IOrder = await makeFacilityBooking(
          partnerProfile.partnerId,
          data,
          creditInfo
        );

        // Update user profile for next time
        if (!account.profile.firstName) {
          updateProfile({ firstName });
        }
        if (!account.profile.lastName) {
          updateProfile({ lastName });
        }

        setOrderId(response.id);
        setReadableId(response.readableId.readableId);

        if (paymentMethod === 'razorpay') {
          try {
            checkoutWithRazorpay(
              response,
              async () => {
                const pendingOrderTransaction = response.orderTransactions.find(
                  (t) => t.status !== 'SUCCESS'
                );
                if (pendingOrderTransaction) {
                  await checkStatus(response.id, pendingOrderTransaction.id);
                  onSuccessPayment(data);
                }
              },
              () => {
                onFailedPayment(
                  'Booking failed. Please complete payment process to finish the booking'
                );
              }
            );
          } catch (error) {
            onFailedPayment(error?.response?.data?.msg || 'Booking failed');
          }
          return;
        }

        if (response?.status === 'pending' && paymentMethod === 'creditCard') {
          const pendingOrderTransaction = response.orderTransactions.find(
            (o) => o.status !== 'SUCCESS' && o.paymentMethod === 'creditCard'
          );
          const properties = pendingOrderTransaction
            ? pendingOrderTransaction.rawData.properties
            : [];
          const clientSecret = properties.length
            ? properties.find((p) => p.key === 'client_secret')?.value
            : '';
          if (clientSecret && pendingOrderTransaction)
            return {
              clientSecret,
              orderId: pendingOrderTransaction.orderId,
              id: pendingOrderTransaction.id,
            };
          return onFailedPayment('Booking failed');
        }
        onSuccessPayment(data);
        return null;
      } catch (error) {
        trackEvent('processBookingFacilityFailed', error);
        onFailedPayment(error?.response?.data?.msg || 'Booking failed');
      }
    };

    const userInfoRef = useRef<HTMLDivElement>();

    const onCreateBooking = () => {
      if (!email || !firstName || !lastName) {
        userInfoRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
        return setIsReqUserInfo(true);
      }

      const isBooking = LocalStorageHelper.get('isBooking');
      if (isBooking) {
        return cogoToast.error(
          'Another booking is process. Please wait that booking to be complete to do another booking'
        );
      }
      LocalStorageHelper.set('isBooking', true);
      if (isSubmitting) return;
      setIsSubmitting(true);
      if (ref && ref.current && paymentMethod === 'creditCard') {
        return ref.current?.dispatchEvent(new Event('submit'));
      }
      makeBooking();
    };

    const renderPaymentMethods = () => {
      const walletAmount = currentCustomerData?.clubCreditBalance?.length
        ? currentCustomerData?.clubCreditBalance[0]?.amount
        : 0;
      const currency = currentCustomerData?.clubCreditBalance?.length
        ? currentCustomerData?.clubCreditBalance[0]?.currency
        : '';

      const isDisabledClubCredit =
        centsToDecimal(walletAmount, currency) < total;

      if (isDisabledClubCredit && paymentMethod === 'clubCredits') {
        const newMethod =
          paymentMethods.find((method) => method !== 'clubCredits') || '';
        setPaymentMethod(newMethod);
      }

      return paymentMethods.map((method) => {
        const isSelected = method === paymentMethod;
        switch (method) {
          case 'cash':
          case 'bankTransfer':
          case 'cheque':
          case 'voucher':
          case 'pointOfSales':
            return (
              <PaymentCard
                key={method}
                onClick={() => setPaymentMethod(method)}
                icon="dollar"
                isSelected={isSelected}
                name={PAYMENT_METHODS[method].name}
                description={PAYMENT_METHODS[method].description}
              />
            );
          case 'creditCard':
            return (
              <PaymentCard
                key={method}
                onClick={() => setPaymentMethod(method)}
                icon="credit-card"
                isSelected={isSelected}
                name={PAYMENT_METHODS[method].name}
                description={PAYMENT_METHODS[method].description}
              >
                {isSelected && (
                  <CreditCard
                    ref={ref}
                    onMakePayment={makeBooking}
                    onSuccess={onSuccessPayment}
                    onFailed={onFailedPayment}
                  />
                )}
              </PaymentCard>
            );
          case 'razorpay':
            return (
              <PaymentCard
                key={method}
                onClick={() => setPaymentMethod(method)}
                icon="credit-card"
                isSelected={isSelected}
                name={PAYMENT_METHODS[method].name}
                description={PAYMENT_METHODS[method].description}
              >
                <Flex alignItems="center" mt="2">
                  <Text variant="caption.large" mr="1" color="secondaryText">
                    Transactions are powered by
                  </Text>
                  <Image src={RazorpayLogo} />
                </Flex>
              </PaymentCard>
            );
          case 'clubCredits':
            return (
              <PaymentCard
                key={method}
                onClick={() => setPaymentMethod(method)}
                icon="wallet"
                isSelected={isSelected}
                name={`${PAYMENT_METHODS[method].name}: 
              ${centsToDecimal(walletAmount, currency)} ${currency}`}
                description={PAYMENT_METHODS[method].description}
                isDisabled={centsToDecimal(walletAmount, currency) < total}
              >
                <Text variant="body">{partnerProfile?.brandName} credits</Text>
              </PaymentCard>
            );
          default:
            return null;
        }
      });
    };

    return (
      <>
        <Flex mx={[1, -1]} mt="3" flexWrap="wrap">
          <Box ref={userInfoRef} width={[1, 0.5]} px={[0, 1]}>
            <Text fontWeight="bold" mb="2" mx={[3, 2]}>
              BOOKING INFORMATION
            </Text>
            <Card mb="3">
              <Label>Email (for sending payment confirmation)</Label>
              <Input
                isError={isReqUserInfo && !email}
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.target.value)
                }
              />
              <Grid>
                <Box>
                  <Label>First name</Label>
                  <Input
                    isError={isReqUserInfo && !firstName}
                    value={firstName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFirstName(e.target.value)
                    }
                  />
                </Box>
                <Box>
                  <Label>Last name</Label>
                  <Input
                    isError={isReqUserInfo && !lastName}
                    value={lastName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setLastName(e.target.value)
                    }
                  />
                </Box>
              </Grid>
            </Card>
            <Text fontWeight="bold" mb="2" mx={2}>
              PAYMENT METHOD
            </Text>
            <Card mb="3">{renderPaymentMethods()}</Card>
            <Card mb="3">
              <Label>Add a note (optional)</Label>
              <Textarea
                placeholder="Tell us your social request. We may not be able to accommodate all requests."
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setNote(e.target.value)
                }
              />
            </Card>
          </Box>
          <Box width={[1, 0.5]} px={[0, 1]}>
            <Text fontWeight="bold" mb="2" mx={2}>
              PAYMENT SUMMARY
            </Text>
            <PaymentSummary
              venueName={selectedVenue?.venueName}
              selectedFacilities={selectedFacilities}
              setStep={setStep}
              sortSlots={sortSlots}
              currency={partnerProfile.partner.defaultCurrencyId}
              fee={fee}
              total={total}
              coupon={coupon}
              couponDetail={couponDetail}
              setCoupon={setCoupon}
              setCouponDetail={setCouponDetail}
              applyCoupon={applyCoupon}
              isDryrun={isDryrun}
            />
          </Box>
        </Flex>
        {isSubmitting && (
          <Flex
            sx={{
              position: 'fixed',
              width: '100%',
              height: '100%',
              top: '0px',
              left: '0px',
              bg: 'primary',
              zIndex: '9999',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Text color="white" variant="lb.lg" mb="3">
              Processing payment...
            </Text>
            <Text color="white">Please do not close browser</Text>
          </Flex>
        )}
        <StickyBottomBar>
          <BackBtn
            variant="outline.normal"
            color="primaryText"
            onClick={() => setStep(1)}
          >
            Back
          </BackBtn>
          {isSubmitting ? (
            <Spinner intent="primary" size={20} />
          ) : (
            <ConfirmBtn
              id="booking-payment-button"
              disabled={!paymentMethod || !email || isDryrunErr || isSubmitting}
              onClick={onCreateBooking}
            >
              Confirm & pay {total} {partnerProfile?.partner.defaultCurrencyId}
            </ConfirmBtn>
          )}
        </StickyBottomBar>
        {isOpenPhoneVerification && (
          <PhoneVerification
            isOpen={isOpenPhoneVerification}
            onClose={() => setOpenPhoneVerification(false)}
            onCancel={onClose}
          />
        )}
      </>
    );
  }
);

export default MakePayment;
