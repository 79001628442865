import React from 'react';
import { Flex } from 'rebass';

import PeopleInbox from './PeopleInbox';
import ActivityInbox from './ActivityInbox';
import EmptyList from '../../UI/EmptyList';
import { Spinner } from '@blueprintjs/core';

interface IPeopleAndActivity {
  type: 'GROUP' | 'PLAY';
  rawObject: any;
  date: string;
}

const PeopleAndActivities: React.FC<{
  profileId: number;
  loading: boolean;
  peopleAndActivities: IPeopleAndActivity[];
  hasMore: boolean;
}> = ({ profileId, peopleAndActivities, loading, hasMore }) => {
  return (
    <Flex flexDirection="column">
      {!!peopleAndActivities?.length &&
        peopleAndActivities.map(item => {
          if (item.type === 'PLAY')
            return (
              <ActivityInbox
                key={item.date}
                activity={item.rawObject}
                profileId={profileId}
              />
            );
          if (item.type === 'GROUP')
            return <PeopleInbox key={item.date} groupInbox={item.rawObject} />;
          return null;
        })}
      {!peopleAndActivities?.length && !loading && (
        <EmptyList text="chat or activities" />
      )}
      {hasMore && loading && <Spinner size={24} intent="primary" />}
    </Flex>
  );
};

export default PeopleAndActivities;
