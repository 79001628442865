import React, { useState } from 'react';
import { Flex } from 'rebass';

import { Icon } from '../Primitives';
import Text from '../UI/Text';

import { centsToDecimal } from '../../helpers/currency';

import { PrimaryDialog } from '../UI/Dialog';

import styled from '../../theme/styled';

const PricePoint = styled(Flex)`
  padding: 8px;
  background-color: ${(p) => p.selected && p.theme.colors.primaryHover};
  border-radius: 4px;
`;

interface Props {
  priceCents: {
    currency: string;
    price: number;
  };
  pricePoints?: {
    maxPlayer: number;
    minPlayer: number;
    priceCents: {
      currency: string;
      price: number;
    };
  }[];
  numOfPlayer: number;
}

const DynamicPricing: React.FC<Props> = ({
  priceCents,
  pricePoints = [],
  numOfPlayer,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const isDynamic = !!pricePoints;

  const { currency } = priceCents;

  const selectedPrice = pricePoints
    ? pricePoints.find(
        (price) =>
          price.maxPlayer >= numOfPlayer && price.minPlayer <= numOfPlayer
      )
    : null;

  return (
    <Flex alignItems="center" mb="3" color="primaryText">
      <Icon icon="dollar" size="md" />
      <Flex flexDirection="column" ml="2">
        {selectedPrice ? (
          <Text>
            <strong>
              {centsToDecimal(selectedPrice.priceCents.price, currency)}{' '}
              {priceCents.currency}
            </strong>
            /person (for {selectedPrice.minPlayer - 1} + players going)
          </Text>
        ) : (
          <Text>
            <strong>
              {centsToDecimal(priceCents.price, currency)} {priceCents.currency}
            </strong>
            /person
          </Text>
        )}
        {isDynamic && (
          <Text
            fontSize="12px"
            color="primary"
            sx={{ cursor: 'pointer' }}
            onClick={() => setIsOpen(true)}
          >
            Dynamic Price
          </Text>
        )}
      </Flex>
      {isOpen && (
        <PrimaryDialog
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          title="Dynamic Price"
        >
          <Flex flexDirection="column" p="3" bg="white" color="primaryText">
            <Flex justifyContent="space-between">
              <Text>
                Price/person (<strong>{currency}</strong>)
              </Text>
              <Text>No. of players going</Text>
            </Flex>
            {pricePoints?.map((p, index) => (
              <PricePoint
                key={index}
                justifyContent="space-between"
                fontSize="20px"
                selected={
                  p.minPlayer >= numOfPlayer && p.maxPlayer <= numOfPlayer
                }
              >
                <Text>{centsToDecimal(p.priceCents.price, currency)}</Text>
                <Text>
                  {p.minPlayer} - {p.maxPlayer}
                </Text>
              </PricePoint>
            ))}
          </Flex>
        </PrimaryDialog>
      )}
    </Flex>
  );
};

export default DynamicPricing;
