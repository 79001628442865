import {
  getPartnerByUri,
  getPartnerProfile,
  getVenuesByPartnerId,
} from './../api/VenueAPI';
import { IPartnerProfile, IVenue } from './../interface/Venue';
import { observable, runInAction, action } from 'mobx';
class PartnerStore {
  @observable
  partnerProfile: IPartnerProfile | null = null;

  @observable
  venuesByPartner: IVenue[] = [];

  @observable
  state: 'INITIAL' | 'LOADING' | 'DONE' | 'ERROR' | 'NOT_FOUND' = 'INITIAL';

  @action
  getPartnerProfileByUri = async (uri: string) => {
    if (this.state === 'LOADING') return;
    this.state = 'LOADING';
    try {
      const profile = await getPartnerByUri(uri);
      if (profile) {
        await this.getPartnerProfile(profile?.partnerId);
        await this.getVenuesByPartnerId(profile?.partnerId);
      }
      runInAction(() => {
        this.state = profile ? 'DONE' : 'NOT_FOUND';
      });
    } catch (error) {}
  };

  @action
  getPartnerProfile = async (partnerId: string) => {
    try {
      const partnerProfile = await getPartnerProfile(partnerId);
      runInAction(() => {
        this.partnerProfile = partnerProfile;
      });
    } catch (error) {}
  };

  @action
  getVenuesByPartnerId = async (partnerId: string) => {
    try {
      const venues = await getVenuesByPartnerId(partnerId);
      runInAction(() => {
        this.venuesByPartner = venues;
      });
    } catch (error) {}
  };

  @action
  resetState = () => {
    this.state = 'INITIAL';
  };
}

const partnerStore = new PartnerStore();

export default partnerStore;
