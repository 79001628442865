import React, { useEffect } from 'react';

import { trackPageView } from '../services/amplitudeService';
import { UnAuthHeaderOnlyLogo } from '../components/Layout/UnAuth/Header';
import Footer from '../components/Layout/UnAuth/Footer';
import { Button, Text } from '../components/Primitives';
import { Flex } from 'theme-ui';

const Error = () => {
  useEffect(() => {
    trackPageView('Error');
  }, []);

  return (
    <Flex
      sx={{
        minHeight: '100vh',
        flexDirection: 'column',
      }}
    >
      <UnAuthHeaderOnlyLogo />
      <Flex
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <Text variant="hd.xs">404 | Page not found</Text>&nbsp;
        <Button
          mt="3"
          variant="outline.primary"
          size="lg"
          onClick={() => (window.location.href = '/home')}
        >
          Back to home
        </Button>
      </Flex>
      <Footer />
    </Flex>
  );
};

export default Error;
