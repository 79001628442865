import { decorate, observable, action, runInAction } from 'mobx';
import { DateTime } from 'luxon';

import {
  getComments,
  sendTextComment,
  sendImageComment,
  markAsReadDiscussion,
} from '../api/InboxAPI';
import { getActivityData, updateProfilePlayStatus } from '../api/ActivityAPI';

import { SEARCH_LIMIT } from '../constant';

import { userStore } from './UserStore';

import { IComment } from '../interface/Inbox';
import { IActivity } from '../interface/Activity';

class ActivityDetailStore {
  comments: IComment[] = [];

  activity: IActivity | null = null;

  loading: boolean = false;

  loadingDetail: boolean = false;

  params: { before?: number | null; limit: number } = { limit: SEARCH_LIMIT };

  hasMore: boolean = true;

  lastMessageWithPoll: IComment | undefined;

  getActivityDetail = async (activityName: string) => {
    this.loadingDetail = true;
    try {
      const activity = await getActivityData(activityName);
      runInAction(() => {
        this.activity = activity;
      });
    } catch (error) {
    } finally {
      runInAction(() => {
        this.loadingDetail = false;
      });
    }
  };

  getComments = async (activityId: string, isReset?: boolean) => {
    if (isReset) {
      this.params = { limit: SEARCH_LIMIT };
      this.hasMore = true;
      this.comments = [];
    }
    if (this.loading || !this.hasMore) return;
    this.loading = true;
    try {
      const data = await getComments('PLAY', activityId, this.params);
      const comments: IComment[] = data?.comments || [];
      const hasPoll: boolean = data?.hasPoll;
      runInAction(() => {
        this.hasMore = comments.length !== 20 ? false : true;
        if (comments.length === 0) {
          return (this.params.before = null);
        }
        this.comments = [...this.comments, ...comments];
        const lastMessageWithPoll = [...this.comments, ...comments].find(
          (comment) => comment?.content?.poll
        );
        this.lastMessageWithPoll = hasPoll ? lastMessageWithPoll : undefined;
        this.params.before = comments[0]?.position;
        if (this.comments[0]?.position && userStore.account.id > -1) {
          this.markAsRead(activityId, this.comments[0].position);
        }
      });
    } catch (error) {
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  sendTextComment = async (activityId: string, text: string) => {
    const comment: IComment = {
      position: this.comments[0]?.position ? this.comments[0].position + 1 : 1,
      id: 'pre-message',
      content: {
        text,
      },
      type: 'TEXT',
      senderId: userStore.account.profileId,
      updatedAt: DateTime.local().toISO(),
      createdAt: DateTime.local().toISO(),
      deleteAt: null,
      sender: userStore.account.profile,
    };
    this.comments = [comment, ...this.comments];
    try {
      const comment = await sendTextComment('PLAY', activityId, text);
      runInAction(() => {
        this.comments[0] = comment;
        if (comment?.position) {
          this.markAsRead(activityId, comment.position);
        }
      });
    } catch (error) {
      runInAction(() => {
        this.comments.shift();
      });
    }
  };

  sendImageComment = async (activityId: string, image: File) => {
    try {
      const comment = await sendImageComment('PLAY', activityId, image);
      runInAction(() => {
        this.comments = [comment, ...this.comments];
        if (comment?.position) {
          this.markAsRead(activityId, comment.position);
        }
      });
    } catch (error) {}
  };

  markAsRead = async (activityId: string, position: number) => {
    try {
      await markAsReadDiscussion(activityId, position);
    } catch (error) {}
  };

  updateActivity = async (gameId: string, status: 'DECLINED' | 'ACCEPTED') => {
    try {
      const activity = await updateProfilePlayStatus(gameId, status);
      runInAction(() => {
        this.activity = activity;
      });
    } catch (error) {
      throw error;
    }
  };

  updateComments = (activity: IActivity, newComment: IComment) => {
    try {
      newComment.position = this.comments[0]?.position
        ? this.comments[0].position + 1
        : 1;
      this.comments = [newComment, ...this.comments];
      runInAction(() => {
        this.comments[0] = newComment;
        if (newComment?.position) {
          this.markAsRead(activity.id, newComment.position);
        }
        if (activity && newComment.type === 'SYSTEM') {
          this.activity = activity;
        }
      });
    } catch (error) {
      throw error;
    }
  };
}

decorate(ActivityDetailStore, {
  comments: observable,
  activity: observable,
  loading: observable,
  loadingDetail: observable,
  lastMessageWithPoll: observable,
  getComments: action,
  getActivityDetail: action,
  sendTextComment: action,
  sendImageComment: action,
  markAsRead: action,
  updateActivity: action,
  updateComments: action,
});

export const activityDetailStore = new ActivityDetailStore();
