import React, { useEffect } from 'react';

import styled from '../theme/styled';
import { trackPageViewError } from '../services/amplitudeService';

const MainBody = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
`;

const ServerIssue = () => {
  useEffect(() => {
    trackPageViewError('Server issue', 502, 'Server issue');
  }, []);

  return (
    <div>
      <MainBody>
        Our server in under construction. Please comeback after 10 minutes.
      </MainBody>
    </div>
  );
};

export default ServerIssue;
