import React from 'react';
import { Flex, Box, Grid } from 'theme-ui';

import { Icon, Text } from '../../Primitives';

import ListImage from '../../Timeline/Card/ListImage';

import IScoreObject from '../../../interface/Score';
import { fromServer } from '../../../helpers/datetime';

const ScoreBody: React.FC<{
  match: IScoreObject;
  start: string;
  end: string;
}> = ({ match, start, end }) => {
  const {
    players,
    timezone,
    score: { team1, team2 },
    status,
  } = match;

  const displayStart = fromServer(start, timezone, 'cccc MMM dd');
  const timeStart = fromServer(start, timezone, 'h:mm a');
  const timeEnd = end ? fromServer(end, timezone, 'h:mm a') : '';

  const team1Member = players.filter((player) =>
    team1.profileIds.includes(player.id)
  );

  const team2Member = players.filter((player) =>
    team2.profileIds.includes(player.id)
  );

  return (
    <>
      <Text color="primaryText" mb="3">
        {displayStart}, {timeStart}
        {timeEnd ? ` - ${timeEnd}` : ''}
      </Text>
      <Grid
        sx={{
          gridTemplateColumns: '1fr auto 1fr',
        }}
      >
        <ListImage members={team1Member} />
        {team1.isWinner ? (
          <Flex
            sx={{
              color: 'white',
              bg: 'primary',
              width: '20px',
              height: '20px',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '20px',
              alignSelf: 'center',
            }}
          >
            W
          </Flex>
        ) : (
          <Box
            sx={{
              width: '20px',
              height: '20px',
            }}
          />
        )}
        <Flex
          sx={{
            alignSelf: 'center',
            justifyContent: 'space-around',
          }}
        >
          {team1.setGames.map((game, index) => (
            <Text
              variant="lb.lg"
              color={team1.isWinner ? 'primaryText' : 'secondaryText'}
              key={game + index}
            >
              {game}
            </Text>
          ))}
        </Flex>
      </Grid>
      <Grid
        sx={{
          gridTemplateColumns: '1fr auto 1fr',
          textAlign: 'center',
          position: 'relative',
        }}
      >
        <Text p="2" bg="white" variant="lb.md">
          VS
        </Text>
      </Grid>
      <Grid
        sx={{
          gridTemplateColumns: '1fr auto 1fr',
        }}
      >
        <ListImage members={team2Member} />
        {team2.isWinner ? (
          <Flex
            sx={{
              color: 'white',
              bg: 'primary',
              width: '20px',
              height: '20px',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '20px',
              alignSelf: 'center',
            }}
          >
            W
          </Flex>
        ) : (
          <Box
            sx={{
              width: '20px',
              height: '20px',
            }}
          />
        )}
        <Flex
          sx={{
            alignSelf: 'center',
            justifyContent: 'space-around',
          }}
        >
          {team2.setGames.map((game, index) => (
            <Text
              variant="lb.lg"
              color={team2.isWinner ? 'primaryText' : 'secondaryText'}
              key={game + index}
            >
              {game}
            </Text>
          ))}
        </Flex>
      </Grid>
      {status === 'CONFIRMED' && (
        <>
          <Box variant="line" my="3" />
          <Flex
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Icon icon="ok" stroke="primaryGreen" size="md" />
            <Text ml="2" color="primaryGreen" variant="lb.md">
              Score verified
            </Text>
          </Flex>
        </>
      )}
    </>
  );
};

export default ScoreBody;
