import React from 'react';
import { Box, Input, BoxProps } from 'theme-ui';

import { ReactComponent as CheckboxOn } from '../../assets/icons/check/on.svg';
import { ReactComponent as CheckboxOff } from '../../assets/icons/check/off.svg';
import { ReactComponent as CheckboxSome } from '../../assets/icons/check/some.svg';

import { ReactComponent as CircleOn } from '../../assets/icons/check-circle/on.svg';
import { ReactComponent as CircleOff } from '../../assets/icons/check-circle/off.svg';

interface Props extends BoxProps {
  checked: boolean;
  onChange?: (event: React.FormEvent<HTMLDivElement>) => void;
  size?: number;
  isSome?: boolean;
  readOnly?: boolean;
}

const Rect = ({
  checked,
  onChange,
  size = 24,
  isSome,
  readOnly,
  ...props
}: Props) => {
  return (
    <Box
      {...props}
      sx={{
        ...props.sx,
        cursor: 'pointer',
      }}
    >
      <Input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        readOnly={readOnly}
        sx={{
          position: 'absolute',
          opacity: 0,
          zIndex: -1,
          width: '1px',
          height: '1px',
          m: '0px',
        }}
      />
      {checked && !isSome ? (
        <CheckboxOn width={size} height={size} />
      ) : (
        <CheckboxOff width={size} height={size} />
      )}
      {isSome && <CheckboxSome width={size} height={size} />}
    </Box>
  );
};

const Circle = ({
  checked,
  onChange,
  size = 24,
  readOnly,
  ...props
}: Props) => {
  return (
    <Box
      {...props}
      sx={{
        ...props.sx,
        cursor: 'pointer',
      }}
    >
      <Input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        readOnly={readOnly}
        sx={{
          position: 'absolute',
          opacity: 0,
          zIndex: -1,
          width: '1px',
          height: '1px',
          m: '0px',
        }}
      />
      {checked ? (
        <CircleOn width={size} height={size} />
      ) : (
        <CircleOff width={size} height={size} />
      )}
    </Box>
  );
};

export default {
  Rect,
  Circle,
};
