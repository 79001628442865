import React from 'react';
import { Dialog } from '@blueprintjs/core';
import { Box, Flex, Link } from 'theme-ui';

import { Text } from '../../Primitives';

interface Props {
  title: string;
  description?: string;
  getAppDescription?: string;
  isOpen: boolean;
  onClose: () => void;
}

const JoinDialog: React.FC<Props> = ({
  title,
  description,
  getAppDescription = 'Get the Rovo app so that the host can contact you quickly if there are any changes to the game.',
  isOpen,
  onClose,
}) => {
  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
        }}
        p="3"
      >
        <Box p="3">
          <Text
            variant="lb.lg"
            mb="2"
            sx={{
              textAlign: 'center',
            }}
          >
            {title}
          </Text>
          {!!description && (
            <Text
              sx={{
                textAlign: 'center',
              }}
              mb="2"
            >
              {description}
            </Text>
          )}
          {getAppDescription && (
            <Text
              sx={{
                textAlign: 'center',
              }}
              variant="lb.lg"
              mb="2"
            >
              {getAppDescription}
            </Text>
          )}
        </Box>

        <Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://page.rovo.co/download-app"
          sx={{
            width: '100%',
            bg: 'primary',
            color: 'white',
            textAlign: 'center',
            py: 3,
            fontSize: '16px',
            fontWeight: 500,
            borderRadius: '8px',
            ':hover': {
              color: 'white',
              textDecoration: 'none',
            },
          }}
        >
          Get the Rovo app here
        </Link>
      </Flex>
    </Dialog>
  );
};

export default JoinDialog;
