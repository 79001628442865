import React, { FunctionComponent } from 'react';
import { Box, Image, Flex } from 'theme-ui';
import { useHistory } from 'react-router-dom';

import { Avatar, Text } from '../Primitives';

import { IPeople } from '../../interface/People';
import { getRating } from '../../helpers/user';

type Props = {
  person: IPeople;
  isVertical?: boolean;
};

const PeopleCard: FunctionComponent<Props> = ({ person, isVertical }) => {
  const {
    firstName,
    lastName,
    picture,
    sportProfiles,
    pulseLevel,
    location,
    username,
  } = person;
  const history = useHistory();

  const locationName = location?.name || location?.locality || location?.city;

  const firstFourSports = sportProfiles.slice(0, 4);

  const rating = getRating(firstFourSports[0]);

  const isShowBadge = firstFourSports.length < 2;

  return (
    <Box
      variant="card"
      sx={{
        cursor: 'pointer',
      }}
      onClick={() => history.push(`/${username}`)}
    >
      <Flex
        sx={{
          alignItems: 'center',
          flexDirection: isVertical ? 'column' : 'row',
        }}
      >
        <Avatar
          badgeText={isShowBadge ? rating : ''}
          size="lg"
          src={picture}
          pulseLevel={pulseLevel}
        />
        <Box
          ml={isVertical ? '0' : '4'}
          mt={isVertical ? '24px' : '0'}
          sx={{
            flex: 1,
            textAlign: isVertical ? 'center' : 'left',
            width: '100%',
          }}
        >
          <Flex
            sx={{
              flexWrap: 'wrap',
              justifyContent: isVertical ? 'center' : 'flex-start',
            }}
            mb="2"
          >
            {firstFourSports.map((sport) => (
              <Image
                key={sport.id}
                mr="2"
                width="16px"
                sx={{
                  width: '16px',
                  maxWidth: '16px',
                  height: '16px',
                  maxHeight: '16px',
                }}
                src={`https://s3-ap-southeast-1.amazonaws.com/prod.rovo.co/assets/sports/sport-${sport.sportInfo.uri}-icon.png`}
              />
            ))}
          </Flex>
          <Text
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            variant="lb.md"
            mb="2"
          >{`${firstName} ${lastName}`}</Text>
          <Text
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            variant="pg.sm"
          >
            {locationName}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default PeopleCard;
