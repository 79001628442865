import 'emoji-mart/css/emoji-mart.css';
import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from '@blueprintjs/core';
import { Flex } from 'rebass';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { Picker } from 'emoji-mart';

import { useOnClickOutside } from '../../hooks/useOuterClick';

import { getCurrencies } from '../../api/UtilsAPI';

import { ICurrency } from '../../interface/Utils';

import styled from '../../theme/styled';
import Text from './Text';
import { Icon } from '../Primitives';

import css from '@emotion/css';

const CurrencyDialog = styled(Dialog)`
  width: 280px;
  padding: 8px;
  position: relative;
`;

const CurrencyItem = styled(Text)`
  :hover {
    background-color: ${(p) => p.theme.colors.primaryHover};
  }
  cursor: pointer;
`;

const SearchIcon = styled(Icon)`
  position: absolute;
  right: 16px;
`;

export const SearchInput = styled.input`
  padding: 12px;
  border-radius: 4px;
  border: ${(p) => p.theme.border.searchInput};
  color: ${(p) => p.theme.colors.primaryText};
  opacity: 0.5;
  :focus {
    opacity: 1;
    border-color: ${(p) => p.theme.colors.primary};
  }
  width: 100%;
  background-color: ${(p) => p.theme.colors.searchInputBg};
`;

const CoreLabel = (props: any) => (
  <Text mx={props.mx || 0} mt={props.mt || 2} as="label" {...props} />
);

export const Label = styled(CoreLabel)`
  color: ${(p) => p.theme.colors.primaryText};
  font-weight: bold;
  margin-bottom: 8px;
  display: block;
`;

const CoreInput = React.forwardRef((props: any, ref) => (
  <Text as="input" {...props} ref={ref} />
));

export const Textarea = styled.textarea`
  width: 100%;
  height: 80px;
  padding: 8px;
  color: ${(p) => p.theme.colors.primaryText};
  border: ${(p) => p.theme.border.searchInput};
  border-radius: 4px;
  ::placeholder {
    opacity: 0.5;
    color: ${(p) => p.theme.colors.primaryText};
  }
`;

export const Input = styled(CoreInput)`
  padding: 16px;
  border-radius: 4px;
  border: ${(p) => p.theme.border.searchInput};
  color: ${(p) => p.theme.colors.primaryText};
  width: 100%;
  ::placeholder {
    opacity: 0.5;
    color: ${(p) => p.theme.colors.primaryText};
  }
  :focus {
    border-color: ${(p) => p.theme.colors.primary};
  }
  ${(p) =>
    p.isError &&
    css`
      border-color: ${p.theme.colors.danger};
    `}
`;

export const InputFile = styled.input`
  display: none;
`;

export const CurrencyInput: React.FC<{
  setValue: Function;
  defaultValue?: ICurrency;
}> = ({ setValue, defaultValue }) => {
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [searchString, setSearchString] = useState('');
  const [isOpen, setOpenModal] = useState(false);
  const [isSearching, setSearching] = useState(false);
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const currencies = await getCurrencies();
        setCurrencies(currencies);
      } catch {}
    };
    fetchCurrencies();
  }, []);

  const onClickCurrency = (currency: ICurrency) => {
    const { code, name } = currency;
    setSelectedCurrency(`${code} (${name})`);
    setValue(currency);
    setOpenModal(false);
  };

  const defaultCurrency = defaultValue
    ? `${defaultValue.code} (${defaultValue.name})`
    : '';

  return (
    <>
      <Input
        value={selectedCurrency || defaultCurrency}
        readOnly
        onClick={() => setOpenModal(true)}
      />
      {isOpen && (
        <CurrencyDialog
          isOpen={isOpen}
          onClose={() => {
            setOpenModal(false);
            setSearching(false);
          }}
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            height="40px"
            color="primaryText"
          >
            {isSearching ? (
              <>
                <Icon icon="arrow-left" onClick={() => setSearching(false)} />
                <Input
                  ml="2"
                  value={searchString}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSearchString(e.target.value)
                  }
                  placeholder="Search currency"
                />
              </>
            ) : (
              <>
                <Text>Select currency</Text>
                <SearchIcon
                  icon="search"
                  size="lg"
                  onClick={() => setSearching(true)}
                />
              </>
            )}
          </Flex>
          <Flex
            overflow="auto"
            flexDirection="column"
            height="400px"
            color="primaryText"
          >
            <PerfectScrollBar>
              {currencies
                .filter(
                  ({ name, code }: { name: string; code: string }) =>
                    `${name} ${code}`
                      .toLowerCase()
                      .search(searchString.toLowerCase()) > -1
                )
                .map(({ id, code, name }) => (
                  <Flex key={id}>
                    <CurrencyItem
                      py="1"
                      width="100%"
                      onClick={() => onClickCurrency({ id, code, name })}
                    >
                      {code}&nbsp;({name})
                    </CurrencyItem>
                  </Flex>
                ))}
            </PerfectScrollBar>
          </Flex>
        </CurrencyDialog>
      )}
    </>
  );
};

interface ChatInputProps {
  value: string;
  onChange: Function;
  onEnter: Function;
  onSelectImage: Function;
  hasLikeEmoji?: boolean;
}

const EmojiWrapper = styled(Flex)`
  position: sticky;
  bottom: 0px;
  height: 40px;
  padding: 16px;
  border: ${(p) => p.theme.border.searchInput};
  border-radius: 16px;
  display: flex;
  align-items: center;
`;

const CInput = styled.input`
  border: none;
  margin-left: 8px;
  flex: 1 1 auto;
`;

const SendIcon = styled(Flex)`
  width: 40px;
  height: 40px;
  border-radius: 50px;
  :hover {
    background-color: ${(p) => p.theme.colors.primaryHover};
  }
`;

export const ChatInput: React.FC<ChatInputProps> = ({
  value,
  onChange,
  onEnter,
  onSelectImage,
  hasLikeEmoji,
}) => {
  const [isShowEmoji, setIsShowEmoij] = useState(false);

  const addEmoij = (e: any) => {
    const newValue = value + e.native;
    onChange(newValue);
    setIsShowEmoij(false);
  };

  const EmojiInput = useRef();

  useOnClickOutside(EmojiInput, () => {
    setIsShowEmoij(false);
  });

  const onFileSelected = (e: any) => {
    e.preventDefault();
    let files = e.target.files;
    if (!files.length) {
      return;
    }
    onSelectImage(files[0]);
  };

  return (
    <Flex alignItems="center">
      <EmojiWrapper flex="1 1 auto" ref={EmojiInput}>
        <Icon
          icon="happy-face"
          fill="primaryText"
          size="md"
          onClick={() => setIsShowEmoij((i) => !i)}
        />
        {isShowEmoji && (
          <Picker
            style={{ position: 'absolute', bottom: '40px', left: '0px' }}
            onSelect={addEmoij}
          />
        )}
        <CInput
          value={value}
          placeholder="Add a comment"
          onChange={(e: any) => onChange(e.target.value)}
          onKeyPress={(e: React.KeyboardEvent) => {
            if (e.key === 'Enter') {
              onEnter(value);
              return onChange('');
            }
          }}
        />
        <Flex as="label" htmlFor="chat-image">
          <Icon icon="camera" size="lg" />
          <InputFile
            type="file"
            id="chat-image"
            name="avatar"
            accept="image/*"
            onChange={onFileSelected}
            multiple
          />
        </Flex>
      </EmojiWrapper>

      <SendIcon alignItems="center" justifyContent="center" mx="2">
        {value || !hasLikeEmoji ? (
          <Icon
            icon="send"
            onClick={() => onEnter(value)}
            size="lg"
            stroke="primary"
            fill="primary"
          />
        ) : (
          <Text
            as="span"
            fontSize="24px"
            height="100%"
            onClick={() => onEnter('👍')}
            sx={{
              cursor: 'pointer',
            }}
          >
            <span role="img" aria-label="like">
              👍
            </span>
          </Text>
        )}
      </SendIcon>
    </Flex>
  );
};
