import React from 'react';
import { PageBody, PageHeading, TabTitle } from '../Schedule.styled';
import { Text } from 'rebass';
import { Icon } from '../../Primitives';
import { useHistory } from 'react-router-dom';
import { Button } from 'rebass';
import { Box } from 'rebass';
import { Tabs, Tab } from '@blueprintjs/core';
import FacilityBookingsTab from './FacilityBookingsTab';
import UpcomingActivitiesTab from '../../Profile/Plays/UpcomingTab';
import ScoreTab from '../../Profile/Plays/ScoreTab';
import LocalStorageHelper from '../../../helpers/localStorage';

interface Props {
  tabId: string;
  setTabId: (tabId: string) => void;
}

const ScheduleBody: React.FC<Props> = ({ tabId, setTabId }) => {
  const history = useHistory();
  const currentUser = LocalStorageHelper.get('currentUser');

  const profileId = currentUser?.account?.profileId;

  return (
    <PageBody>
      <PageHeading
        display="flex"
        height="56px"
        alignItems="center"
        width="100%"
        px={['3', '24px']}
      >
        <Icon
          icon="chevron-left"
          size="lg"
          fill="primaryText"
          onClick={() => history.goBack()}
        />
        <Text variant="headline.small" ml="1">
          Schedule
        </Text>
        <Button
          variant="noPadding"
          ml="auto"
          fontWeight="bold"
          onClick={() => history.push('/schedule/history/activities')}
        >
          History
        </Button>
      </PageHeading>
      <Box>
        <Tabs
          id="schedule-tabs"
          className="modify-tabs"
          renderActiveTabPanelOnly
          onChange={(id: string) => {
            setTabId(id);
            history.push(`/schedule/${id}`);
          }}
          selectedTabId={tabId}
        >
          <Tab
            id="activities"
            title={<TabTitle>Activities</TabTitle>}
            panel={<UpcomingActivitiesTab />}
          />
          <Tab
            id="facility-bookings"
            title={<TabTitle>Facility Booking</TabTitle>}
            panel={<FacilityBookingsTab upcoming />}
          />
          <Tab
            id="scores"
            title={<TabTitle>Scores</TabTitle>}
            panel={<ScoreTab profileId={profileId} />}
          />
        </Tabs>
      </Box>
    </PageBody>
  );
};

export default ScheduleBody;
