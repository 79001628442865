import React, { FunctionComponent, SetStateAction, Dispatch } from 'react';
import { observer } from 'mobx-react-lite';
import { Flex, Button } from 'rebass';

import Text from '../../UI/Text';

import LocationInput from './LocationInput';

import { StyledSlider, CloseIcon } from './FilterBar.styled';

type Location = {
  latitude: number;
  longitude: number;
  placeId: string;
  radius: number;
  name: string;
};

type Props = {
  currentLocations: Location[];
  isAddingLocation: boolean;
  setCurrentLocation: Dispatch<SetStateAction<Location[]>>;
  setAddLocation: Dispatch<SetStateAction<boolean>>;
};

const FilterLocation: FunctionComponent<Props> = observer(
  ({
    currentLocations,
    isAddingLocation,
    setCurrentLocation,
    setAddLocation,
  }) => {
    const addLocation = (location: Location) => {
      if (currentLocations.some(l => l.placeId === location.placeId)) return;
      setCurrentLocation([...currentLocations, { ...location, radius: 20 }]);
    };

    const removeLocation = (index: number) => {
      currentLocations.splice(index, 1);
      setCurrentLocation([...currentLocations]);
    };

    const modifyLocationRadius = (value: number, placeId: string) => {
      const selectedIndex = currentLocations.findIndex(
        (location: { placeId: string }) => location.placeId === placeId
      );
      currentLocations[selectedIndex] = {
        ...currentLocations[selectedIndex],
        radius: value,
      };
      setCurrentLocation([...currentLocations]);
    };

    return (
      <>
        {currentLocations.map((location, index) => (
          <Flex
            key={location.name}
            mb="2"
            flexDirection="column"
            fontSize="2"
            fontWeigh="bold"
          >
            <Flex mb="1" color="primaryText" justifyContent="space-between">
              <Text minWidth="48px">{location.radius}&nbsp;km</Text>
              <Flex alignItems="center">
                <Text>From {location.name}</Text>
                {currentLocations.length > 1 && (
                  <CloseIcon
                    onClick={() => removeLocation(index)}
                    icon="close"
                    size="sm"
                  />
                )}
              </Flex>
            </Flex>
            <StyledSlider
              min={0}
              max={30}
              stepSize={0.5}
              labelStepSize={30}
              labelRenderer={false}
              onChange={value => modifyLocationRadius(value, location.placeId)}
              value={location.radius}
            />
          </Flex>
        ))}
        <Button
          variant="outline"
          onClick={() => setAddLocation(status => !status)}
        >
          {isAddingLocation ? 'Close location selection' : 'Add a location'}
        </Button>
        {isAddingLocation && <LocationInput onClickItem={addLocation} />}
      </>
    );
  }
);

export default FilterLocation;
