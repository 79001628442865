import React from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

import AuthLayout from './components/Layout';

import ChangePassword from './ChangePassword';
import EmailVerify from './EmailVerify';

const AccountVerify: React.FC = () => {
  const location = useLocation();
  const parsed = qs.parse(location.search);

  return (
    <AuthLayout
      formName={
        parsed.mode === 'resetPassword' ? 'Change Password' : 'Verify Email'
      }
    >
      {parsed.mode === 'resetPassword' && <ChangePassword />}
      {parsed.mode === 'verifyEmail' && <EmailVerify />}
    </AuthLayout>
  );
};

export default AccountVerify;
