import React from 'react';
import { Image, Flex, Box } from 'rebass';

import Avatar from '../UI/Avatar';
import Text from '../UI/Text';
import { Icon } from '../Primitives';
import { InputFile } from '../UI/Input';

import { PrimaryDialog } from '../UI/Dialog';
import { IPeople } from '../../interface/People';

import styled from '../../theme/styled';

import { RemoveImgIcon } from './AddFeedBox';

const TextField = styled(Text)`
  width: 100%;
  padding: 8px;
  border: none;
  ::placeholder {
    opacity: 0.6;
  }
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  profile: IPeople;
  images: File[];
  onFileSelected: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRemoveImage: Function;
  onCreatePost: Function;
  caption: string;
  setCaption: Function;
}

const CreatePostDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  profile,
  images,
  onFileSelected,
  onRemoveImage,
  onCreatePost,
  caption,
  setCaption,
}) => {
  const { name, picture, pulseLevel } = profile;

  const onClickShare = () => {
    if (!caption && !images.length) return;
    onCreatePost();
    onClose();
  };

  return (
    <PrimaryDialog
      isOpen={isOpen}
      onClose={onClose}
      title="Create post"
      actionBtn="Share"
      onClickActionBtn={onClickShare}
    >
      <Flex bg="white" flexDirection="column" height="100%">
        <Flex p="3">
          <Avatar pulseLevel={pulseLevel} imageLink={picture} size="40px" />
          <Box ml="3">
            <Text color="primaryText">{name}</Text>
            <Text fontSize="12px">Share a photo, post with your followers</Text>
          </Box>
        </Flex>
        <TextField
          p="2"
          as="textarea"
          placeholder="What's going on?"
          color="primaryText"
          value={caption}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setCaption(e.target.value)
          }
        />
        <Flex flexWrap="wrap">
          {images.map((i: File, index: number) => {
            const url = window.URL.createObjectURL(i);
            return (
              <Flex
                key={index}
                justifyContent="center"
                alignItems="center"
                p="3"
                width="100%"
                variant="card"
                m="2"
                sx={{
                  position: 'relative',
                }}
              >
                <Image
                  src={url}
                  width="100%"
                  onLoad={() => {
                    window.URL.revokeObjectURL(url);
                  }}
                />
                <RemoveImgIcon
                  icon="close"
                  stroke="black"
                  size="sm"
                  onClick={() => onRemoveImage(i.name)}
                />
              </Flex>
            );
          })}
        </Flex>
        <Flex
          as="label"
          htmlFor="postFeed"
          justifyContent="center"
          alignItems="center"
          mt="auto"
          p="3"
          sx={{
            borderTop: '1px solid #E9EEF1',
          }}
        >
          <Icon icon="camera" stroke="primary" />
          <Text fontWeight="bold" color="primary" ml="2">
            Add Photo
          </Text>
          <InputFile
            type="file"
            id="postFeed"
            name="avatar"
            accept="image/*"
            onChange={onFileSelected}
            multiple
          />
        </Flex>
      </Flex>
    </PrimaryDialog>
  );
};

export default CreatePostDialog;
