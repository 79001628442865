import React, { FunctionComponent } from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

import styled from '../../theme/styled';

type Props = {
  children: any;
  value: number;
  strokeWidth: number;
  endColor: string;
  startColor: string;
  gradientId: string;
};

const Progress = styled(CircularProgressbarWithChildren)`
  width: 84px;
`;

// this is the component imported to the view
const GradientCircularProgress: FunctionComponent<Props> = props => {
  const {
    value,
    strokeWidth,
    endColor,
    startColor,
    gradientId,
    children,
  } = props;
  const gradientTransform = `rotate(90)`;
  return (
    <>
      <svg style={{ height: 0, width: 0 }}>
        <defs>
          <linearGradient id={gradientId} gradientTransform={gradientTransform}>
            <stop offset="0%" stopColor={startColor} />
            <stop offset="100%" stopColor={endColor} />
          </linearGradient>
        </defs>
      </svg>
      <Progress
        value={value}
        strokeWidth={strokeWidth}
        styles={{
          path: {
            stroke: `url(#${gradientId})`,
            strokeLinecap: 'butt',
            transform: 'rotate(0.4turn)',
            transformOrigin: 'center center',
          },
        }}
      >
        {children}
      </Progress>
    </>
  );
};

export default GradientCircularProgress;
