export const elevation = {
  above: {
    tiny: '0px -2px 2px rgba(0, 0, 0, 0.1)',
    xLow: '0px -4px 8px rgba(0, 0, 0, 0.12)',
    low: '0px -4px 16px rgba(0, 0, 0, 0.12)',
    medium: '0px -8px 36px rgba(0, 0, 0, 0.16)',
    high: '0px -16px 48px rgba(0, 0, 0, 0.24)',
  },
  below: {
    tiny: '0px 2px 2px rgba(0, 0, 0, 0.1)',
    xLow: '0px 4px 8px rgba(0, 0, 0, 0.12)',
    low: '0px 4px 16px rgba(0, 0, 0, 0.12)',
    medium: '0px 8px 36px rgba(0, 0, 0, 0.16)',
    high: '0px 16px 48px rgba(0, 0, 0, 0.24)',
  },
  stroke: {
    bottom: 'inset 0px -1px 0px #E9EFF5',
    top: 'inset 0px 1px 0px #E9EFF5',
    'top-bottom': 'inset 0px 1px 0px #E9EFF5, inset 0px -1px 0px #E9EFF5',
    'left-right': 'inset -1px 0px 0px #E9EFF5, inset 1px 0px 0px #E9EFF5',
  },
};
