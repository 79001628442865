import React, { useState } from 'react';
import { Card, Flex } from 'rebass';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react-lite';
import { Spinner } from '@blueprintjs/core';

import Text from '../../UI/Text';
import Avatar from '../../UI/Avatar';
import { SimpleDialog } from '../../UI/Dialog';

import { HeaderMobile } from '../../Layout/Header';
import FacebookLogin from '../../Authentication/components/FacebookLogin';
import { userStore } from '../../../stores/UserStore';

import styled from '../../../theme/styled';

const Social = styled(Flex)`
  cursor: pointer;
`;

const SocialAccounts: React.FC = observer(() => {
  const { account, loading, disconnectProvider } = userStore;
  const { facebook, firebaseId } = account;
  const imageLink = facebook ? facebook.picture.data.url : '';

  const isMobile = useMediaQuery({ maxWidth: 1024 });

  const [isOpenInfo, setIsOpenInfo] = useState(false);

  const toggleFacebookAccountLink = async () => {
    if (!firebaseId) {
      return setIsOpenInfo(true);
    }
    if (imageLink) {
      return disconnectProvider('FACEBOOK');
    }
  };

  return (
    <Flex flexDirection="column">
      <HeaderMobile
        text="Social Accounts"
        hasBackBtn={isMobile}
        backLink="/settings"
      />
      <Text textTransform="uppercase" fontWeight="bold" p="3">
        Facebook
      </Text>
      {loading ? (
        <Spinner intent="primary" />
      ) : (
        <Card px="3" mx="3">
          {imageLink ? (
            <Social
              alignItems="center"
              p="2"
              onClick={toggleFacebookAccountLink}
            >
              <Avatar imageLink={imageLink} size="40px" />
              <Text color="primaryText" ml="2">
                Unlink Facebook account
              </Text>
            </Social>
          ) : (
            <FacebookLogin />
          )}
          <Text>We'll never post to Facebook without your permission.</Text>
        </Card>
      )}
      <SimpleDialog onClose={() => setIsOpenInfo(false)} isOpen={isOpenInfo}>
        <Flex width="300px">
          Please create an email login for this account before unlinking your
          Facebook account
        </Flex>
      </SimpleDialog>
    </Flex>
  );
});

export default SocialAccounts;
