import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { Dialog } from '@blueprintjs/core';

import styled from '../../theme/styled';
import { CreditCard } from '../Payment';
import { Text, Button, Icon, Checkbox } from '../Primitives';
import {
  getPaymentMethodsByCurrency,
  createOrGetPartnerTrainer,
  dryrunJoinWorkoutParty,
  joinWorkoutParty,
} from '../../api/OrderAPI';
import { centsToDecimal } from '../../helpers/currency';
import { observer } from 'mobx-react-lite';
import { userStore } from '../../stores/UserStore';
import { Spinner, Flex } from 'theme-ui';
import { IOrder } from '../../interface/Order';
import cogoToast from 'cogo-toast';
import { IPaymentMethod } from '../../interface/Utils';
import { useStripe } from '@stripe/react-stripe-js';
import { ReactComponent as IconConfirm } from '../../assets/illustrator/colored-ok.svg';
import { checkOrderTransactionStatus } from '../../api/VenueAPI';
import { useHistory } from 'react-router-dom';

const SDialog = styled(Dialog)`
  margin: 0px;
  padding: 16px;
  background-color: white;
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  priceCents: {
    currency: string;
    price: number;
  };
  createdById: number;
  activityId: string;
}

const ccBrand: {
  [index: string]: 'visa' | 'american-express' | 'master-card' | 'jcb';
} = {
  Visa: 'visa',
  'American Express': 'american-express',
  MasterCard: 'master-card',
  JCB: 'jcb',
};

const PayToJoinDialog = observer(
  ({ isOpen, onClose, priceCents, createdById, activityId }: Props) => {
    const ref = useRef<HTMLFormElement>();
    const stripe = useStripe();
    const history = useHistory();

    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
    const [partnerId, setPartnerId] = useState('');
    const [total, setTotal] = useState(priceCents.price);
    const [selectedMethod, setMethod] = useState('');
    const [isAddNew, setIsAddNew] = useState(false);
    const [isPaySuccess, setIsPaySuccess] = useState(false);
    const [isDryrun, setIsDryrun] = useState(false);

    useEffect(() => {
      const getData = async () => {
        try {
          const paymentMethods: IPaymentMethod[] = await getPaymentMethodsByCurrency(
            priceCents.currency
          );
          const partnerId = await createOrGetPartnerTrainer(createdById);
          const method = paymentMethods.find((p) => p.isDefault);
          if (method) {
            setMethod(method.paymentMethodId);
          } else {
            setIsAddNew(true);
          }
          setLoading(false);
          setPaymentMethods(paymentMethods);
          setPartnerId(partnerId);
        } catch (error) {
          setLoading(false);
        }
      };
      getData();
    }, [priceCents, createdById]);

    const onFailedPayment = (message?: string) => {
      if (message) {
        cogoToast.error(message || 'Join WorkoutParty! failed');
      }
      setSubmitting(false);
    };

    const onSuccessPayment = async () => {
      setIsPaySuccess(true);
      setSubmitting(false);
    };

    const onMakePayment = async (token?: string) => {
      const data = {
        paymentInfo: {
          paymentMethod: 'creditCard',
          creditCardTokenInfo: { token },
          paymentMethodId: selectedMethod,
        },
        customerInfo: {
          firstName: account.profile.firstName,
          lastName: account.profile.lastName,
          email: account.email,
          mobile: account.phone,
        },
        lineItems: [
          {
            targetType: 'joinActivity',
            targetId: activityId,
          },
        ],
        promoCode: '',
        currency: priceCents.currency,
      };

      try {
        const response: IOrder = await joinWorkoutParty(data, partnerId);
        const pendingOrderTransaction = response?.orderTransactions.find(
          (o) => o.status !== 'SUCCESS' && o.paymentMethod === 'creditCard'
        );
        if (pendingOrderTransaction) {
          const properties = pendingOrderTransaction
            ? pendingOrderTransaction.rawData.properties
            : [];
          const clientSecret = properties.length
            ? properties.find((p) => p.key === 'client_secret')?.value
            : '';
          if (clientSecret && pendingOrderTransaction)
            return {
              clientSecret,
              orderId: pendingOrderTransaction.orderId,
              id: pendingOrderTransaction.id,
            };
          return onFailedPayment('Booking failed');
        }
        onSuccessPayment();
      } catch (error) {
        const message =
          error?.response?.data?.msg ||
          error?.response?.data?.message?.message ||
          'Join WorkoutParty! failed';
        if (typeof message === 'string') onFailedPayment(message);
      }
    };

    const onSubmit = async () => {
      setSubmitting(true);
      if (ref && ref.current) {
        return ref.current?.dispatchEvent(new Event('submit'));
      }
      const response = await onMakePayment();
      if (response) {
        if (response?.clientSecret && stripe) {
          const { error } = await stripe.confirmCardPayment(
            response.clientSecret
          );
          if (error) {
            return onFailedPayment(error.message);
          }
          await checkOrderTransactionStatus(response.orderId, response.id);
          return onSuccessPayment();
        }
      }
    };

    const { account } = userStore;

    const dryrunOrder = useCallback(
      async (partnerId: string) => {
        const data = {
          paymentInfo: {
            paymentMethod: 'creditCard',
          },
          customerInfo: {
            firstName: account.profile.firstName,
            lastName: account.profile.lastName,
            email: account.email,
            mobile: account.phone,
          },
          lineItems: [
            {
              targetType: 'joinActivity',
              targetId: activityId,
            },
          ],
          promoCode: '',
          currency: priceCents.currency,
        };
        const order: IOrder = await dryrunJoinWorkoutParty(data, partnerId);
        setTotal(order.totalAmount);
        setIsDryrun(true);
      },
      [account, priceCents, activityId]
    );

    useEffect(() => {
      if (partnerId && !isDryrun) {
        dryrunOrder(partnerId);
      }
    }, [partnerId, dryrunOrder, isDryrun]);

    function renderPaymentMethods(onlyDefault?: boolean) {
      if (!paymentMethods?.length) return null;
      return paymentMethods.map((paymentMethod) => {
        if (onlyDefault && !paymentMethod.isDefault) return null;

        const ccLast4 = paymentMethod.pluginInfo.properties.find(
          (p) => p.key === 'ccLast4'
        )?.value;
        const ccType = paymentMethod.pluginInfo.properties.find(
          (p) => p.key === 'ccType'
        )?.value;
        const ccExpirationYear = paymentMethod.pluginInfo.properties.find(
          (p) => p.key === 'ccExpirationYear'
        )?.value;
        const ccExpirationMonth = paymentMethod.pluginInfo.properties.find(
          (p) => p.key === 'ccExpirationMonth'
        )?.value;

        const isSelected = selectedMethod === paymentMethod.paymentMethodId;
        if (ccLast4 && ccType && ccExpirationMonth && ccExpirationYear) {
          return (
            <Flex
              key={paymentMethod.paymentMethodId}
              sx={{
                alignItems: 'center',
                width: '100%',
                px: '24px',
                py: 2,
                cursor: 'pointer',
                ':not(:last-of-type)': {
                  borderBottom: '1px solid',
                  borderColor: 'sportmanGray',
                },
              }}
              onClick={() => {
                if (onlyDefault || submitting) return;
                setMethod(paymentMethod.paymentMethodId);
                setIsAddNew(false);
              }}
            >
              <Checkbox.Circle readOnly checked={isSelected} />
              <Flex
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid',
                  borderRadius: '4px',
                  borderColor: 'border',
                  px: 1,
                  ml: 3,
                }}
              >
                {!!ccBrand[ccType] && <Icon icon={ccBrand[ccType]} size="lg" />}
              </Flex>
              <Text variant="lb.md" mx="4">
                **** {ccLast4}
              </Text>
              <Text>
                Exp: {ccExpirationMonth} / {ccExpirationYear}
              </Text>
            </Flex>
          );
        } else {
          return null;
        }
      });
    }

    const enableSubmit = useMemo(
      () => (!selectedMethod && isAddNew) || selectedMethod,
      [selectedMethod, isAddNew]
    );

    return (
      <SDialog isOpen={isOpen} onClose={onClose}>
        {isPaySuccess && (
          <Flex
            mt="5"
            sx={{
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              height: '100%',
            }}
          >
            <IconConfirm />
            <Text mt="3" mb="2" variant="hd.sm">
              Payment Successful
            </Text>
            <Text mb="3" variant="pg.lg">
              Your payment is successful & you've been added to the activity.
              <br />
              Join in the discussion with the host or other participants!
            </Text>
            <Button
              sx={{
                width: '100%',
              }}
              size="md"
              variant="outline"
              onClick={() => {
                onClose();
                history.replace(
                  `/plays/${activityId}?current=activity-discussions`
                );
              }}
            >
              Open Discussion
            </Button>
          </Flex>
        )}
        {loading ? (
          <Flex
            sx={{
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              p: 4,
            }}
          >
            <Spinner mb="3" />
          </Flex>
        ) : (
          !isPaySuccess && (
            <>
              <Text
                variant="lb.lg"
                sx={{
                  textAlign: 'center',
                  mb: '24px',
                }}
              >
                Confirm payment
              </Text>
              {renderPaymentMethods()}
              <Flex
                sx={{
                  cursor: 'pointer',
                  alignItems: 'center',
                  px: '24px',
                  py: 2,
                }}
                onClick={() => {
                  setMethod('');
                  setIsAddNew(true);
                }}
              >
                <Checkbox.Circle readOnly checked={!selectedMethod} />
                <Text ml="3" variant="lb.md">
                  New payment method
                </Text>
              </Flex>
              {isAddNew && (
                <CreditCard
                  hasLabel
                  ref={ref}
                  onMakePayment={onMakePayment}
                  onSuccess={onSuccessPayment}
                  onFailed={onFailedPayment}
                />
              )}
              <Button
                mt="3"
                loading={submitting}
                disabled={!enableSubmit}
                size="lg"
                variant="primary"
                onClick={onSubmit}
              >
                Confirm and pay {centsToDecimal(total, priceCents.currency)}{' '}
                {priceCents.currency}
              </Button>
            </>
          )
        )}
      </SDialog>
    );
  }
);

export default PayToJoinDialog;
