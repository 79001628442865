import { Dialog } from '@blueprintjs/core';
import cogoToast from 'cogo-toast';
import { auth } from 'firebase/app';
import React, { useEffect, useRef, useState } from 'react';
import { Box, Flex } from 'rebass';
import AuthService from '../../services/authService';
import {
  default as FireBaseService,
  default as firebaseService,
} from '../../services/firebaseService';
import { CloseIcon } from '../Layout/FilterBar/FilterBar.styled';
import { Text } from '../Primitives';
import OTPVerify from './components/OTPVerify';
import PhoneVerify from './components/PhoneVerify';

type Props = {
  onClose: () => void;
  onCancel: () => void;
  isOpen: boolean;
  reason?: string;
};

const PhoneVerification = ({ onClose, isOpen, onCancel }: Props) => {
  const [
    confirmation,
    setConfirmation,
  ] = useState<auth.ConfirmationResult | void>();
  const [phone, setPhone] = useState('');
  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const recaptchaRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (firebaseService.currentUser?.phoneNumber) {
      setPhone(firebaseService.currentUser.phoneNumber);
    }
  }, []);

  const handleSendPhone = async () => {
    setIsSubmitting(true);
    try {
      if (firebaseService.currentUser?.phoneNumber) {
        await FireBaseService.phoneVerification(`+${phone}`);
        return onClose();
      }
      const confirmation = await FireBaseService.phoneVerification(`+${phone}`);
      setConfirmation(confirmation);
      setStep(2);
      setIsSubmitting(false);
      if (recaptchaRef.current) {
        recaptchaRef.current.innerHTML =
          '<div id="phone-verification-button"></div>';
      }
    } catch (error) {
      setIsSubmitting(false);
      if (recaptchaRef.current) {
        recaptchaRef.current.innerHTML =
          '<div id="phone-verification-button"></div>';
      }
      cogoToast.error(error?.message);
    }
  };

  const handleSendCode = async (code: string) => {
    setIsSubmitting(true);
    if (!confirmation) return;
    try {
      const { user } = await confirmation.confirm(code);
      if (user && user.phoneNumber && user.uid) {
        await AuthService.verifyLogin(user.uid, user.phoneNumber, 'PHONE');
        setIsSubmitting(false);
        cogoToast.success('Verify Phone successfully.');
        return onClose();
      }
    } catch (error) {
      if (error.code === 'auth/credential-already-in-use') {
        cogoToast.error(
          'This phone number is already linked to another account. Please use another phone number.'
        );
        onCancel();
        return onClose();
      }
      setIsSubmitting(false);
      cogoToast.error(error?.message || error?.response?.data?.errorMessage);
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
    >
      <Flex p="3">
        <Text variant="lb.lg">Account verification</Text>
        <Box
          sx={{
            textAlign: 'right',
            display: ['none', 'block'],
            ml: 'auto',
          }}
        >
          <CloseIcon
            fill="primaryText"
            icon="close"
            size="lg"
            onClick={() => {
              onCancel();
              onClose();
            }}
          />
        </Box>
      </Flex>
      <Box px="3">
        <Box ref={recaptchaRef}>
          <div id="phone-verification-button"></div>
        </Box>
        {step === 1 && (
          <PhoneVerify
            phone={phone}
            setPhone={setPhone}
            handleSendPhone={handleSendPhone}
            isSubmitting={isSubmitting}
          />
        )}
        {step === 2 && (
          <OTPVerify
            handleSendCode={handleSendCode}
            handleSendPhone={handleSendPhone}
            isSubmitting={isSubmitting}
          />
        )}
      </Box>
    </Dialog>
  );
};

export default PhoneVerification;
