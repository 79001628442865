import React, { useMemo, useState, useEffect } from 'react';
import { Tabs, Tab } from '@blueprintjs/core';
import { Flex } from 'rebass';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';

import Text from '../UI/Text';
import { IActivity } from '../../interface/Activity';

import DetailTab from './DetailTab';
import DiscussionTab from './DiscussionTab';

import {
  PageWrapper,
  Header,
  BackIcon,
  TabTitle,
} from './ActivityDetail.styled';
import firebaseService from '../../services/firebaseService';
import config from '../../config';

interface Props {
  onClose: () => void;
  activity: IActivity;
}

const ActivityDetail: React.FC<Props> = ({ onClose, activity }) => {
  const { name, createdById, priceCents } = activity;

  const location = useLocation();
  const parsed = qs.parse(location.search);
  const current = parsed.current;

  const [selectedTab, setSelectedTab] = useState('activity-details');

  useEffect(() => {
    if (typeof current === 'string') setSelectedTab(current);
  }, [current]);

  const isPayToJoinHost = useMemo(() => {
    const value: number[] | null = firebaseService.getRemoteConfigByKey(
      'whitelisted_paid_hosts'
    );
    const list =
      value === null || value?.length === 0
        ? config.WHITELISTED_PAID_HOST
        : value;
    return list.includes(createdById) && !!priceCents;
  }, [createdById, priceCents]);

  return (
    <PageWrapper>
      <Flex flexDirection="column">
        <Header
          p="3"
          pb="54px"
          color="primaryText"
          variant="backgroundGradient"
          width="100%"
        >
          <BackIcon
            icon="chevron-left"
            fill="white"
            size="lg"
            onClick={onClose}
          />
          <Text
            textAlign="left"
            color="white"
            ml="2"
            width="100%"
            fontSize={[16, 18]}
            truncate
          >
            {name}
          </Text>
        </Header>
        <Tabs
          id="activity-detail-tabs"
          selectedTabId={selectedTab}
          onChange={(tabId) => {
            if (typeof tabId === 'string') {
              setSelectedTab(tabId);
            }
          }}
        >
          <Tab
            id="activity-details"
            title={
              <TabTitle
                sx={{
                  fontWeight: selectedTab === 'activity-details' ? 500 : 400,
                }}
                color="white"
              >
                Details
              </TabTitle>
            }
            panel={<DetailTab activity={activity} />}
          />
          <Tab
            id="activity-discussions"
            title={
              <TabTitle
                sx={{
                  fontWeight:
                    selectedTab === 'activity-discussions' ? 500 : 400,
                }}
                color="white"
              >
                Discussion
              </TabTitle>
            }
            panel={
              <DiscussionTab
                activity={activity}
                isPayToJoinHost={isPayToJoinHost}
              />
            }
          />
        </Tabs>
      </Flex>
    </PageWrapper>
  );
};

export default ActivityDetail;
