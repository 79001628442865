import React, { useState, useEffect } from 'react';
import { Flex } from 'rebass';
import { useParams, useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import Profile from './components/Profile';

import styled from '../../theme/styled';
import SettingsMenu from './SettingsMenu';
import SocialAccounts from './components/SocialAccounts';
import Notifications from './components/Notifications';
import Privacy from './components/Privacy';
import Language from './components/Language';
import Availability from './components/Availability';
import FindFriends from './components/FindFriends';
import InviteFriends from './components/InviteFriends';

const Wrapper = styled(Flex)`
  border-right: ${p => p.theme.border.box};
  flex-grow: 1;
  @media (min-width: 768px) {
    min-width: 600px;
    max-width: 600px;
  }
`;

const SettingsBody: React.FC = () => {
  const [activeMenu, setActiveMenu] = useState('');

  const history = useHistory();
  const params: { path?: string } = useParams();

  const isDesktop = useMediaQuery({ minWidth: 1024 });

  useEffect(() => {
    if (isDesktop && !params.path) {
      history.push('/settings/profile');
      return setActiveMenu('profile');
    }
    setActiveMenu(params.path || '');
  }, [history, params, isDesktop]);

  return (
    <Wrapper flexDirection="column">
      {!activeMenu && <SettingsMenu />}
      {activeMenu === 'profile' && <Profile />}
      {activeMenu === 'social-accounts' && <SocialAccounts />}
      {activeMenu === 'notifications' && <Notifications />}
      {activeMenu === 'privacy' && <Privacy />}
      {activeMenu === 'language' && <Language />}
      {activeMenu === 'availability' && <Availability />}
      {activeMenu === 'find-friends' && <FindFriends />}
      {activeMenu === 'invite-friends' && <InviteFriends />}
    </Wrapper>
  );
};

export default SettingsBody;
