import React, {
  FunctionComponent,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import { Flex } from 'rebass';
import { observer } from 'mobx-react-lite';
import { Tabs, Tab } from '@blueprintjs/core';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

import styled from '../../theme/styled';

import ActivitiesTab from './ActivitiesTab';
import PeopleTab from './PeopleTab';
import GroupsTab from './GroupsTab';
import VenuesTab from './VenuesTab';
import FilterBar from '../Layout/FilterBar';

import { filterStore } from '../../stores/FilterStore';
import { SORT_TYPES, encodeSchedule } from '../../helpers/search';
import SearchField from './SearchField';
import { isArray } from 'util';
import SearchTab from './SearchTab';

const Body = styled(Flex)``;

const Title = styled.div`
  padding: 4px 16px;
  font-weight: bold;
`;

type Props = {};

const SearchBody: FunctionComponent<Props> = observer(() => {
  const {
    selectedSports,
    sortTypes,
    skillLevelRange,
    scheduleList,
    isShowFullActivities,
    locations,
    resetQuery,
  } = filterStore;

  const [selectedTab, setSelectedTab]: [
    string,
    Dispatch<SetStateAction<string>>
  ] = useState('all');
  const [queryParam, setQueryParam] = useState('');

  const location = useLocation();
  const parsed = qs.parse(location.search);
  const q = parsed.q;

  useEffect(() => {
    if (isArray(q)) return;
    setQueryParam(q || '');
  }, [q]);

  useEffect(() => {
    resetQuery('search');
  }, [resetQuery]);

  const selectedType = sortTypes[selectedTab];

  const query: {
    sports: string[];
    sort: string;
    locations: { latitude: number; longitude: number; radius: number }[];
    minSkillRating: number;
    maxSkillRating: number;
    schedule: number;
    showFullGames: boolean;
    q?: string;
  } = {
    sports: selectedSports.map((sport) => sport.toUpperCase()),
    sort: SORT_TYPES[selectedType],
    locations: locations.map(({ latitude, longitude, radius }) => ({
      latitude: Number(latitude),
      longitude: Number(longitude),
      radius: Number(radius),
    })),
    minSkillRating: skillLevelRange[0],
    maxSkillRating: skillLevelRange[1],
    schedule: encodeSchedule(scheduleList),
    showFullGames: isShowFullActivities,
    q: q && !isArray(q) ? q : '',
  };

  return (
    <Body as="main" flexDirection="column">
      <SearchField defaultSearchString={queryParam} hasBackBtn />
      <Flex
        sx={{
          flex: 1,
          px: [0, '24px'],
          flexDirection: 'column',
        }}
      >
        <Tabs
          id="explore-tabs"
          className="modify-tabs"
          renderActiveTabPanelOnly
          onChange={(id: string) => {
            setSelectedTab(id);
          }}
          selectedTabId={selectedTab}
        >
          <Tab
            id="all"
            title={<Title>All</Title>}
            panel={<SearchTab setSelectedTab={setSelectedTab} />}
          />
          <Tab
            id="activities"
            title={<Title>Activities</Title>}
            panel={<ActivitiesTab query={query} />}
          />
          <Tab
            id="people"
            title={<Title>People</Title>}
            panel={<PeopleTab query={query} />}
          />
          <Tab
            id="groups"
            title={<Title>Groups</Title>}
            panel={<GroupsTab query={query} />}
          />
          <Tab
            id="venues"
            title={<Title>Venues</Title>}
            panel={<VenuesTab query={query} />}
          />
        </Tabs>
        {selectedTab !== 'all' && <FilterBar selectedTab={selectedTab} />}
      </Flex>
    </Body>
  );
});

export default SearchBody;
