import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Flex, Box, Card } from 'theme-ui';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { AvatarWithProgress } from '../../UI/Avatar';

import { IPeople } from '../../../interface/People';
import styled from '../../../theme/styled';
import { getPulseLevel } from '../../../helpers/pulseLevel';

import { userStore } from '../../../stores/UserStore';

import ProfileProgress from './ProfileProgress';
import { Button, Text, Icon } from '../../Primitives';
import config from '../../../config';
import ShareDialog from '../../Layout/ShareDialog';
import { createGroups } from '../../../api/InboxAPI';

type Props = {
  profile: IPeople;
  isSelf: boolean;
  loading: boolean;
};

const DetailBox = styled(Box)`
  width: 96px;
  :not(:last-child) {
    border-right: ${(p) => p.theme.border.box};
    margin-right: 8px;
  }
  cursor: pointer;
  :hover {
    background-color: ${(p) => p.theme.colors.primaryHover};
  }
`;

const SettingIcon = styled(Icon)`
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;

const ProfileProgressWrapper = styled(Card)`
  background-color: ${(p) => p.theme.colors.gray};
  border-radius: 32px;
  cursor: pointer;
`;

const UserInformation: React.FC<Props> = observer(
  ({ profile, isSelf, loading }) => {
    const {
      name,
      followers,
      following,
      sportProfiles,
      pictureLarge,
      picture,
      pulseLevel,
      pulsePoints,
      username,
      gender,
      location,
      progress,
      followStatus,
    } = profile;

    const [isShowShareDialog, setShowShareDialog] = useState(false);
    const [groupId, setGroupId] = useState<number | undefined>();

    const activities = sportProfiles.reduce(
      (num, sport) => (num += sport.gamesPlayed),
      0
    );

    const { updateFollow, account } = userStore;

    const createChat = useCallback(async () => {
      try {
        const group = await createGroups(
          [profile.id, account.profile.id],
          'PAIR'
        );
        setGroupId(group.id);
      } catch (error) {}
    }, [profile, account]);

    const percentage = getPulseLevel(pulseLevel, pulsePoints);

    const history = useHistory();

    useEffect(() => {
      if (account.id > -1) {
        createChat();
      }
    }, [account, createChat]);

    const showChatButton = useMemo(() => profile.id !== account.profile.id, [
      profile,
      account,
    ]);

    return (
      <Flex
        sx={{
          flexDirection: 'column',
        }}
        bg="white"
      >
        <Flex
          p={[2, 3]}
          mb="2"
          sx={{
            alignItems: 'center',
          }}
        >
          <AvatarWithProgress
            size="72px"
            imageLink={pictureLarge || picture}
            pulseLevel={pulseLevel}
            value={percentage}
            loading={loading}
          />
          <Flex
            sx={{
              flexDirection: 'column',
              flex: 1,
            }}
            ml="3"
          >
            <Flex
              sx={{
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              mb="1"
            >
              <Text variant="lb.lg">
                {name}&nbsp;
                <Text
                  sx={{
                    display: ['none', 'inline'],
                  }}
                >
                  {username && `@${username}`}
                </Text>
              </Text>
              {!isSelf && (
                <SettingIcon
                  icon="share-android"
                  size="lg"
                  onClick={() => setShowShareDialog(true)}
                />
              )}
            </Flex>
            <Flex mb="3">
              {!!gender && (
                <Flex
                  sx={{
                    alignItems: 'center',
                  }}
                  mr="3"
                >
                  <>
                    <Icon icon={gender} size="sm" />
                    <Text textTransform="capitalize" ml="1" as="span">
                      {gender}
                    </Text>
                  </>
                </Flex>
              )}
              {!!location && !!location.name && (
                <Flex
                  sx={{
                    alignItems: 'center',
                  }}
                >
                  <Icon icon="location" size="sm" />
                  <Text textTransform="capitalize" ml="1" as="span">
                    {location.name}
                  </Text>
                </Flex>
              )}
            </Flex>
            <Flex>
              <DetailBox
                onClick={() =>
                  history.push(`/${profile.username}?current=play`)
                }
              >
                <Text variant="lb.md">{activities}</Text>
                <Text>Activities</Text>
              </DetailBox>
              <DetailBox
                onClick={() =>
                  history.push(`/${profile.username}?current=follower`)
                }
              >
                <Text variant="lb.md">{followers}</Text>
                <Text>Followers</Text>
              </DetailBox>
              <DetailBox
                onClick={() =>
                  history.push(`/${profile.username}?current=following`)
                }
              >
                <Text variant="lb.md">{following}</Text>
                <Text>Following</Text>
              </DetailBox>
            </Flex>
          </Flex>
        </Flex>
        <Flex px="3" mb="2">
          {!!followStatus && (
            <Button
              sx={{
                flex: 1,
                width: '100%',
                textTransform: 'capitalize',
              }}
              variant={
                followStatus && followStatus !== 'NOT_FOLLOWING'
                  ? 'outline.primary'
                  : 'outline.normal'
              }
              ml="auto"
              onClick={() => updateFollow()}
            >
              {followStatus !== 'NOT_FOLLOWING'
                ? followStatus.toLowerCase()
                : 'Follow'}
            </Button>
          )}
          {showChatButton && (
            <Button
              ml="2"
              variant="outline.normal"
              onClick={() => history.push(`/inbox/g/${groupId}`)}
            >
              Chat with {profile.firstName} {profile.lastName}
            </Button>
          )}
        </Flex>
        {isSelf && progress !== 100 && (
          <ProfileProgressWrapper
            py="10px"
            px="24px"
            mx={[2, 3]}
            mb="3"
            onClick={() =>
              history.push(`/${username}?current=profile-completion`)
            }
          >
            <ProfileProgress progress={progress} />
          </ProfileProgressWrapper>
        )}
        {isShowShareDialog && (
          <ShareDialog
            isOpen={isShowShareDialog}
            onClose={() => setShowShareDialog(false)}
            url={`${config.URL}/@${profile.username}`}
          />
        )}
      </Flex>
    );
  }
);

export default UserInformation;
