import React, { useState, useEffect, useRef } from 'react';
import { Flex, Card, Button } from 'rebass';
import debounce from 'lodash/debounce';
import PerfectScrollBar from 'react-perfect-scrollbar';
import get from 'lodash/get';

import { SearchInput } from '../../UI/Input';
import Text from '../../UI/Text';
import ReviewStar from '../../UI/ReviewStar';

import { searchLocations, getLocationDetail } from '../../../api/LocationAPI';

import styled from '../../../theme/styled';
import getGeolocation from '../../../helpers/geolocation';
import { useOnClickOutside } from '../../../hooks/useOuterClick';

const Wrapper = styled(Flex)`
  position: relative;
`;

const LocationWrapper = styled(PerfectScrollBar)`
  max-height: 400px;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const Result = styled(Card)`
  max-height: 400px;
  margin-top: 8px;
  background-color: white;
`;

interface Location {
  latitude: number;
  longitude: number;
  placeId: string;
  radius: number;
  name: string;
}

export const LocationItem = styled(Flex)`
  :not(:last-child) {
    border-bottom: 0.5px solid #d4dade;
  }
  :hover {
    background-color: ${(p) => p.theme.colors.primaryHover};
  }
  cursor: pointer;
  padding: 8px 0px;
  min-height: 50px;
  justify-content: center;
  flex-direction: column;
`;

const LocationInput: React.FC<{
  onClickItem: Function;
  isOverlay?: boolean;
}> = ({ onClickItem, isOverlay }) => {
  const [locationData, setLocationData] = useState([]);
  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    const getLocations = debounce(async (value) => {
      if (!value) return;
      try {
        const response = await searchLocations(value);
        const { results } = response;
        setLocationData(results);
      } catch (error) {
        throw error;
      }
    }, 100);
    getLocations(searchString);
  }, [searchString]);

  const onSelectLocation = async (location: Location) => {
    if (!location.placeId) {
      setSearchString('');
      return onClickItem(location);
    }
    try {
      const locationData = await getLocationDetail(location.placeId);
      return onClickItem(locationData);
    } catch (error) {
      return onClickItem(location);
    } finally {
      setSearchString('');
    }
  };

  const resultRef = useRef<HTMLDivElement>();

  useOnClickOutside(resultRef, () => setSearchString(''));

  return (
    <Wrapper mt="3" flexDirection="column">
      <SearchInput
        type="search"
        placeholder="Search for a location"
        onChange={(event) => {
          event.preventDefault();
          setSearchString(event.target.value);
        }}
        value={searchString}
      />
      {!!searchString && (
        <Result
          ref={resultRef}
          px="0"
          width="100%"
          sx={{
            position: isOverlay ? 'absolute' : 'relative',
            top: isOverlay ? '40px' : '0px',
          }}
        >
          <LocationWrapper>
            <Button
              sx={{
                minHeight: '40px',
              }}
              mb="1"
              variant="outline.normal"
              type="button"
              onClick={() => {
                setSearchString('');
                getGeolocation(onClickItem);
              }}
            >
              Use current location
            </Button>
            {locationData.map((location) => {
              const { name, description, placeId, id, venueDetail } = location;
              const overallStars = get(
                venueDetail,
                'reviewSummary.overallStars'
              );
              const reviewsNumber = get(
                venueDetail,
                'reviewSummary.reviewsNumber'
              );
              const venueName = get(venueDetail, 'venueName');
              return (
                <LocationItem
                  key={id || placeId}
                  onClick={() => onSelectLocation(location)}
                >
                  <Text color="primaryText">{venueName || name}</Text>
                  {venueDetail ? (
                    <ReviewStar
                      overallStars={overallStars}
                      reviewsNumber={reviewsNumber}
                    />
                  ) : (
                    <Text
                      fontSize="12px"
                      color="secondaryText"
                      fontWeight="500"
                    >
                      {description}
                    </Text>
                  )}
                </LocationItem>
              );
            })}
          </LocationWrapper>
        </Result>
      )}
    </Wrapper>
  );
};

export default LocationInput;
