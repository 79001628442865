import React, { FunctionComponent } from 'react';
import { Flex } from 'theme-ui';

import { Avatar, Text } from '../../Primitives';

import styled from '../../../theme/styled';

import { PulseIconNames } from '../../Primitives/Icon/iconTypes';

type Props = {
  members: {
    firstName: string;
    lastName: string;
    picture: string;
    id: number;
    pulseLevel: PulseIconNames;
  }[];
};

const Wrapper = styled(Flex)`
  flex-direction: row;
  text-align: center;
  justify-content: center;
`;

const ImageWrapper = styled(Flex)`
  position: relative;
  text-align: center;
`;

const ListImage: FunctionComponent<Props> = ({ members }) => {
  if (members.length > 2) {
    const max = members.length > 5 ? 5 : members.length;
    const displayName = `${members[0].firstName}, ${members[1].firstName} && ${
      members.length - 2
    } others`;
    return (
      <Wrapper>
        <ImageWrapper>
          {members.slice(0, max).map(({ picture, pulseLevel, id }, index) => (
            <Flex
              sx={{
                flexDirection: 'column',
                alignItems: 'center',
                position: 'absolute',
                left: 16 * index + 'px',
                ':not(:last-child)': {
                  mr: 2,
                },
              }}
              key={id}
            >
              <Avatar src={picture} pulseLevel={pulseLevel} size="md" />
            </Flex>
          ))}
          <Text mt="56px" variant="pg.sm">
            {displayName}
          </Text>
        </ImageWrapper>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      {members.map(({ firstName, lastName, picture, pulseLevel, id }) => (
        <Flex
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: '80px',
            minWidth: '80px',
            ':not(:last-child)': {
              mr: 2,
            },
          }}
          key={id}
        >
          <Avatar src={picture} pulseLevel={pulseLevel} size="md" />
          <Text mt="3" variant="pg.sm">
            {firstName} {lastName}
          </Text>
        </Flex>
      ))}
    </Wrapper>
  );
};

export default ListImage;
