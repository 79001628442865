import React from 'react';
import { Flex } from 'rebass';
import { observer } from 'mobx-react-lite';
import { Spinner } from '@blueprintjs/core';

import { inboxStore } from '../../../stores/InboxStore';
import { userStore } from '../../../stores/UserStore';

import ActivityInbox from '../components/ActivityInbox';
import EmptyList from '../../UI/EmptyList';
import useWindowInfinityScroll from '../../../hooks/useWindowInfinityScroll';

const ActivitiesInboxTab: React.FC = observer(() => {
  const {
    getActivities,
    loadingActivities,
    activities,
    hasMoreActivities,
  } = inboxStore;
  const {
    account: { profileId },
  } = userStore;

  useWindowInfinityScroll(getActivities);

  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column" mt="2">
        {!!activities.length &&
          activities.map(p => (
            <ActivityInbox
              key={p.id + p.start}
              activity={p}
              profileId={profileId}
            />
          ))}
      </Flex>
      {!activities?.length && !loadingActivities && (
        <EmptyList text="activities" />
      )}
      {hasMoreActivities && loadingActivities && (
        <Spinner intent="primary" size={24} />
      )}
    </Flex>
  );
});

export default ActivitiesInboxTab;
