import React from 'react';
import { Box, Flex, Text, Image } from 'theme-ui';

import { IPartnerProfile, IVenue } from '../../../interface/Venue';

import { Icon } from '../../Primitives';

import { PageBody } from '../../Layout/Layout.styled';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { Session } from '../Venue.styled';
import { VenueCard } from '../../Cards';

const PartnerBody: React.FC<{
  partnerProfile: IPartnerProfile;
  venuesByPartner: IVenue[];
}> = observer(({ partnerProfile, venuesByPartner }) => {
  const history = useHistory();

  return (
    <PageBody>
      <Flex
        px="3"
        py="2"
        bg={partnerProfile?.brandColor || 'dark5'}
        sx={{
          height: '56px',
          zIndex: '1',
          alignItems: 'center',
          position: 'sticky',
          top: '0px',
        }}
      >
        <Icon
          icon="chevron-left"
          size="lg"
          fill="primaryText"
          onClick={() => {
            history.goBack();
          }}
        />
      </Flex>
      <Flex>
        {partnerProfile.logo ? (
          <Flex
            sx={{
              height: '200px',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              bg: partnerProfile?.brandColor || 'dark5',
              flexDirection: 'column',
            }}
          >
            <Image
              sx={{
                borderRadius: 9999,
              }}
              width="120px"
              src={partnerProfile.logo}
              mb="3"
            />
            <Text variant="lb.lg" color="white">
              {partnerProfile.brandName}
            </Text>
          </Flex>
        ) : (
          <Flex
            sx={{
              height: '200px',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            bg={partnerProfile?.brandColor || 'dark5'}
          >
            <Icon icon="camera" size="lg" stroke="white" />
            <Text color="white" variant="lb.lg" mb="1">
              No photo yet
            </Text>
          </Flex>
        )}
      </Flex>
      <Session
        p="3"
        mb="3"
        sx={{
          borderTop: 'box',
        }}
      >
        <Flex
          sx={{
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              textAlign: 'center',
            }}
          >
            <Text variant="lb.lg">{partnerProfile.gamesCount}</Text>
            <Text variant="pg.md">Games</Text>
          </Box>
          <Box
            sx={{
              textAlign: 'center',
            }}
          >
            <Text variant="lb.lg">{partnerProfile.followersCount}</Text>
            <Text variant="pg.md">Followers</Text>
          </Box>
        </Flex>
      </Session>
      <Session p="3">
        <Text variant="lb.lg">Venues</Text>
        {venuesByPartner.map((venue) => (
          <Box py="3" key={venue.id}>
            <VenueCard venue={venue} />
          </Box>
        ))}
      </Session>
    </PageBody>
  );
});

export default PartnerBody;
