import React, { useState } from 'react';
import { Box, Flex } from 'theme-ui';
import Carousel from '@brainhubeu/react-carousel';

import { Text, Icon, Button } from '../../../components/Primitives';
import styled from '../../../theme/styled';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { filterStore } from '../../../stores/FilterStore';

const Wrapper = styled.div`
  position: relative;
  .carousel-arrow {
    display: none;
  }
  :hover {
    .carousel-arrow {
      display: flex;
    }
  }
`;

type Props = {
  title: string;
  itemLength: number;
  slidesPerPage?: number;
  link: string;
  filters?: any;
};

const PresentCarousel: React.FC<Props> = observer(
  ({ children, title, itemLength, slidesPerPage = 1, link, filters }) => {
    const history = useHistory();

    const [value, setValue] = useState(0);

    const { setCustomFilter } = filterStore;

    function onClickNext() {
      if (value === itemLength - 1) return;
      setValue((v) => v + 1);
    }

    function onClickBack() {
      if (value === 0) return;
      setValue((v) => v - 1);
    }

    function onClickSeeAll() {
      if (!!filters) {
        setCustomFilter(filters);
      }
      history.push(link);
    }

    return (
      <Box>
        <Flex
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            px: '24px',
          }}
        >
          <Text variant="lb.lg">{title}</Text>
          <Button onClick={onClickSeeAll} variant="flat.primary">
            See all
          </Button>
        </Flex>

        <Wrapper>
          <Flex
            className="carousel-arrow"
            sx={{
              position: 'absolute',
              height: '100%',
              top: '0px',
              left: '0px',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: value === 0 ? 'none' : 'block',
                padding: '48px 8px',
                zIndex: 2,
                border: '1px solid',
                borderColor: 'border',
                borderLeft: 'none',
                borderTopRightRadius: '4px',
                bg: 'white',
                opacity: 0.9,
                borderBottomRightRadius: '4px',
                cursor: 'pointer',
                ':hover': {
                  color: 'primaryHover',
                },
              }}
              onClick={onClickBack}
            >
              <Icon icon="chevron-left" fill="primaryText" />
            </Box>
          </Flex>
          <Flex
            className="carousel-arrow"
            sx={{
              position: 'absolute',
              height: '100%',
              top: '0px',
              right: '0px',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: value === itemLength - 1 ? 'none' : 'block',
                padding: '48px 8px',
                zIndex: 2,
                border: '1px solid',
                borderColor: 'border',
                borderRight: 'none',
                borderTopLeftRadius: '4px',
                bg: 'white',
                opacity: 0.9,
                borderBottomLeftRadius: '4px',
                cursor: 'pointer',
                ':hover': {
                  color: 'primaryHover',
                },
              }}
              onClick={onClickNext}
            >
              <Icon icon="chevron-right" fill="primaryText" />
            </Box>
          </Flex>
          <Box>
            <Carousel
              clickToChange
              slidesPerPage={slidesPerPage}
              value={value}
              onChange={(value) => setValue(value)}
            >
              {children}
            </Carousel>
          </Box>
        </Wrapper>
      </Box>
    );
  }
);

export default PresentCarousel;
