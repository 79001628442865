import React from 'react';
import { IActivity } from '../../../interface/Activity';
import ActivityData from './ActivityData';
import { Box } from 'rebass';

type Props = {
  activity: IActivity;
  hideTag?: boolean;
  p?: string;
  noCard?: boolean;
  isWhiteText?: boolean;
};

const ActivityCard: React.FC<Props> = ({
  activity,
  hideTag,
  noCard,
  p,
  isWhiteText,
}) => {
  return (
    <Box
      variant={noCard ? '' : 'card'}
      p={p || 3}
      sx={{
        cursor: 'pointer',
        width: '100%',
      }}
    >
      <ActivityData
        activity={activity}
        hideTag={hideTag}
        isWhiteText={isWhiteText}
      />
    </Box>
  );
};

export default ActivityCard;
