import httpService from '../services/httpService';
import get from 'lodash/get';

export const getOrderById = async (orderId: string) => {
  try {
    const data = await httpService.get(`partners/orders/${orderId}`);
    const order = get(data, 'data.data');
    return order;
  } catch (err) {
    throw err;
  }
};

export const getCancelationPolicy = async (orderId: string) => {
  try {
    const response = await httpService.get(
      `partners/orders/${orderId}/cancelationPolicy`
    );
    return response?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const cancelFacilityBooking = async (
  bookingIds: string[],
  orderId: string,
  changeLogMeta?: {
    reason?: string;
    comment?: string;
  }
) => {
  const data = {
    bookingIds,
    changeLogMeta,
  };
  try {
    const response = await httpService.post(
      `partners/orders/${orderId}/cancelFacilityBookingsByCustomer`,
      data
    );
    return response?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const rescheduleFacilityBooking = async (
  orderId: string,
  fromFacilityBookings: { id: string }[],
  toFacilityBookings: {
    facilityId: string;
    startTime: string;
    endTime: string;
  }[]
) => {
  const data = {
    paymentMethod: 'cash',
    fromFacilityBookings,
    toFacilityBookings,
  };
  try {
    const response = await httpService.put(
      `partners/orders/${orderId}/facilityBookings`,
      data
    );
    return response?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const createOrGetPartnerTrainer = async (profileId: number) => {
  try {
    const response = await httpService.post(
      `partners/partners/trainers/${profileId}`,
      {}
    );
    return response.data?.data?.id;
  } catch (error) {}
};

export const getPaymentMethodsByCurrency = async (currency: string) => {
  try {
    const response = await httpService.get(`orders/paymentMethods/${currency}`);
    return response.data?.data;
  } catch (error) {
    throw error;
  }
};

export const setDefaultPaymentMethod = async (paymentMethodId: string) => {
  try {
    const response = await httpService.get(
      `orders/paymentMethods/${paymentMethodId}/setDefault`
    );
    return response.data?.data;
  } catch (error) {}
};

export const addPaymentMethodByCurrency = async (
  token: string,
  currency: string
) => {
  const data = {
    paymentMethod: 'creditCard',
    creditCardTokenInfo: {
      token,
    },
  };
  try {
    const response = await httpService.post(
      `orders/paymentMethods/${currency}`,
      data
    );
    return response.data?.data;
  } catch (error) {
    throw error;
  }
};

export type DryrunWorkoutPartyData = {
  paymentInfo: {
    paymentMethod: string;
    paymentMethodId?: string;
    creditCardTokenInfo?: {
      token?: string;
    };
  };
  customerInfo: {
    firstName: string;
    lastName: string;
    email?: string;
    mobile?: string | number;
  };
  lineItems: {
    targetType: string;
    targetId: string;
  }[];
  promoCode: string;
  currency: string;
};

export const dryrunJoinWorkoutParty = async (
  data: DryrunWorkoutPartyData,
  partnerId: string
) => {
  try {
    const response = await httpService.post(
      `partners/partners/${partnerId}/orders/dryRun`,
      data
    );
    return response.data?.data;
  } catch (error) {
    throw error;
  }
};

export const joinWorkoutParty = async (
  data: DryrunWorkoutPartyData,
  partnerId: string
) => {
  try {
    const response = await httpService.post(
      `partners/partners/${partnerId}/orders`,
      data
    );
    return response.data?.data;
  } catch (error) {
    throw error;
  }
};

export const captureJoinActivityPayment = async (activityId: string) => {
  try {
    const response = await httpService.post(
      `partners/payments/capture/joiningActivity/${activityId}`
    );
    return response.data;
  } catch (error) {
    // throw error;
  }
};

export const getOrderByTransactionId = async (transactionId: string) => {
  try {
    const response = await httpService.get(
      `partners/orders/${transactionId}/orderTransactions`
    );
    return response.data?.data;
  } catch (error) {}
};
