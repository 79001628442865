import React, { useEffect, useState } from 'react';
import { Box, Grid } from 'theme-ui';
import { useParams, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Navigation from '../../components/Layout/Navigation';
import FollowList from '../../components/Explore/FollowList';

import PremiumExplore from './PremiumExplore';
import PremiumBilling from './PremiumBilling';
import { Tabs, Tab } from '@blueprintjs/core';
import { Text } from '../../components/Primitives';
import Header from '../../components/Layout/Header';

const availableSections = ['billing', 'explore'];

const PremiumSection: React.FC = observer(() => {
  const param: { section?: string } = useParams();
  const history = useHistory();

  const [selectedTab, setSelectedTab] = useState('explore');

  useEffect(() => {
    if (param.section && availableSections.includes(param.section)) {
      setSelectedTab(param.section);
    }
  }, [param]);

  return (
    <Box variant="layout.main">
      <Header name="Subscription" noSecondaryIcon />
      <Box variant="layout.leftSide">
        <Navigation selected="premium" />
      </Box>
      <Box>
        <Grid
          columns={['1fr auto 1fr']}
          px="24px"
          sx={{
            height: '56px',
            alignItems: 'center',
            boxShadow: 'stroke.bottom',
            display: ['none', 'grid'],
          }}
        >
          <Box />
          <Text variant="lb.lg">Subscription</Text>
          <Box />
        </Grid>

        <Tabs
          id="premium-tabs"
          className="modify-tabs"
          renderActiveTabPanelOnly
          onChange={(id: string) => {
            setSelectedTab(id);
            history.push(`/premium/${id}`);
          }}
          selectedTabId={selectedTab}
        >
          <Tab
            id="explore"
            title={<Text p="2">Feature</Text>}
            panel={<PremiumExplore />}
          />
          <Tab
            id="billing"
            title={<Text p="2">Billing</Text>}
            panel={<PremiumBilling />}
          />
        </Tabs>
      </Box>
      <Box variant="layout.rightSide">
        <FollowList />
      </Box>
    </Box>
  );
});

export default PremiumSection;
