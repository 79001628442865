import React from 'react';
import { Dialog } from '@blueprintjs/core';
import { Box, Button, Flex } from 'rebass';

import Text from '../../UI/Text';

import styled from '../../../theme/styled';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: Function;
  title?: string;
  description?: string;
  cancelText?: string;
  confirmText?: string;
  buttonColors?: [string, string];
  children?: React.ReactChild;
}

const Wrapper = styled(Dialog)`
  margin: 0px;
  padding: 0px;
  width: 320px;
`;

const ActionWrapper = styled(Flex)`
  border-top: ${p => p.theme.border.searchInput};
  > button:first-of-type {
    border-right: ${p => p.theme.border.searchInput};
  }
`;

const ConfirmDialog: React.FC<Props> = ({
  onClose,
  onConfirm,
  title,
  description,
  isOpen,
  cancelText,
  confirmText,
  buttonColors = ['danger', 'primary'],
  children,
}) => {
  return (
    <Wrapper onClose={onClose} isOpen={isOpen}>
      <Box p="3" textAlign="center" color="primaryText">
        {title && (
          <Text mb="3" fontSize="16px" fontWeight="bold">
            {title}
          </Text>
        )}
        {description && <Text mb="2">{description}</Text>}
        {children}
      </Box>
      <ActionWrapper alignItems="center">
        <Button
          p="2"
          width="50%"
          variant="noPadding"
          fontWeight="bold"
          color={buttonColors[0]}
          onClick={onClose}
          sx={{
            borderRadius: '0px',
          }}
        >
          {cancelText || 'Cancel'}
        </Button>
        <Button
          p="2"
          width="50%"
          variant="noPadding"
          fontWeight="bold"
          color={buttonColors[1]}
          onClick={onConfirm}
        >
          {confirmText || 'Confirm'}
        </Button>
      </ActionWrapper>
    </Wrapper>
  );
};

export default ConfirmDialog;
