import cogoToast from 'cogo-toast';
import axios from 'axios';
import * as Sentry from '@sentry/browser';

import LocalStorageHelper from '../helpers/localStorage';
import config from '../config';

const statusCodes = [401, 403, 404, 502];

const nonRedirects = ['/sign-in', 'sign-up'];

const instance = axios.create();

const account = LocalStorageHelper.get('currentUser') || {};

let isServerIssue = false;

const session = account?.session;
instance.defaults.headers.common['Content-Type'] = 'application/json';
if (session) {
  instance.defaults.headers.common['Authorization'] = session;
}
instance.defaults.baseURL = config.API_HOST;

instance.interceptors.response.use(
  (response) => {
    isServerIssue = false;
    return response;
  },
  (error) => {
    if (
      error?.response?.status === 401 &&
      !nonRedirects.includes(window.location.pathname)
    ) {
      LocalStorageHelper.remove('currentUser');
      window.location.href = `/sign-in?re=${window.location.href}`;
    }
    if (!statusCodes.includes(error?.response?.status) && error?.response) {
      Sentry.withScope(function (scope) {
        scope.setTag('endpoint', window.location.href);
        scope.setExtras(error?.response);
        error.message = `Error code: ${error.response.status} in ${window.location.href}`;
        Sentry.captureException(error);
      });
    }

    if (error.request.status === 502 && !isServerIssue) {
      isServerIssue = true;
      error.message = 'Server issues. Please try again after 10 minutes.';
      return cogoToast.error(error.message);
    }

    return Promise.reject(error);
    // }
  }
);

export default instance;
