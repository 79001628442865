import React, { FunctionComponent, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Icon } from '../../Primitives';
import Text from '../../UI/Text';

import { filterStore } from '../../../stores/FilterStore';

import FilterSport from './FilterSport';
import FilterTypes from './FilterTypes';
import AdvanceOptions from './AdvanceOptions';

import { FilterWrapper, SelectWrapper } from './FilterBar.styled';

type Props = {
  selectedTab: string;
};

const FilterBar: FunctionComponent<Props> = observer(({ selectedTab }) => {
  const { selectedSports, sortTypes } = filterStore;

  const [isOpenSport, setSportFilterDialog] = useState(false);
  const [isOpenSortDialog, setSortDialog] = useState(false);
  const [isOpenAdvanceDialog, setAdvanceDialog] = useState(false);

  const numOfSports = selectedSports.length;

  const displaySport = () => {
    if (numOfSports === 0) return 'All sports';
    if (numOfSports === 1) return selectedSports[0];
    if (numOfSports > 1) return `${numOfSports} sports`;
  };

  return (
    <>
      <FilterWrapper height="50px">
        <SelectWrapper
          px={[2, 3]}
          flex="1"
          onClick={() => setSportFilterDialog(true)}
        >
          {displaySport()}
          <Icon icon="chevron-down" size="md" />
        </SelectWrapper>
        <SelectWrapper
          sx={{
            textTransform: 'capitalize',
          }}
          px={[2, 3]}
          flex="1"
          onClick={() => setSortDialog(true)}
        >
          {sortTypes[selectedTab]}
          <Icon icon="chevron-down" size="md" />
        </SelectWrapper>
        <SelectWrapper px={[2, 3]} onClick={() => setAdvanceDialog(true)}>
          <Text pr="3">Filter</Text>
          <Icon icon="controls-alt" size="md" />
        </SelectWrapper>
      </FilterWrapper>
      {isOpenSport && (
        <FilterSport
          isOpen={isOpenSport}
          onClose={() => setSportFilterDialog(false)}
        />
      )}
      {isOpenSortDialog && (
        <FilterTypes
          selectedTab={selectedTab}
          isOpen={isOpenSortDialog}
          onClose={() => setSortDialog(false)}
        />
      )}
      {isOpenAdvanceDialog && (
        <AdvanceOptions
          isOpen={isOpenAdvanceDialog}
          selectedTab={selectedTab}
          onClose={() => setAdvanceDialog(false)}
        />
      )}
    </>
  );
});

export default FilterBar;
