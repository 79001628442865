import React, { useState } from 'react';
import { Dialog } from '@blueprintjs/core';
import { Box, Text, Flex } from 'rebass';
import { Button } from 'rebass';
import Switch from '../Switch';
import { Textarea } from 'theme-ui';
import { sendTextComment } from '../../../api/InboxAPI';

interface Props {
  onLeave: () => void;
  isOpen: boolean;
  onClose: () => void;
  activityId: string;
}

const LeaveDialog: React.FC<Props> = ({
  onLeave,
  isOpen,
  onClose,
  activityId,
}) => {
  const [checked, setChecked] = useState(false);
  const [message, setMessage] = useState('');

  const handleOnLeave = () => {
    sendTextComment('PLAY', activityId, message);
    onLeave();
  };

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <Flex flexDirection="column" alignItems="center" p="3">
        <Box p="3">
          <Text variant="body.large" mb="3">
            Pulling out?
          </Text>
          <Text variant="body" mb="3">
            Leaving the game after committing is not very nice to host. If you
            have to leave, do ensure you let players know why you're pulling
            out.
          </Text>
          <Flex mb="2" alignItems="center">
            <Switch checked={checked} onChange={() => setChecked((s) => !s)} />
            <Text variant="body.large" ml="2">
              I've informed everyone on the reason for leaving the game
            </Text>
          </Flex>
          <Textarea
            placeholder="Tell to everyone why you left."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </Box>
        <Flex justifyContent="center">
          <Button variant="outline.normal" mr="1" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="outline"
            ml="1"
            disabled={!checked && !message}
            onClick={handleOnLeave}
            sx={{
              cursor: !checked && !message ? 'not-allowed' : 'pointer',
              opacity: !checked && !message ? '0.3' : '1',
            }}
          >
            Yes, leave game
          </Button>
        </Flex>
      </Flex>
    </Dialog>
  );
};

export default LeaveDialog;
