import React, { useState } from 'react';
import { Box } from 'theme-ui';

import CancelPremium from './components/CancelPremium';
import BillingPremium from './components/BillingPremium';

const PremiumBilling = () => {
  const [isCancel, setCancel] = useState(false);
  return (
    <Box>
      {isCancel ? (
        <CancelPremium setCancel={setCancel} />
      ) : (
        <BillingPremium setCancel={setCancel} />
      )}
    </Box>
  );
};

export default PremiumBilling;
