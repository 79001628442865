import { Flex } from 'rebass';

import styled from '../../theme/styled';

export const PageBody = styled(Flex)`
  z-index: 0;
  flex-direction: column;

  @media (min-width: 768px) {
    width: 600px;
  }
`;

export const PageHeading = styled(Flex)`
  position: sticky;
  top: 0;
  height: 56px;
  border-bottom: ${(p) => p.theme.colors.divider};
  background-color: ${(p) => p.theme.colors.white};
  z-index: 1;
`;

export const ColumnItem = styled(Flex)`
  align-items: center;
  border-bottom: ${(p) => p.theme.border.box};
  cursor: pointer;
  :hover {
    background-color: ${(p) => p.theme.colors.primaryHover};
  }
`;

export const GroupRequestItem = styled(Flex)`
  border-top: ${(p) => p.theme.border.box};
  border-bottom: ${(p) => p.theme.border.box};
  background-color: ${(p) => p.theme.colors.white};
  align-items: center;
  padding: 16px 8px;
`;
