import React from 'react';
import { Flex } from 'rebass';
import { Spinner } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';
import debounce from 'lodash/debounce';

import { SearchInput } from '../../UI/Input';

import { inboxStore } from '../../../stores/InboxStore';

import PeopleInbox from '../components/PeopleInbox';
import EmptyList from '../../UI/EmptyList';
import useWindowInfinityScroll from '../../../hooks/useWindowInfinityScroll';

const PeopleInboxTab: React.FC = observer(() => {
  const { getPeople, loadingPeople, people, hasMorePeople } = inboxStore;

  const searchPeople = debounce(value => {
    getPeople(value, true);
  }, 50);

  useWindowInfinityScroll(getPeople);

  return (
    <Flex flexDirection="column">
      <SearchInput
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          searchPeople(e.target.value)
        }
        placeholder="Search people in inbox"
      />
      <Flex flexDirection="column" mt="2">
        {!!people.length &&
          people.map(p => <PeopleInbox key={p.id} groupInbox={p} />)}
      </Flex>
      {!people?.length && !loadingPeople && <EmptyList text="people" />}
      {hasMorePeople && loadingPeople && <Spinner intent="primary" size={24} />}
    </Flex>
  );
});

export default PeopleInboxTab;
