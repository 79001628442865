import React, { FunctionComponent } from 'react';
import { Spinner } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';
import { Box } from 'theme-ui';

import { PeopleCard } from '../Cards';
import { IPeople } from '../../interface/People';

import { peopleStore } from '../../stores/PeopleStore';
import useWindowInfinityScroll from '../../hooks/useWindowInfinityScroll';
import EmptyList from '../UI/EmptyList';

type Props = {
  query: Object;
};

const PeopleTab: FunctionComponent<Props> = observer(({ query }) => {
  const { getPeople, people, hasMore, loading } = peopleStore;

  useWindowInfinityScroll(getPeople, query);

  return (
    <Box pb={['108px', 5]}>
      {people.map((person: IPeople) => (
        <Box mb="3">
          <PeopleCard key={person.id} person={person} />
        </Box>
      ))}
      {!people.length && !loading && <EmptyList text="people" />}
      {hasMore && loading && <Spinner size={24} intent="primary" />}
    </Box>
  );
});

export default PeopleTab;
