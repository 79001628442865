import React, { FunctionComponent } from 'react';
import { Box, Flex } from 'rebass';

import Avatar from '../../UI/Avatar';
import Text from '../../UI/Text';
import { Icon } from '../../Primitives';

import styled from '../../../theme/styled';
import { observer } from 'mobx-react-lite';
import { userStore } from '../../../stores/UserStore';
import { useStores } from '../../../hooks/useStore';

export const CardWrapper = styled(Flex)`
  position: relative;
  border-top: ${(p) => p.theme.border.box};
  border-bottom: ${(p) =>
    !p.isShowCommentInbox ? 'none' : p.theme.border.box};
  border-radius: 0px;
  width: 100%;
  background-color: ${(p) => p.theme.colors.white};
  :not(:last-child) {
    margin-bottom: 16px;
  }
`;

const HeaderWrapper = styled(Flex)`
  border-bottom: ${(p) => p.theme.border.box};
`;

const Header = styled(Text)`
  a,
  a:visited {
    color: inherit;
  }
  a:hover {
    text-decoration: none;
  }
`;

const CommentWrapper = styled(Box)`
  border-top: ${(p) => p.theme.border.box};
`;

const Comment = styled(Text)`
  cursor: pointer;
`;

const LikeCount = styled(Text)`
  :hover {
    text-decoration: underline;
  }
  cursor: pointer;
`;

const InputComment = styled.input`
  padding: 8px 16px;
  margin-left: 16px;
  font-size: 12px;
  border-radius: 16px;
  border: ${(p) => p.theme.border.searchInput};
  color: ${(p) => p.theme.colors.primaryText};
  opacity: 0.7;
  :focus {
    opacity: 1;
  }
  width: 100%;
  background-color: ${(p) => p.theme.colors.white};
`;

type HeaderProps = {
  picture: string;
  pulseLevel: string;
  header: string;
  time: string;
  onClick: Function;
};

export const CardHeader: FunctionComponent<HeaderProps> = ({
  picture,
  pulseLevel,
  header,
  time,
  onClick,
}) => {
  return (
    <HeaderWrapper
      justifyContent="space-between"
      px={[2, 3]}
      py="2"
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <Flex alignItems="center">
        <Box minWidth="32px">
          <Avatar imageLink={picture} pulseLevel={pulseLevel} size="40px" />
        </Box>
        <Box ml="2">
          <Header
            color="primaryText"
            dangerouslySetInnerHTML={{ __html: header }}
          />
          <Text fontSize="12px">about {time}</Text>
        </Box>
      </Flex>
    </HeaderWrapper>
  );
};

type CommentProps = {
  pulseLevel: string;
  numOfLike: number;
  numOfComment: number;
  userLiked: boolean;
  id: string;
  isSelf: boolean;
  isDetail?: boolean;
  onShare: () => void;
  onOpenLikeDetail: () => void;
  isShowCommentInbox?: boolean;
  isHideInteractive?: boolean;
  onClick: Function;
};

export const CardComment: FunctionComponent<CommentProps> = observer(
  ({
    pulseLevel,
    numOfLike,
    numOfComment,
    userLiked,
    id,
    isSelf,
    isDetail,
    onShare,
    onOpenLikeDetail,
    isShowCommentInbox,
    isHideInteractive,
    onClick,
  }) => {
    const {
      account: { profile },
      updateActivityLike,
    } = userStore;
    const { postDetailStore } = useStores();

    const { picture } = profile;

    const handleLike = () => {
      if (isDetail) {
        postDetailStore.updateLike(id, !userLiked);
      } else {
        updateActivityLike(id, !userLiked, numOfLike);
      }
    };

    return (
      <CommentWrapper
        pb={isShowCommentInbox ? '3' : ''}
        px={[2, 3]}
        onClick={onClick}
      >
        {!isHideInteractive && (
          <Flex justifyContent="space-between" alignItems="center">
            <Flex width="100px" py="2">
              <Box mr="2">
                <Icon
                  icon={userLiked ? 'heart-colored' : 'favorite'}
                  size="lg"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleLike();
                  }}
                />
              </Box>
              <Box mr="2">
                <Icon icon="chat" size="lg" onClick={() => onClick} />
              </Box>
              <Icon
                icon="share-android"
                size="lg"
                onClick={(e) => {
                  e.stopPropagation();
                  onShare();
                }}
              />
            </Flex>
            {!!numOfLike && (
              <LikeCount color="primaryText" mr="2" onClick={onOpenLikeDetail}>
                {numOfLike > 1 ? `${numOfLike} likes` : `${numOfLike} like`}
              </LikeCount>
            )}
          </Flex>
        )}
        {!!numOfComment && isShowCommentInbox && (
          <Comment pb="1" fontSize="12px">
            View&nbsp;
            {numOfComment > 1
              ? `${numOfComment} comments`
              : `${numOfComment} comment`}
          </Comment>
        )}
        {isShowCommentInbox && (
          <Flex alignItems="center">
            <Avatar imageLink={picture} pulseLevel={pulseLevel} size="32px" />
            <InputComment placeholder="Add a comment" />
          </Flex>
        )}
      </CommentWrapper>
    );
  }
);
