import cogoToast from 'cogo-toast';
import Geocode from 'react-geocode';
import config from '../config';

export default function getGeolocation(saveLocation: Function) {
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  function success(pos: { coords: { latitude: number; longitude: number } }) {
    Geocode.fromLatLng(
      pos.coords.latitude.toString(),
      pos.coords.longitude.toString(),
      config.GOOGLE_MAP_API_KEY
    ).then(
      (response: {
        results: {
          address_components: {
            long_name: string;
          }[];
        }[];
      }) => {
        const address = response.results[0]?.address_components[0]?.long_name;
        if (address) {
          saveLocation({
            radius: 20,
            latitude: pos.coords.latitude,
            longitude: pos.coords.latitude,
            name: address,
          });
        }
      },
      (error: Error) => {
        cogoToast.error(error);
      }
    );
  }

  function error(err: { code: number; message: string }) {
    cogoToast.warn(err.message);
  }
  navigator.geolocation.getCurrentPosition(success, error, options);
}
