import React from 'react';
import { Flex, Image } from 'rebass';
import { Instagram } from 'react-content-loader';

import Text from '../UI/Text';
import IActivityTimeline from '../../interface/ActivityTimeline';
import { IPeople } from '../../interface/People';

import ScoreCard from './Card/ScoreCard';
import PostCard from './Card/PostCard';
import HostCard from './Card/HostCard';
import AwardCard from './Card/AwardCard';
import InteractiveCard from './Card/InteractiveCard';
import CardContainer from './Card/CardContainer';

import PrivateProfileImg from '../../assets/private-profile.png';
import EmptyTimelineImg from '../../assets/empty-timeline.png';
import { useHistory } from 'react-router-dom';

const displayActivities = [
  'post',
  'play',
  'host',
  'join',
  'award',
  'comment',
  'like',
];

const Timeline: React.FC<{
  profile: IPeople;
  timeline: { activities: IActivityTimeline[]; lastActivityId: string | null };
  isSelf: boolean;
  timelineLoading: boolean;
}> = ({ profile, timeline, isSelf, timelineLoading }) => {
  const { activities, lastActivityId } = timeline;
  const { isPrivate, firstName } = profile;

  const renderCard = (item: any) => {
    const { verb } = item;
    switch (verb) {
      case 'post':
        return <PostCard key={item.id} item={item} />;
      case 'play':
        return <ScoreCard key={item.id} item={item} />;
      case 'host':
      case 'join':
        return <HostCard key={item.id} item={item} />;
      case 'award':
        return <AwardCard key={item.id} item={item} />;
      default:
        return null;
    }
  };

  const displayTimeline = activities.filter(t =>
    displayActivities.includes(t.verb)
  );
  const history = useHistory();

  if (isPrivate && !isSelf)
    return (
      <Flex
        justifyContent="center"
        p="4"
        alignItems="center"
        flexDirection="column"
      >
        <Image src={PrivateProfileImg} width="80px" mb="2" />
        <Text fontWeight="bold" color="primaryText">
          {firstName}'s activities is private
        </Text>
        <Text fontWeight="bold">Follow {firstName} to see the activities</Text>
      </Flex>
    );

  return (
    <Flex flexDirection="column" mb="5">
      {!!displayTimeline.length &&
        displayTimeline.map(item => {
          const link = `/${
            item.actor.objectType === 'partner'
              ? item.actor.rawObject.pageInfo.uri
              : '@' + item.actor.rawObject.username
          }/activities/${item.id}`;
          if (['comment', 'like'].includes(item.verb)) {
            return (
              <InteractiveCard
                key={item.id}
                item={item}
                isSelf={isSelf}
                isShowCommentInbox
              />
            );
          }
          return (
            <CardContainer
              key={item.id}
              item={item}
              isSelf={isSelf}
              onClick={() => history.push(link)}
              isShowCommentInbox
            >
              {renderCard(item)}
            </CardContainer>
          );
        })}
      {!displayTimeline.length && !timelineLoading && (
        <Flex flexDirection="column" alignItems="center">
          <Image src={EmptyTimelineImg} width="200px" mb="3" />
          <Text>
            {isSelf
              ? `You haven't post anything yet`
              : `${firstName} haven't post anything yet.`}
          </Text>
        </Flex>
      )}
      {timelineLoading && lastActivityId !== null && <Instagram />}
    </Flex>
  );
};

export default Timeline;
