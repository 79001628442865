import React from 'react';
import { PrimaryDialog } from '../UI/Dialog';
import { observer } from 'mobx-react-lite';
import { IActivity } from '../../interface/Activity';
import { Box } from 'rebass';
import SearchPlayers from './SearchPlayers';
import { useStores } from '../../hooks/useStore';
interface Props {
  isOpen: boolean;
  onClose: () => void;
  activity: IActivity;
}

const ReservePlayers = observer(({ isOpen, onClose, activity }: Props) => {
  const { inviteStore } = useStores();
  const { invitedPlayers, reservePlayers } = inviteStore;

  return (
    <PrimaryDialog
      title="Reserve Players"
      isOpen={isOpen}
      onClose={onClose}
      actionBtn="Reserve"
      onClickActionBtn={() => reservePlayers(activity.id)}
    >
      <Box bg="white">
        <SearchPlayers
          text="Reserve"
          activity={activity}
          invitedPlayers={invitedPlayers}
        />
      </Box>
    </PrimaryDialog>
  );
});

export default ReservePlayers;
