import React, { useState } from 'react';
import { Flex } from 'rebass';
import { useForm } from 'react-hook-form';

import Text from '../UI/Text';
import Link from '../UI/Link';
import { Input } from '../UI/Input';

import FireBaseService from '../../services/firebaseService';
import { Button } from '../Primitives';
import AuthLayout from './components/Layout';
import { Box } from 'rebass';
import { SignUpBtn } from './styled';

type FormData = {
  email: string;
};

const LoginPage: React.FC = () => {
  const [serverErr, setServerErr] = useState({
    message: '',
    code: '',
    isError: false,
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, errors } = useForm<FormData>();

  const onSubmit = handleSubmit(async ({ email }) => {
    setLoading(true);
    try {
      const response = await FireBaseService.sendEmailToResetPassword(email);
      setSuccessMessage(response.message);
      setLoading(false);
    } catch (err) {
      setServerErr({ ...err, isError: true });
      setLoading(false);
    }
  });

  return (
    <AuthLayout formName="Reset your password">
      {!!successMessage ? (
        <Text variant="hd.lg" textAlign="center">
          Done! Check your email inbox for instructions to reset your password.
        </Text>
      ) : (
        <Flex as="form" flexDirection="column" width="100%" onSubmit={onSubmit}>
          <Box mb="2">
            <Text variant="lb.md" mb="2">
              Email
            </Text>
            <Input
              disabled={loading}
              name="email"
              type="email"
              placeholder="Enter your email"
              ref={register({ required: true })}
              isError={!!errors.email}
            />
          </Box>
          {errors.email && <Text color="danger">Email is required</Text>}
          {serverErr.code === 'auth/user-not-found' ||
          serverErr.code === 'auth/wrong-password' ||
          serverErr.code === 'auth/invalid-email' ? (
            <Text color="danger" mt="2">
              You have entered an invalid email or password. If you signed up
              using Facebook, please try logging in using Facebook. For further
              assistance, please contact us at{' '}
              <a
                href="mailto:support@rovo.co?Subject=Login Issues"
                target="_blank"
                rel="noopener noreferrer"
              >
                support@rovo.co
              </a>
            </Text>
          ) : (
            !!serverErr.message && (
              <Text color="danger" mt="2">
                {serverErr.message}
              </Text>
            )
          )}
          <Button loading={loading} type="submit" my="3" size="lg">
            <Text variant="lb.md" color="white">
              Send reset password link
            </Text>
          </Button>
          <Flex alignItems="center" mb="3">
            <Box height="1px" bg="#D1DCE8" flex="1" />
            <Text variant="lb.md" mx="2">
              Or back to
            </Text>
            <Box height="1px" bg="#D1DCE8" flex="1" />
          </Flex>
          <Flex>
            <SignUpBtn flex="1" mr="2">
              <Link to="/sign-in">
                <Text variant="lb.md" color="#0ac2cc">
                  Log in
                </Text>
              </Link>
            </SignUpBtn>
            <SignUpBtn flex="1" mr="2">
              <Link to="/sign-up">
                <Text variant="lb.md" color="#0ac2cc">
                  Sign up
                </Text>
              </Link>
            </SignUpBtn>
          </Flex>
        </Flex>
      )}
    </AuthLayout>
  );
};

export default LoginPage;
