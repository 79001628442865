import React, { useEffect } from 'react';

import SearchPage from '../components/Explore/Search';
import { trackPageView } from '../services/amplitudeService';

const Search: React.FC = () => {
  useEffect(() => {
    trackPageView('Search');
  }, []);

  return <SearchPage />;
};

export default Search;
