import Money from 'js-money';

export const decimalToCents = (decimalAmount: number, currency: string) => {
  if (!currency) {
    return 0;
  }
  try {
    const formatedAmount = Money.fromDecimal(decimalAmount, currency, Math.ceil)
      .amount;
    return formatedAmount;
  } catch (error) {
    return 0;
  }
};

export const centsToDecimal = (centsAmount: number, currency: string) => {
  if (!currency) {
    return 0;
  }
  const roundedAmount = Math.round(centsAmount);
  try {
    const formatedAmount = Money.fromInteger(
      roundedAmount,
      currency
    ).toDecimal();
    return formatedAmount;
  } catch (error) {
    return 0;
  }
};
