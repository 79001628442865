import { decorate, observable, action } from 'mobx';
import { DEFAULT_LOCATION } from '../constant';

import { SORT_OPTIONS } from '../helpers/search';

type Location = {
  latitude: number;
  longitude: number;
  placeId: string;
  radius: number;
  name: string;
};

type Query = {
  selectedSports: string[];
  sortTypes: { [index: string]: string };
  locations: Location[];
  skillLevelRange: [number, number];
  scheduleList: number[];
  isShowFullActivities: boolean;
  isShowSameGender: boolean;
};

class FilterStore {
  selectedSports: string[] = [];

  sortTypes: { [index: string]: string } = {
    activities: SORT_OPTIONS.activities[0],
    people: SORT_OPTIONS.people[0],
    groups: SORT_OPTIONS.groups[0],
    venues: SORT_OPTIONS.venues[0],
    classes: SORT_OPTIONS.classes[1],
  };

  locations: Location[] = [DEFAULT_LOCATION];

  skillLevelRange: [number, number] = [1, 7];

  scheduleList: number[] = [...Array(21).keys()];

  isShowFullActivities: boolean = false;

  isShowSameGender: boolean = false;

  customFilters: any;

  currentActive: string = 'explore';

  exploreQuery: Query = {
    selectedSports: [],
    sortTypes: {
      activities: SORT_OPTIONS.activities[0],
      people: SORT_OPTIONS.people[0],
      groups: SORT_OPTIONS.groups[0],
      venues: SORT_OPTIONS.venues[0],
      classes: SORT_OPTIONS.classes[1],
    },
    locations: [DEFAULT_LOCATION],
    skillLevelRange: [1, 7],
    scheduleList: [...Array(21).keys()],
    isShowFullActivities: false,
    isShowSameGender: false,
  };

  initQuery: Query = {
    selectedSports: [],
    sortTypes: {
      activities: SORT_OPTIONS.activities[0],
      people: SORT_OPTIONS.people[0],
      groups: SORT_OPTIONS.groups[0],
      venues: SORT_OPTIONS.venues[2],
      classes: SORT_OPTIONS.classes[1],
    },
    locations: [],
    skillLevelRange: [1, 7],
    scheduleList: [...Array(21).keys()],
    isShowFullActivities: false,
    isShowSameGender: false,
  };

  resetQuery = (currentPage: string) => {
    if (this.currentActive !== currentPage) {
      this.exploreQuery = {
        selectedSports: this.selectedSports,
        sortTypes: this.sortTypes,
        locations: this.locations,
        skillLevelRange: this.skillLevelRange,
        scheduleList: this.scheduleList,
        isShowFullActivities: this.isShowFullActivities,
        isShowSameGender: this.isShowSameGender,
      };
      this.currentActive = currentPage;
    }
    this.selectedSports = this.initQuery.selectedSports;
    this.sortTypes = this.initQuery.sortTypes;
    this.locations = this.initQuery.locations;
    this.skillLevelRange = this.initQuery.skillLevelRange;
    this.scheduleList = this.initQuery.scheduleList;
    this.isShowFullActivities = this.initQuery.isShowFullActivities;
    this.isShowSameGender = this.initQuery.isShowFullActivities;
  };

  setExploreQuery = () => {
    this.currentActive = 'explore';
    this.selectedSports = this.exploreQuery.selectedSports;
    this.sortTypes = this.exploreQuery.sortTypes;
    this.locations = this.exploreQuery.locations;
    this.skillLevelRange = this.exploreQuery.skillLevelRange;
    this.scheduleList = this.exploreQuery.scheduleList;
    this.isShowFullActivities = this.exploreQuery.isShowFullActivities;
    this.isShowSameGender = this.exploreQuery.isShowFullActivities;
  };

  setSelectedSports = (sports: string[]) => {
    this.selectedSports = sports;
  };

  selectSortType = (type: string, tabId: string) => {
    const id = tabId.split('-')[0];
    this.sortTypes = {
      ...this.sortTypes,
      [id]: type,
    };
  };

  setCurrentLocation = (location: Location) => {
    this.locations[0] = location;
  };

  setLocations = (locations: Location[]) => {
    this.locations = locations;
  };

  setSkillLevel = (skillLevelRange: [number, number]) => {
    this.skillLevelRange = skillLevelRange;
  };

  setScheduleList = (schedule: number[]) => {
    this.scheduleList = schedule;
  };

  setShowFullActivities = (value: boolean) => {
    this.isShowFullActivities = value;
  };

  setFilterGender = (value: boolean) => {
    this.isShowSameGender = value;
  };

  setCustomFilter = (customFilters: any) => {
    this.customFilters = customFilters;
  };
}

decorate(FilterStore, {
  selectedSports: observable,
  sortTypes: observable,
  locations: observable,
  skillLevelRange: observable,
  isShowFullActivities: observable,
  isShowSameGender: observable,
  setSelectedSports: action,
  selectSortType: action,
  setCurrentLocation: action,
  setLocations: action,
  setSkillLevel: action,
  setScheduleList: action,
  setShowFullActivities: action,
  setFilterGender: action,
  setExploreQuery: action,
  resetQuery: action,
});

export const filterStore = new FilterStore();
