import { decorate, observable, action, runInAction } from 'mobx';
import { userStore } from './UserStore';
import { updateFollow } from './../api/ProfileAPI';
import { searchProfiles } from '../api/OldSearch';
import { IPeople } from '../interface/People';
import { SEARCH_LIMIT } from '../constant';

class SearchFriendStore {
  people: IPeople[] = [];

  loading: boolean = false;

  hasMore: boolean = true;

  query: {
    offset: number;
    limit: number;
  } = { offset: 0, limit: SEARCH_LIMIT };

  getPeople = async (query?: { query: string }, isReset?: boolean) => {
    if (this.loading) return;
    if (query && !query.query) return;
    if (!this.hasMore && !isReset) return;
    if (isReset) this.query.offset = 0;
    this.loading = true;
    let people: IPeople[] = [];
    try {
      people = await searchProfiles({
        ...this.query,
        ...query,
      });
    } catch { }
    const dataLength = people.length;
    runInAction(() => {
      if (dataLength !== SEARCH_LIMIT) this.hasMore = false;
      if (!isReset) {
        this.people = [...this.people, ...people];
      } else {
        this.people = people;
      }
      this.loading = false;
      this.query.offset =
        dataLength === SEARCH_LIMIT
          ? this.query.offset + dataLength
          : this.query.offset;
    });
  };

  resetSearch = () => {
    this.query.offset = 0;
    this.hasMore = true;
  }

  updateFollow = async (person: IPeople) => {
    const { followStatus, id, isPrivate } = person;
    const isFollow = followStatus !== 'NOT_FOLLOWING';
    const selectedFollowerIndex = this.people.findIndex(f => f.id === id)
    const following = userStore.account.profile.following
    runInAction(() => {
      this.people[selectedFollowerIndex].followStatus = !isFollow ? isPrivate ? 'REQUESTED' : 'FOLLOWING' : 'NOT_FOLLOWING'
      userStore.account.profile.following = isPrivate ? following : !isFollow ? following + 1 : following - 1;
    })
    try {
      await updateFollow(id, isFollow);
    } catch (error) {
      runInAction(() => {
        this.people[selectedFollowerIndex].followStatus = followStatus
        userStore.account.profile.following = following
      })
    }
  }

}

decorate(SearchFriendStore, {
  people: observable,
  loading: observable,
  hasMore: observable,
  getPeople: action,
  updateFollow: action,
});

export const searchFriendStore = new SearchFriendStore();
