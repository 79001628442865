import React from 'react';
import { Box, Flex } from 'rebass';
import { useMediaQuery } from 'react-responsive';

import Text from '../../UI/Text';

import { HeaderMobile } from '../../Layout/Header';

import styled from '../../../theme/styled';
import { Icon } from '../../Primitives';

const LangItem = styled(Flex)`
  cursor: pointer;
  :hover {
    background-color: ${p => p.theme.colors.primaryHover};
  }
`;

const Language: React.FC<{ language?: string }> = ({ language = 'en-US' }) => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  return (
    <Flex flexDirection="column">
      <HeaderMobile
        text="Change language"
        hasBackBtn={isMobile}
        backLink="/settings"
      />
      <Box py="3">
        <Text px="3" mb="3" color="primaryText" fontWeight="bold">
          Currently we only support English on web
        </Text>
        <LangItem
          px="3"
          py="2"
          color="primaryText"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text>English</Text>
          <Icon icon="ok" stroke="primary" size="md" />
        </LangItem>
      </Box>
    </Flex>
  );
};

export default Language;
