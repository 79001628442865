import { Box } from 'rebass';
import styled from '../../theme/styled';

export const Session = styled(Box)`
  border-top: ${p => p.theme.border.box};
  border-bottom: ${p => p.theme.border.box};
`;

export const InfoItem = styled(Box)`
  :not(:last-child) {
    border-bottom: ${p => p.theme.border.box};
  }
  flex: 1;
`;
