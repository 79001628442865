import { Box, Button, Flex } from 'rebass';

import styled from '../../../theme/styled';
import { SearchInput } from '../../UI/Input';

export const SearchWrapper = styled(Flex)`
  position: relative;
  padding: 8px;
  @media (min-width: 768px) {
    display: flex;
    position: sticky;
    top: 0px;
    background-color: #fafafa;
    z-index: 10;
    padding: 8px 16px;
    border-bottom: 1px solid ${p => p.theme.colors.divider};
  }
`;

export const InputWrapper = styled(Box)`
  position: relative;
  flex: 1;
`;

export const BackBtn = styled(Button)`
  border-radius: 100%;
`;

export const Menu = styled(Flex)`
  @media (min-width: 768px) {
    position: absolute;
    height: inherit;
    box-shadow: 4px 4px 20px ${p => p.theme.colors.primaryText};
  }
  height: 100vh;
  top: 48px;
  left: 0px;
  position: fixed;
  border-radius: 8px;
  background-color: #fff;
  width: 100%;
  z-index: 2;
  flex-direction: column;
  margin-top: 4px;
`;

export const Item = styled(Flex)`
  border-bottom: 1px solid ${p => p.theme.colors.border};
  :last-child {
    border-bottom: none;
  }
  color: ${p => p.theme.colors.primaryText};
  padding: 8px 16px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  :hover {
    background-color: ${p => p.theme.colors.primaryHover};
  }
`;

export const MobileSearchInput = styled(SearchInput)`
  border-radius: 0px;
`;
