import React, { useState, useEffect } from 'react';
import { Box } from 'theme-ui';
import { useParams } from 'react-router-dom';

import Navigation from '../Layout/Navigation';
import FollowList from '../Explore/FollowList';

import NotificationBody from './components/NotificationBody';
import GroupJoinBody from './components/GroupJoinBody';
import FollowBody from './components/FollowBody';

const NotificationPage: React.FC = () => {
  const [path, setPath] = useState<string | undefined>();
  const param: { path?: string } = useParams();

  useEffect(() => {
    setPath(param.path);
  }, [param]);

  return (
    <Box
      variant="layout.main"
      sx={{
        gap: '0px',
      }}
    >
      <Box variant="layout.leftSide">
        <Navigation selected="notifications" hideOnMobile />
      </Box>
      {!path && <NotificationBody />}
      {path === 'groups' && <GroupJoinBody />}
      {path === 'follows' && <FollowBody />}
      <Box variant="layout.rightSide">
        <FollowList />
      </Box>
    </Box>
  );
};

export default NotificationPage;
