import React, { useEffect } from 'react';

import ExplorePage from '../components/Explore';
import { trackPageView } from '../services/amplitudeService';

const Explore: React.FC = () => {
  useEffect(() => {
    trackPageView('Explore');
  }, []);

  return <ExplorePage />;
};

export default Explore;
