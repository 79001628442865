import React from 'react';
import ContentLoader from 'react-content-loader';

export const ProfileLoading = () => (
  <ContentLoader height={600} width={600} speed={2}>
    <rect x="116" y="84" rx="4" ry="4" width="117" height="6" />
    <rect x="116" y="31" rx="3" ry="3" width="350" height="6" />
    <rect x="116" y="46" rx="3" ry="3" width="380" height="6" />
    <rect x="116" y="65" rx="3" ry="3" width="167" height="6" />
    <circle cx="62" cy="58" r="40" />
    <rect x="27" y="132" rx="16" ry="16" width="552" height="37" />
    <rect x="28" y="190" rx="4" ry="4" width="95" height="150" />
    <rect x="149" y="190" rx="4" ry="4" width="227" height="151" />
    <rect x="401" y="190" rx="4" ry="4" width="174" height="151" />
  </ContentLoader>
);

export const AllTabInboxLoading = () => {
  return (
    <ContentLoader height={475} width={600} speed={2}>
      <rect x="16" y="11" rx="4" ry="4" width="100" height="13" />
      <rect x="16" y="38" rx="5" ry="5" width="100" height="100" />
      <rect x="132" y="38" rx="5" ry="5" width="100" height="100" />
      <rect x="16" y="166" rx="4" ry="4" width="100" height="13" />
      <circle cx="36" cy="212" r="19" />
      <rect x="70" y="197" rx="4" ry="4" width="247" height="32" />
      <circle cx="36" cy="265" r="19" />
      <circle cx="36" cy="318" r="19" />
      <rect x="70" y="248" rx="4" ry="4" width="247" height="32" />
      <rect x="70" y="300" rx="4" ry="4" width="247" height="32" />
    </ContentLoader>
  );
};

export const NotificationLoading = () => {
  return (
    <ContentLoader
      height={60}
      width={600}
      speed={2}
      backgroundColor="#E9EEF1"
      foregroundColor="#fafafa"
    >
      <rect x="60" y="26" rx="0" ry="0" width="100" height="6" />
      <rect x="60" y="5" rx="0" ry="0" width="500" height="16" />
      <circle cx="28" cy="24" r="24" />
    </ContentLoader>
  );
};

export const GroupJoinRequestLoading = () => {
  return (
    <ContentLoader
      height={120}
      width={600}
      speed={2}
      backgroundColor="#E9EEF1"
      foregroundColor="#fafafa"
    >
      <rect x="76" y="45" rx="0" ry="0" width="106" height="17" />
      <circle cx="34" cy="53" r="24" />
      <rect x="384" y="8" rx="4" ry="4" width="106" height="36" />
      <rect x="384" y="56" rx="4" ry="4" width="106" height="36" />
    </ContentLoader>
  );
};

export const ActivityLoading = () => {
  return (
    <ContentLoader
      height={500}
      width={600}
      speed={2}
      backgroundColor="#E9EEF1"
      foregroundColor="#fafafa"
    >
      <rect x="16" y="5" rx="0" ry="0" width="150" height="18" />
      <circle cx="42" cy="121" r="24" />
      <rect x="16" y="32" rx="0" ry="0" width="570" height="18" />
      <rect x="17" y="61" rx="0" ry="0" width="150" height="14" />
      <circle cx="107" cy="120" r="24" />
      <circle cx="168" cy="120" r="24" />
      <circle cx="228" cy="120" r="24" />
      <rect x="19" y="154" rx="0" ry="0" width="550" height="18" />
      <rect x="21" y="177" rx="0" ry="0" width="43" height="18" />
      <rect x="75" y="178" rx="0" ry="0" width="43" height="18" />
      <circle cx="30" cy="219" r="9" />
      <rect x="50" y="229" rx="0" ry="0" width="240" height="12" />
      <circle cx="30" cy="272" r="9" />
      <rect x="50" y="264" rx="0" ry="0" width="240" height="12" />
      <rect x="50" y="210" rx="0" ry="0" width="240" height="12" />
    </ContentLoader>
  );
};

export const FacilityBookingLoading = () => (
  <ContentLoader
    height={150}
    width={600}
    speed={2}
    backgroundColor="#E9EEF1"
    foregroundColor="#fafafa"
  >
    <rect x="16" y="5" rx="0" ry="0" width="150" height="18" />
    <rect x="16" y="32" rx="0" ry="0" width="246" height="18" />
    <rect x="16" y="64" rx="0" ry="0" width="150" height="14" />
    <rect x="16" y="110" rx="0" ry="0" width="43" height="17" />
    <rect x="16" y="85" rx="0" ry="0" width="150" height="14" />
    <rect x="76" y="111" rx="0" ry="0" width="218" height="17" />
  </ContentLoader>
);
