import React from 'react';
import { Flex } from 'rebass';
import css from '@emotion/css';

import Text from '../../../UI/Text';
import { Icon } from '../../../Primitives';

import { CurrentSport } from './AddSport';

import styled from '../../../../theme/styled';

const Image = styled(Flex)`
  position: relative;
  width: 100%;
  height: 120px;
  background-image: url(${p => p.uri});
  background-size: cover;
  border-radius: 8px;
  cursor: pointer;
  :hover {
    background: linear-gradient(314.43deg, #b7ce62 0%, #06bebd 100%),
      url(${p => p.uri});
    background-size: cover;
    background-blend-mode: overlay;
  }
  ${p =>
    p.selected &&
    css`
      background: linear-gradient(314.43deg, #b7ce62 0%, #06bebd 100%),
        url(${p.uri});
      background-size: cover;
      background-blend-mode: overlay;
    `}
`;

const TickIcon = styled(Icon)`
  position: absolute;
  right: 8px;
  bottom: 8px;
`;

type Props = {
  index: number;
  id: string;
  name: string;
  uri: string;
  currentSports: CurrentSport[];
  setCurrentSports: Function;
};

const SportImage: React.FC<Props> = ({
  index,
  id,
  name,
  uri,
  currentSports,
  setCurrentSports,
}) => {
  const selected = currentSports.findIndex(sport => sport.sport === id) > -1;

  const toggleSport = () => {
    if (selected) {
      const updatedSports = currentSports.filter(sport => sport.sport !== id);
      return setCurrentSports(updatedSports);
    } else {
      setCurrentSports([
        ...currentSports,
        { sport: id, name, level: 'INTERMEDIATE' },
      ]);
    }
  };
  return (
    <Flex
      flexDirection="column"
      width="50%"
      key={id}
      pr={index % 2 === 0 ? '2' : '0'}
      pb="2"
      onClick={toggleSport}
    >
      <Image
        justifyContent="center"
        alignItems="center"
        selected={selected}
        uri={`https://s3-ap-southeast-1.amazonaws.com/prod.rovo.co/assets/sports/sport-${uri}-background.jpg`}
      >
        <Text
          color="white"
          fontWeight="bold"
          fontSize="20px"
          textAlign="center"
        >
          {name.toUpperCase()}
        </Text>
        {selected && <TickIcon icon="ok" stroke="white" />}
      </Image>
    </Flex>
  );
};

export default SportImage;
