import React, { useState, useRef } from 'react';
import { Box, Flex, Input, Spinner } from 'theme-ui';

import { Text, Button, Icon } from '../../../components/Primitives';
import { CreditCard } from '../../../components/Payment';
import { IPremiumPlan } from '../../../interface/Utils';
import { observer } from 'mobx-react-lite';
import { premiumStore } from '../../../stores/PremiumStore';
import { userStore } from '../../../stores/UserStore';
import cogoToast from 'cogo-toast';
import { centsToDecimal } from '../../../helpers/currency';

type Props = {
  currency?: string;
};

enum BillingPeriod {
  'MONTHLY' = 'month',
  'ANNUAL' = 'year',
}

enum DurationPeriod {
  'MONTHS' = 'month',
  'DAYS' = 'week',
  'YEARS' = 'year',
  'UNLIMITED' = '',
}

const PremiumDetails = observer(({ currency = 'USD' }: Props) => {
  const { account } = userStore;
  const {
    dryrunPurchasePremium,
    purchasePremium,
    plans,
    state,
    changeState,
    product,
  } = premiumStore;

  const [plan, setPlan] = useState(plans[0]);
  const [isDryrun, setIsDryrun] = useState(false);
  const [promoState, setPromoState] = useState<'INITIAL' | 'ENTER' | 'DONE'>(
    'INITIAL'
  );
  const [promoCode, setPromoCode] = useState('');
  const [totalPriceCents, setTotalPriceCents] = useState<{
    price: number;
    currency: string;
  }>({ price: 0, currency: 'USD' });

  const [dryrunPlan, setDryrunPlan] = useState(plans[0]);

  const ref = useRef<HTMLFormElement>();

  const trialPhase = dryrunPlan?.phases.find((phase) => phase.type === 'TRIAL');

  const trialTime = trialPhase
    ? trialPhase.duration.number > 1 && trialPhase.duration.unit !== 'DAYS'
      ? DurationPeriod[trialPhase.duration.unit] + 's'
      : trialPhase.duration.number > 7
      ? DurationPeriod[trialPhase.duration.unit] + 's'
      : DurationPeriod[trialPhase.duration.unit]
    : '';

  const billedToday = trialPhase?.prices.length
    ? trialPhase.prices.find((price) => price.currency === currency)
    : trialPhase?.fixedPrices.find((price) => price.currency === currency);

  function onChangePlan(p: IPremiumPlan) {
    dryrunOrder(p);
    setPlan(p);
  }

  async function applyPromo() {
    try {
      await dryrunOrder(plan);
      setPromoState('DONE');
    } catch (error) {
      setPromoState('INITIAL');
      setIsDryrun(false);
      cogoToast.error(
        error?.response?.data?.errorMessage || 'Apply promo code failed.'
      );
      setPromoCode('');
    }
  }

  const currentPlan = dryrunPlan.phases.find(
    (phase) => phase.type === 'EVERGREEN'
  );
  const currentPrice = currentPlan?.prices.length
    ? currentPlan.prices.find((price) => price.currency === currency)
    : currentPlan?.fixedPrices.find((price) => price.currency === currency);

  const dryrunOrder = async (p: IPremiumPlan) => {
    const premiumRequest = {
      lineItems: [
        {
          productType: product?.type || 'BASE',
          productId: p.name,
        },
      ],
      customerInfo: {
        firstName: account.profile.firstName,
        lastName: account.profile.lastName,
        email: account.email,
      },
      currency,
      promoCode,
    };
    setIsDryrun(true);
    const {
      lineItems,
      totalPriceCents,
    }: {
      lineItems: { rawObject: IPremiumPlan }[];
      totalPriceCents: {
        price: number;
        currency: string;
      };
    } = await dryrunPurchasePremium(premiumRequest);
    setTotalPriceCents(totalPriceCents);
    setDryrunPlan(lineItems[0].rawObject);
    setIsDryrun(false);
  };

  function onFailedPayment(message: string) {
    cogoToast.error(message);
    changeState('DONE');
  }

  async function onPurchasePremium(token: string) {
    if (!account) return;
    const customerInfo = {
      firstName: account.profile.firstName,
      lastName: account.profile.lastName,
      email: account.email || '',
    };
    try {
      await purchasePremium(
        token,
        customerInfo,
        plan.name,
        promoCode,
        currency
      );
    } catch (error) {
      onFailedPayment('Purchase failed');
    }
  }

  function onCreateBooking() {
    if (ref && ref.current) {
      return ref.current?.dispatchEvent(new Event('submit'));
    }
  }

  const totalPrice = centsToDecimal(
    totalPriceCents.price,
    totalPriceCents.currency
  );

  return (
    <Box my="24px">
      {trialPhase ? (
        <>
          <Text variant="pg.lg" mb="3">
            Billed today:{' '}
            <b>
              {billedToday && billedToday?.value === 0
                ? 'Free'
                : `${billedToday?.value} ${billedToday?.currency}`}
            </b>
          </Text>
          <Text variant="pg.lg" mb="3">
            Billed after{' '}
            {trialPhase.duration.unit === 'DAYS'
              ? trialPhase.duration.number / 7
              : trialPhase.duration.number}{' '}
            {trialTime}, please select:
          </Text>
        </>
      ) : (
        <Text variant="pg.lg" mb="3">
          Billed today:
        </Text>
      )}
      {plans.map((p) => {
        const selectedPhase = p.phases.find(
          (phase) => phase.type === 'EVERGREEN'
        );
        const price = selectedPhase?.prices.length
          ? selectedPhase.prices.find((price) => price.currency === currency)
          : selectedPhase?.fixedPrices.find(
              (price) => price.currency === currency
            );
        if (!selectedPhase || !price) return null;

        const isSelected = plan.name === p.name;

        return (
          <Box
            key={p.name}
            variant="cardBox"
            mb="3"
            sx={{
              borderColor: isSelected ? 'primary' : 'border',
              borderWidth: isSelected ? '2px' : '1px',
              cursor: 'pointer',
            }}
            onClick={() => onChangePlan(p)}
          >
            {isDryrun &&
            isSelected &&
            promoState === 'DONE' &&
            currentPrice?.value !== 0 ? (
              <Flex
                sx={{
                  width: '100%',
                  justifyContent: 'center',
                }}
              >
                <Spinner />
              </Flex>
            ) : (
              <>
                <Flex
                  sx={{
                    alignItems: 'center',
                  }}
                >
                  <Text variant="lb.lg">
                    {isSelected &&
                    promoState === 'DONE' &&
                    currentPrice?.value !== 0
                      ? `${currentPrice?.value} ${currentPrice?.currency}`
                      : `${price.value} ${price.currency}`}
                  </Text>
                  {isSelected &&
                    promoState === 'DONE' &&
                    totalPrice !== price.value && (
                      <Text
                        sx={{
                          textDecoration: 'line-through',
                        }}
                        ml="1"
                        variant="pg.md"
                        color="secondaryText"
                      >
                        {price.value} {price.currency}
                      </Text>
                    )}
                  &nbsp;
                  <Text variant="pg.xs">
                    /&nbsp;{BillingPeriod[p.billingPeriod]}
                  </Text>
                  <Text color="secondaryText" variant="lb.md" ml="auto">
                    {p.billingPeriod === 'MONTHLY' ? 'Monthly' : 'Annual'}
                  </Text>
                </Flex>
                <Flex
                  sx={{
                    alignItems: 'center',
                  }}
                >
                  <Text variant="pg.xs" color="secondaryText">
                    {(
                      (promoState === 'DONE' &&
                      isSelected &&
                      currentPrice &&
                      currentPrice?.value !== 0
                        ? currentPrice.value /
                          (p.billingPeriod === 'MONTHLY' ? 30 : 365)
                        : price.value /
                          (p.billingPeriod === 'MONTHLY' ? 30 : 365)) * 7
                    ).toFixed(2)}
                    &nbsp;
                    {price.currency}&nbsp;
                  </Text>
                  <Text variant="pg.xs" color="secondaryText">
                    / week
                  </Text>
                  {p.discountPercentage > 0 && (
                    <Box variant="tags.primary" ml="auto">
                      You save {p.discountPercentage}%
                    </Box>
                  )}
                </Flex>
              </>
            )}
          </Box>
        );
      })}
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: '4',
        }}
      >
        {promoState === 'INITIAL' && (
          <>
            <Text>Got promo code?</Text>
            <Button
              variant="flat.primary"
              onClick={() => setPromoState('ENTER')}
            >
              Enter promo code
            </Button>
          </>
        )}
        {promoState === 'ENTER' && (
          <>
            <Input
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
              placeholder="Enter promo code"
              sx={{
                textTransform: 'uppercase',
                height: '48px',
              }}
            />
            <Button
              sx={{
                width: '80px',
              }}
              size="md"
              ml="2"
              onClick={applyPromo}
            >
              Apply
            </Button>
          </>
        )}
        {promoState === 'DONE' && (
          <>
            <Text mr="2" variant="lb.md">
              {promoCode.toUpperCase()}
            </Text>
            <Icon
              icon="close"
              fill="primaryText"
              onClick={() => {
                setPromoState('INITIAL');
                setPromoCode('');
                setDryrunPlan(plan);
                setTotalPriceCents({ price: 0, currency });
              }}
            />
          </>
        )}
      </Flex>
      <Text variant="hd.sm" mb="24px">
        Payment Method
      </Text>
      <CreditCard
        hasLabel
        ref={ref}
        onMakePayment={onPurchasePremium}
        onSuccess={() => changeState('PURCHASED')}
        onFailed={onFailedPayment}
      />
      <Button
        size="lg"
        sx={{
          width: '100%',
          mt: 4,
          mb: 2,
        }}
        disabled={!plan || state === 'SUBMITTING' || promoState === 'ENTER'}
        onClick={onCreateBooking}
      >
        {trialPhase
          ? `Start ${
              trialPhase.duration.unit === 'DAYS'
                ? trialPhase.duration.number / 7
                : trialPhase.duration.number
            } ${trialTime} trial`
          : 'Purchase Premium'}
        {!!trialPhase && !!currentPrice && (
          <Text variant="pg.xs" color="white">
            {' '}
            then {currentPrice.value} {currentPrice.currency}{' '}
            {plan.billingPeriod.toLowerCase()}
          </Text>
        )}
      </Button>
      <Text color="secondaryText" variant="pg.sm">
        Recurring billing. Cancel anytime.
      </Text>
    </Box>
  );
});

export default PremiumDetails;
