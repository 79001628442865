import React, { FunctionComponent } from 'react';
import { Grid, Flex, Box } from 'theme-ui';

import { Text } from '../../Primitives';

import { IActivityScore } from '../../../interface/ActivityTimeline';
import ListImage from './ListImage';

const ScoreCard: FunctionComponent<{
  item: IActivityScore;
}> = ({ item }) => {
  const {
    object: {
      rawObject: {
        players,
        score: { team1, team2 },
      },
    },
  } = item;

  const team1Member = players.filter((player) =>
    team1.profileIds.includes(player.id)
  );

  const team2Member = players.filter((player) =>
    team2.profileIds.includes(player.id)
  );

  return (
    <Box>
      <Grid
        sx={{
          gridTemplateColumns: '1fr auto 1fr',
        }}
      >
        <ListImage members={team1Member} />
        {team1.isWinner ? (
          <Flex
            sx={{
              color: 'white',
              bg: 'primary',
              width: '20px',
              height: '20px',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '20px',
              alignSelf: 'center',
            }}
          >
            W
          </Flex>
        ) : (
          <Box
            sx={{
              width: '20px',
              height: '20px',
            }}
          />
        )}
        <Flex
          sx={{
            alignSelf: 'center',
            justifyContent: 'space-around',
          }}
        >
          {team1.setGames.map((game, index) => (
            <Text
              variant="lb.lg"
              color={team1.isWinner ? 'primaryText' : 'secondaryText'}
              key={game + index}
            >
              {game}
            </Text>
          ))}
        </Flex>
      </Grid>
      <Grid
        sx={{
          gridTemplateColumns: '1fr auto 1fr',
          textAlign: 'center',
          position: 'relative',
        }}
      >
        <Text p="2" bg="white" variant="lb.md">
          VS
        </Text>
      </Grid>
      <Grid
        sx={{
          gridTemplateColumns: '1fr auto 1fr',
        }}
      >
        <ListImage members={team2Member} />
        {team2.isWinner ? (
          <Flex
            sx={{
              color: 'white',
              bg: 'primary',
              width: '20px',
              height: '20px',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '20px',
              alignSelf: 'center',
            }}
          >
            W
          </Flex>
        ) : (
          <Box
            sx={{
              width: '20px',
              height: '20px',
            }}
          />
        )}
        <Flex
          sx={{
            alignSelf: 'center',
            justifyContent: 'space-around',
          }}
        >
          {team2.setGames.map((game, index) => (
            <Text
              variant="lb.lg"
              color={team2.isWinner ? 'primaryText' : 'secondaryText'}
              key={game + index}
            >
              {game}
            </Text>
          ))}
        </Flex>
      </Grid>
    </Box>
  );
};

export default ScoreCard;
