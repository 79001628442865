import React from 'react';
import { Button, Flex, Box, Text } from 'rebass';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Icon } from '../../Primitives';

import {
  PageBody,
  PageHeading,
  GroupRequestItem,
} from '../Notification.styled';
import { useStores } from '../../../hooks/useStore';
import useWindowInfinityScroll from '../../../hooks/useWindowInfinityScroll';
import Avatar from '../../UI/Avatar';
import { GroupJoinRequestLoading } from '../../UI/ContentLoading';
import { EmptyNotificationScreen } from './NotificationItems';

const GroupJoinBody: React.FC = observer(() => {
  const history = useHistory();
  const { notificationStore } = useStores();
  const {
    groups,
    groupLoading,
    hasMoreGroupRequest,
    getGroupJoinRequest,
    actionLoading,
    approveGroupJoin,
    deleteGroupJoin,
  } = notificationStore;

  useWindowInfinityScroll(getGroupJoinRequest);

  return (
    <PageBody>
      <PageHeading
        display="flex"
        height="56px"
        alignItems="center"
        width="100%"
        px={['3', '24px']}
      >
        <Icon
          icon="chevron-left"
          size="lg"
          fill="primaryText"
          onClick={() => history.goBack()}
        />
        <Text ml="2" variant="headline.small">
          Group join requests
        </Text>
      </PageHeading>
      <Box>
        {!!groups.length &&
          groups.map((group, groupIndex) => {
            if (group.members.some((m) => m.status === 'PENDING'))
              return (
                <Flex flexDirection="column" key={group.id}>
                  <Text p="3" bg="dark6" variant="body" fontWeight="bold">
                    {group.groupProfile.name.toUpperCase()}
                  </Text>
                  {group.members.map((member, memberIndex) => {
                    if (member.status !== 'PENDING') return null;
                    return (
                      <GroupRequestItem key={member.id}>
                        <Avatar
                          imageLink={member.profile?.picture}
                          size="50px"
                          pulseLevel={member.profile?.pulseLevel}
                          onClick={() =>
                            history.push(`/${member.profile.username}`)
                          }
                        />
                        <Text ml="2" variant="body.large" fontWeight="bold">
                          {member.profile?.name}
                        </Text>
                        <Flex flexDirection="column" ml="auto">
                          <Button
                            disabled={actionLoading}
                            variant="outline"
                            fontWeight="bold"
                            mb="2"
                            onClick={() =>
                              approveGroupJoin(
                                group.id,
                                member.id,
                                groupIndex,
                                memberIndex
                              )
                            }
                          >
                            Approve
                          </Button>
                          <Button
                            disabled={actionLoading}
                            variant="outline.normal"
                            fontWeight="bold"
                            onClick={() =>
                              deleteGroupJoin(group.id, member.id, groupIndex)
                            }
                          >
                            Delete
                          </Button>
                        </Flex>
                      </GroupRequestItem>
                    );
                  })}
                </Flex>
              );
            return null;
          })}
        {!groups.length && !groupLoading && (
          <EmptyNotificationScreen
            title="No requests at the moment."
            description=" "
          />
        )}
        {hasMoreGroupRequest && groupLoading && (
          <>
            <GroupJoinRequestLoading />
            <GroupJoinRequestLoading />
            <GroupJoinRequestLoading />
          </>
        )}
      </Box>
    </PageBody>
  );
});

export default GroupJoinBody;
