import React, { useState } from 'react';
import { Box, Flex, Button } from 'rebass';
import { DateTime } from 'luxon';

import { Icon } from '../Primitives';
import Text from '../UI/Text';

import styled from '../../theme/styled';

import { getDuration, isToday } from '../../helpers/datetime';

import { IComment } from '../../interface/Inbox';
import { IActivity } from '../../interface/Activity';
import { ILocation } from '../../interface/Utils';

const Option = styled(Flex)`
  border-top: ${(p) => p.theme.border.searchInput};
  border-bottom: ${(p) => p.theme.border.searchInput};
  flex-direction: column;
`;

const Modifier = styled(Flex)`
  cursor: pointer;
  :hover {
    background-color: ${(p) => p.theme.colors.primaryTextHover};
  }
`;

interface Props {
  activity: IActivity;
  lastMessageWithPoll: IComment | undefined;
}

export interface RawOption {
  date: DateTime;
  start: DateTime;
  end: DateTime;
  location: ILocation;
}

export const PollDialog: React.FC<Props> = ({
  activity,
  lastMessageWithPoll,
}) => {
  // const [isEditTime, setIsEditTime] = useState(false);
  // const [isEditingLocation, setIsEditLocation] = useState(false);

  const { start, end, locations } = activity;

  // const [options, setOptions] = useState(
  //   !!lastMessageWithPoll && lastMessageWithPoll.content?.poll?.options
  //     ? lastMessageWithPoll.content.poll.options
  //     : []
  // );

  const [rawOptions, setRawOptions] = useState<RawOption[]>([]);

  const onDeleteOption = () => {};

  const onCreateOption = () => {
    const duration = getDuration(start, end, 'minutes');
    const option = {
      date: DateTime.local(),
      start: DateTime.local(),
      end: DateTime.local().plus({ minutes: duration }),
      location: locations[0],
    };
    rawOptions.push(option);
    setRawOptions([...rawOptions]);
  };

  // const onChangeOption = (index: number, option: RawOption) => {
  //   rawOptions[index] = option;
  //   setRawOptions([...rawOptions]);
  // };

  return (
    <Flex flexDirection="column" my="2">
      {rawOptions.map((option: RawOption, index: number) => (
        <Option key={index} bg="white" p="2" color="primaryText" mb="3">
          <Flex justifyContent="space-between" width="100%" mb="2">
            <Text fontWeight="bold">Option {index + 1}</Text>
            <Icon
              icon="close"
              size="sm"
              fill="secondaryText"
              onClick={onDeleteOption}
            />
          </Flex>
          <Modifier py="2">
            <Icon icon="calendar" size="sm" />
            <Text ml="1">
              {isToday(option.date.toISO())
                ? 'Today,'
                : option.date.toFormat('EEE dd MMM,')}
            </Text>
            <Text ml="1" fontWeight="bold">
              {option.start.toFormat('h a')} - {option.end.toFormat('h a')}
            </Text>
          </Modifier>
          <Box variant="line" my="2" />
          <Modifier py="2">
            <Icon icon="location" size="sm" />
            <Text ml="1">{option.location.name}</Text>
          </Modifier>
        </Option>
      ))}
      <Button variant="transparent" bg="white" onClick={onCreateOption}>
        <Flex alignItems="center">
          <Icon icon="add" fill="primary" size="sm" />
          <Text ml="1">Add option</Text>
        </Flex>
      </Button>
    </Flex>
  );
};
