import React, { useEffect } from 'react';

import FeedPage from '../components/Feed';
import { trackPageView } from '../services/amplitudeService';

const Feed: React.FC = () => {
  useEffect(() => {
    trackPageView('Feed');
  }, []);

  return <FeedPage />;
};

export default Feed;
