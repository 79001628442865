import React, { useState } from 'react';
import { Flex, Box, Spinner } from 'theme-ui';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';

import { Icon, Button, Text } from '../Primitives';
import Avatar from '../UI/Avatar';

import {
  Body,
  BackBtn,
  PeopleWrapper,
  InviteFriend,
  RemoveFollowerDialog,
  AvatarTop,
} from './Profile.styled';
import { IFollower } from '../../interface/People';
import { followerStore } from '../../stores/FollowerStore';
import useWindowInfinityScroll from '../../hooks/useWindowInfinityScroll';
import EmptyList from '../UI/EmptyList';

const Followers: React.FC<{
  profileId: number;
  isSelf: boolean;
  name: string;
}> = observer(({ profileId, isSelf, name }) => {
  const {
    followers,
    getFollowers,
    loading,
    hasMore,
    updateFollow,
    removeFollower,
  } = followerStore;
  const history = useHistory();

  const [isOpen, setDialogStatus] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(
    followers.length ? followers[0] : null
  );

  useWindowInfinityScroll(getFollowers, profileId);

  const toProfile = (username: string) => {
    history.push(`/${username}`);
  };

  return (
    <Body py={[2, 3]} flexDirection="column">
      <Flex
        px="3"
        pb={[2, 3]}
        sx={{
          alignItems: 'center',
        }}
        bg="white"
      >
        <BackBtn
          icon="chevron-left"
          fill="primaryText"
          onClick={() => history.push(`/${name}`)}
        />
        <Text ml="3" variant="lb.lg">
          Followers
        </Text>
      </Flex>
      <Box variant="line" />
      {isSelf && (
        <InviteFriend
          px="3"
          py="2"
          alignItems="center"
          justifyContent="space-between"
          onClick={() => history.push('/settings/find-friends')}
        >
          <Text color="primary" variant="lb.md">
            Invite your friend
          </Text>
          <Icon icon="chevron-right" fill="primaryText" />
        </InviteFriend>
      )}
      <Box variant="line" />
      {followers.map((p: IFollower) => {
        const { follower } = p;
        const {
          id,
          firstName,
          lastName,
          picture,
          pulseLevel,
          username,
          followStatus,
        } = follower;
        if (id === profileId) return null;
        return (
          <PeopleWrapper
            p="3"
            alignItems="center"
            key={id}
            bg="white"
            onClick={() => toProfile(username)}
          >
            <Avatar imageLink={picture} pulseLevel={pulseLevel} />
            <Text ml="2" variant="lb.md">
              {firstName} {lastName}
            </Text>
            <Button
              variant={
                followStatus && followStatus !== 'NOT_FOLLOWING'
                  ? 'outline.normal'
                  : 'outline.primary'
              }
              ml="auto"
              sx={{
                textTransform: 'capitalize',
              }}
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                updateFollow(p);
              }}
            >
              {!!followStatus && followStatus !== 'NOT_FOLLOWING'
                ? followStatus.toLowerCase()
                : 'Follow'}
            </Button>
            {isSelf && (
              <Icon
                icon="ellipsis-vertical"
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  setSelectedPerson(p);
                  setDialogStatus(true);
                }}
              />
            )}
          </PeopleWrapper>
        );
      })}
      {!followers.length && !loading && <EmptyList text="follower" />}
      {hasMore && loading && (
        <Flex
          sx={{
            justifyContent: 'center',
          }}
        >
          <Spinner size={30} />
        </Flex>
      )}
      {isOpen && selectedPerson !== null && (
        <RemoveFollowerDialog
          isOpen={isOpen}
          onClose={() => setDialogStatus(false)}
        >
          <AvatarTop imageLink={selectedPerson.follower.picture} size="80px" />
          <Text mb="2" variant="lb.md">
            Remove {selectedPerson.follower.firstName}?
          </Text>
          <Text color="primaryText" mb="2">
            {selectedPerson.follower.firstName} won't be informed that he/she
            has been removed from your followers.
          </Text>
          <Box
            sx={{
              width: '100%',
              textAlign: 'center',
            }}
          >
            <Button
              sx={{
                width: '40%',
              }}
              variant="outline.normal"
              mr="3"
              onClick={() => setDialogStatus(false)}
            >
              Cancel
            </Button>
            <Button
              sx={{
                width: '40%',
              }}
              variant="outline.danger"
              onClick={() => {
                removeFollower(selectedPerson.follower.id);
                setDialogStatus(false);
              }}
            >
              Remove
            </Button>
          </Box>
        </RemoveFollowerDialog>
      )}
    </Body>
  );
});

export default Followers;
