import React, { FunctionComponent } from 'react';
import { Flex, Image } from 'theme-ui';

import Text from '../Text';

import { PUBLIC_URL } from '../../../constant';

const HexagonBadge: FunctionComponent<{
  text: string | number;
  className?: string;
  size?: string;
}> = ({ text, className, size = '16px' }) => {
  return (
    <Flex
      sx={{
        alignItems: 'center',
        position: 'relative',
      }}
      className={className}
    >
      <Image
        src={`${PUBLIC_URL}/images/badge/polygon.png`}
        width={size}
        height={size}
      />
      <Text
        sx={{
          position: 'absolute',
          width: '100%',
          textAlign: 'center',
        }}
        variant="pg.xxs"
        color="white"
      >
        {text}
      </Text>
    </Flex>
  );
};

export default HexagonBadge;
