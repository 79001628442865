import React, { useEffect } from 'react';

import SchedulePage from '../components/Schedule';
import { trackPageView } from '../services/amplitudeService';

const Schedule: React.FC = () => {
  useEffect(() => {
    trackPageView('Schedule');
  }, []);
  return <SchedulePage />;
};

export default Schedule;
