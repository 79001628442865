import { observable, action, runInAction } from 'mobx';
import { IPeople, IInvitePlayer } from '../interface/People';
import {
  searchPlayers,
  getInvitedPlayers,
  invitePlayersToGame,
  inviteGroupsToGame,
  reserveSpots,
} from '../api/ActivityAPI';
import { SEARCH_LIMIT } from '../constant';
import { IGroupInbox } from '../interface/Inbox';
import cogoToast from 'cogo-toast';
import { activityDetailStore } from './ActivityDetailStore';
import { userStore } from './UserStore';

class InviteStore {
  @observable
  players: IPeople[] = [];

  @observable
  invitedPlayers: IInvitePlayer[] = [];

  @observable
  hasMore: boolean = true;

  @observable
  hasMoreInvited: boolean = true;

  @observable
  loading: boolean = false;

  @observable
  loadingInvited: boolean = false;

  params: {
    offset: number;
    limit: 20;
  } = { offset: 0, limit: SEARCH_LIMIT };

  invitedParams: {
    offset: number;
    limit: 20;
  } = { offset: 0, limit: SEARCH_LIMIT };

  @action
  getPlayers = async (
    {
      playId,
      sport,
      query,
    }: { playId: string; sport: string[]; query?: string },
    isReset?: boolean
  ) => {
    if (isReset) {
      this.hasMore = true;
      this.players = [];
      this.params.offset = 0;
    }
    if (!this.hasMore || this.loading) return;
    this.loading = true;
    try {
      const response = await searchPlayers({
        ...this.params,
        playId,
        sport,
        query,
      });
      runInAction(() => {
        this.players = [...this.players, ...response];
        this.hasMore = response.length !== SEARCH_LIMIT ? false : true;
        this.params.offset = this.players.length;
        this.loading = false;
      });
    } catch (error) {
      this.loading = false;
    }
  };

  @action
  getInvitedPlayers = async (playId: string, isReset?: boolean) => {
    if (isReset) {
      this.hasMoreInvited = true;
      this.invitedPlayers = [];
      this.invitedParams.offset = 0;
    }
    if (
      !this.hasMoreInvited ||
      this.loadingInvited ||
      !(userStore.account.profile.id > -1)
    )
      return;
    this.loadingInvited = true;
    try {
      const response = await getInvitedPlayers(this.invitedParams, playId);
      runInAction(() => {
        this.invitedPlayers = [...this.invitedPlayers, ...response];
        this.hasMoreInvited = response.length !== SEARCH_LIMIT ? false : true;
        this.invitedParams.offset = this.invitedPlayers.length;
        this.loadingInvited = false;
      });
    } catch (error) {
      this.loadingInvited = false;
    }
  };

  @observable
  selectedPlayers: IPeople[] = [];

  @action
  toggleSelectPlayer = (profile: IPeople, isSelected: boolean) => {
    if (isSelected) {
      this.selectedPlayers = this.selectedPlayers.filter(
        (p) => p.id !== profile.id
      );
    } else {
      this.selectedPlayers.push(profile);
    }
  };

  @observable
  selectedGroups: IGroupInbox[] = [];

  @action
  toggleSelectGroup = (group: IGroupInbox, isSelected: boolean) => {
    if (isSelected) {
      this.selectedGroups = this.selectedGroups.filter(
        (p) => p.id !== group.id
      );
    } else {
      this.selectedGroups.push(group);
    }
  };

  @observable
  isInviting: boolean = false;

  @action
  invitePlayersToGame = async (playId: string) => {
    this.isInviting = true;
    const profileIds = this.selectedPlayers.map((play) => play.id);
    try {
      await invitePlayersToGame(playId, profileIds);
      await this.getInvitedPlayers(playId, true);
      runInAction(() => {
        this.isInviting = false;
        cogoToast.success(
          `You have successfully invited ${
            profileIds.length > 1 ? '1 people' : profileIds.length + ' people'
          } into game `
        );
        this.selectedPlayers = [];
      });
    } catch (error) {
      cogoToast.error('Invite failed. Please try again');

      this.isInviting = false;
    }
  };

  @action
  inviteSinglePlayerToGame = async (playId: string, profile: IPeople) => {
    this.isInviting = true;
    try {
      await invitePlayersToGame(playId, [profile.id]);
      await this.getInvitedPlayers(playId, true);
      runInAction(() => {
        this.isInviting = false;
        cogoToast.success(
          `You have successfully invited ${profile.name} into game.`
        );
        this.selectedPlayers = [];
      });
    } catch (error) {
      cogoToast.error('Invite failed. Please try again');
      this.isInviting = false;
    }
  };

  @action
  inviteGroupsToGame = async (playId: string) => {
    this.isInviting = true;
    const groupIds = this.selectedGroups.map((group) => group.id);
    try {
      await inviteGroupsToGame(playId, groupIds);
      await this.getInvitedPlayers(playId, true);
      runInAction(() => {
        this.isInviting = false;
        cogoToast.success(
          `You have successfully invited ${
            groupIds.length > 1 ? '1 group' : groupIds.length + ' groups'
          } into game `
        );
      });
    } catch (error) {
      cogoToast.error('Invite failed. Please try again');

      this.isInviting = false;
    }
  };

  @observable
  isReserving: boolean = false;

  @action
  reservePlayers = async (playId: string) => {
    this.isReserving = true;
    const profileIds = this.selectedPlayers.map((play) => play.id);
    try {
      await reserveSpots(playId, profileIds);
      await activityDetailStore.getActivityDetail(playId);
      runInAction(() => {
        this.isInviting = false;
        cogoToast.success(
          `You have successfully reserve ${
            profileIds.length > 1 ? '1 people' : profileIds.length + ' people'
          } into game.`
        );
        this.selectedPlayers = [];
      });
    } catch (error) {
      cogoToast.error('Reserve failed. Please try again');

      this.isReserving = false;
    }
  };
}

export default InviteStore;
