import React from 'react';
import { Box } from 'theme-ui';

import Text from '../Text';

import { iconList } from './iconList';
import { IconNames, IconSizes } from './iconTypes';
import colors from '../../../theme/colors';

const iconSizes: { [key in IconSizes]: number } = {
  sm: 16,
  md: 20,
  lg: 24,
  xl: 32,
  badge: 40,
  xxl: 48,
};

type Props = {
  icon: IconNames;
  size?: IconSizes;
  stroke?: string;
  fill?: string;
  className?: string;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
};

const Icon = ({
  icon,
  size = 'md',
  stroke,
  fill,
  className,
  onClick,
}: Props) => {
  const SelectedIcon = iconList[icon];
  const selectedSize = iconSizes[size];

  return (
    <SelectedIcon
      style={{
        cursor: onClick ? 'pointer' : 'initial',
        pointerEvents: onClick ? 'initial' : 'none',
      }}
      onClick={onClick}
      stroke={stroke && colors[stroke]}
      fill={fill ? colors[fill] : 'none'}
      className={className}
      title={icon}
      width={selectedSize + 'px'}
      height={selectedSize + 'px'}
    />
  );
};

export default Icon;

type IconTextProps = {
  icon: IconNames;
  size: IconSizes;
  stroke?: string;
  className?: string;
  text: string | number;
};

export const IconWithText = ({
  text,
  size = 'xl',
  ...props
}: IconTextProps) => {
  const selectedSize = iconSizes[size];

  return (
    <Box
      sx={{
        height: selectedSize + 'px',
        position: 'relative',
      }}
    >
      <Icon {...props} size={size} />
      <Text
        sx={{
          lineHeight: selectedSize + 'px',
          position: 'absolute',
          top: '0px',
          width: '100%',
          textAlign: 'center',
        }}
      >
        {text}
      </Text>
    </Box>
  );
};
