import httpService from '../services/httpService';
import get from 'lodash/get';
import { IActivity } from '../interface/Activity';
import { IPeople } from '../interface/People';
import { IGroup } from '../interface/Group';
import { IVenue } from '../interface/Venue';
import { SEARCH_LIMIT } from '../constant';
import cogoToast from 'cogo-toast';

const defaultQuery = {
  debug: false,
  q: '',
  suggest: false,
  fuzziness: true,
  offset: 0,
  limit: SEARCH_LIMIT,
  showFullGames: true,
  aggregate: true,
  enrich: true,
};

export const searchAll = async (query?: Object, suggest: boolean = false) => {
  try {
    let response = await httpService.post('search', {
      ...defaultQuery,
      ...query,
      suggest,
    });
    const suggestText =
      get(response, 'data.data.body.suggest.name-suggest-fuzzy[0].options') ||
      [];
    const bucketsData: {
      activities: IActivity[];
      people: IPeople[];
      venues: IVenue[];
      groups: IGroup[];
    } = {
      people: [],
      venues: [],
      groups: [],
      activities: [],
    };
    const buckets = get(
      response,
      'data.data.body.aggregations.by_type.buckets',
      []
    );
    buckets.forEach((element: any) => {
      switch (element.key) {
        case 'profile':
          const profile = get(element, 'top_type_hits.hits.hits', []);
          const nomalizeProfile = profile
            .filter((d: any) => d.rawObject)
            .map((d: any) => d.rawObject);
          bucketsData.people = nomalizeProfile;
          break;
        case 'venue':
          const venue = get(element, 'top_type_hits.hits.hits', []);
          const nomalizeVenue = venue
            .filter((d: any) => d.rawObject)
            .map((d: any) => d.rawObject);
          bucketsData.venues = nomalizeVenue;
          break;
        case 'group':
          const group = get(element, 'top_type_hits.hits.hits', []);
          const nomalizeGroup = group
            .filter((d: any) => d.rawObject)
            .map((d: any) => d.rawObject);
          bucketsData.groups = nomalizeGroup;
          break;
        case 'play':
          const play = get(element, 'top_type_hits.hits.hits', []);
          const nomalizePlay = play
            .filter((d: any) => d.rawObject)
            .map((d: any) => d.rawObject);
          bucketsData.activities = nomalizePlay;
          break;
        default:
          break;
      }
    });
    if (suggest) {
      return { suggestText };
    } else return { bucketsData };
  } catch (error) {
    throw error;
  }
};

export const getActivities = async (query?: Object) => {
  try {
    let response = await httpService.post('search/plays', {
      ...defaultQuery,
      ...query,
      status: 'PLANNING',
    });
    const rawData = get(response, 'data.data.body.hits.hits') || [];
    return rawData
      .filter((hit: { rawObject: IActivity }) => hit.rawObject)
      .map((hit: { rawObject: IActivity }) => hit.rawObject);
  } catch (error) {
    if (error?.response?.errorTitle) {
      cogoToast.error(error?.response?.errorTitle);
    }
    throw error;
  }
};

export const getPeople = async (query?: Object) => {
  try {
    let response = await httpService.post('search/profiles', {
      ...defaultQuery,
      ...query,
    });
    const rawData = get(response, 'data.data.body.hits.hits') || [];
    return rawData
      .filter((hit: { rawObject: IPeople }) => hit.rawObject)
      .map((hit: { rawObject: IPeople }) => hit.rawObject);
  } catch (error) {
    throw error;
  }
};

export const getGroups = async (query?: Object) => {
  try {
    let response = await httpService.post('search/groups', {
      ...defaultQuery,
      ...query,
    });
    const rawData = get(response, 'data.data.body.hits.hits') || [];
    return rawData
      .filter((hit: { rawObject: IGroup }) => hit.rawObject)
      .map((hit: { rawObject: IGroup }) => hit.rawObject);
  } catch (error) {
    throw error;
  }
};

export const getVenues = async (query?: Object) => {
  try {
    let response = await httpService.post('search/venues', {
      ...defaultQuery,
      ...query,
    });
    const rawData = get(response, 'data.data.body.hits.hits') || [];
    return rawData
      .filter((hit: { rawObject: IVenue }) => hit.rawObject)
      .map((hit: { rawObject: IVenue }) => hit.rawObject);
  } catch (error) {
    throw error;
  }
};

type SearchQuery = {
  locations: Object[];
  sort: string;
  sports: string[];
};

export const getClasses = async (query: SearchQuery, offset: number) => {
  const { locations, sort, sports } = query;
  try {
    let response = await httpService.post('partners/explore/classes', {
      filters: {
        locations,
        sports,
      },
      limit: 10,
      offset,
      query: '',
      sort,
    });
    const rawData = get(response, 'data.data') || [];
    return rawData;
  } catch (err) {
    throw err;
  }
};
