import React, { useEffect } from 'react';
import { Box, Flex, Image } from 'theme-ui';
// import Carousel from '@brainhubeu/react-carousel';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Text, Button } from '../../components/Primitives';
import { MinimalFooter } from '../../components/Layout/UnAuth/Footer';
import { UnAuthHeaderOnlyLogo } from '../../components/Layout/UnAuth/Header';
import { premiumStore } from '../../stores/PremiumStore';

import { ReactComponent as RovoPremiumText } from '../../assets/illustrator/rovo-premium.svg';

import { ReactComponent as Premium } from '../../assets/icons/stylized/rovo-premium.svg';

import { benefits } from './PremiumBenefits';

const PremiumPage = observer(() => {
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
  const history = useHistory();

  const { premium, getPremium } = premiumStore;
  useEffect(() => {
    if (!premium) {
      getPremium();
    }
  }, [premium, getPremium]);

  function toPurchase() {
    history.push('/premium/purchase');
  }

  return (
    <Box>
      <UnAuthHeaderOnlyLogo />
      <Box
        mt="56px"
        sx={{
          maxWidth: '1160px',
          textAlign: 'center',
          mx: 'auto',
        }}
      >
        <Flex
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <RovoPremiumText width="280px" height="56px" />
          <Premium width="32px" height="32px" />
        </Flex>
        <Text variant={isDesktop ? 'dp.md' : 'hd.lg'} px={[3, 0]} mt="1" mb="2">
          Get more out of your Rovo
        </Text>
        <Text variant="pg.xl" mb="24px">
          Access all advanced features for as low as 2.09 USD / week.
        </Text>
        <Button px="4" size="lg" onClick={toPurchase}>
          Get 2 weeks limited trial
        </Button>
        <Flex
          my={[5, 6]}
          sx={{
            alignItems: 'center',
          }}
        >
          <Box>
            <Image
              sx={{
                boxShadow: 'below.medium',
                borderRadius: '8px',
              }}
              src="/images/premium-1.png"
            />
          </Box>
          <Box>
            <Image
              sx={{
                boxShadow: 'below.medium',
                borderRadius: '8px',
              }}
              src="/images/premium-2.png"
            />
          </Box>
          <Box>
            <Image
              sx={{
                boxShadow: 'below.high',
                borderRadius: '8px',
              }}
              src="/images/premium-3.png"
            />
          </Box>
          <Box>
            <Image
              sx={{
                boxShadow: 'below.medium',
                borderRadius: '8px',
              }}
              src="/images/premium-4.png"
            />
          </Box>
          <Box>
            <Image
              sx={{
                boxShadow: 'below.medium',
                borderRadius: '8px',
              }}
              src="/images/premium-5.png"
            />
          </Box>
        </Flex>
        <Text variant={isDesktop ? 'dp.md' : 'hd.lg'} px={[3, 0]} mb="3">
          Massive benefits for sport players like you
        </Text>
        <Text variant="pg.xl" mb="4">
          Whether you’re at home or outdoors, Rovo Premium has you covered.
        </Text>
        <Flex
          sx={{
            flexWrap: 'wrap',
            justifyContent: 'space-around',
          }}
          mb={[4, 6]}
        >
          {benefits.map((benefit) => (
            <Box
              key={benefit.icon}
              sx={{
                maxWidth: '300px',
                textAlign: 'center',
                mb: [4, 5],
              }}
            >
              <Image
                src={benefit.bigIcon || benefit.icon}
                sx={{
                  height: isDesktop ? '100px' : '60px',
                }}
              />
              <Text variant="hd.xs">{benefit.title.en}</Text>
              <Text>{benefit.subtitle.en}</Text>
            </Box>
          ))}
        </Flex>
        {/* <Text variant={isDesktop ? 'dp.md' : 'hd.lg'} px={[3, 0]} mb="3">
          Listen to “non-experts”, like you
        </Text>
        <Text variant="pg.xl" mb={[3, 5]}>
          Rovo premium is built for people like you, not professional athletes.
          That's why these folks are raving about it.
        </Text>
        <Carousel
          infinite
          dots={!isDesktop}
          arrows={isDesktop}
          animationSpeed={1000}
          addArrowClickHandler
          arrowLeft={
            isDesktop ? (
              <Flex
                sx={{
                  borderRadius: '9999px',
                  border: '2px solid',
                  borderColor: 'sportmanGray',
                  width: '80px',
                  height: '80px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Icon icon="chevron-left" size="badge" fill="primary" />
              </Flex>
            ) : undefined
          }
          arrowRight={
            isDesktop ? (
              <Flex
                sx={{
                  borderRadius: '9999px',
                  border: '2px solid',
                  borderColor: 'sportmanGray',
                  width: '80px',
                  height: '80px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Icon icon="chevron-right" size="badge" fill="primary" />
              </Flex>
            ) : undefined
          }
        >
          <Box
            sx={{
              borderRadius: '16px',
              p: 2,
              bg: 'white',
            }}
          >
            <Flex
              sx={{
                boxShadow: 'below.medium',
                p: [3, 4],
                borderRadius: '16px',
                flexDirection: 'column',
                alignItems: 'center',
                maxWidth: '540px',
                textAlign: 'center',
              }}
            >
              <Avatar pulseLevel="INFERNO" badgeText="U" size="xl" />
              <Text variant="hd.sm" color="primary" mb="2" mt="4">
                It’s just too good to ignore
              </Text>
              <Text variant="pg.lg" mb="3">
                “I am really impressed with Rovo Gears, especially when I could
                get 20% discount with Rovo Premium. Imagine how much you save in
                a year for sport equipment. It’s just amazing.”
              </Text>
              <Flex
                sx={{
                  alignItems: 'center',
                }}
              >
                <Text variant="lb.lg" mr="2">
                  VuAn Nguyen
                </Text>
                <Text variant="pg.lg">Singapore, Singapore</Text>
              </Flex>
            </Flex>
          </Box>
        </Carousel> */}
        <Box
          my={[5, 6]}
          mx={[3, 0]}
          p={['24px', '48px']}
          sx={{
            border: '2px solid',
            borderColor: 'sportmanGray',
            borderRadius: '8px',
          }}
        >
          <Flex
            sx={{
              alignItems: 'center',
              flexDirection: ['column', 'row'],
            }}
          >
            <Premium
              style={{
                boxShadow: '0px 16px 48px #FED657',
                borderRadius: 9999,
              }}
              width={isDesktop ? '160px' : '80px'}
              height={isDesktop ? '160px' : '80px'}
            />
            <Box
              sx={{
                textAlign: ['center', 'left'],
                ml: [0, 5],
                mt: [4, 0],
              }}
            >
              <Text variant={isDesktop ? 'hd.xl' : 'hd.sm'} mb="2">
                Limited trial for 2 weeks
              </Text>
              <Text variant={isDesktop ? 'pg.xl' : 'pg.lg'}>
                Free for 2 weeks, then from 2.09 USD /week
              </Text>
            </Box>
            <Button
              size="lg"
              sx={{
                width: ['100%', 'initial'],
                mt: [3, 0],
              }}
              ml="auto"
              mr={['auto', 0]}
              onClick={toPurchase}
            >
              Try premium now
            </Button>
          </Flex>
        </Box>
      </Box>
      <MinimalFooter />
    </Box>
  );
});

export default PremiumPage;
