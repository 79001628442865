import React, { useState, useEffect } from 'react';
import { Box, Card, Flex } from 'theme-ui';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react-lite';
import { Tabs, Tab, Spinner } from '@blueprintjs/core';
import { useHistory } from 'react-router-dom';

import { Text, Button } from '../../Primitives';
import Avatar from '../../UI/Avatar';
import EmptyList from '../../UI/EmptyList';
import { SearchInput } from '../../UI/Input';

import { getFacebookFriends } from '../../../api/ProfileAPI';

import { HeaderMobile } from '../../Layout/Header';
import FacebookLogin from '../../Authentication/components/FacebookLogin';

import { IPeople } from '../../../interface/People';

import useWindowInfinityScroll from '../../../hooks/useWindowInfinityScroll';

import { userStore } from '../../../stores/UserStore';
import { searchFriendStore } from '../../../stores/SearchFriendStore';
import LocalStorageHelper from '../../../helpers/localStorage';

const FriendCard: React.FC<{
  people: IPeople;
  handleFollow?: Function;
}> = observer(({ people, handleFollow }) => {
  const history = useHistory();
  const { id, username, picture, name, followStatus } = people;
  return (
    <Card
      sx={{
        alignItems: 'center',
      }}
      key={id}
      mb="2"
      onClick={() => history.push(`/${username}`)}
    >
      <Flex>
        <Avatar imageLink={picture} size="48px" />
        <Box
          ml="2"
          sx={{
            alignSelf: 'center',
          }}
        >
          <Text variant="lb.md">{name}</Text>
          <Text variant="lb.md">{username}</Text>
        </Box>

        {!!followStatus && (
          <Box
            ml="auto"
            sx={{
              alignSelf: 'center',
            }}
          >
            <Button
              sx={{
                textTransform: 'capitalize',
              }}
              variant={
                followStatus && followStatus !== 'NOT_FOLLOWING'
                  ? 'outline.primary'
                  : 'outline.normal'
              }
              py="2"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                if (handleFollow) handleFollow(people);
              }}
            >
              {followStatus !== 'NOT_FOLLOWING'
                ? followStatus.toLowerCase()
                : 'Follow'}
            </Button>
          </Box>
        )}
      </Flex>
    </Card>
  );
});

const FacebookTab: React.FC<{
  facebook?: Object;
  facebookId?: string;
  facebookFriends: IPeople[];
  loading: boolean;
}> = ({ facebook, facebookId, facebookFriends, loading }) => {
  const numOfFriend = facebookFriends.length;
  return (
    <Box px="3" color="primaryText">
      {facebook && facebookId ? (
        loading ? (
          <Spinner intent="primary" />
        ) : (
          <>
            <Card>
              You have {numOfFriend} friend{numOfFriend > 1 ? 's' : ''} on
              Facebook
            </Card>
            <Box mt="3">
              {facebookFriends.map((person: IPeople) => (
                <FriendCard people={person} />
              ))}
            </Box>
          </>
        )
      ) : (
        <>
          <Flex
            sx={{
              flexDirection: 'column',
              alignItems: 'center',
            }}
            mt="5"
          >
            <Text my="3">See who is on Rovo among your Facebook friends</Text>
            <FacebookLogin />
          </Flex>
        </>
      )}
    </Box>
  );
};

const SearchTab: React.FC<{
  query: { query: string };
  onChange: Function;
}> = observer(({ query, onChange }) => {
  const {
    getPeople,
    people,
    hasMore,
    loading,
    resetSearch,
    updateFollow,
  } = searchFriendStore;

  useWindowInfinityScroll(getPeople, query);

  return (
    <Flex
      sx={{
        flexDirection: 'column',
      }}
      px="3"
    >
      <SearchInput
        placeholder="Search for a player"
        onChange={(e) => {
          onChange(e.target.value);
          resetSearch();
        }}
      />
      <Box mt="3">
        {people.map((person: IPeople) => (
          <FriendCard
            people={person}
            key={person.id}
            handleFollow={updateFollow}
          />
        ))}
        {!people.length && !loading && <EmptyList text="people" />}
        {hasMore && loading && <Spinner size={24} intent="primary" />}
      </Box>
    </Flex>
  );
});

const FindFriends: React.FC = observer(() => {
  const { account } = userStore;
  const { facebook, facebookId } = account;
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const [searchString, setSearchString] = useState('');
  const [loading, setLoading] = useState(false);
  const [facebookFriends, setFacebookFriends] = useState([]);

  useEffect(() => {
    async function getFriends() {
      setLoading(true);
      const token = LocalStorageHelper.get('facebookAccessToken');
      if (token) {
        try {
          const friends = await getFacebookFriends(token);
          setFacebookFriends(friends);
        } catch {}
      }
      setLoading(false);
    }
    getFriends();
  }, [account]);
  return (
    <Flex
      sx={{
        flexDirection: 'column',
      }}
    >
      <HeaderMobile
        text="Find Friends"
        hasBackBtn={isMobile}
        backLink="/settings"
      />
      <Box py="3">
        <Tabs
          id="find-friends"
          className="modify-tabs"
          renderActiveTabPanelOnly
        >
          <Tab
            id="facebook"
            title={
              <Text mx="2" variant="lb.md">
                Facebook
              </Text>
            }
            panel={
              <FacebookTab
                facebook={facebook}
                facebookId={facebookId}
                facebookFriends={facebookFriends}
                loading={loading}
              />
            }
          />
          <Tab
            id="search"
            title={
              <Text mx="2" variant="lb.md">
                Search
              </Text>
            }
            panel={
              <SearchTab
                query={{ query: searchString }}
                onChange={setSearchString}
              />
            }
          />
        </Tabs>
      </Box>
    </Flex>
  );
});

export default FindFriends;
