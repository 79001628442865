import { decorate, observable, action, runInAction } from 'mobx';
import { DateTime } from 'luxon';

import {
  getGroupMessages,
  sendTextMessage,
  sendImageMessage,
  getGroupById,
  markAsReadMessage,
} from '../api/InboxAPI';
import { updateProfilePlayStatus } from '../api/ActivityAPI';

import { SEARCH_LIMIT } from '../constant';

import { userStore } from './UserStore';

import { IMessage, IGroupInbox } from '../interface/Inbox';
import cogoToast from 'cogo-toast';

class GroupInboxStore {
  messages: IMessage[] = [];

  group: IGroupInbox | null = null;

  loading: boolean = false;

  params: { before?: number | null; limit: number } = { limit: SEARCH_LIMIT };

  hasMore: boolean = true;

  getMessages = async (groupId: number, isReset?: boolean) => {
    if (isReset) {
      this.params = { limit: SEARCH_LIMIT };
      this.hasMore = true;
      this.messages = [];
    }
    if (this.loading || !this.hasMore) return;
    this.loading = true;
    try {
      const messages: IMessage[] = await getGroupMessages(groupId, this.params);
      runInAction(() => {
        if (messages.length === 0) {
          this.hasMore = false;
          return (this.params.before = null);
        }
        this.messages = [...this.messages, ...messages];
        this.params.before = messages[messages.length - 1]?.position;
        if (this.messages[0]?.position) {
          this.markAsRead(groupId, this.messages[0].position);
        }
      });
    } catch (error) {
    } finally {
      this.loading = false;
    }
  };

  getGroup = async (groupId: number) => {
    try {
      const group = await getGroupById(groupId);
      runInAction(() => {
        this.group = group;
      });
    } catch (error) {}
  };

  sendTextMessage = async (groupId: number, text: string) => {
    const message: IMessage = {
      position: this.messages[0]?.position ? this.messages[0].position + 1 : 1,
      id: -1,
      content: {
        text,
      },
      type: 'TEXT',
      senderId: userStore.account.profileId,
      updatedAt: DateTime.local().toISO(),
      createdAt: DateTime.local().toISO(),
      sender: userStore.account.profile,
    };
    this.messages = [message, ...this.messages];
    try {
      const message = await sendTextMessage(groupId, text);
      runInAction(() => {
        this.messages[0] = message;
        if (message?.position) {
          this.markAsRead(groupId, message.position);
        }
      });
    } catch (error) {
      runInAction(() => {
        this.messages.shift();
      });
    }
  };

  sendImageMessage = async (groupId: number, image: File) => {
    try {
      const message = await sendImageMessage(groupId, image);
      runInAction(() => {
        this.messages = [message, ...this.messages];
        if (message?.position) {
          this.markAsRead(groupId, message.position);
        }
      });
    } catch (error) {}
  };

  markAsRead = async (groupId: number, position: number) => {
    try {
      await markAsReadMessage(groupId, position);
    } catch (error) {}
  };

  updateGameInvite = async (
    messageId: number,
    playId: string,
    status: 'DECLINED' | 'ACCEPTED'
  ) => {
    if (!playId) return;
    try {
      const play = await updateProfilePlayStatus(playId, status);
      runInAction(() => {
        const index = this.messages.findIndex(m => m.id === messageId);
        this.messages[index].content.play = play;
        cogoToast.success(
          status === 'ACCEPTED'
            ? 'Join activity success'
            : 'Leave activity success'
        );
      });
    } catch (error) {
      cogoToast.error(error?.response?.data?.message || 'Action failed');
    }
  };
}

decorate(GroupInboxStore, {
  messages: observable,
  group: observable,
  loading: observable,
  getMessages: action,
  getGroup: action,
  sendTextMessage: action,
  sendImageMessage: action,
  markAsRead: action,
  updateGameInvite: action,
});

export const groupInboxStore = new GroupInboxStore();
