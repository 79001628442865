import React from 'react';
import { Text, Box, Flex } from 'rebass';
import { Dialog } from '@blueprintjs/core';
import styled from '../../theme/styled';
import { Icon } from '../Primitives';

const SDialog = styled(Dialog)`
  margin: 0px;
  padding: 16px;
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  showInvite: Function;
  showReserve: Function;
}

const InviteDialog = ({ isOpen, onClose, showInvite, showReserve }: Props) => {
  return (
    <SDialog isOpen={isOpen} onClose={onClose}>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        p="2"
        sx={{
          ':hover': {
            backgroundColor: 'primaryHover',
          },
          cursor: 'pointer',
        }}
        onClick={showInvite}
      >
        <Box>
          <Text variant="body" fontWeight="bold">
            Invite players
          </Text>
          <Text variant="body">
            Browse or search for players to invite to your game.
          </Text>
        </Box>
        <Box minWidth="32px">
          <Icon icon="chevron-right" size="xl" fill="primaryText" />
        </Box>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        p="2"
        sx={{
          ':hover': {
            backgroundColor: 'primaryHover',
          },
          cursor: 'pointer',
        }}
        onClick={showReserve}
      >
        <Box>
          <Text variant="body" fontWeight="bold">
            Reserved spots for players
          </Text>
          <Text variant="body">
            Reserve spots for player (in Rovo or not in Rovo) who have already
            committed to join your game.
          </Text>
        </Box>
        <Box minWidth="32px">
          <Icon icon="chevron-right" size="xl" fill="primaryText" />
        </Box>
      </Flex>
    </SDialog>
  );
};

export default InviteDialog;
