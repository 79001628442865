import React, { useRef, useMemo } from 'react';
import { Box, Flex } from 'rebass';
import { Spinner } from '@blueprintjs/core';

import { SearchInvite } from './ActivityDetail.styled';
import { Text } from 'rebass';
import EmptyList from '../UI/EmptyList';
import Avatar from '../UI/Avatar';
import { Icon } from '../Primitives';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../hooks/useStore';
import { IActivity } from '../../interface/Activity';
import { useBodyInfinityScroll } from '../../hooks/useWindowInfinityScroll';
import debounce from 'lodash/debounce';
import { IInvitePlayer } from '../../interface/People';

interface Props {
  activity: IActivity;
  invitedPlayers: IInvitePlayer[];
  text?: string;
}

const SearchPlayers: React.FC<Props> = observer(
  ({ activity, invitedPlayers, text }) => {
    const { inviteStore } = useStores();
    const {
      players,
      loading,
      hasMore,
      getPlayers,
      toggleSelectPlayer,
      selectedPlayers,
    } = inviteStore;

    const body = useRef<HTMLDivElement>();

    const params = useMemo(
      () => ({ playId: activity.id, sport: [activity.sport] }),
      [activity]
    );
    const onSearch = debounce((value: string) => {
      getPlayers({ ...params, query: value }, true);
    }, 200);

    useBodyInfinityScroll(body, getPlayers, params);

    return (
      <Flex flexDirection="column" justifyContent="space-between">
        <Flex flexWrap="wrap" px="3">
          {selectedPlayers.map(p => (
            <Flex
              key={p.id}
              sx={{
                borderRadius: '20px',
                bg: 'border',
                p: 1,
                m: 1,
              }}
              alignItems="center"
            >
              <Text mr="1" fontSize="12px" color="primaryText">
                {p.name}
              </Text>
              <Flex
                p="1"
                bg="secondaryText"
                sx={{
                  borderRadius: '20px',
                }}
              >
                <Icon
                  icon="close"
                  size="sm"
                  stroke="white"
                  onClick={() => toggleSelectPlayer(p, true)}
                />
              </Flex>
            </Flex>
          ))}
          <Flex alignItems="center" width="100%" mb="2">
            <Text color="primaryText" fontWeight="bold" mr="1">
              {text || 'Invite'}:
            </Text>
            <SearchInvite
              placeholder="Search players"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onSearch(e.target.value)
              }
            />
          </Flex>
        </Flex>
        <Flex
          flexDirection="column"
          overflow="auto"
          id="body-scrolling"
          height="calc(100vh - 200px)"
          ref={body}
        >
          {!!players.length &&
            players.map(p => {
              const isJoined = activity.accepted.includes(p.id);
              const isInvited = invitedPlayers.some(
                play => play.profileId === p.id
              );
              const isSelected = selectedPlayers.some(play => play.id === p.id);
              if (isJoined) return null;
              return (
                <Flex
                  key={p.id}
                  sx={{
                    borderBottom: '1px solid #d4dade',
                    ':hover': {
                      bg: 'primaryHover',
                    },
                    cursor: 'pointer',
                  }}
                  p="2"
                  alignItems="center"
                  onClick={() => {
                    if (isInvited) return;
                    toggleSelectPlayer(p, isSelected);
                  }}
                >
                  <Box minWidth="40px">
                    <Avatar
                      imageLink={p.picture}
                      size="40px"
                      pulseLevel={p.pulseLevel}
                    />
                  </Box>
                  <Box ml="3">
                    <Text variant="body" fontWeight="bold">
                      {p.name}
                    </Text>
                    <Text variant="caption">@{p.username}</Text>
                  </Box>
                  <Box minWidth="24px" ml="auto">
                    {isSelected && (
                      <Icon icon="ok" stroke="primary" size="lg" />
                    )}
                    {isJoined && <Text color="primary">Joined</Text>}
                    {isInvited && !isJoined && (
                      <Text color="primary">Invited</Text>
                    )}
                  </Box>
                </Flex>
              );
            })}
          {!hasMore && !players.length && <EmptyList text="players" />}
          {loading && hasMore && (
            <Box alignSelf="center" mt="3">
              <Spinner size={24} intent="primary" />
            </Box>
          )}
        </Flex>
      </Flex>
    );
  }
);

export default SearchPlayers;
