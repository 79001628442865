import React, {
  FunctionComponent,
  useState,
  useEffect,
  SyntheticEvent,
} from 'react';
import { Flex, Button } from 'rebass';
import { observer } from 'mobx-react-lite';

import { getSports } from '../../../api/UtilsAPI';

import Text from '../../UI/Text';
import { SearchInput } from '../../UI/Input';
import { Icon } from '../../Primitives';
import { SportDialog, SportWrapper, Item } from './FilterBar.styled';
import { filterStore } from '../../../stores/FilterStore';

type Props = {
  isOpen: boolean;
  onClose: (event?: SyntheticEvent<HTMLElement, Event> | undefined) => void;
};

const FilterSport: FunctionComponent<Props> = observer(
  ({ isOpen, onClose }) => {
    const { selectedSports, setSelectedSports } = filterStore;

    const [sports, setSports] = useState([]);
    const [currentSports, setCurrentSports] = useState(selectedSports);
    const [searchString, setSearchString]: [string, Function] = useState('');

    useEffect(() => {
      async function getSportsData() {
        const sports = await getSports();
        setSports(sports.map((sport: { name: string }) => sport.name));
      }

      getSportsData();
    }, []);

    const toggleSport = (sport: string) => {
      if (currentSports.includes(sport)) {
        const filteredSports = currentSports.filter(s => s !== sport);
        setCurrentSports(filteredSports);
      } else {
        setCurrentSports([...currentSports, sport]);
      }
    };

    const clearSelection = () => {
      setCurrentSports([]);
      setSearchString('');
    };

    const applyFilter = () => {
      setSelectedSports(currentSports);
      onClose();
    };

    const displaySports = sports.filter((sport: string) =>
      sport.includes(searchString)
    );

    return (
      <SportDialog isOpen={isOpen} onClose={onClose}>
        <Flex flexDirection="column">
          <Flex fontSize="3" alignItems="center" justifyContent="space-between">
            <Text color="primaryText">Select sport</Text>
            <Button variant="noPadding" color="primary" onClick={applyFilter}>
              Done
            </Button>
          </Flex>
          <Flex fontSize="2" alignItems="center" my="2">
            <Text color="primaryText" mr="3">
              {currentSports.length} sport{currentSports.length > 1 && 's'}{' '}
              selected
            </Text>
            <Button
              variant="noPadding"
              color="primary"
              onClick={clearSelection}
            >
              Clear
            </Button>
          </Flex>
        </Flex>
        <SearchInput
          type="text"
          placeholder="Search for a sport"
          onChange={e => setSearchString(e.target.value)}
        />
        <SportWrapper flexDirection="column" mt="2">
          {displaySports.map((sport: string) => {
            const selected = currentSports.includes(sport);
            return (
              <Flex
                py="2"
                justifyContent="space-between"
                key={sport}
                onClick={() => toggleSport(sport)}
              >
                <Item
                  fontSize="20px"
                  color={selected ? 'primary' : 'primaryText'}
                >
                  {sport}
                </Item>
                {selected && <Icon icon="ok" size="lg" />}
              </Flex>
            );
          })}
        </SportWrapper>
      </SportDialog>
    );
  }
);

export default FilterSport;
