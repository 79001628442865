import React from 'react';
import { Box, Flex } from 'theme-ui';

import { Text, Icon } from '../../../components/Primitives';

import { danger, primaryText } from '../../../theme/colors';

type Props = {
  isToast?: boolean;
};

const PaymentErrorBox = ({ isToast }: Props) => {
  return (
    <Flex
      sx={{
        border: '1px solid',
        borderColor: danger,
        borderRadius: '4px',
        p: '3',
        bg: isToast ? 'danger' : 'transparent',
      }}
    >
      <Box mr="2">
        <Icon icon="sad-face" fill="danger" />
      </Box>
      <Box
        sx={{
          flex: 1,
          textAlign: 'left',
        }}
      >
        <Text variant="lb.md" color={isToast ? 'white' : primaryText}>
          Issue with your payment for Rovo Premium.
        </Text>
        <Text>
          We were unable to charge your payment record on file. Please update
          your payment method to keep Rovo Premium.
        </Text>
        {isToast && (
          <Text
            sx={{
              fontWeight: 500,
              mt: 3,
              cursor: 'pointer',
            }}
            onClick={() => (window.location.href = 'premium/billing')}
            color={danger}
          >
            Click to update payment method
          </Text>
        )}
      </Box>
    </Flex>
  );
};

export default PaymentErrorBox;
