import React, { useEffect, useState } from 'react';
import { Box, Flex, Spinner } from 'theme-ui';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';

import { Button, Text, Icon } from '../../../components/Primitives';
import { premiumStore } from '../../../stores/PremiumStore';

import CancelPremiumDialog from './CancelPremiumDialog';
import { benefits } from '../PremiumBenefits';

type Props = {
  setCancel: (value: boolean) => void;
};

const CancelPremium = observer(({ setCancel }: Props) => {
  const history = useHistory();

  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const {
    premiumSubscription,
    getSubscriptions,
    subscriptions,
    state,
    renewedDate,
  } = premiumStore;

  useEffect(() => {
    if (!subscriptions && state !== 'LOADING')
      history.push('/premium/purchase');
  }, [subscriptions, state, history]);

  useEffect(() => {
    if (!subscriptions.length) {
      getSubscriptions();
    }
  }, [getSubscriptions, subscriptions]);

  return (
    <Box>
      {state === 'LOADING' ? (
        <Flex
          sx={{
            justifyContent: 'center',
          }}
        >
          <Spinner />
        </Flex>
      ) : (
        <Box
          sx={{
            mx: [3, 'auto'],
            mt: [3, 5],
          }}
        >
          <Flex
            sx={{
              maxWidth: '480px',
              flexDirection: 'column',
              alignItems: 'center',
              mx: 'auto',
              px: 3,
            }}
          >
            {!!premiumSubscription && (
              <Text mb="24px">
                Are you sure you want to cancel your Premium Pack subscription?
                You will loose all of benefits{' '}
                {premiumSubscription.phaseType === 'TRIAL'
                  ? 'today'
                  : `after ${renewedDate}`}
              </Text>
            )}

            <Box mb="24px">
              {benefits.map((benefit) => (
                <Flex
                  key={benefit.title.en}
                  sx={{
                    alignItems: 'center',
                    ':not(:last-child)': {
                      mb: 3,
                    },
                  }}
                >
                  <Icon icon="close" fill="danger" />
                  <Text variant="lb.md" ml="2">
                    {benefit.title.en}
                  </Text>
                </Flex>
              ))}
            </Box>
            <Button
              fullWidth
              size="md"
              mb="3"
              onClick={() => history.push('/home')}
            >
              Nevermind
            </Button>
            <Button
              fullWidth
              size="md"
              variant="outline.gray"
              onClick={() => setIsOpenDialog(true)}
            >
              Continue to cancel subscription
            </Button>
          </Flex>
        </Box>
      )}
      {isOpenDialog && (
        <CancelPremiumDialog
          isOpen={isOpenDialog}
          onClose={() => setIsOpenDialog(false)}
        />
      )}
    </Box>
  );
});

export default CancelPremium;
