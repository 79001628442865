import React, { useEffect } from 'react';
import { Box, Flex, Image } from 'theme-ui';

import { Text } from '../../components/Primitives';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';

import { TitleText } from './LandingPage.styled';
import LocalStorageHelper from '../../helpers/localStorage';
import UnAuthHeader from '../../components/Layout/UnAuth/Header';
import Footer from '../../components/Layout/UnAuth/Footer';

const LandingPage: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    const currentUser = LocalStorageHelper.get('currentUser');
    if (currentUser?.session) {
      history.push('/explore');
    }
  }, [history]);

  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <UnAuthHeader />
      <Flex
        pt={[4, 0]}
        sx={{
          height: ['calc(100vh - 56px - 70px)', 'calc(100vh - 56px - 60px)'],
          flexDirection: ['column', 'row'],
        }}
      >
        <Flex
          sx={{
            width: '100%',
            flexDirection: 'column',
            justifyContent: isDesktop ? 'center' : 'flex-start',
          }}
          pl={['24px', '10%']}
          pr={['24px', '0']}
        >
          <Box
            sx={{
              textAlign: isDesktop ? 'left' : 'center',
            }}
          >
            <TitleText variant={isDesktop ? 'dp.md' : 'hd.lg'} mb="3" />
          </Box>
          <Text
            sx={{
              textAlign: isDesktop ? 'left' : 'center',
            }}
            variant={isDesktop ? 'pg.xl' : 'pg.lg'}
            mb={isDesktop ? '48px' : '24px'}
          >
            Everything you need for sport right at your fingertips.
          </Text>
          <Flex
            mb="3"
            sx={{
              justifyContent: ['center', 'flex-start'],
            }}
          >
            <Box mr="3">
              <a
                rel="noopener noreferrer"
                href="https://apps.apple.com/us/app/rovo-find-sports-buddies/id1044009295?mt=8"
              >
                <Image
                  alt="Download on the App Store"
                  src="/images/apple-store-badge.png"
                />
              </a>
            </Box>
            <Box>
              <a
                rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=com.rovo.rovo&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              >
                <Image
                  alt="Get it on Google Play"
                  src="/images/google-play-badge.png"
                />
              </a>
            </Box>
          </Flex>
          <Text
            variant="pg.sm"
            color="#3E4C59"
            sx={{
              textAlign: ['center', 'left'],
            }}
          >
            Supports 30+ sports worldwide.
          </Text>
        </Flex>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            backgroundImage: 'url(/images/bg1.png)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'right',
          }}
        ></Box>
      </Flex>
      <Box
        sx={{
          width: '100%',
          zIndex: -1,
          height: 'auto',
          display: ['none', 'block'],
          backgroundImage: 'url(/images/overlay-home.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'right',
          position: 'absolute',
          mixBlendMode: 'darken',
          top: '0px',
          right: '0px',
        }}
      ></Box>
      <Footer />
    </Box>
  );
};

export default LandingPage;
