import React, { useEffect, useCallback } from 'react';
import { Flex } from 'rebass';
import { observer } from 'mobx-react-lite';
import throttle from 'lodash/throttle';

import { userStore } from '../../stores/UserStore';

import Timeline from '../Timeline';
import AddFeedBox from './AddFeedBox';

import styled from '../../theme/styled';

const Body = styled(Flex)``;

const FeedBody: React.FC = observer(() => {
  const {
    feed,
    account: { profile },
    feedLoading,
    getFeed,
  } = userStore;

  useEffect(() => {
    getFeed(true);
  }, [getFeed]);

  const handleScroll = useCallback(
    throttle(() => {
      if (
        window.innerHeight + document.documentElement.scrollTop <
        document.documentElement.offsetHeight - 300
      ) {
        return;
      }
      getFeed();
    }, 500),
    []
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <Body as="main" flexDirection="column">
      <AddFeedBox profile={profile} />
      <Timeline
        profile={profile}
        isSelf
        timeline={feed}
        timelineLoading={feedLoading}
      />
    </Body>
  );
});

export default FeedBody;
