import { observable, action, runInAction } from 'mobx';
import { getActivities } from '../api/SearchAPI';
import { updateProfilePlayStatus } from '../api/ActivityAPI';
import { IActivity } from '../interface/Activity';
import { SEARCH_LIMIT } from '../constant';

class ActivityStore {
  @observable
  activities: IActivity[] = [];

  @observable
  parties: IActivity[] = [];

  @observable
  loading: boolean = false;

  @observable
  hasMore: boolean = true;

  query: {
    offset: number;
  } = { offset: 0 };

  @action
  getActivities = async (query?: Object, isReset?: boolean) => {
    if (this.loading) return;
    if (!this.hasMore && !isReset) return;
    if (isReset) this.query.offset = 0;
    this.loading = true;
    let activities: IActivity[] = [];
    try {
      activities = await getActivities({
        ...this.query,
        ...query,
      });
    } catch {}
    const dataLength = activities.length;
    runInAction(() => {
      if (dataLength < SEARCH_LIMIT) this.hasMore = false;
      if (!isReset) {
        this.activities = [...this.activities, ...activities];
      } else {
        this.activities = activities;
      }
      this.loading = false;
      this.query.offset =
        dataLength === SEARCH_LIMIT
          ? this.query.offset + dataLength
          : this.query.offset;
    });
  };

  @action
  setActivities = (activities: IActivity[]) => {
    this.activities = activities;
  };

  @action
  setParties = (parties: IActivity[]) => {
    this.parties = parties;
  };

  @action
  updateActivity = async (gameId: string, status: 'DECLINED' | 'ACCEPTED') => {
    try {
      const activity = await updateProfilePlayStatus(gameId, status);
      runInAction(() => {
        const index = this.activities.findIndex(
          (activity) => activity.id === gameId
        );
        const partyIndex = this.parties.findIndex(
          (party) => party.id === gameId
        );
        if (index > -1) this.activities[index] = activity;
        if (partyIndex > -1) this.parties[index] = activity;
      });
    } catch (error) {
      throw error;
    }
  };
}

export const activityStore = new ActivityStore();
