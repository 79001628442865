import React from 'react';
import { Box, Flex, Image } from 'theme-ui';
import { observer } from 'mobx-react-lite';

import { Avatar, Text, Icon, Button } from '../../../components/Primitives';
import { ReactComponent as OK } from '../../../assets/illustrator/colored-ok.svg';
import { userStore } from '../../../stores/UserStore';

const SuccessPurchasePremiumDialog = observer(() => {
  const { account } = userStore;

  return (
    <Flex
      variant="card"
      sx={{
        alignItems: 'center',
        textAlign: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: '480px',
        mx: 'auto',
        mt: [3, '120px'],
        p: 4,
      }}
    >
      <Flex
        sx={{
          alignItems: 'center',
          mb: 4,
        }}
      >
        <Avatar size="md" pulseLevel="BLAZE" src={account.profile.picture} />
        <Text variant="lb.lg" mx="2">
          {account.profile.name}
        </Text>
        <Icon icon="premium" size="md" />
      </Flex>
      <OK />
      <Text variant="lb.lg" mt="3">
        Awesome.
      </Text>
      <Text variant="lb.lg" mb="2">
        You’re Premium now.{' '}
        <span role="img" aria-label="awesome">
          🎉
        </span>
      </Text>
      <Text
        variant="pg.sm"
        mb="4"
        sx={{
          maxWidth: '250px',
        }}
      >
        Now open Rovo App to explore all the advanced features that Premium is
        bringing to you
      </Text>
      <Flex>
        <Box mr="3">
          <a
            rel="noopener noreferrer"
            href="https://apps.apple.com/us/app/rovo-find-sports-buddies/id1044009295?mt=8"
          >
            <Image
              alt="Download on the App Store"
              src="/images/apple-store-badge.png"
            />
          </a>
        </Box>
        <Box>
          <a
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=com.rovo.rovo&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          >
            <Image
              alt="Get it on Google Play"
              src="/images/google-play-badge.png"
            />
          </a>
        </Box>
      </Flex>
      <Button
        variant="outline.gray"
        size="lg"
        mt="3"
        onClick={() => {
          window.location.href = '/home';
        }}
      >
        Or continue using the web
      </Button>
    </Flex>
  );
});

export default SuccessPurchasePremiumDialog;
