import styled from '../../theme/styled';
import { Box, Flex } from 'rebass';
import { Dialog } from '@blueprintjs/core';
import PerfectScrollBar from 'react-perfect-scrollbar';

import { Icon } from '../Primitives';

export const Wrapper = styled(Dialog)`
  margin: 0px;
  padding: 0px;
  height: 100vh;
  width: 100vw;
  @media (min-width: 768px) {
    width: 500px;
    height: 100%;
  }
  .bp3-tab-panel {
    margin-top: 0px;
  }
  .bp3-tab-indicator-wrapper .bp3-tab-indicator {
    background-color: #fafafa;
    opacity: 0.6;
  }
  .bp3-tabs {
    position: relative;
    top: -38px;
    margin-bottom: -38px;
  }
  .bp3-tab-list {
    position: sticky;
    z-index: 1;
    top: 56px;
  }
`;

export const PageWrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
  .bp3-tab-panel {
    margin-top: 0px;
  }
  .bp3-tab-indicator-wrapper .bp3-tab-indicator {
    background-color: #fafafa;
    opacity: 0.6;
  }
  .bp3-tabs {
    position: relative;
    top: -38px;
    margin-bottom: -38px;
  }
  .bp3-tab-list {
    position: sticky;
    z-index: 1;
    top: 56px;
  }
`;

export const Header = styled(Flex)`
  align-items: center;
  position: sticky;
  z-index: 1;
  top: 0px;
`;

export const BackIcon = styled(Icon)`
  cursor: pointer;
  :hover {
    background-color: ${(p) => p.theme.colors.primaryHover};
  }
  border-radius: 100%;
  path {
    stroke: 'white';
  }
`;

export const MoreIcon = styled(Icon)``;

export const TabTitle = styled(Box)`
  padding: 4px 16px;
`;

export const TabWrapper = styled(Flex)`
  color: ${(p) => p.theme.colors.primaryText};
  flex-direction: column;
`;

export const PeopleNeeded = styled(Flex)`
  background-color: ${(p) => p.theme.colors.coral};
  border-radius: 32px;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
`;

export const Footer = styled(Flex)`
  position: sticky;
  bottom: -1px;
  z-index: 1;
  border-top: ${(p) => p.theme.border.searchInput};
`;

export const Body = styled(Flex)``;

export const ChatBodyWrapper = styled(Flex)`
  flex-direction: column;
  overflow: auto;
  padding: 0px 8px;
  height: 100%;
  > div {
    flex: 0 0 auto;
  }
`;

export const CommentWrapper = styled(Box)`
  border-bottom: ${(p) => p.theme.border.box};
`;

export const FullImageDialog = styled(Dialog)`
  padding: 16px;
  width: initial;
  max-width: 80%;
  max-height: 80%;
  margin: 0px;
`;

export const SearchInvite = styled.input`
  padding: 0px;
  border: none;
  outline: none;
`;

export const AvatarWrapper = styled(PerfectScrollBar)`
  display: flex;
  overflow: auto;
  flex: none;
  height: auto;
`;
