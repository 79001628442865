import React, { FunctionComponent } from 'react';
import { Box, Flex } from 'theme-ui';

import { Icon, Text, Button } from '../Primitives';
import ReviewStar from '../UI/ReviewStar';

import DummyImg from '../../assets/dummy-img.png';

import { IVenue } from '../../interface/Venue';
import { useHistory } from 'react-router-dom';
import { useStores } from '../../hooks/useStore';

type Props = {
  venue: IVenue;
};

const VenueCard: FunctionComponent<Props> = ({ venue }) => {
  const { venueStore } = useStores();
  const { getSelectedVenue } = venueStore;

  const {
    venueName,
    media,
    location: { address },
    reviewSummary: { overallStars, reviewsNumber },
    facilityGroups,
    pageInfo,
    canCallToBook,
    phoneNumber,
  } = venue;

  const onlineBookingEnabled = facilityGroups.some(
    (f) => f.onlineBookingEnabled
  );

  const firstImage = media.length && media[0].url;

  const history = useHistory();

  const onClickVenue = (event: React.MouseEvent) => {
    event.stopPropagation();
    history.push(`/venues/${pageInfo.uri}`);
    getSelectedVenue(pageInfo.uri);
  };

  const onClickBooking = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (!onlineBookingEnabled) return;
    history.push(`/venues/${pageInfo.uri}?booking=true`);
  };

  return (
    <Box p="0" variant="card" onClick={onClickVenue} sx={{ cursor: 'pointer' }}>
      <Box
        sx={{
          backgroundImage: `url(${firstImage || DummyImg})`,
          width: '100%',
          height: '140px',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        }}
      />
      <Box p="3">
        <Text truncate variant="lb.lg">
          {venueName}
        </Text>
        <ReviewStar
          isShowTotal
          overallStars={overallStars}
          reviewsNumber={reviewsNumber}
        />
        <Flex
          sx={{
            alignItems: 'center',
          }}
          mt="3"
        >
          <Flex
            sx={{
              minWidth: '20px',
              alignItems: 'center',
            }}
          >
            <Icon icon="location" />
          </Flex>
          <Text
            sx={{
              flex: 1,
            }}
            truncate
            color="primaryText"
          >
            {address}
          </Text>
        </Flex>
      </Box>
      <Flex
        sx={{
          justifyContent: 'center',
        }}
        px="3"
        pb="3"
      >
        <Button
          sx={{
            width: '100%',
          }}
          variant="outline"
          onClick={onClickBooking}
        >
          {onlineBookingEnabled ? (
            'Book instantly'
          ) : canCallToBook && phoneNumber ? (
            <a href={`tel:${phoneNumber}`}>Call to book</a>
          ) : (
            'View'
          )}
        </Button>
      </Flex>
    </Box>
  );
};

export default VenueCard;
