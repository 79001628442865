import React, { useMemo } from 'react';
import { PageBody, PageHeading, TabTitle } from '../Schedule.styled';
import { Text } from 'rebass';
import { Icon } from '../../Primitives';
import { useHistory } from 'react-router-dom';
import { Box } from 'rebass';
import { Tabs, Tab } from '@blueprintjs/core';
import FacilityBookingsTab from './FacilityBookingsTab';
import LocalStorageHelper from '../../../helpers/localStorage';
import PastActivitiesTab from '../../Profile/Plays/PastTab';

interface Props {
  tabId: string;
  setTabId: (tabId: string) => void;
}

const HistoryBody: React.FC<Props> = ({ tabId, setTabId }) => {
  const history = useHistory();
  const currentUser = LocalStorageHelper.get('currentUser');

  const username = currentUser?.account?.profile?.username;

  const params = useMemo(
    () => ({
      username,
      params: { past: true },
    }),
    [username]
  );

  return (
    <PageBody>
      <PageHeading
        display="flex"
        height="56px"
        alignItems="center"
        width="100%"
        px={['3', '24px']}
      >
        <Icon
          icon="chevron-left"
          fill="primaryText"
          size="lg"
          onClick={() => history.push('/schedule/activities')}
        />
        <Text variant="headline.small" ml="1">
          History
        </Text>
      </PageHeading>
      <Box>
        <Tabs
          id="schedule-tabs"
          renderActiveTabPanelOnly
          onChange={(id: string) => {
            setTabId(id);
            history.push(`/schedule/history/${id}`);
          }}
          selectedTabId={tabId}
        >
          <Tab
            id="activities"
            title={<TabTitle>Activities</TabTitle>}
            panel={<PastActivitiesTab params={params} />}
          />
          <Tab
            id="facility-bookings"
            title={<TabTitle>Facility Booking</TabTitle>}
            panel={<FacilityBookingsTab />}
          />
        </Tabs>
      </Box>
    </PageBody>
  );
};

export default HistoryBody;
