import React, { useState, useEffect } from 'react';

import { ISportProfile } from '../../../../interface/People';
import { getSportAssessment } from '../../../../api/UtilsAPI';
import { userStore } from '../../../../stores/UserStore';

import SportDetail from './SportDetail';
import SelfAssessment from './SelfAssessment';

interface Props {
  selectedSport: ISportProfile | null;
  onClose: Function;
  isSelf: boolean;
  setSelectedSport: Function;
}

const SportDetailContainer: React.FC<Props> = ({
  selectedSport,
  onClose,
  isSelf,
  setSelectedSport,
}) => {
  const [assessmentFlow, setAssessment] = useState([]);
  const [isSelfAssessment, setIsSelfAssessment] = useState(false);

  const assessment = selectedSport?.assessment || [
    {
      id: 'base',
      name: 'Base',
      value: 1,
    },
  ];

  useEffect(() => {
    if (!selectedSport) return;
    const getSportAssessmentFlow = async () => {
      const assessmentFlow = await getSportAssessment(selectedSport.sport);
      setAssessment(assessmentFlow);
    };
    getSportAssessmentFlow();
  }, [selectedSport]);

  const onEditBio = (bio: string) => {
    if (!selectedSport) return;
    const updatedData = {
      ...selectedSport,
      bio,
    };
    setSelectedSport(updatedData);
    userStore.updateSportProfile(updatedData);
  };

  const onEditPreferences = (
    preferenceId: string,
    attributeId: string,
    value: 0 | 1
  ) => {
    if (!selectedSport) return;
    const updatedData = { ...selectedSport };
    const preferenceIndex = updatedData.sportPreferences.findIndex(
      p => p.id === preferenceId
    );
    let attributeIndex = -1;
    if (preferenceIndex !== -1) {
      attributeIndex = updatedData.sportPreferences[
        preferenceIndex
      ].attributes.findIndex(a => a.id === attributeId);
    }
    if (attributeIndex > -1 && preferenceIndex > -1) {
      updatedData.sportPreferences[preferenceIndex].attributes[
        attributeIndex
      ].value = value;
      updatedData.preferences = updatedData.sportPreferences;
    }
    setSelectedSport(updatedData);
    userStore.updateSportProfile(updatedData);
  };

  const onEditSelfAssessment = (
    assessment: { id: string; name: string; value: number }[]
  ) => {
    if (selectedSport !== null) {
      const updatedData = selectedSport;
      updatedData.assessment = assessment;
      userStore.updateSportProfile(updatedData);
    }
  };

  return isSelfAssessment ? (
    <SelfAssessment
      assessmentFlow={assessmentFlow}
      setIsSelfAssessment={setIsSelfAssessment}
      assessment={assessment}
      saveSelfAssessment={onEditSelfAssessment}
    />
  ) : (
    <SportDetail
      selectedSport={selectedSport}
      onClose={onClose}
      isSelf={isSelf}
      onEditBio={onEditBio}
      onEditPreferences={onEditPreferences}
      setIsSelfAssessment={setIsSelfAssessment}
    />
  );
};

export default SportDetailContainer;
