import styled from '../../theme/styled';
import { Flex, Image, Card } from 'rebass';

import Text from '../UI/Text';

export const Wrapper = styled(Flex)`
  z-index: 3;
  @media (min-width: 768px) {
    height: 100vh;
    flex-direction: column;
    position: sticky;
    top: 0;
    padding-left: 16px;
  }
`;

export const ContainerWrapper = styled(Flex)`
  width: 100%;
  z-index: 1;
`;

export const Logo = styled(Image)`
  display: none;
  cursor: pointer;
  @media (min-width: 768px) {
    display: block;
  }
`;

export const NavigationItem = styled(Flex)`
  cursor: pointer;
  height: 48px;
  :hover {
    color: ${p => p.theme.colors.primary};
  }
`;

export const NavigationWrapper = styled(Flex)`
  justify-content: space-around;
  position: fixed;
  bottom: 0px;
  width: 100%;
  @media (min-width: 768px) {
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const ItemName = styled(Text)`
  @media (min-width: 768px) {
    display: block;
  }
`;

export const SettingMenuWrapper = styled(Card)`
  position: absolute;
  z-index: 9999;
  top: 12px;
  left: 32px;
  box-shadow: 4px 4px 20px #667190;
  @media (min-width: 1280px) {
    right: 0px;
    left: inherit;
  }
`;
