import React, { useEffect, useState } from 'react';
import { Flex, Box } from 'rebass';
import startCase from 'lodash/startCase';
import { observer } from 'mobx-react-lite';

import { FacilityBookingLoading } from '../../UI/ContentLoading';
import { Text, Button } from '../../Primitives';

import { fromServer } from '../../../helpers/datetime';
import { centsToDecimal } from '../../../helpers/currency';
import { useStores } from '../../../hooks/useStore';

import RescheduleBooking from '../../FacilityBooking/RescheduleBooking';
import CancellationBookingDialog from './CancelationBookingDialog';

import { DetailText } from '../Schedule.styled';
interface Props {
  orderId: string;
  isShowAction?: boolean;
  setProcessingStatus?: (status: string) => void;
}

const tag: { [index: string]: any } = {
  pending: 'paymentTag.unpaid',
  paid: 'paymentTag.paid',
  canceled: 'paymentTag.canceled',
};

const statusText: { [index: string]: any } = {
  pending: 'UNPAID',
  paid: 'PAID',
  canceled: 'CANCELED',
};

const TransactionDetail: React.FC<Props> = observer(
  ({ orderId, isShowAction, setProcessingStatus }) => {
    const { facilityBookingStore } = useStores();
    const {
      order,
      getOrderDetail,
      getPolicy,
      cancelableBookingIds,
      reschedulableBookings,
      minHoursCancel,
      customerCancelationAfterReschedule,
      rescheduledByCustomer,
    } = facilityBookingStore;

    const [isCancel, setIsCancel] = useState(false);
    const [isReschedule, setIsReschedule] = useState(false);

    useEffect(() => {
      if (!!orderId) {
        getOrderDetail(orderId);
      }
      if (!!orderId && isShowAction) {
        getPolicy(orderId);
      }
    }, [orderId, isShowAction, getOrderDetail, getPolicy]);

    useEffect(() => {
      if (order && order.id === orderId) {
        setProcessingStatus?.(order.processingStatus);
      }
    });

    if (!order || order?.id !== orderId)
      return (
        <Box>
          <FacilityBookingLoading />
        </Box>
      );

    const venue = order.venues?.length ? order.venues[0] : null;

    return (
      <>
        <DetailText>
          <Text variant="pg.md">Transaction type</Text>
          <Text variant="pg.md">{startCase(order.serviceType)}</Text>
        </DetailText>
        <DetailText>
          <Text variant="pg.md">Date & time</Text>
          <Text variant="pg.md">
            {fromServer(
              order.createdAt,
              venue ? venue.timezone : '',
              'EEE, dd MMM y, h:mm a'
            )}
          </Text>
        </DetailText>
        <DetailText>
          <Text variant="pg.md">Transaction ID</Text>
          <Text variant="pg.md">{order.readableId.readableId}</Text>
        </DetailText>
        <DetailText>
          <Text variant="pg.md">Payment</Text>
          <Flex alignItems="center">
            <Box variant={tag[order.status]}>{statusText[order.status]}</Box>
            <Text variant="pg.md" ml="2">
              {startCase(order.paymentMethod)}
            </Text>
          </Flex>
        </DetailText>
        <Box variant="line" my="2" />
        <Text py="2" variant="lb.lg">
          {venue?.venueName}
        </Text>
        <Box>
          {order.orderLineItems.map((item) => {
            if (item.targetType !== 'facilityBooking')
              return (
                <Box key={item.targetId}>
                  <Flex justifyContent="space-between">
                    <Text variant="pg.md">{item.description}</Text>
                    <Text variant="lb.md">
                      {item.price.price} {item.price.currency}
                    </Text>
                  </Flex>
                  <Box variant="line" my="2" />
                </Box>
              );
            const date = fromServer(
              item.facilityBooking?.timeRange[0],
              venue?.timezone,
              'EEE d, MMM y'
            );
            const start = fromServer(
              item.facilityBooking?.timeRange[0],
              venue?.timezone,
              'h:mm a'
            );
            const end = fromServer(
              item.facilityBooking?.timeRange[1],
              venue?.timezone,
              'h:mm a'
            );
            return (
              <Box key={item.id}>
                <Text variant="pg.md">
                  {item.facilityBooking?.facility?.name}
                </Text>
                <Text variant="pg.md">{date}</Text>
                <Flex justifyContent="space-between">
                  <Text variant="pg.md">
                    {start} - {end}
                  </Text>
                  <Text variant="lb.md">
                    {item.price.price === 0
                      ? 'Free'
                      : `${item.price.price} ${item.price.currency}`}
                  </Text>
                </Flex>
                <Box variant="line" my="2" />
              </Box>
            );
          })}
        </Box>
        <Box>
          {!!order?.processingFee?.amount && (
            <Flex justifyContent="space-between">
              <Text variant="lb.sm">Tax & non-refundable processing fees</Text>
              <Text variant="pg.sm">
                {order.processingFee.amount} {order.currency}
              </Text>
            </Flex>
          )}
          <Flex justifyContent="space-between">
            <Text variant="lb.lg">Total</Text>
            <Text variant="lb.md">
              {order.totalAmount === 0
                ? 'Free'
                : `${centsToDecimal(order.totalAmount, order.currency)} ${
                    order.currency
                  }`}
            </Text>
          </Flex>
        </Box>
        {isShowAction && (
          <Box
            sx={{
              mt: 'auto',
              position: 'sticky',
              bottom: '0px',
              bg: '#fafafa',
              pb: [3, 0],
              pt: [3, 3],
            }}
          >
            {!!reschedulableBookings.length && order.status !== 'canceled' && (
              <Button
                variant="outline.normal"
                sx={{
                  width: '100%',
                  mb: 2,
                }}
                onClick={() => setIsReschedule(true)}
              >
                <Text variant="lb.md">Change booking slot</Text>
              </Button>
            )}
            {!!cancelableBookingIds.length && order.status !== 'canceled' ? (
              <Button
                variant="outline.normal"
                sx={{
                  width: '100%',
                }}
                onClick={() => setIsCancel(true)}
              >
                <Text variant="lb.md">Cancelation this booking</Text>
              </Button>
            ) : (
              <Text
                variant="lb.sm"
                sx={{
                  textAlign: 'center',
                }}
              >
                {!!minHoursCancel
                  ? !customerCancelationAfterReschedule && rescheduledByCustomer
                    ? `Cancellations are not allowed after rescheduling slots`
                    : `Cancellation are allowed only before ${minHoursCancel} hours
                prior to the slot time as per the cancellation policy of the
                venue.`
                  : order.status !== 'canceled' &&
                    'This facility does not support online cancellation.'}
              </Text>
            )}
          </Box>
        )}
        {isReschedule && order && (
          <RescheduleBooking
            isOpen={isReschedule}
            onClose={() => setIsReschedule(false)}
            venueId={order.venues[0].id}
          />
        )}
        {isCancel && (
          <CancellationBookingDialog
            currency={order.currency}
            isOpen={isCancel}
            onClose={() => setIsCancel(false)}
          />
        )}
      </>
    );
  }
);

export default TransactionDetail;
