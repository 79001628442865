import { observable, runInAction, computed, action } from 'mobx';
import { sendTextComment, sendImageComment } from '../api/InboxAPI';
import { getComments, updateActivityLike } from '../api/ProfileAPI';
import { getPostData } from '../api/ActivityAPI';
import { IComment } from '../interface/Inbox';

import { DateTime } from 'luxon';
import { userStore } from './UserStore';
import { SEARCH_LIMIT } from '../constant';
import IActivityTimeline from '../interface/ActivityTimeline';

class PostDetailStore {
  @observable
  post: IActivityTimeline | undefined;

  @observable
  comments: IComment[] = [];

  @observable
  loading: boolean = false;

  @observable
  hasMoreComments: boolean = true;

  @observable
  loadingData: boolean = false;

  @computed
  get before() {
    return !!this.comments.length
      ? this.comments[this.comments.length - 1].position
      : undefined;
  }

  @action
  getPost = async (activityId: string) => {
    this.loadingData = true;
    try {
      const post = await getPostData(activityId);
      runInAction(() => {
        this.post = post;
        this.loadingData = false;
      });
    } catch (error) {
      this.loadingData = false;
    }
  };

  @action
  getComments = async (id: string) => {
    if (!this.hasMoreComments || this.loading) return;
    if (this.loading) return;
    this.loading = true;
    try {
      const comments = await getComments('ACTIVITY', id, {
        limit: 20,
        before: this.before,
      });
      runInAction(() => {
        this.comments = [...this.comments, ...comments].sort(
          (a, b) => a.position - b.position
        );
        this.hasMoreComments = comments.length !== SEARCH_LIMIT ? false : true;
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action
  sendTextComment = async (activityId: string, text: string) => {
    const comment: IComment = {
      position: this.comments[0]?.position ? this.comments[0].position + 1 : 1,
      id: 'pre-message',
      content: {
        text,
      },
      type: 'TEXT',
      senderId: userStore.account.profileId,
      updatedAt: DateTime.local().toISO(),
      createdAt: DateTime.local().toISO(),
      deleteAt: null,
      sender: userStore.account.profile,
    };
    this.comments = [comment, ...this.comments];
    try {
      const comment = await sendTextComment('ACTIVITY', activityId, text);
      runInAction(() => {
        this.comments[0] = comment;
      });
    } catch (error) {
      runInAction(() => {
        this.comments.shift();
      });
    }
  };

  @action
  sendImageComment = async (activityId: string, image: File) => {
    try {
      const comment = await sendImageComment('ACTIVITY', activityId, image);
      runInAction(() => {
        this.comments = [comment, ...this.comments];
      });
    } catch (error) {}
  };

  @action
  updateLike = async (activityId: string, userLiked: boolean) => {
    try {
      await updateActivityLike(activityId, userLiked);
      runInAction(() => {
        if (this.post) {
          this.post.custom.userLiked = userLiked;
          this.post.custom.likesCount = userLiked
            ? this.post.custom.likesCount + 1
            : this.post.custom.likesCount - 1;
        }
      });
    } catch (error) {}
  };
}

export default PostDetailStore;
