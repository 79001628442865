import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { Grid, Flex } from 'theme-ui';
import cogoToast from 'cogo-toast';

import { useStores } from '../../hooks/useStore';
import MakeBooking from './Steps/MakeBooking';
import SelectSlots from './Steps/SelectSlots';
import ReviewSlots from './Steps/ReviewSlots';

import { FacilityBookingDialog } from './FacilityBooking.styled';
import { Icon, Text } from '../Primitives';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  venueId: string;
}

export interface ISelectedSlot {
  facilityId: string;
  start: string;
  end: string;
  date: string;
  name: string;
  price?: { price: number; currency: string };
}

const titles = ['Slots to reschedule', 'Select new slots', 'Confirm new slots'];

const RescheduleBooking: React.FC<Props> = observer(
  ({ isOpen, onClose, venueId }) => {
    const { venueStore, facilityBookingStore } = useStores();

    const { getSelectedVenue, selectedVenue, partnerProfile } = venueStore;
    const { selectedLineItems, totalPrice } = facilityBookingStore;

    useEffect(() => {
      if (!selectedVenue?.id || selectedVenue?.id !== venueId) {
        getSelectedVenue(venueId, true);
      }
    }, [getSelectedVenue, selectedVenue, venueId]);

    const [step, setStep] = useState(1);
    const [date, setDate] = useState(DateTime.local());

    const [selectedSlots, setSelectedSlots] = useState<ISelectedSlot[]>([]);

    const onClickSlot = (slot: ISelectedSlot, isSelected: boolean) => {
      let updateSelectedSlots = [...selectedSlots];
      if (isSelected) {
        updateSelectedSlots = updateSelectedSlots.filter(
          (s) =>
            !(
              s.start === slot.start &&
              s.facilityId === slot.facilityId &&
              s.date === slot.date
            )
        );
      } else {
        if (selectedSlots.length === selectedLineItems.length) return;
        const total = [...selectedSlots, slot].reduce((total, current) => {
          if (current.price?.price) {
            return total + current.price.price;
          }
          return total;
        }, 0);
        if (total > totalPrice)
          return cogoToast.warn(
            'Total price of rescheduled slots cannot exceed current price'
          );
        updateSelectedSlots.push(slot);
      }
      setSelectedSlots(updateSelectedSlots);
    };

    const handleClickBack = () => {
      if (step === 1) {
        return onClose();
      }
      if (step === 2) {
        setSelectedSlots([]);
      }
      setStep((s) => s - 1);
    };

    return (
      <FacilityBookingDialog isOpen={isOpen} onClose={onClose}>
        <Grid columns="32px 1fr 32px" p="3">
          <Flex
            sx={{
              alignItems: 'center',
            }}
          >
            <Icon
              icon="chevron-left"
              fill="primaryText"
              size="lg"
              onClick={handleClickBack}
            />
          </Flex>
          <Flex
            sx={{
              alignItems: 'center',
            }}
          >
            <Text variant="lb.md">{titles[step - 1]}</Text>
          </Flex>
          <Flex
            sx={{
              textAlign: 'right',
              alignItems: 'center',
            }}
          >
            <Icon icon="close" size="lg" onClick={onClose} />
          </Flex>
        </Grid>
        {step === 1 && selectedVenue && partnerProfile && (
          <SelectSlots setStep={setStep} timezone={selectedVenue.timezone} />
        )}
        {step === 2 && (
          <MakeBooking
            setStep={setStep}
            onClickSlot={onClickSlot}
            selectedSlots={selectedSlots}
            setSelectedSlots={setSelectedSlots}
            date={date}
            setDate={setDate}
            isReschedule
            numOfReschedule={selectedLineItems.length}
          />
        )}
        {step === 3 && selectedVenue && (
          <ReviewSlots
            onClose={onClose}
            timezone={selectedVenue.timezone}
            selectedSlots={selectedSlots}
          />
        )}
      </FacilityBookingDialog>
    );
  }
);

export default RescheduleBooking;
