import React from 'react';
import styled from '../../theme/styled';

const SwitchCheckbox = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
  :checked + .react-switch-label .react-switch-button {
    left: calc(100% + 2px);
    transform: translateX(-100%);
  }
  :checked + .react-switch-label {
    background-color: ${p => p.theme.colors.primary};
  }
`;

const SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 30px;
  height: 12px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
`;

const SwitchBtn = styled.span`
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
`;

const Switch: React.FC<{
  checked: boolean;
  onChange?: Function;
  onClick?: any;
}> = ({ checked, onChange, onClick }) => {
  return (
    <>
      <SwitchCheckbox
        id="react-switch-new"
        type="checkbox"
        checked={checked}
        onChange={e => onChange && onChange(e.target.checked)}
      />
      <SwitchLabel
        onClick={onClick}
        className="react-switch-label"
        htmlFor="react-switch-new"
      >
        <SwitchBtn className="react-switch-button" />
      </SwitchLabel>
    </>
  );
};

export default Switch;
