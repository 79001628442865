import React, { Dispatch, SetStateAction } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Flex, Card } from 'rebass';
import { Spinner } from '@blueprintjs/core';

import { searchStore } from '../../stores/SearchStore';

import Text from '../UI/Text';
import {
  ActivityBlock,
  PeopleBlock,
  VenueBlock,
  GroupBlock,
} from './components/SearchResultBlock';
import styled from '../../theme/styled';
import EmptyList from '../UI/EmptyList';
import { useHistory } from 'react-router-dom';

const CardWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
`;

const SeeAllBtn = styled(Text)`
  :hover {
    text-decoration: underline;
  }
  cursor: pointer;
`;

const SearchTab: React.FC<{
  setSelectedTab: Dispatch<SetStateAction<string>>;
}> = observer(({ setSelectedTab }) => {
  const { loading, activities, people, venues, groups, totalDoc } = searchStore;

  const history = useHistory();

  return !loading ? (
    <Flex flexDirection="column">
      {!totalDoc && <EmptyList text="item" />}
      {!!activities.length && (
        <Box width="100%" mb="4">
          <Flex mb="2" fontWeight="bold" justifyContent="space-between">
            <Text fontSize="16" textTransform="uppercase" color="primaryText">
              Activities
            </Text>
            {activities.length > 3 && (
              <SeeAllBtn
                color="primary"
                onClick={() => setSelectedTab('activities')}
              >
                See all ({activities.length})
              </SeeAllBtn>
            )}
          </Flex>
          <CardWrapper>
            {activities.slice(0, 3).map((activity, index) => (
              <React.Fragment key={activity.id}>
                <ActivityBlock
                  activity={activity}
                  onClick={() => history.push(`/plays/${activity.id}`)}
                />
                {index !== 2 && <Box variant="line" my="2" />}
              </React.Fragment>
            ))}
          </CardWrapper>
        </Box>
      )}
      {!!people.length && (
        <Box width="100%" mb="4">
          <Flex mb="2" fontWeight="bold" justifyContent="space-between">
            <Text fontSize="16" textTransform="uppercase" color="primaryText">
              People
            </Text>
            {people.length > 4 && (
              <SeeAllBtn
                color="primary"
                onClick={() => setSelectedTab('people')}
              >
                See all ({people.length})
              </SeeAllBtn>
            )}
          </Flex>
          <CardWrapper>
            {people.slice(0, 4).map((person, index) => (
              <React.Fragment key={person.id}>
                <PeopleBlock
                  person={person}
                  onClick={() => history.push(`/${person.username}`)}
                />
                {index !== 3 && <Box variant="line" mt="1" mb="2" />}
              </React.Fragment>
            ))}
          </CardWrapper>
        </Box>
      )}
      {!!groups.length && (
        <Box width="100%" mb="4">
          <Flex mb="2" fontWeight="bold" justifyContent="space-between">
            <Text fontSize="16" textTransform="uppercase" color="primaryText">
              Groups
            </Text>
            {groups.length > 4 && (
              <SeeAllBtn
                color="primary"
                onClick={() => setSelectedTab('groups')}
              >
                See all ({groups.length})
              </SeeAllBtn>
            )}
          </Flex>
          <CardWrapper>
            {groups.slice(0, 4).map((group, index) => (
              <React.Fragment key={group.id}>
                <GroupBlock
                  group={group}
                  onClick={() =>
                    history.push(`/groups/${group.groupProfile.uri}`)
                  }
                />
                {index !== 3 && <Box variant="line" mt="2" mb="3" />}
              </React.Fragment>
            ))}
          </CardWrapper>
        </Box>
      )}
      {!!venues.length && (
        <Box width="100%" mb="4">
          <Flex mb="2" fontWeight="bold" justifyContent="space-between">
            <Text fontSize="16" textTransform="uppercase" color="primaryText">
              Venues
            </Text>
            {venues.length > 4 && (
              <SeeAllBtn
                color="primary"
                onClick={() => setSelectedTab('venues')}
              >
                See all ({venues.length})
              </SeeAllBtn>
            )}
          </Flex>
          <CardWrapper>
            {venues.slice(0, 4).map((venue, index) => (
              <React.Fragment key={venue.id}>
                <VenueBlock
                  venue={venue}
                  onClick={() => history.push(`/venues/${venue.pageInfo.uri}`)}
                />
                {index !== 3 && <Box variant="line" mt="2" mb="3" />}
              </React.Fragment>
            ))}
          </CardWrapper>
        </Box>
      )}
    </Flex>
  ) : (
    <Spinner />
  );
});

export default SearchTab;
