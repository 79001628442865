import React, { FunctionComponent, SyntheticEvent } from 'react';
import { Flex } from 'rebass';
import { observer } from 'mobx-react-lite';

import Text from '../../UI/Text';

import { FilterDialog, Item } from './FilterBar.styled';
import { filterStore } from '../../../stores/FilterStore';
import { SORT_OPTIONS } from '../../../helpers/search';
import { Icon } from '../../Primitives';

type Props = {
  isOpen: boolean;
  onClose: (event?: SyntheticEvent<HTMLElement, Event> | undefined) => void;
  selectedTab: string;
};

const FilterTypes: FunctionComponent<Props> = observer(
  ({ isOpen, onClose, selectedTab = 'activities' }) => {
    const { selectSortType, sortTypes } = filterStore;

    return (
      <FilterDialog isOpen={isOpen} onClose={onClose}>
        <Flex fontSize="3" alignItems="center" justifyContent="center">
          <Text color="primaryText">Sort by</Text>
        </Flex>
        <Flex flexDirection="column" fontSize="3" color="primaryText">
          {SORT_OPTIONS[selectedTab].map(option => (
            <Flex
              py="2"
              justifyContent="space-between"
              key={option}
              onClick={() => {
                selectSortType(option, selectedTab);
                onClose();
              }}
            >
              <Item
                color={
                  option === sortTypes[selectedTab] ? 'primary' : 'primaryText'
                }
              >
                {option}
              </Item>
              {option === sortTypes[selectedTab] && (
                <Icon icon="ok" size="lg" />
              )}
            </Flex>
          ))}
        </Flex>
      </FilterDialog>
    );
  }
);

export default FilterTypes;
