import React from 'react';
import { Box, Flex, Spinner } from 'theme-ui';
import cogoToast from 'cogo-toast';

import { StickyBottomBar, ConfirmBtn } from '../FacilityBooking.styled';

import { Text, Button } from '../../Primitives';
import { resendOrderConfirmation } from '../../../api/VenueAPI';
import TransactionDetail from '../../Schedule/components/TransactionDetail';

import { ReactComponent as IconConfirm } from '../../../assets/illustrator/colored-ok.svg';

interface Props {
  orderId: string;
  readableId: string;
  processingStatus: string;
  setProcessingStatus: (status: string) => void;
  onClose: Function;
}

const ConfirmBooking: React.FC<Props> = ({
  orderId,
  readableId,
  onClose,
  setProcessingStatus,
  processingStatus,
}) => {
  const resendConfirmation = async () => {
    try {
      await resendOrderConfirmation(readableId);
      cogoToast.success('Email have been sent, please check your inbox');
    } catch (error) {
      const message =
        error?.response?.data?.msg || error?.response?.data?.message;
      if (message) {
        cogoToast.error(message);
      }
    }
  };

  return (
    <>
      <Flex
        sx={{
          height: '100%',
          flexWrap: 'wrap',
          marginLeft: '16px',
          marginRight: '16px',
          marginBottom: '16px',
        }}
        p="0"
        variant="card"
        mt="3"
      >
        <Box
          p="3"
          sx={{
            width: ['100%', '50%'],
            borderRight: `1px solid`,
            borderColor: 'divider',
          }}
        >
          {!processingStatus && (
            <Flex
              sx={{
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spinner />
            </Flex>
          )}
          {processingStatus === 'COMMITTED' && (
            <Flex
              mt="5"
              sx={{
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <IconConfirm />
              <Text mt="3" mb="2" variant="hd.sm">
                Booking confirmed. See you soon!
              </Text>
              <Text mb="3" variant="pg.lg">
                You’ll receive an email confirmation regarding your booking.
              </Text>
              <Button
                sx={{
                  width: '100%',
                }}
                size="md"
                variant="outline"
                onClick={resendConfirmation}
              >
                Resend email confirmation
              </Button>
            </Flex>
          )}
          {!!processingStatus && processingStatus !== 'COMMITTED' && (
            <Flex
              sx={{
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              <Text mt="3" mb="2" variant="hd.sm">
                Sorry, something went wrong
              </Text>
              <Text mb="3" variant="pg.lg">
                Your booking was not confirmed. Please contact us at
                support@rovo.co and we will check the status of your booking.
              </Text>
              <Text>Support Phone: 9500948983 / 9789788681</Text>
            </Flex>
          )}
        </Box>
        <Box
          sx={{
            width: ['100%', '50%'],
          }}
          p="3"
        >
          <TransactionDetail
            setProcessingStatus={setProcessingStatus}
            orderId={orderId}
          />
        </Box>
      </Flex>
      <StickyBottomBar mt="2">
        <ConfirmBtn onClick={onClose}>Done</ConfirmBtn>
      </StickyBottomBar>
    </>
  );
};

export default ConfirmBooking;
