import { observable, action, runInAction } from 'mobx';
import { getGroups } from '../api/SearchAPI';
import { joinGroup, leaveGroup } from '../api/GroupAPI';
import { IGroup } from '../interface/Group';
import { SEARCH_LIMIT } from '../constant';
import cogoToast from 'cogo-toast';

class GroupStore {
  @observable
  groups: IGroup[] = [];

  @observable
  loading: boolean = false;

  @observable
  hasMore: boolean = true;

  query: {
    offset: number;
  } = { offset: 0 };

  @action
  getGroups = async (query?: Object, isReset?: boolean) => {
    if (this.loading) return;
    if (!this.hasMore && !isReset) return;
    if (isReset) this.query.offset = 0;
    this.loading = true;
    let groups: IGroup[] = [];
    try {
      groups = await getGroups({
        ...this.query,
        ...query,
      });
    } catch {}
    const dataLength = groups.length;
    runInAction(() => {
      if (dataLength < SEARCH_LIMIT) this.hasMore = false;
      if (!isReset) {
        this.groups = [...this.groups, ...groups];
      } else {
        this.groups = groups;
      }
      this.loading = false;
      this.query.offset =
        dataLength === SEARCH_LIMIT
          ? this.query.offset + dataLength
          : this.query.offset;
    });
  };

  @action
  setGroups = (groups: IGroup[]) => {
    this.groups = groups;
  };

  @action
  joinGroup = async (groupId: number) => {
    try {
      const group = await joinGroup(groupId);
      runInAction(() => {
        const index = this.groups.findIndex((g) => g.id === groupId);
        if (index > -1) {
          this.groups[index] = group;
        }
      });
    } catch (error) {
      runInAction(() => {});
    }
  };

  @action
  leaveGroup = async (groupId: number) => {
    try {
      const group = await leaveGroup(groupId);
      runInAction(() => {
        const index = this.groups.findIndex((g) => g.id === groupId);
        if (index > -1) {
          this.groups[index] = group;
        }
      });
    } catch (error) {
      cogoToast.error(
        error?.response?.message || 'Join group failed. Please try again.'
      );
    }
  };
}

export const groupStore = new GroupStore();
