import React from 'react';
import { Flex, Text } from 'rebass';

import { PaymentMethodCard } from '../FacilityBooking.styled';
import { primary, dark6 } from '../../../theme/colors';

import { Icon } from '../../Primitives';
import { IconNames } from '../../Primitives/Icon/iconTypes';

interface Props {
  name: string;
  description?: string;
  isSelected: boolean;
  icon: IconNames;
  onClick: Function;
  isDisabled?: boolean;
}

const PaymentCard: React.FC<Props> = ({
  children,
  name,
  description,
  icon,
  isSelected,
  onClick = () => {},
  isDisabled,
}) => {
  return (
    <PaymentMethodCard
      onClick={() => {
        if (isDisabled) return;
        onClick();
      }}
      sx={{
        border: 'box',
        borderColor: isSelected ? primary : dark6,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        opacity: isDisabled ? 0.7 : 1,
        ':hover': {
          borderColor: isDisabled ? dark6 : primary,
        },
      }}
    >
      <Flex mb="2" alignItems="center">
        <Icon icon={icon} stroke="primaryText" size="lg" />
        <Text ml="2" variant="body" fontWeight="bold">
          {name}
        </Text>
      </Flex>
      {description && <Text variant="body">{description}</Text>}
      {children}
    </PaymentMethodCard>
  );
};

export default PaymentCard;
