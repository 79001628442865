import {
  getGroups,
  getInboxOfPeopleAndActivities,
  getInboxPairGroups,
  getInboxActivities,
} from './../api/InboxAPI';
import { observable, action, runInAction } from 'mobx';

import { IGroupInbox } from '../interface/Inbox';
import { SEARCH_LIMIT } from '../constant';
import { IActivity } from '../interface/Activity';

interface IPeopleAndActivity {
  type: 'GROUP' | 'PLAY';
  rawObject: any;
  date: string;
}

class InboxStore {
  @observable
  people: any[] = [];

  @observable
  activities: IActivity[] = [];

  @observable
  groups: IGroupInbox[] = [];

  @observable
  peopleAndActivities: IPeopleAndActivity[] = [];

  @observable
  loadingPeople: boolean = false;

  @observable
  loadingActivities: boolean = false;

  @observable
  loadingPeopleAndActivities: boolean = false;

  @observable
  loadingGroups: boolean = false;

  @observable
  hasMorePeople: boolean = true;

  @observable
  hasMoreGroup: boolean = true;

  @observable
  hasMorePeopleAndActivities: boolean = true;

  @observable
  hasMoreActivities: boolean = true;

  peopleParams: { offset?: number; limit?: number } = {
    offset: 0,
    limit: SEARCH_LIMIT,
  };

  groupParams: { offset?: number; limit?: number } = {
    offset: 0,
    limit: SEARCH_LIMIT,
  };

  activityParams: { before?: string; limit?: number } = {
    limit: SEARCH_LIMIT,
  };

  pNaParams: { before?: string; limit?: number } = {
    limit: SEARCH_LIMIT,
  };

  @action
  getPeople = async (query?: string, isReset?: boolean) => {
    if (isReset) {
      this.people = [];
      this.hasMorePeople = true;
      this.peopleParams.offset = 0;
    }
    if (!this.hasMorePeople || this.loadingPeople) return;

    this.loadingPeople = true;
    try {
      const people = await getInboxPairGroups({ ...this.peopleParams, query });
      runInAction(() => {
        this.people = people;
      });
    } catch (error) {
    } finally {
      this.loadingPeople = false;
    }
  };

  @action
  getActivities = async (query?: any, isReset?: boolean) => {
    if (isReset) {
      this.activities = [];
      this.hasMoreActivities = true;
      this.activityParams.before = undefined;
    }
    if (!this.hasMoreActivities || this.loadingActivities) return;
    this.loadingActivities = true;
    try {
      const activities = await getInboxActivities(this.activityParams);
      runInAction(() => {
        this.activities = [...this.activities, ...activities];
        if (activities.length !== SEARCH_LIMIT) {
          this.hasMoreActivities = false;
        }
        this.activityParams.before =
          activities[activities.length - 1].lastCommentAt;
      });
    } catch (error) {
    } finally {
      this.loadingActivities = false;
    }
  };

  @action
  getGroups = async (query?: string, isReset?: boolean) => {
    if (isReset) {
      this.groups = [];
      this.hasMoreGroup = true;
      this.groupParams.offset = 0;
    }
    if (!this.hasMoreGroup || this.loadingGroups) return;
    this.loadingGroups = true;
    try {
      const groups = await getGroups({
        ...this.groupParams,
        query,
      });
      runInAction(() => {
        this.groups = [...this.groups, ...groups];
        if (groups.length !== SEARCH_LIMIT) {
          this.hasMoreGroup = false;
        }
        this.groupParams.offset = this.groups.length;
      });
    } catch (error) {
    } finally {
      this.loadingGroups = false;
    }
  };

  @action
  getPeopleAndActivities = async (query?: any, isReset?: boolean) => {
    if (isReset) {
      this.peopleAndActivities = [];
      this.hasMorePeopleAndActivities = true;
      this.pNaParams.before = undefined;
    }
    if (this.loadingPeopleAndActivities || !this.hasMorePeopleAndActivities)
      return;
    this.loadingPeopleAndActivities = true;
    try {
      const peopleAndActivities = await getInboxOfPeopleAndActivities(
        this.pNaParams
      );
      runInAction(() => {
        this.peopleAndActivities = [
          ...this.peopleAndActivities,
          ...peopleAndActivities,
        ];
        if (peopleAndActivities.length !== SEARCH_LIMIT) {
          this.hasMorePeopleAndActivities = false;
        }
        this.pNaParams.before =
          peopleAndActivities[peopleAndActivities.length - 1].date;
      });
    } catch (error) {
    } finally {
      this.loadingPeopleAndActivities = false;
    }
  };
}

export const inboxStore = new InboxStore();
