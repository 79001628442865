import config from '../config';
import { useCallback, useEffect } from 'react';
import { IAccount } from '../interface/People';
import LocalStorageHelper from '../helpers/localStorage';
import { userStore } from '../stores/UserStore';

const loadScript = () => {
  let id = 'freshchat-lib';
  if (document.getElementById(id) || (window as any).fcWidget) return;
  let script = document.createElement('script');
  script.async = true;
  script.type = 'text/javascript';
  script.src = 'https://wchat.freshchat.com/js/widget.js';
  script.id = id;
  document.head.appendChild(script);
};

interface Settings {
  token: string;
  host?: string;
  siteId?: string;
  externalId?: string | number;
  restoreId?: string | null;
  firstName?: string;
  lastName?: string;
}

interface Props {
  account: IAccount | null;
}

type UserProperties = {
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string | number;
};

type FreshChatEvent = 'user:created';

interface FreshChatEventRes {
  status: number;
  data?: {
    identifier: string;
    restoreId: string;
  };
}

type FreshChatEventCallback = (data: FreshChatEventRes) => void;

type FreshchatWidget = {
  init: (settings: Settings) => void;
  close: VoidFunction;
  user: {
    setProperties: (properties: UserProperties) => void;
    get: (callback: FreshChatEventCallback) => void;
    clear: () => Promise<any>;
  };
  on: (event: FreshChatEvent, callback: FreshChatEventCallback) => void;
};

const host = 'https://wchat.freshchat.com';
const siteId = 'Rovo';

export function useFreshchat() {
  const init = useCallback(() => {
    if ((window as any).fcWidget) {
      const widget: FreshchatWidget = (window as any).fcWidget;
      const currentUser: { account?: IAccount } =
        LocalStorageHelper.get('currentUser') || {};

      if (currentUser.account && currentUser.account?.profileId !== -1) {
        widget.init({
          token: config.FRESHCHAT_TOKEN,
          host,
          siteId,
          externalId: currentUser.account.profile.id,
          restoreId: currentUser.account.freshchatId,
        });
        widget.user.setProperties({
          email: currentUser.account?.email,
          firstName: currentUser.account?.profile.firstName,
          lastName: currentUser.account?.profile.lastName,
          phone: currentUser.account?.phone,
        });
        widget.on('user:created', ({ status, data }) => {
          if (
            status === 200 &&
            data?.restoreId &&
            currentUser.account?.profileId
          ) {
            userStore.updateAccount({ freshchatId: data.restoreId });
          }
        });
      } else {
        widget.user.clear();
        widget.init({
          token: config.FRESHCHAT_TOKEN,
          host,
        });
      }
    } else {
      loadScript();
      const interval = window.setInterval(() => {
        if ((window as any).fcWidget) {
          init();
          clearInterval(interval);
        }
      }, 1000);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    return function destroyWidget() {
      (window as any).fcWidget?.destroy();
    };
  }, []);
}
