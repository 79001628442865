import amplitude from 'amplitude-js';
import branch from 'branch-sdk';
import config from '../config';
import { IAccount } from '../interface/People';

amplitude.getInstance().init(config.AMPLITUDE_KEY);

export const setUserAccount = (account: IAccount) => {
  const instance = amplitude.getInstance();
  if (account != null && account.profile != null) {
    const profile = account.profile;
    const { id, followers, following, sportProfiles = [], gender } = profile;
    const activeSportProfiles = sportProfiles.filter(
      sportProfile => sportProfile.status === 'ACTIVE'
    );
    const gamesPlayed = activeSportProfiles.reduce(
      (gamesPlayed, sportProfile) => {
        return gamesPlayed + sportProfile.gamesPlayed;
      },
      0
    );

    instance.setUserId(id.toString());
    var properties: any = {
      signUpDate: account.registeredAt || profile.createdAt,
      gamesPlayed: gamesPlayed,
      playersFollowed: following,
      playersFollowing: followers,
      sportsAdded: activeSportProfiles.map(sportProfile => sportProfile.sport),
      gender: gender,
    };
    for (var i = 0; i < activeSportProfiles.length; i++) {
      const sp = activeSportProfiles[i];
      properties[sp.sport.toLowerCase().replace('_', '') + 'Rating'] =
        sp.rating;
    }
    instance.setUserProperties(properties);
  } else {
    // User not authenticated
    instance.setUserId(null);
  }
};

export const getUTMCampaign = (parameters: any) => {
  if (parameters) {
    let url = parameters['$canonical_url'];
    let campaign = new URL(url).searchParams.get('utm_campaign');

    if (campaign && campaign.length > 0) {
      return campaign;
    }

    campaign = parameters['utm_campaign'];
    if (campaign && campaign.length > 0) {
      return campaign;
    }

    campaign = parameters['~campaign'];
    if (campaign && campaign.length > 0) {
      return campaign;
    }
  }
};

export const getUTMMedium = (parameters: any) => {
  if (parameters) {
    let url = parameters['$canonical_url'];
    let campaign = new URL(url).searchParams.get('utm_medium');

    if (campaign && campaign.length > 0) {
      return campaign;
    }

    campaign = parameters['utm_medium'];
    if (campaign && campaign.length > 0) {
      return campaign;
    }

    campaign = parameters['~channel'];
    if (campaign && campaign.length > 0) {
      return campaign;
    }
  }
};

export const getUTMSource = (parameters: any) => {
  if (parameters) {
    let url = parameters['$canonical_url'];
    let campaign = new URL(url).searchParams.get('utm_source');

    if (campaign && campaign.length > 0) {
      return campaign;
    }

    campaign = parameters['utm_source'];
    if (campaign && campaign.length > 0) {
      return campaign;
    }
  }
};

export const trackEvent = (eventName: string, parameters: any) => {
  if (config.ENV === 'dev') return;
  amplitude.getInstance().logEvent(eventName, parameters);
};

let isBranchSdkInitialized = false;
export const trackPageView = (pageName: string) => {
  if (config.ENV === 'dev') return;
  let parameters: any = {
    name: pageName,
    pagePath: window.location.pathname,
    pageTitle: window.document.title,
    pageUrl: window.location.href,
    pageParams: window.location.search,
  };
  if (isBranchSdkInitialized) {
    const data = new URL(window.location.href).searchParams;
    parameters.campaign = data.get('utm_campaign');
    parameters.medium = data.get('utm_medium');
    parameters.source = data.get('utm_source');
    trackEvent('ViewPage', parameters);
  } else {
    branch.init(config.BRANCH_KEY, function(err: any, data: any) {
      isBranchSdkInitialized = true;
      if (err) {
        trackPageViewError(pageName, null, err.message);
        return;
      }

      let parsedData = (data && data.data_parsed) || {};
      const clickedBranchLink = parsedData['+clicked_branch_link'];

      if (!clickedBranchLink) {
        parsedData['$canonical_url'] = window.location.href;
      }
      const campaign = getUTMCampaign(parsedData);
      const medium = getUTMMedium(parsedData);
      const source = getUTMSource(parsedData);
      // Track page view
      parameters.campaign = getUTMCampaign(parsedData);
      parameters.medium = getUTMMedium(parsedData);
      parameters.source = getUTMSource(parsedData);
      trackEvent('ViewPage', parameters);

      if (!clickedBranchLink) {
        return;
      }

      let url =
        parsedData['$desktop_url'] ||
        parsedData['$deeplink_path'] ||
        parsedData['$canonical_url'];
      if (url.indexOf(`://`) === -1) {
        url = `https://${url}`;
      }
      url = new URL(
        parsedData['$desktop_url'] ||
          parsedData['$deeplink_path'] ||
          parsedData['$canonical_url']
      );

      if (parsedData['pid']) {
        url.searchParams.append('pid', parsedData['pid']);
      }
      if (campaign) {
        url.searchParams.append('utm_campaign', campaign);
      }
      if (medium) {
        url.searchParams.append('utm_medium', medium);
      }

      if (source) {
        url.searchParams.append('utm_source', source);
      }
    });
  }
};

export const trackPageViewError = (
  pageName: string,
  errorCode: number | null,
  errorMessage?: string
) => {
  if (config.ENV === 'dev') return;
  let parameters: any = {
    eventName: 'ViewPage',
    name: pageName,
  };
  if (errorCode) {
    parameters['errorCode'] = errorCode;
  }

  if (errorMessage) {
    parameters['errorMessage'] = errorMessage;
  }
  trackEvent('Exception', parameters);
};
