import React from 'react';
import { Button, ButtonProps, Spinner } from 'theme-ui';
import Text from './Text';

// type ButtonVariant = 'sl.primary' | 'ol.primary' | 'fl.primary';

type ButtonSize = 'sm' | 'md' | 'lg';

enum TextSize {
  sm = 'lb.sm',
  md = 'lb.md',
  lg = 'lb.lg',
}

enum ButtonHeight {
  sm = '36px',
  md = '48px',
  lg = '56px',
}

interface Props extends ButtonProps {
  loading?: boolean;
  size?: ButtonSize;
  fullWidth?: boolean;
  // variant?: ButtonVariant;
}

const PrimitiveButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ loading, size = 'sm', fullWidth, children, sx, ...props }, ref) => {
    return (
      <Button
        {...props}
        ref={ref}
        sx={{
          cursor: 'pointer',
          height: ButtonHeight[size],
          ':disabled': {
            cursor: 'not-allowed',
          },
          width: fullWidth ? '100%' : 'initial',
          ...sx,
        }}
      >
        {loading ? (
          <Spinner color="background" size="16" />
        ) : (
          <Text variant={TextSize[size]}>{children}</Text>
        )}
      </Button>
    );
  }
);

export default PrimitiveButton;
