import React, { useEffect, useState } from 'react';
import { Flex, Button } from 'rebass';
import { observer } from 'mobx-react-lite';

import Text from '../../../UI/Text';
import { Icon } from '../../../Primitives';

import { ISport } from '../../../../interface/Utils';
import { getSports } from '../../../../api/UtilsAPI';
import { userStore } from '../../../../stores/UserStore';

import SelectSport from './SelectSport';
import SelectSportLevel from './SelectSportLevel';

export type CurrentSport = {
  sport: string;
  level: string;
  name: string;
};

const AddSport: React.FC<{
  selectedSports: string[];
  close?: Function;
}> = observer(({ selectedSports, close }) => {
  const { addSports } = userStore;

  const [sports, setSports] = useState<ISport[]>([]);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState('SPORT');
  const [currentSports, setCurrentSports] = useState<CurrentSport[]>([]);

  useEffect(() => {
    async function getSportsData() {
      setLoading(true);
      try {
        const sports: ISport[] = await getSports();
        const filteredSports = sports.filter(
          (sport) => !selectedSports.includes(sport.id) && sport.enabled === '1'
        );
        setSports(filteredSports);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
    getSportsData();
  }, [selectedSports]);

  const handleAddSports = async () => {
    await addSports(
      currentSports.map((sport) => ({ sport: sport.sport, level: sport.level }))
    );
  };

  return (
    <Flex flexDirection="column" p="3" height="100%">
      <Flex justifyContent="space-between" alignItems="center" mb="3">
        <Flex
          alignItems="center"
          fontSize="16px"
          color="primaryText"
          fontWeight="bold"
        >
          {step === 'SPORT' ? (
            <>
              {close && (
                <Icon icon="chevron-left" size="md" onClick={() => close} />
              )}
              <Text>What sports do you play?</Text>
            </>
          ) : (
            <>
              <Icon
                icon="chevron-left"
                fill="primaryText"
                size="md"
                onClick={() => setStep('SPORT')}
              />
              <Text ml="2">How good are you at these?</Text>
            </>
          )}
        </Flex>
        <Button
          variant="noPadding"
          disabled={!currentSports.length}
          onClick={() => {
            if (!currentSports.length) return;
            setStep('SKILL');
            if (step === 'SKILL') {
              handleAddSports();
              close && close();
            }
          }}
        >
          Next
        </Button>
      </Flex>
      {step === 'SPORT' ? (
        <SelectSport
          sports={sports}
          setCurrentSports={setCurrentSports}
          currentSports={currentSports}
          setStep={setStep}
          loading={loading}
        />
      ) : (
        <SelectSportLevel
          currentSports={currentSports}
          setCurrentSports={setCurrentSports}
        />
      )}
    </Flex>
  );
});

export default AddSport;
