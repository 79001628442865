import { IconNames } from './iconTypes';

// stroke
import { ReactComponent as AccessibilityHuman } from '../../../assets/icons/stroke/accessibility-human.svg';
import { ReactComponent as Activity } from '../../../assets/icons/stroke/activity.svg';
import { ReactComponent as Add } from '../../../assets/icons/stroke/add.svg';
import { ReactComponent as Alarm } from '../../../assets/icons/stroke/alarm.svg';
import { ReactComponent as AppsAlt } from '../../../assets/icons/stroke/apps-alt.svg';
import { ReactComponent as Apps } from '../../../assets/icons/stroke/apps.svg';
import { ReactComponent as Arrange } from '../../../assets/icons/stroke/arrange.svg';
import { ReactComponent as ArrowDownCircle } from '../../../assets/icons/stroke/arrow-down-circle.svg';
import { ReactComponent as ArrowDown } from '../../../assets/icons/stroke/arrow-down.svg';
import { ReactComponent as ArrowLeftBottom } from '../../../assets/icons/stroke/arrow-left-bottom.svg';
import { ReactComponent as ArrowLeftCircle } from '../../../assets/icons/stroke/arrow-left-circle.svg';
import { ReactComponent as ArrowLeftTop } from '../../../assets/icons/stroke/arrow-left-top.svg';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/stroke/arrow-left.svg';
import { ReactComponent as ArrowRightBottom } from '../../../assets/icons/stroke/arrow-right-bottom.svg';
import { ReactComponent as ArrowRightCircle } from '../../../assets/icons/stroke/arrow-right-circle.svg';
import { ReactComponent as ArrowRightTop } from '../../../assets/icons/stroke/arrow-right-top.svg';
import { ReactComponent as ArrowRight } from '../../../assets/icons/stroke/arrow-right.svg';
import { ReactComponent as ArrowUpCircle } from '../../../assets/icons/stroke/arrow-up-circle.svg';
import { ReactComponent as ArrowUp } from '../../../assets/icons/stroke/arrow-up.svg';
import { ReactComponent as Bag } from '../../../assets/icons/stroke/bag.svg';
import { ReactComponent as Bell } from '../../../assets/icons/stroke/bell.svg';
import { ReactComponent as Bill } from '../../../assets/icons/stroke/bill.svg';
import { ReactComponent as Bookmark } from '../../../assets/icons/stroke/bookmark.svg';
import { ReactComponent as CalendarAdd } from '../../../assets/icons/stroke/calendar-add.svg';
import { ReactComponent as CalendarDecline } from '../../../assets/icons/stroke/calendar-decline.svg';
import { ReactComponent as CalendarEvent } from '../../../assets/icons/stroke/calendar-event.svg';
import { ReactComponent as Calendar } from '../../../assets/icons/stroke/calendar.svg';
import { ReactComponent as Camera } from '../../../assets/icons/stroke/camera.svg';
import { ReactComponent as Chart } from '../../../assets/icons/stroke/chart.svg';
import { ReactComponent as Chat } from '../../../assets/icons/stroke/chat.svg';
import { ReactComponent as ChevronDown } from '../../../assets/icons/stroke/chevron-down.svg';
import { ReactComponent as ChevronLeft } from '../../../assets/icons/stroke/chevron-left.svg';
import { ReactComponent as ChevronRight } from '../../../assets/icons/stroke/chevron-right.svg';
import { ReactComponent as ChevronUp } from '../../../assets/icons/stroke/chevron-up.svg';
import { ReactComponent as ChevronsDown } from '../../../assets/icons/stroke/chevrons-down.svg';
import { ReactComponent as ChevronsLeft } from '../../../assets/icons/stroke/chevrons-left.svg';
import { ReactComponent as ChevronsRight } from '../../../assets/icons/stroke/chevrons-right.svg';
import { ReactComponent as ChevronsUp } from '../../../assets/icons/stroke/chevrons-up.svg';
import { ReactComponent as Clip } from '../../../assets/icons/stroke/clip.svg';
import { ReactComponent as Close } from '../../../assets/icons/stroke/close.svg';
import { ReactComponent as Comment } from '../../../assets/icons/stroke/comment.svg';
import { ReactComponent as ControlsAlt } from '../../../assets/icons/stroke/controls-alt.svg';
import { ReactComponent as ControlsVerticalAlt } from '../../../assets/icons/stroke/controls-vertical-alt.svg';
import { ReactComponent as CreditCard } from '../../../assets/icons/stroke/credit-card.svg';
import { ReactComponent as Cup } from '../../../assets/icons/stroke/cup.svg';
import { ReactComponent as Dollar } from '../../../assets/icons/stroke/dollar.svg';
import { ReactComponent as Drag } from '../../../assets/icons/stroke/drag.svg';
import { ReactComponent as Edit } from '../../../assets/icons/stroke/edit.svg';
import { ReactComponent as EllipsisVertical } from '../../../assets/icons/stroke/ellipsis-vertical.svg';
import { ReactComponent as Ellipsis } from '../../../assets/icons/stroke/ellipsis.svg';
import { ReactComponent as Explore } from '../../../assets/icons/stroke/explore.svg';
import { ReactComponent as Favorite } from '../../../assets/icons/stroke/favorite.svg';
import { ReactComponent as Feed } from '../../../assets/icons/stroke/feed.svg';
import { ReactComponent as Female } from '../../../assets/icons/stroke/female.svg';
import { ReactComponent as Hamburger } from '../../../assets/icons/stroke/hamburger.svg';
import { ReactComponent as HappyFace } from '../../../assets/icons/stroke/happy-face.svg';
import { ReactComponent as HomeAlt } from '../../../assets/icons/stroke/home-alt.svg';
import { ReactComponent as Info } from '../../../assets/icons/stroke/info.svg';
import { ReactComponent as Language } from '../../../assets/icons/stroke/language.svg';
import { ReactComponent as Link } from '../../../assets/icons/stroke/link.svg';
import { ReactComponent as Location } from '../../../assets/icons/stroke/location.svg';
import { ReactComponent as LockAlt } from '../../../assets/icons/stroke/lock-alt.svg';
import { ReactComponent as Male } from '../../../assets/icons/stroke/male.svg';
import { ReactComponent as ManualTrack } from '../../../assets/icons/stroke/manual-track.svg';
import { ReactComponent as Map } from '../../../assets/icons/stroke/map.svg';
import { ReactComponent as Minus } from '../../../assets/icons/stroke/minus.svg';
import { ReactComponent as Moon } from '../../../assets/icons/stroke/moon.svg';
import { ReactComponent as NeutralFace } from '../../../assets/icons/stroke/neutral-face.svg';
import { ReactComponent as New } from '../../../assets/icons/stroke/new.svg';
import { ReactComponent as News } from '../../../assets/icons/stroke/news.svg';
import { ReactComponent as Ok } from '../../../assets/icons/stroke/ok.svg';
import { ReactComponent as People } from '../../../assets/icons/stroke/people.svg';
import { ReactComponent as PersonAdd } from '../../../assets/icons/stroke/person-add.svg';
import { ReactComponent as PersonFind } from '../../../assets/icons/stroke/person-find.svg';
import { ReactComponent as Person } from '../../../assets/icons/stroke/person.svg';
import { ReactComponent as Plus } from '../../../assets/icons/stroke/plus.svg';
import { ReactComponent as Pulse } from '../../../assets/icons/stroke/pulse.svg';
import { ReactComponent as Refresh } from '../../../assets/icons/stroke/refresh.svg';
import { ReactComponent as Remove } from '../../../assets/icons/stroke/remove.svg';
import { ReactComponent as SadFace } from '../../../assets/icons/stroke/sad-face.svg';
import { ReactComponent as Search } from '../../../assets/icons/stroke/search.svg';
import { ReactComponent as Send } from '../../../assets/icons/stroke/send.svg';
import { ReactComponent as Settings } from '../../../assets/icons/stroke/settings.svg';
import { ReactComponent as ShareAndroid } from '../../../assets/icons/stroke/share-android.svg';
import { ReactComponent as Shield } from '../../../assets/icons/stroke/shield.svg';
import { ReactComponent as Star } from '../../../assets/icons/stroke/star.svg';
import { ReactComponent as Sun } from '../../../assets/icons/stroke/sun.svg';
import { ReactComponent as Sunset } from '../../../assets/icons/stroke/sunset.svg';
import { ReactComponent as Support } from '../../../assets/icons/stroke/support.svg';
import { ReactComponent as ThumbDown } from '../../../assets/icons/stroke/thumb-down.svg';
import { ReactComponent as ThumbUp } from '../../../assets/icons/stroke/thumb-up.svg';
import { ReactComponent as Time } from '../../../assets/icons/stroke/time.svg';
import { ReactComponent as Timer } from '../../../assets/icons/stroke/timer.svg';
import { ReactComponent as TrackScore } from '../../../assets/icons/stroke/track-score.svg';
import { ReactComponent as Training } from '../../../assets/icons/stroke/training.svg';
import { ReactComponent as User } from '../../../assets/icons/stroke/user.svg';
import { ReactComponent as Wallet } from '../../../assets/icons/stroke/wallet.svg';
import { ReactComponent as Window } from '../../../assets/icons/stroke/window.svg';
import { ReactComponent as ZoomIn } from '../../../assets/icons/stroke/zoom-in.svg';
import { ReactComponent as ZoomOut } from '../../../assets/icons/stroke/zoom-out.svg';

// colored
import { ReactComponent as BellColored } from '../../../assets/icons/colored/bell.svg';
import { ReactComponent as CalendarColored } from '../../../assets/icons/colored/calendar.svg';
import { ReactComponent as ChatColored } from '../../../assets/icons/colored/chat.svg';
import { ReactComponent as FeedColored } from '../../../assets/icons/colored/feed.svg';
import { ReactComponent as HeartColored } from '../../../assets/icons/colored/heart.svg';
import { ReactComponent as HomeAltColored } from '../../../assets/icons/colored/home-alt.svg';
import { ReactComponent as PolygonGreen } from '../../../assets/icons/colored/polygon-green.svg';
import { ReactComponent as StarEmptyColored } from '../../../assets/icons/colored/star-empty.svg';
import { ReactComponent as StarHalfColored } from '../../../assets/icons/colored/star-half.svg';
import { ReactComponent as StarFullColored } from '../../../assets/icons/colored/star-full.svg';

// pulse
import { ReactComponent as Blaze } from '../../../assets/icons/pulse/blaze.svg';
import { ReactComponent as Ember } from '../../../assets/icons/pulse/ember.svg';
import { ReactComponent as Firestorm } from '../../../assets/icons/pulse/firestorm.svg';
import { ReactComponent as Flame } from '../../../assets/icons/pulse/flame.svg';
import { ReactComponent as Flicker } from '../../../assets/icons/pulse/flicker.svg';
import { ReactComponent as Inferno } from '../../../assets/icons/pulse/inferno.svg';
import { ReactComponent as Spark } from '../../../assets/icons/pulse/spark.svg';
import { ReactComponent as Wildfire } from '../../../assets/icons/pulse/wildfire.svg';

// badge
import { ReactComponent as ActivityCover } from '../../../assets/icons/badge/activity-cover.svg';
import { ReactComponent as BadgeCover } from '../../../assets/icons/badge/badge-cover.svg';
import { ReactComponent as Beginner } from '../../../assets/icons/badge/beginner.svg';
import { ReactComponent as NoBadge } from '../../../assets/icons/badge/no-badge.svg';
import { ReactComponent as Premium } from '../../../assets/icons/stylized/rovo-premium.svg';
import { ReactComponent as Rovopay } from '../../../assets/icons/badge/rovopay.svg';

// payment
import { ReactComponent as AmericanExpress } from '../../../assets/icons/payment/american-express.svg';
import { ReactComponent as JCB } from '../../../assets/icons/payment/jcb.svg';
import { ReactComponent as MasterCard } from '../../../assets/icons/payment/master-card.svg';
import { ReactComponent as Visa } from '../../../assets/icons/payment/visa.svg';

type IconList = {
  [key in IconNames]: React.FC<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
};

export const iconList: IconList = {
  // stroke
  'accessibility-human': AccessibilityHuman,
  activity: Activity,
  add: Add,
  alarm: Alarm,
  'apps-alt': AppsAlt,
  apps: Apps,
  arrange: Arrange,
  'arrow-down-circle': ArrowDownCircle,
  'arrow-down': ArrowDown,
  'arrow-left-bottom': ArrowLeftBottom,
  'arrow-left-circle': ArrowLeftCircle,
  'arrow-left-top': ArrowLeftTop,
  'arrow-left': ArrowLeft,
  'arrow-right-bottom': ArrowRightBottom,
  'arrow-right-circle': ArrowRightCircle,
  'arrow-right-top': ArrowRightTop,
  'arrow-right': ArrowRight,
  'arrow-up-circle': ArrowUpCircle,
  'arrow-up': ArrowUp,
  bag: Bag,
  bell: Bell,
  bill: Bill,
  bookmark: Bookmark,
  'calendar-add': CalendarAdd,
  'calendar-decline': CalendarDecline,
  'calendar-event': CalendarEvent,
  calendar: Calendar,
  camera: Camera,
  chart: Chart,
  chat: Chat,
  'chevron-down': ChevronDown,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'chevron-up': ChevronUp,
  'chevrons-down': ChevronsDown,
  'chevrons-left': ChevronsLeft,
  'chevrons-right': ChevronsRight,
  'chevrons-up': ChevronsUp,
  clip: Clip,
  close: Close,
  comment: Comment,
  'controls-alt': ControlsAlt,
  'controls-vertical-alt': ControlsVerticalAlt,
  'credit-card': CreditCard,
  cup: Cup,
  dollar: Dollar,
  drag: Drag,
  edit: Edit,
  'ellipsis-vertical': EllipsisVertical,
  ellipsis: Ellipsis,
  explore: Explore,
  favorite: Favorite,
  feed: Feed,
  female: Female,
  hamburger: Hamburger,
  'happy-face': HappyFace,
  'home-alt': HomeAlt,
  info: Info,
  language: Language,
  link: Link,
  location: Location,
  'lock-alt': LockAlt,
  male: Male,
  'manual-track': ManualTrack,
  map: Map,
  minus: Minus,
  moon: Moon,
  'neutral-face': NeutralFace,
  new: New,
  news: News,
  ok: Ok,
  people: People,
  'person-add': PersonAdd,
  'person-find': PersonFind,
  person: Person,
  plus: Plus,
  pulse: Pulse,
  refresh: Refresh,
  remove: Remove,
  'sad-face': SadFace,
  search: Search,
  send: Send,
  settings: Settings,
  'share-android': ShareAndroid,
  shield: Shield,
  star: Star,
  sun: Sun,
  sunset: Sunset,
  support: Support,
  'thumb-down': ThumbDown,
  'thumb-up': ThumbUp,
  time: Time,
  timer: Timer,
  'track-score': TrackScore,
  training: Training,
  user: User,
  wallet: Wallet,
  window: Window,
  'zoom-in': ZoomIn,
  'zoom-out': ZoomOut,

  // colored
  'bell-colored': BellColored,
  'calender-colored': CalendarColored,
  'chat-colored': ChatColored,
  'feed-colored': FeedColored,
  'heart-colored': HeartColored,
  'home-alt-colored': HomeAltColored,
  'polygon-green': PolygonGreen,
  'star-empty-colored': StarEmptyColored,
  'star-full-colored': StarFullColored,
  'star-half-colored': StarHalfColored,

  // pulse
  BLAZE: Blaze,
  EMBER: Ember,
  FIRESTORM: Firestorm,
  FLAME: Flame,
  FLICKER: Flicker,
  INFERNO: Inferno,
  SPARK: Spark,
  WILDFIRE: Wildfire,

  //badge
  'activity-cover': ActivityCover,
  'badge-cover': BadgeCover,
  'no-badge': NoBadge,
  beginner: Beginner,
  premium: Premium,
  rovopay: Rovopay,

  // payment
  'american-express': AmericanExpress,
  jcb: JCB,
  'master-card': MasterCard,
  visa: Visa,
};
