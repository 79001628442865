import React from 'react';
import { Spinner } from '@blueprintjs/core';
import { Box, Flex, Text, Image } from 'rebass';

import { Icon } from '../../../Primitives';
import { IconWithText } from '../../../Primitives/Icon';

import { ISportProfile } from '../../../../interface/People';

import {
  SportDetailGraph,
  Achievements,
  BadgeHeight,
  Achievement,
  NumForEachBadge,
} from './SportDetail.styled';

import SportPreference from './SportPreference';
// import SportBio from './SportBio';
import { Ranked, UnRanked } from './SportAssessment';

interface Props {
  selectedSport: ISportProfile | null;
  onClose: Function;
  isSelf: boolean;
  setIsSelfAssessment: Function;
  onEditBio: (bio: string) => void;
  onEditPreferences: (
    preferenceId: string,
    attributeId: string,
    value: 0 | 1
  ) => void;
}

const SportDetail: React.FC<Props> = ({
  selectedSport,
  onClose,
  isSelf,
  onEditBio,
  onEditPreferences,
  setIsSelfAssessment,
}) => {
  if (!selectedSport) return <Spinner intent="primary" />;

  const {
    gamesPlayed,
    achievements,
    sportPreferences,
    rating,
    assessmentPending,
    sportInfo: { name, hasAssessment, assessmentEnabled },
    assessment,
    sport,
  } = selectedSport;

  const badges = achievements.slice().sort((a, b) => {
    return b.playFeedbackIds.length - a.playFeedbackIds.length;
  });

  const largestCount = badges.length ? badges[0].count : 0;

  return (
    <Box bg="white">
      <SportDetailGraph color="primaryText" p="3">
        <Flex
          alignItems="center"
          mb="3"
          justifyContent="space-between"
          color="white"
        >
          <Icon
            icon="chevron-left"
            size="lg"
            fill="white"
            onClick={() => onClose()}
          />
          <Flex>
            <Text mr="2">{name}</Text>
          </Flex>
          <Box width="32px" />
        </Flex>
        {!assessmentPending ? (
          <Ranked
            hasAssessment={hasAssessment}
            assessmentEnabled={assessmentEnabled}
            assessmentPending={assessmentPending}
            rating={rating}
            assessment={assessment}
            isSelf={isSelf}
            name={name}
            sport={sport}
            setIsSelfAssessment={setIsSelfAssessment}
          />
        ) : (
          <UnRanked
            hasAssessment={hasAssessment}
            assessmentEnabled={assessmentEnabled}
            isSelf={isSelf}
          />
        )}
      </SportDetailGraph>
      {/* {(bio || isSelf) && (
        <SportBio
          bio={bio}
          isSelf={isSelf}
          sportName={name}
          onEditBio={onEditBio}
        />
      )} */}
      <Flex
        py="3"
        pl="3"
        mb="3"
        sx={{
          borderTop: '1px solid',
          borderBottom: '1px solid',
          borderColor: 'border',
        }}
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          mr="3"
          color="white"
          minWidth="50px"
        >
          <IconWithText icon="activity-cover" size="badge" text={gamesPlayed} />
          <Text
            fontSize={['12px', '14px']}
            textAlign="center"
            color="primaryText"
          >
            Games
          </Text>
        </Flex>
        {!!badges.length && (
          <Achievements>
            {badges.map(({ count, icon, id, name, playFeedbackIds }) => (
              <Achievement
                key={id}
                mr="3"
                textAlign="center"
                alignItems="center"
                minWidth="60px"
                flexDirection="column"
                onClick={() => {}}
              >
                <Image
                  sx={{
                    filter: !count && 'grayscale(100%)',
                    zIndex: 1,
                    cursor: count && 'pointer',
                  }}
                  key={id}
                  src={icon}
                  size="50px"
                  minWidth="50px"
                />
                {!!count && !!playFeedbackIds.length && (
                  <BadgeHeight
                    height={
                      largestCount ? `${(count / largestCount) * 80}px` : '0px'
                    }
                  />
                )}
                {!!count && !!playFeedbackIds.length && (
                  <NumForEachBadge bg="primaryText" color="white">
                    {count}
                  </NumForEachBadge>
                )}

                <Flex
                  height="30px"
                  alignItems="flex-end"
                  fontSize="12px"
                  mt="2"
                  fontWeight="bold"
                >
                  {name}
                </Flex>
                {!!playFeedbackIds.length && (
                  <Box fontSize="12px">
                    <Text as="span" pr="1">
                      by {playFeedbackIds.length}
                    </Text>
                    <Icon icon="people" size="sm" />
                  </Box>
                )}
              </Achievement>
            ))}
          </Achievements>
        )}
      </Flex>
      {!!sportPreferences.length &&
        sportPreferences.map((sportPreference) => (
          <SportPreference
            key={sportPreference.id}
            isSelf={isSelf}
            onEditPreferences={onEditPreferences}
            sportPreference={sportPreference}
          />
        ))}
    </Box>
  );
};

export default SportDetail;
