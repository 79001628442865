import httpService from '../services/httpService';
import get from 'lodash/get';

export const searchProfiles = async (query?: Object) => {
  try {
    let response = await httpService.get('players/search', {
      params: { ...query },
    });
    const profiles: any = get(response, 'data.profiles') || [];
    return profiles;
  } catch (error) {
    throw error;
  }
};
