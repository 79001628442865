import React, { useEffect, useState } from 'react';
import { Box, Grid, Image, Spinner, Flex, Link } from 'theme-ui';
import { observer } from 'mobx-react-lite';
import Carousel from '@brainhubeu/react-carousel';
import chuck from 'lodash/chunk';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';

import { homeStore } from '../../../stores/HomeStore';
import { activityStore } from '../../../stores/ActivityStore';
import { groupStore } from '../../../stores/GroupStore';
import { Text, Icon } from '../../../components/Primitives';

import { ReactComponent as Activity } from '../../../assets/icons/stylized/activity.svg';
import { ReactComponent as People } from '../../../assets/icons/stylized/person.svg';
import { ReactComponent as Group } from '../../../assets/icons/stylized/groups.svg';
import { ReactComponent as Venue } from '../../../assets/icons/stylized/location.svg';
import { ReactComponent as Premium } from '../../../assets/icons/stylized/rovo-premium.svg';
import { ReactComponent as Training } from '../../../assets/icons/stylized/training.svg';

import PresentCarousel from './PresentCarousel';
import {
  PeopleCard,
  GroupCard,
  VenueCard,
  ActivityCard,
} from '../../../components/Cards';

import JoinDialog from '../../../components/UI/Dialog/JoinDialog';

const HomeBody = observer(() => {
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
  const history = useHistory();

  const { activities, parties } = activityStore;
  const { groups } = groupStore;
  const { getHomeData, sessions, state } = homeStore;

  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    getHomeData();
  }, [getHomeData]);

  return (
    <Box bg="background">
      <Grid
        columns={['1fr auto 1fr']}
        px="24px"
        sx={{
          height: '72px',
          alignItems: 'center',
          boxShadow: 'stroke.bottom',
          display: ['none', 'grid'],
        }}
      >
        <Box />
        <Text variant="lb.lg">Home</Text>
        <Box
          sx={{
            textAlign: 'right',
          }}
        >
          <Icon
            icon="search"
            size="lg"
            onClick={() => history.push('/search')}
          />
        </Box>
      </Grid>
      <Box py="4">
        <Grid columns="1fr 1fr 1fr 1fr" px={[3, 5]} mb="4">
          <Box
            sx={{
              textAlign: 'center',
              cursor: 'pointer',
            }}
            onClick={() => history.push('/explore/activities')}
          >
            <Activity width="48px" height="48px" />
            <Text variant="pg.sm">Activities</Text>
          </Box>
          <Box
            sx={{
              textAlign: 'center',
              cursor: 'pointer',
            }}
            onClick={() => history.push('/explore/people')}
          >
            <People width="48px" height="48px" />
            <Text variant="pg.sm">People</Text>
          </Box>
          <Box
            sx={{
              textAlign: 'center',
              cursor: 'pointer',
            }}
            onClick={() => history.push('/explore/groups')}
          >
            <Group width="48px" height="48px" />
            <Text variant="pg.sm">Groups</Text>
          </Box>
          <Box
            sx={{
              textAlign: 'center',
              cursor: 'pointer',
            }}
            onClick={() => history.push('/explore/venues')}
          >
            <Venue width="48px" height="48px" />
            <Text variant="pg.sm">Venues</Text>
          </Box>
          <Box
            sx={{
              textAlign: 'center',
              cursor: 'pointer',
            }}
            onClick={() => history.push('/premium')}
          >
            <Premium width="48px" height="48px" />
            <Text variant="pg.sm">Premium</Text>
          </Box>
          <Box
            sx={{
              textAlign: 'center',
              cursor: 'pointer',
            }}
            onClick={() => setOpen(true)}
          >
            <Training width="48px" height="48px" />
            <Text variant="pg.sm">Workouts</Text>
          </Box>
        </Grid>
        <Flex
          sx={{
            justifyContent: 'center',
          }}
        >
          {state === 'LOADING' && <Spinner />}
          {state === 'ERROR' && <Text>Oops! Something went wrong</Text>}
        </Flex>
        {state === 'DONE' &&
          sessions.map((session) => {
            switch (session.type) {
              case 'BANNER':
                return (
                  <Box key={session.type}>
                    <Carousel key={session.type} infinite>
                      {session.items
                        // TODO: Remove this when premium is ready
                        .filter((b) => !b.link?.includes('premium'))
                        .map((b) => {
                          return (
                            <Link
                              key={b.id}
                              href={b.link}
                              rel="noreferrer noopener"
                              target="_blank"
                              mx="24px"
                              mb="4"
                            >
                              <Image
                                sx={{
                                  cursor: 'pointer',
                                  borderRadius: '4px',
                                  boxShadow: 'below.low',
                                }}
                                src={b.image}
                              />
                            </Link>
                          );
                        })}
                    </Carousel>
                  </Box>
                );
              case 'PLAY':
                const selected =
                  session.title === 'Activities nearby' ? activities : parties;
                return (
                  <PresentCarousel
                    key={session.type}
                    title={session.title}
                    itemLength={session.items.length}
                    filters={session?.filters}
                    link="/explore/activities"
                  >
                    {selected.map((item) => (
                      <Box
                        p="24px"
                        sx={{
                          width: '100%',
                          height: '100%',
                        }}
                        key={item.id}
                      >
                        <ActivityCard activity={item} />
                      </Box>
                    ))}
                  </PresentCarousel>
                );
              case 'PROFILE':
                return (
                  <PresentCarousel
                    key={session.type}
                    title={session.title}
                    itemLength={session.items.length}
                    link="/explore/people"
                  >
                    {chuck(session.items, isDesktop ? 3 : 1).map(
                      (items, index) => (
                        <Grid
                          sx={{
                            width: '100%',
                            height: '100%',
                            p: '24px',
                          }}
                          columns={['1fr', '1fr 1fr 1fr']}
                          key={index}
                        >
                          {items.map((item) => (
                            <PeopleCard
                              key={item.id}
                              person={item}
                              isVertical={isDesktop}
                            />
                          ))}
                        </Grid>
                      )
                    )}
                  </PresentCarousel>
                );
              case 'GROUP':
                return (
                  <PresentCarousel
                    key={session.type}
                    title={session.title}
                    itemLength={session.items.length}
                    link="/explore/groups"
                  >
                    {chuck(groups, isDesktop ? 2 : 1).map((items, index) => (
                      <Grid
                        sx={{
                          width: '100%',
                          height: '100%',
                          p: '24px',
                        }}
                        columns={['1fr', '1fr 1fr']}
                        key={index}
                      >
                        {items.map((item) => (
                          <GroupCard key={item.id} group={item} />
                        ))}
                      </Grid>
                    ))}
                  </PresentCarousel>
                );
              case 'VENUE':
                return (
                  <PresentCarousel
                    key={session.type}
                    title={session.title}
                    itemLength={session.items.length}
                    link="/explore/venues"
                  >
                    {chuck(session.items, isDesktop ? 2 : 1).map(
                      (items, index) => (
                        <Grid
                          sx={{
                            width: '100%',
                            height: '100%',
                            p: '24px',
                          }}
                          columns={['1fr', '1fr 1fr']}
                          key={index}
                        >
                          {items.map((item) => (
                            <VenueCard key={item.id} venue={item} />
                          ))}
                        </Grid>
                      )
                    )}
                  </PresentCarousel>
                );
              default:
                return null;
            }
          })}
      </Box>
      <JoinDialog
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        title="This feature is currently only available on the Rovo App."
        description=""
        getAppDescription=""
      />
    </Box>
  );
});

export default HomeBody;
