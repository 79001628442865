import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from 'rebass';

import ActivityCard from '../../UI/Card/ActivityCard';
import { ActivityLoading } from '../../UI/ContentLoading';
import EmptyList from '../../UI/EmptyList';

import { IActivity } from '../../../interface/Activity';

import useWindowInfinityScroll from '../../../hooks/useWindowInfinityScroll';
import { userActivityStore } from '../../../stores/PlayStore';

const PastActivitiesTab: FunctionComponent<{ params: Object }> = observer(
  ({ params }) => {
    const { getProfilePlays, activities, hasMore, loading } = userActivityStore;

    useWindowInfinityScroll(getProfilePlays, params);

    return (
      <Box>
        {!!activities.length &&
          activities.map((activity: IActivity) => (
            <Box px="3" py="2" key={activity.id}>
              <ActivityCard activity={activity} />
            </Box>
          ))}
        {!activities.length && !loading && <EmptyList text="activities" />}
        {hasMore && loading && (
          <>
            <ActivityLoading />
            <ActivityLoading />
          </>
        )}
      </Box>
    );
  }
);

export default PastActivitiesTab;
