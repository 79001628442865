import { DateTime, ToRelativeUnit } from 'luxon';

export const fromServer = (
  time: string,
  timezone?: string,
  format?: string
) => {
  return DateTime.fromISO(time)
    .setZone(timezone || 'local')
    .toFormat(format || 'DDDD');
};

export const toServer = (time: string, format: string, timezone?: string) => {
  return DateTime.fromFormat(time, format, { zone: timezone })
    .toUTC()
    .toISO();
};

export const getDuration = (
  start: string,
  end: string,
  unit?: ToRelativeUnit
) => {
  const diff = DateTime.fromISO(end)
    .diff(DateTime.fromISO(start), unit || 'minutes')
    .toObject()[unit || 'minutes'];
  return diff ? Math.abs(diff) : 0;
};

export const isToday = (time: string, timezone?: string) => {
  return DateTime.local().hasSame(
    DateTime.fromISO(time).setZone(timezone || 'local'),
    'day'
  );
};

export const isSameTimeZone = (timezone: string) => {
  return DateTime.local().zoneName === timezone;
};

export const isSameDate = (day1: string, day2: string) => {
  return fromServer(day1, '', 'D') === fromServer(day2, '', 'D');
};

export const getLastActive = (time: string) => {
  const LA = DateTime.fromISO(time);
  const now = DateTime.local();
  const diff = now
    .diff(LA, ['year', 'month', 'day', 'hour', 'minute'])
    .toObject();
  const { years, months, days, hours, minutes } = diff;
  if (years) return years > 1 ? `${years} years ago` : 'a year ago';
  if (months) return months > 1 ? `${months} months ago` : 'a months ago';
  if (days) {
    const displayDay = hours && hours > 12 ? days + 1 : days;
    return displayDay > 1 ? `${displayDay} days ago` : 'a day ago';
  }
  if (hours) {
    const displayHour = minutes && minutes > 30 ? hours + 1 : hours;
    return displayHour > 1 ? `${displayHour} hours ago` : 'an hour ago';
  }
  if (minutes) {
    return Math.floor(minutes || 0) > 1
      ? `${Math.floor(minutes || 0)} minutes ago`
      : 'a moment ago';
  }
  return '';
};
