import React, { FunctionComponent, Dispatch, SetStateAction } from 'react';
import { Box } from 'rebass';
import { Spinner } from '@blueprintjs/core';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { Menu, Item } from './SearchField.styled';
import { useLockBodyScroll } from '../../../hooks/useLockBodyScroll';

type Props = {
  suggest: {
    text: string;
    _id: string;
  }[];
  loading: boolean;
  setMenuStatus: Dispatch<SetStateAction<boolean>>;
  searchString: string;
  addRecentSearch: Function;
  handleSearch: Function;
};

const SearchSuggest: FunctionComponent<Props> = ({
  suggest,
  loading,
  setMenuStatus,
  searchString,
  handleSearch,
}) => {
  const history = useHistory();

  const isLockScroll = useMediaQuery({ query: '(min-width: 768px)' });

  useLockBodyScroll(isLockScroll);

  return (
    <Menu>
      {!!searchString && (
        <Item
          fontSize="16px"
          fontWeight="bold"
          onClick={() => {
            handleSearch(searchString);
          }}>
          Search for "{searchString}"
        </Item>
      )}
      {!loading ? (
        suggest.map(({ text, _id }) => (
          <Item
            key={_id}
            onClick={() => {
              history.push(`/search?q=${text}`);
              setMenuStatus(false);
            }}>
            {text}
          </Item>
        ))
      ) : (
        <Box py="2">
          <Spinner size={24} intent="primary" />
        </Box>
      )}
    </Menu>
  );
};

export default SearchSuggest;
