import { observable, action, runInAction, computed, when } from 'mobx';
import cogoToast from 'cogo-toast';

import {
  getActivityNotifications,
  getGroupJoinRequests,
  getFollowRequests,
  approveGroupJoinRequest,
  deleteGroupJoinRequest,
  markAsReadActivityNotifications,
} from '../api/NotificationAPI';
import { getBanners } from '../api/UtilsAPI';
import { IBanner } from '../interface/Utils';
import { IActivityNotification } from '../interface/Notification';
import { IGroup } from '../interface/Group';
import { SEARCH_LIMIT } from '../constant';
import { IFollower } from '../interface/People';
import { approveFollower, removeFollower } from '../api/ProfileAPI';
import { userStore } from './UserStore';

class NotificationStore {
  constructor() {
    when(() => {
      return userStore.account.id !== -1;
    }, this.getInitData);
  }

  @observable
  activities: IActivityNotification[] = [];

  @observable
  lastActivityId: string | undefined = '';

  @observable
  unseenCount: number = 0;

  @observable
  unreadCount: number = 0;

  @observable
  appNotifications: Object[] = [];

  @observable
  loading: boolean = false;

  @observable
  hasError: boolean = false;

  @action
  getNotifications = async (
    { limit, markSeen }: { limit?: number; markSeen?: boolean },
    isReset?: boolean
  ) => {
    if (isReset) {
      this.activities = [];
      this.lastActivityId = '';
    }
    if (this.loading || this.lastActivityId === undefined) return;
    this.loading = true;
    try {
      const response = await getActivityNotifications({
        idLt: this.lastActivityId,
        limit,
        markSeen,
      });
      runInAction(() => {
        this.activities = [...this.activities, ...response?.activities];
        this.lastActivityId = response?.lastActivityId;
        this.unreadCount = response?.unreadCount;
        this.unseenCount = response?.unseenCount;
        this.appNotifications = response?.appNotifications;
        this.hasError = false;
      });
    } catch (error) {
      this.hasError = true;
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action
  markReadNotifications = async (activityIds: string[]) => {
    try {
      await markAsReadActivityNotifications(activityIds);
    } catch (error) {}
  };

  @observable
  banners: IBanner[] = [];

  @observable
  bannerLoading: boolean = false;

  @action
  getBanners = async () => {
    if (this.banners.length) return;
    this.bannerLoading = true;
    try {
      const banners = await getBanners();
      runInAction(() => {
        this.banners = banners;
        this.hasError = false;
      });
    } catch (error) {
      this.hasError = true;
    } finally {
      runInAction(() => {
        this.bannerLoading = false;
      });
    }
  };

  @observable
  groups: IGroup[] = [];

  groupQuery: { limit: number; offset: number } = {
    limit: SEARCH_LIMIT,
    offset: 0,
  };

  @observable
  groupLoading: boolean = false;

  @observable
  hasMoreGroupRequest: boolean = true;

  @action
  getGroupJoinRequest = async (query?: any, isReset?: boolean) => {
    if (!this.hasMoreGroupRequest || this.groupLoading) return;
    if (isReset) this.groups = [];
    this.groupLoading = true;
    try {
      const groups = await getGroupJoinRequests(this.groupQuery);
      runInAction(() => {
        this.groups = [...this.groups, ...groups];
        this.hasError = false;
        if (groups.length < SEARCH_LIMIT) {
          this.hasMoreGroupRequest = false;
        } else {
          this.groupQuery.offset = this.groupQuery.offset + this.groups.length;
        }
      });
    } catch (error) {
    } finally {
      this.groupLoading = false;
    }
  };

  @observable
  followRequests: IFollower[] = [];

  @observable
  hasMoreFollowRequests: boolean = true;

  @observable
  followLoading: boolean = false;

  @observable
  followQuery: { limit: number; offset: number } = {
    limit: SEARCH_LIMIT,
    offset: 0,
  };

  @action
  getFollowRequests = async (query?: any, isReset?: boolean) => {
    if (!this.hasMoreFollowRequests || this.followLoading) return;
    if (isReset) this.followRequests = [];
    this.followLoading = true;
    try {
      const followRequests = await getFollowRequests(this.followQuery);
      runInAction(() => {
        this.followRequests = [...this.followRequests, ...followRequests];
        if (followRequests.length < SEARCH_LIMIT) {
          this.hasMoreFollowRequests = false;
        } else {
          this.followQuery.offset =
            this.followQuery.offset + this.followRequests.length;
        }
      });
    } catch (error) {
    } finally {
      this.followLoading = false;
    }
  };

  @computed
  get totalNotification() {
    return this.groups.length + this.unseenCount + this.followRequests.length;
  }

  @action
  getInitData = () => {
    this.getNotifications({}, true);
    this.getBanners();
    this.getFollowRequests({}, true);
    this.getGroupJoinRequest({}, true);
  };

  @observable
  actionLoading: boolean = false;

  @action
  approveGroupJoin = async (
    groupId: number,
    memberId: string,
    groupIndex: number,
    memberIndex: number
  ) => {
    this.actionLoading = true;
    try {
      await approveGroupJoinRequest(groupId, memberId);
      runInAction(() => {
        this.groups[groupIndex].members[memberIndex].status = 'ACTIVE';
        if (this.groups[groupIndex].members.every(m => m.status === 'ACTIVE')) {
          this.groups = this.groups.filter(g => g.id !== groupId);
        }
      });
    } catch (error) {
      cogoToast.error(error?.response?.message || 'Approve failed');
    } finally {
      runInAction(() => {
        this.actionLoading = false;
      });
    }
  };

  @action
  deleteGroupJoin = async (
    groupId: number,
    memberId: string,
    groupIndex: number
  ) => {
    this.actionLoading = true;
    try {
      await deleteGroupJoinRequest(groupId, memberId);
      runInAction(() => {
        this.groups[groupIndex].members = this.groups[
          groupIndex
        ].members.filter(m => m.id !== memberId);
        if (this.groups[groupIndex].members.every(m => m.status === 'ACTIVE')) {
          this.groups = this.groups.filter(g => g.id !== groupId);
        }
      });
    } catch (error) {
      cogoToast.error(error?.response?.message || 'Delete failed');
    } finally {
      runInAction(() => {
        this.actionLoading = false;
      });
    }
  };

  @action
  approveFollow = async (profileId: number) => {
    this.actionLoading = true;
    try {
      await approveFollower(profileId);
      runInAction(() => {
        this.followRequests = this.followRequests.filter(
          f => f.follower.id !== profileId
        );
      });
    } catch (error) {
      cogoToast.error(error?.response?.message || 'Approve failed');
    } finally {
      runInAction(() => {
        this.actionLoading = false;
      });
    }
  };

  @action
  deleteFollow = async (profileId: number) => {
    this.actionLoading = true;
    try {
      await removeFollower(profileId);
      runInAction(() => {
        this.followRequests = this.followRequests.filter(
          f => f.follower.id !== profileId
        );
      });
    } catch (error) {
      cogoToast.error(error?.response?.message || 'Delete failed');
    } finally {
      runInAction(() => {
        this.actionLoading = false;
      });
    }
  };
}

export default NotificationStore;
