import httpService from '../services/httpService';

export const getVenueByUri = async (venueUri: string) => {
  try {
    const response = await httpService.get(`partners/pages`, {
      params: {
        uri: venueUri,
        types: ['venue'],
      },
    });
    const pages = response?.data?.data || [];
    const selectedPage =
      pages.find((page: any) => page?.uri === venueUri) || null;
    return selectedPage?.venueDetail
      ? {
          ...selectedPage.venueDetail,
          pageInfo: {
            uri: selectedPage?.uri,
          },
        }
      : null;
  } catch (error) {
    if (error?.response?.statusCode === 404) return null;
    throw error;
  }
};

export const getPartnerByUri = async (uri: string) => {
  try {
    const response = await httpService.get(`partners/pages`, {
      params: {
        uri,
        types: ['partnerProfile'],
      },
    });
    const pages = response?.data?.data || [];
    const selectedPage = pages.find((page: any) => page?.uri === uri) || null;
    return selectedPage?.profileDetail || null;
  } catch (error) {
    if (error?.response?.statusCode === 404) return null;
    throw error;
  }
};

export const getVenuesByPartnerId = async (partnerId: string) => {
  try {
    const response = await httpService.get(
      `partners/partners/${partnerId}/venues`
    );
    return response.data.data;
  } catch (error) {}
};

export const getPartnerProfile = async (partnerId: string) => {
  try {
    const response = await httpService.get(
      `partners/partners/${partnerId}/partnerProfile`
    );
    return response?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getPageReviews = async (
  pageId: string,
  { offset = 0, limit = 20 }: { offset: number; limit: number }
) => {
  try {
    const response = await httpService.get(`partners/pages/${pageId}/reviews`, {
      params: { offset, limit },
    });
    return response?.data?.data;
  } catch (error) {
    if (error?.response?.statusCode === 404) return [];
    throw error;
  }
};

export const createPageReview = async (data: {
  pageId: string;
  text: string;
  rating: number;
  reviewerName: string;
  email: string;
  rovoProfileId: number;
}) => {
  try {
    const response = await httpService.post(
      `partners/page/${data.pageId}/reviews`,
      data
    );
    return response?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getBookingSchedule = async (
  facilityGroupId: string,
  date: string,
  extended: boolean = false
) => {
  try {
    const response = await httpService.get(
      `partners/facilityGroups/${facilityGroupId}/slots`,
      { params: { date, extended } }
    );
    return response?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getCustomerForCurrentProfileByPartnerId = async (
  partnerId: string
) => {
  try {
    const response = await httpService.get(
      `partners/partners/${partnerId}/customers/me`
    );
    return response?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const dryrunFacilityBooking = async (
  partnerId: string,
  data: {
    customer: {
      firstName: string;
      lastName: string;
      email: string;
    };
    bookings: {
      facilityId: string;
      startTime: string;
      endTime: string;
    }[];
    paymentMethod: string;
    customerNote: string;
    couponCode: string;
    customerId?: string;
  }
) => {
  try {
    const response = await httpService.post(
      `partners/partners/${partnerId}/orders/dryRun/forFacilityBookingOrder`,
      data
    );
    return response?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const makeFacilityBooking = async (
  partnerId: string,
  data: {
    customer: {
      firstName: string;
      lastName: string;
      email: string;
    };
    bookings: {
      facilityId: string;
      startTime: string;
      endTime: string;
    }[];
    paymentMethod: string;
    customerNote: string;
    couponCode: string;
    customerId?: string;
    creditInfo?: {
      creditToken: string;
    };
  },
  creditInfo?: {
    creditToken: string;
  }
) => {
  try {
    if (creditInfo?.creditToken) data.creditInfo = creditInfo;
    const response = await httpService.post(
      `partners/partners/${partnerId}/orders/fromFacilityBookings/forSelfBookedCustomer`,
      data
    );
    return response?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const resendOrderConfirmation = async (readableId: string) => {
  try {
    await httpService.post(
      `partners/orders/${readableId}/sendConfirmationEmail`
    );
  } catch (error) {
    throw error;
  }
};

export const checkOrderTransactionStatus = async (
  orderId: string,
  orderTransactionId: string
) => {
  try {
    const res = await httpService.post(
      `partners/orders/${orderId}/orderTransactions/${orderTransactionId}/checkStatus`,
      {}
    );
    return res.data?.data;
  } catch (error) {
    // throw error;
  }
};

export const getVenueById = async (venueId: string) => {
  try {
    const response = await httpService.get(`partners/venues/${venueId}`);
    return response?.data?.data;
  } catch (error) {
    throw error;
  }
};
