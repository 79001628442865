export const benefits = [
  {
    icon:
      'https://s3-ap-southeast-1.amazonaws.com/prod.rovo.co/assets/premium/premium-workout.png',
    bigIcon: '',
    title: {
      en: 'Hundreds of workouts',
      id: 'Hundreds of workouts',
      fr: 'Hundreds of workouts',
      es: 'Hundreds of workouts',
    },
    subtitle: {
      en:
        'Get access to all guided home yoga, fitness & sports training videos.',
      id:
        'Get access to all guided home yoga, fitness & sports training videos.',
      fr:
        'Get access to all guided home yoga, fitness & sports training videos.',
      es:
        'Get access to all guided home yoga, fitness & sports training videos.',
    },
  },
  {
    icon:
      'https://s3-ap-southeast-1.amazonaws.com/prod.rovo.co/assets/premium/ads-free.png',
    bigIcon: '',
    title: {
      en: 'Ad-free experience',
      id: 'Ad-free experience',
      fr: 'Ad-free experience',
      es: 'Ad-free experience',
    },
    subtitle: {
      en: 'Get a 100% ad-free experience on Rovo with Premium.',
      id: 'Get a 100% ad-free experience on Rovo with Premium.',
      fr: 'Get a 100% ad-free experience on Rovo with Premium.',
      es: 'Get a 100% ad-free experience on Rovo with Premium.',
    },
  },
  {
    icon:
      'https://s3-ap-southeast-1.amazonaws.com/prod.rovo.co/assets/premium/gears.png',
    bigIcon: '',
    title: {
      en: '30% off Rovo Store (Coming soon)',
      id: '30% off Rovo Store (Coming soon)',
      fr: '30% off Rovo Store (Coming soon)',
      es: '30% off Rovo Store (Coming soon)',
    },
    subtitle: {
      en: 'Get 30% off all Rovo apparel, footwear & equipment at our store.',
      id: 'Get 30% off all Rovo apparel, footwear & equipment at our store.',
      fr: 'Get 30% off all Rovo apparel, footwear & equipment at our store.',
      es: 'Get 30% off all Rovo apparel, footwear & equipment at our store.',
    },
  },
  {
    icon:
      'https://s3-ap-southeast-1.amazonaws.com/prod.rovo.co/assets/premium/unlimited-broadcast.png',
    bigIcon: '',
    title: {
      en: 'Unlimited broadcasts',
      id: 'Unlimited broadcasts',
      fr: 'Unlimited broadcasts',
      es: 'Unlimited broadcasts',
    },
    subtitle: {
      en:
        'Fill up your activities with ease through unlimited number of broadcasts.',
      id:
        'Fill up your activities with ease through unlimited number of broadcasts.',
      fr:
        'Fill up your activities with ease through unlimited number of broadcasts.',
      es:
        'Fill up your activities with ease through unlimited number of broadcasts.',
    },
  },
  {
    icon:
      'https://s3-ap-southeast-1.amazonaws.com/prod.rovo.co/assets/premium/unlimited-ranked-game.png',
    bigIcon: '',
    title: {
      en: 'Host or join unlimited ranked activities & leaderboards',
      id: 'Host or join unlimited ranked activities & leaderboards',
      fr: 'Host or join unlimited ranked activities & leaderboards',
      es: 'Host or join unlimited ranked activities & leaderboards',
    },
    subtitle: {
      en:
        'Earn more ranking points by hosting or taking part in unlimited ranked activities.',
      id:
        'Earn more ranking points by hosting or taking part in unlimited ranked activities.',
      fr:
        'Earn more ranking points by hosting or taking part in unlimited ranked activities.',
      es:
        'Earn more ranking points by hosting or taking part in unlimited ranked activities.',
    },
  },
  {
    icon:
      'https://s3-ap-southeast-1.amazonaws.com/prod.rovo.co/assets/premium/premium-event.png',
    bigIcon: '',
    title: {
      en: 'Access to Premium only merchandise & events',
      id: 'Access to Premium only merchandise & events',
      fr: 'Access to Premium only merchandise & events',
      es: 'Access to Premium only merchandise & events',
    },
    subtitle: {
      en:
        "You'll be invited to exclusive events, tournaments & product launches which are only available to premium users.",
      id:
        "You'll be invited to exclusive events, tournaments & product launches which are only available to premium users.",
      fr:
        "You'll be invited to exclusive events, tournaments & product launches which are only available to premium users.",
      es:
        "You'll be invited to exclusive events, tournaments & product launches which are only available to premium users.",
    },
  },
  // {
  //   icon:
  //     'https://s3-ap-southeast-1.amazonaws.com/prod.rovo.co/assets/premium/workout-party-logo.png',
  //   bigIcon:
  //     'https://s3-ap-southeast-1.amazonaws.com/prod.rovo.co/assets/premium/workout-party-web-logo.png',
  //   title: {
  //     en: 'Includes WorkoutParty! Premium',
  //     id: 'Includes WorkoutParty! Premium',
  //     fr: 'Includes WorkoutParty! Premium',
  //     es: 'Includes WorkoutParty! Premium',
  //   },
  //   subtitle: {
  //     en:
  //       'All the premium features of WorkoutParty! a new group based live streaming digital instructor led workout platform.',
  //     id:
  //       'All the premium features of WorkoutParty! a new group based live streaming digital instructor led workout platform.',
  //     fr:
  //       'All the premium features of WorkoutParty! a new group based live streaming digital instructor led workout platform.',
  //     es:
  //       'All the premium features of WorkoutParty! a new group based live streaming digital instructor led workout platform.',
  //   },
  // },
];

export const trialDurations = [
  { planName: 'premium-pack-monthly', unit: 'WEEKS', number: 2 },
  { planName: 'premium-pack-annual', unit: 'WEEKS', number: 2 },
  {
    planName: 'discount-30-trial-premium-pack-monthly',
    unit: 'MONTHS',
    number: 3,
  },
  {
    planName: 'discount-30-trial-premium-pack-annual',
    unit: 'MONTHS',
    number: 3,
  },
];
