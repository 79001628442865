import { decorate, observable, action, runInAction } from 'mobx';
import { getProfilePlays } from '../api/ProfileAPI';
import { IActivity } from '../interface/Activity';
import { SEARCH_LIMIT } from '../constant';

class UserActivityStore {
  activities: IActivity[] = [];

  loading: boolean = false;

  hasMore: boolean = true;

  params: {
    offset: number;
  } = { offset: 0 };

  getProfilePlays = async ({ username, params }: { username: string, params?: Object }, isReset?: boolean) => {
    if (this.loading) return;
    if (!this.hasMore && !isReset) return;
    if (isReset) this.params.offset = 0;
    this.loading = true;
    let activities: IActivity[] = [];
    const mergedParams = {
      ...this.params,
      ...params,
    }
    try {
      activities = await getProfilePlays(username, mergedParams);
    } catch { }
    const dataLength = activities.length;
    runInAction(() => {
      if (dataLength < SEARCH_LIMIT) this.hasMore = false;
      if (!isReset) {
        this.activities = [...this.activities, ...activities];
      } else {
        this.activities = activities;
      }
      this.loading = false;
      this.params.offset =
        dataLength === SEARCH_LIMIT
          ? this.params.offset + dataLength
          : this.params.offset;
    });
  };
}

decorate(UserActivityStore, {
  activities: observable,
  loading: observable,
  hasMore: observable,
  getProfilePlays: action,
});

export const userActivityStore = new UserActivityStore();
