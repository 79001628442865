import React, { useState } from 'react';
import { Box, Button, Flex, Image } from 'rebass';
import { Slider } from '@blueprintjs/core';
import styled from '../../../../theme/styled';

import { Icon } from '../../../Primitives';
import Text from '../../../UI/Text';

import { PUBLIC_URL } from '../../../../constant';

const Wrapper = styled(Flex)`
  position: relative;
  height: 100vh;
  @media (min-width: 768px) {
    height: initial;
    min-height: 600px;
  }
`;

const BackBtn = styled(Icon)`
  position: absolute;
  top: 16px;
  left: 16px;
`;

const SSlider = styled(Slider)`
  .bp3-slider-handle {
    .bp3-slider-label {
      display: none;
    }
  }
  .bp3-slider-label {
    font-weight: bold;
    font-size: 14px;
  }
  .bp3-slider-track {
    height: 24px;
    background: linear-gradient(176.13deg, #b7ce63 0%, #06bebd 100%), #06bebd;
    border-radius: 50px;
  }
  .bp3-slider-progress {
    background: transparent;
  }
  .bp3-slider-handle {
    width: 40px;
    height: 32px;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #adbbc4;
    ::before {
      content: '| | |';
      width: 100%;
      text-align: center;
    }
  }
`;

interface AssessmentFlow {
  id: string;
  name: string;
  scale: {
    min: {
      label: string;
      value: string;
    };
    max: {
      label: string;
      value: string;
    };
  };
  steps: {
    description: string;
    value: string;
  }[];
  title: string;
}

interface Assessment {
  id: string;
  name: string;
  value: number;
}

interface Props {
  assessmentFlow: AssessmentFlow[];
  setIsSelfAssessment: Function;
  assessment: Assessment[];
  saveSelfAssessment: (assessment: Assessment[]) => void;
}

const Step: React.FC<{
  data: AssessmentFlow;
  toNextStep: Function;
  isLast: boolean;
  onDoSelfAssessment: (id: string, name: string, value: number) => void;
}> = ({ data, toNextStep, isLast, onDoSelfAssessment }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const { title, steps, id, name, scale } = data;
  const { description } = steps[currentStep];

  const handleChange = (value: number) => {
    onDoSelfAssessment(id, name, Number(steps[value].value));
  };

  return (
    <>
      <Text fontSize="18px" mb="4" mt="1">
        Self Assessment
      </Text>
      <Text mb="3" fontSize="16px" fontWeight="bold">
        {title}
      </Text>
      <Text minHeight="150px">{description}</Text>
      <Box mt="auto" width="100%" px="3" mb="3">
        <SSlider
          min={0}
          max={steps.length - 1}
          stepSize={1}
          value={currentStep}
          onChange={(value) => setCurrentStep(value)}
          labelStepSize={steps.length - 1}
          showTrackFill={false}
          labelRenderer={false}
        />
      </Box>
      <Flex
        mt="2"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        fontSize="14px"
        fontWeight="bold"
      >
        <Text>{scale.min.label}</Text>
        <Text>{scale.max.label}</Text>
      </Flex>

      <Button
        width="100%"
        mt="auto"
        height="50px"
        onClick={() => {
          handleChange(currentStep);
          toNextStep();
        }}
      >
        {isLast ? 'Complete' : 'Next'}
      </Button>
    </>
  );
};

const GetStartStep: React.FC<{ toNextStep: Function }> = ({ toNextStep }) => {
  return (
    <>
      <Text fontSize="18px" mb="4" mt="1">
        Self Assessment
      </Text>
      <Image
        src={`${PUBLIC_URL}/images/self_assessment.png`}
        width="300px"
        mb="4"
      />
      <Text mb="4" textAlign="center" width="320px">
        Doing the Self Assessment will help you find other people of your skill
        rating & learn what your strengths are.
      </Text>
      <Button width="100%" mt="auto" height="50px" onClick={toNextStep}>
        Get started
      </Button>
    </>
  );
};

const SelfAssessment: React.FC<Props> = ({
  assessmentFlow,
  setIsSelfAssessment,
  saveSelfAssessment,
  assessment: initAssessment,
}) => {
  const [step, setStep] = useState(-1);
  const [assessment, setAssessment] = useState(initAssessment);

  const onSaveSelfAssessment = () => {
    saveSelfAssessment(assessment);
  };

  const toNextStep = () => {
    if (step === assessmentFlow.length - 1) {
      onSaveSelfAssessment();
      return setIsSelfAssessment(false);
    }
    setStep((s) => s + 1);
  };

  const toPrevStep = () => {
    if (step === -1) return setIsSelfAssessment(false);
    setStep((s) => s - 1);
  };

  const onDoSelfAssessment = (id: string, name: string, value: number) => {
    const newAssessment = assessment;
    const selectedIndex = assessment.findIndex((a) => a.id === id);
    if (selectedIndex > -1) {
      newAssessment[selectedIndex] = { id, name, value };
    } else {
      newAssessment.push({ id, name, value });
    }
    setAssessment([...newAssessment]);
  };

  return (
    <Wrapper
      alignItems="center"
      p="3"
      color="primaryText"
      flexDirection="column"
    >
      <BackBtn icon="chevron-left" fill="primaryText" onClick={toPrevStep} />
      {step === -1 ? (
        <GetStartStep toNextStep={toNextStep} />
      ) : (
        <Step
          key={step}
          data={assessmentFlow[step]}
          toNextStep={toNextStep}
          isLast={step === assessmentFlow.length - 1}
          onDoSelfAssessment={onDoSelfAssessment}
        />
      )}
    </Wrapper>
  );
};

export default SelfAssessment;
