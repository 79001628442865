import { Dialog } from '@blueprintjs/core';
import { Flex } from 'rebass';

import styled from '../../theme/styled';
import { Icon } from '../Primitives';
import Avatar from '../UI/Avatar';
import { MenuSetting } from '../Layout/Navigation';

export const PageWrapper = styled(Flex)`
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const Body = styled(Flex)`
  width: 100%;
`;

export const BackBtn = styled(Icon)`
  cursor: pointer;
  :hover {
    background-color: ${(p) => p.theme.colors.primaryHover};
  }
  border-radius: 100%;
`;

export const PeopleWrapper = styled(Flex)`
  cursor: pointer;
  :hover {
    background-color: ${(p) => p.theme.colors.primaryHover};
  }
`;

export const ExtendHeader = styled(Flex)`
  position: sticky;
  top: 0px;
  z-index: 3;
`;

export const Title = styled.div`
  padding: 4px 16px;
  font-weight: bold;
`;

export const TickIconCover = styled(Flex)`
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${(p) =>
    p.completed
      ? 'linear-gradient(314.43deg, #b7ce62 0%, #06bebd 99.03%)'
      : 'transparent'};
  border: ${(p) => !p.completed && p.theme.border.box};
  border-width: 2px;
  margin-right: 16px;
`;

export const MobileSettingMenu = styled(MenuSetting)`
  left: inherit;
  right: 32px;
`;

export const InviteFriend = styled(Flex)`
  cursor: pointer;
  :hover {
    background-color: ${(p) => p.theme.colors.primaryTextHover};
  }
`;

export const RemoveFollowerDialog = styled(Dialog)`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const AvatarTop = styled(Avatar)`
  position: relative;
  top: -32px;
  margin: 0px auto;
`;
