import React from 'react';
import { Flex, Box } from 'rebass';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { Spinner } from '@blueprintjs/core';

import { Icon } from '../Primitives';
import Text from '../UI/Text';
import Avatar from '../UI/Avatar';
import { PrimaryDialog } from '../UI/Dialog';

import {
  PeopleWrapper,
  FollowBtn,
  InviteFriend,
} from './LikeDetailDialog.styled';
import { ILiker } from '../../interface/People';
import { likersStore } from '../../stores/LikersStore';
import useWindowInfinityScroll from '../../hooks/useWindowInfinityScroll';
import EmptyList from '../UI/EmptyList';
import { userStore } from '../../stores/UserStore';

const LikeDetailDialog: React.FC<{
  activityId: string;
  isOpen: boolean;
  onClose: () => void;
}> = observer(({ activityId, isOpen, onClose }) => {
  const {
    likers,
    getActivityLikes,
    loading,
    hasMore,
    updateFollow,
  } = likersStore;
  const {
    account: { profileId },
  } = userStore;
  const history = useHistory();

  useWindowInfinityScroll(getActivityLikes, activityId);

  const toProfile = (username: string) => {
    history.push(`/${username}`);
  };

  return (
    <PrimaryDialog isOpen={isOpen} onClose={onClose} title="Like">
      <Flex flexDirection="column">
        <InviteFriend
          px="3"
          py="2"
          alignItems="center"
          justifyContent="space-between"
          onClick={() => history.push('/settings/find-friends')}
        >
          <Text color="primary" fontWeight="bold">
            Invite your friend
          </Text>
          <Icon icon="chevron-right" fill="primaryText" />
        </InviteFriend>
        <Box variant="line" />
        {likers.map((p: ILiker) => {
          const { liker } = p;
          const {
            id,
            firstName,
            lastName,
            picture,
            pulseLevel,
            username,
            followStatus,
          } = liker;
          return (
            <PeopleWrapper
              p="3"
              alignItems="center"
              key={id}
              bg="white"
              onClick={() => toProfile(username)}
            >
              <Avatar imageLink={picture} pulseLevel={pulseLevel} />
              <Text ml="2" fontWeight="bold" color="primaryText">
                {firstName} {lastName}
              </Text>
              {id !== profileId && (
                <FollowBtn
                  followed={followStatus && followStatus !== 'NOT_FOLLOWING'}
                  variant="outline"
                  ml="auto"
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    updateFollow(p);
                  }}
                >
                  <Text textTransform="capitalize" fontWeight="bold">
                    {!!followStatus && followStatus !== 'NOT_FOLLOWING'
                      ? followStatus.toLowerCase()
                      : 'Follow'}
                  </Text>
                </FollowBtn>
              )}
            </PeopleWrapper>
          );
        })}
        {!likers.length && !loading && <EmptyList text="liker" />}
        {hasMore && loading && <Spinner size={30} intent="primary" />}
      </Flex>
    </PrimaryDialog>
  );
});

export default LikeDetailDialog;
