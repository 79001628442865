import React, { useState, useEffect } from 'react';
import { Box, Flex } from 'rebass';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react-lite';

import Text from '../../UI/Text';

import { HeaderMobile } from '../../Layout/Header';
import Switch from '../../UI/Switch';
import { userStore } from '../../../stores/UserStore';

const Privacy: React.FC = observer(() => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  const { account, updateProfile } = userStore;
  const {
    profile: { isPrivate },
  } = account;

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(isPrivate);
  }, [isPrivate]);

  const onUpdatePrivacy = (checked: boolean) => {
    updateProfile({ isPrivate: checked });
  };

  return (
    <Flex flexDirection="column">
      <HeaderMobile text="Privacy" hasBackBtn={isMobile} backLink="/settings" />
      <Box p="3">
        <Flex mb="2" justifyContent="space-between" alignItems="center">
          <Text color="primaryText">Private profile</Text>
          <Switch checked={checked} onChange={onUpdatePrivacy} />
        </Flex>
        <Text>
          When your profile is private, only people you approve can see your
          activities. Your exitting followers won't be affected
        </Text>
      </Box>
    </Flex>
  );
});

export default Privacy;
