import React, { useEffect, useState, useRef } from 'react';
import { Box, Flex, Text, Image } from 'rebass';
import { observer } from 'mobx-react-lite';

import styled from '../../../theme/styled';

import Avatar from '../../UI/Avatar';
import { ChatInput } from '../../UI/Input';

import { IPeople } from '../../../interface/People';
import { getLastActive } from '../../../helpers/datetime';
import { useStores } from '../../../hooks/useStore';

import ScoreCard from '../../Timeline/Card/ScoreCard';
import PostCard from '../../Timeline/Card/PostCard';
import HostCard from '../../Timeline/Card/HostCard';
import AwardCard from '../../Timeline/Card/AwardCard';
import CardContainer from '../../Timeline/Card/CardContainer';
import { useHistory } from 'react-router-dom';
import { Icon } from '../../Primitives';

const Comment = styled(Flex)`
  padding: 8px 16px;
  border-radius: 16px;
`;

const ViewPrevComment = styled(Text)`
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

interface Props {
  item: any;
}

interface IComment {
  type: string;
  position: number;
  content: {
    text: string;
    photo?: string;
  };
  parentType: string;
  parentId: string;
  sender: IPeople;
  updatedAt: string;
}

const PostDetail: React.FC<Props> = observer(({ item }) => {
  const { postDetailStore } = useStores();
  const {
    comments,
    getComments,
    sendTextComment,
    sendImageComment,
    hasMoreComments,
  } = postDetailStore;
  const [comment, setComment] = useState('');

  useEffect(() => {
    getComments(item.id);
  }, [getComments, item]);

  const body = useRef<HTMLDivElement>();

  const scrollToBottom = async () => {
    if (body && body.current) {
      body.current.scrollTop = body.current.scrollHeight;
    }
  };

  const sendTextMessage = async (text: string) => {
    await sendTextComment(item.id, text);
    scrollToBottom();
  };

  const sendImageMessage = async (image: File) => {
    await sendImageComment(item.id, image);
    scrollToBottom();
  };

  const renderCard = (item: any) => {
    const verb = item?.verb;
    switch (verb) {
      case 'post':
        return <PostCard key={item.id} item={item} />;
      case 'play':
        return <ScoreCard key={item.id} item={item} />;
      case 'host':
      case 'join':
        return <HostCard key={item.id} item={item} />;
      case 'award':
        return <AwardCard key={item.id} item={item} />;
      default:
        return null;
    }
  };

  const history = useHistory();

  return (
    <Flex flexDirection="column" bg="white" height="100%" ref={body}>
      <Flex
        sx={{
          position: 'sticky',
          top: '0',
          height: '56px',
          'z-index': 1,
        }}
        px="3"
        py="2"
        alignItems="center"
        bg="white"
      >
        <Icon
          icon="chevron-left"
          size="lg"
          fill="primaryText"
          onClick={() => {
            history.goBack();
          }}
        />
        <Text variant="headline.small">Post Detail</Text>
      </Flex>
      <CardContainer item={item} isSelf isDetail>
        {renderCard(item)}
      </CardContainer>
      {hasMoreComments && (
        <ViewPrevComment
          px="3"
          mb="2"
          color="primaryText"
          onClick={() => getComments(item.id)}
        >
          View previous comments
        </ViewPrevComment>
      )}
      <Flex flexDirection="column" px="3">
        {!!comments.length ? (
          comments.map((comment) => {
            const { sender, content, updatedAt, position } = comment;
            const { picture, pulseLevel, name } = sender;
            const lastActive = getLastActive(updatedAt);

            return (
              <Flex mb="2" key={position}>
                <Avatar
                  imageLink={picture}
                  pulseLevel={pulseLevel}
                  size="32px"
                />
                <Box ml="2" color="primaryText" flex="1">
                  <Comment flexDirection="column" bg="gray" mb="2">
                    <Text fontWeight="bold">{name}</Text>
                    {comment.type === 'PHOTO' ? (
                      <Image src={comment.content?.url} />
                    ) : (
                      <Text
                        dangerouslySetInnerHTML={{ __html: content.text }}
                      />
                    )}
                  </Comment>
                  <Text fontSize="12px">{lastActive}</Text>
                </Box>
              </Flex>
            );
          })
        ) : (
          <Text variant="body.large">No comment yet</Text>
        )}
      </Flex>
      <Box
        mt="auto"
        sx={{
          position: 'sticky',
          bottom: '0px',
          px: '3',
          py: '2',
          bg: 'white',
        }}
      >
        <ChatInput
          value={comment}
          onChange={(value: string) => setComment(value)}
          hasLikeEmoji
          onEnter={sendTextMessage}
          onSelectImage={sendImageMessage}
        />
      </Box>
    </Flex>
  );
});

export default PostDetail;
