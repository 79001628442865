import React, { FunctionComponent } from 'react';
import { Flex, Image } from 'rebass';
import { observer } from 'mobx-react-lite';
import Text from '../../UI/Text';

import IActivityTimeline from '../../../interface/ActivityTimeline';

import styled from '../../../theme/styled';

const Background = styled(Flex)`
  background: radial-gradient(
    48.31% 48.31% at 50% 54.68%,
    #4b6682 0%,
    #212f3d 100%
  );
`;

const AwardCard: FunctionComponent<{
  item: IActivityTimeline;
}> = observer(({ item }) => {
  const {
    object: { rawObject },
  } = item;

  const {
    sportInfo: { name },
  } = rawObject;

  return (
    <Background
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      py="4"
    >
      <Text fontSize="20px" mb="2">
        {name}
      </Text>
      <Image src={rawObject.icon} width="200px" />
      <Text mt="2" fontSize="20px" fontWeight="bold" color="white">
        {rawObject.name}
      </Text>
    </Background>
  );
});

export default AwardCard;
