import styled from '../../../theme/styled';
import { Dialog, Slider, RangeSlider } from '@blueprintjs/core';
import Text from '../../UI/Text';
import { Flex } from 'rebass';
import { Icon } from '../../Primitives';

export const FilterWrapper = styled(Flex)`
  position: sticky;
  bottom: 48px;
  background-color: #fff;
  align-items: center;
  box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  width: 100%;
  border: 0.5px solid #e9eef1;
  box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.1);
  @media (min-width: 768px) {
    bottom: 16px;
    border-radius: 6px;
    margin-top: auto;
  }
`;

export const SelectWrapper = styled(Flex)`
  cursor: pointer;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  :hover {
    background-color: ${(p) => p.theme.colors.primaryHover};
  }
  :not(:last-child) {
    border-right: ${(p) => p.theme.border.box};
  }
`;

export const FilterDialog = styled(Dialog)`
  position: absolute;
  bottom: 0px;
  padding: 0;
  margin: 0;
  width: 100%;
  padding: 16px;
  background: #fff;
  @media (min-width: 768px) {
    max-width: 568px;
    bottom: initial;
    padding: 32px;
  }
`;

export const SportDialog = styled(FilterDialog)`
  height: 80vh;
`;

export const SportWrapper = styled(Flex)`
  overflow: auto;
  height: 100%;
`;

export const LocationWrapper = styled(Flex)`
  max-height: 160px;
  overflow: auto;
`;

export const Item = styled(Text)`
  :hover {
    color: ${(p) => p.theme.colors.primary};
  }
  cursor: pointer;
  text-transform: capitalize;
`;

export const LocationItem = styled(Flex)`
  :not(:last-child) {
    border-bottom: 0.5px solid #d4dade;
  }
  :hover {
    background-color: ${(p) => p.theme.colors.primaryHover};
  }
  cursor: pointer;
  min-height: 40px;
  justify-content: center;
  flex-direction: column;
`;

export const StyledSlider = styled(Slider)`
  .bp3-slider-handle {
    background-color: ${(p) => p.theme.colors.primary};
    border-radius: 50%;
    background-image: none;
  }
`;

export const SRangeSlider = styled(RangeSlider)``;

export const LevelText = styled(Text)`
  :not(:last-child) {
    border-right: 0.5px solid #d4dade;
  }
`;

export const CloseIcon = styled(Icon)`
  cursor: pointer;
  margin-left: 8px;
  min-width: 16px;
`;
