const dev = {
  URL: 'http://localhost:3000',
  ENV: 'dev',
  API_HOST: 'https://api.inrovo.co/v1.9.0/',
  SOCKET_HOST: 'https://api.inrovo.co',
  FB: {
    APP_ID: '918484418265408',
  },
  FIREBASE: {
    APP_LINK:
      'https://y8ka5.app.goo.gl/?apn=com.rovo.rovo.alpha&ibi=com.rovo.rovo-sandbox&ipbi=com.rovo.rovo-sandbox&isi=1044009295&ius=rovo&utm_source=rovo&utm_medium=web&d=1',
    API_KEY: 'AIzaSyCgHtQq_OrhtLXcFiViYILJ7YRXieK5JJU',
    AUTH_DOMAIN: 'rovo-sandbox-4066e.firebaseapp.com',
    DATABASE_URL: 'https://rovo-sandbox-4066e.firebaseio.com',
    PROJECT_ID: 'rovo-sandbox-4066e',
    APP_ID: '1:544362592138:android:c268574b79fc44aa',
    STORAGE_BUCKET: 'rovo-sandbox-4066e.appspot.com',
    MESSAGING_SENDER_ID: '544362592138',
  },
  RAZORPAY_API_KEY: 'rzp_test_irqRw5zPuDeiPL',
  STRIPE_PUBLIC_KEY: 'pk_test_d1i8P8z8WoENK9lCqBEAaW7r',
  AMPLITUDE_KEY: '9dafbfbf3f71b1492cfc809d45219c52',
  BRANCH_KEY: 'key_live_koKhe9m304jJd60DOEpXqdokyCfXxX8Q',
  WHITELISTED_PAID_HOST: [4, 20425, 20226, 20627, 20673, 20426],
  WP_URL: 'https://app-alpha.workoutparty.co',
  FRESHCHAT_TOKEN: '81e463f4-df2e-45a2-9ee9-83c040a3ce6a',
};

const alpha = {
  URL: 'https://inrovo.co',
  ENV: 'alpha',
  API_HOST: 'https://api.inrovo.co/v1.9.0/',
  SOCKET_HOST: 'https://api.inrovo.co',
  FB: {
    APP_ID: '918484418265408',
  },
  FIREBASE: {
    APP_LINK:
      'https://y8ka5.app.goo.gl/?apn=com.rovo.rovo.alpha&ibi=com.rovo.rovo-sandbox&ipbi=com.rovo.rovo-sandbox&isi=1044009295&ius=rovo&utm_source=rovo&utm_medium=web&d=1',
    API_KEY: 'AIzaSyCgHtQq_OrhtLXcFiViYILJ7YRXieK5JJU',
    AUTH_DOMAIN: 'rovo-sandbox-4066e.firebaseapp.com',
    DATABASE_URL: 'https://rovo-sandbox-4066e.firebaseio.com',
    PROJECT_ID: 'rovo-sandbox-4066e',
    APP_ID: '1:544362592138:android:c268574b79fc44aa',
    STORAGE_BUCKET: 'rovo-sandbox-4066e.appspot.com',
    MESSAGING_SENDER_ID: '544362592138',
  },
  RAZORPAY_API_KEY: 'rzp_test_irqRw5zPuDeiPL',
  STRIPE_PUBLIC_KEY: 'pk_test_d1i8P8z8WoENK9lCqBEAaW7r',
  AMPLITUDE_KEY: '9dafbfbf3f71b1492cfc809d45219c52',
  BRANCH_KEY: 'key_live_koKhe9m304jJd60DOEpXqdokyCfXxX8Q',
  WHITELISTED_PAID_HOST: [4, 20425, 20226, 20627, 20673, 20426],
  WP_URL: 'https://app-alpha.workoutparty.co',
  FRESHCHAT_TOKEN: '81e463f4-df2e-45a2-9ee9-83c040a3ce6a',
};

const prod = {
  URL: 'https://rovo.co',
  ENV: 'prod',
  API_HOST: 'https://api.rovo.co/v1.9.0/',
  SOCKET_HOST: 'https://api.rovo.co',
  FB: {
    APP_ID: '865242910256226',
  },
  FIREBASE: {
    APP_LINK:
      'https://d276g.app.goo.gl/?apn=com.rovo.rovo&ibi=com.rovo.rovo&ipbi=com.rovo.rovo&isi=1044009295&ius=rovo&utm_source=rovo&utm_medium=web',
    API_KEY: 'AIzaSyBeWYM-LaS38M8heHw8BT5aWjYWQDtTZqM',
    AUTH_DOMAIN: 'rovo-e1018.firebaseapp.com',
    DATABASE_URL: 'https://rovo-e1018.firebaseio.com',
    PROJECT_ID: 'rovo-e1018',
    APP_ID: '1:933714766256:android:262690f52612b067',
    STORAGE_BUCKET: 'rovo-e1018.appspot.com',
    MESSAGING_SENDER_ID: '933714766256',
  },
  RAZORPAY_API_KEY: 'rzp_live_GRVWLVAnhXoEO7',
  STRIPE_PUBLIC_KEY: 'pk_live_ODqeXhT1VlFQbcmxlB1SUxDP',
  AMPLITUDE_KEY: '51ac68f09db7c719db94f864e1ebf727',
  BRANCH_KEY: 'key_live_koKhe9m304jJd60DOEpXqdokyCfXxX8Q',
  WHITELISTED_PAID_HOST: [302399],
  WP_URL: 'https://app.workoutparty.co',
  FRESHCHAT_TOKEN: 'd94e8831-c599-4003-8f3b-0e0e3e4781ab',
};

const getConfig = () => {
  const env = process.env.REACT_APP_STAGE;
  switch (env) {
    case 'dev':
      return dev;
    case 'alpha':
      return alpha;
    case 'prod':
      return prod;
    default:
      return dev;
  }
};

const config = getConfig();

export default {
  ...config,
  GOOGLE_MAP_API_KEY: 'AIzaSyBeWYM-LaS38M8heHw8BT5aWjYWQDtTZqM',
};
